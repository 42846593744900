import {useTitle} from 'hoofd'
import {FC, ReactElement} from 'react'
import {useParams} from 'react-router-dom'
import Header from '../../components/header/HeaderComponent'
import {
  DetailWrapper,
  LoadingBox,
} from '../../components/learner/micro-learning/MicroLearning.style'
import QuestionReviewDetail from '../../components/learner/micro-learning/question-review/QuestionReviewDetail.component'
import LoadingCircular from '../../components/loading/LoadingCircular.component'
import BackNavigation from '../../components/navigation/BackNavigation.component'
import useStyles, {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/GlobalStyles'
import {QUESTION_REVIEW_MICRO} from '../../provider/services/MicroLearning.service'

const QuestionReview: FC = (): ReactElement => {
  const {id} = useParams()
  const classes = useStyles()

  const {data: questionReviewData, loading: questionReviewLoading} =
    QUESTION_REVIEW_MICRO(id || '')

  const microLearningName = questionReviewData?.microlearnings?.[0]?.name
  useTitle(microLearningName)

  if (questionReviewLoading) {
    return (
      <LoadingBox>
        <LoadingCircular />
      </LoadingBox>
    )
  }

  const dataMicro = questionReviewData?.microlearnings?.[0]
  const dataMicroMembers = dataMicro?.microlearning_members?.[0]
  const dataPeople =
    questionReviewData?.people_work_placements?.[0]?.global_user
  const dataQuestionreview = {
    microMembers: dataMicroMembers,
    microQuestion: dataMicro?.microlearning_questions,
    microQuestionLocked: dataMicro?.locked,
    questionCount: dataMicro?.question_count,
    people: dataPeople,
  }

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'micro-learning-detail'}
          className={classes.tabs}
          centered
        >
          <StyledTab
            label="Micro Learning Detail"
            value="micro-learning-detail"
          />
        </StyledTabs>
      </TabsWrapper>

      <DetailWrapper className="bg-[#fafafa]">
        <div className="w-full flex flex-col">
          <BackNavigation />
          <QuestionReviewDetail {...dataQuestionreview} />
        </div>
      </DetailWrapper>
    </>
  )
}

export default QuestionReview
