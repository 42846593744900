import React from 'react'
import {Card, CardMedia, CardContent, Typography} from '@material-ui/core'
import {IBook} from '../../../../interfaces'
import {bindHover} from 'material-ui-popup-state/hooks'
import {PopupState} from 'material-ui-popup-state/core'
import {convertToRupiah} from '../../../../utils/helpers'
import LinesEllipsis from 'react-lines-ellipsis'
import Rating from '../../../utils-components/Rating.component'
import WishlistButton from '../../../button/WishlistButton.component'
import AddToCartButton from '../../../button/AddToCartButton.component'
import {useNavigate} from 'react-router-dom'

type BookCardProps = {
  classes: any
  item: IBook
  hidePrice: boolean
  popupState: PopupState
  isWishlist?: boolean
  removeFromWishlist?: () => void
  addToCart?: () => void
  width?: string
  featureName?: string
  wishlistLoading?: boolean
}

const BookCard = ({
  classes,
  item,
  hidePrice,
  popupState,
  isWishlist,
  removeFromWishlist,
  addToCart,
  width = 'w-1/4',
  featureName,
  wishlistLoading,
}: BookCardProps): React.ReactElement => {
  const navigate = useNavigate()

  const pricing = item.pricing_idr ?? item.pricingIdr
  const isFree = !pricing || '' + pricing === '0'

  return (
    <div className={`w-full md:w-1/2 lg:${width} pb-1`}>
      <Card
        className={classes.root}
        {...bindHover(popupState)}
        onClick={() =>
          navigate(`/learner/book/${item.isbn}`, {
            state: {prevRouteName: featureName},
          })
        }
      >
        <CardMedia
          className={classes.mediaPortait}
          image={
            item.thumbnail ||
            require('../../../../assets/images/defaultBookCover.image.png')
          }
          style={
            isWishlist ? {display: 'flex', justifyContent: 'flex-end'} : {}
          }
        >
          {isWishlist && (
            <WishlistButton
              isWishlist={true}
              action={removeFromWishlist}
              loading={Boolean(wishlistLoading)}
            />
          )}
        </CardMedia>
        <CardContent className="pb-4">
          <div className="h-14 overflow-visible">
            <div style={{lineHeight: '1.5em', height: '3.5em'}}>
              <LinesEllipsis
                text={item.title}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
                style={{fontSize: '1rem', fontWeight: 'bold'}}
              />
            </div>
            <LinesEllipsis
              className="text-gray-400 text-sm"
              text={item.global_user?.name || item.OwnerUser?.name}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
          <div className="mt-6">
            <Rating
              averageRating={
                item.average_rating ? Number(item.average_rating) : 0
              }
              totalEnrollment={item.count_reviews || item.countReviews}
              isSmallRating
            />
          </div>
          {!hidePrice && (
            <Typography className="pt-1 font-bold capitalize text-right">
              {isFree ? 'Gratis' : convertToRupiah(pricing)}
            </Typography>
          )}
          {isWishlist && (
            <AddToCartButton
              isInCart={item.isInCart || false}
              action={addToCart}
              className="mt-4"
              fullWidth
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default BookCard
