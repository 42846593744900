import React from 'react'
import {useNavigate} from 'react-router-dom'
import {
  Divider,
  Paper,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TablePagination,
  Typography,
} from '@material-ui/core'

import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {GreyTypography} from '../../../components/utils-components/GlobalStyles'
import LinesEllipsis from 'react-lines-ellipsis'

const learnerDataDetail = [
  {
    id: 0,
    name: '100 Days of Code - The Complete Python Pro Bootcamp for 2021',
    type: 'Course',
  },
  {
    id: 1,
    name: 'NodeJS - The Complete Guide (MVC, REST APIs, GraphQL, Deno)',
    type: 'Micro Learning',
  },
  {
    id: 2,
    name: 'Phillip Gouse',
    type: 'E-Book',
  },
  {
    id: 3,
    name: 'Adobe XD Essential Training for Beginner',
    type: 'Audio Book',
  },
  {
    id: 4,
    name: 'React Native - The Practical Guide [2021 Edition]',
    type: 'Event',
  },
  {
    id: 5,
    name: 'React Native - The Practical Guide [2021 Edition]',
    type: 'Event',
  },
]

interface LearnerDetailProps {
  data: {
    id: number
    name: string
    type: string
  }
}

const Item = (props: LearnerDetailProps) => {
  const {data} = props
  return (
    <div className="px-6 py-4 flex gap-6">
      <img
        src={require('../../../assets/images/learner-detail.png')}
        alt="learner-thumbnail"
      />
      <div>
        {/* <div> */}
        <LinesEllipsis
          text={data.name}
          className="font-medium text-base overflow-hidden"
          maxLine={1}
        />
        {/* </div> */}
        <div
          className="text-sm mt-7 text-[#039be5]"
          style={{position: 'absolute'}}
        >
          {data.type}
        </div>
      </div>
    </div>
  )
}
const ContributorLearnerDetailPage = () => {
  const navigate = useNavigate()
  return (
    <Paper>
      <div className="h-20 px-6 py-6 flex items-center">
        <IconButton onClick={() => navigate(-1)} edge="start">
          <ArrowBackIcon />
        </IconButton>
        <img
          className="w-10 mr-3"
          src={require('../../../assets/images/learner-img.png')}
          alt="learner-thumbnail"
        />
        <div>
          <Typography className="font-medium text-lg">
            Makenna Botosh
          </Typography>
          <GreyTypography className="text-sm mt-2">
            Visual Designer at WorkLifeBeyonf R&D Office Bandung
          </GreyTypography>
        </div>
      </div>

      <Divider />
      <TableContainer className="overflow-x-visible">
        <Table className="table-fixed">
          <TableBody>
            {learnerDataDetail.map((item) => {
              return <Item data={item} key={item.id} />
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={0}
          rowsPerPage={10}
          page={0}
          onPageChange={() => console.info(1)}
          onRowsPerPageChange={() => console.info(2)}
        />
      </TableContainer>
    </Paper>
  )
}

export default ContributorLearnerDetailPage
