import {gql} from '@apollo/react-hooks'

export const GET_RECOMMENDED_TRENDING_COURSES = gql`
  query (
    $offset: Int, 
    $limit: Int, 
    $category_id: [Int], 
    $isRecommended: Boolean, 
    $sortByTrending: Boolean
  ) {
    getListCourses(
      isRecommended: $isRecommended
      sortByTrending: $sortByTrending
      offset: $offset
      limit: $limit
      min_price: $min_price
      max_price: $max_price
      free: $free
      paid: $paid
      search: $search
      rating: $rating
      category_id: $category_id
      language: $language
      level: $level
    ) {
      count
      courses {
        id
        title
        subtitle
        slug
        category
        academy_course_category {
          id
          name
        }
        type
        subtitle
        description
        goals
        requirements
        target_audience
        topic
        level
        thumbnail
        video
        language
        creator
        CreatorUser: global_user {
          id
          name
          avatar
          email
        }
        organization_id
        count_section
        count_lesson
        count_enrollment
        averageRating: average_rating
        price_tier
        Price: academy_price_tier {
          id
          name
          pricing_idr
        }
      }
    }
  }
`
