import {makeStyles} from '@material-ui/core/styles'
import React from 'react'
import {LearningType} from '../../../utils/enum'
import BookCard from './card/BookCard.component'
import ContributorCard from './card/ContributorCard.component'
import CourseCard from './card/CourseCard.component'
import EventCard from './card/EventCard.component'
import MicroLearningCard from './card/MicroLearningCard.component'
import SocialLearningCard from './card/SocialLearningCard.component'
import {PopperBook} from './popper/PopperBook.component'
import {PopperCourse} from './popper/PopperCourse.component'
import {PopperMicroLearning} from './popper/PopperMicroLearning.component'
import {usePopupState, bindPopover} from 'material-ui-popup-state/hooks'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {PopupState} from 'material-ui-popup-state/core'
import PopperEvent from './popper/PopperEvent.component'

type LearningCatalogCardProps = {
  type: LearningType
  item: any
  hidePrice: boolean
  width?: string
  isWishlist?: boolean
  removeFromWishlist?: () => void
  addToCart?: () => void
  featureName?: string
  wishlistLoading?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '14px 14px 0',
    cursor: 'pointer',
  },
  paper: {
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16);',
  },
  mediaLandspace: {
    height: 130,
  },
  mediaPortait: {
    height: 244,
  },
  mediaSquare: {
    height: 152,
    width: 152,
  },
  popper: {
    zIndex: 1,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.9em',
      height: '3em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
      },
    },
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    },
  },
  eventCard: {
    margin: '14px 14px 0',
    cursor: 'pointer',
  },
  eventCardContent: {
    height: 170,
    display: 'flex',
    flexDirection: 'row',
  },
  socialLearningCardContent: {
    height: 300,
    padding: 10,
    alignItems: 'stretch',
  },
  marginTextContent: {
    marginBottom: 10,
  },
  microLearningCard: {
    gap: 10,
    padding: 10,
  },
  videoPreview: {
    height: 140,
  },
}))

type HoverProps = {
  popupState: PopupState
}

const HoverPopperCard: React.FC<HoverProps> = ({popupState, children}) => {
  return (
    <HoverPopover
      {...bindPopover(popupState)}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      disableScrollLock
    >
      {children}
    </HoverPopover>
  )
}

const LearnerCatalogCard = (
  props: LearningCatalogCardProps
): React.ReactElement => {
  const classes = useStyles()
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'learnerPopover',
  })

  const renderLearnerCatalogCard = () => {
    switch (props.type) {
      case LearningType.BOOK:
        return (
          <>
            <BookCard
              classes={classes}
              item={props.item}
              hidePrice={props.hidePrice}
              popupState={popupState}
              isWishlist={props.isWishlist}
              removeFromWishlist={props.removeFromWishlist}
              addToCart={props.addToCart}
              width={props.width}
              featureName={props.featureName}
              wishlistLoading={props.wishlistLoading}
            />
            {!props.isWishlist && (
              <HoverPopperCard popupState={popupState}>
                <PopperBook classes={classes} {...props} />
              </HoverPopperCard>
            )}
          </>
        )
      case LearningType.EVENT:
        return (
          <>
            <EventCard
              classes={classes}
              item={props.item}
              hidePrice={props.hidePrice}
              popupState={popupState}
              isWishlist={props.isWishlist}
              removeFromWishlist={props.removeFromWishlist}
              width={props.width}
              featureName={props.featureName}
              wishlistLoading={props.wishlistLoading}
            />
            {!props.isWishlist && (
              <HoverPopperCard popupState={popupState}>
                <PopperEvent classes={classes} {...props} />
              </HoverPopperCard>
            )}
          </>
        )
      case LearningType.SOCIAL_LEARNING:
        return (
          <SocialLearningCard
            classes={classes}
            item={props.item}
            hidePrice={false}
            handlePopper={() => true}
            width={props.width}
          />
        )
      case LearningType.MICRO_LEARNING:
        return (
          <>
            <MicroLearningCard
              classes={classes}
              item={props.item}
              hidePrice={props.hidePrice}
              popupState={popupState}
              isWishlist={props.isWishlist}
              removeFromWishlist={props.removeFromWishlist}
              addToCart={props.addToCart}
              width={props.width}
              featureName={props.featureName}
              wishlistLoading={props.wishlistLoading}
            />
            {!props.isWishlist && (
              <HoverPopperCard popupState={popupState}>
                <PopperMicroLearning classes={classes} {...props} />
              </HoverPopperCard>
            )}
          </>
        )
      case LearningType.LEARNING_CONTRIBUTOR:
        return <ContributorCard item={props.item} />
      default:
        return (
          <>
            <CourseCard
              classes={classes}
              item={props.item.Course || props.item}
              hidePrice={props.hidePrice}
              popupState={popupState}
              width={props.width}
              isWishlist={props.isWishlist}
              removeFromWishlist={props.removeFromWishlist}
              addToCart={props.addToCart}
              featureName={props.featureName}
              wishlistLoading={props.wishlistLoading}
            />
            {!props.isWishlist && (
              <HoverPopperCard popupState={popupState}>
                <PopperCourse classes={classes} {...props} />
              </HoverPopperCard>
            )}
          </>
        )
    }
  }

  return renderLearnerCatalogCard()
}

export default LearnerCatalogCard
