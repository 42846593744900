import React from 'react'
import {useApolloClient} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {Checkbox, Divider, Menu, MenuItem, Typography} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

import DeletePopup from '../../../../shared-component/popup/DeletePopup'
import {ReplyTextarea} from '../../../../../components/review-rating/RatingComment'
import {DiscussionComment} from './DiscussionComment'

import {USER_ID} from '../../../../../utils/globals'
import {
  ADD_HELPFUL,
  DELETE_COMMENT_RESPONSE,
  DELETE_HELPFUL,
  EDIT_COMMENT_RESPONSE,
} from '../../../../../graphql/mutations'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'

export interface CommentChildProps {
  data: any
  refetch?: () => void
}

const enum CommentState {
  NONE,
  EDITING,
  DELETING,
}

const CommentChild = (props: CommentChildProps) => {
  const {data, refetch} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [state, setState] = React.useState(CommentState.NONE)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()

  const placement = data.user.placements[0]
  const isHelpful = data.self_helpful.length > 0

  const handleHelpfulChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    next: boolean
  ) => {
    let promise

    if (next) {
      promise = client.mutate({
        mutation: ADD_HELPFUL,
        variables: {
          objects: {
            response_id: data.id,
            user_id: USER_ID,
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })
    } else {
      promise = client.mutate({
        mutation: DELETE_HELPFUL,
        variables: {
          objects: {
            response_id: {_eq: data.id},
            user_id: {_eq: USER_ID},
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    promise.then(
      () => refetch?.(),
      () =>
        enqueueSnackbar(`Failed to ${next ? 'like' : 'unlike'} comment`, {
          variant: 'error',
        })
    )
  }

  const handleTextareaSubmit = (next: string) => {
    setState(CommentState.NONE)

    const promise = client.mutate({
      mutation: EDIT_COMMENT_RESPONSE,
      variables: {
        id: data.id,
        set: {
          fulltext: next,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    promise.catch(() => {
      enqueueSnackbar(`Failed to edit comment`, {variant: 'error'})
    })
  }

  const handleDelete = () => {
    setState(CommentState.NONE)

    const promise = client.mutate({
      mutation: DELETE_COMMENT_RESPONSE,
      variables: {
        commentId: data.id,
      },
      context: INSTRUCTOR_CONTEXT,
    })

    promise.then(
      () => refetch?.(),
      () => enqueueSnackbar(`Failed to delete comment`, {variant: 'error'})
    )
  }

  const handleMenuClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <DiscussionComment
        author={data.user.name}
        avatarUrl={data.user.avatar}
        position={`${placement?.company_job_profile.title} at ${placement?.company_profile.brand_name}`}
        date={data.date_added}
        body={state !== CommentState.EDITING ? data.fulltext : null}
        onMenu={(ev) => setAnchorEl(ev.currentTarget)}
        footer={
          <div>
            {state !== CommentState.EDITING ? (
              <>
                <Typography
                  color="textSecondary"
                  className="mt-2 text-sm flex gap-2"
                >
                  <span>Like ({data.count_helpful || 0})</span>
                </Typography>

                <div className="flex gap-1 mt-1 -mb-4 -mx-3">
                  <Checkbox
                    title="Like this comment"
                    checked={isHelpful}
                    icon={<FavoriteBorderIcon />}
                    checkedIcon={<FavoriteIcon color="error" />}
                    onChange={handleHelpfulChange}
                    size="small"
                    color="default"
                    className="h-11 w-11"
                  />
                </div>
              </>
            ) : (
              <ReplyTextarea
                editing
                comment
                initialValue={data.fulltext}
                onCancel={() => setState(CommentState.NONE)}
                onSubmit={handleTextareaSubmit}
              />
            )}
          </div>
        }
      />

      <Divider className="mb-6" />

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
        {data.user.id === USER_ID && (
          <MenuItem
            onClick={() => {
              setState(CommentState.EDITING)
              handleMenuClose()
            }}
          >
            Edit
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setState(CommentState.DELETING)
            handleMenuClose()
          }}
          className="text-red-600"
        >
          Delete
        </MenuItem>
      </Menu>

      <DeletePopup
        open={state === CommentState.DELETING}
        name="comment"
        checkboxText="I understand that deleting this comment is permanent and cannot be undone. This action will also delete replies in this comment."
        mutation={handleDelete}
        handleClose={() => setState(CommentState.NONE)}
      />
    </>
  )
}

export default CommentChild
