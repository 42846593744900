import React, {FC, ReactElement} from 'react'
import {Divider, Typography} from '@material-ui/core'
import {TextBlue} from '../../../utils-components/GlobalStyles'
import ReviewDialog from '../../catalog/dialog/AddEditReviewDialog.component'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import {
  ADD_BOOK_REVIEW,
  UPDATE_BOOK_REVIEW,
} from '../../../../graphql/mutations/book/review.mutation'
import {useMutation} from '@apollo/client'
import {useSnackbar} from 'notistack'
import {useParams} from 'react-router-dom'
import {
  BOOK_REVIEWS_AGGREGATE,
  MY_BOOK_REVIEWS,
} from '../../../../provider/services/Book.service'
import Skeleton from 'react-loading-skeleton'
import DisplayRatingStar from '../../../review-rating/DisplayRatingStar.component'
import RatingSummarySection from '../../../review-rating/RatingSummarySection.component'
import Dompurify from 'dompurify'
import ReviewList from './ReviewList.component'

type TabReviewProps = {
  isMyBook: boolean
  isAudioBook: boolean
  bookDetailRefetch: () => void
}

const TabReview: FC<TabReviewProps> = ({
  isMyBook,
  isAudioBook,
  bookDetailRefetch,
}: TabReviewProps): ReactElement => {
  const {isbn} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  const [reviewDialogData, setReviewDialogData] = React.useState({
    isOpen: false,
    rate: 4,
    description: 'bukunya bagus sekali',
  })

  const [ratingSum, setRatingSum] = React.useState<Array<number>>([])
  const [userRateData, setUserRateData] = React.useState<number>(0)

  const {
    data: myBookReviewsData,
    loading: myBookReviewLoading,
    refetch: myBookReviewRefetch,
  } = MY_BOOK_REVIEWS(isbn || '', !isMyBook)

  const {data: bookReviewsAggregateData, loading: bookReviewsAggregateLoading} =
    BOOK_REVIEWS_AGGREGATE(isbn || '')

  const [addReview] = useMutation(ADD_BOOK_REVIEW, HASURA_ROLE_USER_CONTEXT)

  const [updateReview] = useMutation(
    UPDATE_BOOK_REVIEW,
    HASURA_ROLE_USER_CONTEXT
  )

  React.useEffect(() => {
    let _ratingSum: number[] = new Array(5).fill(0)

    bookReviewsAggregateData?.academy_book_reviews.forEach((review: any) => {
      _ratingSum[review.rating - 1] = _ratingSum[review.rating - 1] + 1
    })

    _ratingSum = _ratingSum
      .map((rating) =>
        Math.round(
          (rating /
            bookReviewsAggregateData?.academy_book_reviews_aggregate?.aggregate
              ?.count) *
            100
        )
      )
      ?.reverse()

    setRatingSum(_ratingSum)
  }, [bookReviewsAggregateData])

  const closeAddEditReviewDialog: any = () => {
    setReviewDialogData({...reviewDialogData, isOpen: false})
  }

  const addReviewMutation = () => {
    addReview({
      variables: {
        isbn: isbn,
        rating: userRateData,
        review_text: reviewDialogData.description,
      },
    })
      .then(() => {
        enqueueSnackbar('Review successfully submitted', {
          variant: 'success',
        })
        myBookReviewRefetch()
        bookDetailRefetch()

        closeAddEditReviewDialog()
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar('Review failed to submit, please try again later', {
          variant: 'error',
        })
      })
  }

  const editReviewMutation = () => {
    updateReview({
      variables: {
        isbn: isbn,
        rating: userRateData,
        review_text: reviewDialogData.description,
        userId: USER_ID,
      },
    })
      .then(() => {
        enqueueSnackbar('Review successfully submitted', {
          variant: 'success',
        })
        myBookReviewRefetch()
        bookDetailRefetch()

        closeAddEditReviewDialog()
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar('Review failed to submit, please try again later', {
          variant: 'error',
        })
      })
  }

  if (
    (bookReviewsAggregateLoading && !bookReviewsAggregateData) ||
    myBookReviewLoading
  ) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }

  const isMyBookReviewExist = myBookReviewsData?.academy_book_reviews?.[0]

  return (
    <div className="flex flex-col space-y-4 pt-3">
      <Typography variant="body1" className="font-bold">
        Ulasan buku
      </Typography>
      <Divider />
      {isMyBook && (
        <>
          <div
            id="my-book-review-section"
            className="flex flex-col w-full space-y-2 pb-2"
          >
            <Typography className="pb-1">Ulasan Anda</Typography>

            <DisplayRatingStar
              count={myBookReviewsData?.academy_book_reviews?.[0]?.rating || 0}
              starSize="large"
            />

            <div
              className="text-sm text-gray-500 pt-1"
              dangerouslySetInnerHTML={{
                __html: Dompurify.sanitize(
                  myBookReviewsData?.academy_book_reviews?.[0]?.review_text
                ),
              }}
            />

            <TextBlue
              className="w-fit cursor-pointer text-sm"
              onClick={() => {
                setUserRateData(
                  myBookReviewsData?.academy_book_reviews?.[0]?.rating
                )
                setReviewDialogData({
                  ...reviewDialogData,
                  rate: myBookReviewsData?.academy_book_reviews?.[0]?.rating,
                  description:
                    myBookReviewsData?.academy_book_reviews?.[0]?.review_text,
                  isOpen: true,
                })
              }}
            >
              {isMyBookReviewExist ? 'Ubah ' : 'Tambahkan '} Ulasan
            </TextBlue>
          </div>
          <Divider />
        </>
      )}

      <RatingSummarySection
        ratingAvg={
          bookReviewsAggregateData?.academy_book_reviews_aggregate?.aggregate
            ?.avg?.rating
        }
        ratingCount={
          bookReviewsAggregateData?.academy_book_reviews_aggregate?.aggregate
            ?.count
        }
        ratingSum={ratingSum}
      />

      <ReviewList isbn={isbn || ''} />

      {reviewDialogData.isOpen && (
        <ReviewDialog
          userRateData={userRateData}
          setUserRateData={setUserRateData}
          reviewData={reviewDialogData}
          setReviewData={setReviewDialogData}
          featureName={isAudioBook ? 'Buku Audio' : 'Buku Elektronik'}
          submiteviewAction={
            isMyBookReviewExist ? editReviewMutation : addReviewMutation
          }
          cancelReviewAction={closeAddEditReviewDialog}
        />
      )}
    </div>
  )
}

export default TabReview
