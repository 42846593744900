// import ContributorEarningsCard from '../dashboard/ContributorEarningsCard'
// import ContributorTransactionCard from './ContributorTransactionsCard'

const ContributorEarningsPage = () => {
  return (
    <>
      {/* <ContributorEarningsCard />
      <div className="mt-6">
        <ContributorTransactionCard />
      </div> */}
    </>
  )
}

export default ContributorEarningsPage
