import {gql} from '@apollo/react-hooks'

export const GET_COURSE_REVIEWS = gql`
  query getCourseReviews($offset: Int, $limit: Int, $id: Int, $rating: Int) {
    academy_course_enrollments_aggregate(
      where: {
        _and: [
          {rating: {_eq: $rating}}
          {rating: {_is_null: false}}
          {review_date: {_is_null: false}}
        ]
        # rating: {_eq: $rating}
        course_id: {_eq: $id}
        deletedAt: {_is_null: true}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_course_enrollments(
      where: {
        _and: [
          {rating: {_eq: $rating}}
          {rating: {_is_null: false}}
          {review_date: {_is_null: false}}
        ]
        # rating: {_eq: $rating}
        course_id: {_eq: $id}
        deletedAt: {_is_null: true}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      global_user {
        id
        name
        avatar
        company_profiles {
          legal_name
          brand_name
        }
      }
      rating
      review_date
      review_text
      review_reply_date
      review_reply_text
    }
  }
`
