import React, {FC, ReactElement} from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import StarRate from '../../../review-rating/StarRate.component'

type ReviewData = {
  isOpen: boolean
  rate: number
  description: string
}

type ReviewDialogProps = {
  userRateData: number
  setUserRateData: (rate: number) => void
  reviewData: ReviewData
  setReviewData: (value: ReviewData) => void
  featureName: string
  cancelReviewAction: () => void
  submiteviewAction: () => void
}

const ReviewDialog: FC<ReviewDialogProps> = ({
  reviewData,
  setReviewData,
  featureName,
  userRateData,
  setUserRateData,
  cancelReviewAction,
  submiteviewAction,
}: ReviewDialogProps): ReactElement => {
  return (
    <Dialog
      fullWidth
      open={reviewData.isOpen}
      onClose={() => setReviewData({...reviewData, isOpen: false})}
      maxWidth="sm"
    >
      <DialogTitle className="bg-slate-200">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="h6">Ulasan</Typography>
          <Close
            className="cursor-pointer"
            onClick={() => setReviewData({...reviewData, isOpen: false})}
          />
        </div>
      </DialogTitle>
      <Divider />

      <DialogContent className="flex flex-col py-6">
        <Typography variant="subtitle1" color="primary" className="pb-2">
          Nilai {featureName}
        </Typography>
        <StarRate
          userRateData={userRateData}
          setUserRateData={setUserRateData}
        />

        <Typography variant="subtitle1" color="primary" className="py-2">
          Keterangan
        </Typography>
        <TextField
          fullWidth
          multiline
          maxRows={4}
          minRows={4}
          variant="outlined"
          placeholder="Tulis ulasan Anda"
          defaultValue={reviewData.description}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setReviewData({...reviewData, description: e?.target?.value})
          }
        />

        <div className="flex flex-row w-full justify-end items-end space-x-3 pt-3">
          <Button color="primary" onClick={cancelReviewAction}>
            Batal
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={submiteviewAction}
          >
            Kirim
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ReviewDialog
