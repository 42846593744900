import gql from 'graphql-tag'

export const UPDATE_USER_COMPETENCY = gql`
  mutation ($id: uuid!, $name: String, $description: String) {
    update_learning_diary_competencies(
      where: {id: {_eq: $id}}
      _set: {name: $name, description: $description}
    ) {
      affected_rows
    }
  }
`
