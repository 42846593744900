import {useQuery} from '@apollo/react-hooks'
import {
  GET_TOP_CONTRIBUTOR,
  GET_ASIDE_OF_TOP_CONTRIBUTOR,
  GET_DETAIL_CONTRIBUTOR_COURSE,
  GET_DETAIL_CONTRIBUTOR_BOOKS,
  GET_DETAIL_CONTRIBUTOR_EVENT,
  GET_DETAIL_CONTRIBUTOR_MICROLEARNING,
  GET_DETAIL_CONTRIBUTOR_SOCIAL_LEARNING,
  GET_CONTRIBUTOR_INFO_USER,
} from '../../graphql/queries'
import {ContributorRatingType} from '../../interfaces'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../utils/globals'

export const LIST_TOP_LEARNING_CONTRIBUTOR = ({
  limit,
  offset,
  contrib_labels = [],
  queryName = '',
  ratingGte = 0,
}: {
  limit?: number
  offset?: number
  contrib_labels?: string[]
  queryName?: string
  ratingGte?: number
}) => {
  const variables: {
    all_limit?: number
    all_offset?: number
    contrib_labels?: string[]
    queryName?: string
    totalRatingAvgCmp?: ContributorRatingType
  } = {
    all_limit: limit || 2,
    all_offset: offset || 0,
    contrib_labels,
    queryName: `%${queryName}%`,
    totalRatingAvgCmp: {
      _gte: ratingGte,
    },
  }

  if (!limit || limit === -1) {
    delete variables.all_limit
  }

  if (!offset) {
    delete variables.all_offset
  }

  if (contrib_labels.length === 0) {
    delete variables.contrib_labels
  }

  if (!queryName) {
    delete variables.queryName
  }

  if (ratingGte === 0) {
    delete variables.totalRatingAvgCmp
  }

  return useQuery(GET_TOP_CONTRIBUTOR, {
    variables,
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_ASIDE_OF_TOP_CONTRIBUTOR = (
  label: string = 'INSTRUCTOR',
  limit: number = 2,
  offset: number = 0
) => {
  return useQuery(GET_ASIDE_OF_TOP_CONTRIBUTOR, {
    variables: {
      label,
      limit,
      offset,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const DETAIL_CONTRIBUTOR_HEADER = (id?: string) => {
  return useQuery(GET_CONTRIBUTOR_INFO_USER, {
    variables: {
      userId: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_CONTRIBUTOR_COURSE = (
  search: string,
  id?: string,
  sort?: string
) => {
  let ordering

  switch (sort) {
    case 'highest':
      ordering = {
        academy_price_tier: {
          pricing_idr: 'desc_nulls_last',
        },
      }
      break
    case 'lowest':
      ordering = {
        academy_price_tier: {
          pricing_idr: 'asc_nulls_last',
        },
      }
      break
    case 'top':
      ordering = {
        average_rating: 'desc_nulls_last',
      }
      break
    case 'popular':
      ordering = {
        count_enrollment: 'desc_nulls_last',
      }
      break
    default:
      ordering = {
        date_added: 'desc_nulls_last',
      }
  }

  return useQuery(GET_DETAIL_CONTRIBUTOR_COURSE, {
    variables: {
      id,
      search: `%${search}%`,
      ordering: [ordering],
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_CONTRIBUTOR_BOOKS = (
  search: string,
  type: number,
  id?: string,
  sort?: string
) => {
  let ordering

  switch (sort) {
    case 'highest':
      ordering = {
        pricing_idr: 'desc_nulls_last',
      }
      break
    case 'lowest':
      ordering = {
        pricing_idr: 'asc_nulls_last',
      }
      break
    case 'top':
      ordering = {
        average_rating: 'desc_nulls_last',
      }
      break
    case 'popular':
      ordering = {
        count_reviews: 'desc_nulls_last',
      }
      break
    default:
      ordering = {
        date_added: 'desc_nulls_last',
      }
  }

  return useQuery(GET_DETAIL_CONTRIBUTOR_BOOKS, {
    variables: {
      id,
      search: `%${search}%`,
      ordering: [ordering],
      type,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_CONTRIBUTOR_EVENT = (
  search: string,
  id?: string,
  sort?: string
) => {
  let ordering

  switch (sort) {
    case 'highest':
      ordering = {
        pricing_idr: 'desc_nulls_last',
      }
      break
    case 'lowest':
      ordering = {
        pricing_idr: 'asc_nulls_last',
      }
      break
    case 'top':
      ordering = {
        average_rating: 'desc_nulls_last',
      }
      break
    case 'popular':
      ordering = {
        count_reviews: 'desc_nulls_last',
      }
      break
    default:
      ordering = {
        date_added: 'desc_nulls_last',
      }
  }

  return useQuery(GET_DETAIL_CONTRIBUTOR_EVENT, {
    variables: {
      id,
      search: `%${search}%`,
      ordering: [ordering],
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_CONTRIBUTOR_MICROLEARNING = (
  search: string,
  id?: string,
  sort?: string
) => {
  let ordering

  switch (sort) {
    case 'highest':
      ordering = {
        price: 'desc_nulls_last',
      }
      break
    case 'lowest':
      ordering = {
        price: 'asc_nulls_last',
      }
      break
    case 'top':
      ordering = {
        average_rating: 'desc_nulls_last',
      }
      break
    case 'popular':
      ordering = {
        review_count: 'desc_nulls_last',
      }
      break
    default:
      ordering = {
        date_added: 'desc_nulls_last',
      }
  }

  return useQuery(GET_DETAIL_CONTRIBUTOR_MICROLEARNING, {
    variables: {
      id,
      search: `%${search}%`,
      ordering: [ordering],
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_CONTRIBUTOR_SOCIAL_LEARNING = (
  search: string,
  id?: string,
  sort?: string
) => {
  let ordering

  switch (sort) {
    case 'highest':
      ordering = {
        price: 'desc_nulls_last',
      }
      break
    case 'lowest':
      ordering = {
        price: 'asc_nulls_last',
      }
      break
    case 'top':
      ordering = {
        average_rating: 'desc_nulls_last',
      }
      break
    case 'popular':
      ordering = {
        review_count: 'desc_nulls_last',
      }
      break
    default:
      ordering = {
        date_added: 'desc_nulls_last',
      }
  }

  return useQuery(GET_DETAIL_CONTRIBUTOR_SOCIAL_LEARNING, {
    variables: {
      userId: USER_ID,
      id,
      search: `%${search}%`,
      ordering: [ordering],
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}
