import {type ReactNode} from 'react'
import {useField, UseFieldConfig} from 'react-final-form'

import {useDropzone} from 'react-dropzone'

import {Button, FormHelperText, Paper, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {showErrorOnChange} from '../../../../../../components/input-forms/validators'
import CloseIcon from '@material-ui/icons/Close'

export type Poster = string | File

export interface PosterFieldProps {
  name: string
  validate?: UseFieldConfig<Poster[]>['validate']
  label?: string
  description?: ReactNode
}

const objectMapping = new WeakMap<File, string>()

const PosterField = (props: PosterFieldProps) => {
  const {name, validate, label, description} = props

  const {input, meta} = useField<Poster[]>(name, {
    validate,
    format: (v) => {
      if (!Array.isArray(v)) {
        return []
      }

      return v
    },
  })

  const {value, onChange} = input
  const {error, submitError} = meta
  const isError = showErrorOnChange(meta)

  const dropzone = useDropzone({
    disabled: meta.submitting,
    accept: {
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/png': ['.png'],
    },
    onDrop: (files) => {
      const next = value.slice()

      for (const file of files) {
        const localUrl = URL.createObjectURL(file)
        objectMapping.set(file, localUrl)

        next.push(file)
      }

      onChange(next)
    },
  })

  const handleRemove = (idx: number) => {
    const next = value.slice()
    next.splice(idx, 1)

    onChange(next)
  }

  return (
    <div>
      <Typography
        component="label"
        color={isError ? 'error' : 'textPrimary'}
        className="block text-sm"
        gutterBottom
      >
        {label}
      </Typography>

      {description && (
        <Typography color="textSecondary" className="mb-2 text-sm">
          {description}
        </Typography>
      )}

      <input {...dropzone.getInputProps()} />

      <div className="flex gap-2">
        <Button
          variant={dropzone.isDragAccept ? 'outlined' : 'contained'}
          {...dropzone.getRootProps({className: `h-16 w-16 bg-white`})}
        >
          <AddIcon fontSize="large" color="secondary" />
        </Button>

        <div className="flex gap-2 flex-wrap">
          {value.map((file, idx) => (
            <Paper key={idx} elevation={2} className="overflow-hidden relative">
              <img
                className="h-16 aspect-video object-cover"
                src={file instanceof File ? objectMapping.get(file) : file}
              />

              <Button
                onClick={() => handleRemove(idx)}
                className="absolute top-0 right-0 min-w-0 p-1"
              >
                <CloseIcon fontSize="small" />
              </Button>
            </Paper>
          ))}
        </div>
      </div>

      {isError ? (
        <FormHelperText error className="mt-2">
          {error || submitError}
        </FormHelperText>
      ) : null}
    </div>
  )
}

export default PosterField
