import {useField} from 'react-final-form'

export const LOCAL_PREFIX = `local:`

export const EMPTY_ARRAY: unknown[] = []

export enum EditState {
  NONE = 1,
  EDITING,
  ADDING,
}

export enum LessonType {
  ARTICLE = 'article',
  QUIZ = 'quiz',
  VIDEO = 'video',
}

export const getInterimId = () => {
  return LOCAL_PREFIX + (crypto as any).randomUUID()
}

export const isInterimId = (id: string | number) => {
  return typeof id === 'string' && id.startsWith(LOCAL_PREFIX)
}

export const useFieldValue = <V = any>(name: string) => {
  const {input} = useField<V>(name, {
    subscription: {value: true},
  })

  return input.value
}

const padInt = (value: number, length: number, fill: string) => {
  return ('' + value).padStart(length, fill)
}

export const formatDuration = (duration: number) => {
  const h = ~~(duration / 3600)
  const m = ~~((duration - h * 3600) / 60)
  const s = duration - h * 3600 - m * 60

  return `${padInt(h, 2, '0')}:${padInt(m, 2, '0')}:${padInt(s, 2, '0')}}`
}
