import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Form} from 'react-final-form'
import {Button, Divider, IconButton, Paper, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'
import FormLoading from '../../../../../components/input-forms/FormLoading'
import axios, {type AxiosRequestConfig} from 'axios'
import {type FormApi} from 'final-form'
import FormText from '../../../../../components/input-forms/FormText'
import FormSelect, {
  SelectItem,
} from '../../../../../components/input-forms/FormSelect'
import FormPrice from '../../../../../components/input-forms/FormPrice'
import {isRequired} from '../../../../../components/input-forms/validators'
import AcademyCategoryFields from '../../../../../components/contributor/forms/AcademyCategoryFields'
import {languageOptionsGQL, levelOptions} from '../../../../../utils/constans'
import {useApolloClient, useQuery} from '@apollo/client'
import {GET_CONTRIBUTOR_BOOK_LANDING_DETAILS} from '../../../../../graphql/queries'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {UPLOAD_URL, USER_ID} from '../../../../../utils/globals'
import ImageThumbnailField, {
  type ImageThumbnail,
} from '../../../../../components/contributor/forms/ImageThumbnailField'
import {useSnackbar} from 'notistack'
import {UPDATE_BOOK_LANDING_DETAILS} from '../../../../../graphql/mutations/contributor/books.mutation'

type BookProps = {
  type: string
}

interface FormData {
  id: number
  title: string
  description: string
  language: string
  level: number
  category: number
  subcategory: number
  price_tier: number
  thumbnail: ImageThumbnail
  edition: number
  isbn: string
  copyright_year: number
  author_written: string
  publisher_written: string
}
const LANGUAGES: SelectItem[] = [
  {
    value: '',
    label: 'Select language',
    disabled: true,
  },
  ...languageOptionsGQL,
]

const LEVELS: SelectItem[] = [
  {
    value: '',
    label: 'Select level',
    disabled: true,
  },
  ...levelOptions,
]
const ContributorBookManageLandingPage = ({type}: BookProps) => {
  const {bookSlug} = useParams()
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [submitProgress, setSubmitProgress] = React.useState<number>()
  const {data, loading} = useQuery(GET_CONTRIBUTOR_BOOK_LANDING_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      owner: USER_ID,
      isbn: bookSlug,
    },
    context: INSTRUCTOR_CONTEXT,
  })
  console.info(data)
  const initialValues = React.useMemo(() => {
    if (!data || data.academy_books.length < 1) {
      return
    }

    const book = data.academy_books[0]

    const formData: FormData = {
      id: book.id,
      title: book.title,
      description: book.summary || undefined,
      language: (book.language !== '0' && book.language) || undefined,
      level: book.level || undefined,
      category: book.academy_course_category.academy_course_category?.id,
      subcategory: book.academy_course_category?.id,
      price_tier: book.pricing_idr,
      thumbnail: book.thumbnail || undefined,
      edition: book.edition,
      isbn: book.isbn,
      copyright_year: book.copyright_year,
      author_written: book.author_written,
      publisher_written: book.publisher_written,
    }

    return formData
  }, [data])
  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  const submit = async (data: FormData, form: FormApi<any, any>) => {
    console.info(data)

    let count = 0
    let current = 0

    let thumbnail = data.thumbnail

    const axiosConfig: AxiosRequestConfig<unknown> = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      withCredentials:true,
      onUploadProgress: (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total)
        const offset = (100 / count) * current

        setSubmitProgress(offset + percentage / count)
      },
    }

    // NOTE(intrnl): set the `count` which is the total
    if (thumbnail instanceof Blob) {
      count++
    }

    if (count > 0) {
      setSubmitProgress(0)
    }

    // NOTE(intrnl): now start uploading
    if (thumbnail instanceof Blob) {
      const thumbForm = new FormData()
      thumbForm.append('file', thumbnail)

      try {
        const response = await axios.post(UPLOAD_URL!, thumbForm, axiosConfig)

        const url = response.data.url
        form.change('thumbnail', (thumbnail = url))
      } catch {
        setSubmitProgress(undefined)
        enqueueSnackbar(`Failed to upload course thumbnail, try again later`, {
          variant: 'error',
        })

        return
      }

      current++
    }

    // NOTE(intrnl): we're done here, so let's set it back to indeterminate
    if (count > 0) {
      setSubmitProgress(undefined)
    }

    const promise = client.mutate({
      mutation: UPDATE_BOOK_LANDING_DETAILS,
      variables: {
        isbn: data.isbn,
        objects: {
          book_type: type === 'ebooks' ? 2 : 3,
          title: data.title,
          summary: data.description,
          language: '' + data.language,
          level: data.level,
          category: data.subcategory,
          price_tier: data.price_tier,
          thumbnail: thumbnail,
          copyright_year: data.copyright_year,
          author_written: data.author_written,
          publisher_written: data.publisher_written,
          isbn: data.isbn,
          edition: data.edition,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    return promise.catch(() => {
      enqueueSnackbar(`Failed to update course landing`, {variant: 'error'})
    })
  }

  return (
    <Form onSubmit={submit} initialValues={initialValues}>
      {({handleSubmit, pristine, submitting}) => (
        <Paper component="form" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 px-6 py-1">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              {type === 'ebooks' ? 'E-Book' : 'Audiobook'} Landing Page
            </Typography>

            <Button
              disabled={pristine || submitting}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col gap-4 p-6">
            {(submitting || loading) && (
              <FormLoading progress={submitProgress} />
            )}
            <FormText
              name="title"
              label={`${type === 'ebooks' ? 'E-Book' : 'Audiobook'} Title*`}
              fullWidth
              showCharLimit={150}
            />

            <FormSelect
              name="language"
              validate={isRequired}
              parse={Number}
              label="Language*"
              options={LANGUAGES}
              className="text-sm sm:max-w-xs"
            />

            <FormSelect
              name="level"
              validate={isRequired}
              parse={Number}
              label="Level*"
              options={LEVELS}
              className="text-sm sm:max-w-xs"
            />

            <div className="flex flex-col gap-4 sm:max-w-xs">
              <AcademyCategoryFields showMark />
            </div>

            <FormPrice
              name="price_tier"
              validate={isRequired}
              label="Price*"
              className="text-sm sm:max-w-xs"
            />

            <FormText
              name="isbn"
              label={`${type === 'ebooks' ? 'E-Book' : 'Audiobook'} ISBN*`}
              fullWidth
            />

            <FormText
              name="edition"
              label={`${type === 'ebooks' ? 'E-Book' : 'Audiobook'} Edition*`}
              fullWidth
            />
            <FormText
              name="copyright_year"
              label="Copyright Year*"
              className="text-sm sm:max-w-xs"
            />
            <FormText
              name="author_written"
              label="Add Author Written*"
              fullWidth
            />
            <FormText
              name="publisher_written"
              label="Add Publisher Written*"
              fullWidth
            />

            <FormText
              name="description"
              validate={isRequired}
              label="Description*"
              fullWidth
              multiline
              minRows={5}
            />

            <div className="grid gap-4 xl:grid-cols-2 xl:gap-8">
              <ImageThumbnailField
                name="thumbnail"
                label={`${type === 'ebooks' ? 'E-Book' : 'Audiobook'} Image*`}
                buttonLabel={`${
                  type === 'ebooks' ? 'e-book' : 'audiobook'
                } Edition*`}
                description={
                  <>
                    Resolution Recommendation: 1600 x 2560 pixels
                    <br />
                    Type File: jpg, jpeg, png
                    <br />
                    Max Size: 25 MB
                  </>
                }
              />
            </div>
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorBookManageLandingPage
