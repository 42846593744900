import {Menu, MenuItem} from '@material-ui/core'
import {MoreHoriz} from '@material-ui/icons'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {CustomTypography} from '../../../../components/utils-components/GlobalStyles'
import DeletePopup from '../../../shared-component/popup/DeletePopup'
import {useQuery, useMutation} from '@apollo/client'
import {LIST_COMPETENCY_ADDED_BY_USER} from '../../../../graphql/queries'
import {DELETE_COMPETENCY_BY_USER} from '../../../../graphql/mutations'
import {USER_ID} from '../../../../utils/globals'
import {format} from 'date-fns'
import {useSnackbar} from 'notistack'
import {ICompetencyUser} from '../../../../interfaces/Competency.interface'

import NoListComponent from '../../../../components/empty-state/NoList.component'

const INITIAL_DELETE_STATE = {id: '', name: '', countDiary: 0}
const AddedByUser = () => {
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteData, setDeleteData] = useState(INITIAL_DELETE_STATE)

  const [deleteCompetency] = useMutation(DELETE_COMPETENCY_BY_USER)

  const {data, refetch} = useQuery(LIST_COMPETENCY_ADDED_BY_USER, {
    variables: {
      userId: USER_ID,
    },
  })

  useEffect(() => {
    if (refetch) refetch()
  }, [refetch])

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleDelete = () => {
    handleClose()
    deleteCompetency({variables: {id: deleteData.id}}).then(() => {
      refetch()
      enqueueSnackbar('Success delete competence', {
        variant: 'success',
      })
      setDeleteData(INITIAL_DELETE_STATE)
    })
  }

  return (
    <div>
      <div className="flex items-center justify-between">
        <CustomTypography fsize="14px" fweight="bold">
          Daftar Kompetensi
        </CustomTypography>
        <button
          onClick={() => navigate('/profile/competence/add')}
          className="text-white bg-[#014a62] text-sm lg:text-base px-2 lg:px-6 py-2 lg:py-3 rounded-md"
        >
          Tambah Kompetensi Baru
        </button>
      </div>
      <div className="mt-6">
        {data?.learning_diary_competencies.length > 0 ? (
          data.learning_diary_competencies.map((item: ICompetencyUser) => (
            <div key={item.id}>
              <hr className="mb-6" />
              <div className="flex items-center justify-between">
                <CustomTypography fsize="14px" fweight="bold">
                  {item.name}
                </CustomTypography>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <MoreHoriz
                        style={{
                          fontSize: '16px',
                          color: '#a9a8a8',
                          cursor: 'pointer',
                        }}
                        {...bindTrigger(popupState)}
                      >
                        more_horiz
                      </MoreHoriz>
                      <Menu
                        {...bindMenu(popupState)}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          style: {
                            transform: 'translateX(40px) translateY(40px)',
                          },
                        }}
                      >
                        <MenuItem
                          onClick={() => {
                            navigate(`/profile/competence/edit/${item.id}`)
                            popupState.close()
                          }}
                        >
                          Ubah
                        </MenuItem>
                        <MenuItem
                          style={{color: '#ff6666'}}
                          onClick={() => {
                            setDeleteData({
                              id: item.id,
                              name: item.name,
                              countDiary: item.count_diary.aggregate.count,
                            })
                            setOpenDelete(true)
                            popupState.close()
                          }}
                        >
                          Hapus
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              </div>
              <CustomTypography
                fsize="12px"
                fcolor="#a9a8a8"
                mt="8px"
                mb="12px"
              >
                Terakhir diubah:{' '}
                {format(new Date(item?.date_added || ''), 'MMMM dd, yyyy')}
              </CustomTypography>
              <CustomTypography fsize="14px" mb="15px">
                {item.description}
              </CustomTypography>
            </div>
          ))
        ) : (
          <div className="mt-4">
            <NoListComponent title="Maaf, tidak ada daftar" imageSize={180} />
          </div>
        )}
        <DeletePopup
          open={openDelete}
          handleClose={handleClose}
          mutation={handleDelete}
          name={deleteData.name}
          checkboxText={`I understand that deleting this competence is permanent and cannot be undone. ${
            deleteData.countDiary
              ? 'And it will also have an impact on the learning diary that uses this competency.'
              : ''
          }`}
        />
      </div>
    </div>
  )
}

export default AddedByUser
