import React from 'react'

import {Grid, Button, makeStyles} from '@material-ui/core'
import classNames from 'classnames'
import {LearningType} from '../../../../utils/enum'
import TabRow from './TabRow.component'
// import {RecommendedBookCategoryRow} from './CategoryRow.component'
import {LearnerBookContext} from '../../../../provider/LearnerBook.provider'

type BookTabProps = {
  isSearchFilter?: boolean
}

const useStyles = makeStyles(() => ({
  buttonSwitchType: {
    width: 134,
    height: 45,
  },
  categoryContainer: {
    padding: 15,
    backgroundColor: '#eff2f4',
    marginTop: 50,
    marginBottom: 50,
    marginLeft: 15,
    marginRight: 15,
  },
}))

function BookTab(props: BookTabProps) {
  const classes = useStyles()
  const learnerBookContext = React.useContext(LearnerBookContext)

  return (
    <Grid container className="lg:p-5 p-1">
      <div className="mb-10 ml-4 lg:ml-0">
        <Button
          variant="contained"
          color={
            learnerBookContext.reducerState.bookType === 2
              ? 'primary'
              : 'default'
          }
          className={classes.buttonSwitchType}
          onClick={() =>
            learnerBookContext.changePageLimit(
              0,
              4,
              'CHANGE_PAGE_LIMIT_MY_BOOKS',
              0,
              2
            )
          }
        >
          Buku Elektronik
        </Button>
        <Button
          variant="contained"
          color={
            learnerBookContext.reducerState.bookType === 3
              ? 'primary'
              : 'default'
          }
          className={classNames(classes.buttonSwitchType, 'ml-8')}
          onClick={() =>
            learnerBookContext.changePageLimit(
              0,
              4,
              'CHANGE_PAGE_LIMIT_MY_BOOKS',
              0,
              3
            )
          }
        >
          Buku Audio
        </Button>
      </div>
      {!props.isSearchFilter && (
        <>
          <TabRow
            title={
              learnerBookContext.reducerState.bookType === 2
                ? 'Buku Elektronik Saya'
                : 'Buku Audio Saya'
            }
            data={learnerBookContext.myBooks.academy_books}
            type={LearningType.BOOK}
          />
          <TabRow
            title="Sedang Tren di SmartASN"
            data={learnerBookContext.bookTrendings.data}
            type={LearningType.BOOK}
          />
          <TabRow
            title="Rekomendasi untuk Anda"
            data={learnerBookContext.bookRecommended.data}
            type={LearningType.BOOK}
          />
          {/* <Grid item sm={12} className={classes.categoryContainer}>
            <RecommendedBookCategoryRow />
          </Grid> */}
        </>
      )}

      <TabRow
        title={
          props.isSearchFilter
            ? ''
            : learnerBookContext.reducerState.bookType === 2
            ? 'Semua Buku Elektronik'
            : 'Semua Buku Audio'
        }
        data={learnerBookContext.bookAll.data}
        type={LearningType.BOOK}
        isAll={true}
      />
    </Grid>
  )
}

export default BookTab
