import React from 'react'
// import {Typography} from '@material-ui/core'
import Lesson from '../course/lesson/Lesson.component'
import Tab from '../course/tab/Tab.component'
import {ICourse} from '../../../interfaces/Course.interface'

type CourseDetailProps = {
  course: ICourse
  refetchCourseDetail: () => void
  isPreview?: boolean
}

const CourseDetail = (props: CourseDetailProps) => {
  return (
    <div className="flex flex-col">
      <Lesson
        course={props.course}
        refetchCourseDetail={props.refetchCourseDetail}
        isPreview={props.isPreview}
      />
      <Tab
        course={props.course}
        refetch={props.refetchCourseDetail}
        isPreview={props.isPreview}
      />
    </div>
  )
}

export default CourseDetail
