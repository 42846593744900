import React from 'react'
import ReactJWPlayer from 'react-jw-player'
import {GET_COURSE_VIDEO_PREVIEW} from '../../.././../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import {createStyles, makeStyles} from '@material-ui/core'

type VideoLessonProps = {
  slug?: string
  video: string
  onFinish?: () => void
}

const useStyles = makeStyles(() =>
  createStyles({
    videoPreview: {},
  })
)

const VideoLesson = (props: VideoLessonProps) => {
  const classes = useStyles()
  const {
    data: dataVideoPreview,
    isLoading: loadingVideoPreview,
    refetch: refetchVideoPreview,
    error: errorVideoPreview,
  } = GET_COURSE_VIDEO_PREVIEW(props.slug, props.video)

  React.useEffect(() => {
    refetchVideoPreview()
  }, [props.video, refetchVideoPreview])

  const onOneHundredPercent = () => {
    if (props.onFinish) {
      props.onFinish()
    }
  }

  if (loadingVideoPreview) {
    return <Skeleton height={300} className="mx-4 my-2" width={'50vw'} />
  }

  if (errorVideoPreview) {
    return (
      <div className="h-[300px] flex flex-col justify-center">
        <div className="text-center italic text-gray-400">
          There was an error loading the video
        </div>
      </div>
    )
  }

  return (
    <ReactJWPlayer
      playerId={'1gQfwd2J'}
      playerScript={'https://cdn.jwplayer.com/libraries/1gQfwd2J.js'}
      playlist={dataVideoPreview.url?.playlist || []}
      className={classes.videoPreview}
      onOneHundredPercent={onOneHundredPercent}
    />
  )
}

export default VideoLesson
