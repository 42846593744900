import {gql} from '@apollo/client'

export const GET_LEARNING_DIARY_COMPETENCY = gql`
  query ($user: uuid!, $sort: order_by) {
    learning_diary_competencies(
      where: {
        learning_diaries: {user_id: {_eq: $user}}
        deleted_at: {_is_null: true}
      }
      order_by: [{learning_diaries_aggregate: {sum: {duration: $sort}}}]
    ) {
      id
      name
      learning_diaries_aggregate {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_course: learning_diaries_aggregate(where: {activity: {_eq: 1}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_ebook: learning_diaries_aggregate(where: {activity: {_eq: 2}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_audiobook: learning_diaries_aggregate(where: {activity: {_eq: 3}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_others: learning_diaries_aggregate(where: {activity: {_eq: 9}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
    }
    competency_dictionaries(
      where: {
        learning_diaries: {user_id: {_eq: $user}}
        deleted_at: {_is_null: true}
      }
      order_by: [{learning_diaries_aggregate: {sum: {duration: $sort}}}]
    ) {
      id
      name
      learning_diaries_aggregate {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_course: learning_diaries_aggregate(where: {activity: {_eq: 1}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_ebook: learning_diaries_aggregate(where: {activity: {_eq: 2}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_audiobook: learning_diaries_aggregate(where: {activity: {_eq: 3}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
      count_others: learning_diaries_aggregate(where: {activity: {_eq: 9}}) {
        aggregate {
          sum {
            duration
          }
        }
      }
    }
  }
`
