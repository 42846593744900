import {useMutation} from '@apollo/client'
import {useSnackbar} from 'notistack'
import {useNavigate} from 'react-router-dom'
import AcademyCategoryFields from '../../../components/contributor/forms/AcademyCategoryFields'
import CreateWizard from '../../../components/input-forms/CreateWizard'
import FormValidatedText from '../../../components/input-forms/FormValidatedText'
import {
  combineValidators,
  isRequired,
} from '../../../components/input-forms/validators'
import {CONTRIBUTOR_CREATE_BOOK} from '../../../graphql/mutations/contributor/books.mutation'
import {INSTRUCTOR_CONTEXT} from '../../../utils/contributor-helpers'
import {USER_ID} from '../../../utils/globals'
import {capitalize} from '../../../utils/helpers'

interface FormValues {
  title: string
  category: number
  subcategory: number
  isbn: string
}

type BookProps = {
  type: string
}

const ContributorBookCreatePage = ({type}: BookProps) => {
  const navigate = useNavigate()
  const {enqueueSnackbar} = useSnackbar()
  const [createBook] = useMutation(CONTRIBUTOR_CREATE_BOOK, {
    context: INSTRUCTOR_CONTEXT,
  })

  const validateBookTitle = combineValidators(
    isRequired,

    (value: string) =>
      value.length < 5 &&
      `${
        type === 'ebook' ? 'E-Book' : capitalize(type)
      } title is too short, it must be 5 characters or longer`,

    (value: string) =>
      value.length > 150 &&
      `${
        type === 'ebook' ? 'E-Book' : capitalize(type)
      } title is too long, only 150 characters can be submitted`
  )

  const handleSubmit = (data: FormValues) => {
    createBook({
      variables: {
        objects: {
          category: data.subcategory,
          title: data.title,
          owner: USER_ID,
          status: 'draft',
          book_type: type === 'ebook' ? 2 : 3,
          isbn: data.isbn,
        },
      },
    })
      .then((res: any) => {
        const id = res.data.insert_academy_books.returning[0].isbn
        navigate(
          `/contributor/${type === 'ebook' ? 'ebooks' : 'audiobooks'}/${id}`
        )
      })
      .catch(() => {
        enqueueSnackbar('Add Book failed', {
          variant: 'error',
        })
      })
  }

  return (
    <CreateWizard
      onSubmit={handleSubmit}
      onCancel={() => navigate(-1)}
      title={`Create ${type === 'ebook' ? 'E-Book' : capitalize(type)}`}
    >
      <CreateWizard.Page
        label={`${type === 'ebook' ? 'E-Book' : capitalize(type)} Title`}
        title={`How About ${
          type === 'ebook' ? 'E-Book' : capitalize(type)
        } Title?`}
        description="It's ok if you can't think of a good title now. You can change it later."
      >
        <FormValidatedText
          name="title"
          label={`${type === 'ebook' ? 'E-Book' : capitalize(type)} Title`}
          placeholder={`Add ${type === 'ebook' ? 'e-book' : type} title`}
          validate={validateBookTitle}
          showCharLimit={150}
          fullWidth
          autoFocus
        />
      </CreateWizard.Page>

      <CreateWizard.Page
        label={`${type === 'ebook' ? 'E-Book' : capitalize(type)} Category`}
        title={`What category best fits the ${
          type === 'ebook' ? 'e-book' : type
        } you'll create?`}
        description="It's ok if you can't think of a good category now. You can change it later."
      >
        <AcademyCategoryFields autoFocus />
      </CreateWizard.Page>

      <CreateWizard.Page
        label={`${type === 'ebook' ? 'E-Book' : capitalize(type)} ISBN`}
        title={`What is the ISBN number of your ${
          type === 'ebook' ? 'e-book' : type
        }`}
        description="it's ok if you can't think of a right ISBN now. You can change it later"
      >
        <FormValidatedText
          name="isbn"
          validate={isRequired}
          label={`${type === 'ebook' ? 'E-Book' : capitalize(type)} ISBN`}
          placeholder={`Add ${type === 'ebook' ? 'e-book' : type} ISBN`}
          fullWidth
          autoFocus
        />
      </CreateWizard.Page>
    </CreateWizard>
  )
}

export default ContributorBookCreatePage
