import {gql} from '@apollo/react-hooks'

export const GET_EVENT_DETAIL_BY_ID = gql`
  query ($id: Int!) {
    event_schedules_by_pk(id: $id) {
      event_category {
        id
        name
      }
      title
      thumbnail
      pricing_idr
      location_info
      datestart
      dateend
      description
      author: global_user {
        id
        name
      }
      contributor: global_user {
        id
      }
      event_type {
        id
        name
      }
      event_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
        }
      }
    }
  }
`

export const GET_UPCOMING_EVENT = gql`
  query ($creator: uuid!, $limit: Int, $offset: Int) {
    event_schedules(
      where: {creator: {_eq: $creator}, datestart: {_gt: "now()"}}
      offset: $offset
      limit: $limit
    ) {
      id
      title
      thumbnail
      summary
      description
      dateStart: datestart
      dateEnd: dateend
      location_info
      status
      pricing
      creator_user: global_user {
        id
        name
        email
        biography
        avatar
        status
      }
      EventType: event_type {
        id
        code
        name
        thumbnail
        fa_class
        date_added
        last_modified
      }
      EventCategory: event_category {
        parent
        code
        name
        thumbnail
        fa_class
        date_added
        last_modified
      }
    }
    event_schedules_aggregate(
      where: {creator: {_eq: $creator}, datestart: {_gt: "now()"}}
    ) {
      aggregate {
        count
      }
    }
  }
`
