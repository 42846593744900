import React from 'react'
import MyCertificateCard from '../../../../components/card/MyCertificateCard.component'
import NoListComponent from '../../../../components/empty-state/NoList.component'
import LoadingCircular from '../../../../components/loading/LoadingCircular.component'
import {ICertificate} from '../../../../interfaces'
import {MY_CERTICIFATE_LIST} from '../../../../provider/services/Course.service'

const MyCourseCertificateComponent = ({
  search = '',
  sort = '',
  filterData,
}: ICertificate) => {
  const _sort = sort.split('-')
  const order_by =
    _sort[0] === 'date' ? {date_added: _sort[1]} : {course_name: _sort[1]}

  if (filterData?.language) {
    filterData.language = filterData.language.map((languageId) =>
      languageId.toString()
    )
  }

  const {data, error, loading} = MY_CERTICIFATE_LIST({
    search: `%${search}%`,
    order_by: [order_by],
    filterData: filterData,
  })

  if (loading) {
    return <LoadingCircular height="500px" />
  }

  if (error) console.error(error)

  return data?.academy_certificates?.length === 0 ? (
    <div className="flex flex-col justify-center" style={{height: 500}}>
      <NoListComponent
        imageSize={200}
        title={search ? 'Sorry, Result Not Found' : 'Sorry, No List'}
        subTitle={
          search
            ? "It seems we can't find any results based on your search"
            : 'Currently you do not have course certificate'
        }
      />
    </div>
  ) : (
    <div className="space-y-5">
      {data?.academy_certificates?.map((value: any) => {
        return (
          <MyCertificateCard
            id={value.id}
            key={value.id}
            cert_name={value.course_name}
            cert_no={value.certificate_no}
            cert_url={value.certificate_url}
            instructor_name={value.instructors?.[0]?.name}
            thumbnail={
              value.academy_course_enrollment?.academy_course?.thumbnail
            }
          />
        )
      })}
    </div>
  )
}

export default MyCourseCertificateComponent
