import FormText, {type FormTextProps} from './FormText'

export interface FormValidatedTextProps
  extends Omit<FormTextProps, 'format' | 'parse'> {}

const RE_INVALID = /(<\/?[a-z]+\s*)>/i

const FormValidatedText = (props: FormValidatedTextProps) => {
  return FormText({
    onChange: (ev) => {
      if (RE_INVALID.test(ev.target.value)) {
        ev.preventDefault()
      }
    },
    ...props,
  })
}

export default FormValidatedText
