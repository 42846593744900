import React, {FC, ReactElement} from 'react'
import {Icon} from '@material-ui/core'
import useStyles from '../utils-components/GlobalStyles'

type StarRateProps = {
  userRateData: number
  setUserRateData: (rate: number) => void
  starSize?: 'small' | 'medium' | 'large' | undefined
}

const StarRate: FC<StarRateProps> = ({
  userRateData,
  setUserRateData,
  starSize = 'large',
}: StarRateProps): ReactElement => {
  const classes = useStyles()
  const [hover, setHover] = React.useState<number>(0)

  return (
    <div className="star-rating">
      {[...Array(5)].map((_, index) => {
        index += 1
        return (
          <Icon
            key={`star-rating-${index}`}
            className={
              index <= (hover || userRateData)
                ? classes.skyBlueColor
                : classes.greyColor
            }
            fontSize={starSize}
            onClick={() => setUserRateData(index)}
            onDoubleClick={() => {
              setUserRateData(0)
              setHover(0)
            }}
            onMouseEnter={() => setHover(index)}
            onMouseLeave={() => setHover(userRateData)}
          >
            star
          </Icon>
        )
      })}
    </div>
  )
}

export default StarRate
