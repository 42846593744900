import {gql} from '@apollo/client'

export const GET_DETAIL_CONTRIBUTOR_COURSE = gql`
  query ($id: uuid!, $search: String, $ordering: [academy_courses_order_by!]!) {
    academy_courses(
      where: {
        creator: {_eq: $id}
        title: {_ilike: $search}
        subtitle: {_ilike: $search}
        status: {_eq: "active"}
      }
      order_by: $ordering
    ) {
      id
      title
      subtitle
      thumbnail
      description
      level
      language
      topic
      goals
      CreatorUser: global_user {
        id
        name
      }
      averageRating: average_rating
      countEnrollment: count_enrollment
      Price: academy_price_tier {
        id
        pricing_idr
      }
      AcademyCourseCategory: academy_course_category {
        id
        name
      }
      slug
      video
    }
  }
`
