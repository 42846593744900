import {Dispatch} from 'react'
import {
  IStateLearnerReducerProps,
  PriceType,
  RatingType,
} from '../../interfaces'
import {LearningType} from '../../utils/enum'

export const LearnerReducer = (state: any, action: any) => {
  const {payload, type} = action

  switch (type) {
    case 'CHANGE_PAGE_LIMIT_ACADEMIC_CATEGORIES':
      return {
        ...state,
        pageAcademicCategories: payload.page,
        limitAcademicCategories: payload.limit,
      }
    case 'CHANGE_PAGE_LIMIT_MY_COURSE':
      return {
        ...state,
        course: {
          ...state.course,
          pageMyCourse: payload.page,
          limitMyCourse: payload.limit,
        },
      }
    case 'CHANGE_PAGE_LIMIT_TRENDING_COURSE':
      return {
        ...state,
        course: {
          ...state.course,
          offsetTrendingCourse: payload.offset,
          limitTrendingCourse: payload.limit,
        },
      }
    case 'CHANGE_PAGE_LIMIT_ALL_COURSES':
      return {
        ...state,
        course: {
          ...state.course,
          limitAllCourse: payload.limit,
          // offsetAllCourse: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_ALL_COURSES':
      return {
        ...state,
        course: {
          ...state.course,
          offsetAllCourse: payload.offset,
        },
      }

    case 'CHANGE_PAGE_LIMIT_RECOMMENDED_COURSE':
      return {
        ...state,
        course: {
          ...state.course,
          offsetRecommendedCourse: payload.offset,
          limitRecommendedCourse: payload.limit,
        },
      }
    case 'CHANGE_PAGE_LIMIT_MY_BOOKS':
      return {
        ...state,
        pageMyBooks: payload.page,
        limitMyBooks: payload.limit,
        offsetMyBooks: payload.offset,
        bookType: payload.catalogType,
        isSkipMyBooks: payload.isSkip,
      }
    case 'CHANGE_PAGE_LIMIT_TRENDING_BOOKS':
      return {
        ...state,
        pageTrendingBooks: payload.page,
        limitTrendingBooks: payload.limit,
      }
    case 'CHANGE_PAGE_LIMIT_RECOMMENDED_BOOKS':
      return {
        ...state,
        pageRecommendedBooks: payload.page,
        limitRecommendedgBooks: payload.limit,
      }
    case 'CHANGE_LIMIT_ALL_BOOKS':
      return {
        ...state,
        limitAllBooks: payload.limit,
      }

    case 'CHANGE_PAGE_LIMIT_EVENTS_NEARBY':
      return {
        ...state,
        pageEventsNearby: payload.page,
        limitEventsNearby: payload.limit,
      }
    case 'CHANGE_PAGE_LIMIT_EVENTS_FOR_YOU':
      return {
        ...state,
        pageEventsForYou: payload.page,
        limitEventsForYou: payload.limit,
      }
    case 'CHANGE_POSITION_EVENTS_NEARBY':
      return {
        ...state,
        eventsNearbyLat: payload.lat,
        eventsNearbyLon: payload.lon,
      }
    case 'CHANGE_PAGE_LIMIT_ALL_EVENTS':
      return {
        ...state,
        limitAllEvents: payload.limit,
      }
    case 'CHANGE_ACTIVE_CATALOG_TAB':
      return {
        ...state,
        activeCatalogTab: payload.activeCatalog,
      }
    case 'CHANGE_PAGE_MY_SOCIAL_LEARNING':
      return {
        ...state,
        socialLearning: {
          ...state.socialLearning,
          offsetMySocialLearning: payload.offset,
          limitSocialLearning: payload.limit,
        },
      }
    case 'CHANGE_OFFSET_TRENDING_SOCIAL_LEARNING':
      return {
        ...state,
        socialLearning: {
          ...state.socialLearning,
          offsetTrending: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_RECOMMENDED_SOCIAL_LEARNING':
      return {
        ...state,
        socialLearning: {
          ...state.socialLearning,
          offsetRecommended: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_PUBLIC_SOCIAL_LEARNING':
      return {
        ...state,
        socialLearning: {
          ...state.socialLearning,
          offsetGlobal: payload.offset,
        },
      }
    case 'RESET_BOOK_PAGINATION':
      return {
        ...state,
        pageTrendingBooks: 1,
        pageRecommendedBooks: 1,
        offsetMyBooks: 0,
      }
    case 'CHANGE_OFFSET_MY_MICROLEARNING':
      return {
        ...state,
        microlearning: {
          ...state.microlearning,
          offsetMyMicroLearning: payload.offset,
          limitMicroLearning: payload.limit,
        },
      }
    case 'CHANGE_OFFSET_TRENDING_MICROLEARNING':
      return {
        ...state,
        microlearning: {
          ...state.microlearning,
          offsetTrendingMicroLearning: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_RECOMMENDED_MICROLEARNING':
      return {
        ...state,
        microlearning: {
          ...state.microlearning,
          offsetRecommendedMicroLearning: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_ALL_MICROLEARNING':
      return {
        ...state,
        microlearning: {
          ...state.microlearning,
          offsetAllMicroLearning: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_CONTRIBUTOR':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopContributor: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_INSTRUCTOR':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopInstructor: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_MENTOR':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopMentor: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_COACH':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopCoach: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_EXPERT':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopExpert: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_TOP_TRAINER':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetTopTrainer: payload.offset,
        },
      }
    case 'CHANGE_OFFSET_ALL_INSTRUCTOR':
      return {
        ...state,
        learningContributor: {
          ...state.learningContributor,
          offsetAllInstructor: payload.offset,
        },
      }
    case 'CHANGE_SEARCH_KEYWORD':
      return {
        ...state,
        searchKeyword: payload.searchKeyword,
      }
    case 'CHANGE_ACADEMIC_FILTER':
      return {
        ...state,
        academicFilter: {
          ...state.academicFilter,
          rating: payload.rating,
          categoriesREST: payload.categoriesREST,
          levelsREST: payload.levelsREST,
          languagesREST: payload.languagesREST,
          priceMin: payload.priceMin,
          priceMax: payload.priceMax,
          priceGQL: payload.priceGQL,
          categoriesGQL: payload.categoriesGQL,
          ratingGQL: payload.ratingGQL,
          contributorLabels: payload.contributorLabels,
        },
      }
    default:
      return state
  }
}

export const LearnerReducerInitialState: IStateLearnerReducerProps = {
  pageAcademicCategories: 1,
  limitAcademicCategories: 4,
  searchKeyword: '',
  academicFilter: {
    rating: '',
    categoriesREST: '',
    levelsREST: '',
    languagesREST: '',
    priceMin: '',
    priceMax: '',
    priceGQL: {
      _lte: 0,
      _gte: 0,
    },
    categoriesGQL: [],
    ratingGQL: [],
    contributorLabels: [],
  },
  course: {
    pageMyCourse: 1,
    limitMyCourse: 4,
    offsetTrendingCourse: 0,
    limitTrendingCourse: 4,
    pageAllCourse: 1,
    limitAllCourse: 8,
    offsetAllCourse: 0,
    offsetRecommendedCourse: 0,
    limitRecommendedCourse: 4,
    priceMin: '',
    priceMax: '',
    rating: '',
    category: '',
    categories: '',
  },
  pageTrendingBooks: 1,
  limitTrendingBooks: 4,
  pageRecommendedBooks: 1,
  limitRecommendedBooks: 4,
  offsetMyBooks: 0,
  limitMyBooks: 4,
  bookType: 2,
  limitAllBooks: 4,
  activeCatalogTab: LearningType.COURSE,
  isSkipMyBooks: false,
  pageEventsForYou: 1,
  limitEventsForYou: 2,
  eventsNearbyLat: 0,
  eventsNearbyLon: 0,
  pageEventsNearby: 1,
  limitEventsNearby: 2,
  limitAllEvents: 8,
  microlearning: {
    limitMicroLearning: 4,
    offsetMyMicroLearning: 0,
    offsetTrendingMicroLearning: 0,
    offsetRecommendedMicroLearning: 0,
    limitAllMicroLearning: 12,
    offsetAllMicroLearning: 0,
  },
  learningContributor: {
    limitTopContributor: 2,
    offsetTopContributor: 0,
    limitTopInstructor: 2,
    offsetTopInstructor: 0,
    limitTopMentor: 2,
    offsetTopMentor: 0,
    limitTopCoach: 2,
    offsetTopCoach: 0,
    limitTopExpert: 2,
    offsetTopExpert: 0,
    limitTopTrainer: 2,
    offsetTopTrainer: 0,
    limitAllInstructor: 8,
    offsetAllInstructor: 0,
  },
  socialLearning: {
    limitSocialLearning: 4,
    offsetMySocialLearning: 0,
    offsetTrending: 0,
    offsetRecommended: 0,
    limitGlobal: 12,
    offsetGlobal: 0,
  },
}

export const initialValue = {
  academicCategories: {data: [], pages: 0, total: 0},
  allCategories: {data: [], pages: 0, total: 0},
  myCourse: {data: [], pages: 0, total: 0},
  trendings: {data: [], loading: false, total: 0},
  courseRecommended: {data: [], loading: false, total: 0},
  courseRecommendationCategories: {
    academy_course_categories: [],
  },
  courseAll: {data: [], loading: false, total: 0},
  bookTrendings: {data: [], pages: 0, total: 0},
  bookRecommended: {data: [], pages: 0, total: 0},
  bookAll: {data: [], pages: 0, total: 0},
  myBooks: {
    academy_books: [],
    academy_books_aggregate: {
      aggregate: {
        count: 0,
      },
    },
  },
  myBooksIsLoading: false,
  bookRecommendationCategories: {
    academy_course_categories: [],
  },
  eventsForYou: {data: [], pages: 0, total: 0},
  eventsNearBy: {data: [], pages: 0, total: 0},
  eventRecommendationCategories: {
    event_categories: [],
  },
  eventsAll: {data: [], pages: 0, total: 0},
  mySocialLearning: {
    classrooms: [],
  },
  socialLearningRecommended: {
    getListClassroom: {
      classrooms: [],
    },
  },
  socialLearningPublic: {
    classrooms: [],
  },
  socialLearning: {
    mySociLearning: {
      data: [],
      total: 0,
      loading: false,
    },
    trending: {
      data: [],
      total: 0,
      loading: false,
    },
    recommended: {
      data: [],
      total: 0,
      loading: false,
    },
    global: {
      data: [],
      total: 0,
      loading: false,
    },
    category: {
      dara: [],
      loading: false,
    },
  },
  microlearning: {
    myMicrolearning: {
      data: [],
      total: 0,
      loading: false,
    },
    trendingMicroLearning: {
      data: [],
      total: 0,
      loading: false,
    },
    recommendedMicroLearning: {
      data: [],
      total: 0,
      loading: false,
    },
    allMicroLearning: {
      data: [],
      total: 0,
      loading: false,
    },
    category: {
      data: [],
      loading: false,
    },
  },
  learningContributor: {
    topLearningContributor: {
      data: [],
      total: 0,
      loading: false,
    },
    topInstructor: {
      data: [],
      total: 0,
      loading: false,
    },
    topMentor: {
      data: [],
      total: 0,
      loading: false,
    },
    topCoach: {
      data: [],
      total: 0,
      loading: false,
    },
    topExpert: {
      data: [],
      total: 0,
      loading: false,
    },
    topTrainer: {
      data: [],
      total: 0,
      loading: false,
    },
    allInstructor: {
      data: [],
      total: 0,
      loading: false,
    },
  },
  refetchTopContributor: undefined,
  changePageLimit: async () => undefined,
  changeSearchKeyword: async () => undefined,
  changeAcademicFilter: async () => undefined,
  changeActiveTab: async () => undefined,
  changeEventsForYouLocation: async () => undefined,

  reducerState: LearnerReducerInitialState,
  refetchAcademyCategories: undefined,
  refetchMyBooks: undefined,
  refetchAllBooks: undefined,
  refetchMySocialLearning: '',
  loadingListAllBooks: false,
  loadingListAllCourses: false,
}

export const changePageLimit = async (
  page: number,
  limit: number,
  type: number | string,
  dispatch: Dispatch<any>,
  offset: number,
  catalogType: number,
  isSkip: boolean
) => {
  try {
    dispatch({
      type,
      payload: {
        page,
        limit,
        offset,
        catalogType,
        isSkip,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

export const changeActiveCatalogTab = async (
  dispatch: Dispatch<any>,
  activeCatalog: LearningType
) => {
  try {
    dispatch({
      type: 'CHANGE_ACTIVE_CATALOG_TAB',
      payload: {
        activeCatalog,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

// TODO: Needed due to has different type (could be ebook / audio book).
export const resetBookPagination = async (dispatch: Dispatch<any>) => {
  try {
    dispatch({
      type: 'RESET_BOOK_PAGINATION',
    })
  } catch (err) {
    console.error(err)
  }
}

// TODO: Change events for you location
export const changeEventsForYouLocation = async (
  dispatch: Dispatch<any>,
  lat: number,
  lon: number
) => {
  try {
    dispatch({
      type: 'CHANGE_POSITION_EVENTS_NEARBY',
      payload: {
        lat,
        lon,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

// TODO: Set keyword for global search learning material
export const changeKeyword = async (
  dispatch: Dispatch<any>,
  searchKeyword: string
) => {
  try {
    dispatch({
      type: 'CHANGE_SEARCH_KEYWORD',
      payload: {
        searchKeyword,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

// TODO: Set academic filter for learning material, rating, level, etc
export const changeAcademicFilter = async (
  dispatch: Dispatch<any>,
  rating: number,
  categoriesREST: string,
  levelsREST: string,
  languagesREST: string,
  priceMin: number,
  priceMax: number,
  priceGQL: PriceType,
  categoriesGQL: number[],
  ratingGQL: RatingType[],
  contributorLabels: string[]
) => {
  try {
    dispatch({
      type: 'CHANGE_ACADEMIC_FILTER',
      payload: {
        rating,
        categoriesREST,
        levelsREST,
        languagesREST,
        priceMin,
        priceMax,
        priceGQL,
        categoriesGQL,
        ratingGQL,
        contributorLabels,
      },
    })
  } catch (err) {
    console.error(err)
  }
}
