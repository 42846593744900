import {useField, type UseFieldConfig} from 'react-final-form'

import {
  MenuItem,
  FormControl,
  FormHelperText,
  Select,
  Typography,
  type FormControlProps,
  type SelectProps,
} from '@material-ui/core'

import {showErrorOnBlur, ShowErrorFn} from './validators'

export interface SelectItem {
  value: any
  label: any
  disabled?: boolean
}

export interface FormSelectProps {
  label?: string
  name: string
  options: SelectItem[]
  fullWidth?: boolean
  disabled?: boolean
  helperText?: string
  className?: string
  validate?: UseFieldConfig<any>['validate']
  format?: UseFieldConfig<any>['format']
  parse?: UseFieldConfig<any>['parse']
  autoFocus?: boolean
  onChange?: SelectProps['onChange']
  showError?: ShowErrorFn
  formControlProps?: FormControlProps
  selectProps?: SelectProps
}

const FormSelect = (props: FormSelectProps) => {
  const {
    label,
    name,
    options,
    fullWidth,
    disabled,
    helperText,
    className = '',
    validate,
    format,
    parse,
    autoFocus,
    onChange,
    showError = showErrorOnBlur,
    formControlProps,
    selectProps,
  } = props

  const id = `formSelect-${name}`

  const {input, meta} = useField(name, {
    validate,
    format,
    parse,
  })

  const {error, submitError} = meta
  const isError = showError(meta)

  const handleSelectChange: SelectProps['onChange'] = !onChange
    ? input.onChange
    : (ev, node) => {
        onChange(ev, node)

        if (!ev.defaultPrevented) {
          input.onChange(ev)
        }
      }

  return (
    <FormControl fullWidth={fullWidth} {...formControlProps}>
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <Select
        id={id}
        variant="outlined"
        className={`text-sm ${className}`}
        readOnly={meta.submitting}
        disabled={disabled}
        error={isError}
        autoFocus={autoFocus}
        displayEmpty
        inputProps={{style: {padding: `16.8px 14px`}}}
        {...input}
        onChange={handleSelectChange}
        {...selectProps}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.disabled}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : helperText ? (
        <FormHelperText>{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default FormSelect
