import React from 'react'

type AnyFunction = (...args: any[]) => any

interface EventRef<T extends AnyFunction> {
  latest: T
  stable: T
}

const useEvent = <T extends AnyFunction>(callback: T): T => {
  const ref = React.useRef<EventRef<T>>()

  if (!ref.current) {
    ref.current = {
      latest: callback,
      stable: function (this: any) {
        return ref.current!.latest.apply(this, arguments as any)
      } as T,
    }
  }

  React.useLayoutEffect(() => {
    ref.current!.latest = callback
  })

  return ref.current.stable
}

export default useEvent
