import {makeStyles, StepConnector, withStyles} from '@material-ui/core'

export const CustomConnector = withStyles({
  alternativeLabel: {
    top: 19,
    left: 'calc(-50% + 30px)',
    right: 'calc(50% + 30px)',
    position: 'absolute',
  },
  active: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#039be5',
    },
  },
  line: {
    borderTopWidth: 1,
    borderColor: '#e9e9f0',
  },
})(StepConnector)

const useStyles = makeStyles(() => ({
  iconRoot: {
    color: '#fff',
    border: '1px solid #a9a8a8',
    borderRadius: '50%',
    width: 38,
    height: 38,
  },
  iconText: {
    fill: '#a9a8a8',
    fontSize: 10,
  },
  iconActive: {
    border: 0,
    '& $text': {
      fill: '#fff',
    },
  },
}))
export default useStyles
