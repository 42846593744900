import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_MICROLEARNINGS = gql`
  query (
    $dateLastmonth: timestamp!
    $userId: uuid!
    $limit: Int
    $offset: Int
    $search: String
    $orderBy: [microlearnings_order_by!]
    $status: [String!]
    $createdDate: timestamp_comparison_exp
  ) {
    microlearnings_aggregate(
      where: {
        created_by_user_id: {_eq: $userId}
        organization_id: {_is_null: true}
        status: {_in: $status}
        name: {_ilike: $search}
        date_added: $createdDate
      }
    ) {
      aggregate {
        count
      }
    }

    microlearnings(
      where: {
        created_by_user_id: {_eq: $userId}
        organization_id: {_is_null: true}
        status: {_in: $status}
        name: {_ilike: $search}
        date_added: $createdDate
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      status
      slug
      average_rating
      icon_url
      date_added
      name
      total_enrolled: microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      this_month: microlearning_members_aggregate(
        where: {date_added: {_gte: $dateLastmonth}}
      ) {
        aggregate {
          count
        }
      }
      price
      total_rating: microlearning_reviews_aggregate {
        aggregate {
          count(columns: star)
        }
      }
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_MICROLEARNING_DETAIL_PROGRESS = gql`
  query ($microlearningId: String) {
    getProgressMicrolearningDetail(microlearningId: $microlearningId) {
      total
      information
      content
    }
  }
`

export const GET_CONTRIBUTOR_MICROLEARING_LEARNER_STATS = gql`
  query ($microlearningId: String) {
    getDashboardLearnerContributorMicrolearningDetail(
      microlearningId: $microlearningId
    ) {
      eighteen_to_twenty_four
      twenty_five_to_thirty_four
      thirty_five_to_forty_nine
      over_fifty
      male
      female
      total_learner
    }
  }
`

export const GET_CONTRIBUTOR_MICROLEARING_ACTIVITY_STATS = gql`
  query (
    $startDate: String
    $endDate: String
    $timeType: String
    $microlearningId: String!
  ) {
    getActivityByTimeMicrolearningDetail(
      startDate: $startDate
      endDate: $endDate
      microlearningId: $microlearningId
      timeType: $timeType
    ) {
      wishlist_microlearning
      enrollment_microlearning
    }
  }
`

export const GET_CONTRIBUTOR_MICROLEARING_EARNING_STATS = gql`
  query (
    $startDate: String
    $endDate: String
    $timeType: String
    $microlearningId: String!
  ) {
    getEarningByTimeMicrolearningDetail(
      startDate: $startDate
      endDate: $endDate
      timeType: $timeType
      microlearningId: $microlearningId
    ) {
      earning_microlearning
    }
  }
`

export const GET_CONTRIBUTOR_MICROLEARNING_SLUG_AVAILABILITY = gql`
  query ($slug: String!) {
    microlearnings(where: {slug: {_eq: $slug}}) {
      id
      slug
    }
  }
`
