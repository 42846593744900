import {type ReactNode} from 'react'
import {Link as RouterLink, useMatch, useResolvedPath} from 'react-router-dom'

import {
  withStyles,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
} from '@material-ui/core'

export const SidebarItem = withStyles((theme) => ({
  root: {
    fontSize: 14,
    paddingLeft: 24,
    paddingRight: 24,
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.grey[100]} !important`,
    fontWeight: 600,
    paddingLeft: 24 - 4,
    borderLeft: `4px solid ${theme.palette.secondary.main}`,
  },
}))(ListItem)

interface SidebarNavItemProps {
  to: string
  end?: boolean
  caseSensitive?: boolean
  children?: ReactNode
  className?: string
}

export const SidebarNavItem = (props: SidebarNavItemProps) => {
  const {to, end = false, caseSensitive = false, children, className} = props

  const path = useResolvedPath(to)
  const match = useMatch({path: path.pathname, end, caseSensitive})

  return (
    <SidebarItem
      button
      // @ts-expect-error
      component={RouterLink}
      to={path}
      selected={!!match}
      className={className}
    >
      {children}
    </SidebarItem>
  )
}

interface SidebarMenuProps {
  title?: string
  children?: ReactNode
}

export const SidebarMenu = (props: SidebarMenuProps) => {
  const {title = 'Menu', children} = props

  return (
    <Paper>
      <div className="flex justify-between px-6 py-4">
        <Typography className="font-bold text-[#014a62]">{title}</Typography>
      </div>

      <Divider />

      <List className="flex flex-col gap-2">{children}</List>
    </Paper>
  )
}
