import {gql} from '@apollo/client'

export const GET_LEARNING_DIARY = gql`
  query (
    $userId: uuid
    $limit: Int
    $offset: Int
    $search: String
    $ordering: [learning_diaries_order_by!]!
  ) {
    learning_diaries(
      where: {user_id: {_eq: $userId}, subject: {_ilike: $search}}
      limit: $limit
      offset: $offset
      order_by: $ordering
    ) {
      id
      subject
      description
      date_added
      duration
      date
      global_user {
        id
        name
        avatar
      }
      learning_diary_activity {
        activity
      }
      learning_diary_participants {
        id
        global_user {
          id
          name
          avatar
        }
      }
      learning_diary_files {
        id
        size
        file
        name
        type
      }
      competency_dictionary {
        id
        name
      }
      learning_diary_competency {
        id
        name
        date_added
      }
    }
    learning_diaries_aggregate(where: {user_id: {_eq: $userId}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_DETAIL_LEARNING_DIARY = gql`
  query ($id: uuid) {
    learning_diaries(where: {id: {_eq: $id}}) {
      id
      subject
      description
      date
      date_added
      duration
      global_user {
        id
        name
        avatar
      }
      learning_diary_activity {
        id
        activity
      }
      learning_diary_participants {
        id
        global_user {
          id
          name
          avatar
        }
      }
      learning_diary_files {
        id
        size
        file
        name
        type
      }
      competency_dictionary {
        id
        name
      }
      learning_diary_competency {
        id
        name
        date_added
      }
    }
  }
`

export const GET_LEARNING_DIARY_ACTIVITIES = gql`
  query {
    learning_diary_activities {
      id
      activity
    }
  }
`

export const GET_LEARNING_DIARY_USER_COMPETENCIES = gql`
  query ($userId: uuid!, $limit: Int, $offset: Int, $query: String) {
    learning_diary_competencies(
      where: {user_id: {_eq: $userId}, name: {_ilike: $query}}
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`

export const GET_LEARNING_DIARY_COMPANY_COMPETENCIES = gql`
  query ($userId: uuid!, $query: String, $limit: Int, $offset: Int) {
    competency_dictionaries(
      where: {
        search_name: {_ilike: $query}
        competency_version_dictionaries: {
          competency_individuals: {
            user: {_eq: $userId}
            deleted_at: {_is_null: true}
          }
        }
      }
      limit: $limit
      offset: $offset
    ) {
      id
      name
    }
  }
`
