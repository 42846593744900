import React, {FC, ReactElement} from 'react'
import {Typography} from '@material-ui/core'
import {IEventTitle} from '../../../interfaces'
import {Share} from '@material-ui/icons'
import ShareCatalogItemDialog from '../catalog/dialog/ShareCatalogItemDialog.component'

const EventTitle: FC<IEventTitle> = (props: IEventTitle): ReactElement => {
  const {title, autor, isPreview} = props
  const [shareThisEvent, setShareThisEvent] = React.useState({
    isOpen: false,
    copyStatus: false,
  })

  const shareableURL =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname

  return (
    <div>
      <Typography variant="h6" className="font-bold mt-8">
        {title}
      </Typography>
      <Typography variant="subtitle2" color="textSecondary" className="mt-2">
        Oleh {autor}
      </Typography>

      {!isPreview && (
        <div className="flex flex-row mt-4">
          <div
            className="flex flex-row cursor-pointer"
            onClick={() => setShareThisEvent({...shareThisEvent, isOpen: true})}
          >
            <Share color="primary" />
            <Typography variant="subtitle2" color="primary" className="ml-3">
              Bagikan
            </Typography>
          </div>
        </div>
      )}

      {shareThisEvent?.isOpen && (
        <ShareCatalogItemDialog
          shareData={shareThisEvent}
          setShareData={setShareThisEvent}
          shareableUrl={shareableURL}
          title={title || ''}
          featureName="Acara"
        />
      )}
    </div>
  )
}

export default EventTitle
