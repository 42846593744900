import {Field, useField, useForm} from 'react-final-form'

import {getIn} from 'final-form'

import {
  Button,
  Checkbox,
  FormHelperText,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Radio,
  Typography,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'

import {
  isRequired,
  showErrorOnChange,
} from '../../../../../../../components/input-forms/validators'
import FormSelect, {
  SelectItem,
} from '../../../../../../../components/input-forms/FormSelect'
import CkEditorField from '../../../../../../../components/contributor/forms/CkEditorField'

import {getInterimId} from '../_utils'
import {FieldArray} from 'react-final-form-arrays'

export interface QuestionSubformProps {
  prefix?: string
  number: number
  inline?: boolean
  pristine?: boolean
  onDelete?: () => void
  onSubmit?: (values: any) => void
  onCancel?: () => void
}

// NOTE(intrnl): the choices are stored in two keys, `choices` containing the
// actual choices that students can choose from, and `answer_keys` that contains
// the correct answers.

// `answer_keys` being an array of the actual answers instead of an index makes
// it problematic to handle, we'll just keep track of it in a different way.

// See `QuestionForm.tsx` for the actual serialization/deserialization

export interface ChoiceItem {
  id: string
  text: string
}

export enum QuestionType {
  SINGLE_ANSWER = 'multiple',
  MULTIPLE_ANSWER = 'checkbox',
  SHORT_ANSWER = 'shortAnswer',
}

const QUESTION_TYPES: SelectItem[] = [
  {value: QuestionType.SINGLE_ANSWER, label: 'Multiple Choice'},
  {value: QuestionType.MULTIPLE_ANSWER, label: 'Multiple Answer'},
]

const AnswerField = (props: {prefix: string; multiple: boolean}) => {
  const {prefix, multiple} = props

  const {input, meta} = useField(`${prefix}answer_keys`, {
    validateFields: [`${prefix}choices`],
    validate: (answers, ctx: any) => {
      const choices = getIn(ctx, `${prefix}choices`)

      const hasOneFilled = answers.some((id: string) => {
        const index = choices.findIndex((item: ChoiceItem) => item.id === id)

        if (index === -1) {
          return false
        }

        const choice = choices[index]

        if (!choice.text.trim()) {
          return false
        }

        return true
      })

      if (!hasOneFilled) {
        return 'There needs to be one choice marked as the answer'
      }
    },
  })

  const {value: answers, onChange: onAnswersChange} = input
  const {error, submitError} = meta

  const isError = showErrorOnChange(meta)

  const Input = multiple ? Checkbox : Radio

  return (
    <div>
      <Typography
        component="label"
        color={isError ? 'error' : 'textSecondary'}
        className="block text-sm"
        gutterBottom
      >
        Answers
      </Typography>

      <FieldArray name={`${prefix}choices`}>
        {({fields}) => (
          <>
            <div>
              {fields.map((name, index) => (
                <div key={index} className="flex gap-4 mb-4">
                  <Field name={name}>
                    {({input: {value, onChange}}) => (
                      <OutlinedInput
                        value={value.text || ''}
                        onChange={(ev) =>
                          onChange({...value, text: ev.target.value})
                        }
                        placeholder="Add the answer"
                        margin="dense"
                        color="secondary"
                        className="grow"
                        startAdornment={
                          <InputAdornment position="start">
                            <Input
                              checked={answers.includes(value.id)}
                              onChange={(ev, checked) => {
                                if (multiple) {
                                  if (checked) {
                                    onAnswersChange(answers.concat(value.id))
                                  } else {
                                    const clone = answers.slice()
                                    const idx = answers.indexOf(value.id)

                                    clone.splice(idx, 1)
                                    onAnswersChange(clone)
                                  }
                                } else if (checked) {
                                  onAnswersChange([value.id])
                                }
                              }}
                            />
                          </InputAdornment>
                        }
                      />
                    )}
                  </Field>

                  <IconButton
                    onClick={() => fields.remove(index)}
                    edge="end"
                    className="text-red-600"
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              ))}
            </div>

            <Link
              component="button"
              onClick={() => fields.push({id: getInterimId(), text: ''})}
              type="button"
              color="secondary"
            >
              <span aria-hidden="true">+ </span>
              <span>Add more answer</span>
            </Link>
          </>
        )}
      </FieldArray>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : null}
    </div>
  )
}

const QuestionSubform = (props: QuestionSubformProps) => {
  const {
    prefix = '',
    number,
    inline,
    pristine,
    onDelete,
    onSubmit,
    onCancel,
  } = props

  const form = useForm()

  return (
    <div className={inline ? 'flex py-6 pl-1' : 'flex p-4 pl-1'}>
      <Typography color="textSecondary" className="w-12 mr-2 text-sm">
        {number}.
      </Typography>

      <div className="flex flex-col gap-6 text-sm grow relative">
        {onDelete && (
          <IconButton
            onClick={onDelete}
            size="small"
            edge="end"
            className="text-gray-400 absolute -top-1 right-0 z-10"
          >
            <DeleteIcon />
          </IconButton>
        )}

        <CkEditorField
          name={`${prefix}fulltext`}
          validate={isRequired}
          label="Question"
        />

        <FormSelect
          name={`${prefix}fieldtype`}
          validate={isRequired}
          label="Question Type"
          options={QUESTION_TYPES}
          className="sm:max-w-xs"
          onChange={() => form.change(`${prefix}answer_keys`, [])}
        />

        <Field name={`${prefix}fieldtype`} subscription={{value: true}}>
          {({input: {value}}) => {
            if (value === QuestionType.SHORT_ANSWER) {
              return null
            }

            return (
              <AnswerField
                prefix={prefix}
                multiple={value === QuestionType.MULTIPLE_ANSWER}
              />
            )
          }}
        </Field>

        {(onSubmit || onCancel) && (
          <div className="flex flex-row-reverse gap-4">
            <Button
              disabled={pristine}
              onClick={onSubmit}
              variant="contained"
              color="secondary"
              disableElevation
              className="h-12 w-24"
            >
              Confirm
            </Button>

            <Button
              onClick={onCancel}
              color="inherit"
              className="h-12 w-24 text-red-400"
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default QuestionSubform
