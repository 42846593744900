import React from 'react'
import {useParams, Link} from 'react-router-dom'

import {
  Avatar,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@material-ui/core'
import CallIcon from '@material-ui/icons/Call'
import MailIcon from '@material-ui/icons/Mail'
import PlaceIcon from '@material-ui/icons/Place'
import StarIcon from '@material-ui/icons/Star'

import {IContributorHeader} from '../../../interfaces'

import {
  CHAT_HOME_SERVER,
  CHAT_URL,
  // OLD_LEARNING_APP_URL,
  SOSMED_APP_URL,
} from '../../../utils/globals'
import {CONTRIBUTOR_LABEL} from '../../../utils/constans'

import WlbBannerImage from '../../../assets/images/smartasn-banner.png'

interface HeaderProps {
  data: IContributorHeader
}

const Header: React.FC<HeaderProps> = ({data}) => {
  const {contribId} = useParams()
  const _data = data.learning_contributor_ratings_by_pk

  const user = _data.global_user
  const phoneData = user.people_phones?.[0]
  const addressData = user.people_addresses?.[0]
  const emailData = user.people_emails?.[0]?.email
  // const socialData = user?.people_addresses?.[0]

  return (
    <Paper className="mb-8 overflow-hidden">
      <div
        className="aspect-[16/3] bg-gray-100"
        style={{
          backgroundImage: `url(${
            user && (user.contributor_banner || WlbBannerImage)
          })`,
          backgroundSize: '100%',
          backgroundPosition: '50% 60%',
          backgroundRepeat: 'no-repeat',
        }}
      />

      <div className="grid md:grid-cols-[min-content_minmax(0,_1fr)] lg:flex gap-6 py-6 px-6 relative">
        <Avatar
          alt={user.name}
          src={user.avatar}
          className="h-40 w-40 -mt-12"
        />

        <div className="flex-grow">
          <Typography className="font-bold text-lg">{user.name}</Typography>

          <Typography color="textSecondary" className="text-sm">
            Learning Contributor
          </Typography>

          <Typography color="secondary" className="font-bold mt-2 capitalize">
            {CONTRIBUTOR_LABEL[user.contributor_label] ||
              user.contributor_label}
          </Typography>

          <div className="flex flex-row flex-wrap mt-4 gap-4">
            <Button
              component="a"
              href={`${SOSMED_APP_URL}/profile/${contribId}/detail`}
              target="_blank"
              variant="contained"
              color="primary"
              className="flex-grow sm:flex-grow-0"
            >
              Lihat Profil
            </Button>
            <Button
              component={Link}
              to={`/learner/create-social-learning`}
              variant="outlined"
              color="primary"
              className="flex-grow sm:flex-grow-0"
            >
              Mulai Sesi Individu
            </Button>
          </div>
        </div>

        <div className="md:col-span-2 flex flex-wrap md:flex-nowrap justify-evenly gap-6">
          <div className="text-center lg:text-right">
            <h3 className="mb-1">Catalog</h3>
            <span className="font-bold text-4xl text-gray-700">
              {_data.total_catalog_count}
            </span>
          </div>

          <div className="text-center tg:text-right">
            <h3 className="mb-1">Pelajar</h3>
            <span className="font-bold text-4xl text-gray-700">
              {_data.total_learner_count}
            </span>
          </div>

          <div className="text-center">
            <h3 className="mb-1">Peringkat keseluruhan</h3>
            <div className="flex gap-2 items-center text-4xl">
              <StarIcon color="secondary" className="text-3xl" />
              <span className="font-bold text-gray-700">
                {_data.total_rating_avg.toFixed(1)}
              </span>
              <span className="text-base text-gray-500 self-end">
                ({_data.total_rating_count})
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-[#f7f8f9] flex flex-wrap gap-4 justify-between px-6 py-4">
        <div className="flex gap-4 items-center">
          {phoneData && (
            <IconButton
              component="a"
              href={`tel:${phoneData?.country_code}${phoneData?.phone}`}
              color="primary"
              size="small"
            >
              <CallIcon />
            </IconButton>
          )}
          {emailData && (
            <IconButton
              component="a"
              href={`mailto:${emailData}`}
              color="primary"
              size="small"
            >
              <MailIcon />
            </IconButton>
          )}
          {addressData && (
            <Tooltip
              title={
                <Typography variant="caption">
                  {`${addressData?.address}, ${addressData?.global_city?.name}, ${addressData?.global_province?.name}`}
                </Typography>
              }
              placement="top"
            >
              <IconButton color="primary" size="small">
                <PlaceIcon />
              </IconButton>
            </Tooltip>
          )}
        </div>

        <Button
          variant="outlined"
          color="primary"
          component="a"
          href={`${CHAT_URL}?chat=@${contribId}:${CHAT_HOME_SERVER}`}
          target="_blank"
        >
          Kirim Pesan
        </Button>
      </div>
    </Paper>
  )
}

export default Header
