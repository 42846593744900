import React from 'react'
import {Theme, createStyles, makeStyles} from '@material-ui/core'
import {Fullscreen} from '@material-ui/icons'
import {ILesson} from '../../../../../interfaces/Course.interface'
import Dompurify from 'dompurify'

type ArticleLessonProps = {
  lesson: ILesson
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    titleWrapper: {
      fontSize: theme.typography.pxToRem(17),
      fontWeight: 600,
      backgroundColor: '#FFFCFC',
      color: '#014A62',
      borderTopLeftRadius: '4px',
      borderTopRightRadius: '4px',
      padding: '15px 20px',
      height: '15%',
      marginBottom: '5px',
      display: 'flex',
      justifyContent: 'space-between',
    },

    screenController: {
      display: 'flex',
      gap: 15,
    },

    iconScreenController: {
      cursor: 'pointer',
    },
  })
)

export const ArticleLessonComponent = (props: ArticleLessonProps) => {
  const classes = useStyles()

  // const [zoomLevel, setZoomLevel] = useState(70)

  /* const handleZoomIn = () => {
    const currentZoom = zoomLevel

    setZoomLevel(currentZoom + 5)
  }

  const handleZoomOut = () => {
    const currentZoom = zoomLevel

    setZoomLevel(currentZoom + 5)
  } */

  const handleFullScreen = () => {
    const resource: any = props.lesson?.resources?.[0]
    const url = typeof resource === 'string' ? resource : resource.url

    window.open(url, '_blank', 'noopener noreferrer')
  }

  const renderArticle = (resource: any) => {
    const url = typeof resource === 'string' ? resource : resource.url

    return (
      <iframe
        className="border-2 border-black"
        src={`${url}#navbar=0&toolbar=0`}
        width="100%"
        height="323"
      ></iframe>
    )
  }

  /*  useEffect(() => {
    if (zoomLevel) {
      renderArticle()
    }
  }, [zoomLevel, renderArticle]) */

  return (
    <div>
      <div className={classes.titleWrapper}>
        <div>{props.lesson.title}</div>
        <div className={classes.screenController}>
          {/*           <ZoomIn
            className={classes.iconScreenController}
            onClick={handleZoomIn}
          />
          <ZoomOut
            className={classes.iconScreenController}
            onClick={handleZoomOut}
          /> */}
          {props.lesson?.resources?.length === 1 && (
            <Fullscreen
              className={classes.iconScreenController}
              onClick={handleFullScreen}
            />
          )}
        </div>
      </div>

      <div
        className={`set-inner-html overflow-auto mt-4 ${
          props.lesson?.resources?.length > 0 ? 'hidden' : 'visible'
        } }`}
        dangerouslySetInnerHTML={{
          __html: Dompurify.sanitize(`${props.lesson.summary}`),
        }}
      />

      {props.lesson?.resources?.length === 1 &&
        renderArticle(props.lesson.resources[0])}
    </div>
  )
}

export default ArticleLessonComponent
