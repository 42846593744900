import React from 'react'
import {Field} from 'react-final-form'
import {Draggable} from 'react-beautiful-dnd'

import DOMPurify from 'dompurify'

import {
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Radio,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import {EditState, useFieldValue} from '../_utils'

import QuestionForm from './QuestionForm'

export interface QuestionSubviewProps {
  id: string
  name: string
  index: number
}

const SanitizedTitle = (props: {title: string}) => {
  const {title} = props

  const sanitized = React.useMemo(() => {
    return DOMPurify.sanitize(title, {
      // NOTE(intrnl): unwrap paragraphs entirely.
      FORBID_TAGS: ['p'],
    })
  }, [title])

  return (
    <div
      className="grow text-sm mx-2 overflow-hidden whitespace-nowrap text-ellipsis"
      dangerouslySetInnerHTML={{__html: sanitized}}
    />
  )
}

const enum QuestionType {
  SINGLE_ANSWER = 'multiple',
  MULTIPLE_ANSWER = 'checkbox',
  SHORT_ANSWER = 'shortAnswer',
}

const ChoiceAnswerView = (props: {name: string; multiple?: boolean}) => {
  const {name, multiple} = props

  const Input = multiple ? Checkbox : Radio

  const choices = useFieldValue<string[]>(`${name}.choices`)
  const answers = useFieldValue<string[]>(`${name}.answer_keys`)

  return (
    <FormGroup className="p-6 pl-16 pt-0">
      {choices.map((value) => (
        <FormControlLabel
          key={value}
          label={value}
          control={
            <Input checked={answers.includes(value)} readOnly color="primary" />
          }
        />
      ))}
    </FormGroup>
  )
}

const QuestionSubview = (props: QuestionSubviewProps) => {
  const {id, name, index} = props

  const [editState, setEditState] = React.useState(EditState.NONE)
  const [isOpened, setIsOpened] = React.useState(false)

  const type = useFieldValue<QuestionType>(`${name}.fieldtype`)

  const isEditing = editState !== EditState.NONE

  if (isEditing) {
    return (
      <Paper elevation={0}>
        <Field name={name} subscription={{value: true}}>
          {({input: {value, onChange}}) => (
            <QuestionForm
              initialValues={value}
              number={index + 1}
              onCancel={() => {
                setEditState(EditState.NONE)
              }}
              onSubmit={(next: any) => {
                setEditState(EditState.NONE)
                onChange(next)
              }}
            />
          )}
        </Field>

        <Divider />
      </Paper>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          elevation={snapshot.isDragging ? 2 : 0}
        >
          <div className="flex items-center px-4 py-1 bg-white">
            <IconButton {...provided.dragHandleProps} edge="start">
              <DragIndicatorIcon />
            </IconButton>

            <Field
              name={`${name}.fulltext`}
              validateFields={[]}
              subscription={{value: true}}
            >
              {({input: {value}}) => (
                <SanitizedTitle title={`${index + 1}. ${value}`} />
              )}
            </Field>

            <IconButton
              disabled={isEditing}
              onClick={() => setEditState(EditState.EDITING)}
              title="Edit question"
              color="secondary"
            >
              <EditIcon />
            </IconButton>

            <IconButton title="Delete question">
              <DeleteIcon color="error" />
            </IconButton>

            <IconButton
              disabled={type === QuestionType.SHORT_ANSWER}
              onClick={() => setIsOpened(!isOpened)}
              title="Open/close question"
              edge="end"
            >
              <ExpandMoreIcon className={isOpened ? 'rotate-180' : ''} />
            </IconButton>
          </div>

          <Collapse in={isOpened} unmountOnExit>
            <ChoiceAnswerView
              name={name}
              multiple={type === QuestionType.MULTIPLE_ANSWER}
            />
          </Collapse>

          <Divider className={snapshot.isDragging ? 'invisible' : ''} />
        </Paper>
      )}
    </Draggable>
  )
}

export default QuestionSubview
