// https://github.com/mbrn/material-table/blob/v1.69.0/src/components/m-table-pagination.js

import React, {ChangeEvent, type ForwardedRef, type MouseEvent} from 'react'

import {
  IconButton,
  TablePagination,
  Typography,
  useTheme,
} from '@material-ui/core'
import {TablePaginationActionsProps} from '@material-ui/core/TablePagination/TablePaginationActions'
import FirstPage from '@material-ui/icons/FirstPage'
import LastPage from '@material-ui/icons/LastPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

const PaginationActions_ = (
  props: TablePaginationActionsProps,
  ref: ForwardedRef<HTMLDivElement>
) => {
  const {
    count,
    page,
    rowsPerPage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onChangePage,
    onPageChange,
    backIconButtonProps,
    nextIconButtonProps,
    ...rest
  } = props

  const theme = useTheme()

  const isFirstPage = page === 0
  const isLastPage = page >= Math.ceil(count / rowsPerPage) - 1

  const handleFirstPage = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0)
  }

  const handleLastPage = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  const handlePrevPage = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1)
  }

  const handleNextPage = (event: MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1)
  }

  const labelFrom = count === 0 ? 0 : page * rowsPerPage + 1
  const labelTo = Math.min((page + 1) * rowsPerPage, count)
  const labelCount = count

  const label = `${labelFrom}-${labelTo} of ${labelCount}`

  return (
    <div ref={ref} {...rest}>
      <IconButton
        onClick={handleFirstPage}
        disabled={isFirstPage}
        color="inherit"
      >
        {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
      </IconButton>
      <IconButton
        onClick={handlePrevPage}
        disabled={isFirstPage}
        color="inherit"
        {...backIconButtonProps}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>

      <Typography
        variant="caption"
        style={{
          flex: 1,
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit',
        }}
      >
        {label}
      </Typography>

      <IconButton
        onClick={handleNextPage}
        disabled={isLastPage}
        color="inherit"
        {...nextIconButtonProps}
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPage}
        disabled={isLastPage}
        color="inherit"
      >
        {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
      </IconButton>
    </div>
  )
}

export const PaginationActions = React.forwardRef(PaginationActions_)

export interface PaginationProps {
  rowsPerPageOptions?: number[]
  count: number
  page: number
  rowsPerPage: number
  onPageChange?: (next: number) => void
  onRowsPerPageChange?: (next: number) => void
}

export const Pagination = (props: PaginationProps) => {
  const {
    rowsPerPageOptions = [10, 25, 100],
    count,
    page,
    rowsPerPage,
    onPageChange,
    onRowsPerPageChange,
  } = props

  const handlePageChange = (event: unknown, next: number) => {
    if (onPageChange) {
      onPageChange(next)
    }
  }

  const handleRowsPerPageChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (onRowsPerPageChange) {
      onRowsPerPageChange(+ev.target.value)
    }
  }

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      onPageChange={handlePageChange}
      onRowsPerPageChange={handleRowsPerPageChange}
      ActionsComponent={PaginationActions}
      classes={{caption: 'hidden', selectRoot: 'm-0', actions: 'm-0'}}
      SelectProps={{
        renderValue: (value) => (
          <div style={{padding: '0px 5px'}}>{value + ' rows'}</div>
        ),
      }}
    />
  )
}
