import {
  makeStyles,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  iconRoot: {
    color: theme.palette.text.secondary,
    fill: 'transparent',
    height: 36,
    width: 36,
    borderRadius: '50%',
    border: `1px solid currentColor`,
  },
  iconCompleted: {
    fill: 'currentColor',
    border: 0,
  },
  iconText: {
    fontSize: 10,
    fontWeight: 600,
    fill: 'currentColor',
  },
  label: {
    color: theme.palette.text.secondary,
    marginTop: `8px !important`,
  },
  labelCompleted: {
    color: `${theme.palette.primary.main} !important`,
  },
  connector: {
    top: 18,
    left: 'calc(-50% + 64px)',
    right: 'calc(50% + 64px)',
    position: 'absolute',
  },
}))

export interface CreateStepperProps {
  active: number
  labels: string[]
}

const CreateStepper = (props: CreateStepperProps) => {
  const {active, labels} = props

  const styles = useStyles()

  return (
    <Stepper
      activeStep={active}
      alternativeLabel
      connector={<StepConnector classes={{root: styles.connector}} />}
    >
      {labels.map((label, idx) => (
        <Step key={idx}>
          <StepLabel
            StepIconProps={{
              classes: {
                root: styles.iconRoot,
                text: styles.iconText,
                completed: styles.iconCompleted,
              },
            }}
            classes={{
              label: styles.label,
              active: styles.labelCompleted,
              completed: styles.labelCompleted,
            }}
          >
            {label}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default CreateStepper
