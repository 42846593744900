import React from 'react'
import {Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'

import {Draggable, Droppable} from 'react-beautiful-dnd'

import {IconButton, Paper} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'

import Lesson from './Lesson'
import LessonAdd from './LessonAdd'
import SectionForm from './SectionForm'

import {getInterimId, isInterimId, EditState, EMPTY_ARRAY} from './_utils'

export interface SectionProps {
  id: string
  name: string
  index: number
  disableDelete?: boolean
  onDelete?: () => void
}

const Section = (props: SectionProps) => {
  const {id, name, index, disableDelete, onDelete} = props

  const isNew = isInterimId(id)
  const [editState, setEditState] = React.useState(
    isNew ? EditState.ADDING : EditState.NONE
  )

  if (editState !== EditState.NONE) {
    return (
      <Field name={name} subscription={{value: true}}>
        {({input: {value, onChange}}) => (
          <SectionForm
            initialValues={value}
            editState={editState}
            onCancel={() => {
              if (editState === EditState.ADDING) {
                if (onDelete) {
                  onDelete()
                }
              } else {
                setEditState(EditState.NONE)
              }
            }}
            onSubmit={(next: any) => {
              setEditState(EditState.NONE)
              onChange(next)
            }}
          />
        )}
      </Field>
    )
  }

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Paper
          innerRef={provided.innerRef}
          {...provided.draggableProps}
          className="bg-[#f7f8f9] mb-4"
          elevation={snapshot.isDragging ? 4 : 1}
        >
          <div className="flex items-center px-6 py-1">
            <IconButton {...provided.dragHandleProps} edge="start">
              <DragIndicatorIcon />
            </IconButton>

            <span className="grow text-sm font-bold mx-2">
              <Field name={`${name}.title`} subscription={{value: true}}>
                {({input: {value}}) => value}
              </Field>
            </span>

            <IconButton
              title="Edit section"
              onClick={() => setEditState(EditState.EDITING)}
              color="secondary"
            >
              <EditIcon />
            </IconButton>

            <IconButton
              title="Delete section"
              onClick={onDelete}
              disabled={disableDelete}
              edge="end"
            >
              <DeleteIcon color={disableDelete ? 'disabled' : 'error'} />
            </IconButton>
          </div>

          <FieldArray name={`${name}.academy_course_lessons`}>
            {({fields}) => (
              <div className="px-6 pt-2 pb-6">
                <Droppable
                  droppableId={`${name}.academy_course_lessons`}
                  type="lesson"
                >
                  {(provided) => (
                    <div ref={provided.innerRef} className="flex flex-col">
                      {(fields.value || EMPTY_ARRAY).map((item, index) => {
                        const id = '' + item.id

                        return (
                          <Lesson
                            key={id}
                            id={id}
                            index={index}
                            name={`${name}.academy_course_lessons[${index}]`}
                            onDelete={() => fields.remove(index)}
                          />
                        )
                      })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>

                <LessonAdd
                  onAdd={(next) => {
                    fields.push({
                      id: getInterimId(),
                      lesson_type: next,
                    })
                  }}
                />
              </div>
            )}
          </FieldArray>
        </Paper>
      )}
    </Draggable>
  )
}

export default Section
