import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import TabRow from '../../catalog/tabs/TabRow.component'
import {LearningType} from '../../../../utils/enum'
import {IMicroLearning} from '../../../../interfaces/Microlearning.interface'
import {ALL_MICROLEARNING} from '../../../../provider/services/MicroLearning.service'
import {useSnackbar} from 'notistack'
import {IAcademicFilter} from '../../../../interfaces/Filter.interface'

import LoadingCard from '../../../loading/LoadingCard.component'

type MicroLearningSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: IAcademicFilter
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  activeTab: LearningType
}

function MicroLearningSearchFilterTab(
  props: MicroLearningSearchFilterTabProps
) {
  const {countResult, setCountResult} = props
  const {enqueueSnackbar} = useSnackbar()

  const {
    data: dataListAllMicroLearning,
    loading: loadingListAllMicroLearning,
    error: errorListAllMicroLearning,
    refetch: refetchListAllMicroLearning,
  } = ALL_MICROLEARNING(
    -1,
    0,
    props.searchKeyword,
    Number(props.academicFilter.priceMax),
    Number(props.academicFilter.priceMin),
    props.academicFilter.categoriesGQL,
    props.academicFilter.levelsGQL,
    props.academicFilter.languagesGQL,
    Number(props.academicFilter.rating)
  )

  React.useEffect(() => {
    if (
      dataListAllMicroLearning?.microlearnings_aggregate.aggregate.count !==
      countResult.countResultMicroLearning
    ) {
      setCountResult({
        ...countResult,
        countResultMicroLearning:
          dataListAllMicroLearning?.microlearnings_aggregate.aggregate.count,
      })
    }
  }, [
    dataListAllMicroLearning,
    countResult,
    setCountResult,
    errorListAllMicroLearning,
  ])

  React.useEffect(() => {
    setTimeout(refetchListAllMicroLearning, 1000)
  }, [
    props.searchKeyword,
    props.academicFilter,
    props.priceFilter,
    refetchListAllMicroLearning,
    props.activeTab,
  ])

  if (loadingListAllMicroLearning) {
    return <LoadingCard length={4} />
  } else if (errorListAllMicroLearning) {
    enqueueSnackbar(JSON.stringify(errorListAllMicroLearning), {
      variant: 'error',
    })
  }

  const microLearningData = [...dataListAllMicroLearning.microlearnings]

  return (
    <Grid container className="px-2 pb-2">
      <TabRow
        title={''}
        data={
          props.priceFilter === 'Price (Low - High)'
            ? microLearningData
                .sort(
                  (a: IMicroLearning, b: IMicroLearning) =>
                    Number(a.price) - Number(b.price)
                )
                .filter((microLearning: IMicroLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      microLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      microLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return microLearning
                  }
                })
            : props.priceFilter === 'Price (High - Low)'
            ? microLearningData
                .sort(
                  (a: IMicroLearning, b: IMicroLearning) =>
                    Number(b.price) - Number(a.price)
                )
                .filter((microLearning: IMicroLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      microLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      microLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return microLearning
                  }
                })
            : microLearningData
                .sort(
                  (a: IMicroLearning, b: IMicroLearning) =>
                    Number(b.date_added) - Number(a.date_added)
                )
                .filter((microLearning: IMicroLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      microLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      microLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return microLearning
                  }
                })
        }
        type={LearningType.MICRO_LEARNING}
        isAll={true}
        isFilterCatalog={true}
      />
    </Grid>
  )
}

export default MicroLearningSearchFilterTab
