import React from 'react'
import {Typography} from '@material-ui/core'
import {Done, ChevronRight} from '@material-ui/icons'
import {
  ICategory,
  ISubCategory,
} from '../../../../interfaces/Academic.interface'
import {ICourse} from '../../../../interfaces/Course.interface'
import {defineLanguageCode} from '../../../../utils/helpers'
import {LIST_ACADEMY_CATEGORIES_WT_PAGINATION} from '../../../../provider/services/Academy.service'
import Skeleton from 'react-loading-skeleton'
import Dompurify from 'dompurify'

const TabAbout = (props: ICourse) => {
  const {data, isLoading} = LIST_ACADEMY_CATEGORIES_WT_PAGINATION()

  if (isLoading) {
    return <Skeleton height={300} className="mx-4 my-2" width={'20vw'} />
  }

  return (
    <div className="flex flex-col mt-8">
      <Typography variant="body1" className="font-bold">
        Rincian
      </Typography>
      <table className="mt-4">
        <tr className="h-8">
          <td className="w-32">
            <Typography variant="subtitle2">Bahasa</Typography>
          </td>
          <td>
            <Typography variant="subtitle2">:</Typography>
          </td>
          <td className="pl-6">
            <Typography variant="subtitle2" color="textSecondary">
              {defineLanguageCode(props.language)}
            </Typography>
          </td>
        </tr>
        <tr className="h-8">
          <td>
            <Typography variant="subtitle2">Kategori</Typography>
          </td>
          <td>
            <Typography variant="subtitle2">:</Typography>
          </td>
          <td className="pl-6">
            <Typography variant="subtitle2" color="textSecondary">
              {
                data?.data.find((category: ICategory) =>
                  category.AcademyCourseCategories.find(
                    (subCategory: ISubCategory) =>
                      subCategory.id === props.AcademyCourseCategory.id
                  )
                )?.name
              }
            </Typography>
          </td>
        </tr>
        <tr className="h-8">
          <td>
            <Typography variant="subtitle2">Sub Kategori</Typography>
          </td>
          <td>
            <Typography variant="subtitle2">:</Typography>
          </td>
          <td className="pl-6">
            <Typography variant="subtitle2" color="textSecondary">
              {props.AcademyCourseCategory.name}
            </Typography>
          </td>
        </tr>
        <tr className="h-8">
          <td>
            <Typography variant="subtitle2">Tingkatan</Typography>
          </td>
          <td>
            <Typography variant="subtitle2">:</Typography>
          </td>
          <td className="pl-6">
            <Typography variant="subtitle2" color="textSecondary">
              {props.level === 1
                ? 'Beginner'
                : props.level === 2
                ? 'Intermediate'
                : 'Advanced'}
            </Typography>
          </td>
        </tr>
      </table>
      <Typography variant="body1" className="mt-9 font-bold">
        Keterangan
      </Typography>
      <div
        className="mt-4"
        dangerouslySetInnerHTML={{
          __html: Dompurify.sanitize(props.description),
        }}
      />
      <Typography variant="body1" className="mt-9 font-bold">
        Apa yang akan Anda pelajari
      </Typography>
      <table className="mt-4">
        {props.goals?.map((goal: string, index: number) => (
          <tr className="h-8" key={`goal-${index}`}>
            <td className="w-8">
              <Done fontSize="small" color="disabled" />
            </td>
            <td>
              <Typography variant="subtitle2">{goal}</Typography>
            </td>
          </tr>
        ))}
      </table>
      <Typography variant="body1" className="mt-9 font-bold">
        Persyaratan
      </Typography>
      <table className="mt-4">
        {props.requirements?.map((requirement: string, index: number) => (
          <tr className="h-8" key={`goal-${index}`}>
            <td className="w-8">
              <Done fontSize="small" color="disabled" />
            </td>
            <td>
              <Typography variant="subtitle2">{requirement}</Typography>
            </td>
          </tr>
        ))}
      </table>
      <Typography variant="body1" className="mt-9 font-bold">
        Kursus ini ditujukan bagi
      </Typography>
      <table className="mt-4">
        {props.targetAudience?.map((audience: string, index: number) => (
          <tr className="h-8" key={`goal-${index}`}>
            <td className="w-8">
              <ChevronRight fontSize="small" color="disabled" />
            </td>
            <td>
              <Typography variant="subtitle2">{audience}</Typography>
            </td>
          </tr>
        ))}
      </table>
    </div>
  )
}

export default TabAbout
