import {gql} from '@apollo/client'

export const GET_ACADEMY_CATEGORIES = gql`
  query ($where: academy_course_categories_bool_exp, $limit: Int) {
    academy_course_categories(
      where: $where
      limit: $limit
      order_by: [{parent: asc_nulls_first}]
    ) {
      id
      name
      academy_course_category {
        id
        name
      }
    }
    total: academy_course_categories_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
`
