import React from 'react'
import {makeStyles, TextField} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  label: {
    color: '#a9a8a8',
    fontSize: 14,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  placeholderStyle: {
    '& input::placeholder': {
      fontSize: '14px',
    },
    '& textarea::placeholder': {
      fontSize: '14px',
    },
  },
}))

const TextFieldForm = (props: any) => {
  const {label, endAdornment, multiline = false, inputProps, ...rest} = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <TextField
        fullWidth
        classes={{root: classes.placeholderStyle}}
        variant="outlined"
        InputProps={{
          ...inputProps,
        }}
        multiline={multiline}
        {...rest}
      />
      {endAdornment}
    </div>
  )
}

export default TextFieldForm
