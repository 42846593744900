import {ApolloProvider} from '@apollo/react-hooks'
import {MuiThemeProvider} from '@material-ui/core'
import {SnackbarProvider} from 'notistack'
import {QueryClient, QueryClientProvider} from 'react-query'
import {BrowserRouter} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import './App.css'
import client from './config/apollo.service'
import Router from './config/Router.service'
import {useTitleTemplate} from 'hoofd'
import {theme} from './config/theme.config'
import * as Sentry from '@sentry/browser'
import {SENTRY_ENV, SENTRY_URL, userData} from './utils/globals'

const queryClient = new QueryClient()

Sentry.init({
  dsn: SENTRY_URL,
  environment: SENTRY_ENV,
})

Sentry.configureScope((scope: any) => scope.setExtra('userData', userData))

const App = () => {
  useTitleTemplate('SmartASN - Pembelajaran | %s')
  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider maxSnack={3}>
                <Router />
              </SnackbarProvider>
            </MuiThemeProvider>
          </ThemeProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </ApolloProvider>
  )
}

export default App
