import {Box} from '@material-ui/core'
import {useContext} from 'react'
import {
  BorderLinearProgress,
  CustomTypography,
  SummaryLinearProgress,
} from '../../../../components/utils-components/GlobalStyles'
import {LearningProfileContext} from '../../../../provider/LearningProfile.provider'
import {format} from 'date-fns'
import NoListComponent from '../../../../components/empty-state/NoList.component'
import {CompetencyDictionary} from '../../../../interfaces/Competency.interface'

const AddedByCompany = () => {
  const {dataAddedByCompany} = useContext(LearningProfileContext)

  const getPercent = (value: string | number) => {
    return (Number(value) / 5) * 100
  }
  const countGap = (num1: number, num2: number) => {
    const gap = num1 - num2

    return gap.toFixed(1)
  }

  return (
    <>
      {!dataAddedByCompany.length ? (
        <div className="mt-4">
          <NoListComponent title="Maaf, tidak ada daftar" imageSize={180} />
        </div>
      ) : (
        dataAddedByCompany?.map((data: CompetencyDictionary, idx: number) => {
          const aggregate = data.competency_version_dictionaries[0]
          const date = new Date(data?.date_added || '')

          return (
            <>
              <div className="space-y-4" key={idx}>
                <div className="flex justify-between items-center pb-2">
                  <CustomTypography fweight="bold" fsize="14px">
                    {data.name.en}
                  </CustomTypography>
                  {date && (
                    <CustomTypography
                      fsize="12px"
                      fcolor="#a9a8a8"
                      fweight="semi-bold"
                    >
                      Terakhir diperbarui: {format(date, 'MMMM dd, yyyy')}
                    </CustomTypography>
                  )}
                </div>
                <div className="flex justify-between items-center">
                  <CustomTypography fsize="12px" fweight="semi-bold">
                    Kompetensi Model
                  </CustomTypography>
                  <Box sx={{width: '50%'}}>
                    <SummaryLinearProgress
                      variant="determinate"
                      value={getPercent(
                        aggregate.competency_individuals_aggregate.aggregate.avg
                          .level
                      )}
                    />
                  </Box>
                </div>
                <div className="flex justify-between items-center">
                  <CustomTypography fsize="12px" fweight="semi-bold">
                    Kompetensi Individu
                  </CustomTypography>
                  <Box sx={{width: '50%'}}>
                    <BorderLinearProgress
                      variant="determinate"
                      value={getPercent(
                        aggregate.competency_model_items_aggregate.aggregate.avg
                          .level
                      )}
                    />
                  </Box>
                </div>
                <div className="flex justify-between items-center">
                  <CustomTypography fsize="12px" fweight="semi-bold">
                    Celah
                  </CustomTypography>
                  <Box sx={{width: '50%'}}>
                    <CustomTypography fsize="12px" fweight="bold">
                      {countGap(
                        aggregate.competency_individuals_aggregate.aggregate.avg
                          .level,
                        aggregate.competency_model_items_aggregate.aggregate.avg
                          .level
                      )}
                    </CustomTypography>
                  </Box>
                </div>
              </div>
              <hr className="my-6" />
            </>
          )
        })
      )}
      {/* {} */}
    </>
  )
}

export default AddedByCompany
