import React from 'react'
import {FormGroup, TextField, Typography} from '@material-ui/core'

import {ILessonQuestion, IAnswerProps} from '../../../../../../interfaces'

type QuizLessonShortAnswerProps = {
  data: ILessonQuestion

  isDecrementQuestionNumberAvailable: boolean
  isIncrementQuestionNumberAvailable: boolean
  answers: IAnswerProps[]
  setAnswers: (answers: IAnswerProps[]) => void
}

const QuizLessonShortAnswer = (props: QuizLessonShortAnswerProps) => {
  const [questionExistInAnswers, setQuestionExistInAnswers] =
    React.useState<IAnswerProps>()

  React.useEffect(() => {
    setQuestionExistInAnswers(
      props.answers.find(
        (answer: IAnswerProps) => answer.questionId === props.data.id
      )
    )
  }, [props.data.id, props.answers])

  return (
    <>
      <FormGroup className="pl-4 pb-36 ">
        <Typography>Answer</Typography>
        <TextField
          className=" mr-4"
          multiline
          onChange={(e) => {
            if (questionExistInAnswers) {
              // TODO: exist
              const indexExistingAnswer = props.answers.findIndex(
                (item: IAnswerProps) => item.questionId === props.data.id
              )

              const _answers = [...props.answers]

              _answers[indexExistingAnswer] = {
                questionId: questionExistInAnswers.questionId,
                answers: [e.target.value],
              }

              props.setAnswers(_answers)
            } else {
              // TODO: not exist
              props.setAnswers([
                ...props.answers,
                {questionId: props.data.id, answers: [e.target.value]},
              ])
            }
          }}
          rows="5"
          variant="outlined"
          placeholder="Add description"
          value={questionExistInAnswers?.answers[0]}
        />
      </FormGroup>
    </>
  )
}

export default QuizLessonShortAnswer
