import React, {FC, ChangeEvent, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Tabs, Tab} from '@material-ui/core'
import {LEARNING_TYPES} from '../../../utils/constans'
import {
  CourseTab,
  BookTab,
  EventTab,
  SocialLearningTab,
  MicroLearningTab,
  ContributorTab,
} from './tabs'

import {LearnerCourseProvider} from '../../../provider/LearnerCourse.provider'
import {LearnerBookProvider} from '../../../provider/LearnerBook.provider'
import {LearnerEventProvider} from '../../../provider/LearnerEvent.provider'
import {LearnerMicroLearningProvider} from '../../../provider/LearnerMicroLearning.provider'
import {LearnerSocialLearningProvider} from '../../../provider/LearnerSocialLearning.provider'
import {LearnerLearningContributorProvider} from '../../../provider/LearnerLearningContributor.provider'

type ACTIVE_TAB =
  | 'COURSE'
  | 'BOOK'
  | 'EVENT'
  | 'MICRO_LEARNING'
  | 'SOCIAL_LEARNING'
  | 'LEARNING_CONTRIBUTOR'
  | string

const LearnerCatalogTab: FC = () => {
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = React.useState<ACTIVE_TAB>()
  const {active_tab} = useParams()

  useEffect(() => {
    setActiveTab(active_tab?.toUpperCase() || LEARNING_TYPES.COURSE)
  }, [active_tab])

  const changeTab = (event: ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue)

    navigate(`/learner/${newValue?.toLowerCase()}`)
  }

  return (
    <>
      <Tabs
        value={activeTab}
        indicatorColor="secondary"
        TabIndicatorProps={{
          style: {
            height: 5,
          },
        }}
        textColor="secondary"
        onChange={changeTab}
        className="p-5"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab label="Kursus" value={LEARNING_TYPES.COURSE} />
        <Tab label="Buku" value={LEARNING_TYPES.BOOK} />
        <Tab label="Acara" value={LEARNING_TYPES.EVENT} />
        <Tab
          label="Pembelajaran Sosial"
          value={LEARNING_TYPES.SOCIAL_LEARNING}
        />
        <Tab label="Pembelajaran Mikro" value={LEARNING_TYPES.MICRO_LEARNING} />
        <Tab
          label="Kontributor Pembelajaran"
          value={LEARNING_TYPES.LEARNING_CONTRIBUTOR}
        />
      </Tabs>
      {activeTab === LEARNING_TYPES.COURSE ? (
        <LearnerCourseProvider>
          <CourseTab />
        </LearnerCourseProvider>
      ) : activeTab === LEARNING_TYPES.BOOK ? (
        <LearnerBookProvider>
          <BookTab />
        </LearnerBookProvider>
      ) : activeTab === LEARNING_TYPES.EVENT ? (
        <LearnerEventProvider>
          <EventTab />
        </LearnerEventProvider>
      ) : activeTab === LEARNING_TYPES.MICRO_LEARNING ? (
        <LearnerMicroLearningProvider>
          <MicroLearningTab />
        </LearnerMicroLearningProvider>
      ) : activeTab === LEARNING_TYPES.SOCIAL_LEARNING ? (
        <LearnerSocialLearningProvider>
          <SocialLearningTab />
        </LearnerSocialLearningProvider>
      ) : activeTab === LEARNING_TYPES.LEARNING_CONTRIBUTOR ? (
        <LearnerLearningContributorProvider>
          <ContributorTab />
        </LearnerLearningContributorProvider>
      ) : null}
    </>
  )
}

export default LearnerCatalogTab
