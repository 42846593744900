// @ts-nocheck

import {format, parseISO, intervalToDuration, formatDistance} from 'date-fns'
import {dayShortName, microlearningType} from './constans'
import {SSO_URL} from './globals'

const idrFormatterPadded = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
})

const idrFormatter = new Intl.NumberFormat('id-ID', {
  style: 'currency',
  currency: 'IDR',
  minimumFractionDigits: 0,
})

export function truncateFractional(number: number, digit: number) {
  const pow = 10 ** digit
  return Math.trunc(number * pow) / pow
}

export function convertToRupiah(nominal: any, unpadded?: boolean) {
  const formatter = unpadded ? idrFormatter : idrFormatterPadded

  // The formatter does rounding when truncating fractions,
  // if you have 0.999, it should not mean that you have Rp 1,00
  const formatted = formatter.format(truncateFractional(nominal, 2))

  // Additionally, there shouldn't be a space between currency sign and nominal
  return formatted.replace(/\s+/g, '')
}

export function convertToAngka(rupiah) {
  return parseInt(rupiah.replace(/[^0-9]/g, ''), 10)
}

export function nextAlphabet(c) {
  return String.fromCharCode(c.charCodeAt(0) + 1)
}

export function countDownTimer(seconds) {
  const second = seconds / 1000
  let h = Math.floor(second / 3600)
  let m = Math.floor((second % 3600) / 60)
  let s = Math.floor((second % 3600) % 60)

  if (h < 10) h = '0' + h
  if (m < 10) m = '0' + m
  if (s < 10) s = '0' + s

  return h + ':' + m + ':' + s
}

export function convertHMSToMilliseconds(hms: string): number {
  let milliseconds: number = 0
  const splittedHMS = hms.split(':')
  // hours
  milliseconds += parseInt(splittedHMS[0]) * (60000 * 60)
  // minutes
  milliseconds += parseInt(splittedHMS[1]) * 60000
  // seconds
  milliseconds += parseInt(splittedHMS[2]) * 1000

  return milliseconds
}

export function createCookie(name, value, days) {
  let expires
  if (days) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toGMTString()
  } else {
    expires = ''
  }
  document.cookie = name + '=' + value + expires + '; path=/'
}

export function getCookie(c_name) {
  if (document.cookie.length > 0) {
    let c_start = document.cookie.indexOf(c_name + '=')
    if (c_start !== -1) {
      c_start = c_start + c_name.length + 1
      let c_end = document.cookie.indexOf(';', c_start)
      if (c_end === -1) {
        c_end = document.cookie.length
      }
      return unescape(document.cookie.substring(c_start, c_end))
    }
  }
  return ''
}

export function getWithin(start_date) {
  if (start_date !== '') {
    // var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    // var firstDate = new Date(start_date);
    // var secondDate = new Date();
    // var diffDays = Math.round(Math.round((secondDate.getTime() - firstDate.getTime()) / (oneDay)));

    // return diffDays
    // var date2 = new Date(start_date);
    // var date1 = new Date();
    // var diff = Math.floor(date1.getTime() - date2.getTime());
    // var day = 1000 * 60 * 60 * 24;

    // var days = Math.floor(diff / day);
    // // var weeks = Math.floor(diff / 7);
    // var months = Math.floor(days / 31);
    // var years = Math.floor(months / 12);

    // var message = '';
    // message += " was "
    // message += days + " days "
    // // message += weeks + " weeks "
    // message += months + " months "
    // message += years + " years ago \n"

    // return message
    // Calculate time between two dates:
    const date1 = new Date(start_date) // the date you already commented/ posted
    const date2 = new Date() // today

    let message = ''

    const diffInSeconds = Math.abs(date2 - date1) / 1000
    const days = Math.floor(diffInSeconds / 60 / 60 / 24)
    const hours = Math.floor((diffInSeconds / 60 / 60) % 24)
    const minutes = Math.floor((diffInSeconds / 60) % 60)

    const months = Math.floor(days / 31)
    const years = Math.floor(months / 12)

    // the below object is just optional
    // if you want to return an object instead of a message

    // check if difference is in years or months
    if (years === 0 && months === 0) {
      // show in days if no years / months
      if (days > 0) {
        if (days === 1) {
          message = days + ' day'
        } else {
          message = days + ' days'
        }
      } else if (hours > 0) {
        if (hours === 1) {
          message = hours + ' hour'
        } else {
          message = hours + ' hours'
        }
      } else {
        // show in minutes if no years / months / days
        if (minutes === 1) {
          message = minutes + ' minute'
        } else {
          message = minutes + ' minutes'
        }
      }
    } else if (years === 0 && months > 0) {
      // show in months if no years
      if (months === 1) {
        message = months + ' month'
      } else {
        message = months + ' months'
      }
    } else if (years > 0) {
      // show in years if years exist
      if (years === 1) {
        message = years + ' year'
      } else {
        message = years + ' years'
      }
    }

    return 'Within ' + message
  }
}

const nominalFormatter = new Intl.NumberFormat('id-ID')

export function formatNominal(nominal) {
  return nominalFormatter.format(nominal)
}

export function listenCookieChange() {
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      if (!getCookie('userData')) {
        window.location.href = SSO_URL
      }
    }
  })
}

export function strMaxLength(str, limit) {
  return str.length > limit ? str.substring(0, limit).trim() + '...' : str
}

export function defineLanguageCode(code) {
  switch (String(code)) {
    case 'en':
    case 'eng':
    case '2':
      return 'Bahasa Inggris'
    case 'ind':
    case '1':
    default:
      return 'Bahasa Indonesia'
  }
}

export function defineAcademyLevel(level) {
  return microlearningType?.[level] || microlearningType['1']
}

export function getPercent(value, total) {
  return (value / total) * 100
}

export function capitalize(word: string) {
  if (!word) return word
  return word[0].toUpperCase() + word.substr(1).toLowerCase()
}

export function convertToHour(_date: Date) {
  return format(parseISO(_date), 'HH:mm')
}

export function convertToShortDate(_date: Date) {
  return format(parseISO(_date), 'MMM dd, yyyy')
}
export const timeConvert = (num) => {
  if (num > 60) {
    const hours = Math.floor(num / 60)
    const hourText = hours > 1 ? ' Hours' : ' Hour'
    return hours + hourText
  } else {
    const minuteText = num > 1 ? ' Minutes' : ' Minute'
    return num + minuteText
  }
}

export const formattedTimeConvert = (minute, isOnlyHour = false) => {
  const end = (minute || 0) * 60 * 1000
  const duration = intervalToDuration({start: 0, end})

  let formatted = ''

  if (isOnlyHour) {
    const _minutes = minute % 60
    const _hours = minute % 60 === 0 ? minute / 60 : (minute - _minutes) / 60
    formatted = `${_hours}h ${_minutes}m`
  } else {
    formatted = end
      ? `${duration.years ? duration.years + 'Years' : ''}${
          duration.months ? duration.months + 'Months' : ''
        }${duration.days ? duration.days + 'd' : ''} ${
          duration.hours ? duration.hours + 'h' : ''
        } ${duration.minutes ? duration.minutes + 'm' : ''}`
      : '0 m'
  }

  return formatted
}

export const randomColor = `#${Math.floor(Math.random() * 16777215).toString(
  16
)}`
export function mapFilterResults(obj) {
  const ret = {}

  for (const prop in obj) {
    let val = obj[prop]

    if (Array.isArray(val)) {
      val = val.map((item) => item.value)
    }

    ret[prop] = val
  }

  // delete obj element if it contains zero data
  for (const p in ret) {
    if (ret[p].length === 0) {
      delete ret[p]
    }
  }

  return ret
}

/**
 *
 * @param str: string (example: 'top-learning-contributor')
 * @returns string (example: Top Learning Contributor)
 */
export const toTitleCase = (str = ' ') => {
  // Remove special char
  const text = str.replace(/[^a-zA-Z0-9]/g, ' ')

  return text.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
  })
}
export const trimString = (data, length, seeMore = false) => {
  return (
    data &&
    `${data.substring(0, length)} ${
      data.length > length && !seeMore ? '...' : ''
    }`
  )
}

export const generateShrtDaySchedule = (schedule: string) => {
  const tempSchedule = schedule?.split('')
  const rawAnswerQuestion: string[] = []
  tempSchedule?.forEach((data, key) => {
    data === '1' && rawAnswerQuestion.push(dayShortName[key])
  })

  return rawAnswerQuestion?.join(', ')
}

export const generateTimeSchedule = (scheduleTime: string) => {
  const replaceDot = scheduleTime?.replace(':', '.')
  return replaceDot?.substring(0, 5)
}

export const getTypeFile = (href = '') => {
  return href.split('.').slice(-1).pop().toLocaleLowerCase()
}

/**
 *
 * @param date {string}
 * @returns time distance from current date, example: 2 minutes ago, over 1 year ago.etc
 */

export const formateDateDistance = (date: string) => {
  const nowDate = new Date()
  const dateConvert = new Date(date)
  const formated = formatDistance(nowDate, dateConvert)
  return `${formated} ago`
}
