import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_MY_BOOKS = gql`
  query (
    $bookType: Int!
    $dateLastmonth: timestamptz
    $userId: uuid!
    $orderBy: [academy_books_order_by!]
    $status: [String!]
    $createdDate: timestamp_comparison_exp
    $limit: Int
    $offset: Int
    $search: String
  ) {
    academy_books_aggregate(
      where: {
        owner: {_eq: $userId}
        book_type: {_eq: $bookType}
        date_added: $createdDate
        status: {_in: $status}
        title: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_books(
      where: {
        owner: {_eq: $userId}
        book_type: {_eq: $bookType}
        date_added: $createdDate
        status: {_in: $status}
        title: {_ilike: $search}
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      title
      status
      isbn
      thumbnail
      date_added
      enrolled: academy_book_owners_aggregate {
        aggregate {
          count
        }
      }
      this_month: academy_book_owners_aggregate(
        where: {created_at: {_gte: $dateLastmonth}}
      ) {
        aggregate {
          count
        }
      }
      pricing
      pricing_idr
      average_rating
      total_rating: academy_book_reviews_aggregate {
        aggregate {
          count(columns: rating)
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_DETAILS = gql`
  query ($isbn: String, $owner: uuid) {
    academy_books(where: {isbn: {_eq: $isbn}, owner: {_eq: $owner}}) {
      id
      title
      summary
      isbn
      thumbnail
      date_added
      last_modified
      status
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_DETAILS_PROGRESS = gql`
  query ($isbn: String) {
    getProgressBookDetail(isbn: $isbn) {
      total
      information
      content
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_ACTIVITY_BREAKDOWN = gql`
  query (
    $startDate: String
    $endDate: String
    $timeType: String
    $isbn: String!
  ) {
    getActivityByTimeBookDetail(
      startDate: $startDate
      endDate: $endDate
      isbn: $isbn
      timeType: $timeType
    ) {
      wishlist_book
      enrollment_book
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_EARNING_BREAKDOWN = gql`
  query (
    $startDate: String
    $endDate: String
    $timeType: String
    $isbn: String!
  ) {
    getEarningByTimeBookDetail(
      startDate: $startDate
      endDate: $endDate
      timeType: $timeType
      isbn: $isbn
    ) {
      earning_book
    }
  }
`
export const GET_CONTRIBUTOR_BOOK_ENROLLMENT_STATS = gql`
  query ($isbn: String) {
    getDashboardLearnerContributorBookDetail(isbn: $isbn) {
      eighteen_to_twenty_four
      twenty_five_to_thirty_four
      thirty_five_to_forty_nine
      over_fifty
      male
      female
      total_learner
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_LANDING_DETAILS = gql`
  query ($isbn: String, $owner: uuid) {
    academy_books(where: {isbn: {_eq: $isbn}, owner: {_eq: $owner}}) {
      id
      title
      isbn
      summary
      thumbnail
      language
      level
      status
      academy_course_category {
        id
        academy_course_category {
          id
        }
      }
      pricing_idr
      edition
      copyright_year
      author_written
      publisher_written
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_REVIEWS = gql`
  query ($where: academy_book_reviews_bool_exp, $limit: Int, $offset: Int) {
    total: academy_book_reviews_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    reviews: academy_book_reviews(
      where: $where
      limit: $limit
      offset: $offset
      order_by: {id: desc}
    ) {
      id
      date_added
      review_text
      rating
      review_fields
      review_reply
      review_reply_date
      user: global_user {
        id
        name
        avatar
        placements: people_work_placements(
          limit: 1
          where: {status: {_eq: "ACTIVE"}}
        ) {
          id
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      globalUserByReviewReplyUser {
        id
        name
        avatar
        contributor_label
      }
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_RATING_AGGREGATE = gql`
  query ($isbn: String!) {
    total: academy_book_reviews_aggregate(where: {isbn: {_eq: $isbn}}) {
      aggregate {
        count
        avg {
          rating
        }
      }
    }
    total5: academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: 5}}
    ) {
      aggregate {
        count
      }
    }
    total4: academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: 4}}
    ) {
      aggregate {
        count
      }
    }
    total3: academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: 3}}
    ) {
      aggregate {
        count
      }
    }
    total2: academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: 2}}
    ) {
      aggregate {
        count
      }
    }
    total1: academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: 1}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CONTRIBUTOR_BOOK_LEARNERS = gql`
  query (
    $ownerId: uuid!
    $isbn: String!
    $search: String!
    $limit: Int
    $offset: Int
  ) {
    total_unfiltered: academy_book_owners_aggregate(
      where: {academy_book: {owner: {_eq: $ownerId}, isbn: {_eq: $isbn}}}
    ) {
      aggregate {
        count
      }
    }
    total: academy_book_owners_aggregate(
      where: {
        academy_book: {owner: {_eq: $ownerId}, isbn: {_eq: $isbn}}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
    ) {
      aggregate {
        count
      }
    }
    owners: academy_book_owners(
      where: {
        academy_book: {owner: {_eq: $ownerId}, isbn: {_eq: $isbn}}
        _or: [{global_user: {name: {_ilike: $search}}}]
      }
      limit: $limit
      offset: $offset
    ) {
      id
      global_user {
        id
        name
        avatar
        email
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
