import React, {ChangeEvent} from 'react'
import {
  Tabs,
  Tab,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Button,
  makeStyles,
} from '@material-ui/core'
import {LearningType} from '../../../utils/enum'

import {useNavigate, useLocation} from 'react-router-dom'
import CourseSearchFilterTab from './tabs/CourseSearchFilterTab.component'
import BookSearchFilterTab from './tabs/BookSearchFilterTab.component'
import EventSearchFilterTab from './tabs/EventSearchFilterTab.component'
import SocialLearningSearchFilterTab from './tabs/SocialLearningSearchFilterTab.component'
import MicroLearningSearchFilterTab from './tabs/MicroLearningSearchFilterTab.component'
import LearningContributorSearchFilterTab from './tabs/LearningContributorSearchFilterTab.component'
import classNames from 'classnames'

import {IAcademicFilter} from '../../../interfaces/Filter.interface'

type SearchFilterTabProps = {
  activeTab?: LearningType
  initialQuery: string | undefined
  searchKeyword: string
  academicFilter: IAcademicFilter
  priceFilter: string
  handleChangePriceFilter: (event: ChangeEvent<{value: any}>) => void
  contributorFilter: string
  handleChangeContributorFilter: (event: ChangeEvent<{value: any}>) => void
}

type SortResultFormProps = {
  searchKeyword: string
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  priceFilter: string
  handleChangePriceFilter: (event: ChangeEvent<{value: any}>) => void
  contributorFilter: string
  handleChangeContributorFilter: (event: ChangeEvent<{value: any}>) => void
  activeTab: number
}

const useStyles = makeStyles(() => ({
  buttonSwitchType: {
    width: 134,
    height: 45,
  },
  selectedFilterBy: {
    fontWeight: 'bold',
  },
  tabLabel: {
    color: '#666',
  },
}))

const SortResultForm = (props: SortResultFormProps) => {
  const classes = useStyles()
  const location = useLocation()
  // const learnerAcademicContext = React.useContext(LearnerAcademicContext)

  console.info(
    !location.pathname.includes('/learner/tabs-all/learning-contributor')
      ? props.priceFilter
      : props.contributorFilter
  )

  return (
    <div className="flex flex-1 flex-col px-5">
      <FormControl>
        <Select
          value={
            !location.pathname.includes(
              '/learner/tabs-all/learning-contributor'
            )
              ? props.priceFilter
              : props.contributorFilter
          }
          onChange={
            !location.pathname.includes(
              '/learner/tabs-all/learning-contributor'
            )
              ? props.handleChangePriceFilter
              : props.handleChangeContributorFilter
          }
          style={{width: 200}}
          disableUnderline={true}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          classes={{
            root: classes.selectedFilterBy,
          }}
        >
          <MenuItem
            value={'Latest'}
            className={
              location.pathname.includes(
                '/learner/tabs-all/learning-contributor'
              )
                ? 'hidden'
                : 'visible'
            }
          >
            Terbaru
          </MenuItem>
          {!location.pathname.includes('/learner/tabs-all/social-learning') && (
            <MenuItem
              value={
                !location.pathname.includes(
                  '/learner/tabs-all/learning-contributor'
                )
                  ? 'Price (Low - High)'
                  : 'Rating (Low - High)'
              }
            >
              {!location.pathname.includes(
                '/learner/tabs-all/learning-contributor'
              )
                ? 'Harga (Rendah - Tinggi)'
                : 'Rating (Rendah - Tinggi)'}
            </MenuItem>
          )}
          {!location.pathname.includes('/learner/tabs-all/social-learning') && (
            <MenuItem
              value={
                !location.pathname.includes(
                  '/learner/tabs-all/learning-contributor'
                )
                  ? 'Price (High - Low)'
                  : 'Rating (High - Low)'
              }
            >
              {!location.pathname.includes(
                '/learner/tabs-all/learning-contributor'
              )
                ? 'Harga (Tinggi - Rendah)'
                : 'Rating (Tinggi - Rendah)'}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <Typography className="mt-4" color="textSecondary">
        {props.activeTab === LearningType.COURSE
          ? props.countResult.countResultCourse
          : props.activeTab === LearningType.BOOK
          ? props.countResult.countResultBook
          : props.activeTab === LearningType.EVENT
          ? props.countResult.countResultEvent
          : props.activeTab === LearningType.SOCIAL_LEARNING
          ? props.countResult.countResultSocialLearning
          : props.activeTab === LearningType.MICRO_LEARNING
          ? props.countResult.countResultMicroLearning
          : props.activeTab === LearningType.LEARNING_CONTRIBUTOR
          ? props.countResult.countResultContributor
          : 0}
        &nbsp; Hasil dari &#8220;
        {props.searchKeyword}
        &#8221;
      </Typography>
    </div>
  )
}

const SearchFilterTab = (props: SearchFilterTabProps) => {
  const classes = useStyles()
  // const learnerAcademicContext = React.useContext(LearnerAcademicContext)
  const [activeTab, setActiveTab] = React.useState<LearningType>(
    props.activeTab || LearningType.COURSE
  )
  const [bookType, setBookType] = React.useState(2)
  const [countResult, setCountResult] = React.useState({
    countResultCourse: 0,
    countResultBook: 0,
    countResultEvent: 0,
    countResultMicroLearning: 0,
    countResultSocialLearning: 0,
    countResultContributor: 0,
  })

  const navigate = useNavigate()

  const handleChangeTab = (event: ChangeEvent<{}>, newValue: LearningType) => {
    setActiveTab(newValue)
    // learnerAcademicContext.changeActiveTab(newValue)
    navigate(
      newValue === 0
        ? `/learner/tabs-all/course/${props.searchKeyword}`
        : newValue === 1
        ? `/learner/tabs-all/book/${props.searchKeyword}`
        : newValue === 2
        ? `/learner/tabs-all/event/${props.searchKeyword}`
        : newValue === 3
        ? `/learner/tabs-all/micro-learning/${props.searchKeyword}`
        : newValue === 4
        ? `/learner/tabs-all/social-learning/${props.searchKeyword}`
        : `/learner/tabs-all/learning-contributor/${props.searchKeyword}`
    )
  }

  const renderFilterData = () => {
    const maxValue = '999999999'
    const filterData: IAcademicFilter = props.academicFilter

    filterData.priceMin =
      filterData.price === '1' && filterData.priceMin === ''
        ? '0'
        : filterData.price === '2' && filterData.priceMin === ''
        ? '1'
        : filterData.priceMin

    filterData.priceMax =
      filterData.price === '1' && filterData.priceMax === ''
        ? '0'
        : filterData.price === '2' && filterData.priceMax === ''
        ? maxValue
        : filterData.priceMax

    return filterData
  }

  return (
    <>
      <Tabs
        value={activeTab}
        // value={learnerAcademicContext.reducerState.activeCatalogTab}
        indicatorColor="secondary"
        TabIndicatorProps={{
          style: {
            height: 5,
            color: 'yellow',
          },
        }}
        textColor="secondary"
        onChange={handleChangeTab}
        className="p-5"
      >
        <Tab
          label="Kursus"
          value={LearningType.COURSE}
          classes={{textColorSecondary: classes.tabLabel}}
        />
        <Tab
          label="Buku"
          value={LearningType.BOOK}
          classes={{textColorSecondary: classes.tabLabel}}
        />
        <Tab
          label="Acara"
          value={LearningType.EVENT}
          classes={{textColorSecondary: classes.tabLabel}}
        />
        <Tab
          label="Pembelajaran Sosial"
          value={LearningType.SOCIAL_LEARNING}
          classes={{textColorSecondary: classes.tabLabel}}
        />
        <Tab
          label="Pembelajaran Mikro"
          value={LearningType.MICRO_LEARNING}
          classes={{textColorSecondary: classes.tabLabel}}
        />
        <Tab
          label="Kontributor Pembelajaran"
          value={LearningType.LEARNING_CONTRIBUTOR}
          classes={{textColorSecondary: classes.tabLabel}}
        />
      </Tabs>
      {activeTab === LearningType.COURSE ? (
        <>
          <SortResultForm
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            activeTab={activeTab}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <CourseSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            activeTab={activeTab}
          />
        </>
      ) : activeTab === LearningType.BOOK ? (
        <>
          <div className="mb-10 ml-5">
            <Button
              variant="contained"
              color={bookType === 2 ? 'primary' : 'default'}
              className={classes.buttonSwitchType}
              onClick={() => setBookType(2)}
            >
              Buku Elektronik
            </Button>
            <Button
              variant="contained"
              color={bookType === 3 ? 'primary' : 'default'}
              className={classNames(classes.buttonSwitchType, 'ml-8')}
              onClick={() => setBookType(3)}
            >
              Buku Audio
            </Button>
          </div>
          <SortResultForm
            activeTab={activeTab}
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <BookSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            bookType={bookType}
            activeTab={activeTab}
          />
        </>
      ) : activeTab === LearningType.EVENT ? (
        <>
          <SortResultForm
            activeTab={activeTab}
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <EventSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            activeTab={activeTab}
          />
        </>
      ) : activeTab === LearningType.MICRO_LEARNING ? (
        <>
          <SortResultForm
            activeTab={activeTab}
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <MicroLearningSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            activeTab={activeTab}
          />
        </>
      ) : activeTab === LearningType.SOCIAL_LEARNING ? (
        <>
          <SortResultForm
            activeTab={activeTab}
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <SocialLearningSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            activeTab={activeTab}
          />
        </>
      ) : activeTab === LearningType.LEARNING_CONTRIBUTOR ? (
        <>
          <SortResultForm
            activeTab={activeTab}
            searchKeyword={props.searchKeyword}
            countResult={countResult}
            priceFilter={props.priceFilter}
            handleChangePriceFilter={props.handleChangePriceFilter}
            contributorFilter={props.contributorFilter}
            handleChangeContributorFilter={props.handleChangeContributorFilter}
          />
          <LearningContributorSearchFilterTab
            searchKeyword={props.searchKeyword}
            academicFilter={renderFilterData()}
            priceFilter={props.priceFilter}
            contributorFilter={props.contributorFilter}
            countResult={countResult}
            setCountResult={setCountResult}
            activeTab={activeTab}
          />
        </>
      ) : null}
    </>
  )
}

export default SearchFilterTab
