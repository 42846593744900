import React from 'react'
import {useQuery} from '@apollo/client'

import {CircularProgress, Link} from '@material-ui/core'

import CommentChild from './CommentChild'

import {USER_ID} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_COURSE_COMMENTS_REPLIES} from '../../../../../graphql/queries'

interface ChunkedChildListProps {
  discussionId: number
  sort?: 'asc' | 'desc'
  offset: number
  limit: number
  onShowMore?: () => void
}

const ChunkedChildList = (props: ChunkedChildListProps) => {
  const {discussionId, sort = 'asc', offset, limit, onShowMore} = props

  const {data, refetch} = useQuery(GET_COURSE_COMMENTS_REPLIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      discussionId,
      order: {date_added: sort},
      offset,
      limit,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  if (!data) {
    return (
      <div className="grid place-items-center mb-6">
        <CircularProgress />
      </div>
    )
  }

  const responses = data.responses

  if (responses.length < 1) {
    return null
  }

  return (
    <>
      {responses.map((response: any) => (
        <CommentChild key={response.id} data={response} refetch={refetch} />
      ))}

      {onShowMore && responses.length >= limit && (
        <div className="mb-6">
          <Link
            component="button"
            color="secondary"
            className="text-sm"
            onClick={onShowMore}
          >
            See {sort === 'asc' ? 'More' : 'Older'} Comments
          </Link>
        </div>
      )}
    </>
  )
}

const MemoizedChunkedChildList = React.memo(ChunkedChildList)

export interface CommentChildListingProps {
  discussionId: number
  sort?: 'asc' | 'desc'
}

const CommentChildListing = (props: CommentChildListingProps) => {
  const {discussionId, sort} = props

  const [pages, setPages] = React.useState(1)
  const MAX_ITEMS = 5

  const handleShowMore = React.useCallback(() => {
    return setPages((pages) => pages + 1)
  }, [])

  return (
    <>
      {Array.from({length: pages}, (_, idx) => (
        <MemoizedChunkedChildList
          key={idx}
          discussionId={discussionId}
          sort={sort}
          offset={idx * MAX_ITEMS}
          limit={MAX_ITEMS}
          onShowMore={idx === pages - 1 ? handleShowMore : undefined}
        />
      ))}
    </>
  )
}

export default CommentChildListing
