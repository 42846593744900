import {gql} from '@apollo/client'

export const INSERT_REVIEW_COURSE = gql`
  mutation (
    $userId: uuid!
    $courseId: Int!
    $reviewText: String!
    $rating: Int!
  ) {
    update_academy_course_enrollments(
      where: {user_id: {_eq: $userId}, course_id: {_eq: $courseId}}
      _set: {rating: $rating, review_text: $reviewText}
    ) {
      affected_rows
    }
  }
`

export const UPDATE_REVIEW_COURSE = gql`
  mutation (
    $userId: uuid!
    $courseId: Int!
    $reviewText: String!
    $rating: Int!
  ) {
    update_academy_course_enrollments(
      where: {user_id: {_eq: $userId}, course_id: {_eq: $courseId}}
      _set: {rating: $rating, review_text: $reviewText}
    ) {
      affected_rows
    }
  }
`
