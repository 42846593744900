import React from 'react'
import { useField, useForm } from 'react-final-form'

import {
  FormControl,
  FormControlLabel,
  Switch,
  Typography,
} from '@material-ui/core'

import { type DateRange } from '@smartasn/wlb-utils-components'

import {
  combineValidators,
  isRequired,
} from '../../../../../../components/input-forms/validators'
import DateRangeModal from '../../../../../../components/modal/DateRangeModal'

import DateTimeField from './DateTimeField'

const validateEndDate = combineValidators(isRequired, (value, context: any) => {
  if (!context) {
    return false
  }

  const start = new Date(context.datestart)
  const end = new Date(value)

  return (
    start.getTime() > end.getTime() &&
    `End date cannot be earlier than start date`
  )
})

const ScheduleField = () => {
  const form = useForm()
  const { input } = useField('schedule_fields.all_day')

  const [isOpen, setIsOpen] = React.useState(false)
  const [initialRange, setInitialRange] = React.useState<DateRange>({})

  const isAllDay = !!input.value

  const handleOpen = () => {
    const { datestart, dateend } = form.getState().values

    setInitialRange({
      startDate: datestart ? new Date(datestart) : undefined,
      endDate: dateend ? new Date(dateend) : undefined,
    })

    setIsOpen(true)
  }

  return (
    <>
      <DateRangeModal
        open={isOpen}
        initialRange={initialRange}
        onClose={() => setIsOpen(false)}
        onSubmit={(range) => {
          const state = form.getState()
          const { datestart, dateend } = state.values

          const start = new Date(range.startDate)
          const end = new Date(range.endDate)

          if (datestart) {
            const date = new Date(datestart)
            start.setHours(
              date.getHours(),
              date.getMinutes(),
              date.getSeconds(),
              date.getMilliseconds()
            )
          }

          if (dateend) {
            const date = new Date(dateend)
            end.setHours(
              date.getHours(),
              date.getMinutes(),
              date.getSeconds(),
              date.getMilliseconds()
            )
          }

          form.batch(() => {
            form.change('datestart', start.toISOString())
            form.change('dateend', end.toISOString())
          })

          setIsOpen(false)
        }}
      />

      <FormControl>
        <Typography
          component="label"
          color="textSecondary"
          className="block text-sm"
          gutterBottom
        >
          Time*
        </Typography>

        <FormControlLabel
          control={
            <Switch
              checked={isAllDay}
              onChange={(ev, checked) => {
                input.onChange(checked)

                // NOTE(intrnl): we want it checking all-day to properly reflect
                // on the datestart and dateend
                if (checked) {
                  const state = form.getState()
                  const { datestart, dateend } = state.values

                  form.batch(() => {
                    if (datestart) {
                      const date = new Date(datestart)
                      date.setHours(0, 0, 0, 0)
                      form.change('datestart', date.toISOString())
                    }

                    if (dateend) {
                      const date = new Date(dateend)
                      date.setHours(23, 59, 59, 999)
                      form.change('dateend', date.toISOString())
                    }
                  })
                }
              }}
            />
          }
          label="All Day"
          className="sm:max-w-xs"
        />
      </FormControl>

      <DateTimeField
        name="datestart"
        validate={isRequired}
        showTime={isAllDay}
        label="Start*"
        onButtonClick={handleOpen}
      />

      <DateTimeField
        name="dateend"
        validate={validateEndDate}
        showTime={isAllDay}
        endTime
        label="End*"
        onButtonClick={handleOpen}
      />
    </>
  )
}

export default ScheduleField
