import BookActivityCard from './BookActivityCard'
import BookEarningsCard from './BookEarningsCard'
import BookEnrollmentsCard from './BookEnrollmentsCard'

const ContributorBookDashboardPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <BookEnrollmentsCard />
      <BookActivityCard />
      <BookEarningsCard />
    </div>
  )
}

export default ContributorBookDashboardPage
