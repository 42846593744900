import gql from 'graphql-tag'

export const GET_RECOMMENDED_CATEGORY_CLASSROOM = gql`
  query ($userId: uuid!) {
    academy_course_categories(
      where: {classrooms: {classroom_members: {member_user_id: {_eq: $userId}}}}
    ) {
      id
      name
    }
  }
`
