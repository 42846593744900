// NOTE(intrnl): move this to another folder if this is being used outside of
// contributor dashboard.

import React from 'react'
import { Field, useForm, useFormState } from 'react-final-form'
import { useFieldArray, type UseFieldArrayConfig } from 'react-final-form-arrays'

import {
  Button,
  FormControl,
  FormHelperText,
  Typography,
} from '@material-ui/core'

import { AttachmentItem, getFileType } from '@smartasn/wlb-utils-components'

import { showErrorOnChange } from '../../input-forms/validators'
import { useDropzone } from 'react-dropzone'
import AddIcon from '@material-ui/icons/Add'
import FieldTooltip from '../../utils-components/FieldTooltip'

export type Accept = Record<string, string[]>

export interface AttachmentFieldProps {
  name: string
  validate?: UseFieldArrayConfig<Attachment>['validate']
  format?: (value: any) => Attachment
  maxSize?: number
  maxFiles?: number
  accept?: Accept
  label?: string
}

export type AttachmentType = string | File

export interface Attachment {
  name: string
  type?: string
  url: AttachmentType
  size: number
}

export const DEFAULT_MAX_SIZE = 25 * 1000 * 1000 // 25 MB

export const DEFAULT_ACCEPT = {
  'application/pdf': ['.pdf'],
  'application/msword': ['.doc'],
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
    '.docx',
  ],
  'application/vnd.ms-excel': ['.xls'],
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
    '.xlsx',
  ],
  'application/vnd.ms-powerpoint': ['.ppt'],
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
    '.pptx',
  ],
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg', '.pjp', '.pjpeg'],
  'image/gif': ['.gif'],
  'image/tiff': ['.tiff'],
}

export const formatAccept = (accepts: Accept) => {
  const documents: string[] = []
  const images: string[] = []

  for (const mime in accepts) {
    const exts = accepts[mime]

    if (mime.startsWith('application/')) {
      documents.push(...exts)
    } else if (mime.startsWith('image/')) {
      images.push(...exts)
    }
  }

  return { documents, images }
}

export const objectMapping = new WeakMap<File, string>()

const AttachmentFieldInfo = (props: { accept: Accept; maxSize: number }) => {
  const { accept, maxSize } = props

  const { documents, images } = React.useMemo(() => {
    return formatAccept(accept)
  }, [accept])

  return (
    <FieldTooltip
      title={
        <>
          <p>Appropriate file extensions</p>

          <ul className="list-disc pl-4 my-2">
            {documents.length > 0 && <li>Document ({documents.join(', ')})</li>}
            {images.length > 0 && <li>Image ({images.join(', ')})</li>}
          </ul>

          <p>Max size: {maxSize / 1000 / 1000} MB</p>
        </>
      }
    />
  )
}

const AttachmentField = (props: AttachmentFieldProps) => {
  const {
    name,
    validate,
    format,
    maxSize = DEFAULT_MAX_SIZE,
    maxFiles,
    accept = DEFAULT_ACCEPT,
    label,
  } = props

  // NOTE(intrnl): `useFieldArray` doesn't give you access to submitting state
  // for some reason.
  const { submitting } = useFormState({ subscription: { submitting: true } })

  const form = useForm()

  const { fields, meta } = useFieldArray<Attachment>(name, {
    validate,
  })

  const { error, submitError } = meta
  const isError = showErrorOnChange(meta)

  const isOverMaxFiles =
    maxFiles !== undefined && (fields.length || 0) >= maxFiles

  const dropzone = useDropzone({
    disabled: submitting || isOverMaxFiles,
    maxSize,
    accept,
    onDrop: (files) => {
      if (files.length > 0) {
        form.batch(() => {
          for (const file of files) {
            const localUrl = URL.createObjectURL(file)
            objectMapping.set(file, localUrl)

            fields.push({
              name: file.name,
              type: getFileType(file.name),
              url: file,
              size: file.size,
            })
          }
        })
      }
    },
  })

  return (
    <FormControl>
      {label && (
        <Typography
          component="label"
          color="textSecondary"
          className="block text-sm"
          gutterBottom
        >
          {label} <AttachmentFieldInfo accept={accept} maxSize={maxSize} />
        </Typography>
      )}

      <input {...dropzone.getInputProps()} />

      <div className="flex flex-col gap-2 max-w-lg">
        <Button
          variant={dropzone.isDragAccept ? 'outlined' : 'contained'}
          disabled={isOverMaxFiles}
          {...dropzone.getRootProps({
            className: `h-16 w-16 ${!isOverMaxFiles ? 'bg-white' : ''}`,
          })}
        >
          <AddIcon fontSize="large" color="secondary" />
        </Button>

        {fields.map((name, index) => (
          <Field key={index} name={name}>
            {({ input: { value, onChange } }) => {
              const file = format ? format(value) : value

              return (
                <AttachmentItem
                  name={file.name}
                  url={
                    file.url instanceof File
                      ? objectMapping.get(file.url)
                      : file.url
                  }
                  type={file.type}
                  size={file.size}
                  disabled={submitting}
                  onNameChange={(ev) => {
                    onChange({ ...file, name: ev.target.value })
                  }}
                  onRemove={() => fields.remove(index)}
                />
              )
            }}
          </Field>
        ))}
      </div>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default AttachmentField
