import {useTitle} from 'hoofd'

import Header from '../../components/header/HeaderComponent'
import {DetailWrapper} from '../../components/learner/event/Event.style'
import EventDetail from '../../components/learner/event/EventDetail.component'
import BackNavigation from '../../components/navigation/BackNavigation.component'

import useStyles, {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/GlobalStyles'

type EventPageProps = {
  isPreview?: boolean
}

const Event = (props: EventPageProps) => {
  const {isPreview} = props

  useTitle('Detil Acara')
  const classes = useStyles()

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs value={'event'} className={classes.tabs} centered>
          <StyledTab label="Acara" value="event" />
        </StyledTabs>
      </TabsWrapper>

      <DetailWrapper>
        <div className="w-full flex flex-col">
          <BackNavigation label="Kembali ke Katalog Pembelajaran" />

          <EventDetail isPreview={isPreview} />
        </div>
      </DetailWrapper>
    </>
  )
}

export default Event
