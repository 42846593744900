export enum LearningType {
  'COURSE',
  'BOOK',
  'EVENT',
  'MICRO_LEARNING',
  'SOCIAL_LEARNING',
  'LEARNING_CONTRIBUTOR',
}

export enum QueryFetchPolicy {
  'cache-first',
  'cache-only',
  'network-only',
  'cache-and-network',
  'no-cache',
  'standby ',
}
