import {gql} from '@apollo/client'

export const INSERT_MICRO_WISHLIST = gql`
  mutation addWishlist($object: [microlearning_wishlists_insert_input!]!) {
    insert_microlearning_wishlists(objects: $object) {
      returning {
        id
        microlearning
        user_id
        name
        description
        price
        icon_url
      }
    }
  }
`

export const REMOVE_MICRO_WISHLIST = gql`
  mutation ($microlearning: uuid!) {
    update_microlearning_wishlists(
      where: {microlearning: {_eq: $microlearning}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        microlearning
      }
    }
  }
`

export const INSERT_SETTING_MICRO = gql`
  mutation (
    $userId: uuid
    $microlearningId: uuid
    $dailyQuestion: Int
    $schedule: String
    $scheduleTime: time
  ) {
    update_microlearning_members(
      where: {
        user_id: {_eq: $userId}
        microlearning_id: {_eq: $microlearningId}
      }
      _set: {
        daily_question: $dailyQuestion
        schedule: $schedule
        schedule_time: $scheduleTime
      }
    ) {
      affected_rows
    }
  }
`

export const INSERT_MY_REVIEW_MICRO = gql`
  mutation ($microlearningId: String!, $description: String!, $star: Int!) {
    setMicrolearningReview(
      microlearningId: $microlearningId
      star: $star
      description: $description
    ) {
      success
    }
  }
`
