import {Typography} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'

type RatingProps = {
  averageRating: number
  totalEnrollment?: number
  isSmallRating?: boolean
  ratingFilter?: boolean
  noExtraPadding?: boolean
  showTextEnrollment?: boolean
}

const Rating = (props: RatingProps) => {
  const round = Math.round(props.averageRating || 0)

  return (
    <div
      className={
        props.noExtraPadding ? 'flex flex-row' : 'flex flex-1 flex-row'
      }
    >
      <div
        className={`flex flex-row content-start justify-start items-center ${
          props.isSmallRating ? '' : 'mt-6'
        }`}
      >
        {Array.from({length: 5}, (_, idx) => (
          <StarIcon
            key={idx}
            color={idx + 1 <= round ? 'secondary' : 'disabled'}
            style={{fontSize: props.isSmallRating ? 15 : 25}}
          />
        ))}
      </div>
      {!props.ratingFilter && (
        <div
          className={`flex items-center ml-1 ${
            props.isSmallRating ? '' : 'mt-6'
          }`}
        >
          <Typography
            variant="body2"
            className={`flex flex-row ${props.isSmallRating ? 'ml-2' : 'px-1'}`}
            style={props.isSmallRating ? {fontSize: '12px'} : {}}
          >
            <b>{(props.averageRating || 0).toFixed(1)}</b>
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={
              props.isSmallRating ? {fontSize: '12px', marginLeft: '3px'} : {}
            }
          >
            ({props.totalEnrollment || 0}
            {props.showTextEnrollment && ' Penilaian'})
          </Typography>
        </div>
      )}
    </div>
  )
}

export default Rating
