import {levelOptions} from '../../utils/constans'
import {HASURA_ROLE_USER_CONTEXT} from '../../utils/globals'
import {
  GET_ACADEMY_CATEGORIES,
  GET_GLOBAL_LANGUAGE,
} from '../../graphql/queries'
import {IDataLanguage, IRecommendedCategories} from '../../interfaces'

interface Variables {
  search: string
  limit: number
  _nin: number[]
}

export const listFilterBooks = [
  {
    name: 'Kategori',
    fieldName: 'category',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_ACADEMY_CATEGORIES,
        options: HASURA_ROLE_USER_CONTEXT,
        getVariables: ({search, limit, _nin}: Variables) => {
          return {
            where: {
              id: {_nin: _nin},
              parent: {_is_null: false},
              _or: [
                {name: {_ilike: search}},
                {academy_course_category: {name: {_ilike: search}}},
              ],
            },
            limit,
          }
        },
        setData: (data: IRecommendedCategories) => {
          if (data?.academy_course_categories) {
            const _data = data.academy_course_categories.map((res) => ({
              value: res.id,
              label: `${
                res.academy_course_category?.name
                  ? res.academy_course_category.name + ' - '
                  : ''
              } ${res.name}`,
            }))
            return [_data, data.total?.aggregate.count]
          }
        },
      },
    },
  },
]

export const listFilter = [
  ...listFilterBooks,
  {
    name: 'Level',
    fieldName: 'level',
    type: 'checkbox',
    options: {
      list: levelOptions,
    },
  },
  {
    name: 'Bahasa',
    fieldName: 'language',
    type: 'checkbox',
    options: {
      fetch: {
        query: GET_GLOBAL_LANGUAGE,
        options: HASURA_ROLE_USER_CONTEXT,
        getVariables: ({search, limit, _nin}: Variables) => {
          return {
            where: {id: {_nin: _nin}, _or: [{name: {_ilike: search}}]},
            limit,
          }
        },
        setData: (data: IDataLanguage) => {
          if (data.global_languages) {
            const _data = data?.global_languages.map((res) => ({
              value: res.id,
              label: res.name,
            }))
            return [_data, _data.length]
          }
        },
      },
    },
  },
]

export const listFilterSocial = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          label: 'On Going',
          value: 'ongoing',
        },
        {
          label: 'Done',
          value: 'done',
        },
      ],
    },
  },
]

export const listFilterContributorOrders = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {value: 'pending', label: 'New Order'},
        {value: 'completed', label: 'Done'},
        {value: 'rejected', label: 'Rejected'},
      ],
    },
  },
  {
    name: 'Date',
    fieldName: 'date',
    type: 'date',
  },
  {
    name: 'Price',
    fieldName: 'price',
    type: 'salary',
    min: 0,
    max: 999_999_999,
  },
]
