import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  TextField,
  Typography,
  makeStyles,
  createStyles,
} from '@material-ui/core'

import React, {useState, ChangeEvent} from 'react'
import {ICourseAddReviewPayload} from '../../interfaces/Course.interface'

type YourFeedbackProps = {
  onSubmit: (arg: ICourseAddReviewPayload) => void

  isReviewed: number | null
  target: string
  rating: number
  reviewText?: string
}

const useStyles = makeStyles(() =>
  createStyles({
    iconClose: {
      position: 'absolute',
      right: 24,
      cursor: 'pointer',
    },
    text: {
      color: '#014a62',
      fontSize: 14,
      margin: '12px 0px',
    },
  })
)

const RATING_DATA = [1, 2, 3, 4, 5]

const INITIAL_STATE_DIALOG = {
  open: false,
  rating: 0,
  description: '',
}

const YourFeedback = (props: YourFeedbackProps) => {
  const [dialog, setDialog] = useState(INITIAL_STATE_DIALOG)
  const classes = useStyles()

  const handleOpenDialog = () => {
    setDialog((e) => ({...e, open: true}))
  }

  const handleCloseDialog = () => {
    setDialog(INITIAL_STATE_DIALOG)
  }

  const handleClickRating = (rating: number) => {
    setDialog((e) => ({...e, rating}))
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDialog((prev) => ({
      ...prev,
      description: e.target?.value,
    }))
  }

  const handleSubmit = () => {
    props.onSubmit({
      rating: dialog.rating,
      description: dialog.description,
    })

    return handleCloseDialog()
  }

  const handleOpenEdit = () => {
    setDialog({
      ...dialog,
      rating: props.isReviewed || 0,
      description: props.reviewText || '',
    })
    handleOpenDialog()
  }

  return (
    <>
      <div>
        <Typography variant={'body1'} className="mt-6 font-bold">
          Your Review
        </Typography>
        <div className="flex flex-row my-5">
          {RATING_DATA.map((res, i) => {
            return (
              <Icon
                key={`${i}star`}
                color={res <= props.rating ? 'secondary' : 'disabled'}
                className={`text-5xl`}
              >
                star
              </Icon>
            )
          })}
        </div>
        <p className={classes.text}>{props.reviewText}</p>
        {!props.isReviewed ? (
          <Typography
            onClick={handleOpenDialog}
            className="cursor-pointer mb-6"
            color="secondary"
            variant="body1"
          >
            Tambahkan Ulasan
          </Typography>
        ) : (
          <Typography
            onClick={handleOpenEdit}
            className="cursor-pointer mb-6"
            color="secondary"
            variant="body1"
          >
            Edit Review
          </Typography>
        )}
      </div>
      <Divider />

      <Dialog
        open={dialog.open}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <div className="flex items-center relative">
          <DialogTitle classes={{root: 'text-center'}}>Review</DialogTitle>
          <Icon onClick={handleCloseDialog} className={classes.iconClose}>
            close
          </Icon>
        </div>
        <DialogContent className="pb-8">
          <p className={classes.text}>Rate {props.target}</p>
          {RATING_DATA.map((res, i) => (
            <Icon
              key={`${i}`}
              onClick={() => handleClickRating(res)}
              color={res <= dialog.rating ? 'secondary' : 'disabled'}
              className={`text-grey text-5xl cursor-pointer`}
            >
              star
            </Icon>
          ))}
          <p className={classes.text}>Description</p>
          <TextField
            value={dialog.description}
            onChange={handleChange}
            rows={4}
            fullWidth
            variant="outlined"
            multiline
            placeholder="Write your review"
          />
          <div className="mt-24 flex flex-row justify-right">
            <Button className="ml-auto mr-8" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="primary"
              size="large"
            >
              Submit
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default YourFeedback
