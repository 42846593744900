import React, {type ReactNode} from 'react'
import {useField, type UseFieldConfig} from 'react-final-form'
import {useDropzone} from 'react-dropzone'

import {Button, FormHelperText, IconButton, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'

import {showErrorOnChange} from '../../input-forms/validators'
import ImageCropModal from '../../modal/ImageCropModal'

export type ImageThumbnail = string | Blob

export interface ImageThumbnailFieldProps {
  name: string
  validate?: UseFieldConfig<ImageThumbnail>['validate']
  format?: UseFieldConfig<ImageThumbnail>['format']
  parse?: UseFieldConfig<ImageThumbnail>['parse']
  label?: string
  description?: ReactNode
  buttonLabel?: string
}

interface ImageRef {
  value: ImageThumbnail
  url: string
}

const ImageThumbnailField = (props: ImageThumbnailFieldProps) => {
  const {name, validate, format, parse, label, description, buttonLabel} = props

  const [isOpen, setIsOpen] = React.useState(false)

  // NOTE(intrnl): this is to hold the image that was uploaded via drag and drop,
  // we want it to be passed through to the image crop modal first.
  const [tempSource, setTempSource] = React.useState<string>()

  const {
    input: {value, onChange},
    meta,
  } = useField<ImageThumbnail>(name, {
    validate,
    format,
    parse,
    beforeSubmit: () => !isOpen,
  })

  const dropzone = useDropzone({
    disabled: meta.submitting,
    noClick: true,
    accept: {
      'image/*': [],
    },
    onDrop: React.useCallback((acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const reader = new FileReader()

        reader.addEventListener('load', () => {
          setTempSource(reader.result!.toString())
          setIsOpen(true)
        })

        reader.readAsDataURL(acceptedFiles[0])
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  })

  const imageRef = React.useRef<ImageRef>()

  if (!imageRef.current || imageRef.current.value !== value) {
    if (imageRef.current && imageRef.current.value instanceof Blob) {
      URL.revokeObjectURL(imageRef.current.url)
    }

    const url = value instanceof Blob ? URL.createObjectURL(value) : value
    imageRef.current = {value, url}
  }

  const isError = showErrorOnChange(meta)

  const toggleCropModal = () => {
    setIsOpen(!isOpen)
  }

  const errorLabel = isError ? (
    <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>
  ) : null

  React.useEffect(() => {
    return () => {
      if (imageRef.current && imageRef.current.value instanceof Blob) {
        URL.revokeObjectURL(imageRef.current.url)
      }
    }
  }, [])

  return (
    <div>
      <Typography
        component="label"
        color={isError ? 'error' : 'textPrimary'}
        className="block text-sm"
        gutterBottom
      >
        {label}
      </Typography>

      {description && (
        <Typography color="textSecondary" className="mb-2 text-sm">
          {description}
        </Typography>
      )}

      <div {...dropzone.getRootProps({className: 'aspect-video h-40 sm:h-64'})}>
        <input {...dropzone.getInputProps()} />

        {value ? (
          <div className="aspect-video h-40 sm:h-64 rounded overflow-hidden relative">
            <img
              src={imageRef.current!.url}
              className="h-full w-full object-cover"
            />

            <div className="group flex items-center justify-center gap-4 absolute z-10 inset-0 rounded-none transition-colors bg-black bg-opacity-0 hover:bg-opacity-50 focus-within:bg-opacity-50">
              <IconButton
                title="Replace image"
                disabled={meta.submitting}
                onClick={toggleCropModal}
                className="text-white invisible group-hover:visible group-focus-within:visible"
              >
                <EditIcon />
              </IconButton>
            </div>
          </div>
        ) : (
          <Button
            disabled={meta.submitting}
            onClick={toggleCropModal}
            variant="outlined"
            color={dropzone.isDragAccept ? 'secondary' : 'default'}
            className="block border-2 border-dashed bg-gray-50 normal-case h-full w-full"
          >
            <AddIcon fontSize="large" color="action" />
            <Typography
              color={dropzone.isDragAccept ? 'secondary' : 'textSecondary'}
              className="mt-2 text-sm"
            >
              {buttonLabel}
            </Typography>
            <Typography
              color={dropzone.isDragAccept ? 'secondary' : 'textSecondary'}
              className="mt-2 text-sm"
            >
              Drag the file here
            </Typography>
          </Button>
        )}
      </div>

      {errorLabel}

      <ImageCropModal
        open={isOpen}
        aspect={16 / 9}
        initialSource={tempSource}
        onClose={toggleCropModal}
        onSubmit={(blob) => {
          onChange(blob)
          toggleCropModal()
        }}
      />
    </div>
  )
}

export default ImageThumbnailField
