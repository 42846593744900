import {useState, useEffect} from 'react'
import {useQuery} from '@apollo/client'
import {
  GET_WISHLIST_MICROLEARNING,
  GET_USER_CART,
} from '../../../../graphql/queries'
import {USER_ID} from '../../../../utils/globals'
import {LearningType} from '../../../../utils/enum'
import {useSnackbar} from 'notistack'
import {DELETE_MICROLEARNING_WISHLIST} from '../../../../provider/services/MicroLearning.service'
import {ADD_TO_CART} from '../../../../provider/services/Course.service'
import {
  IMicroLearningWishlist,
  IMicroLearning,
} from '../../../../interfaces/Microlearning.interface'

import LearnerCatalogCard from '../../../../components/learner/catalog/LearnerCatalogCard.component'
import NoListComponent from '../../../../components/empty-state/NoList.component'
import LoadingCard from '../../../../components/loading/LoadingCard.component'

type courseWishlistProps = {
  searchValue: any
}

const MicroLearningWishlistComponent = (props: courseWishlistProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [list, setList] = useState<IMicroLearningWishlist[]>([])
  const [wishlistLoading, setWishlistLoading] = useState(false)
  const [tempId, setTempId] = useState('')

  const {data, loading, refetch} = useQuery(GET_WISHLIST_MICROLEARNING, {
    variables: {user_id: USER_ID},
  })

  const {
    data: cartList,
    loading: cartLoading,
    refetch: refetchCart,
  } = useQuery(GET_USER_CART)

  const [deleteFromWishlist] = DELETE_MICROLEARNING_WISHLIST()
  const [addToCart] = ADD_TO_CART()

  useEffect(() => {
    if (data) {
      setList(
        data.microlearning_wishlists.filter(
          (item: IMicroLearningWishlist) => item.microlearningByMicrolearning
        )
      )
    }
  }, [data])

  const removeFromWishlist = (id: string) => {
    setWishlistLoading(true)
    setTempId(id)
    deleteFromWishlist({
      variables: {
        id: id,
      },
    })
      .then(() => {
        refetch()
        enqueueSnackbar('Micro learning removed from wishlist', {
          variant: 'success',
        })
      })
      .catch(() => {
        enqueueSnackbar('Remove micro learning from wishlish failed', {
          variant: 'error',
        })
      })
      .finally(() => {
        setTempId('')
        setWishlistLoading(false)
      })
  }

  const handleAddToCart = (item: IMicroLearning) => {
    const variables = {
      objects: [
        {
          item_id: String(item.id),
          item_object: {
            name: item.name,
            image: item.icon_url,
            price: item?.price || 0,
            weight: 0,
            note: '',
          },
          item_table: 'microlearnings',
          item_vendor: item.global_user?.name,
          item_vendor_id: item.global_user?.id,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    addToCart({
      variables,
    })
      .then(() => {
        enqueueSnackbar(
          'Berhasil!! Pembelajaran mikro ditambahkan ke keranjang',
          {
            variant: 'success',
          }
        )
        refetchCart()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menambahkan ke keranjang', {variant: 'error'})
      })
  }

  if (loading || cartLoading) return <LoadingCard length={4} />

  const microlearningList = () => {
    const _list: IMicroLearningWishlist[] = []
    list.forEach((item: IMicroLearningWishlist) => {
      _list.push({
        ...item,
        microlearningByMicrolearning: {
          ...item.microlearningByMicrolearning,
          isInCart: cartList?.v_marketplace_user_carts.some(
            (val: any) =>
              val.item_id === String(item.microlearningByMicrolearning.id)
          ),
        },
      })
    })

    if (props.searchValue) {
      return _list.filter((item: IMicroLearningWishlist) =>
        item.microlearningByMicrolearning.name
          .toLowerCase()
          .includes(props.searchValue.toLowerCase())
      )
    }

    return _list
  }

  return (
    <div>
      {microlearningList().length > 0 ? (
        <div className="flex flex-wrap">
          {microlearningList().map(
            (item: IMicroLearningWishlist, i: number) => {
              return (
                <LearnerCatalogCard
                  type={LearningType.MICRO_LEARNING}
                  item={{
                    ...item.microlearningByMicrolearning,
                  }}
                  key={i}
                  hidePrice={false}
                  isWishlist
                  removeFromWishlist={() => removeFromWishlist(item.id)}
                  addToCart={() =>
                    handleAddToCart(item.microlearningByMicrolearning)
                  }
                  wishlistLoading={wishlistLoading && tempId === item.id}
                />
              )
            }
          )}
        </div>
      ) : (
        <NoListComponent
          title="Maaf, Tidak Ada Daftar"
          subTitle="Saat ini, Anda tidak memiliki daftar keinginan pembelajaran mikro"
        />
      )}
    </div>
  )
}

export default MicroLearningWishlistComponent
