import React, { type ChangeEvent } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { format, formatISO } from 'date-fns'

import {
  Avatar,
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

import { Filter } from '@smartasn/wlb-utils-components'
import Skeleton from 'react-loading-skeleton'

import BookEarningsCard from '../dashboard/BookEarningsCard'

import { Pagination } from '../../../../../components/contributor/Pagination'
import NoListComponent from '../../../../../components/empty-state/NoList.component'

import { GET_CONTRIBUTOR_RECENT_TRANSACTIONS } from '../../../../../graphql/queries'
import { INSTRUCTOR_CONTEXT } from '../../../../../utils/contributor-helpers'
import { convertToRupiah } from '../../../../../utils/helpers'

const MemoizedBookEarningsCard = React.memo(BookEarningsCard)

const TransactionRow = (props: { item?: any; onClick?: () => void }) => {
  const { item, onClick } = props

  const user = item && item.global_wallets.global_users[0]
  const position = item && user.placements[0]

  const formattedDate = React.useMemo(() => {
    return item && format(new Date(item.date_added), 'MMMM dd, yyyy')
  }, [item])

  const formattedTime = React.useMemo(() => {
    return item && format(new Date(item.date_added), 'hh:mm aa')
  }, [item])

  const formattedPrice = React.useMemo(() => {
    return item && convertToRupiah(item.final_price)
  }, [item])

  return (
    <TableRow
      tabIndex={onClick ? 0 : undefined}
      onClick={onClick}
      onKeyUp={(ev) => onClick && ev.key === 'Enter' && onClick()}
      className={
        onClick
          ? 'cursor-pointer transition hover:bg-[#f5f5f5] focus:bg-[#f5f5f5]'
          : ''
      }
    >
      <TableCell className="pl-6">
        {item ? (
          <Avatar src={user.avatar} alt={user.name} />
        ) : (
          <Skeleton className="h-10 w-10 rounded-full" />
        )}
      </TableCell>
      <TableCell className="max-w-0">
        <Typography className="text-sm mb-1 whitespace-nowrap text-ellipsis overflow-hidden">
          {item ? user.name : <Skeleton />}
        </Typography>
        <Typography
          color="textSecondary"
          className="text-xs whitespace-nowrap text-ellipsis overflow-hidden"
        >
          {item ? position?.profile.title : <Skeleton />}
        </Typography>
      </TableCell>
      <TableCell className="text-right whitespace-nowrap">
        {item ? (
          <span className="flex gap-2 items-center">
            <span>{formattedDate}</span>
            <span className="h-1 w-1 rounded-full bg-gray-300" />
            <span>{formattedTime}</span>
          </span>
        ) : (
          <Skeleton />
        )}
      </TableCell>
      <TableCell className="whitespace-nowrap">
        {item ? formattedPrice : <Skeleton />}
      </TableCell>
    </TableRow>
  )
}

const enum TransactionSortOrder {
  DESC = 'desc',
  ASC = 'asc',
}

const transactionFilterOptions = [
  {
    type: 'date',
    fieldName: 'date',
    name: 'Transaction Date',
  },
]

const sortOptions = [
  { label: 'Latest', value: TransactionSortOrder.ASC },
  { label: 'Oldest', value: TransactionSortOrder.DESC },
]

const ContributorBookEarningsPage = () => {
  const navigate = useNavigate()

  const { bookSlug } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const searchTimeoutRef = React.useRef<any>()
  const rootRef = React.useRef<HTMLElement>(null)

  const search = searchParams.get('query') ?? ''

  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const orderBy =
    (searchParams.get('order') as TransactionSortOrder) ??
    TransactionSortOrder.ASC

  const dateFrom = searchParams.get('from') || undefined
  const dateTo = searchParams.get('to') || undefined

  const where = React.useMemo(() => {
    return {
      date_added: {
        _gte: dateFrom,
        _lte: dateTo,
      },
      status: {
        _eq: 'completed',
      },
      global_invoice_items: {
        item_table: { _eq: 'academy_books' },
        item_table_id: { _eq: '' + bookSlug! },
      },
      _or: search
        ? [
          { invoice_to: { _ilike: `%${search}%` } },
          { code: { _ilike: `%${search}%` } },
        ]
        : undefined,
    }
  }, [bookSlug, search, dateFrom, dateTo])

  const { data } = useQuery(GET_CONTRIBUTOR_RECENT_TRANSACTIONS, {
    // @ts-ignore
    wlb_skipPatch: true,
    fetchPolicy: 'cache-and-network',
    variables: {
      where: where,
      order: { id: orderBy },
      limit: limit,
      offset: page * limit,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const setParams = (next: URLSearchParams) => {
    clearTimeout(searchTimeoutRef.current)
    setSearchParams(next, { replace: true })

    setTimeout(() =>
      rootRef.current!.scrollIntoView({ block: 'start', behavior: 'smooth' })
    )
  }

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setParams(next)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setParams(next)
  }

  const handleApplyFilter = (res: any) => {
    const next = new URLSearchParams(searchParams)
    const include = res[0]

    if (include.date?.from) {
      next.set('from', formatISO(include.date.from))
    } else {
      next.delete('from')
    }

    if (include.date?.to) {
      next.set('to', formatISO(include.date.to))
    } else {
      next.delete('to')
    }

    setParams(next)
  }

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const handleOrderByChange = (value: any) => {
    const next = new URLSearchParams(searchParams)
    next.set('order', value)

    setParams(next)
  }

  return (
    <div className="flex flex-col gap-4">
      <MemoizedBookEarningsCard />

      <Paper ref={rootRef} className="scroll-m-32">
        <Typography color="primary" className="px-6 py-4 font-bold">
          Recent Transaction
        </Typography>

        <Divider />

        <div className="px-6 pt-6 pb-4">
          <TextField
            key={search}
            placeholder="Search"
            variant="outlined"
            fullWidth
            defaultValue={search}
            onChange={handleSearchChange}
            autoFocus
            InputProps={{
              classes: {
                root: `p-0`,
                input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon color="primary" className="mr-4" />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="flex items-center justify-between px-2 pb-4">
          <Select
            value={orderBy}
            onChange={(e) => handleOrderByChange(e.target.value)}
            variant="standard"
            disableUnderline={true}
            SelectDisplayProps={{
              className:
                'text-sm pl-4 pr-6 py-2 focus:bg-[#f5f5f5] outline-none rounded transition font-bold',
            }}
          >
            {sortOptions.map((option: any, idx: number) => {
              return (
                <MenuItem value={option.value} key={idx}>
                  {option.label}
                </MenuItem>
              )
            })}
          </Select>

          <Filter
            includeExclude={false}
            listFilter={transactionFilterOptions}
            onApply={handleApplyFilter}
          >
            <Button className="px-4 font-bold">Filter</Button>
          </Filter>
        </div>

        {search && (
          <div className="px-6 pb-4">
            <Typography color="textSecondary" className="text-sm">
              Showing {data?.total?.aggregate?.count || 0} results based on your
              search &quot;<span className="whitespace-pre-wrap">{search}</span>
              &quot;
            </Typography>
          </div>
        )}

        <Divider />

        <Table className="w-full">
          <TableHead>
            <TableRow>
              <TableCell className="w-0"></TableCell>
              <TableCell>Learner</TableCell>
              <TableCell className="w-64">Transaction Date</TableCell>
              <TableCell className="w-40">Price</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!data ? (
              Array.from({ length: limit }, (_, idx) => (
                <TransactionRow key={idx} />
              ))
            ) : data.invoices.length > 0 ? (
              data.invoices.map((row: any) => (
                <TransactionRow
                  key={row.id}
                  item={row}
                  onClick={() => navigate(`../../orders/${row.id}`)}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4}>
                  <NoListComponent title="Sorry, No List" />
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>

        <Pagination
          rowsPerPageOptions={[10, 25, 100]}
          count={data?.total?.aggregate?.count || 0}
          rowsPerPage={limit}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </div>
  )
}

export default ContributorBookEarningsPage
