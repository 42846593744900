import styled from 'styled-components'
import {makeStyles, Box} from '@material-ui/core'

export const LoadingBox = styled(Box)`
  display: 'flex';
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 67.5px 0px 67.5px;
  font-family: ${(props: {theme: {font: any}}) => props.theme.font};
  padding-bottom: 15px;
  @media (max-width: 835px) {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
`

export const QuestionSection = styled.div`
  height: 18rem;
  overflow-y: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    background: #a9a8a8;
    border-radius: 5px;
  }
`

const useStyles = makeStyles(() => ({
  imgDetailMicro: {
    height: 280,
    maxWidth: 410,
    objectFit: 'cover',
  },
  root: {
    cursor: 'pointer',
  },
  chevronContainer: {
    backgroundColor: '#039be5',
    padding: 5,
    borderRadius: 10,
    marginLeft: 6,
    marginRight: 6,
    color: '#fff',
  },
  titleMicroLearning: {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal',
    fontWeight: 700,
  },
  autorMicroLearning: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginTop: '1.5rem',
  },
  checkboxQuestion: {
    '&.Mui-disabled': {
      pointerEvents: 'auto',
      color: '#707070',
      cursor: 'pointer',
    },
    '&.Mui-checked': {
      color: '#014a62',
    },
  },
  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
}))

export default useStyles
