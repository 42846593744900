import {useQueryService} from '../../config/axiosQuery.service'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {USER_ID, HASURA_ROLE_USER_CONTEXT} from '../../utils/globals'
import {
  GET_RECOMMENDED_EVENTS,
  GET_EVENT_DETAIL_BY_ID,
  GET_UPCOMING_EVENT,
  GET_CONTRIBUTOR_INFO_SHORT,
} from '../../graphql/queries'
import {DELETE_EVENT_FROM_WISHLIST} from '../../graphql/mutations'

export const LIST_EVENTS_FOR_YOU = (
  limit: number = 2,
  page: number = 1,
  priceMin: number | string = '',
  priceMax: number | string = '',
  categories: string = '',
  key: string = 'listForYouEvent'
) =>
  useQueryService(
    [key],
    limit !== -1
      ? `event/schedule/get?limit=${limit}&page=${page}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}`
      : `event/schedule/get?page=${page}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}`
  )

export const LIST_EVENTS_NEARBY = (
  limit: number = 2,
  page: number = 1,
  latitude: number,
  longitude: number,
  priceMin: number | string = '',
  priceMax: number | string = '',
  categories: string = '',
  key: string = 'listNearbyEvent'
) =>
  useQueryService(
    [key],
    limit !== -1
      ? `event/schedule/get?lng=${longitude}&lat=${latitude}&orders[0][0]=distance&limit=${limit}&page=${page}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}`
      : `event/schedule/get?lng=${longitude}&lat=${latitude}&orders[0][0]=distance&page=${page}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}`
  )

export const LIST_RECOMMENDATION_EVENT_CATEGORIES = () => {
  return useQuery(GET_RECOMMENDED_EVENTS, {
    variables: {
      userId: USER_ID,
      limit: 4,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const LIST_ALL_EVENTS = (
  limit: number = 8,
  search: string,
  priceMin: number | string,
  priceMax: number | string,
  categories: string | number[],
  orders: string
) =>
  useQueryService(
    ['listAllEvents'],
    limit !== -1
      ? `event/schedule/get?limit=${limit}&search=${search}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}&${orders}`
      : `event/schedule/get?search=${search}&priceMin=${priceMin}&priceMax=${priceMax}&category=${categories}&${orders}`
  )

export const DELETE_EVENT_WISHLIST = () => {
  return useMutation(DELETE_EVENT_FROM_WISHLIST)
}
export const EVENT_BY_ID = (id?: string) => {
  return useQuery(GET_EVENT_DETAIL_BY_ID, {
    variables: {
      id: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const CONTRIBUTOR_EVENT_DETAILS = (
  id: string,
  skipFetching: boolean
) => {
  return useQuery(GET_CONTRIBUTOR_INFO_SHORT, {
    variables: {
      userId: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}

type UpcomingEventParam = {
  creator: string
  limit?: number
  offset?: number
  skipFetching?: boolean
}

export const UPCOMING_EVENT = ({
  creator,
  limit = 4,
  offset = 0,
  skipFetching = false,
}: UpcomingEventParam) => {
  return useQuery(GET_UPCOMING_EVENT, {
    variables: {
      creator: creator,
      limit: limit,
      offset: offset,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}
