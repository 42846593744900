import gql from 'graphql-tag'

export const LEARNING_GLOBAL_SEARCH = gql`
  query ($query: String!) {
    academy_courses(
      where: {title: {_ilike: $query}, organization_id: {_is_null: true}}
      limit: 5
    ) {
      id
      title
      thumbnail
      academy_course_category {
        id
        name
      }
      level
      language
      global_user {
        id
        name
      }
      academy_price_tier {
        pricing_idr
      }
      average_rating
      topic
      duration_seconds
    }
    microlearnings(
      where: {
        name: {_ilike: $query}
        organization_id: {_is_null: true}
        type: {_eq: "PUBLIC"}
        status: {_eq: "ACTIVE"}
      }
      limit: 5
    ) {
      id
      name
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      global_user {
        id
        name
        avatar
      }
      name
      date_added
      status
      price
      type
      icon_url
      average_rating
      review_count
      academy_course_category {
        id
        name
      }
      description
      question_count
    }
    classrooms(where: {name: {_ilike: $query}}, limit: 5) {
      id
      avatar_url
      name
      description
      price
      rules
      is_finished
      is_individual
      resume
      academy_course_category {
        code
        name
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
        }
      }
    }
    academy_books(where: {title: {_ilike: $query}}, limit: 5) {
      id
      title
      pricing_idr
      thumbnail
      book_type
      duration_seconds
      average_rating
      author: global_user {
        id
        name
      }
    }
    event_schedules(where: {title: {_ilike: $query}}, limit: 5) {
      id
      event_category {
        id
        name
      }
      title
      thumbnail
      pricing_idr
      datestart
      location_info
      creator
      status
      event_type {
        id
        name
      }
      event_reviews_aggregate {
        aggregate {
          avg {
            rating
          }
        }
      }
    }
  }
`
