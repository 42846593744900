import React from 'react'
import {
  Typography,
  Divider,
  Icon,
  makeStyles,
  createStyles,
  withStyles,
  LinearProgress,
  Avatar,
} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import {
  ICourse,
  ICourseReview,
  ICourseReviewREST,
  ICourseAddReviewPayload,
} from '../../../../interfaces/Course.interface'

import {
  ADD_COURSE_REVIEW,
  COURSE_REVIEW_GQL,
  UPDATE_COURSE_REVIEW,
} from '../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import YourFeedback from '../../../utils-components/YourFeedback'
import {USER_ID} from '../../../../utils/globals'
import NoList from '../../../../components/empty-state/NoList.component'
import classNames from 'classnames'
import Rating from '../../../utils-components/Rating.component'

type ITabReview = {
  course: ICourse
  averageRating: number

  reviewData: ICourseReviewREST
  reviewRefetch: () => void
  courseRefetch: () => void
}

type StarReviewProps = {
  count: number
  classes: ClassNameMap
  reviewData: ICourseReviewREST
}

const useStyles = makeStyles(() =>
  createStyles({
    tabReviewRootContainer: {
      width: 750,
    },
    skyBlueColor: {
      color: '#039be5',
    },
    skyBlueBgColor: {
      backgroundColor: '#039be5',
    },
    greyColor: {
      color: '#DCDBDB',
    },
  })
)

const LinearProgressReview = withStyles((theme) => ({
  root: {
    marginTop: 4,
    height: 8,
    width: '100%',
    borderRadius: 3,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress)

const StarReview = (props: StarReviewProps) => {
  const {count, classes, reviewData} = props
  const [percentage, setPercentage] = React.useState<string>('')
  const mappingPercentage = React.useCallback(() => {
    const reviewAtSum = reviewData.data.filter((review: ICourseReview) => {
      return review.rating === 5 - count
    }).length

    return ((reviewAtSum / reviewData.total) * 100).toFixed(0)
  }, [reviewData.data, reviewData.total, count])

  React.useEffect(() => {
    setPercentage(mappingPercentage())
  }, [mappingPercentage])

  return (
    <div className="flex flex-row items-center mb-2">
      <div className="flex flex-row mr-8">
        {[...Array(5)].map((_star: number[], index: number) => {
          if (index < 5 - count) {
            return <Icon className={classes.skyBlueColor}>star</Icon>
          } else {
            return <Icon className={classes.greyColor}>star</Icon>
          }
        })}
      </div>
      <LinearProgressReview
        variant="determinate"
        value={isNaN(parseInt(percentage)) ? 0 : parseInt(percentage)}
      />
      <Typography className="w-20 text-right" color="textSecondary">
        {isNaN(parseInt(percentage)) ? 0 : parseInt(percentage)}%
      </Typography>
    </div>
  )
}

const TabReview = (props: ITabReview) => {
  const {reviewData, reviewRefetch, courseRefetch} = props
  const [countReviewBasedOn, setCountReviewBasedOn] = React.useState<number>(0)
  const [filterRating, setFilterRating] = React.useState<number>(0)
  const [limit, setLimit] = React.useState<number>(10)
  const {
    data: dataListRating,
    loading: loadingListRating,
    refetch: refetchListRating,
  } = COURSE_REVIEW_GQL(props.course.id, filterRating, limit)

  const classes = useStyles()

  const [addCourseReview] = ADD_COURSE_REVIEW()
  const [updateCourseReview] = UPDATE_COURSE_REVIEW()

  const handleSubmit = (value: ICourseAddReviewPayload) => {
    if (props.course.Enrollment.rating) {
      updateCourseReview({
        variables: {
          rating: value.rating,
          reviewText: value.description || '',
          userId: USER_ID,
          courseId: props.course.id,
        },
      }).then(() => {
        refetchListRating()
        courseRefetch()
        reviewRefetch()
      })
    } else {
      addCourseReview({
        variables: {
          rating: value.rating,
          reviewText: value.description || '',
          userId: USER_ID,
          courseId: props.course.id,
        },
      }).then(() => {
        refetchListRating()
        courseRefetch()
        reviewRefetch()
      })
    }
  }

  React.useEffect(() => {
    if (!reviewData) {
      reviewRefetch()
    }
  }, [reviewData, reviewRefetch])

  React.useEffect(() => {
    if (dataListRating && filterRating === 0) {
      setCountReviewBasedOn(
        dataListRating?.academy_course_enrollments_aggregate?.aggregate?.count
      )
    }
  }, [dataListRating, filterRating])

  if (loadingListRating) {
    return <Skeleton height={300} className="mx-4 my-2" width={'50vw'} />
  }

  return (
    <div className="flex flex-col w-10/12">
      <Typography variant="body1" className="mt-8 mb-6 font-bold">
        Ulasan Pembelajaran
      </Typography>
      {props.course.Enrollment?.completionRate === 100 && (
        <>
          <Divider />
          <YourFeedback
            target="Course"
            onSubmit={handleSubmit}
            rating={props.course.Enrollment.rating || 0}
            isReviewed={props.course.Enrollment.rating}
            reviewText={props.course.Enrollment.reviewText || ''}
          />
        </>
      )}

      <Divider />
      <div className={`flex w-full mt-8 items-center`}>
        <div className="w-1/5">
          <div className="flex flex-col mt-4">
            <Typography className="text-grey-darker" variant="h2">
              {props.reviewData.total === 0
                ? 0
                : props.averageRating.toFixed(2)}
            </Typography>
            <div className="flex mt-5 pb-2">
              <Rating
                averageRating={props.averageRating}
                totalEnrollment={0}
                ratingFilter
              />
            </div>

            <Typography variant="body2">
              Berdasarkan {countReviewBasedOn} ulasan
            </Typography>
          </div>
        </div>
        <div className="w-4/5 pl-8">
          {[...new Array(5)].map((item: any, index: number) => (
            <>
              <StarReview
                key={`starReview-${index}`}
                count={index}
                classes={classes}
                reviewData={props.reviewData}
              />
            </>
          ))}
        </div>
      </div>
      <div className="w-3/6 mt-10 mb-4 flex flex-row justify-between">
        {[...Array(6)].map((rating, index) => {
          return (
            <div
              key={rating}
              onClick={() => {
                setFilterRating(index)
              }}
              className={classNames(
                `border rounded border-gray-300 flex flex-row justify-center w-12 h-8 items-center cursor-pointer`,
                filterRating === index ? classes.skyBlueBgColor : 'bg-white'
              )}
            >
              <Icon
                className={
                  index !== filterRating ? classes.skyBlueColor : 'text-white'
                }
                hidden={index === 0}
                fontSize="small"
              >
                star
              </Icon>
              <Typography
                className={classNames(
                  `text-sm ${
                    filterRating === index ? 'text-white' : 'text-black'
                  }`
                )}
              >
                {index === 0 ? 'Semua' : index}
              </Typography>
            </div>
          )
        })}
      </div>
      <Divider />

      {dataListRating.academy_course_enrollments_aggregate?.aggregate?.count ===
      0 ? (
        <div className="py-14">
          <NoList
            imageSize={180}
            title="Maaf, tidak ada daftar"
            subTitle="Tidak ada ulasan untuk kursus ini"
          />
        </div>
      ) : (
        <>
          {dataListRating.academy_course_enrollments.map(
            (review: ICourseReview, index: number) => (
              <div className="flex flex-row py-10" key={`review-${index}`}>
                <Avatar
                  style={{width: 64, height: 64}}
                  src={review.global_user?.avatar}
                />
                <div className="ml-6 w-full flex flex-col">
                  <Typography className="font-bold">
                    {review.global_user?.name}
                  </Typography>
                  <span className="flex flex-row items-center">
                    <div className="flex flex-row">
                      {[...Array(5)].map((star, index) => {
                        return (
                          <Icon
                            className={
                              index < review.rating
                                ? classes.skyBlueColor
                                : classes.greyColor
                            }
                            key={star + index}
                          >
                            star
                          </Icon>
                        )
                      })}
                    </div>
                    <Typography
                      hidden={!review.review_date}
                      color="textSecondary"
                      variant="subtitle2"
                      className="ml-2"
                    >
                      {new Date(review.review_date).toLocaleDateString(
                        'en-US',
                        {
                          month: 'short',
                          day: 'numeric',
                          year: 'numeric',
                        }
                      )}
                    </Typography>
                  </span>
                  <Typography>{review.review_text}</Typography>
                </div>
              </div>
            )
          )}
          <Divider />
          <Typography
            variant="body1"
            color="secondary"
            className="cursor-pointer my-6"
            onClick={() => {
              setLimit(
                dataListRating.academy_course_enrollments_aggregate?.aggregate
                  .count > dataListRating.academy_course_enrollments?.length
                  ? limit + 5
                  : limit - 5
              )
            }}
            hidden={
              filterRating > 0 ||
              dataListRating.academy_course_enrollments?.length < 10
            }
          >
            Ulasan{' '}
            {dataListRating.academy_course_enrollments_aggregate?.aggregate
              .count > dataListRating.academy_course_enrollments?.length
              ? 'Lebih Banyak'
              : 'Lebih Sedikit'}{' '}
          </Typography>
        </>
      )}
    </div>
  )
}

export default TabReview
