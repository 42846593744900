import React, {FC} from 'react'
import {ICourse} from '../../../../interfaces'
import {TContributorSection} from '../../../../interfaces/Contributor.interface'
import {LIST_CONTRIBUTOR_COURSE} from '../../../../provider/services/LearningContributor.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCircular from '../../../loading/LoadingCircular.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'

const Course: FC<TContributorSection> = ({id, search, sort}) => {
  const {data, loading} = LIST_CONTRIBUTOR_COURSE(search, id, sort)

  if (loading) {
    return <LoadingCircular height="500px" />
  }

  return data?.academy_courses.length === 0 ? (
    <div className="flex flex-col justify-center" style={{height: 500}}>
      <NoListComponent
        title="Maaf, tidak ada daftar"
        subTitle="Kursus tidak ditemukan"
      />
    </div>
  ) : (
    <div className="flex flex-wrap -mx-3">
      {data?.academy_courses.map((item: ICourse, i: number) => {
        const _item = {
          ...item,
          Price: {
            pricing: {
              IDR: item.Price?.pricing_idr?.toString() ?? '0',
            },
          },
        }

        return (
          <LearnerCatalogCard
            type={LearningType.COURSE}
            item={_item}
            key={i}
            hidePrice={false}
          />
        )
      })}
    </div>
  )
}

export default Course
