import React from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import {
  Avatar,
  Button,
  Divider,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from 'react-loading-skeleton'

import { Filter } from '@smartasn/wlb-utils-components'

import NoListComponent from '../../../../../components/empty-state/NoList.component'
import { Pagination } from '../../../../../components/contributor/Pagination'

import { type CoursePageContext } from '../ContributorCourseDetailPageLayout'

import useDebounce from '../../../../../hooks/useDebounce'
import { INSTRUCTOR_CONTEXT } from '../../../../../utils/contributor-helpers'
import { GET_CONTRIBUTOR_COURSE_LEARNERS } from '../../../../../graphql/queries'

const getCompletionLabel = (rate: number) => {
  switch (rate) {
    case 100:
      return (
        <Typography className="text-green-600 text-sm">Completed</Typography>
      )
    case 0:
      return (
        <Typography color="secondary" className="text-sm">
          Enrolled
        </Typography>
      )
    default:
      return (
        <Typography className="text-yellow-500 text-sm">On Going</Typography>
      )
  }
}

const enum Enrollment {
  ENROLLED = 'enrolled',
  ONGOING = 'ongoing',
  COMPLETED = 'completed',
}

const filters: any[] = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          label: 'Enrolled',
          value: Enrollment.ENROLLED,
        },
        {
          label: 'On Going',
          value: Enrollment.ONGOING,
        },
        {
          label: 'Completed',
          value: Enrollment.COMPLETED,
        },
      ],
    },
  },
]

const getEnrollmentFilter = (status: Enrollment) => {
  switch (status) {
    case Enrollment.ENROLLED:
      return { _eq: 0 }
    case Enrollment.COMPLETED:
      return { _eq: 100 }
    case Enrollment.ONGOING:
      return { _gt: 0, _lt: 100 }
  }
}

const ContributorCourseLearnersPage = () => {
  const rootRef = React.useRef<HTMLElement>(null)

  const { courseId } = useOutletContext<CoursePageContext>()
  const [searchParams, setSearchParams] = useSearchParams()

  const search = searchParams.get('query') ?? ''
  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const statuses = searchParams.getAll('status') as Enrollment[]

  const debouncedSearch = useDebounce(search)

  const { where, whereUnfiltered } = React.useMemo(() => {
    return {
      where: {
        course_id: { _eq: courseId },
        _and: [
          {
            _or: [{ global_user: { name: { _ilike: `%${debouncedSearch}%` } } }],
          },
          {
            _or: statuses.length
              ? statuses.map((status) => ({
                completion_rate: getEnrollmentFilter(status),
              }))
              : undefined,
          },
        ],
      },
      whereUnfiltered: {
        course_id: { _eq: courseId },
      },
    }
  }, [courseId, statuses, debouncedSearch])

  const { data } = useQuery(GET_CONTRIBUTOR_COURSE_LEARNERS, {
    skip: !courseId,
    fetchPolicy: 'cache-and-network',
    context: INSTRUCTOR_CONTEXT,
    variables: {
      where,
      whereUnfiltered,
      limit,
      offset: page * limit,
    },
  })

  const setParams = (next: URLSearchParams, scroll?: boolean) => {
    setSearchParams(next, { replace: true })

    if (scroll) {
      setTimeout(() => {
        rootRef.current!.scrollIntoView({ block: 'start', behavior: 'smooth' })
      })
    }
  }

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    setParams(next)
  }

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setParams(next, true)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setParams(next, true)
  }

  const handleFilterApply = ({ 0: include }: any) => {
    const next = new URLSearchParams(searchParams)

    const statuses = include.status

    next.delete('page')
    next.delete('status')

    if (statuses && statuses.length > 0 && statuses.length < 3) {
      for (const status of statuses) {
        next.append('status', status.value)
      }
    }

    setParams(next, true)
  }

  return (
    <Paper ref={rootRef} className="scroll-m-32">
      <Typography color="primary" className="px-6 py-4 font-bold">
        Learner ({data?.total_unfiltered.aggregate.count || 0})
      </Typography>

      <Divider />

      <div className="px-6 py-6">
        <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Divider />

      <div className="flex items-center justify-end px-2 py-4">
        <Filter
          includeExclude={false}
          listFilter={filters}
          onApply={handleFilterApply}
        >
          <Button className="px-4 font-bold">Filter</Button>
        </Filter>
      </div>

      {debouncedSearch && (
        <div className="px-6 pb-4">
          <Typography color="textSecondary" className="text-sm">
            Showing {data?.total?.aggregate?.count || 0} results based on your
            search &quot;
            <span className="whitespace-pre-wrap">{debouncedSearch}</span>
            &quot;
          </Typography>
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-0">
              <span className="invisible">Avatar</span>
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell className="w-1/3">Email</TableCell>
            <TableCell className="w-1/6">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data ? (
            Array.from({ length: limit }, (_, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <Skeleton className="h-10 w-10 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))
          ) : data.learners.length > 0 ? (
            data.learners.map((learner: any) => (
              <TableRow key={learner.id}>
                <TableCell>
                  <Avatar
                    alt={learner.global_user.name}
                    src={learner.global_user.avatar}
                  />
                </TableCell>
                <TableCell>
                  <Typography className="text-sm">
                    {learner.global_user.name}
                  </Typography>
                  <Typography color="textSecondary" className="text-sm">
                    {
                      learner.global_user.people_work_placements[0]
                        ?.company_job_profile.title
                    }
                  </Typography>
                </TableCell>
                <TableCell>{learner.global_user.email}</TableCell>
                <TableCell>
                  {getCompletionLabel(learner.completion_rate)}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} className="py-16">
                <NoListComponent title="Sorry, No List" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Pagination
        rowsPerPageOptions={[10, 25, 100]}
        count={data?.total?.aggregate?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default ContributorCourseLearnersPage
