import React from 'react'
import {Outlet, useParams} from 'react-router-dom'
import {useQuery, useMutation} from '@apollo/client'
import {useTitle} from 'hoofd'
import {useSnackbar} from 'notistack'

import {
  Button,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core'

import Skeleton from 'react-loading-skeleton'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'

import {
  SidebarMenu,
  SidebarNavItem,
} from '../../../../components/contributor/SidebarMenu'
import ContributorCreatorDetailLayout from '../../../../components/contributor/layout/ContributorCreatorDetailLayout'

import {INSTRUCTOR_CONTEXT} from '../../../../utils/contributor-helpers'
import {
  GET_MICRO_LEARNING_DETAIL,
  GET_CONTRIBUTOR_MICROLEARNING_DETAIL_PROGRESS,
} from '../../../../graphql/queries'
import {UPDATE_MICROLEARNING} from '../../../../graphql/mutations'

const enum MicroLearningStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  DRAFT = 'DRAFT',
}

const MicroLearningStatusLabels: any = {
  [MicroLearningStatus.ACTIVE]: 'Active',
  [MicroLearningStatus.PENDING]: 'Pending',
  [MicroLearningStatus.DRAFT]: 'Draft',
}

const MicrolearningStatusColors: any = {
  [MicroLearningStatus.DRAFT]: undefined,
  [MicroLearningStatus.ACTIVE]: '#4CAF50',
  [MicroLearningStatus.PENDING]: '#EF4D5E',
}

const ContributorMicroLearningDetailPageLayout = () => {
  const {microlearningId} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  const [updating, setUpdating] = React.useState(false)

  const [updateMicrolearning] = useMutation(UPDATE_MICROLEARNING, {
    context: INSTRUCTOR_CONTEXT,
  })

  const {data, refetch} = useQuery(GET_MICRO_LEARNING_DETAIL, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: microlearningId,
    },
  })

  const {data: progressData} = useQuery(
    GET_CONTRIBUTOR_MICROLEARNING_DETAIL_PROGRESS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        microlearningId: microlearningId,
      },
    }
  )

  const microlearning = data?.microlearnings[0]
  const progress = progressData?.getProgressMicrolearningDetail

  useTitle(microlearning?.name || '')

  const Image = microlearning && microlearning.icon_url ? 'img' : 'div'

  const onUpdate = (status: string) => {
    setUpdating(true)

    if (!status) {
      return
    }

    updateMicrolearning({
      variables: {
        microlearningId,
        set: {
          status,
        },
      },
    })
      .then(() => {
        refetch()
      })
      .catch(() => {
        enqueueSnackbar(`Failed to update status`, {variant: 'error'})
      })
      .finally(() => {
        setUpdating(false)
      })
  }

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  if (data && data.microlearnings.length < 1) {
    return (
      <ContributorCreatorDetailLayout
        label="Micro Learning Detail"
        to="../microlearnings"
      >
        <div>Micro learning not found</div>
      </ContributorCreatorDetailLayout>
    )
  }

  return (
    <ContributorCreatorDetailLayout
      label="Micro Learning Detail"
      to="../microlearnings"
    >
      <Paper className="flex items-stretch gap-12 p-6">
        <div className="my-2 flex grow flex-col justify-between">
          <div>
            {microlearning ? (
              <LinesEllipsis
                component={Typography}
                text={microlearning.name}
                maxLine={3}
                color="primary"
                className="mb-1 text-lg font-bold"
              />
            ) : (
              <p className="mb-1 text-lg">
                <Skeleton width="50%" />
              </p>
            )}

            <Typography
              color="textSecondary"
              className="mb-4 text-sm"
              style={{
                color:
                  microlearning &&
                  MicrolearningStatusColors[microlearning.status],
              }}
            >
              {microlearning ? (
                MicroLearningStatusLabels[microlearning.status]
              ) : (
                <Skeleton width={45} />
              )}
            </Typography>
          </div>

          <div>
            {!microlearning ? null : microlearning.status ===
              MicroLearningStatus.DRAFT ? (
              <>
                <Typography className="mb-2">
                  Progress {Math.round(progress?.total || 0)}%
                </Typography>

                <LinearProgress
                  variant="determinate"
                  value={progress?.total || 0}
                  color="secondary"
                  className="h-2 grow rounded"
                  classes={{bar: 'rounded'}}
                />

                <div className="mt-6 flex gap-4">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => onUpdate('DRAFT')}
                    disabled={updating}
                  >
                    Save Draft
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    onClick={() => onUpdate('PENDING')}
                    disabled={updating}
                  >
                    Publish
                  </Button>
                </div>
              </>
            ) : microlearning.status === MicroLearningStatus.PENDING ? (
              <div className="mt-6 flex gap-4">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="text-red-500"
                  onClick={() => onUpdate('DRAFT')}
                  disabled={updating}
                >
                  Unpublish
                </Button>
              </div>
            ) : microlearning.status === MicroLearningStatus.ACTIVE ? (
              <div className="mt-6 flex gap-4">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="text-red-500"
                  onClick={() => onUpdate('PENDING')}
                  disabled={updating}
                >
                  Unpublish
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        <Image
          alt={microlearning?.name}
          src={microlearning?.icon_url}
          className="aspect-video w-96 shrink-0 rounded bg-gray-200 object-cover"
        />
      </Paper>

      <div className="grid items-baseline gap-8 lg:grid-cols-[320px_minmax(0,_1fr)]">
        <SidebarMenu>
          <SidebarNavItem to="." end>
            Dashboard
          </SidebarNavItem>
          <SidebarNavItem to="manage">Manage</SidebarNavItem>
          <SidebarNavItem to="learners">Learner</SidebarNavItem>
          <SidebarNavItem to="earnings">Earnings</SidebarNavItem>
          <SidebarNavItem to="questions">Question</SidebarNavItem>
          <SidebarNavItem to="reviews">Review</SidebarNavItem>

          <Divider />

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            className="mx-4 my-2"
          >
            Preview My Micro Learning
          </Button>
        </SidebarMenu>

        <Outlet />
      </div>
    </ContributorCreatorDetailLayout>
  )
}

export default ContributorMicroLearningDetailPageLayout
