import {Outlet} from 'react-router-dom'

import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../utils-components/VendorStyles'

export interface ContributorManageDetailLayoutProps {
  label: string
}

const ContributorManageDetailLayout = (
  props: ContributorManageDetailLayoutProps
) => {
  const {label} = props

  return (
    <>
      <TabsWrapper>
        <StyledTabs value="manage" centered className="bg-[#f6f8f9]">
          <StyledTab label={label} value="manage" className="font-bold" />
        </StyledTabs>
      </TabsWrapper>

      <div className="mx-auto box-content max-w-screen-xl px-4 py-4 md:px-20 md:py-8">
        <Outlet />
      </div>
    </>
  )
}

export default ContributorManageDetailLayout
