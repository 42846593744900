import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_ENROLLMENT_STATS = gql`
  query ($userId: uuid!) {
    course_enrollments: academy_course_enrollments_aggregate(
      where: {
        academy_course: {
          creator: {_eq: $userId}
          organization_id: {_is_null: true}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    ebook_enrollments: academy_book_owners_aggregate(
      where: {academy_book: {owner: {_eq: $userId}, book_type: {_eq: 2}}}
    ) {
      aggregate {
        count
      }
    }
    audiobook_enrollments: academy_book_owners_aggregate(
      where: {academy_book: {owner: {_eq: $userId}, book_type: {_eq: 3}}}
    ) {
      aggregate {
        count
      }
    }
    microlearning_enrollments: microlearning_members_aggregate(
      where: {microlearning: {created_by_user_id: {_eq: $userId}}}
    ) {
      aggregate {
        count
      }
    }
    event_enrollments: event_enrollment_aggregate(
      where: {event_schedule: {creator: {_eq: $userId}}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CONTRIBUTOR_DEMOGRAPHIC_STATS = gql`
  query {
    getTotalLearnerByGender {
      male
      female
    }
    getTotalLearnerByAge {
      eighteen_to_twenty_four
      twenty_five_to_thirty_four
      thirty_five_to_forty_nine
      over_fifty
    }
  }
`

export const GET_CONTRIBUTOR_CATALOG_STATS = gql`
  query ($userId: uuid!) {
    course_created: academy_courses_aggregate(
      where: {creator: {_eq: $userId}, organization_id: {_is_null: true}}
    ) {
      aggregate {
        count
      }
    }
    ebook_created: academy_books_aggregate(
      where: {owner: {_eq: $userId}, book_type: {_eq: 2}}
    ) {
      aggregate {
        count
      }
    }
    audiobook_created: academy_books_aggregate(
      where: {owner: {_eq: $userId}, book_type: {_eq: 3}}
    ) {
      aggregate {
        count
      }
    }
    microlearning_created: microlearnings_aggregate(
      where: {created_by_user_id: {_eq: $userId}}
    ) {
      aggregate {
        count
      }
    }
    event_created: event_schedules_aggregate(where: {creator: {_eq: $userId}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CONTRIBUTOR_WISHLIST_STATS = gql`
  query ($userId: uuid!) {
    course_wishlists: academy_wishlists_aggregate(
      where: {
        academy_course: {
          creator: {_eq: $userId}
          organization_id: {_is_null: true}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    ebook_wishlists: academy_book_wishlists_aggregate(
      where: {academy_book: {owner: {_eq: $userId}, book_type: {_eq: 2}}}
    ) {
      aggregate {
        count
      }
    }
    audiobook_wishlists: academy_book_wishlists_aggregate(
      where: {academy_book: {owner: {_eq: $userId}, book_type: {_eq: 3}}}
    ) {
      aggregate {
        count
      }
    }
    microlearning_wishlists: microlearning_wishlists_aggregate(
      where: {
        microlearningByMicrolearning: {created_by_user_id: {_eq: $userId}}
      }
    ) {
      aggregate {
        count
      }
    }
    event_wishlists: event_wishlists_aggregate(
      where: {event_schedule: {creator: {_eq: $userId}}}
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_CONTRIBUTOR_TOTAL_EARNINGS = gql`
  query ($walletId: String!) {
    getTotalEarning(walletId: $walletId) {
      total_price
    }
  }
`

export const GET_CONTRIBUTOR_EARNING_BREAKDOWN = gql`
  query (
    $walletId: String!
    $startDate: String!
    $endDate: String!
    $timeType: String!
  ) {
    getEarningByTime(
      walletId: $walletId
      startDate: $startDate
      endDate: $endDate
      timeType: $timeType
    ) {
      total_earning
      total_course
      total_ebook
      total_audiobook
      total_microlearning
      total_event
    }
  }
`

export const GET_CONTRIBUTOR_POPULAR_COURSE = gql`
  query ($userId: uuid!, $limit: Int!) {
    items: academy_courses(
      where: {creator: {_eq: $userId}, organization_id: {_is_null: true}}
      order_by: {academy_course_enrollments_aggregate: {count: desc_nulls_last}}
      limit: $limit
    ) {
      id
      title
      thumbnail
      enrollments: academy_course_enrollments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_TOP_COURSE = gql`
  query ($userId: uuid!, $limit: Int!) {
    items: academy_courses(
      where: {creator: {_eq: $userId}, organization_id: {_is_null: true}}
      order_by: {average_rating: desc_nulls_last}
      limit: $limit
    ) {
      id
      title
      thumbnail
      average_rating
    }
  }
`

export const GET_CONTRIBUTOR_POPULAR_BOOK = gql`
  query ($userId: uuid!, $bookType: Int!, $limit: Int!) {
    items: academy_books(
      where: {owner: {_eq: $userId}, book_type: {_eq: $bookType}}
      order_by: {academy_book_owners_aggregate: {count: desc_nulls_last}}
      limit: $limit
    ) {
      id
      title
      thumbnail
      enrollments: academy_book_owners_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_TOP_BOOK = gql`
  query ($userId: uuid!, $bookType: Int!, $limit: Int!) {
    items: academy_books(
      where: {owner: {_eq: $userId}, book_type: {_eq: $bookType}}
      order_by: {average_rating: desc_nulls_last}
      limit: $limit
    ) {
      id
      title
      thumbnail
      average_rating
    }
  }
`

export const GET_CONTRIBUTOR_POPULAR_MICROLEARNING = gql`
  query ($userId: uuid!, $limit: Int!) {
    items: microlearnings(
      where: {
        created_by_user_id: {_eq: $userId}
        organization_id: {_is_null: true}
      }
      order_by: {microlearning_members_aggregate: {count: desc_nulls_last}}
      limit: $limit
    ) {
      id
      title: name
      thumbnail: icon_url
      enrollments: microlearning_members_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_TOP_MICROLEARNING = gql`
  query ($userId: uuid!, $limit: Int!) {
    items: microlearnings(
      where: {
        created_by_user_id: {_eq: $userId}
        organization_id: {_is_null: true}
      }
      order_by: {average_rating: desc_nulls_last}
      limit: $limit
    ) {
      id
      title: name
      thumbnail: icon_url
      average_rating
    }
  }
`
