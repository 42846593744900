import React, {useContext} from 'react'
import {CustomTypography} from '../../../../components/utils-components/GlobalStyles'
import {
  IDictionary,
  IDetailDictionaryLevel,
  IDictionaryTable,
  ICompetencyDictionary,
} from '../../../../interfaces/LearningProfile.interface'
import {LearningProfileContext} from '../../../../provider/LearningProfile.provider'

import NoListComponent from '../../../../components/empty-state/NoList.component'
import DetailModal from '../DetailDictionaryModal.component'

const Dictionary = () => {
  const {dataSummary} = useContext(LearningProfileContext)

  const [open, setOpen] = React.useState(false)
  const [detail, setDetail] = React.useState<IDetailDictionaryLevel>({
    title: '',
    datas: [],
    descriptionLevels: [],
  })

  const handleOpen = (data: IDictionary, idx: number) => {
    if (data.competency_dictionaries) {
      const _datas: IDictionaryTable[] = [
        {
          name: 'Category Name',
          value: data.name,
        },
        {
          name: 'Competency ID',
          value: data.id,
        },
        {
          name: 'Competency Name',
          value: data.competency_dictionaries[idx].name.id,
        },
        {
          name: 'Description',
          value: data.competency_dictionaries[idx].description.text,
        },
      ]

      setDetail({
        title: `${data.competency_dictionaries[idx].name.id} (${data.competency_dictionaries[idx].name.id})`,
        datas: _datas,
        descriptionLevels: data.competency_dictionaries[idx].description.levels,
      })
    }
    setOpen(true)
  }

  return (
    <div>
      {!dataSummary.length ? (
        <div className="mt-4">
          <NoListComponent title="Maaf, tidak ada daftar" imageSize={180} />
        </div>
      ) : (
        dataSummary.map((item: IDictionary, i: number) =>
          item.competency_dictionaries.map(
            (data: ICompetencyDictionary, idx: number) => (
              <>
                <div
                  key={i}
                  className="mt-6 cursor-pointer"
                  onClick={() => handleOpen(item, idx)}
                >
                  <CustomTypography
                    style={{cursor: 'pointer'}}
                    fsize="12px"
                    fcolor="#039be5"
                    fweight="bold"
                  >
                    {item.name}
                  </CustomTypography>
                  <CustomTypography
                    fsize="14px"
                    fweight="bold"
                    mt="11px"
                    mb="23px"
                  >
                    {data.name.id}
                  </CustomTypography>
                  {i !== 5 && <hr />}
                </div>
              </>
            )
          )
        )
      )}
      <DetailModal
        open={open}
        handleClose={() => setOpen(false)}
        detail={detail}
      />
    </div>
  )
}

export default Dictionary
