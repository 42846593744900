import {useQuery} from '@apollo/client'
import {createContext, ReactNode, useMemo} from 'react'
import LoadingCircular from '../components/loading/LoadingCircular.component'
import {
  GET_LEARNING_DIARY_COMPETENCY,
  GET_LEARNING_PROFILE,
  GET_PWP_BY_ID,
  GET_LEARNING_PROFILE_SUMMARY,
} from '../graphql/queries'
import {GET_LEARNING_PROFILE_ADDEDBYCOMPANY} from '../graphql/queries/profile/getLearningProfileAddedByCompany.query'
import {ILearningProfileContext} from '../interfaces'
import {IDiaryResume} from '../interfaces/LearningDiary.interface'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../utils/globals'
import {IPeopleWorkPlacement} from '../interfaces/Course.interface'

export const LearningProfileContext = createContext<ILearningProfileContext>({
  data: null,
  placementId: null,
  dataResume: null,
  dataSummary: [],
  refetchResume: () => null,
  dataAddedByCompany: [],
})

export const LearnerProfileProvider = (props: ContextProps) => {
  const {data, loading} = useQuery(
    GET_LEARNING_PROFILE,
    HASURA_ROLE_USER_CONTEXT
  )

  const {data: dataPwp, loading: loadingPwp} = useQuery(GET_PWP_BY_ID, {
    variables: {user: USER_ID},
  })

  const {
    data: dataResume,
    loading: loadingResume,
    refetch: refetchResume,
  } = useQuery(GET_LEARNING_DIARY_COMPETENCY, {
    variables: {
      user: USER_ID,
    },
  })

  const {data: summaryData, loading: loadingProfileSum} = useQuery(
    GET_LEARNING_PROFILE_SUMMARY,
    {
      variables: {
        placement: dataPwp?.people_work_placements?.map(
          (data: IPeopleWorkPlacement) => data.id
        ),
      },
    }
  )
  const {data: AddedByCompanyData, loading: loadingAddedByCompany} = useQuery(
    GET_LEARNING_PROFILE_ADDEDBYCOMPANY,
    {
      variables: {
        placement: dataPwp?.people_work_placements?.map(
          (data: IPeopleWorkPlacement) => data.id
        ),
      },
    }
  )

  const learningSummary = useMemo(() => {
    if (!dataResume) {
      return []
    }

    const result = [
      ...dataResume.learning_diary_competencies,
      ...dataResume.competency_dictionaries,
    ]

    // NOTE(intrnl): sort them again as we've merged the two arrays together
    result.sort((a: IDiaryResume, b: IDiaryResume) => {
      return (
        b.learning_diaries_aggregate.aggregate.sum.duration -
        a.learning_diaries_aggregate.aggregate.sum.duration
      )
    })

    return result.map((competency: IDiaryResume) => {
      return {
        ...competency,
        name:
          competency.__typename === 'competency_dictionaries'
            ? (competency as any).name.en
            : competency.name,
        color: `#${Math.floor(Math.random() * 0xffffff)
          .toString(16)
          .padStart(6, '0')}`,
      }
    })
  }, [dataResume])

  if (
    loading ||
    loadingPwp ||
    loadingResume ||
    loadingProfileSum ||
    loadingAddedByCompany
  ) {
    return <LoadingCircular />
  }

  return (
    <LearningProfileContext.Provider
      value={{
        data,
        placementId: dataPwp.people_work_placements[0]?.id,
        dataResume: learningSummary,
        refetchResume,
        dataSummary: summaryData?.competency_categories,
        dataAddedByCompany: AddedByCompanyData.competency_dictionaries,
      }}
    >
      {props.children}
    </LearningProfileContext.Provider>
  )
}

type ContextProps = {
  children: ReactNode
}
