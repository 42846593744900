import React from 'react'
import {Typography} from '@material-ui/core'
import {FlexColumnCenter} from '../../components/utils-components/GlobalStyles'
import NoListImage from '../../assets/images/no-data-list.svg'

type noListProps = {
  imageSize?: number
  title?: string
  subTitle?: string
}

const NoListComponent = (props: noListProps) => {
  return (
    <FlexColumnCenter>
      <img src={NoListImage} style={{height: props.imageSize || 271}} />
      <Typography variant="h6" className="font-bold mt-10">
        {props.title}
      </Typography>
      <Typography color="textSecondary">{props.subTitle}</Typography>
    </FlexColumnCenter>
  )
}

export default NoListComponent
