import {gql} from '@apollo/react-hooks'

export const GET_RECOMMENDED_COURSE_CATEGORIES = gql`
  query getRecommendedCourseCategories(
    $userId: uuid!
    $offset: Int
    $limit: Int
  ) {
    academy_course_categories_aggregate(
      where: {
        academy_courses: {academy_course_enrollments: {user_id: {_eq: $userId}}}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_course_categories(
      where: {
        academy_courses: {academy_course_enrollments: {user_id: {_eq: $userId}}}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
    }
  }
`
