import React from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import {useTitle} from 'hoofd'

import {format as formatDate} from 'date-fns'

import LoadingCircular from '../../../components/loading/LoadingCircular.component'

import {ReactComponent as WLBLogo} from '../../../assets/images/wlb-combined-green.svg'

import {GET_ORDER_DETAILS} from '../../../graphql/queries'
import {convertToRupiah} from '../../../utils/helpers'

const queryContext = {
  headers: {
    'X-Hasura-Role': 'instructor',
  },
} as const

const ContributorOrderPrintPage = () => {
  const params = useParams()
  useTitle('Print Invoice')

  const {data, error} = useQuery(GET_ORDER_DETAILS, {
    variables: {
      invoiceId: params.invoiceId,
    },
    context: queryContext,
  })

  React.useEffect(() => {
    if (data) {
      setTimeout(() => window.print(), 250)
    }
  }, [data])

  if (error) {
    return (
      <div className="p-4">
        Something went wrong when trying to retrieve order details, please try
        again.
      </div>
    )
  }

  if (!data) {
    return <LoadingCircular message="Please wait" />
  }

  const invoice = data.global_invoices_by_pk

  const items = []
  let totalPrice = 0

  for (const item of invoice.global_invoice_items) {
    const price = item.single_price
    const qty = item.qty

    const total = price * qty
    totalPrice += totalPrice

    items.push(
      <tr key={item.id}>
        <td className="text-left px-4 py-6">{item.itemname}</td>
        <td className="text-right px-4 py-6">{qty}</td>
        <td className="text-right px-4 py-6">{convertToRupiah(price, true)}</td>
        <td className="text-right px-4 py-6">-</td>
        <td className="text-right px-4 py-6">{convertToRupiah(total, true)}</td>
      </tr>
    )
  }

  return (
    <div className="p-4 bg-white w-[1024px] mx-auto text-lg font-sans">
      <div>
        <WLBLogo className="h-9" />
        <div className="bg-[#014a62] text-right px-8 h-14 mt-6">
          <h1 className="bg-white text-black text-[56px] leading-[56px] inline m-0 px-4 uppercase">
            Invoice
          </h1>
        </div>
      </div>

      <div className="p-12">
        <div className="flex gap-2">
          <div className="basis-3/5">
            <h2 className="text-lg font-bold m-0">Invoice</h2>
            <h3 className="text-lg font-bold m-0">{invoice.invoice_to}</h3>
          </div>
          <div className="basis-2/5">
            <table className="w-full font-bold">
              <tbody>
                <tr>
                  <td>Date:</td>
                  <td>
                    {formatDate(new Date(invoice.date_added), 'MMMM dd, yyyy')}
                  </td>
                </tr>
                <tr>
                  <td>Invoice:</td>
                  <td>{invoice.code}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="mt-12">
          <h4 className="font-bold uppercase m-0">Order Detail</h4>

          <table className="w-full mt-4">
            <thead>
              <tr className="border-t border-b border-gray-300">
                <th className="text-left px-4 py-6">Product</th>
                <th className="text-right px-4 py-6">Amount</th>
                <th className="text-right px-4 py-6">Price</th>
                <th className="text-right px-4 py-6">Discount</th>
                <th className="text-right px-4 py-6">Total</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
            <tfoot>
              <tr className="bg-gray-100">
                <th className="text-left px-4 py-6" colSpan={4}>
                  Total
                </th>
                <td className="text-right px-4 py-6">
                  {convertToRupiah(totalPrice, true)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>

        <div className="mt-12">
          <p className="font-bold text-lg m-0">Thank you for your business</p>
        </div>
      </div>
    </div>
  )
}

export default ContributorOrderPrintPage
