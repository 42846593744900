import gql from 'graphql-tag'

export const GET_ALL_MICROLEARNING = gql`
  query getAllMicrolearning(
    $orderBy: [microlearnings_order_by!]
    $offset: Int
    $limit: Int
    $categoryIds: [Int!]
    $levels: [Int!]
    $languageIds: [Int!]
    $price: Int_comparison_exp
    $query: String
    $ratingGt: float8
  ) {
    microlearnings_aggregate(
      where: {
        average_rating: {_gte: $ratingGt}
        price: $price
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
        name: {_ilike: $query}
        organization_id: {_is_null: true}
        type: {_eq: "PUBLIC"}
        status: {_eq: "ACTIVE"}
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings(
      offset: $offset
      limit: $limit
      order_by: $orderBy
      where: {
        average_rating: {_gte: $ratingGt}
        name: {_ilike: $query}
        price: $price
        language_id: {_in: $languageIds}
        level: {_in: $levels}
        category_id: {_in: $categoryIds}
        status: {_eq: "ACTIVE"}
        organization_id: {_is_null: true}
        type: {_eq: "PUBLIC"}
      }
    ) {
      id
      name
      description
      price
      icon_url
      average_rating
      review_count
      question_count
      global_user {
        id
        name
        avatar
      }
      academy_course_category {
        id
        name
      }
      global_language {
        id
        name
      }
      level
    }
  }
`
