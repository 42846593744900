import React, {ReactElement, useEffect, useRef, useState} from 'react'
import {Grid} from '@material-ui/core'

import {SeeAllPageContext} from '../../../../pages/learner/see-all'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'
import NoListComponent from '../../../empty-state/NoList.component'
import SearchSeeAll from '../SearchSeeAll.component'

import {LIST_TOP_LEARNING_CONTRIBUTOR} from '../../../../provider/services/LearningContributor.service'

import {LearningType} from '../../../../utils/enum'
import useDebounce from '../../../../hooks/useDebounce'
import {IContributor} from '../../../../interfaces'
import {LEARNING_CATALOG_TITLE_TYPE} from '../../../../utils/constans'

interface LearningContributorProps extends SeeAllPageContext {}

const LearningContributor = ({
  filter,
  type,
}: LearningContributorProps): ReactElement => {
  let {rating: ratingFilter, type: contributorTypeFilter} = filter

  const [searchTerm, setSearchTerm] = useState<string>('')
  const [contributorLabelsFetchVariable, setContributorLabelsFetchVariable] =
    useState<string[]>()
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm)

  const clearType = type?.replace(/[^a-zA-Z0-9]/g, ' ')?.replace('top ', '')
  const queryName = debouncedSearchTerm?.toLowerCase()
  const ratingGte = Number(ratingFilter)
  const title = LEARNING_CATALOG_TITLE_TYPE?.[type] || ''
  const topContributorType =
    type !== 'top-learning-contributor' ? [clearType.toUpperCase()] : []

  contributorTypeFilter = contributorTypeFilter.map((type) =>
    type.toUpperCase()
  )
  const contribLabels = useRef<string[]>([])
  contribLabels.current = contributorTypeFilter?.[0]
    ? contributorTypeFilter
    : topContributorType

  const {data, loading} = LIST_TOP_LEARNING_CONTRIBUTOR({
    limit: 10,
    contrib_labels: contributorLabelsFetchVariable,
  })

  useEffect(() => {
    if (contribLabels.current) {
      setContributorLabelsFetchVariable(contribLabels.current)
    }
  }, [])

  const contributorData = data?.learning_contributor_ratings
    // eslint-disable-next-line array-callback-return
    ?.filter((item: IContributor) => {
      if (
        item?.global_user?.name?.toLowerCase()?.includes(queryName) &&
        item?.total_rating_avg >= ratingGte
      ) {
        if (contribLabels.current.length > 0) {
          if (
            contribLabels.current.includes(item?.global_user?.contributor_label)
          ) {
            return item
          }
        } else {
          return item
        }
      }
    })

  if (loading) {
    if (!data) return <LoadingCardComponent length={2} isContributor />
  }

  return (
    <div>
      <SearchSeeAll
        placeholder={`Cari ${title}`}
        value={searchTerm}
        handleChange={(ev: React.ChangeEvent<HTMLInputElement>) =>
          setSearchTerm(ev.target.value)
        }
      />

      <div className="mt-8">
        <div className="mt-5 text-[#a9a8a8] text-sm">
          {contributorData?.length || 0} Hasil dari {title}
        </div>
      </div>

      {!loading && contributorData?.length < 1 && (
        <div className="flex flex-col justify-center" style={{height: 500}}>
          <NoListComponent
            imageSize={200}
            title="Maaf, tidak ada daftar"
            subTitle={`${title} tidak ditemukan`}
          />
        </div>
      )}

      <Grid container spacing={2} style={{margin: '2px 0'}}>
        {contributorData?.map((item: IContributor, index: number) => (
          <LearnerCatalogCard
            key={`${type}-${index}-${item?.global_user}`}
            type={LearningType.LEARNING_CONTRIBUTOR}
            item={item}
            hidePrice={true}
          />
        ))}
      </Grid>
    </div>
  )
}

export default LearningContributor
