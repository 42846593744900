import {gql} from '@apollo/client'

export const ADD_EVENT_TO_WISHLIST = gql`
  mutation ($eventId: Int!) {
    insert_event_wishlists_one(object: {event_id: $eventId}) {
      id
      event_id
    }
  }
`

export const DELETE_EVENT_FROM_WISHLIST = gql`
  mutation ($userId: uuid, $id: bigint!) {
    update_event_wishlists(
      where: {_and: {user_id: {_eq: $userId}, id: {_eq: $id}}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`
