import {ReactElement} from 'react'
import {useParams} from 'react-router-dom'
import {useSeeAllContext} from '../../../pages/learner/see-all'
import {LearnerCourseProvider} from '../../../provider/LearnerCourse.provider'
import {LearnerEventProvider} from '../../../provider/LearnerEvent.provider'
import {LearnerLearningContributorProvider} from '../../../provider/LearnerLearningContributor.provider'
import {LearnerSocialLearningProvider} from '../../../provider/LearnerSocialLearning.provider'
import Book from './section/Book.component'
import Course from './section/Course.component'
import Event from './section/Event.component'
import LearningContributor from './section/LearningContributor.component'
import MicroLearning from './section/MicroLearning.component'
import SocialLearning from './section/SocialLearning.component'

const SeeAllSection = (): ReactElement => {
  const {active} = useParams()
  const {type, filter, setFilter} = useSeeAllContext()

  const renderSeeAll = () => {
    const _active = active === 'learning-contributor' ? type : active

    switch (_active) {
      case 'course':
        return (
          <LearnerCourseProvider>
            <Course type={type} filter={filter} setFilter={setFilter} />
          </LearnerCourseProvider>
        )
      case 'social-learning':
        return (
          <LearnerSocialLearningProvider>
            <SocialLearning type={type} filter={filter} setFilter={setFilter} />
          </LearnerSocialLearningProvider>
        )
      case 'event':
        return (
          <LearnerEventProvider>
            <Event type={type} filter={filter} setFilter={setFilter} />
          </LearnerEventProvider>
        )
      case 'ebook':
      case 'audiobook':
        return <Book type={type} filter={filter} setFilter={setFilter} />
      case 'micro-learning':
        return (
          <MicroLearning type={type} filter={filter} setFilter={setFilter} />
        )
      case 'top-learning-contributor':
      case 'top-instructor':
      case 'top-mentor':
      case 'top-coach':
      case 'top-expert':
      case 'top-trainer':
        return (
          <LearnerLearningContributorProvider>
            <LearningContributor
              type={_active}
              filter={filter}
              setFilter={setFilter}
            />
          </LearnerLearningContributorProvider>
        )
    }
  }
  return <div>{renderSeeAll()}</div>
}

export default SeeAllSection
