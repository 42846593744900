import React from 'react'
import {
  InputAdornment,
  IconButton,
  makeStyles,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import {FormControlSearch, TextFieldSearch} from '../../filter/FilterStyles'
import {useNavigate, useLocation} from 'react-router-dom'
import {useQuery} from '@apollo/react-hooks'
import {LEARNING_GLOBAL_SEARCH} from '../../../graphql/queries'
import {LearnerAcademicContext} from '../../../provider/Academic.provider'
import classNames from 'classnames'

type LearnerSearchFormProps = {
  initialQuery?: string
  searchKeyword: string
  setSearchKeyword: (value: string) => void
}

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingTop: 10,
    border: 'none',
  },
  textFieldInput: {
    fontWeight: 'bold',
  },
  dropdownWrapper: {
    maxHeight: '300px',
    position: 'absolute',
    marginTop: '55px',
    width: '100%',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 1,
    padding: 20,
  },
}))

function LearnerSearchForm(props: LearnerSearchFormProps) {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const [search, setSearch] = React.useState(props.initialQuery)
  const [searchList, setSearchList] = React.useState([])
  const [toggleSugest, setToggleSugest] = React.useState(false)
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)

  const {data: searchData, loading: searchLoading} = useQuery(
    LEARNING_GLOBAL_SEARCH,
    {
      variables: {
        query: `%${search}%`,
      },
    }
  )
  React.useEffect(() => {
    if (searchData) {
      const {
        academy_books,
        academy_courses,
        classrooms,
        event_schedules,
        microlearnings,
      } = searchData
      const courseBook = academy_books.concat(academy_courses)
      const classroomEvent = classrooms.concat(event_schedules)
      const microlearning = classroomEvent.concat(microlearnings)

      setSearchList(courseBook.concat(microlearning))
    }
  }, [searchData])

  React.useEffect(() => {
    if (
      props.initialQuery &&
      !learnerAcademicContext.reducerState.searchKeyword
    ) {
      learnerAcademicContext.changeSearchKeyword(props.initialQuery)
    }
  }, [props.initialQuery, learnerAcademicContext])

  const onSearch = (e: any) => {
    setSearch(e.target.value)
    props.setSearchKeyword(e.target.value)
    learnerAcademicContext.changeSearchKeyword(e.target.value)
  }

  const onSearchFocus = (focus: any) => {
    if (focus) setToggleSugest(true)
    else setToggleSugest(false)
  }

  const clearSearch = () => {
    setSearch('')
    setToggleSugest(false)
  }

  const onItemSelect = (value: string) => {
    learnerAcademicContext.changeSearchKeyword(value)

    navigate(
      location.pathname.includes('/learner/tabs-all/')
        ? location.pathname
        : `/learner/tabs-all/${value}`
    )

    props.setSearchKeyword(value)
    setSearch(value)
    setToggleSugest(false)
  }
  return (
    <FormControlSearch variant="outlined">
      <TextFieldSearch
        placeholder="Apa yang ingin anda pelajari hari ini?"
        onChange={(e: any) => onSearch(e)}
        onKeyDown={(e: any) => {
          if (e.key === 'Enter') onItemSelect(search || '')
        }}
        value={props.searchKeyword}
        name="search_filter"
        onFocus={() => onSearchFocus(true)}
        /* onBlur={() => {
                  onSearchFocus(false)
                }} */
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
          classes: {
            input: classes.textFieldInput,
            root: classes.root,
          },
          startAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon style={{color: '#014a62'}} className="mr-4" />
              </IconButton>
            </InputAdornment>
          ),
          endAdornment:
            toggleSugest || search ? (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  size="small"
                  className="mr-4"
                  onClick={() => clearSearch()}
                >
                  <CloseIcon style={{color: '#014a62'}} />
                </IconButton>
              </InputAdornment>
            ) : (
              ''
            ),
        }}
      />
      {toggleSugest && (
        <div
          className={classNames(
            classes.dropdownWrapper,
            searchList.length === 0 || !search ? 'hidden' : 'visible'
          )}
        >
          {searchLoading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <List component="nav">
              {searchList.length > 0 ? (
                searchList.map((val: any, index: number) => {
                  return (
                    <ListItem
                      button
                      key={`${val.id}-${index}`}
                      onClick={() => {
                        onItemSelect(val?.title || val?.name)
                      }}
                    >
                      <ListItemText primary={val?.title || val?.name} />
                    </ListItem>
                  )
                })
              ) : (
                <Typography align="center">Tidak ada hasil</Typography>
              )}
            </List>
          )}
        </div>
      )}
    </FormControlSearch>
  )
}

export default LearnerSearchForm
