import {createTheme} from '@material-ui/core'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#014a62',
    },
    secondary: {
      main: '#039be5',
    },
  },
  typography: {
    fontFamily: [
      'Muli',
      '"Helvetica Neue"',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
    button: {
      textTransform: 'capitalize',
    },
  },
})
