import {Outlet} from 'react-router-dom'

import Header from '../../components/header/HeaderComponent'

const ContributorCreatorDetailPageLayout = () => {
  return (
    <div className="bg-[#fafafa]">
      <Header />

      <Outlet />
    </div>
  )
}

export default ContributorCreatorDetailPageLayout
