import {useQuery} from '@apollo/client'
import {Icon, IconButton} from '@material-ui/core'
import {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {GET_FAVORITE_CATEGORY} from '../../../graphql/queries'
import {IFavoriteLearningCategory} from '../../../interfaces/LearningProfile.interface'
import DetailFavoriteCategories from '../../../pages/profile/partial/DetailFavoriteCategory.component'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../utils/globals'
import {CustomTypography} from '../../utils-components/GlobalStyles'

const FavoriteCategoriesCard = () => {
  const {data} = useQuery(GET_FAVORITE_CATEGORY, {
    variables: {userId: USER_ID},
    ...HASURA_ROLE_USER_CONTEXT,
  })

  const [openDetail, setOpenDetai] = useState(false)
  const [dataDetail, setDataDetail] =
    useState<null | IFavoriteLearningCategory>(null)

  const handleDetail = (item: IFavoriteLearningCategory | null) => {
    setDataDetail(item)
    setOpenDetai(!openDetail)
  }

  const navigate = useNavigate()
  const location = useLocation()

  const isAll = location.pathname.includes('category')

  useEffect(() => {
    if (isAll) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }
  }, [isAll])

  return (
    <div className="shadow-xl bg-white">
      <div className={`p-6 ${isAll && 'flex items-center'}`}>
        {isAll && (
          <IconButton
            size="small"
            className="mr-2"
            onClick={() => navigate(-1)}
          >
            <Icon>arrow_back</Icon>
          </IconButton>
        )}
        <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
          Kategori kesukaan saya
        </CustomTypography>
      </div>
      <hr />
      <div className="pl-5 mt-7 space-y-5 pb-5">
        {data?.favoriteLearningCategories
          .slice(0, isAll ? data?.favoriteLearningCategories.length : 5)
          .map((item: IFavoriteLearningCategory, i: number) => (
            <div key={i} className="flex gap-6">
              <CustomTypography
                fsize="24px"
                fcolor="#014a62"
                fweight="bold"
                style={{minWidth: 43}}
              >
                #{i + 1}
              </CustomTypography>
              <div>
                <CustomTypography fsize="14px" fweight="bold">
                  {item.name}
                </CustomTypography>
                <CustomTypography mt="8px" fsize="14px" fcolor="#a9a8a8">
                  {item.total} Katalog pembelajaran
                </CustomTypography>
                <button
                  className="text-xs bg-transparent text-[#039be5]"
                  onClick={() => handleDetail({...item, number: i + 1})}
                >
                  Lihat Rincian
                </button>
              </div>
            </div>
          ))}
      </div>
      {!isAll && (
        <>
          <hr />
          <div className="p-6 items-center flex justify-center">
            <button
              className="text-xs bg-transparent text-[#039be5]"
              onClick={() => navigate('/profile/favorite-category')}
            >
              Lihat Semua
            </button>
          </div>
        </>
      )}
      <DetailFavoriteCategories
        open={openDetail}
        handleClose={() => handleDetail(null)}
        data={dataDetail}
      />
    </div>
  )
}

export default FavoriteCategoriesCard
