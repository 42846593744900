import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_MY_COURSES = gql`
  query (
    $dateLastmonth: timestamp!
    $userId: uuid!
    $orderBy: [academy_courses_order_by!]
    $status: [String!]
    $createdDate: timestamp_comparison_exp
    $limit: Int
    $offset: Int
    $search: String
  ) {
    academy_courses_aggregate(
      where: {
        creator: {_eq: $userId}
        organization_id: {_is_null: true}
        deletedAt: {_is_null: true}
        status: {_in: $status}
        date_added: $createdDate
        title: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }

    academy_courses(
      where: {
        creator: {_eq: $userId}
        organization_id: {_is_null: true}
        deletedAt: {_is_null: true}
        status: {_in: $status}
        date_added: $createdDate
        title: {_ilike: $search}
      }
      limit: $limit
      order_by: $orderBy
      offset: $offset
    ) {
      id
      title
      thumbnail
      date_added
      status
      slug
      price_tier
      academy_price_tier {
        pricing_idr
      }
      average_rating
      comment: academy_course_lessons {
        academy_discussions_aggregate {
          aggregate {
            count
          }
        }
      }
      unanswered_comment: academy_course_lessons {
        academy_discussions_aggregate(
          where: {academy_discussion_responses: {id: {_is_null: false}}}
        ) {
          aggregate {
            count(columns: id)
          }
        }
      }
      total_enrolled: academy_course_enrollments_aggregate {
        aggregate {
          count
        }
      }
      total_rating: academy_course_enrollments_aggregate {
        aggregate {
          count(columns: rating)
        }
      }
      this_month: academy_course_enrollments_aggregate(
        where: {date_added: {_gte: $dateLastmonth}}
      ) {
        aggregate {
          count
        }
      }
      academy_course_enrollments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_COURSE_SLUG_AVAILABILITY = gql`
  query ($slug: String!) {
    academy_courses(where: {slug: {_eq: $slug}}) {
      id
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_DETAILS = gql`
  query ($userId: uuid!, $slug: String!) {
    academy_courses(
      where: {
        creator: {_eq: $userId}
        slug: {_eq: $slug}
        deletedAt: {_is_null: true}
      }
      limit: 1
    ) {
      id
      slug
      title
      thumbnail
      status
      academy_course_sections(order_by: [{order: asc}, {id: asc}]) {
        id
        title
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_ENROLLMENT_STATS = gql`
  query ($courseId: Int!) {
    getDashboardLearnerContributorCourseDetail(courseId: $courseId) {
      eighteen_to_twenty_four
      twenty_five_to_thirty_four
      thirty_five_to_forty_nine
      over_fifty
      male
      female
      total_learner
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_ACTIVITY_BREAKDOWN = gql`
  query (
    $courseId: String!
    $startDate: String!
    $endDate: String!
    $timeType: String!
  ) {
    getActivityByTimeCourseDetail(
      startDate: $startDate
      endDate: $endDate
      courseId: $courseId
      timeType: $timeType
    ) {
      wishlist_course
      enrollment_course
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_EARNING_BREAKDOWN = gql`
  query (
    $startDate: String!
    $endDate: String!
    $timeType: String!
    $courseId: String!
  ) {
    getEarningByTimeCourseDetail(
      startDate: $startDate
      endDate: $endDate
      timeType: $timeType
      courseId: $courseId
    ) {
      earning_course
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_PROGRESS = gql`
  query ($courseId: Int!) {
    getProgressCourseDetail(courseId: $courseId) {
      total
      landing_page
      target
      content
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_LANDING_DETAILS = gql`
  query ($userId: uuid!, $slug: String!) {
    academy_courses(
      where: {
        creator: {_eq: $userId}
        slug: {_eq: $slug}
        deletedAt: {_is_null: true}
      }
      limit: 1
    ) {
      id
      thumbnail
      video
      title
      description
      language
      level
      academy_course_category {
        id
        academy_course_category {
          id
        }
      }
      academy_price_tier {
        id
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_TARGET_DETAILS = gql`
  query ($userId: uuid!, $slug: String!) {
    academy_courses(
      where: {
        creator: {_eq: $userId}
        slug: {_eq: $slug}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      goals
      requirements
      target_audience
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_CURRICULUM_DETAILS = gql`
  query ($userId: uuid!, $slug: String!) {
    academy_courses(
      where: {
        creator: {_eq: $userId}
        slug: {_eq: $slug}
        deletedAt: {_is_null: true}
      }
    ) {
      id
      academy_course_sections(order_by: {order: asc}) {
        id
        title
        academy_course_lessons(order_by: {order: asc}) {
          id
          lesson_type
          title
          summary
          duration
          video_url
          resources
          academy_course_questions {
            id
            order
            fulltext
            fieldtype
            choices
            answer_keys
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_LESSONS_BY_SECTION = gql`
  query ($sectionId: Int!, $search: String) {
    section: academy_course_sections_by_pk(id: $sectionId) {
      id
      title
    }
    lessons: academy_course_lessons(
      where: {section_id: {_eq: $sectionId}}
      order_by: [
        {academy_course_section: {order: asc}}
        {academy_course_section: {id: asc}}
        {order: asc}
        {id: asc}
      ]
    ) {
      id
      title
      lesson_type
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_LESSONS = gql`
  query ($courseId: Int!, $limit: Int, $nin: [Int!]) {
    total: academy_course_lessons_aggregate(
      where: {
        id: {_nin: $nin}
        deletedAt: {_is_null: true}
        course_id: {_eq: $courseId}
      }
    ) {
      aggregate {
        count
      }
    }
    lessons: academy_course_lessons(
      where: {
        id: {_nin: $nin}
        deletedAt: {_is_null: true}
        course_id: {_eq: $courseId}
      }
      order_by: [
        {academy_course_section: {order: asc}}
        {academy_course_section: {id: asc}}
        {order: asc}
        {id: asc}
      ]
      limit: $limit
    ) {
      id
      title
      lesson_type
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_LESSON_DETAILS = gql`
  query ($lessonId: Int!) {
    lesson: academy_course_lessons_by_pk(id: $lessonId) {
      id
      lesson_type
      title
      summary
      resources
      video_url
      section: academy_course_section {
        id
        course_id
      }
      questions: academy_course_questions(order_by: [{order: asc}, {id: asc}]) {
        id
        order
        fulltext
        fieldtype
        choices
        answer_keys
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSES = gql`
  query (
    $lessonId: Int
    $search: String
    $limit: Int
    $offset: Int
    $orderBy: JSON
  ) {
    response: getQuizResponseCourse(
      search: $search
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      lessonId: $lessonId
    ) {
      count
      average_score
      lowest_score
      highest_score
      data: score_data {
        user_id
        user_name
        user_avatar
        total_repeat
        lowest_score
        highest_score
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSE_DETAILS = gql`
  query (
    $lessonId: Int
    $userId: String
    $limit: Int
    $offset: Int
    $orderBy: JSON
  ) {
    response: getQuizResponseCourseDetail(
      limit: $limit
      offset: $offset
      orderBy: $orderBy
      lessonId: $lessonId
      userId: $userId
    ) {
      count
      lowest_score
      highest_score
      user: user_data {
        user_id
        name: user_name
        avatar: user_avatar
        job_title
        company_name
      }
      data: score_data {
        user_id
        score
        log_sequence
        correct_answer
        date_submitted
        question
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_RECENT_TRANSACTIONS = gql`
  query (
    $where: global_invoices_bool_exp
    $order: [global_invoices_order_by!]
    $limit: Int
    $offset: Int
  ) {
    total: global_invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    invoices: global_invoices(
      where: $where
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      id
      code
      status
      final_price
      date_added
      global_wallets {
        id
        global_users {
          id
          avatar
          name
          placements: people_work_placements(
            limit: 1
            where: {status: {_eq: "ACTIVE"}}
          ) {
            id
            profile: company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_COURSE_LEARNERS = gql`
  query (
    $where: academy_course_enrollments_bool_exp
    $whereUnfiltered: academy_course_enrollments_bool_exp
    $limit: Int
    $offset: Int
  ) {
    total_unfiltered: academy_course_enrollments_aggregate(
      where: $whereUnfiltered
      distinct_on: [user_id]
    ) {
      aggregate {
        count
      }
    }
    total: academy_course_enrollments_aggregate(
      where: $where
      distinct_on: [user_id]
    ) {
      aggregate {
        count
      }
    }
    learners: academy_course_enrollments(
      where: $where
      limit: $limit
      offset: $offset
      order_by: [{user_id: asc}, {id: desc}]
      distinct_on: [user_id]
    ) {
      id
      completion_rate
      global_user {
        id
        name
        avatar
        email
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
