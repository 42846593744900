import {useState} from 'react'
import NoListComponent from '../../../components/empty-state/NoList.component'
import {CustomTypography} from '../../../components/utils-components/GlobalStyles'
import {IDiaryResume} from '../../../interfaces/LearningDiary.interface'
import {formattedTimeConvert} from '../../../utils/helpers'
import DetailLearningDiaryResume from './DetailLearningDiaryResume.component'
type Props = {
  dataResume: IDiaryResume[] | undefined | null
}

const ListLearningDiaryResume = (props: Props) => {
  const [open, setOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<null | IDiaryResume>(null)
  const handleDetail = () => setOpen(!open)

  const handleOpenDetail = (res: IDiaryResume) => {
    handleDetail()
    setSelected(res)
  }

  return (
    <div style={{minHeight: 300}}>
      {props.dataResume?.length === 0 ? (
        <NoListComponent
          title="Maaf, tidak ada daftar"
          subTitle="Catatan pembelajaran tidak ditemukan"
        />
      ) : (
        props.dataResume?.map((res: IDiaryResume, i: number) => {
          const duration = formattedTimeConvert(
            res.learning_diaries_aggregate?.aggregate?.sum?.duration,
            true
          )
          return (
            <div className="flex justify-between px-7 mb-6" key={i}>
              <div className="flex gap-6">
                <div
                  className={`w-4 h-4 bg-transparent border-4 rounded-full`}
                  style={{borderColor: res.color}}
                />
                <div>
                  <CustomTypography fsize="14px">{res.name}</CustomTypography>
                  <button
                    className="text-xs bg-transparent text-[#039be5]"
                    onClick={() => handleOpenDetail(res)}
                  >
                    Lihat Rincian
                  </button>
                </div>
              </div>
              <CustomTypography fsize="18px" fcolor="#4caf50" fweight="bold">
                {duration}
              </CustomTypography>
            </div>
          )
        })
      )}
      <DetailLearningDiaryResume
        open={open}
        handleClose={handleDetail}
        data={selected}
      />
    </div>
  )
}

export default ListLearningDiaryResume
