import {Dialog, Icon, IconButton} from '@material-ui/core'
import {FC} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {
  CustomTypography,
  ModalHeader,
} from '../../../components/utils-components/GlobalStyles'
import {IFavoriteLearningCategory} from '../../../interfaces/LearningProfile.interface'

type TDetailFav = {
  open: boolean
  handleClose: (item: IFavoriteLearningCategory | null) => void
  data: IFavoriteLearningCategory | null
}

const DetailFavoriteCategories: FC<TDetailFav> = ({
  open,
  handleClose,
  data,
}) => {
  const chartData = {
    labels: ['Kursus', 'Buku elektronik', 'Buku audio', 'Pembelajaran Mikro'],
    datasets: [
      {
        data: [
          data?.course_count,
          data?.e_book_count,
          data?.audio_book_count,
          data?.microlearning_count,
        ],
        backgroundColor: ['#039be5', '#ef4d5e', '#ffa000', '#4caf50'],
        borderColor: ['#039be5', '#ef4d5e', '#ffa000', '#4caf50'],
        borderWidth: 1,
      },
    ],
  }

  const item = [
    {id: 1, color: '#039be5', name: 'Kursus', number: data?.course_count},
    {
      id: 2,
      color: '#ef4d5e',
      name: 'Buku elektronik',
      number: data?.e_book_count,
    },
    {
      id: 3,
      color: '#ffa000',
      name: 'Buku audio',
      number: data?.audio_book_count,
    },
    {
      id: 4,
      color: '#4caf50',
      name: 'Pembelajaran Mikro',
      number: data?.microlearning_count,
    },
  ]

  return (
    <Dialog open={open} onClose={() => handleClose(null)} fullWidth>
      <ModalHeader>
        <CustomTypography fsize="16px" fweight="bold">
          #{data?.number} - {data?.name}
        </CustomTypography>
        <IconButton onClick={() => handleClose(null)}>
          <Icon>close</Icon>
        </IconButton>
      </ModalHeader>
      <div className="lg:flex lg:items-center px-6">
        <div className="w-auto h-auto lg:w-1/2 lg:h-80 relative px-2 lg:px-0 p-12">
          <Doughnut
            data={chartData}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              cutout: '80%',
              plugins: {
                legend: {
                  position: 'right',
                  display: false,
                },
              },
            }}
          />
        </div>
        <div className="w-full lg:pl-8">
          {item.map((res) => (
            <div className="flex justify-between px-7 mb-6" key={res.id}>
              <div className="flex gap-6">
                <div
                  className={`w-4 h-4 bg-transparent border-4 rounded-full`}
                  style={{borderColor: res.color}}
                />
                <CustomTypography fsize="14px">{res.name}</CustomTypography>
              </div>
              <CustomTypography fsize="14px">{res.number}</CustomTypography>
            </div>
          ))}
        </div>
      </div>
    </Dialog>
  )
}

export default DetailFavoriteCategories
