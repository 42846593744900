import gql from 'graphql-tag'

export const GET_TOP_CONTRIBUTOR = gql`
  query getTopContributor(
    $all_offset: Int
    $all_limit: Int
    $contrib_labels: [String!]
    $queryName: String
    $totalRatingAvgCmp: float8_comparison_exp
  ) {
    learning_contributor_ratings(
      where: {
        global_user: {
          name: {_ilike: $queryName}
          contributor_label: {_in: $contrib_labels}
        }
        total_rating_avg: $totalRatingAvgCmp
      }
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $all_limit
      offset: $all_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    count: learning_contributor_ratings_aggregate(
      where: {
        global_user: {
          name: {_ilike: $queryName}
          contributor_label: {_in: $contrib_labels}
        }
        total_rating_avg: $totalRatingAvgCmp
      }
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
    ) {
      aggregate {
        count
      }
    }
    countFilter: learning_contributor_ratings_aggregate(
      where: {
        global_user: {
          name: {_ilike: $queryName}
          contributor_label: {_in: $contrib_labels}
        }
        total_rating_avg: $totalRatingAvgCmp
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_ASIDE_OF_TOP_CONTRIBUTOR = gql`
  query getAsideOfTopContributor($label: String, $offset: Int, $limit: Int) {
    learning_contributor_ratings(
      where: {global_user: {contributor_label: {_eq: $label}}}
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $limit
      offset: $offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
    count: learning_contributor_ratings_aggregate(
      where: {global_user: {contributor_label: {_eq: $label}}}
    ) {
      aggregate {
        count
      }
    }
  }
`
