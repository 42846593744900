import {useQuery, useMutation} from '@apollo/react-hooks'
import {useQueryService} from '../../config/axiosQuery.service'
import {QueryFetchPolicy} from '../../utils/enum'

import {
  CHECK_CART,
  GET_BOOK_OWNED,
  GET_BOOK_DETAIL_BY_ISBN,
  GET_MY_BOOKS,
  GET_RECOMMENDED_BOOK_CATEGORIES,
  GET_BOOK_FIELDS_BY_ISBN,
  GET_BOOK_WISHLISTS,
  GET_BOOK_REVIEWS,
  GET_BOOK_REVIEWS_AGGREGATE,
  GET_MY_BOOK_REVIEWS,
  GET_RELATED_BOOKS,
  GET_CONTRIBUTOR_INFO_SHORT,
} from '../../graphql/queries'
import {DELETE_BOOK_FROM_WISHLIST} from '../../graphql/mutations'
import {USER_ID, HASURA_ROLE_USER_CONTEXT} from '../../utils/globals'

export const MY_BOOKS = (
  limit: number = 4,
  offset: number = 0,
  type: number = 2,
  isSkip: boolean = false
) => {
  return useQuery(GET_MY_BOOKS, {
    variables: {
      type,
      limit,
      offset,
      userId: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: isSkip,
  })
}

type RelatedBookParams = {
  author: string
  category: number
  contributor: string
  limit?: number
  offset?: number
  type: number
  skipFetching?: boolean
}

export const RELATED_BOOKS = ({
  author,
  category,
  contributor,
  limit = 4,
  offset = 0,
  type = 2,
  skipFetching = false,
}: RelatedBookParams) => {
  return useQuery(GET_RELATED_BOOKS, {
    variables: {
      author,
      category,
      contributor,
      limit,
      offset,
      type,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}

export const LIST_TRENDING_BOOKS = (
  limit: number,
  page: number,
  type: number,
  priceMin: number | string = '',
  priceMax: number | string = '',
  rating: number | string = '',
  categories: string = '',
  levels: string = '',
  languages: string = ''
) =>
  useQueryService(
    ['listTrendingBooks'],
    limit !== -1
      ? `academy/book/get?limit=${limit}&page=${page}&type=${type}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${levels}&${languages}&${categories}`
      : `academy/book/get?page=${page}&type=${type}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${levels}&${languages}&${categories}`
  )

export const LIST_RECOMMENDATION_BOOKS = (
  limit: number,
  page: number,
  type: number,
  priceMin: number | string = '',
  priceMax: number | string = '',
  rating: number | string = '',
  categories: string = '',
  levels: string = '',
  languages: string = ''
) =>
  useQueryService(
    ['listRecommendationBooks'],
    limit !== -1
      ? `academy/book/get?limit=${limit}&page=${page}&type=${type}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${levels}&${languages}&${categories}`
      : `academy/book/get?page=${page}&type=${type}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${levels}&${languages}&${categories}`
  )

export const LIST_RECOMMENDATION_BOOK_CATEGORIES = () => {
  return useQuery(GET_RECOMMENDED_BOOK_CATEGORIES, {
    variables: {
      userId: USER_ID,
      limit: 4,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const CONTRIBUTOR_DETAILS = (id: string, skipFetching: boolean) => {
  return useQuery(GET_CONTRIBUTOR_INFO_SHORT, {
    variables: {
      userId: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}

export const CHECK_ITEM_IN_CART = (isbn: string, itemTable: string) => {
  return useQuery(CHECK_CART, {
    variables: {
      isbn: isbn,
      item_table: itemTable,
      user: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'network-only',
  })
}

export const CHECK_BOOK_OWNED = (isbn: string) => {
  return useQuery(GET_BOOK_OWNED, {
    variables: {
      isbn: isbn,
      user: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'network-only',
  })
}

type BookWishlistParams = {
  type?: number
  limit?: number
  offset?: number
  query?: string
  isbns?: [string]
  skipFetching?: boolean
  fetchPolicy?: QueryFetchPolicy
}

export const BOOK_WISHLISTS = (params: BookWishlistParams) => {
  return useQuery(GET_BOOK_WISHLISTS, {
    variables: {
      type: params.type,
      limit: params.limit || null,
      offset: params.offset || null,
      query: params.query,
      isbns: params.isbns,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: params.fetchPolicy || 'network-only',
    skip: params.skipFetching,
  })
}

export const BOOK_BY_ISBN = (isbn: string) => {
  return useQuery(GET_BOOK_DETAIL_BY_ISBN, {
    variables: {
      isbn: isbn,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

type BookReviewsParams = {
  isbn: string
  limit?: number
  offset?: number
  rating?: number | null
  skipFetching?: boolean
  fetchPolicy?: QueryFetchPolicy
}

export const BOOK_REVIEWS = (params: BookReviewsParams) => {
  return useQuery(GET_BOOK_REVIEWS, {
    variables: {
      isbn: params.isbn,
      rating: params.rating,
      limit: params.limit || null,
      offset: params.offset || null,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}
export const BOOK_REVIEWS_AGGREGATE = (isbn: string) => {
  return useQuery(GET_BOOK_REVIEWS_AGGREGATE, {
    variables: {
      isbn: isbn,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const MY_BOOK_REVIEWS = (isbn: string, skipFetching?: boolean) => {
  return useQuery(GET_MY_BOOK_REVIEWS, {
    variables: {
      isbn: isbn,
      user: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
    skip: skipFetching,
  })
}

export const BOOK_FIELDS_BY_ISBN = (isbn: string, skipFetching: boolean) => {
  return useQuery(GET_BOOK_FIELDS_BY_ISBN, {
    variables: {
      isbn: isbn,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}

export const LIST_ALL_BOOKS = (
  type: number = 2,
  limit: number = 8,
  search: string,
  priceMin: number | string,
  priceMax: number | string,
  rating: number | string,
  categories: string,
  levels: string,
  languages: string,
  orders: string
) =>
  useQueryService(
    ['listAllBooks'],
    limit === -1
      ? `academy/book/get?type=${type}&search=${search}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${categories}&${levels}&${languages}&${orders}`
      : `academy/book/get?type=${type}&limit=${limit}&search=${search}&priceMin=${priceMin}&priceMax=${priceMax}&rating=${rating}&${categories}&${levels}&${languages}&${orders}`
  )

export const DELETE_BOOK_WISHLIST = () => {
  return useMutation(DELETE_BOOK_FROM_WISHLIST)
}
