import {Button} from '@material-ui/core'
import React, {ReactElement, Dispatch, SetStateAction} from 'react'
import {
  Outlet,
  useOutletContext,
  useParams,
  useNavigate,
} from 'react-router-dom'
import Header from '../../../components/header/HeaderComponent'
import styled from 'styled-components'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../../components/utils-components/GlobalStyles'
import {MainWrapper} from '../../../components/job-portal/JobPortalStyles'
import {ArrowBack} from '@material-ui/icons'
import {LearningType} from '../../../utils/enum'
import SearchFilterSeeAll from '../../../components/learner/see-all/SearchFilterSeeAll.component'
import {LEARNING_CATALOG_TITLE_TYPE} from '../../../utils/constans'
import {ISeeAllFilter} from '../../../interfaces/Filter.interface'

export interface SeeAllPageProps {
  type:
    | 'trending'
    | 'recommended'
    | 'nearby'
    | 'for-you'
    | 'top-learning-contributor'
    | 'top-instructor'
    | 'top-mentor'
    | 'top-coach'
    | 'top-expert'
    | 'top-trainer'
}

export interface SeeAllPageContext {
  type: SeeAllPageProps['type']
  filter: ISeeAllFilter
  setFilter: Dispatch<SetStateAction<ISeeAllFilter>>
}

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const AppWrapper = styled.div`
  width: 100%;
`

const SeeAllPage = (props: SeeAllPageProps): ReactElement => {
  const {active} = useParams()
  const navigate = useNavigate()

  const title = LEARNING_CATALOG_TITLE_TYPE?.[props.type] || ''

  const [filter, setFilter] = React.useState<ISeeAllFilter>({
    rating: '',
    categoriesREST: '',
    levelsREST: '',
    levelsGQL: [],
    languagesREST: '',
    languagesGQL: [],
    price: '',
    priceMin: '',
    priceMax: '',
    priceGQL: {
      _lte: 0,
      _gte: 0,
    },
    categoriesGQL: '',
    type: [],
  })

  const renderFilter = () => {
    const _active = active === 'learning-contributor' ? props.type : active

    const renderFilterData = () => {
      const maxValue = '999999999'
      const filterData = filter

      filterData.priceMin =
        filterData.price === '1' && filterData.priceMin === ''
          ? '0'
          : filterData.price === '2' && filterData.priceMin === ''
          ? '1'
          : filterData.priceMin

      filterData.priceMax =
        filterData.price === '1' && filterData.priceMax === ''
          ? '0'
          : filterData.price === '2' && filterData.priceMax === ''
          ? maxValue
          : filterData.priceMax

      return filterData
    }

    switch (_active) {
      case 'course':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.COURSE}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'ebook':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.BOOK}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'audiobook':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.BOOK}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'social-learning':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.SOCIAL_LEARNING}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'event':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.EVENT}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'micro-learning':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.MICRO_LEARNING}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'top-learning-contributor':
        return (
          <SearchFilterSeeAll
            isTopLearningContributor={true}
            activeTab={LearningType.LEARNING_CONTRIBUTOR}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
      case 'top-instructor':
      case 'top-mentor':
      case 'top-coach':
      case 'top-expert':
      case 'top-trainer':
        return (
          <SearchFilterSeeAll
            activeTab={LearningType.LEARNING_CONTRIBUTOR}
            academicFilter={renderFilterData()}
            setAcademicFilter={setFilter}
          />
        )
    }
  }

  const outletContext: SeeAllPageContext = {
    type: props.type,
    filter,
    setFilter,
  }

  return (
    <div className="bg-[#fafafa]">
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Katalog Pembelajaran" value="learning-catalog" />
        </StyledTabs>
      </TabsWrapper>
      <MainWrapper id="mainwrapper" className="pr-0 lg:pr-[67.5px] gap-7 ">
        <div className="hidden lg:block lg:w-1/4">{renderFilter()}</div>
        <AppWrapper className="p-5 lg:p-0">
          <Button
            onClick={() => navigate(-1)}
            color="primary"
            startIcon={<ArrowBack style={{fontSize: '16px'}} />}
            style={{fontSize: '16px'}}
          >
            {title}
          </Button>
          <Outlet context={outletContext} />
        </AppWrapper>
      </MainWrapper>
    </div>
  )
}

export function useSeeAllContext() {
  return useOutletContext<SeeAllPageContext>()
}

export default SeeAllPage
