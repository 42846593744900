import {gql} from '@apollo/client'

export const DELETE_LEARNING_DIARY = gql`
  mutation ($id: uuid) {
    update_learning_diaries(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      affected_rows
    }
  }
`
