import React, {useEffect, Dispatch, SetStateAction, ChangeEvent} from 'react'
import {useQuery} from '@apollo/react-hooks'
import {useTitle} from 'hoofd'

import {Paper, Typography, Divider} from '@material-ui/core'

import ExpansionFilter from '../search-filter/ExpansionFilter.component'

import {LearningType} from '../../../utils/enum'
import {
  levelOptions,
  priceOptions,
  ratingOptions,
  languageOptionsGQL,
  contributorTypeOptions,
} from '../../../utils/constans'
import {
  GET_COURSE_CATEGORIES,
  GET_EVENT_CATEGORIES,
} from '../../../graphql/queries'
import {ICategoryGQL} from '../../../interfaces'

import {
  ISeeAllFilter,
  ISearchFilter,
} from '../../../interfaces/Filter.interface'

type SearchFilterSiderbarProps = {
  activeTab?: LearningType
  academicFilter: ISeeAllFilter
  setAcademicFilter: Dispatch<SetStateAction<ISeeAllFilter>>
  isTopLearningContributor?: boolean
}

const SearchFilterSeeAll = ({
  isTopLearningContributor = false,
  ...props
}: SearchFilterSiderbarProps) => {
  const {activeTab} = props

  useTitle('Saring & Cari')

  const isEvent = activeTab === LearningType.EVENT
  const isCourse = props.activeTab === LearningType.COURSE
  // const isBook = props.activeTab === LearningType.BOOK
  const isContributor = props.activeTab === LearningType.LEARNING_CONTRIBUTOR
  const isMicrolearning = props.activeTab === LearningType.MICRO_LEARNING
  const isSocialLearning = props.activeTab === LearningType.SOCIAL_LEARNING

  const [expanded, setExpanded] = React.useState({
    category: true,
    topic: true,
    level: true,
    language: true,
    price: true,
    ratings: true,
    duration: true,
    type: true,
    contributorType: true,
  })
  const [subExpanded, setSubExpanded] = React.useState({})
  const [moreOptions, setMoreOptions] = React.useState({
    category: {isHidden: false, maxLength: 4},
    topic: {isHidden: false, maxLength: 4},
  })

  const categoryQuery = isEvent ? GET_EVENT_CATEGORIES : GET_COURSE_CATEGORIES
  const {data: categoryData} = useQuery(categoryQuery)

  const categories = React.useMemo(() => {
    if (!categoryData) {
      return []
    }

    // NOTE(intrnl): for some reason we're not using subcategories for events,
    // this is a problem because we're supposed to be filtering by that instead
    // of the top-level category that it resides in.

    // since <ExpansionFilter> requires the subcategories, and we're not sure
    // how to proceed with this, we'll just shove all top-level categories as
    // subcategories for now.
    if (isEvent) {
      return [
        {
          value: 0,
          label: 'Events',
          options: categoryData.event_categories,
        },
      ]
    }

    const _categories: ICategoryGQL[] =
      categoryData.academy_course_categories.filter(
        (category: ICategoryGQL) => {
          return !category.parent
        }
      )

    return _categories.map((category: ICategoryGQL) => ({
      label: category.name,
      value: category.id,
      options: category.academy_course_categories,
    }))
  }, [categoryData, isEvent])

  const [filterBy, setFilterBy] = React.useState<ISearchFilter>({
    category: [],
    level: [],
    language: [],
    price: '',
    priceMin: '',
    priceMax: '',
    ratings: '',
    duration: [],
    priceGQL: '',
    categoryIdsGQL: [],
    ratingsGQL: [],
    type: [],
  })

  useEffect(() => {
    if (
      filterBy.ratings ||
      filterBy.category ||
      filterBy.level ||
      filterBy.language ||
      filterBy.priceGQL ||
      filterBy.categoryIdsGQL ||
      filterBy.ratingsGQL ||
      filterBy.type
    ) {
      const isQuery = isMicrolearning || isSocialLearning || isCourse

      // TODO: CATEGORIES FILTER
      let categories: number[] | string = isQuery ? [] : ''

      if (filterBy.category.length > 0) {
        filterBy.category.forEach((category: string, index: number) => {
          if (isQuery && Array.isArray(categories)) {
            return categories.push(parseInt(category))
          } else {
            if (isEvent) {
              return categories === ''
                ? (categories = category)
                : (categories += `,${category}`)
            } else {
              return (categories += `category[${index}]=${category}&`)
            }
          }
        })
      }

      // TODO: LEVELS FILTER
      let levels: string = ''
      const levelsGQL: number[] = []
      if (filterBy.level.length > 0) {
        filterBy.level.forEach((level: string, index: number) => {
          if (isQuery) {
            return levelsGQL.push(Number(level))
          }
          return (levels += `level[${index}]=${level}&`)
        })
      }

      // TODO: LANGUAGES FILTER
      let languages: string = ''
      const languagesGQL: number[] = []
      if (filterBy.language.length > 0) {
        filterBy.language.forEach((language: string, index: number) => {
          if (isQuery) {
            return languagesGQL.push(Number(language))
          }
          return (languages += `language[${index}]=${language}&`)
        })
      }
      props.setAcademicFilter({
        ...props.academicFilter,
        rating: filterBy.ratings,
        categoriesREST: String(categories),
        categoriesGQL: categories,
        levelsREST: levels,
        levelsGQL: levelsGQL,
        languagesREST: languages,
        languagesGQL: languagesGQL,
        price: filterBy.price,
        priceMin: filterBy.priceMin,
        priceMax: filterBy.priceMax,
        type: filterBy.type,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterBy.ratings,
    filterBy.category,
    filterBy.level,
    filterBy.language,
    filterBy.priceMin,
    filterBy.priceMax,
    filterBy.price,
    filterBy.type,
  ])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target

    if (name !== 'priceMin' && name !== 'priceMax' && name !== 'ratings') {
      if (name === 'price') {
        setFilterBy((prevState: ISearchFilter) => ({
          ...prevState,
          price: value,
          priceMin: '',
          priceMax: '',
        }))
      } else {
        const _filterBy: {[key: string]: any} = filterBy

        let filters = [..._filterBy[name]]
        const removedFilters = filters.filter((item) => item !== value)

        if (filters.includes(value)) {
          filters = removedFilters
        } else {
          filters.push(value)
        }
        setFilterBy((prevState: ISearchFilter) => ({
          ...prevState,
          [name]: filters,
        }))
      }
    } else {
      setFilterBy((prevState: ISearchFilter) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handlePanel = (panel: string) => {
    const _expandPanel = !expanded[panel as keyof typeof expanded]
    setExpanded((prevState) => ({...prevState, [panel]: _expandPanel}))
  }

  const handleSubPanel = (subPanel: string) => {
    const _expandPanel = !expanded[subPanel as keyof typeof expanded]
    setSubExpanded((prevState) => ({
      ...prevState,
      [subPanel]: _expandPanel,
    }))
  }
  const handleSeeMore = (
    e: ChangeEvent<HTMLInputElement>,
    panel: string,
    maxLength: number
  ) => {
    e.preventDefault()
    setMoreOptions((prevState) => ({
      ...prevState,
      [panel]: {isHidden: true, maxLength},
    }))
  }
  return (
    <>
      <Typography className="font-extrabold">Saring</Typography>

      <Paper className="py-4 mt-5">
        {!isContributor && (
          <>
            <>
              <ExpansionFilter
                expanded={expanded.category}
                subExpanded={subExpanded}
                onChangePanel={() => handlePanel('category')}
                onChangeSubPanel={handleSubPanel}
                summary="Category"
                label="Kategori"
                details={categories}
                onChangeCheckbox={handleOnChange}
                moreOptions={{
                  onClick: (e: any) => handleSeeMore(e, 'category', 0),
                  isHidden: moreOptions.category.isHidden,
                  maxLength: moreOptions.category.maxLength,
                }}
                hasSubCategories={true}
              />
              <Divider />
            </>

            {(isCourse || isMicrolearning) && (
              <>
                <>
                  <ExpansionFilter
                    expanded={expanded.level}
                    onChangePanel={() => handlePanel('level')}
                    summary="Level"
                    label="Tingkatan"
                    details={levelOptions}
                    onChangeCheckbox={handleOnChange}
                  />
                  <Divider />
                </>
                <>
                  <ExpansionFilter
                    expanded={expanded.language}
                    onChangePanel={() => handlePanel('language')}
                    summary="Language"
                    label="Bahasa"
                    details={languageOptionsGQL}
                    onChangeCheckbox={handleOnChange}
                  />
                  <Divider />
                </>
              </>
            )}
            {!isSocialLearning && (
              <>
                <ExpansionFilter
                  expanded={expanded.price}
                  onChangePanel={() => handlePanel('price')}
                  summary="Price"
                  label="Harga"
                  details={priceOptions}
                  onChangeRadio={handleOnChange}
                  rangeInputNumber={{
                    isOpen: true,
                    onChangeNumberMin: handleOnChange,
                    onChangeNumberMax: handleOnChange,
                  }}
                  value={{
                    ratings: filterBy.price,
                    price: {
                      priceMin: filterBy.priceMin,
                      priceMax: filterBy.priceMax,
                    },
                  }}
                />
                <Divider />
              </>
            )}
          </>
        )}
        {isContributor && isTopLearningContributor && (
          <>
            <ExpansionFilter
              expanded={expanded.contributorType}
              onChangePanel={() => handlePanel('contributorType')}
              summary="Type"
              label="Tipe"
              details={contributorTypeOptions}
              onChangeCheckbox={handleOnChange}
            />

            <Divider />
          </>
        )}

        {!isEvent && (
          <ExpansionFilter
            expanded={expanded.ratings}
            onChangePanel={() => handlePanel('ratings')}
            summary="Ratings"
            label="Penilaian"
            isRating
            details={ratingOptions}
            onChangeRadio={handleOnChange}
            value={{ratings: filterBy.ratings}}
          />
        )}
      </Paper>
    </>
  )
}
export default SearchFilterSeeAll
