import {gql} from '@apollo/client'

export const GET_PUBLIC_COURSES = gql`
  query (
    $limit: Int
    $offset: Int
    $price: Int_comparison_exp
    $search: String
    $rating: float8
    $category: [Int!]
    $language: [String!]
    $level: [Int!]
  ) {
    academy_courses_aggregate(
      where: {
        organization_id: {_is_null: true}
        status: {_eq: "active"}
        deletedAt: {_is_null: true}
        academy_price_tier: {pricing_idr: $price}
        title: {_ilike: $search}
        average_rating: {_gte: $rating}
        academy_course_category: {id: {_in: $category}}
        language: {_in: $language}
        level: {_in: $level}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_courses(
      where: {
        organization_id: {_is_null: true}
        deletedAt: {_is_null: true}
        status: {_eq: "active"}
        academy_price_tier: {pricing_idr: $price}
        title: {_ilike: $search}
        average_rating: {_gte: $rating}
        academy_course_category: {id: {_in: $category}}
        language: {_in: $language}
        level: {_in: $level}
      }
      limit: $limit
      offset: $offset
    ) {
      CreatorUser: global_user {
        id
        biography
        name
      }
      active_marketing
      averageRating: average_rating
      category
      congratulation
      count_enrollment
      count_lesson
      count_section
      creator
      date_added
      description
      duration_seconds
      estimated_duration
      goals
      id
      language
      last_modified
      level
      organization_id
      price_tier
      requirements
      slug
      status
      subtitle
      target_audience
      thumbnail
      title
      topic
      type
      video
      welcome
      Price: academy_price_tier {
        id
        pricing
        pricing_idr
        pricing_usd
      }
    }
  }
`
