import {Field, Form} from 'react-final-form'

import arrayMutators from 'final-form-arrays'

import {
  Button,
  Radio,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@material-ui/core'

import {isRequired} from '../../../../../../../components/input-forms/validators'
import FormText from '../../../../../../../components/input-forms/FormText'
import CkEditorField from '../../../../../../../components/contributor/forms/CkEditorField'
import AttachmentField, {
  Accept,
  Attachment,
} from '../../../../../../../components/contributor/forms/AttachmentField'

import {type LessonFormProps} from './_Lesson'
import {EditState} from '../_utils'
import DurationField from '../fields/DurationField'

import useEvent from '../../../../../../../hooks/useEvent'

const ACCEPTS_PDF: Accept = {
  'application/pdf': ['.pdf'],
}

const formatLegacyAttachments = (file: any) => {
  if (typeof file !== 'string') {
    return file
  }

  const next: Attachment = {
    name: file.slice(file.lastIndexOf('/') + 1),
    url: file,
    size: 0,
  }

  return next
}

const ArticleLessonForm = (props: LessonFormProps) => {
  const {editState, initialValues, onSubmit, onCancel} = props

  const isAdding = editState === EditState.ADDING
  const onSubmitCallback = useEvent(onSubmit)

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      subscription={{pristine: true}}
      mutators={{...arrayMutators}}
    >
      {({handleSubmit, pristine}) => (
        <div className="flex flex-col gap-6 p-6 text-sm">
          <Typography color="primary" className="font-bold text-sm">
            {isAdding ? 'Add' : 'Edit'} Article
          </Typography>

          <FormText
            name="title"
            validate={isRequired}
            label="Article Title*"
            placeholder="Add article title"
          />

          <DurationField name="duration" label="Estimated Reading Duration" />

          {isAdding && (
            <Field
              name="summary"
              validateFields={[]}
              subscription={{value: true}}
              allowNull
            >
              {({input: {value, onChange}}) => {
                const isManual = value !== null

                return (
                  <FormControl>
                    <Typography
                      component="label"
                      color="textSecondary"
                      className="block text-sm"
                      gutterBottom
                    >
                      Article Content Input*
                    </Typography>

                    <FormGroup row>
                      <FormControlLabel
                        label="Manual"
                        control={
                          <Radio
                            checked={isManual}
                            onChange={() => onChange('')}
                            color="primary"
                          />
                        }
                      />

                      <FormControlLabel
                        label="Upload File"
                        control={
                          <Radio
                            checked={!isManual}
                            onChange={() => onChange(null)}
                            color="primary"
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                )
              }}
            </Field>
          )}

          <Field name="summary" subscription={{value: true}} allowNull>
            {({input: {value}}) => {
              const isManual = value !== null

              return isManual ? (
                <CkEditorField name="summary" label="Content*" />
              ) : (
                <AttachmentField
                  name="resources"
                  label="Upload File"
                  maxFiles={1}
                  format={formatLegacyAttachments}
                  accept={ACCEPTS_PDF}
                />
              )
            }}
          </Field>

          <div className="flex flex-row-reverse gap-4">
            <Button
              disabled={pristine}
              onClick={() => handleSubmit()}
              variant="contained"
              color="secondary"
              disableElevation
              className="h-12 w-24"
            >
              Confirm
            </Button>

            <Button
              onClick={onCancel}
              color="inherit"
              className="h-12 w-24 text-red-400"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Form>
  )
}

export default ArticleLessonForm
