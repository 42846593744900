import React, {FC} from 'react'
import {IEvent} from '../../../../interfaces'
import {TContributorSection} from '../../../../interfaces/Contributor.interface'
import {LIST_CONTRIBUTOR_EVENT} from '../../../../provider/services/LearningContributor.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCircular from '../../../loading/LoadingCircular.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'

const Event: FC<TContributorSection> = ({id, search, sort}) => {
  const {data, loading} = LIST_CONTRIBUTOR_EVENT(search, id, sort)

  if (loading) {
    return <LoadingCircular height="500px" />
  }

  return data?.event_schedules.length === 0 ? (
    <div className="flex flex-col justify-center" style={{height: 500}}>
      <NoListComponent
        title="Maaf, tidak ada daftar"
        subTitle="Acara tidak ditemukan"
      />
    </div>
  ) : (
    <div className="flex flex-wrap -mx-3">
      {data?.event_schedules.map((item: IEvent, i: number) => {
        return (
          <LearnerCatalogCard
            type={LearningType.EVENT}
            item={item}
            key={i}
            hidePrice={false}
          />
        )
      })}
    </div>
  )
}

export default Event
