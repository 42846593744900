import {Avatar, Paper} from '@material-ui/core'
import {useState} from 'react'
import {userData} from '../../utils/globals'
import UserNavigationComponent from './UserNavigation.component'
import LinesEllipsis from 'react-lines-ellipsis'

const UserLoggedInMobile = () => {
  const [more, setMore] = useState(false)
  return (
    <Paper className="mb-5 py-4">
      <div className="flex items-center gap-4 mb-4 px-4">
        <Avatar src={userData.avatar} className="w-14 h-14 object-cover" />
        <div>
          <p className="text-[16px] font-semibold mb-1">{userData.name}</p>
          <LinesEllipsis
            className="text-[12px] text-[#A9A8A8]"
            text={`${userData.position_name} at ${userData.company_name}`}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </div>
      </div>
      {more && <UserNavigationComponent />}
      <p
        className="font-semibold text-center cursor-pointer text-[14px] text-[#039BE5]"
        onClick={() => setMore(!more)}
      >
        Show {more ? 'Less' : 'More'}
      </p>
    </Paper>
  )
}

export default UserLoggedInMobile
