import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Typography, Tabs, Tab} from '@material-ui/core'
import {Share, FavoriteBorder, Favorite} from '@material-ui/icons'
import Rating from '../../../utils-components/Rating.component'
import TabAbout from './TabAbout.component'
import TabReview from './TabReview.component'
import TabComment from './comment/TabComment.component'
import ContributorCard from '../../catalog/card/ContributorCard.component'
import {makeStyles} from '@material-ui/core/styles'
import {ICourse, ICourseWishlist} from '../../../../interfaces/Course.interface'
import {
  LIST_MY_WISHLIST,
  ADD_TO_WISHLIST,
  REMOVE_FROM_WISHLIST,
  COURSE_REVIEW,
} from '../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import ShareCatalogItemDialog from '../../catalog/dialog/ShareCatalogItemDialog.component'
import {useSnackbar} from 'notistack'
import ConfirmationModal from '../../../modal/ConfirmationModal'
import CheckoutCard from '../../catalog/card/CheckoutCard.component'
import {CONTRIBUTOR_DETAILS} from '../../../../provider/services/Book.service'

const useStyles = makeStyles(() => ({
  ratingWrapper: {
    marginTop: '-15px',
  },
}))

const WishlistControl = (props: {courseId: number}) => {
  const {courseId} = props

  const {enqueueSnackbar} = useSnackbar()
  const {data, refetch, isFetching} = LIST_MY_WISHLIST()

  const wishlist: ICourseWishlist | undefined = React.useMemo(() => {
    if (!data) {
      return undefined
    }

    return data.find((item: ICourseWishlist) => item.courseId === courseId)
  }, [data, courseId])

  const {mutateAsync: dispatchAdd, isLoading: dispatchingAdd} =
    ADD_TO_WISHLIST()
  const {mutateAsync: dispatchRemove, isLoading: dispatchingRemove} =
    REMOVE_FROM_WISHLIST(wishlist?.id!)

  const handleAddition = () => {
    const promise = dispatchAdd({courseId})

    promise.then(
      () => {
        refetch()
      },
      () => {
        enqueueSnackbar(`Failed to add course to wishlist`, {variant: 'error'})
      }
    )
  }

  const handleRemoval = () => {
    const promise = dispatchRemove({})

    promise.then(
      () => {
        refetch()
      },
      () => {
        enqueueSnackbar(`Failed to remove course from wishlist`, {
          variant: 'error',
        })
      }
    )
  }

  if (!data) {
    return null
  }

  const Icon = wishlist ? Favorite : FavoriteBorder
  const disabled = dispatchingAdd || dispatchingRemove || isFetching

  return (
    <div
      className="flex flex-row cursor-pointer"
      onClick={!disabled ? (wishlist ? handleRemoval : handleAddition) : null!}
    >
      <Icon color={disabled ? 'disabled' : 'primary'} />

      <Typography
        variant="subtitle2"
        color={disabled ? 'textSecondary' : 'primary'}
        className="ml-3"
      >
        {wishlist ? 'Hapus dari Daftar Keinginan' : 'Daftar Keinginan'}
      </Typography>
    </div>
  )
}

export interface CourseDetailTabProps {
  course: ICourse
  refetch: () => void
  isPreview?: boolean
}

const CourseDetailTab = (props: CourseDetailTabProps) => {
  const {course, refetch} = props

  const classes = useStyles()
  const navigate = useNavigate()

  const [value, setValue] = React.useState(0)
  const [isAcquiredOpen, setIsAcquiredOpen] = React.useState(false)
  const [shareThisCourse, setShareThisCourse] = React.useState({
    isOpen: false,
    copyStatus: false,
  })

  const {data: contributorData, loading: contributorLoading} =
    CONTRIBUTOR_DETAILS(course.CreatorUser?.id || '', false)

  const {data: reviewData, refetch: reviewRefetch} = COURSE_REVIEW(course.id)

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
  }

  const handleFreeAcquisition = () => {
    refetch()
    setIsAcquiredOpen(true)
  }

  const shareableCourseURL =
    window.location.protocol +
    '//' +
    window.location.host +
    '/learner/course/' +
    course.slug

  return (
    <div className="grid gap-8 lg:grid-cols-[minmax(0,_1fr)_440px] mt-4">
      <div className="lg:order-2">
        {contributorLoading ? (
          <Skeleton height={300} />
        ) : (
          <ContributorCard
            item={contributorData?.learning_contributor_ratings_by_pk}
            isLearningCatalogChat={true}
          />
        )}

        {!course.Enrollment && !props.isPreview && (
          <div className="mt-4">
            <CheckoutCard
              itemType="Course"
              cartData={{
                item_id: '' + course.id,
                item_table: 'academy_courses',
                item_object: {
                  name: course.title,
                  image: course.thumbnail,
                  price: course.Price ? +course.Price.pricing.IDR : 0,
                  weight: 0,
                },
                item_vendor: course.CreatorUser?.name,
                item_vendor_id: course.CreatorUser?.id,
                item_vendor_table: 'global_users',
                item_quantity: 1,
              }}
              onFreeAcquisition={handleFreeAcquisition}
              isPreview={props.isPreview}
            />
          </div>
        )}
      </div>

      <div className="lg:order-1">
        <Typography variant="h6" className="font-bold">
          {course.title}
        </Typography>
        <Typography variant="subtitle2" className="my-2">
          {course.subtitle}
        </Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Oleh {course.CreatorUser.name}
        </Typography>
        <div
          className={classNames(
            'flex flex-row mb-2 w-96',
            classes.ratingWrapper
          )}
        >
          {reviewData && (
            <Rating
              averageRating={course.averageRating}
              totalEnrollment={reviewData.total}
              noExtraPadding
              showTextEnrollment
            />
          )}
          <div className="flex flex-row ml-6 mt-7">
            <Typography variant="caption" className="font-extrabold">
              {course.countEnrollment}
            </Typography>

            <Typography
              variant="caption"
              className="ml-1 font-thin"
              color="textSecondary"
            >
              Siswa Terdaftar
            </Typography>
          </div>
        </div>
        {!props.isPreview && (
          <div className="flex flex-row">
            {!course.Enrollment && <WishlistControl courseId={course.id} />}
            <div
              className={`flex flex-row cursor-pointer ${
                course.Enrollment ? 'ml-0' : 'ml-5'
              }`}
              onClick={() =>
                setShareThisCourse({...shareThisCourse, isOpen: true})
              }
            >
              <Share color="primary" />
              <Typography variant="subtitle2" color="primary" className="ml-3">
                Bagikan
              </Typography>
            </div>
          </div>
        )}

        <Tabs
          className="mt-6"
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="secondary"
          TabIndicatorProps={{
            style: {
              height: 5,
            },
          }}
        >
          <Tab label="Tentang" />
          <Tab label="Ulasan" />
          <Tab label="Komentar" />
        </Tabs>

        <div>
          {value === 0 ? (
            <TabAbout {...course} />
          ) : value === 1 ? (
            <TabReview
              course={course}
              averageRating={course.averageRating}
              reviewData={reviewData}
              reviewRefetch={reviewRefetch}
              courseRefetch={refetch}
            />
          ) : (
            <TabComment {...course} isPreview={props.isPreview} />
          )}
        </div>
      </div>

      {shareThisCourse?.isOpen && (
        <ShareCatalogItemDialog
          shareData={shareThisCourse}
          setShareData={setShareThisCourse}
          shareableUrl={shareableCourseURL}
          title={course?.title || ''}
        />
      )}

      <ConfirmationModal
        open={isAcquiredOpen}
        title="Kursus berhasil diambil"
        message="Produk ini telah ditambahkan ke Pembelajaran Saya."
        cancellation="Kembali"
        confirmation="Pergi ke Pembelajaran Saya"
        onClose={() => setIsAcquiredOpen(false)}
        onConfirm={() => navigate(`/learner/my-learning/courses`)}
      />
    </div>
  )
}

export default CourseDetailTab
