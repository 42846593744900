import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'react-final-form'
import { useApolloClient, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import { type FormApi } from 'final-form'
import axios, { type AxiosRequestConfig } from 'axios'

import { Button, Divider, IconButton, Paper, Typography } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'
import FormLoading from '../../../../../components/input-forms/FormLoading'
import FormText from '../../../../../components/input-forms/FormText'
import FormSelect, {
  SelectItem,
} from '../../../../../components/input-forms/FormSelect'
import {
  combineValidators,
  isBlobSizeCorrect,
  isRequired,
} from '../../../../../components/input-forms/validators'

import AcademyCategoryFields from '../../../../../components/contributor/forms/AcademyCategoryFields'
import PriceTierField from '../../../../../components/contributor/forms/PriceTierField'
import ImageThumbnailField, {
  type ImageThumbnail,
} from '../../../../../components/contributor/forms/ImageThumbnailField'
import JWVideoField, {
  type JWVideo,
} from '../../../../../components/contributor/forms/JWVideoField'
import { validateCourseTitle } from '../../ContributorCourseCreatePage'

import { languageOptionsGQL, levelOptions } from '../../../../../utils/constans'
import { UPLOAD_URL, USER_ID } from '../../../../../utils/globals'
import { GET_CONTRIBUTOR_COURSE_LANDING_DETAILS } from '../../../../../graphql/queries'
import { UPDATE_COURSE_LANDING_DETAILS } from '../../../../../graphql/mutations'
import { INSTRUCTOR_CONTEXT } from '../../../../../utils/contributor-helpers'

interface FormData {
  id: number
  title: string
  description: string
  language: string
  level: number
  category: number
  subcategory: number
  price_tier: number
  thumbnail: ImageThumbnail
  video: JWVideo
}

const validateCourseThumbnail = combineValidators(
  isRequired,
  isBlobSizeCorrect(25)
)

const LANGUAGES: SelectItem[] = [
  {
    value: '',
    label: 'Select language',
    disabled: true,
  },
  ...languageOptionsGQL,
]

const LEVELS: SelectItem[] = [
  {
    value: '',
    label: 'Select level',
    disabled: true,
  },
  ...levelOptions,
]

const ContributorCourseManageLandingPage = () => {
  const { courseSlug } = useParams()

  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()

  const [submitProgress, setSubmitProgress] = React.useState<number>()

  const { data, loading } = useQuery(GET_CONTRIBUTOR_COURSE_LANDING_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      slug: courseSlug,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const initialValues = React.useMemo(() => {
    if (!data || data.academy_courses.length < 1) {
      return
    }

    const course = data.academy_courses[0]

    const formData: FormData = {
      id: course.id,
      title: course.title,
      description: course.description || undefined,
      language: (course.language !== '0' && course.language) || undefined,
      level: course.level || undefined,
      category: course.academy_course_category.academy_course_category?.id,
      subcategory: course.academy_course_category?.id,
      price_tier: course.academy_price_tier?.id,
      thumbnail: course.thumbnail || undefined,
      video: course.video || undefined,
    }

    return formData
  }, [data])

  const handleSubmit = async (data: FormData, form: FormApi<any, any>) => {
    // NOTE(intrnl): this keeps track of how the progression should be divided,
    // if we need to upload both thumbnail and video, then the progress should
    // be 50% thumbnail and 50% video.
    let count = 0
    let current = 0

    let thumbnail = data.thumbnail
    let video = data.video

    const axiosConfig: AxiosRequestConfig<unknown> = {
      withCredentials: true,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (event) => {
        const percentage = Math.round((event.loaded * 100) / event.total)
        const offset = (100 / count) * current

        setSubmitProgress(offset + percentage / count)
      },
    }

    // NOTE(intrnl): set the `count` which is the total
    if (thumbnail instanceof Blob) {
      count++
    }
    if (video instanceof File) {
      count++
    }

    if (count > 0) {
      setSubmitProgress(0)
    }

    // NOTE(intrnl): now start uploading
    if (thumbnail instanceof Blob) {
      const thumbForm = new FormData()
      thumbForm.append('file', thumbnail)

      try {
        const response = await axios.post(UPLOAD_URL!, thumbForm, axiosConfig)

        const url = response.data.url
        form.change('thumbnail', (thumbnail = url))
      } catch {
        setSubmitProgress(undefined)
        enqueueSnackbar(`Failed to upload course thumbnail, try again later`, {
          variant: 'error',
        })

        return
      }

      current++
    }

    if (video instanceof File) {
      try {
        const response = await axios.post(
          UPLOAD_URL + '/video',
          {
            title: `${courseSlug!} Promotional Course Video`,
            description: data.description,
            format: video.type,
          },
          {
            headers: {
              Authorization: axiosConfig.headers!.Authorization,
            },
            withCredentials: true
          }
        )

        const handshake = response.data
        const url = `${handshake.link.protocol}://${handshake.link.address}${handshake.link.path}`

        await axios.put(url, video, {
          ...axiosConfig,
          params: {
            AWSAccessKeyId: handshake.link.query.AWSAccessKeyId,
            Expires: handshake.link.query.Expires,
            Signature: handshake.link.query.Signature,
          },
          headers: {
            'Content-Type': video.type,
          },
        })

        const key = handshake.media.key
        form.change('video', (video = key))
      } catch (error) {
        console.error(error)

        setSubmitProgress(undefined)
        enqueueSnackbar(
          `Failed to upload course promotional video, try again later`,
          {
            variant: 'error',
          }
        )

        return
      }

      current++
    }

    // NOTE(intrnl): we're done here, so let's set it back to indeterminate
    if (count > 0) {
      setSubmitProgress(undefined)
    }

    const promise = client.mutate({
      mutation: UPDATE_COURSE_LANDING_DETAILS,
      variables: {
        courseId: data.id,
        objects: {
          title: data.title,
          description: data.description,
          language: '' + data.language,
          level: data.level,
          category: data.subcategory,
          price_tier: data.price_tier,
          thumbnail: thumbnail,
          video: video,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    return promise.catch(() => {
      enqueueSnackbar(`Failed to update course landing`, { variant: 'error' })
    })
  }

  React.useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'auto' })
  }, [])

  return (
    <Form onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, pristine, submitting }) => (
        <Paper component="form" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 px-6 py-1">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              Course Landing Page
            </Typography>

            <Button
              disabled={pristine || submitting}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col gap-4 p-6">
            {(submitting || loading) && (
              <FormLoading progress={submitProgress} />
            )}

            <FormText
              name="title"
              validate={validateCourseTitle}
              label="Title*"
              fullWidth
              showCharLimit={150}
            />

            <FormText
              name="description"
              validate={isRequired}
              label="Description*"
              fullWidth
              multiline
              minRows={3}
            />

            <FormSelect
              name="language"
              validate={isRequired}
              label="Language*"
              options={LANGUAGES}
              className="sm:max-w-xs"
            />

            <FormSelect
              name="level"
              validate={isRequired}
              parse={Number}
              label="Level*"
              options={LEVELS}
              className="sm:max-w-xs"
            />

            <div className="flex flex-col gap-4 sm:max-w-xs">
              <AcademyCategoryFields showMark />
            </div>

            <PriceTierField
              name="price_tier"
              validate={isRequired}
              parse={Number}
              label="Price*"
              className="sm:max-w-xs"
            />

            <div className="grid gap-4 xl:grid-cols-2 xl:gap-8">
              <ImageThumbnailField
                name="thumbnail"
                label="Course Image*"
                buttonLabel="Add course image"
                description={
                  <>
                    Resolution Recommendation: 750 x 422 pixels
                    <br />
                    Type File: jpg, jpeg, png
                    <br />
                    Max Size: 25 MB
                  </>
                }
                validate={validateCourseThumbnail}
              />

              <JWVideoField
                name="video"
                slug={courseSlug!}
                label="Promotional Video (Optional)"
                buttonLabel="Add promotional video"
                description={
                  <>
                    Min Resolution Recommendation: 1280 x 720 (HD, 720p)
                    <br />
                    Type File: mp4
                    <br />
                    Max Size: 2 GB
                  </>
                }
              />
            </div>
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorCourseManageLandingPage
