import React from 'react'
import {LearnerSidebar, LearnerCatalog} from '../../components/learner'
import {LearnerAcademicProvider} from '../../provider/Academic.provider'

export default function Catalog() {
  return (
    <LearnerSidebar>
      <LearnerAcademicProvider>
        <LearnerCatalog />
      </LearnerAcademicProvider>
    </LearnerSidebar>
  )
}
