import {ChangeEvent, useState, useEffect} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {TextField, InputAdornment, Tab} from '@material-ui/core'
import {LearnerSidebar} from '../../../components/learner'
import {StyledTabsWhite} from '../../../components/utils-components/GlobalStyles'
import makeStyles from '@material-ui/core/styles/makeStyles'

import SearchIcon from '@material-ui/icons/Search'

import CourseWishlistComponent from './tabs/Course.component'
import BookWishlistComponent from './tabs/Book.component'
import EventWishlistComponent from './tabs/Event.component'
import MicroLearningWishlistComponent from './tabs/MicroLearning.component'

const useStyles = makeStyles({
  customTextField: {
    '& input::placeholder': {
      fontSize: '14px',
      fontWeight: 'bold',
      color: '#014a62',
      opacity: 1,
      paddingLeft: '3px',
    },
  },
  customTabs: {
    '&$selected': {
      color: 'red',
    },
  },
})

const routes = ['courses', 'books', 'events', 'micro-learnings']

const WishListPage = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const {active} = useParams()
  const [tabValue, setTabValue] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    navigate(routes[newValue])
  }

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  useEffect(() => {
    if (active) {
      const index = routes.indexOf(active)
      setTabValue(index)
    } else {
      setTabValue(0)
    }
  }, [active])

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return <CourseWishlistComponent searchValue={searchValue} />
      case 1:
        return <BookWishlistComponent searchValue={searchValue} />
      case 2:
        return <EventWishlistComponent searchValue={searchValue} />
      case 3:
        return <MicroLearningWishlistComponent searchValue={searchValue} />

      default:
        return <CourseWishlistComponent searchValue={searchValue} />
    }
  }

  return (
    <LearnerSidebar>
      <div className="px-5">
        <TextField
          variant="outlined"
          placeholder="Cari Daftar Keinginan"
          fullWidth
          value={searchValue}
          onChange={(e: any) => onSearchChange(e)}
          classes={{root: classes.customTextField}}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon style={{color: '#A9A8A8'}} />
              </InputAdornment>
            ),
          }}
        />
        <StyledTabsWhite
          value={tabValue}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleChange}
          className="mt-10"
        >
          <Tab label="Pembelajaran" />
          <Tab label="Buku" />
          <Tab label="Acara" />
          <Tab label="Pembelajaran Mikro" />
        </StyledTabsWhite>
        <div className="py-10">{renderContent()}</div>
      </div>
    </LearnerSidebar>
  )
}

export default WishListPage
