import React, { type ChangeEvent, type ReactNode } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { useTitle } from 'hoofd'

import clsx from 'clsx'
import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import Skeleton from 'react-loading-skeleton'

import { Filter } from '@smartasn/wlb-utils-components'
import { format as formatDate, formatISO } from 'date-fns'

import { convertToRupiah } from '../../../utils/helpers'
import { USER_ID } from '../../../utils/globals'
import { GET_ORDERS } from '../../../graphql/queries'
import { listFilterContributorOrders } from '../../../config/filter/DefaultFilter.config'

interface GlobalInvoice {
  __typename: 'global_invoices'
  id: string
  code: string
  status:
  | 'pending'
  | 'paid'
  | 'shipping'
  | 'completed'
  | 'rejected'
  | 'refund_pending'
  | 'refund_paid'
  date_added: string
  invoice_to: string
  final_price: number
}

interface OrderItemProps {
  data: GlobalInvoice
  onClick?: () => void
}

interface OrderStatusIconProps {
  title: string
  active?: boolean
  children?: ReactNode | undefined
}

const enum TransactSortOrder {
  ID_DESC = 'desc',
  ID_ASC = 'asc',
}

const OrderStatusIcon = (props: OrderStatusIconProps) => {
  const { active, title, children } = props

  const cn = clsx([
    'group',
    'relative',
    'flex justify-center items-center',
    'rounded p-1',
    'text-base text-white',
    active ? 'bg-[#039be5]' : 'bg-[#8d8c8c]',
  ])

  return (
    <Tooltip title={title} placement="top" classes={{ tooltip: 'text-sm' }}>
      <div className={cn}>{children}</div>
    </Tooltip>
  )
}

const OrderItem = (props: OrderItemProps) => {
  const { data, onClick } = props

  const formattedDate = React.useMemo(() => {
    const date = new Date(data.date_added)
    return formatDate(date, 'MMM d, yyyy')
  }, [data.date_added])

  const formattedNominal = React.useMemo(() => {
    return convertToRupiah(data.final_price)
  }, [data.final_price])

  const isCompleted = data.status === 'completed'
  const isRejected = data.status === 'rejected'

  return (
    <TableRow
      tabIndex={0}
      onClick={onClick}
      onKeyUp={(ev) => onClick && ev.key === 'Enter' && onClick()}
      className="cursor-pointer transition hover:bg-[#f5f5f5] focus:bg-[#f5f5f5]"
    >
      <TableCell>{data.code}</TableCell>
      <TableCell>{formattedNominal}</TableCell>
      <TableCell className="overflow-hidden text-ellipsis whitespace-nowrap">
        {data.invoice_to}
      </TableCell>
      <TableCell>{formattedDate}</TableCell>

      <TableCell>
        <div className="flex gap-2">
          <OrderStatusIcon title="Completed" active={isCompleted}>
            <CheckIcon fontSize="inherit" />
          </OrderStatusIcon>

          <OrderStatusIcon title="Rejected" active={isRejected}>
            <NotInterestedIcon fontSize="inherit" />
          </OrderStatusIcon>
        </div>
      </TableCell>
    </TableRow>
  )
}

const ContributorOrdersPage = () => {
  useTitle('Contributor - Orders')

  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const searchTimeoutRef = React.useRef<any>()

  const search = searchParams.get('query') ?? ''
  const orderBy = searchParams.get('order') ?? TransactSortOrder.ID_DESC
  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const statuses = searchParams.getAll('status')
  const priceFrom = searchParams.get('priceFrom') || null
  const priceTo = searchParams.get('priceTo') || null
  const dateFrom = searchParams.get('dateFrom') || null
  const dateTo = searchParams.get('dateTo') || null

  const { data, error } = useQuery(GET_ORDERS, {
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'X-Hasura-Role': 'instructor',
      },
    },
    variables: {
      userId: USER_ID,
      search: `%${search}%`,
      orderBy: orderBy,
      offset: page * limit,
      limit: limit,
      statuses: statuses.length > 0 ? statuses : null,
      priceFrom: priceFrom,
      priceTo: priceTo,
      dateFrom: dateFrom,
      dateTo: dateTo,
    },
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const setParams = (next: URLSearchParams) => {
    const root = document.getElementById('main-content')!

    clearTimeout(searchTimeoutRef.current)
    setSearchParams(next, { replace: true })

    setTimeout(() => root.scrollIntoView({ block: 'start', behavior: 'smooth' }))
  }

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const handleOrderByChange = (
    ev: ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('order', ev.target.value as string)

    setParams(next)
  }

  const handlePageChange = (event: unknown, newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setParams(next)
  }

  const handleRowsPerPageChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', ev.target.value)

    setParams(next)
  }

  const handleFilterApply = (res: any) => {
    const next = new URLSearchParams(searchParams)
    const include = res[0]

    next.delete('page')
    next.delete('status')
    next.delete('priceFrom')
    next.delete('priceTo')
    next.delete('dateFrom')
    next.delete('dateTo')

    if (include.status) {
      for (const option of include.status) {
        next.append('status', option.value)
      }
    }

    if (include.price) {
      if (include.price.min !== undefined) {
        next.set('priceFrom', include.price.min)
      }

      if (include.price.max !== undefined) {
        next.set('priceTo', include.price.max)
      }
    }

    if (include.date) {
      if (include.date.from !== undefined) {
        next.set('dateFrom', formatISO(include.date.from))
      }

      if (include.date.to !== undefined) {
        next.set('dateTo', formatISO(include.date.to))
      }
    }

    clearTimeout(searchTimeoutRef.current)
    setSearchParams(next, { replace: true })
  }

  return (
    <Paper id="main-content" className="scroll-m-32">
      <Typography
        color="primary"
        className="px-6 py-4 text-base font-extrabold"
      >
        Daftar Pesanan
      </Typography>

      <Divider />

      <div className="px-6 pt-6 pb-4">
        <TextField
          key={search}
          placeholder="Cari..."
          variant="outlined"
          fullWidth
          defaultValue={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="flex items-center justify-between px-2 pb-4">
        <Select
          value={orderBy}
          onChange={handleOrderByChange}
          variant="standard"
          disableUnderline={true}
          SelectDisplayProps={{
            className:
              'text-sm pl-4 pr-6 py-2 focus:bg-[#f5f5f5] outline-none rounded transition',
          }}
        >
          <MenuItem value={TransactSortOrder.ID_DESC}>Terbaru</MenuItem>
          <MenuItem value={TransactSortOrder.ID_ASC}>Terlama</MenuItem>
        </Select>

        <Filter
          includeExclude={false}
          listFilter={listFilterContributorOrders}
          onApply={handleFilterApply}
        >
          <Button className="px-4">Saring</Button>
        </Filter>
      </div>

      <TableContainer className="overflow-x-visible">
        <Table className="table-fixed">
          <TableHead>
            <TableRow>
              <TableCell>Pesanan</TableCell>
              <TableCell>Harga</TableCell>
              <TableCell style={{ width: '40%' }}>Pelanggan</TableCell>
              <TableCell>Tanggal</TableCell>
              <TableCell style={{ width: 160 }}>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {error ? (
              <TableRow>
                <TableCell colSpan={5}>
                  Terjadi kesalahan saat mencoba mengambil daftar pesanan
                </TableCell>
              </TableRow>
            ) : data ? (
              data.global_invoices.length > 0 ? (
                data.global_invoices.map((row: GlobalInvoice) => (
                  <OrderItem
                    key={row.id}
                    data={row}
                    onClick={() => {
                      const root = document.getElementById('main-content')!
                      root.scrollIntoView({ block: 'start', behavior: 'smooth' })

                      navigate(`${row.id}`)
                    }}
                  />
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    Belum ada pesanan
                  </TableCell>
                </TableRow>
              )
            ) : (
              Array.from({ length: limit }, (_, idx) => (
                <TableRow key={idx}>
                  {Array.from({ length: 5 }, (_, idx) => (
                    <TableCell key={idx}>
                      <Skeleton />
                    </TableCell>
                  ))}
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data ? data.global_invoices_aggregate.aggregate.count : 0}
          rowsPerPage={limit}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          labelRowsPerPage="Baris per halaman"
          backIconButtonText="Halaman Sebelumnya"
          nextIconButtonText="Halaman Selanjutnya"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} dari ${count}`
          }
        />
      </TableContainer>
    </Paper>
  )
}

export default ContributorOrdersPage
