import React from 'react'
import Header from '../header/HeaderComponent'
import {
  TabsWrapper,
  StyledTab,
  StyledTabs,
} from '../utils-components/GlobalStyles'
import {useTitle} from 'hoofd'
import styled from 'styled-components'
import {LeftSideWrapper, MainWrapper} from '../job-portal/JobPortalStyles'
import UserLoggedIn from '../user-logged-in/UserLoggedInComponent'

type LearnerSidebarProps = {
  children?: React.ReactNode
  profile?: boolean
}

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const AppWrapper = styled.div`
  width: 100%;
`

const LearnerSidebar = (props: LearnerSidebarProps) => {
  useTitle('Home')
  // const [, setTypeFilter] = React.useState([])
  // const [, setCategoryFilter] = React.useState([])
  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab
            label={`${
              props.profile ? 'Profil Pembelajaran' : 'Katalog Pembelajaran'
            }`}
            value="learning-catalog"
          />
        </StyledTabs>
      </TabsWrapper>
      <MainWrapper>
        <LeftSideWrapper>
          <UserLoggedIn
          // setTypeFilter={setTypeFilter}
          // setCategoryFilter={setCategoryFilter}
          />
        </LeftSideWrapper>
        <AppWrapper>{props.children}</AppWrapper>
      </MainWrapper>
    </>
  )
}

export default LearnerSidebar
