import React from 'react'
import {ArrowBack, Print, Share} from '@material-ui/icons'
import {Avatar, Button} from '@material-ui/core'
import {Link, useParams} from 'react-router-dom'
import {useTitle} from 'hoofd'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'

import {
  CustomTypography,
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../../components/utils-components/GlobalStyles'
import {LearnerCourseProvider} from '../../../provider/LearnerCourse.provider'
import Header from '../../../components/header/HeaderComponent'
import LearnerCatalogCard from '../../../components/learner/catalog/LearnerCatalogCard.component'
import ShareCatalogItemDialog from '../../../components/learner/catalog/dialog/ShareCatalogItemDialog.components'
import {LearningType} from '../../../utils/enum'
import {LANDING_APP_URL, userData} from '../../../utils/globals'
import {
  COURSE_BY_SLUG,
  MY_CERTICIFATE_DETAIL_BY_ID,
} from '../../../provider/services/Course.service'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const LeftSide = styled.div`
  width: 75%;
  position: relative;
  @media (max-width: 835px) {
    width: 100%;
  }
`
const RightSide = styled.div`
  width: 25%;
  @media (max-width: 835px) {
    width: 100%;
  }
`

const TextOverImageContainer = ({
  text,
  pt,
}: {
  text: string
  pt?: string
}): React.ReactElement => {
  return (
    <div
      className={`w-full absolute top-0 left-0 z-10 text-center text-sm lg:text-3xl font-bold px-14`}
      style={{paddingTop: pt}}
    >
      {text}
    </div>
  )
}

const DetailCertificate = () => {
  useTitle('Certificate')
  const {id} = useParams()
  const componentRef = React.useRef(null)
  const [shareData, setShareData] = React.useState({
    isOpen: false,
    copyStatus: false,
  })

  const {data, error} = MY_CERTICIFATE_DETAIL_BY_ID({
    id: Number(id),
  })
  const certificate_url = `${LANDING_APP_URL}/screen/d?s=cert_sasn&id=${data?.academy_certificates?.[0]?.certificate_url}`
  const courseSlug =
    data?.academy_certificates?.[0]?.academy_course_enrollment?.academy_course
      ?.slug

  const {data: courseData, error: courseError} = COURSE_BY_SLUG(courseSlug, {
    enabled: !!courseSlug,
  })

  if (error || courseError) {
    console.error(error)
    console.error(courseError)
  }

  return (
    <div className="bg-[#fafafa]">
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'detail-certificate'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Detail Certificate" value="detail-certificate" />
        </StyledTabs>
      </TabsWrapper>

      <div className="pt-5 px-5 lg:px-[67.5px] pb-24">
        <Button
          component={Link}
          to="/learner/my-certificate"
          color="primary"
          startIcon={<ArrowBack style={{fontSize: '16px'}} />}
          style={{fontSize: '16px'}}
        >
          Kembali ke Sertifikat Saya
        </Button>

        <div className="mt-8 flex flex-col lg:flex-row gap-8">
          <LeftSide ref={componentRef}>
            <img
              src="https://public-media.wlb.co.id/a4fa491b-4106-4f95-9534-05b36aaa0d01-1670559120853.png"
              alt="certificate"
            />

            <TextOverImageContainer
              text={(data?.academy_certificates?.[0]?.name_to || '')
                .split(' ')
                .slice(0, 4)
                .join(' ')}
              pt="31.5%"
            />

            <TextOverImageContainer
              text={data?.academy_certificates?.[0]?.course_name || ''}
              pt="45.2%"
            />

            <div
              id="instructor"
              className="absolute bottom-1 lg:bottom-16 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            >
              <p className="text-center text-[10px] lg:text-lg font-bold">
                {(data?.academy_certificates?.[0]?.instructors?.[0]?.name || '')
                  .split(' ')
                  .slice(0, 4)
                  .join(' ')}
              </p>
              <hr className="border-[0.1px] border-[#4B4B4B]" />
              <p className="text-center text-[8px] lg:text-sm mt-0 lg:mt-2">
                {(data?.academy_certificates?.[0]?.instructors?.[0]?.name || '')
                  .split(' ')
                  .slice(0, 4)
                  .join(' ')}
              </p>
              <p className="text-center text-[8px] lg:text-sm">instructor</p>
            </div>

            <div className="w-full absolute z-10 top-1 left-0 pt-[61.8%] pl-[14%]">
              <p className="text-[4px] lg:text-[9px] font-bold">
                {data?.academy_certificates?.[0]?.certificate_url || ''}
              </p>
              <p className="text-[4px] lg:text-[9px] font-bold">
                {certificate_url || ''}
              </p>
            </div>
          </LeftSide>

          <RightSide>
            <div className="flex gap-8">
              <ReactToPrint
                trigger={() => (
                  <Button
                    className="border-none"
                    style={{boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}
                    variant="outlined"
                    startIcon={<Print />}
                  >
                    Cetak
                  </Button>
                )}
                content={() => componentRef.current}
              />

              <Button
                className="border-none"
                style={{boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}
                variant="outlined"
                startIcon={<Share />}
                onClick={() => setShareData({...shareData, isOpen: true})}
              >
                Bagikan
              </Button>
            </div>

            <CustomTypography mt="33px" fsize="16px" fweight="bold">
              Penerima Sertifikat
            </CustomTypography>
            <div
              className="flex p-4 gap-5 mt-6"
              style={{boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)'}}
            >
              <Avatar src={userData?.avatar} alt="avatar" />
              <div>
                <CustomTypography fsize="16px" fweight="bold">
                  {userData?.name}
                </CustomTypography>
                <CustomTypography>{userData?.position_name}</CustomTypography>
              </div>
            </div>

            <CustomTypography mt="33px" fsize="16px" fweight="bold">
              Tentang Kursus
            </CustomTypography>
            {courseData && (
              <LearnerCourseProvider>
                <LearnerCatalogCard
                  type={LearningType.COURSE}
                  item={courseData}
                  key={1}
                  hidePrice={false}
                  width="w-full"
                />
              </LearnerCourseProvider>
            )}
          </RightSide>
        </div>
      </div>

      <ShareCatalogItemDialog
        shareData={shareData}
        setShareData={setShareData}
        shareableUrl={certificate_url}
        title="Certificate"
        featureName="Certificate"
      />
    </div>
  )
}

export default DetailCertificate
