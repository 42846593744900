import React from 'react'

import {
  MY_MICROLEARNING,
  RECOMMENDED_MICROLEARNING,
  TRENDING_MICROLEARNING,
  ALL_MICROLEARNING,
  // RECOMMENDED_CATEGORY_MICROLEARNING,
} from './services/MicroLearning.service'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  initialValue,
} from './reducers/Learner.reducer'

import {LearnerAcademicContext} from './Academic.provider'

import {LearnerMicroLearningContextType, IMicroLearning} from '../interfaces'
// import {USER_ID} from '../utils/globals'

type LearnerMicroLearningProviderProps = {
  children: React.ReactNode
}

export const LearnerMicroLearningContext =
  React.createContext<LearnerMicroLearningContextType>(initialValue)

export const LearnerMicroLearningProvider = (
  props: LearnerMicroLearningProviderProps
) => {
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)
  const [allMicroLearningData, setAllMicroLearning] = React.useState<any[]>([])

  const {data: myMicroLearning, loading: loadingMyMicroLearning} =
    MY_MICROLEARNING(
      state.microlearning.limitMicroLearning,
      state.microlearning.offsetMyMicroLearning
    )

  const {
    data: recommendMicroLearning,
    loading: loadingRecommendedMicroLearning,
  } = RECOMMENDED_MICROLEARNING(
    state.microlearning.limitMicroLearning,
    state.microlearning.offsetRecommendedMicroLearning
  )

  const {data: trendingMicroLearning, loading: loadingTrendingMicroLearning} =
    TRENDING_MICROLEARNING(
      state.microlearning.limitMicroLearning,
      state.microlearning.offsetTrendingMicroLearning
    )

  const {
    data: allMicroLearning,
    loading: loadingAllMicroLearning,
    refetch: refetchAllMicroLearning,
  } = ALL_MICROLEARNING(
    state.microlearning.limitAllMicroLearning,
    state.microlearning.offsetAllMicroLearning,
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.priceGQL._lte,
    learnerAcademicContext.reducerState.academicFilter.priceGQL._gte,
    learnerAcademicContext.reducerState.academicFilter.categoriesGQL,
    [],
    [],
    learnerAcademicContext.reducerState.academicFilter.ratingGQL[0]
      ?.average_rating?._gte
  )

  // const {
  //   data: recommendCategoryMicroLearning,
  //   loading: loadingRecommendCategoryMicroLearning,
  // } = RECOMMENDED_CATEGORY_MICROLEARNING(USER_ID)

  React.useEffect(() => {
    if (allMicroLearning) {
      const _newData = allMicroLearningData
      allMicroLearning.microlearnings.forEach((item: IMicroLearning) => {
        _newData.push(item)
      })
      setAllMicroLearning(_newData)
    }
  }, [allMicroLearning, allMicroLearningData])

  React.useEffect(() => {
    if (refetchAllMicroLearning) refetchAllMicroLearning()
  }, [
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.categoriesGQL,
    learnerAcademicContext.reducerState.academicFilter.priceGQL,
    learnerAcademicContext.reducerState.academicFilter.ratingGQL,
    learnerAcademicContext.reducerState.academicFilter.rating,
    refetchAllMicroLearning,
  ])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  const microLearningReducer = {
    myMicroLearning: {
      data: myMicroLearning?.microlearnings || [],
      total: myMicroLearning?.microlearnings_aggregate?.aggregate?.count || 0,
      loading: loadingMyMicroLearning,
    },
    trendingMicroLearning: {
      data: trendingMicroLearning?.getListMicrolearning?.microlearnings || [],
      total: trendingMicroLearning?.getListMicrolearning?.count || 0,
      loading: loadingTrendingMicroLearning,
    },
    recommendedMicroLearning: {
      data: recommendMicroLearning?.getListMicrolearning?.microlearnings || [],
      total: recommendMicroLearning?.getListMicrolearning?.count || 0,
      loading: loadingRecommendedMicroLearning,
    },
    allMicroLearning: {
      data: allMicroLearningData || [],
      total: allMicroLearning?.microlearnings_aggregate?.aggregate?.count || 0,
      loading: loadingAllMicroLearning,
    },
    category: {
      data:
        // recommendCategoryMicroLearning?.academy_course_categories ||
        [],
      loading: false,
      // loadingRecommendCategoryMicroLearning,
    },
  }

  return (
    <LearnerMicroLearningContext.Provider
      value={{
        changePageLimit: changePageLimitHandler,
        microlearning: microLearningReducer,
        reducerState: state,
      }}
    >
      {props.children}
    </LearnerMicroLearningContext.Provider>
  )
}
