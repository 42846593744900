import {Field} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {Droppable} from 'react-beautiful-dnd'

import {Divider} from '@material-ui/core'
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm'

import {type LessonViewProps} from './_Lesson'
import QuestionSubview from '../components/QuestionSubview'

import {formatDuration, parseDuration} from '../fields/DurationField'
import {EMPTY_ARRAY} from '../_utils'

const QuizLessonView = (props: LessonViewProps) => {
  const {name} = props

  return (
    <div className="p-6 pl-18 text-sm">
      <div className="mb-8">
        <h3 className="font-bold mb-2">Question Duration</h3>

        <div className="flex gap-2 items-center">
          <AccessAlarmIcon color="primary" />

          <Field name={`${name}.duration`} validateFields={[]}>
            {({input: {value}}) => (
              // TODO(intrnl): consider removing after 2025-02-07, this was
              // done to consistently display durations on older data
              <span>{formatDuration(parseDuration(value))}</span>
            )}
          </Field>
        </div>
      </div>

      <div>
        <h3 className="font-bold mb-4">Question List</h3>
        <Divider />

        <FieldArray
          name={`${name}.academy_course_questions`}
          validateFields={[]}
        >
          {({fields}) => (
            <Droppable
              droppableId={`${name}.academy_course_questions`}
              type="quiz-questions"
            >
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  {(fields.value || EMPTY_ARRAY).map((item, index) => {
                    const id = '' + item.id

                    return (
                      <QuestionSubview
                        key={id}
                        id={id}
                        name={`${name}.academy_course_questions[${index}]`}
                        index={index}
                      />
                    )
                  })}

                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          )}
        </FieldArray>
      </div>
    </div>
  )
}

export default QuizLessonView
