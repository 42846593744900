import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'
import { useTitle } from 'hoofd'

import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Paper,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import { AttachmentCard } from '@smartasn/wlb-utils-components'

import { GreyTypography } from '../../../components/utils-components/GlobalStyles'
import ConfirmationModal from '../../../components/modal/ConfirmationModal'

import { format } from 'date-fns'
import { convertToRupiah } from '../../../utils/helpers'
import { CHAT_URL, MATRIX_URL } from '../../../utils/globals'
import { GET_ORDER_DETAILS } from '../../../graphql/queries'
import { UPDATE_ORDER } from '../../../graphql/mutations'

interface GlobalDirectTransfer {
  __typename: 'global_direct_transfer_proofs'
  id: string
  name: string
  type: string
  file: string
  date_added: string
}

interface GlobalInvoices {
  __typename: 'global_invoice_items'
  type: string
  id: number
  item_table: string
  item_table_id: string
  itemname: string
  qty: number
  single_price: number
  thumbnail: string
  weight: number
}
interface OrderDetailsData {
  global_invoices_by_pk: {
    __typename: 'global_invoices'
    id: string
    code: string
    status:
    | 'pending'
    | 'paid'
    | 'shipping'
    | 'completed'
    | 'rejected'
    | 'refund_pending'
    | 'refund_paid'
    invoice_to: string
    global_wallets: {
      __typename: 'global_wallet_accounts'
      global_users: Array<{
        id: string
        name: string
      }>
    }
    global_payment: {
      __typename: 'global_payments'
      global_direct_transfer: {
        __typename: 'global_direct_transfers'
        id: string
        global_direct_transfer_proofs: Array<GlobalDirectTransfer>
      }
    }
    global_invoice_items: Array<GlobalInvoices>
  }
}

const retrieveChatUrl = (data: OrderDetailsData['global_invoices_by_pk']) => {
  if (!data.global_wallets || data.global_wallets.global_users.length < 1) {
    return '#'
  }

  const customerId = data.global_wallets.global_users[0].id
  return `${CHAT_URL}/?chat=@${customerId}:${MATRIX_URL}#/`
}

const queryContext = {
  headers: {
    'X-Hasura-Role': 'instructor',
  },
} as const

const ContributorOrderDetailPage = (): React.ReactElement => {
  const params = useParams()
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const [openDelete, setOpenDelete] = React.useState(false)

  const [mutateStatus, { loading: dispatching }] = useMutation(UPDATE_ORDER, {
    context: queryContext,
  })

  const { data, error } = useQuery(GET_ORDER_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      invoiceId: params.invoiceId,
    },
    context: queryContext,
  })

  const invoice = data?.global_invoices_by_pk

  useTitle(`Contributor - Order ${invoice ? invoice.code : 'Detail'}`)

  const status = invoice?.status
  const isPending = status === 'pending'
  const isPaid = status === 'paid'
  const isCompleted = status === 'completed'
  const isRejected = status === 'rejected'

  const advanceStatus = (status: string) => {
    const invoiceId = invoice!.id

    const promise = mutateStatus({
      variables: {
        invoiceId: invoiceId,
        status: status,
        paymentStatus: status === 'rejected' ? 'rejected' : 'paid',
      },
    })

    promise.catch(() => {
      enqueueSnackbar(`Gagal memperbarui status faktur`, { variant: 'error' })
    })
  }

  const renderDetailCard = () => {
    const transferInfo = invoice!.global_payment.global_direct_transfer
    const proofs = transferInfo?.global_direct_transfer_proofs

    if (proofs?.length > 0) {
      const dateAdded = proofs[0].date_added

      return (
        <div className="bg-[#f7f8f9] px-4 py-3 rounded">
          <Typography display="inline" color="primary" className="text-sm mr-4">
            {invoice!.invoice_to} membayar pembayaran
          </Typography>
          <Typography
            display="inline"
            color="textSecondary"
            className="text-sm"
          >
            {format(new Date(dateAdded), 'MMM d, yyyy')}
          </Typography>

          <div className="flex flex-wrap mt-3">
            {proofs.map((proof: GlobalDirectTransfer) => (
              <AttachmentCard
                key={proof.id}
                name={proof.name}
                url={proof.file}
              />
            ))}
          </div>
        </div>
      )
    }
  }

  const renderActions = () => {
    const transferInfo = invoice!.global_payment.global_direct_transfer
    const proofs = transferInfo?.global_direct_transfer_proofs

    const chatHref = retrieveChatUrl(invoice!)

    const chatButton = (
      <Button
        component="a"
        href={chatHref}
        disabled={chatHref === '#'}
        target="_blank"
        variant="outlined"
        color="primary"
      >
        Chat dengan pelanggan
      </Button>
    )

    if (isRejected) {
      return (
        <div className="px-6 pb-6 text-center text-[#ef4d5e]">
          <p className="mb-6">Pesanan telah ditolak</p>

          {chatButton}
        </div>
      )
    } else if (isPending || isPaid) {
      // Reject Payment:  pending -> rejected
      // Confirm Payment: pending -> completed

      return (
        <div className="px-6 pb-6 flex items-center justify-center gap-5">
          {chatButton}

          <Button
            disabled={dispatching}
            onClick={() => setOpenDelete(true)}
            variant="outlined"
            className="text-[#ef4d5e] border-[#ef4d5e]"
          >
            Tolak Pembayaran
          </Button>

          <Button
            disabled={dispatching || !proofs || proofs.length < 1}
            onClick={() => advanceStatus('completed')}
            variant="contained"
            color="primary"
            disableElevation
          >
            Konfirmasi Pembayaran
          </Button>
        </div>
      )
    } else if (isCompleted) {
      return (
        <div className="px-6 pb-6 flex items-center justify-center">
          {chatButton}
        </div>
      )
    }
  }

  return (
    <>
      <Paper>
        <div className="px-6 py-1 flex items-center">
          <IconButton onClick={() => navigate(-1)} edge="start">
            <ArrowBackIcon />
          </IconButton>

          <Typography color="primary" className="font-bold">
            Detail Pesanan
          </Typography>

          <div className="grow" />

          <Button
            component={Link}
            to="print"
            target="_blank"
            variant="contained"
            color="primary"
            disableElevation
          >
            Cetak nota
          </Button>
        </div>

        <Divider />

        {error ? (
          <div className="py-6 px-7">
            Terjadi kesalahan saat menampilkan detail pesanan
          </div>
        ) : !data ? (
          <div className="py-6 flex justify-center">
            <CircularProgress />
          </div>
        ) : (
          <div className="py-6 px-7">
            <Typography className="font-bold text-sm">Pelanggan</Typography>
            <GreyTypography className="text-sm mt-2">
              {invoice!.invoice_to}
            </GreyTypography>

            <Typography className="font-bold text-sm mt-6">
              Nomor pesanan
            </Typography>
            <GreyTypography className="text-sm mt-2">
              {invoice!.code}
            </GreyTypography>
          </div>
        )}
      </Paper>

      {data && (
        <Paper className="mt-6">
          <div className="px-6 py-4 flex justify-between">
            <Typography color="primary" className="font-bold">
              Item
            </Typography>
          </div>

          <Divider />

          <div className="py-2">
            {invoice!.global_invoice_items.map((item: GlobalInvoices) => (
              <div key={item.id} className="px-6 py-4 flex gap-6">
                <img
                  src={item.thumbnail}
                  alt={item.itemname}
                  className="h-[96px] w-[96px] rounded object-cover overflow-hidden shrink-0"
                />
                <div className="w-full relative">
                  <GreyTypography className="text-sm">
                    {item.itemname}
                  </GreyTypography>

                  <div className="flex justify-between mt-2">
                    <GreyTypography className="text-sm">
                      Total: {'' + item.qty}
                    </GreyTypography>
                    <GreyTypography className="text-sm">
                      {convertToRupiah(item.single_price)}
                    </GreyTypography>
                  </div>

                  <GreyTypography className="text-sm align-bottom absolute bottom-0 right-0 text-right">
                    Total Harga: {convertToRupiah(item.single_price * item.qty)}
                  </GreyTypography>
                </div>
              </div>
            ))}
          </div>
        </Paper>
      )}

      {data && (
        <Paper className="mt-6">
          <div className="px-6 py-4 flex justify-between">
            <Typography color="primary" className="font-bold">
              Tanggapan
            </Typography>
          </div>

          <Divider />

          <div className="px-6 py-6 mb-3 empty:py-1">{renderDetailCard()}</div>

          {renderActions()}
        </Paper>
      )}

      <ConfirmationModal
        open={openDelete}
        danger
        title="Tolak Pembayaran?"
        message="Apakah Anda yakin ingin menolak pembayaran ini? Pesanan akan dibatalkan setelah Anda menolak pembayaran ini."
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false)
          advanceStatus('rejected')
        }}
      />
    </>
  )
}

export default ContributorOrderDetailPage
