import gql from 'graphql-tag'

export const GET_MICRO_LEARNING_DETAIL = gql`
  query ($id: uuid!) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      name
      icon_url
      created_by_user_id
      average_rating
      review_count
      question: microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      global_language {
        id
        name
      }
      category_sub: academy_course_category {
        id
        name
        cat_parent: academy_course_category {
          id
          name
        }
      }
      level
      description
      price
      category_id
      status
      global_user {
        id
        name
      }
    }
  }
`

export const GET_MICRO_LEARNING_WISHLIST = gql`
  query ($microlearning: uuid) {
    microlearning_wishlists(
      where: {
        microlearning: {_eq: $microlearning}
        deleted_at: {_is_null: true}
      }
    ) {
      microlearningByMicrolearning {
        id
        name
        description
        price
        icon_url
        average_rating
        review_count
        question_count
        owner: global_user {
          id
          name
          avatar
        }
      }
    }
  }
`

export const GET_OWNED_MICRO_LEARNING = gql`
  query ($userId: uuid!, $id: uuid!) {
    microlearnings(
      where: {
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
        id: {_eq: $id}
      }
    ) {
      id
    }
  }
`

export const GET_RELATED_MICRO_LEARNING = gql`
  query ($category: Int, $limit: Int, $offset: Int) {
    microlearnings(
      where: {category_id: {_eq: $category}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
      icon_url
      created_by_user_id
      average_rating
      review_count
      microlearning_questions_aggregate {
        aggregate {
          count
        }
      }
      global_language {
        id
        name
      }
      academy_course_category {
        id
        name
      }
      level
      description
      price
      category_id
      global_user {
        id
        name
      }
    }
    microlearnings_aggregate(where: {category_id: {_eq: $category}}) {
      aggregate {
        count
      }
    }
  }
`

export const GET_REVIEW_MICRO_LEARNING = gql`
  query ($microlearningId: String!) {
    microlearningReviews(microlearningId: $microlearningId) {
      average_star
      review_count
      star_count {
        one
        two
        three
        four
        five
      }
    }
  }
`

export const GET_LIST_REVIEW_MICRO = gql`
  query ($microlearningId: uuid, $star: Int, $offset: Int, $limit: Int) {
    microlearning_reviews(
      where: {microlearning_id: {_eq: $microlearningId}, star: {_eq: $star}}
      offset: $offset
      limit: $limit
    ) {
      id
      star
      description
      created_at
      user: global_user {
        id
        name
        avatar
        contributor_label
        people_work_placements(
          where: {status: {_eq: "ACTIVE"}}
          limit: 1
          order_by: [{id: desc}]
        ) {
          id
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      reply_text
      reply_date
      reply_user
      position: globalUserReply {
        id
        name
        avatar
        people_work_placements(
          where: {status: {_eq: "ACTIVE"}}
          limit: 1
          order_by: [{id: desc}]
        ) {
          id
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
    }
  }
`

export const GET_MY_REVIEW_MICRO = gql`
  query getReviewByUserMicrolearning($microlearningId: uuid!, $userId: uuid!) {
    microlearning_reviews(
      where: {
        microlearning_id: {_eq: $microlearningId}
        member_user_id: {_eq: $userId}
      }
    ) {
      id
      star
      description
    }
  }
`

export const GET_ANALYTIC_MICRO = gql`
  query ($id: uuid!, $userId: uuid!) {
    microlearnings(where: {id: {_eq: $id}}) {
      id
      name
      icon_url
      question_count
      microlearning_members_aggregate {
        aggregate {
          count
        }
      }
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        progress
        question_generated_count
        global_user {
          id
          name
          email
          avatar
        }
        user_settings
        schedule
        schedule_time
        score
        is_finished
        daily_question
        expired_unanswered_question_count
        question_answered_count
      }
      question: microlearning_questions_aggregate(
        where: {
          microlearning_member_answers: {
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_QUESTION_REVIEW_MICRO = gql`
  query ($microlearningId: uuid, $userId: uuid) {
    people_work_placements(
      where: {status: {_eq: "ACTIVE"}, user: {_eq: $userId}}
      limit: 1
    ) {
      id
      global_user {
        id
        name
        avatar
      }
    }
    microlearnings(where: {id: {_eq: $microlearningId}}) {
      id
      name
      question_count
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        schedule
        schedule_time
        question_generated_count
        progress
        is_finished
        score
      }
      microlearning_questions(
        where: {
          microlearning_member_answers: {
            microlearning_member_daily: {
              microlearning_member: {user_id: {_eq: $userId}}
            }
          }
        }
      ) {
        id
        question
        question_type
        microlearning_question_answers {
          id
          answer
          is_correct
          microlearning_member_answer_options(
            where: {
              microlearning_member_answer: {
                microlearning_member_daily: {
                  microlearning_member: {user_id: {_eq: $userId}}
                }
              }
            }
          ) {
            id
            microlearning_member_answer {
              id
              is_answered
              is_scored
              score
            }
          }
        }
      }
      locked: microlearning_questions(
        where: {
          _not: {
            microlearning_member_answers: {
              microlearning_member_daily: {
                microlearning_member: {user_id: {_eq: $userId}}
              }
            }
          }
        }
      ) {
        id
        question
        question_type
        microlearning_question_answers {
          id
          answer
          is_correct
          microlearning_member_answer_options(
            where: {
              microlearning_member_answer: {
                microlearning_member_daily: {
                  microlearning_member: {user_id: {_eq: $userId}}
                }
              }
            }
          ) {
            id
            microlearning_member_answer {
              id
              is_answered
              is_scored
              score
            }
          }
        }
      }
    }
  }
`
