import {useMediaQuery} from '@material-ui/core'
import React from 'react'
import Skeleton from 'react-loading-skeleton'

const LoadingCardComponent = (props: {
  length: number
  isContributor?: Boolean
}): React.ReactElement => {
  const isMobileScreen = useMediaQuery(
    '(min-width: 320px) and (max-width: 767px)'
  )

  const isTabletScreen = useMediaQuery(
    '(min-width: 768px) and (max-width: 1200px)'
  )
  return (
    <div style={{width: '100%'}}>
      <Skeleton
        height={props.isContributor ? 257 : 300}
        className="mx-4 my-2"
        count={isMobileScreen ? 1 : isTabletScreen ? 2 : props.length}
        containerClassName="skeleton"
        style={{flexGrow: 1}}
      />
    </div>
  )
}

export default LoadingCardComponent
