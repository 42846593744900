import React from 'react'
import {Link} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import Dompurify from 'dompurify'

import {Button, Card, CardMedia, Typography} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import FavoriteIcon from '@material-ui/icons/Favorite'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'
import Skeleton from 'react-loading-skeleton'

import AddToCartButton from '../../../button/AddToCartButton.component'
import JWPlayer from '../../../../pages/shared-component/media/JWPlayer'

import {ICourse, PopperProps} from '../../../../interfaces'
import {defineAcademyLevel, defineLanguageCode} from '../../../../utils/helpers'
import {USER_ID} from '../../../../utils/globals'
import {GET_USER_CART_COUNT} from '../../../../graphql/queries'
import {
  ADD_TO_CART,
  GET_COURSE_VIDEO_PREVIEW,
  REMOVE_FROM_WISHLIST,
} from '../../../../provider/services/Course.service'
import {
  useMutationService,
  useQueryService,
} from '../../../../config/axiosQuery.service'
import {GET_CATEGORY_DETAILS} from '../../../../graphql/queries/getCategoryProduct'

const MAX_VISIBLE_GOALS = 2

export interface PopperCourseProps extends PopperProps {
  item: ICourse
}

const PopperCourseBanner = (props: Pick<PopperCourseProps, 'item'>) => {
  const {item} = props

  const {
    data: dataVideoPreview,
    error: errorVideoPreview,
    isFetching,
  } = GET_COURSE_VIDEO_PREVIEW(item.slug, item.video)

  if (errorVideoPreview) {
    console.error(errorVideoPreview)
  }

  if (dataVideoPreview) {
    return <JWPlayer playlist={dataVideoPreview?.url?.playlist} />
  }

  if (isFetching) {
    return <Skeleton height={158} className="rounded mb-4" />
  }

  return (
    <CardMedia
      style={{height: 140}}
      className="rounded mb-4"
      image={
        ((item as any).Course
          ? (item as any).Course.thumbnail
          : item.thumbnail) ||
        require('../../../../assets/images/exampleCourseCover.image.png')
      }
    />
  )
}

const PopperCourseActions = (props: Pick<PopperCourseProps, 'item'>) => {
  const {item} = props

  const isFree = !item.Price || '' + item.Price.pricing.IDR === '0'

  const {enqueueSnackbar} = useSnackbar()
  const [addToCart] = ADD_TO_CART()
  const [loading, setLoading] = React.useState(false)
  // const [wishlistId, setWishlistId] = React.useState(0)

  const {mutateAsync: addToWishlist} = useMutationService(
    'academy/wishlist/add',
    'POST'
  )

  const {data: cartData, refetch: refetchCart} = useQuery(GET_USER_CART_COUNT, {
    skip: isFree,
    fetchPolicy: 'cache-and-network',
    variables: {
      itemTable: 'academy_courses',
      itemIds: ['' + item.id],
    },
  })

  // NOTE(intrnl): would've liked the ability to skip querying for this
  const {data: wishlistData, refetch: refetchWishlist} = useQueryService(
    ['courseWishlistList'],
    `academy/wishlist/get`
  )

  const {data: ownedData} = useQueryService(
    ['myCourse'],
    `academy/student/${USER_ID}/enrollment`
  )

  const wishlistItem = React.useMemo(() => {
    return (
      wishlistData &&
      wishlistData.find((wished: any) => wished.courseId === item.id)
    )
  }, [wishlistData, item.id])

  const {mutateAsync: dispatchRemove} = REMOVE_FROM_WISHLIST(wishlistItem?.id)

  const isInCart = cartData && cartData.marketplace_user_carts.length > 0

  const isInWishlist = React.useMemo(() => {
    return (
      wishlistData &&
      wishlistData.some((wished: any) => wished.courseId === item.id)
    )
  }, [wishlistData, item.id])

  const isOwned = React.useMemo(() => {
    return (
      ownedData && ownedData.some((owned: any) => owned.courseId === item.id)
    )
  }, [ownedData, item.id])

  const handleCartAddition = () => {
    const variables = {
      objects: [
        {
          item_id: String(item.id),
          item_object: {
            name: item.title,
            image: item.thumbnail,
            price: item?.Price?.pricing?.IDR || 0,
            weight: 0,
            note: '',
          },
          item_table: 'academy_courses',
          item_vendor: item.CreatorUser?.name,
          item_vendor_id: item.CreatorUser?.id,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    const promise = addToCart({variables})

    promise.then(
      () => {
        refetchCart()
      },
      () => {
        enqueueSnackbar(`Failed to add course to shopping cart`, {
          variant: 'error',
        })
      }
    )
  }

  const handleWishlistAddition = () => {
    setLoading(true)
    const promise = addToWishlist({courseId: item.id})

    promise.then(
      () => {
        refetchWishlist()
        setLoading(false)
        enqueueSnackbar('added to wishlist', {variant: 'success'})
      },
      () => {
        enqueueSnackbar(`Failed to add course to wishlist`, {variant: 'error'})
      }
    )
  }

  const handleRemoveWishlist = async () => {
    setLoading(true)
    const promise = dispatchRemove({})

    promise.then(
      () => {
        refetchWishlist()
        setLoading(false)
        enqueueSnackbar(`Course removed from wishlist`, {
          variant: 'success',
        })
      },
      () => {
        setLoading(false)
        enqueueSnackbar(`Failed to remove course from wishlist`, {
          variant: 'error',
        })
      }
    )
  }

  if (isOwned) {
    return null
  }

  return (
    <div className="flex flex-row justify-between mt-4">
      {isFree ? (
        <Button
          component={Link}
          to={`/learner/course/${item.slug}`}
          variant="contained"
          color="primary"
          size="large"
        >
          Ambil Kursus
        </Button>
      ) : (
        <AddToCartButton
          isInCart={isInCart}
          action={handleCartAddition}
          size="large"
        />
      )}

      {isInWishlist ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleRemoveWishlist}
          disabled={loading}
        >
          <FavoriteIcon />
        </Button>
      ) : (
        <Button
          onClick={handleWishlistAddition}
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
        >
          <FavoriteBorderIcon />
        </Button>
      )}
    </div>
  )
}

// FIXME(intrnl): My Courses endpoint is the only one that does not return
// `AcademyCourseCategory` field which is required here. please remove this
// once we finish moving all the Course-related queries to GraphQL.
const PopperCourseCategory = (props: Pick<PopperCourseProps, 'item'>) => {
  const {item} = props

  const cat = item.AcademyCourseCategory

  const {data, error} = useQuery(GET_CATEGORY_DETAILS, {
    skip: !!cat,
    variables: {
      categoryId: item.category,
    },
  })

  if (cat) {
    return <span>{cat.name}</span>
  }

  if (!data) {
    return null
  }

  if (error) {
    return <span>Unknown</span>
  }

  return <span>{data.academy_course_categories_by_pk.name}</span>
}

const PopperCourse = (props: PopperCourseProps) => {
  const {item, hidePrice, featureName} = props

  const sanitizedDescription = React.useMemo(() => {
    return Dompurify.sanitize(item.description)
  }, [item])

  const goals = (item.Course ? item.Course.goals : item.goals) || []

  const dot = <div className="h-1 w-1 rounded-full bg-gray-600" />

  return (
    <Card style={{width: 320}} className="bg-white p-4">
      <PopperCourseBanner item={item} />

      <div>
        <LinesEllipsis
          text={item.Course ? item.Course.title : item.title}
          maxLine={2}
          component={Link}
          to={`/learner/course/${item.slug}`}
          state={{prevRouteName: featureName}}
          className="font-bold cursor-pointer overflow-hidden"
        />

        <div className="flex flex-wrap items-center gap-x-1 text-gray-600 text-xs mt-1">
          <span>
            {defineLanguageCode(
              item.Course ? item.Course.language : item.language
            )}
          </span>
          {dot}
          <span>
            {defineAcademyLevel(item.Course ? item.Course.level : item.level)}
          </span>
          {dot}
          <PopperCourseCategory item={item} />
        </div>

        <LinesEllipsis
          dangerouslySetInnerHTML={{__html: sanitizedDescription}}
          maxLine={5}
          component="p"
          className="text-sm overflow-hidden mt-2 mb-0 empty:hidden"
        />

        {goals.length > 0 && (
          <>
            <Typography gutterBottom className="font-bold text-sm mt-4">
              Apa yang kamu akan pelajari ?
            </Typography>

            <ul className="text-sm space-y-1">
              {goals
                .slice(0, MAX_VISIBLE_GOALS)
                .map((goal: string, index: number) => (
                  <li className="flex flex-row" key={index}>
                    <CheckIcon fontSize="small" />

                    <LinesEllipsis
                      text={goal}
                      maxLine={2}
                      component="span"
                      className="overflow-hidden ml-2"
                    />
                  </li>
                ))}

              {goals.length > MAX_VISIBLE_GOALS && (
                <li className="text-gray-600">
                  +{'' + (goals.length - MAX_VISIBLE_GOALS)} lebih banyak
                </li>
              )}
            </ul>
          </>
        )}
      </div>

      {!hidePrice && <PopperCourseActions item={item} />}
    </Card>
  )
}

export {PopperCourse}
