import {Divider} from '@material-ui/core'
import {userData} from '../../utils/globals'
import {UserLoggedInProfileWrapper} from '../job-portal/JobPortalStyles'
// grapql
// import {GET_BALANCE_USER} from '../../graphql/queries/getBalanceUser.query'
// Component
import UserSectionComponent from './UserSection.component'
// import UserWalletComponent from './UserWallet.component'
import UserNavigationComponent from './UserNavigation.component'

const customStyle = {
  usernameLabel: {
    color: '#252525',
    fontWeight: 'bold',
    fontSize: 18,
    marginTop: 8,
  },

  posisitionLabel: {
    color: '#a9a8a8',
    fontSize: 13,
    marginTop: 8,
    marginBottom: 8,
    width: 180,
  },
  balanceLabel: {
    color: '#3A798D',
    fontSize: 18,
    width: 180,
    fontWeight: 600,
  },
  textLink: {
    color: '#039be5',
    cursor: 'pointer',
    fontSize: 13,
  },
  pointer: {
    cursor: 'pointer',
  },
  divider: {
    margin: '20px 0px',
  },
  buttonClaim: {
    width: 'auto',
    padding: 0,
    fontSize: '13px',
  },
}

const UserLoggedIn = () => {
  // const {data: balance, error: error1} = useQuery(GET_BALANCE_USER, {
  //   variables: {
  //     userID: USER_ID,
  //   },
  // })

  // if (balance === undefined && error1 === undefined) {
  //   return <CircularProgress />
  // } else if (error1 !== undefined) {
  //   return <div>{JSON.stringify(error1)}</div>
  // }

  return (
    <>
      <UserLoggedInProfileWrapper>
        <UserSectionComponent data={userData} styles={customStyle} />
        {/* <Divider style={customStyle.divider} />
        <UserWalletComponent styles={customStyle} balance={balance} /> */}
        <Divider style={customStyle.divider} />
        <UserNavigationComponent />
      </UserLoggedInProfileWrapper>
    </>
  )
}

export default UserLoggedIn
