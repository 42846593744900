import {gql} from '@apollo/client'

export const GET_DETAIL_CONTRIBUTOR_SOCIAL_LEARNING = gql`
  query (
    $userId: uuid!
    $id: uuid!
    $search: String
    $ordering: [classrooms_order_by!]
  ) {
    classrooms(
      where: {owner_user_id: {_eq: $id}, name: {_ilike: $search}}
      order_by: $ordering
    ) {
      id
      name
      global_user {
        id
        name
      }
      avatar_url
      is_finished
      description
      price
      rules
      privacy
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        user: global_user {
          id
          avatar
          name
        }
      }
      member_requests: classroom_member_requests(
        where: {user_id: {_eq: $userId}, status: {_eq: 0}}
        order_by: {created_at: desc, user_id: asc}
      ) {
        id
        status
        global_user {
          id
        }
      }
      contributor_requests: classroom_contrib_requests(
        where: {status: {_eq: 0}}
        order_by: {date_added: desc, contrib_user_id: asc}
        limit: 1
      ) {
        id
        status
        global_user {
          id
        }
      }
    }
  }
`
