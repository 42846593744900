import {Link as RouterLink} from 'react-router-dom'

import {Checkbox, Divider, Link, Paper, Typography} from '@material-ui/core'

type bookProps = {
  type: string
}
const ContributorBookManagePage = ({type}: bookProps) => {
  const MANAGE_FIELDS = [
    {
      label: `${type === 'ebook' ? 'E-Book' : 'Audiobook'} Information`,
      children: [
        {
          label: `${type === 'ebook' ? 'E-Book' : 'Audiobook'} Landing Page`,
          to: 'landing',
          checked: true,
        },
      ],
    },
    {
      label: 'Content',
      children: [
        {
          label: `Upload ${type === 'ebook' ? 'E-Book' : 'Audiobook'}`,
          to: 'upload',
          checked: false,
        },
      ],
    },
  ]
  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        Manage {type === 'ebook' ? 'E-Book' : 'Audiobook'}
      </Typography>

      <Divider />

      <div className="flex flex-col gap-4 p-6">
        {MANAGE_FIELDS.map((category) => (
          <Paper key={category.label} className="bg-[#f7f8f9]">
            <Typography color="primary" className="px-6 py-4 font-bold">
              {category.label}
            </Typography>

            <Divider />

            <div className="flex flex-col gap-4 py-4 pl-4 pr-6">
              {category.children.map((item) => (
                <div key={item.label} className="flex items-center gap-4">
                  <Checkbox checked={item.checked} readOnly color="primary" />
                  <span className="grow text-sm">{item.label}</span>
                  <Link
                    component={RouterLink}
                    to={item.to}
                    color="primary"
                    className="text-sm font-bold"
                  >
                    Manage
                  </Link>
                </div>
              ))}
            </div>
          </Paper>
        ))}
      </div>
    </Paper>
  )
}

export default ContributorBookManagePage
