import {ApolloClient, HttpLink, InMemoryCache} from '@apollo/client-unpatched'
import {onError} from '@apollo/client-unpatched/link/error'

import {GRAPHQL_URL, SSO_URL} from '../utils/globals'

const cache = new InMemoryCache()
const httpLink = new HttpLink({
  uri: GRAPHQL_URL,
  credentials: 'include',
})

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message, extensions}) => {
      console.error(`[GraphQL error]: Message: ${message}`)
      console.error(extensions)
      if (process.env.NODE_ENV !== 'development') {
        // @ts-ignore
        if (
          extensions.code === 'invalid-jwt' ||
          extensions.code === 'invalid-headers'
        ) {
          // @ts-ignore
          window.location = SSO_URL
        }
      }
    })
  if (networkError) {
    console.error(`[Network error]: ${networkError}`)
  }
})

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
})

export default client
