import {Button} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'

interface RatingButtonProps {
  star?: number
  selected?: boolean
  onClick?: () => void
}

const RatingButton = (props: RatingButtonProps) => {
  const {star, selected, onClick} = props

  return (
    <Button
      onClick={onClick}
      variant={selected ? 'contained' : 'outlined'}
      color="secondary"
      disableElevation
      startIcon={star && <StarIcon className="text-base" />}
      className="w-16 px-2.5 py-1 min-w-0"
    >
      {star || 'All'}
    </Button>
  )
}

export interface RatingSelectProps {
  value?: number
  onChange?: (next: number | undefined) => void
}

const RatingSelect = (props: RatingSelectProps) => {
  const {value, onChange} = props

  return (
    <div className="flex gap-4">
      <RatingButton
        selected={value === undefined}
        onClick={onChange && (() => onChange(undefined))}
      />
      <RatingButton
        star={5}
        selected={value === 5}
        onClick={onChange && (() => onChange(5))}
      />
      <RatingButton
        star={4}
        selected={value === 4}
        onClick={onChange && (() => onChange(4))}
      />
      <RatingButton
        star={3}
        selected={value === 3}
        onClick={onChange && (() => onChange(3))}
      />
      <RatingButton
        star={2}
        selected={value === 2}
        onClick={onChange && (() => onChange(2))}
      />
      <RatingButton
        star={1}
        selected={value === 1}
        onClick={onChange && (() => onChange(1))}
      />
    </div>
  )
}

export default RatingSelect
