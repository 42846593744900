import React from 'react'
import Header from '../../../../../components/header/HeaderComponent'
import {
  TabsWrapper,
  StyledTab,
  StyledTabs,
} from '../../../../../components/utils-components/GlobalStyles'
import styled from 'styled-components'
import {MainWrapper} from '../../../../../components/job-portal/JobPortalStyles'
import {useTitle} from 'hoofd'
import {useParams, useNavigate} from 'react-router-dom'
import {CourseDetail} from '../../../../../components/learner/course'
import {COURSE_BY_SLUG} from '../../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import BackNavigation from '../../../../../components/navigation/BackNavigation.component'
import Alert from '@material-ui/lab/Alert'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const AppWrapper = styled.div``

const ContributorCoursePreview = () => {
  const navigate = useNavigate()
  const {courseSlug} = useParams()
  const {data, isLoading, refetch} = COURSE_BY_SLUG(courseSlug || '')

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useTitle(data?.title || '')

  if (isLoading) {
    return <Skeleton height={300} className="mx-16 my-40" width={'50vw'} />
  }

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Learning Catalog" value="learning-catalog" />
        </StyledTabs>
      </TabsWrapper>
      <MainWrapper>
        <AppWrapper className="w-full flex flex-col">
          <Alert
            severity="info"
            className="mb-8 border py-4"
            icon={false}
            action={
              <span
                onClick={() => navigate(-1)}
                className="cursor-pointer font-semibold"
              >
                Back to Learning Contributor
              </span>
            }
          >
            This is the preview display on the user
          </Alert>
          <BackNavigation />
          <CourseDetail
            course={data}
            refetchCourseDetail={refetch}
            isPreview={true}
          />
        </AppWrapper>
      </MainWrapper>
    </>
  )
}

export default ContributorCoursePreview
