import {gql} from '@apollo/react-hooks'

export const GET_EVENT_TYPES = gql`
  query {
    event_types(where: {deletedAt: {_is_null: true}}) {
      id
      code
      name
    }
  }
`

export const GET_EVENT_SLUG_AVAILABILITY = gql`
  query ($slug: String) {
    event_schedules(where: {slug: {_eq: $slug}}) {
      id
    }
  }
`
