import React from 'react'
import {IconButton} from '@material-ui/core'
import {CheckBox, CheckBoxOutlineBlankOutlined} from '@material-ui/icons'
import {ICourse, ILessonProgress} from '../../../../interfaces/Course.interface'

type LessonCompletionCheckboxProps = {
  course: ICourse
  lessonId: number
  refetchCourseDetail: () => void
  onSubmitCourseEnrollmentProgress?: any
  isSuccessCourseEnrollmentProgress: boolean
  isActive?: boolean
}

const LessonCompletionCheckbox = (props: LessonCompletionCheckboxProps) => {
  const {refetchCourseDetail} = props
  const [isChecked, setIsChecked] = React.useState<boolean>(false)

  const isLessonChecked = React.useCallback(() => {
    return props.course.Enrollment.LessonProgress.some(
      (progress: ILessonProgress) =>
        progress.lessonId === props.lessonId && progress.status === 'DONE'
    )
  }, [props.course.Enrollment.LessonProgress, props.lessonId])

  React.useEffect(() => {
    setIsChecked(isLessonChecked())
  }, [isLessonChecked])

  React.useEffect(() => {
    if (props.isSuccessCourseEnrollmentProgress) {
      refetchCourseDetail()
    }
  }, [props.isSuccessCourseEnrollmentProgress, refetchCourseDetail])
  return (
    <IconButton
      onClick={() => {
        if (!isChecked) {
          setIsChecked(true)
          return props.onSubmitCourseEnrollmentProgress({
            courseId: props.course.id,
            enrollmentId: props.course.Enrollment.id,
            lessonId: props.lessonId,
            grade: 100,
            status: 'DONE',
          })
        }
      }}
    >
      {isChecked ? (
        <CheckBox
          color="secondary"
          style={props.isActive ? {color: '#fff'} : {}}
        />
      ) : (
        <CheckBoxOutlineBlankOutlined color="disabled" />
      )}
    </IconButton>
  )
}

export default LessonCompletionCheckbox
