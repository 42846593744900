import styled from 'styled-components'

import {
  makeStyles,
  withStyles,
  Card,
  Tabs,
  Tab,
  Icon,
  LinearProgress,
  Avatar,
  Button,
  Grid,
  ExpansionPanelSummary,
  MenuItem,
  ExpansionPanel,
  FormControl,
  Typography,
  IconButton,
  TextField,
  TablePagination,
  Accordion,
  AccordionDetails,
  ListItem,
} from '@material-ui/core'
import {fade} from '@material-ui/core/styles/colorManipulator'
import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'

import {Paper} from '../paper/PaperStyles'
import Select from 'react-select'

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props: {theme: {font: any}}) => props.theme.font};
  justify-content: space-between;
`

export const TitleCreate = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  font-size: 18px;
`
export const TaskCard = styled.div`
  width: 100%;
`

export const FlexBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`
export const AddressDetail = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding: 8px;
  align-content: flex-start;
`

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const FlexRowFrist = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`

export const FlexRowFristCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: flex-start;
`

export const FlexRowCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const FlexRowEnd = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: flex-end;
  align-content: flex-end;
`
export const FlexColumnCenter = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`

export const FlexColumn = styled.div`
  margin: 10px 35px;
  flex-direction: column;
  width: 90%;
`

export const BoxWallet = styled.div`
  margin: 10px 34px 10px 0px !important;
  flex-direction: column;
  width: 90%;
`

export const FlexColumnFirst = styled.div`
  flex-direction: column;
  justify-content: flex-start;
`

export const Center = styled.div`
  text-align: center;
`

export const SummaryContainer = styled.div``

export const ListSummaryContainer = styled.div`
  display: flex;
  justify-content: space-between;
  // padding-top: 1.5rem;
  flex-wrap: wrap;
`

export const CardSummary = styled(Card)`
  padding: 18px;
  width: 27%;
  cursor: pointer;
  margin-top: 1rem;
`
export const OverallContainer = styled.div`
  margin: 1rem 0;
  color: #039be5;
`
export const SummaryScore = styled.div`
  color: #039be5;
`
export const Rate = styled.div`
  color: #039be5;
`

export const DateContainer = styled.div`
  color: #707070;
  height: 2rem;
  display: grid;
`
export const SummaryListContainer = styled.div``

export const AchievementContainer = styled.div`
  padding-top: 2rem;
`

export const MultiraterContainer = styled.div`
  padding-top: 2rem;
`

export const NPSContainer = styled.div`
  padding-top: 2rem;
`

export const FeedbackContainer = styled.div`
  padding-top: 2rem;
`
export const TextDeepTealSmall = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  color: #014a62;
`

export const SelectContainer = styled.div``

export const SeeMore = styled.div`
  font-size: 14px;
  border-top: 3px solid #f7f8f9;
  margin-top: 1.5rem;
  padding-top: 1rem;
  text-align: center;
  color: #039be5;
  text-transform: none !important;
  width: 100%;
`

export const CardFeedback = styled(Card)`
  padding: 15px;
  width: 15%;
  display: flex;
  justify-content: center;
`

export const IconFeedback = styled(Icon)`
  font-size: 2.3rem;
`

export const CardFeedbackTabs = styled(Card)`
  padding: 8px 18px;
  margin-bottom: 10px;
  cursor: pointer;
`
export const FeedbackTabsContainer = styled(Card)`
  width: 100%;
  margin-top: 20px;
`
export const FeedbackTabsContent = styled.div`
  padding: 20px;
  padding-top: 0;
  background-color: #f7f8f9;
`
export const FeedbackTabs = styled(Tabs)`
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px -1px,
    rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px;
`
export const FeedbackTab = styled(Tab)`
  text-transform: none;
`
export const StyledTabs = withStyles({
  root: {
    boxShadow: 'none !important',
    backgroundColor: '#fff',
  },
  indicator: {
    backgroundColor: '#039be5',
    height: '3px',
  },
})(Tabs)

export const StyledTabsWhite = withStyles({
  root: {
    boxShadow: 'none !important',
    backgroundColor: 'rgba(246, 248, 249, 0)',
  },
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: '#039be5',
    height: '5px',
  },
})(Tabs)

export const StyledTabsGrey = withStyles({
  root: {
    boxShadow: 'none !important',
    backgroundColor: '#fafafa',
    display: 'flex',
    justifyContent: 'space-between',
  },
  indicator: {
    backgroundColor: '#039be5',
    height: '3px',
  },
})(Tabs)
export const StyledTabWhite = withStyles({
  root: {
    minWidth: '50px',
    padding: '0px 20px',
    margin: '0px 25px',
  },
})(Tab)

export const TabsWrapper = styled.div`
  display: inline-block;
  width: 100%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  z-index: 2;
  position: sticky;
  top: 58px;
`

export const StyledTabsSurvey = withStyles({
  indicator: {backgroundColor: '#f6f8f9', height: '3px'},
})(Tabs)

export const StyledTab = withStyles({
  root: {
    boxShadow: 'none',
    textTtransform: 'capitalize',
    padding: '8px 20px',
    minWidth: '100px',
    fontSize: (props: {fontSize?: string}) => props.fontSize || '13px',
    fontWeight: 'bold',
  },
})(Tab)

export const ArchiveContainer = styled.div`
  min-height: 75vh;
`
export const TitlePerforment = styled.div`
  width: 66px;
  height: 20px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #055469;
`

export const ListArchiveContainer = styled.div`
  margin: 0 1.5rem;
  justify-content: space-between;
  padding-top: 0.5rem;
  display: -webkit-flex;
  -webkit-flex-wrap: wrap;
  display: flex;
  flex-wrap: wrap;
`
export const DetailArchiveContainer = styled.div``

export const TitleArchive = styled.div``

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`

export const InformationContainer = styled.div`
  padding-bottom: 24px;
`

export const StyledLinear = withStyles({
  root: {
    borderRadius: 22,
    margin: '5px 1rem 10px 0',
    background: 'white',
    border: '1px solid lightgrey',
    height: 10,
    width: '80%',
  },
  // bar1Determinate: {
  //   background: '#4caf50',
  // },
})(LinearProgress)

export const TabContent = styled.div`
  padding-top: 2rem;
`

export const DescriptionTabContainer = styled.div``

export const DescriptionWrapper = styled.div``

export const AttachmentWrapper = styled.div`
  padding-top: 2rem;
`

export const AttachmentImg = styled.img`
  width: 30%;
`
export const ActivityTabContainer = styled.div``

export const CommentAvatar = styled(Avatar)`
  width: 78px;
  height: 78px;
`
export const CommentWrapper = styled.div`
  display: flex;
  padding-bottom: 1.5rem;
  align-items: center;
`

export const CommentWrapperAct = styled.div`
  display: flex;
  width: 80%;
`

export const CommentDetailWrapper = styled.div`
  width: 100%;
  margin-left: 1rem;
`

export const CommentChildrenAvatar = styled(Avatar)`
  width: 58px;
  height: 58px;
`

export const BtnAttachment = styled(Button)`
  background: #3b86ff;
  text-transform: capitalize;
  margin: 1rem 1rem 0 0;
`

export const MainMultiraterContainer = styled.div``

export const ListMultiraterContainer = styled.div`
  margin-top: 1rem;
`
export const GoalContainer = styled(Grid)`
  background-color: #f7f8f9;
  margin: 0;
  max-height: 42rem;
  overflow-y: auto;
`
export const FormElement = styled.div`
  width: 50%;
`

export const FilterAssignWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
`

export const StyledExpansionSummaryAssign = styled(ExpansionPanelSummary).attrs(
  {
    classes: {content: 'content'},
  }
)`
  .content {
    align-items: center;
    justify-content: space-between;
  }
`
export const FormChildButton = styled.div`
  display: flex;
  justify-content: flex-end;
  min-height: 80px;
  align-items: center;
  padding-right: 1.6rem;
  padding-bottom: 1rem;
`
export const FeedbackListContainer = styled.div`
  height: 260px;
  overflow-y: auto;
  padding-top: 10px;
`
export const CardSummaryPlain = styled(Card)`
  padding: 18px;
  width: 27%;
  margin-top: 1rem;
`

export const TitleHoverable = styled.div`
  display: flex;
  align-items: center;
  color: #014a62;
  width: max-content;
  cursor: pointer;
  padding: 8px 0 16px;
`

export const IconTitle = styled(Icon)`
  font-size: 2.3rem;
  padding-right: 12px;
`

export const BlueMenuItem = styled(MenuItem)`
  color: #014a62;
`

export const StyledBorderedExpansion = styled(ExpansionPanel)`
  margin-top: 12px;
  border: 1px solid #a9a8a8;
  border-radius: 5px;
`

// New Style For Goals
export const QuickFilter = styled.p`
  width: 63px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  margin-right: 20px;
`
export const MyGoalsBtn = styled(Button)`
  width: 100px;
  height: 35px;
  border-radius: 5px;
  background-color: #014a62;
  margin-right: 20px;
`
export const SpanMyGoals = styled.span`
  width: 100px;
  height: 15px;
  text-transform: initial;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
`

export const DepartementGoalsBtn = styled(Button)`
  width: 150px;
  height: 35px;
  text-transform: initial;
  border-radius: 5px;
  background-color: #ffffff;
`

export const SpanDepartementGoals = styled.span`
  width: 150px;
  height: 15px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #014a62;
`
export const TitleContent = styled.p`
  width: 100%;
  margin-left: 15px;
  height: 15px;
  font-size: 20px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #014a62;
`
export const TitleCardSummary = styled.p`
  width: 100%;
  height: 15px;
  font-size: 16px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
`

export const TitleCardSummaryContent = styled.p`
  width: 100%;
  height: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 15px;
  text-align: left;
  color: #055469;
`

export const GoalsListBtn = styled(Button)`
  width: 70px;
  height: 30px;
  border-radius: 5px;
`

export const GoalProgressIndicator = styled.p`
  margin: 0px;
  text-align: right;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  color: #707070;
`
export const CarouselCard = styled('div')`
  display: flex;
  overflow-x: scroll;
`

export const FormControlSearch = styled(FormControl)`
  display: flex;
  flex-direction: column;
  height: 49, 97px !important;
  border-radius: 3px;
  border: solid 1px #e5e5e5;
  background-color: #ffffff !important;
  justify-content: ${(props: {justifyContent: any}) => props.justifyContent};
  padding: ${(props: {padding: any}) => props.padding};
`
export const TitleVendor = styled(Typography)`
  height: 32px;
  font-size: 24px;
  font-weight: 300;
  line-height: 32px;
  text-align: left;
  padding-bottom: 15px;
`

export const TextGrey = styled(Typography)`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #707070;
  text-decoration: none;
`

export const TextBlackBold = styled(Typography)`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: #000000;
  text-decoration: none;
`

export const TextBlue = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-decoration: none;
  text-align: left;
  color: #039be5;
`

export const TextSmallGrey = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #707070;
`

export const TextBlack = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`

export const TextSmallBlack = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #000000;
`

export const LitleTextBlack = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`
export const Price = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #000000;
`

export const TextDeepTeal = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #014a62;
`
export const BigTextDeepTeal = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #014a62;
`

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-left: 12px;
  width: 68vw !important;
`

export const ShoppingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 98%;
  padding: 25px 76px;
`

export const TitleTransactionDarkBlue = styled.h1`
  font-size: 16px;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: 600;
  color: #014a62;
`

export const TitleTransactionLightBlue = styled.h1`
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #039be5;
`

export const TextLightBlueP = styled.p`
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 200;
  color: #039be5;
`

export const LitleTextGrey = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #a9a8a8;
`

export const MoreLitleTextGrey = styled(Typography)`
  font-size: 11px;
  line-height: 18px;
  text-align: left;
  color: #a9a8a8;
`

export const LitleTextDarkBlue = styled.span`
  font-size: 12px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #014a62;
`

export const LitleTextGreyBold = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  color: #a9a8a8;
`

export const PMediumTextGreyBold = styled.p`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  margin-left: 30px !important;
  color: #a9a8a8;
`
export const MediumTextGreyBold = styled.span`
  font-size: 14px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  color: #a9a8a8;
`

export const LitleTextGreyHalfBold = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  color: #707070;
`

export const TwoColumnTrasaction = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props: {theme: {font: any}}) => props.theme.font};
  justify-content: space-between;
`

export const PaperTransaction = styled(Paper)`
  margin-top: 20px;
  padding: 25px;
`

export const PaperTransaction2 = styled(Paper)`
  margin-top: 20px;
  padding: 12px 25px;
`

export const LitleTextBlue = styled.span`
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  color: #039be5;
`

export const LitleTextWaterMelon = styled.span`
  font-size: 13px;
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  color: #ef4d5e;
`

export const TextFieldWhite = styled(TextField)`
  background-color: #ffffff;
  width: 250px;
`

export const ButtonRed = withStyles((theme) => ({
  outlined: {
    color: theme.palette.error.main,
    border: `1px solid ${fade(theme.palette.error.main, 0.5)}`,

    '&:hover': {
      border: `1px solid ${theme.palette.error.main}`,
      backgroundColor: fade(theme.palette.error.main, 0.08),

      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  contained: {
    color: theme.palette.error.contrastText,
    backgroundColor: theme.palette.error.main,

    '&:hover': {
      backgroundColor: theme.palette.error.dark,

      '@media (hover: none)': {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
}))(Button)

export const CustomAccordion = withStyles({
  root: {
    padding: 0,
    '&$expanded': {
      margin: 'auto',
    },
    backgroundColor: '#E0E0E0',
  },
  disabled: {
    fontWeight: 'bold',
    color: 'red',
    backgroundColor: 'yellow',
  },
  expanded: {},
})(Accordion)

export const CustomAccordionDetails = withStyles({
  root: {
    paddingTop: 8,
    paddingRight: 0,
    paddingBottom: 16,
    paddingLeft: 0,
  },
})(AccordionDetails)

export const CustomListItem = withStyles({
  root: {
    paddingTop: 10,
    paddingBottom: 10,
  },
})(ListItem)

export const WraperTrasanction = styled.div`
  // display: flex;
  // align-items: center;
  // margin-bottom: 10px;
`

export const WraperTrasanction2 = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`

export const BoxSpace = styled.div`
  margin-top: 22px !important;
  align-items: flex-start;
  justify-content: flex-start;
  align-content: flex-start;
`

export const WhiteShadowButton = styled(Button)`
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  color: #014a62;
  font-size: 20px;
  font-weight: 600;
`

export const IncDecButton = styled(IconButton)`
  background-color: #014a62;
  color: #ffffff;
  height: 30px;
  border-color: #ffff;
  border-radius: 5px;
`

export const TextIncDec = styled.p`
  height: 20px;
  margin: 0px;
  weight: 30px;
  background-color: #ffffff;
  padding: 5px 15px;
  text-align: center;
  text-size: 12px;
`

export const BigTextGrey = styled.span`
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #a9a8a8;
`

export const BiggerTextGrey = styled.span`
  font-size: 18px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #a9a8a8;
`

export const BiggestTextGrey = styled.span`
  font-size: 24px;
  font-weight: 200;
  color: #a9a8a8;
`

export const SmallTextGrey = styled.span`
  font-size: 14px;
  font-weight: 200;
  color: #a9a8a8;
`
export const TextBlack1 = styled.span`
  font-size: 16px;
  font-weight: 200;
  color: #000000;
`

export const TextBlackP = styled.p`
  margin: 5px 0px;
  font-size: 16px;
  font-weight: 200;
  color: #000000;
`

export const TextGreen = styled.span`
  font-size: 16px;
  font-weight: 200;
  color: #4caf50;
`
export const TextGreen1 = styled.div`
  color: #4caf50;
  font-size: 18px;
  font-weight: 700;
`
export const PBigTextGrey = styled.p`
  font-size: 16px;
  margin-bottom: 0px;
  color: #a9a8a8;
`

export const BiggerTextDarkBlue = styled.span`
  font-size: 18px;
  font-weight: 600;
  color: #014a62;
`
export const BiggestTextDarkBlue = styled.span`
  font-size: 24px;
  font-weight: 600;
  color: #014a62;
`

export const BiggerTextBlack = styled.p`
  font-size: 18px;
  margin-top: 0px;
  font-weight: 600;
  color: #000000;
`
export const BiggerTextBlack1 = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: #000000;
`

export const TitleTextBlack = styled.p`
  font-size: 25px;
  margin-top: 0px;
  font-weight: 900;
  color: #000000;
`

export const BigTextRed = styled.span`
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
  color: #ef4d5e;
`

export const JustifyRightTransaction = styled.div`
  display: flex;
  align-items: center;
  font-family: ${(props: {theme: {font: any}}) => props.theme.font};
  justify-content: flex-end;
`

export const Sender = styled.img`
  width: 125px;
  height: 30px;
`

export const BankPayment = styled.img`
  width: 92.8px;
  height: 40.8px;
  cursor: pointer;
`

export const ShoppingImg = styled.img`
  width: 147px;
  height: 140.2px;
  border-radius: 5px;
  cursor: pointer;
`

export const ListImg = styled.img`
  width: 96.7px;
  height: 96.9px;
  border-radius: 5px;
`

export const CourierImg = styled.img`
  object-fit: contain;
  width: 80px;
  height: auto;
`

export const IconContainer = styled.img`
  // width: 30px;
  // height: 30px;
`

export const BankImg = styled.img`
  width: 147px;
  height: 48px;
`

export const ProductImg = styled.img`
  width: 188px;
  height: 161px;
  border-radius: 5px;
  cursor: pointer;
`
export const MoreLitleProductImg = styled.img`
  width: 41px;
  height: 40px;
  border-radius: 5px;
`
export const LitleProductImg = styled.img`
  width: 88px;
  margin-left: 5px;
  margin-top: 5px;
  height: 73px;
  cursor: pointer;
  border-radius: 5px;
`
export const LitleRProductImg = styled.img`
  width: 74px;
  height: 75.2px;
  border-radius: 5px;
`

export const ImgCatcher = styled.div`
  background: #a9a8a8
  width: 88px;
  margin-top: 5px;
  margin-left: 5px;
  height: 73px;
  border-radius: 5px;
`

export const TextImgCatcher = styled.span`
  font-size: 26px;
  text-align: center;
  font-weight: 600;
  color: #ffffff;
  padding: 15px 0px;
  display: inherit;
`

export const GreenButtonLittle = styled(Button)`
  width: 88px;
  height: 29px;
  padding: 0px;
  border-radius: 5px;
  background-color: #4caf50;
  &:hover {
    background-color: #328735;
  }
`

export const LitleTextWhiteBold = styled(Typography)`
  font-size: 12px;
  line-height: 18px;
  text-align: left;
  font-weight: 600;
  color: #ffffff;
`

export const DarkBlueBigButton = styled(Button)`
  width: 365px;
  height: 60px;
  border-radius: 5px;
  background-color: #014a62;

  &:hover {
    background-color: #02303f;
  }
`

export const BiggerTextBlueSky = styled.p`
  font-size: 41px;
  margin: 0px;
  color: #007fb2;
`

export const BiggerTextWhite = styled.span`
  font-size: 20px;
  color: #ffffff;
`

export const LitleTextWhite = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
`

export const AwaitContainer = styled.div``

export const BankContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

export const LeaveNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`
export const LeaveNameChild = styled.div`
  display: flex;
  align-items: center;
`
export const LeaveApplyContainer = styled.div`
  margin-left: 2.3rem;
`
export const AppliesToContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-left: -1rem;
`

export const AppliesToChild = styled.li`
  width: 21%;
  padding-bottom: 1rem;
  color: #a9a8a8;
  padding-right: 1.5rem;
`

export const HeaderPurchseContainer = styled.div`
  background: #f7f8f9;
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
`

export const StarsContainer = styled.div`
  display: flex;
  flexdirection: row;
  color: #039be5;
`
export const SelectCust = styled(Select)`
  height: 44.28px;
  width: 186.16px;
  border-radius: 5px;
`

export const FormSearch = styled(TextField)`
  border-radius: 5px !important;
  z-index: 99 !important;
  height: 44.28px !important;

  & .muiinputbase-input : {
    height: 44.28px !important;
  }
`

export const TextHelper = styled.p`
  color: #f44336;
  font-size: 0.75rem;
  margin-left: 25px;
`

export const TableOptions = {
  search: false,
  selection: false,
  sorting: true,
  showTitle: false,
  toolbar: false,
  style: {
    fontWeight: 700,
    lineHeight: 1.43,
    marginTop: `-100px`,
    color: '#a9a8a8',
  },
  rowStyle: {
    wordBreak: 'break-word',
  },
  selectionProps: () => ({
    color: '#a9a8a8',
  }),
  headerStyle: {
    width: '100%',
    //  maxWidth: '50%',
  },
  cellStyle: {
    width: '100%',
    //  maxWidth: '50%',
  },
}

export const TextRed = styled.div`
  color: #ef4d5e;
  font-size: 18px;
  font-weight: 700;
`
export const TablePaginationCus = styled(TablePagination)`
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  margin-top: 23px;
`
export const Box = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
`

export const BoxGrey = styled.div`
  background-color: #f7f8f9;
  padding: 15px;
  border-radius: 5px;
`

export const CustomTypography = styled(Typography)`
  font-size: ${(props: any) => (props.fsize ? props.fsize : '12px')} !important;
  font-weight: ${(props: any) =>
    props.fweight ? props.fweight : 'normal'} !important;
  color: ${(props: any) => (props.fcolor ? props.fcolor : '#000000')};
  cursor: ${(props: any) => (props.fcursor ? props.fcursor : 'default')};
  margin-top: ${(props: any) => (props.mt ? props.mt : 0)} !important;
  margin-bottom: ${(props: any) => (props.mb ? props.mb : 0)} !important;
  margin-right: ${(props: any) => (props.mr ? props.mr : 0)} !important;
  margin-left: ${(props: any) => (props.ml ? props.ml : 0)} !important;
`

export const CustomFlex = styled.div`
  display: flex;
  flex-direction: ${(props: any) =>
    props.direction ? props.direction : 'row'};
  justify-content: ${(props: any) =>
    props.justify ? props.justify : 'flex-start'};
  align-items: ${(props: any) =>
    props.alignItems ? props.alignItems : 'flex-start'};
  align-content: ${(props: any) =>
    props.alignContent ? props.alignContent : 'flex-start'};
  margin: ${(props: {margin: string}) => props.margin || 0};
`

export const WhiteButton = styled(Button)`
  border-radius: 5px;
  border: 1px solid #cecece !important;
  color: #014a62;
  font-size: 20px;
  font-weight: 600 !important;
  padding: 7px 30px !important;
`

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: ${(props: any) => props.bg || `#eff2f4`};
`

export const Left = styled.div`
  width: 70%;
  & > * ~ * {
    margin-top: 2rem;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`
export const Right = styled.div`
  width: 30%;
  & > * ~ * {
    margin-top: 2rem;
  }
  @media (max-width: 640px) {
    width: 100%;
  }
`
export const GreyTypography = styled(Typography)`
  color: #8d8c8c;
`

export const WhiteModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
`

export const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#1a90ff',
  },
}))(LinearProgress)

export const LinearProgressRating = withStyles((theme) => ({
  root: {
    marginTop: 4,
    height: 8,
    width: '100%',
    borderRadius: 3,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#039be5',
  },
}))(LinearProgress)

export const FlatAccordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion)

export const FlatAccordionSummary = withStyles({
  root: {
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary)

export const FlatAccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    display: 'block',
  },
}))(MuiAccordionDetails)

export const SummaryLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: '#dbc9e7',
  },
}))(LinearProgress)

export const MoreButton = withStyles(() => ({
  root: {
    color: '#A9A8A8',
    backgroundColor: '#efefef',
    padding: '0px 10px 0px 10px',
    borderRadius: '5px',
    height: '24px',
    // width: '38px',
  },
}))(Button)

export const FollowButton = withStyles(() => ({
  root: {
    padding: '3.5px 16.4px 3.5px 16.4px',
    borderRadius: '5px',
    height: '24px',
  },
}))(Button)

export const GrayTabs = withStyles({
  root: {
    backgroundColor: '#efefef',
  },
  indicator: {
    backgroundColor: '#1890ff',
    height: '4px',
  },
})(Tabs)

export const ThinTextfield = styled(({...other}) => (
  <TextField
    InputProps={{
      classes: {
        input: 'input',
      },
      disableUnderline: true,
    }}
    FormHelperTextProps={{
      classes: {
        contained: 'contained',
      },
    }}
    {...other}
  />
))`
  & .input {
    padding: 10px 14px;
    background: white;
    font-weight: bold;
  }
  & .contained {
    margin: 0px;
    margin-top: 10px;
  }
`

const useStyles = makeStyles(() => ({
  search: {
    height: '56px !important',
  },
  paddingTop8: {
    paddingTop: '8px !important',
  },
  marginY20: {
    margin: '20px 0px !important',
  },
  marginTop50: {
    marginTop: '50px !important',
  },
  marginTop15: {
    marginTop: '15px !important',
  },
  marginRight10: {
    marginRight: '10px !important',
  },
  width80P: {
    width: '80%',
  },
  width70P: {
    width: '70%',
  },
  width60P: {
    width: '60%',
  },
  width50P: {
    width: '50%',
  },
  width40P: {
    width: '40%',
  },
  width30P: {
    width: '30%',
  },
  width15P: {
    width: '15%',
  },
  width10P: {
    width: '10%',
  },
  width5P: {
    width: '5%',
  },
  fontWeightNormal: {
    fontWeight: 'normal',
  },
  fontWeight600: {
    fontWeight: 'bolder',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
  skyBlueColor: {
    color: '#039be5',
  },
  greyColor: {
    color: '#DCDBDB',
  },
  bar: {
    borderRadius: 3,
    backgroundColor: '#1a90ff',
  },
  chevronContainer: {
    backgroundColor: '#039be5',
    padding: 5,
    borderRadius: 10,
    marginLeft: 6,
    marginRight: 6,
    color: '#fff',
  },
  addBtnAvatar: {
    border: '1px solid #c4c4c4',
    height: '40px',
    width: '40px',
  },
  buttonSwitchType: {
    width: 134,
    height: 45,
  },
  buttonActive: {
    backgroundColor: '#014a62',
    color: '#ffffff',
    '&:hover': {
      backgroundColor: '#014a62',
    },
  },
  buttonInactive: {
    backgroundColor: '#ffffff',
    color: '#a9a8a8',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}))

export default useStyles
