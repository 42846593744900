import gql from 'graphql-tag'

export const UPDATE_MICROLEARNING = gql`
  mutation ($microlearningId: uuid, $set: microlearnings_set_input) {
    update_microlearnings(where: {id: {_eq: $microlearningId}}, _set: $set) {
      returning {
        id
      }
    }
  }
`

export const CREATE_MICROLEARNING = gql`
  mutation (
    $name: String!
    $description: String!
    $price: Int!
    $avatar_url: String!
    $slug: String!
    $category_id: Int!
  ) {
    microlearningNew(
      name: $name
      description: $description
      price: $price
      avatar_url: $avatar_url
      slug: $slug
      category_id: $category_id
    ) {
      id
    }
  }
`
