import React from 'react'
import {makeStyles} from '@material-ui/core'
import Select from 'react-select'
import {TextHelper} from '../utils-components/GlobalStyles'
import {IOptionsEmp, ISocialLearningFormData} from '../../interfaces'

const useStyles = makeStyles(() => ({
  inputStyle: {
    padding: '15px 18px',
  },
  inputStyleTextArea: {
    padding: 0,
  },
  label: {
    color: '#a9a8a8',
    fontSize: 14,
    margin: 0,
    marginBottom: 17,
  },
  root: {
    marginBottom: 32,
  },
  placeholderStyle: {
    '& input::placeholder': {
      fontSize: '14px',
    },
    '& textarea::placeholder': {
      fontSize: '14px',
    },
  },
}))

type propsSelectField = {
  label: string
  name: string
  options?: {
    label: string
    value: number
  }[]
  formatOptionLabel?: (option: IOptionsEmp) => React.ReactElement
  valueDropdown:
    | null
    | {
        label: string
        value: number
      }[]
  placeholder: string
  loading?: boolean
  setSearch?: React.Dispatch<
    React.SetStateAction<{
      mentor_id: string
    }>
  >
  setValue: React.Dispatch<React.SetStateAction<ISocialLearningFormData>>
  setDropdown:
    | React.Dispatch<React.SetStateAction<null>>
    | React.Dispatch<
        React.SetStateAction<
          {
            value: number
            label: string
          }[]
        >
      >
  hideArrow?: boolean
  error?: boolean
  helperText?: string
}

const SelectField = (props: propsSelectField) => {
  const {
    label,
    name,
    options,
    formatOptionLabel,
    valueDropdown,
    placeholder,
    loading,
    setSearch,
    setValue,
    setDropdown,
    hideArrow,
    error,
    helperText,
  } = props
  const classes = useStyles()
  /* eslint-disable no-console */
  const handlerTemp = (dataSearchDropdown: any) => {
    setSearch && 
          setSearch((prev: any) => ({
            ...prev,
            [name]: dataSearchDropdown,
          }))
  }
  return (
    <div className={classes.root}>
      {label && <p className={classes.label}>{label}</p>}
      <Select
        id={name}
        components={{
          SingleValue: (option: any) => {
            return <span>{option?.data?.label}</span>
          },
          ...(hideArrow && {
            DropdownIndicator: () => null,
            IndicatorSeparator: () => null,
          }),
        }}
        options={options}
        formatOptionLabel={formatOptionLabel}
        value={valueDropdown}
        placeholder={placeholder}
        isLoading={loading}
        onInputChange={handlerTemp}
        isClearable={true}
        onChange={(event: any) => {
            /* eslint-disable no-prototype-builtins */
          event && setValue((prev: any) => ({...prev, [name]: event.value}))
          setDropdown(event)
        }}
        styles={{
          control: (provided: any) => ({
            ...provided,
            '&:hover': {borderColor: error ? 'red' : '#000'},
            marginTop: '1rem',
            minHeight: 54,
            borderColor: error ? 'red' : '#c4c4c4',
          }),
          indicatorSeparator: () => ({
            display: 'none',
          }),
          multiValueRemove: (base: any) => ({
            ...base,
            color: '#ffff',
            margin: '8px 9px',
            '&:hover': {color: '#ffff', backgroundColor: 'rgba(0,0,0,0)'},
          }),
          placeholder: (defaultStyles: any) => {
            return {
              ...defaultStyles,
              fontSize: '14px',
              color: '#c4c4c4',
            }
          },
        }}
      />
      {error && <TextHelper className="ml-4 mt-1">{helperText}</TextHelper>}
    </div>
  )
}

export default SelectField
