import {Divider, Paper, Typography} from '@material-ui/core'

import Skeleton from 'react-loading-skeleton'
import {Doughnut} from 'react-chartjs-2'
import {Chart, ArcElement} from 'chart.js'

import {formatCompactNominal} from '../../../utils/contributor-helpers'

Chart.register(ArcElement)

export interface EnrollmentStatsData {
  learners: number
  gender_demographics: {
    male: number
    female: number
  }
  age_demographics: {
    eighteen_to_twenty_four: number
    twenty_five_to_thirty_four: number
    thirty_five_to_forty_nine: number
    over_fifty: number
  }
}

export interface ContributorEnrollmentStatsData {
  learners: number
  enrollments: {
    course_enrollments: number
    ebook_enrollments: number
    audiobook_enrollments: number
    microlearning_enrollments: number
    event_enrollments: number
  }
  gender_demographics: {
    male: number
    female: number
  }
  age_demographics: {
    eighteen_to_twenty_four: number
    twenty_five_to_thirty_four: number
    thirty_five_to_forty_nine: number
    over_fifty: number
  }
}

const genderLabels = [
  {key: 'male', label: 'Male', color: '#039BE5'},
  {key: 'female', label: 'Female', color: '#EF4D5E'},
] as const

const ageLabels = [
  {
    key: 'eighteen_to_twenty_four',
    label: '18\u201324 years',
    color: '#039BE5',
  },
  {
    key: 'twenty_five_to_thirty_four',
    label: '24\u201334 years',
    color: '#EF4D5E',
  },
  {
    key: 'thirty_five_to_forty_nine',
    label: '35\u201349 years',
    color: '#FFA000',
  },
  {
    key: 'over_fifty',
    label: '50+ years',
    color: '#874CAF',
  },
] as const

const enrollmentsList = [
  {
    key: 'course_enrollments',
    label: 'Course',
  },
  {
    key: 'ebook_enrollments',
    label: 'E-Book',
  },
  {
    key: 'audiobook_enrollments',
    label: 'Audiobook',
  },
  {
    key: 'event_enrollments',
    label: 'Event',
  },
  {
    key: 'microlearning_enrollments',
    label: 'Microlearning',
  },
] as const

export type EnrollmentsCardProps =
  | {isMainDashboard: true; data?: ContributorEnrollmentStatsData | null}
  | {isMainDashboard?: false; data?: EnrollmentStatsData | null}

const EnrollmentsCard = (props: EnrollmentsCardProps) => {
  const {isMainDashboard, data} = props

  return (
    <Paper className="xl:col-span-2">
      <Typography color="primary" className="px-6 py-4 font-bold">
        Total Learner
      </Typography>

      <Divider />

      <div className="grid items-center gap-2 px-6 py-4 xl:grid-flow-col xl:gap-4 xl:gap-x-8">
        <div className="xl:row-span-3">
          <div className="flex items-end gap-2">
            {data ? (
              <>
                <span className="text-2xl font-bold xl:text-5xl">
                  {formatCompactNominal(data.learners)}
                </span>{' '}
                <span className="text-xl">Learner</span>
              </>
            ) : (
              <span className="text-2xl xl:text-4xl">
                <Skeleton width={162} />
              </span>
            )}
          </div>
        </div>

        {isMainDashboard
          ? enrollmentsList.map((item) => (
              <div
                key={item.key}
                className="flex justify-between gap-2 text-sm"
              >
                <span>{item.label}</span>
                <span className="text-right font-bold">
                  {data ? (
                    `${formatCompactNominal(
                      (data as ContributorEnrollmentStatsData).enrollments[
                        item.key
                      ]
                    )} Learner`
                  ) : (
                    <Skeleton width={82} />
                  )}
                </span>
              </div>
            ))
          : null}
      </div>

      <Divider />

      <div className="grid xl:grid-cols-2">
        <div className="grid grid-cols-2 gap-4 gap-x-6 px-6 py-4 sm:grid-cols-[minmax(0,_1fr)_144px]">
          <Typography color="primary" className="col-span-2 font-bold">
            Gender Composition
          </Typography>

          <div className="flex flex-col justify-center gap-4 text-sm">
            {genderLabels.map((x) => (
              <div key={x.key} className="flex gap-2">
                <div
                  className="mt-1 h-3 w-3 rounded-full border-[3px]"
                  style={{borderColor: x.color}}
                />

                <div>
                  <p>{x.label}</p>

                  <p className="font-bold" style={{color: x.color}}>
                    {data ? (
                      <>
                        <span className="text-base">
                          {formatCompactNominal(
                            data.gender_demographics[x.key]
                          )}
                        </span>{' '}
                        Learner
                      </>
                    ) : (
                      <span className="text-base">
                        <Skeleton className="text" width={82} />
                      </span>
                    )}
                  </p>
                </div>
              </div>
            ))}
          </div>

          <div>
            <Doughnut
              options={{
                cutout: 50,
                plugins: {
                  // @ts-expect-error
                  title: false,
                  // @ts-expect-error
                  legend: false,
                },
              }}
              data={{
                labels: genderLabels.map((x) => x.label),
                datasets: [
                  {
                    data: genderLabels.map((x) =>
                      data ? data.gender_demographics[x.key] : 0
                    ),
                    backgroundColor: genderLabels.map((x) => x.color),
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4 gap-x-6 px-6 py-4 sm:grid-cols-[minmax(0,_1fr)_144px]">
          <Typography color="primary" className="col-span-2 font-bold">
            Age Composition
          </Typography>

          <div className="grid grid-cols-[min-content_1fr_1fr] content-center items-center gap-4 gap-x-2 text-sm">
            {ageLabels.map((x) => (
              <div key={x.key} className="contents">
                <div
                  className="h-3 w-3 rounded-full border-[3px]"
                  style={{borderColor: x.color}}
                />

                <span>{x.label}</span>

                <span
                  className="justify-self-end font-bold"
                  style={{color: x.color}}
                >
                  {data ? (
                    `${formatCompactNominal(
                      data.age_demographics[x.key]
                    )} Learner`
                  ) : (
                    <Skeleton width={78} />
                  )}
                </span>
              </div>
            ))}
          </div>

          <div>
            <Doughnut
              options={{
                cutout: 50,
                plugins: {
                  // @ts-expect-error
                  title: false,
                  // @ts-expect-error
                  legend: false,
                },
              }}
              data={{
                labels: ageLabels.map((x) => x.label),
                datasets: [
                  {
                    data: ageLabels.map((x) =>
                      data ? data.age_demographics[x.key] : 0
                    ),
                    backgroundColor: ageLabels.map((x) => x.color),
                    borderWidth: 0,
                  },
                ],
              }}
            />
          </div>
        </div>
      </div>
    </Paper>
  )
}

export default EnrollmentsCard
