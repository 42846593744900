import React, {type ReactNode} from 'react'

import clsx from 'classnames'
import {format} from 'date-fns'

import {
  Avatar,
  Button,
  IconButton,
  OutlinedInput,
  Typography,
} from '@material-ui/core'
import ReplyIcon from '@material-ui/icons/Reply'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import SecondaryRating from './SecondaryRating'

export interface RatingCommentProps {
  avatarUrl?: string
  author?: string
  position?: string
  positionHighlight?: boolean
  date?: string | number
  rating?: number
  body?: string
  footer?: ReactNode
  gutterTop?: boolean
  onMenu?: React.MouseEventHandler<HTMLButtonElement>
}

export const RatingComment = (props: RatingCommentProps) => {
  const {
    avatarUrl,
    author,
    position,
    positionHighlight,
    date,
    rating,
    body,
    footer,
    gutterTop,
    onMenu,
  } = props

  const formattedDate = React.useMemo(() => {
    return date ? format(new Date(date), 'MMMM dd, yyyy') : null
  }, [date])

  return (
    <div className={clsx('flex gap-4 relative', gutterTop && 'mt-4')}>
      <Avatar alt={author} src={avatarUrl} className="h-12 w-12 shrink-0" />

      <div className="flex flex-col grow">
        {onMenu && (
          <IconButton
            edge="end"
            className="absolute top-0 right-0"
            onClick={onMenu}
          >
            <MoreHorizIcon />
          </IconButton>
        )}

        <Typography className="text-sm font-bold pr-9">{author}</Typography>

        {position && (
          <Typography
            color={positionHighlight ? 'secondary' : 'textSecondary'}
            className="text-xs mt-1 pr-9"
          >
            {position}
          </Typography>
        )}

        <div className="flex items-center gap-2 mt-1 pr-9">
          {rating && (
            <SecondaryRating
              value={rating}
              precision={0.5}
              readOnly
              className="text-sm"
            />
          )}

          <Typography color="textSecondary" className="text-xs">
            {formattedDate}
          </Typography>
        </div>

        {body && <p className="text-sm mt-2 whitespace-pre-line">{body}</p>}

        {footer}
      </div>
    </div>
  )
}

export interface ReplyActionProps {
  onReply?: () => void
}

export const ReplyAction = (props: ReplyActionProps) => {
  const {onReply} = props

  return (
    <div className="mt-2">
      <Button
        onClick={onReply}
        color="primary"
        startIcon={<ReplyIcon />}
        size="small"
      >
        Reply
      </Button>
    </div>
  )
}

export interface ReplyTextareaProps {
  editing?: boolean
  comment?: boolean
  disabled?: boolean
  initialValue?: string
  onCancel?: () => void
  onSubmit?: (next: string) => void
}

export const ReplyTextarea = (props: ReplyTextareaProps) => {
  const {editing, comment, disabled, initialValue, onCancel, onSubmit} = props

  const [derived, setDerived] = React.useState(initialValue)
  const [value, setValue] = React.useState(initialValue || '')

  if (derived !== initialValue) {
    setDerived(initialValue)
    setValue(initialValue || '')
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(value)
    }
  }

  return (
    <div className="flex flex-col gap-2 mt-4">
      <Typography color="textSecondary" className="text-sm">
        {editing ? 'Edit' : ''} {comment ? 'Comment' : 'Reply'}
      </Typography>

      <OutlinedInput
        disabled={disabled}
        value={value}
        onChange={(ev) => setValue(ev.target.value)}
        placeholder="Add reply"
        autoFocus
        multiline
        minRows={4}
        className="text-sm"
        margin="dense"
      />

      <div className="flex gap-2 justify-end">
        <Button disabled={disabled} onClick={onCancel}>
          Cancel
        </Button>

        <Button
          disabled={!value || disabled}
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          disableElevation
        >
          {editing ? 'Save' : 'Reply'}
        </Button>
      </div>
    </div>
  )
}
