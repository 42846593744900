import React from 'react'

import {
  LIST_TOP_LEARNING_CONTRIBUTOR,
  LIST_ASIDE_OF_TOP_CONTRIBUTOR,
} from './services/LearningContributor.service'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  initialValue,
} from './reducers/Learner.reducer'

import {LearnerLearningContributorContextType} from '../interfaces'
import {LearnerAcademicContext} from './Academic.provider'

type LearnerLearningContributorProviderProps = {
  children: React.ReactNode
}

type IndividualContributorProps = {
  total_catalog_count: number
  total_learner_count: number
  total_rating_avg: number
  total_rating_count: number
  global_user: {
    name: string
    avatar: string
  }
}
export const LearnerLearningContributorContext =
  React.createContext<LearnerLearningContributorContextType>(initialValue)

export const LearnerLearningContributorProvider = (
  props: LearnerLearningContributorProviderProps
) => {
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )

  const learnerAcademicContext = React.useContext(LearnerAcademicContext)

  const [allInstructorData, setAllInstructorData] = React.useState<
    IndividualContributorProps[]
  >([])

  const {data: dataTopContributor, loading: loadingTopContributor} =
    LIST_TOP_LEARNING_CONTRIBUTOR({
      limit: state.learningContributor.limitTopContributor,
      offset: state.learningContributor.offsetTopContributor,
    })
  const {data: dataTopInstructor, loading: loadingTopInstructor} =
    LIST_ASIDE_OF_TOP_CONTRIBUTOR(
      'INSTRUCTOR',
      state.learningContributor.limitTopInstructor,
      state.learningContributor.offsetTopInstructor
    )
  const {data: dataTopMentor, loading: loadingTopMentor} =
    LIST_ASIDE_OF_TOP_CONTRIBUTOR(
      'MENTOR',
      state.learningContributor.limitTopMentor,
      state.learningContributor.offsetTopMentor
    )
  const {data: dataTopCoach, loading: loadingTopCoach} =
    LIST_ASIDE_OF_TOP_CONTRIBUTOR(
      'COACH',
      state.learningContributor.limitTopCoach,
      state.learningContributor.offsetTopCoach
    )
  const {data: dataTopExpert, loading: loadingTopExpert} =
    LIST_ASIDE_OF_TOP_CONTRIBUTOR(
      'EXPERT',
      state.learningContributor.limitTopExpert,
      state.learningContributor.offsetTopExpert
    )
  const {data: dataTopTrainer, loading: loadingTopTrainer} =
    LIST_ASIDE_OF_TOP_CONTRIBUTOR(
      'TRAINER',
      state.learningContributor.limitTopTrainer,
      state.learningContributor.offsetTopTrainer
    )
  const {
    data: dataAllInstructor,
    loading: loadingAllInstructor,
    refetch: refetchTopContributor,
  } = LIST_TOP_LEARNING_CONTRIBUTOR({
    limit: state.learningContributor.limitAllInstructor,
    offset: state.learningContributor.offsetAllInstructor,
    contrib_labels:
      learnerAcademicContext.reducerState.academicFilter.contributorLabels,
    queryName: learnerAcademicContext.reducerState.searchKeyword,
    ratingGte:
      learnerAcademicContext.reducerState.academicFilter.ratingGQL[0]
        ?.average_rating?._gte,
  })

  React.useEffect(() => {
    if (dataAllInstructor) {
      const _newData = allInstructorData

      dataAllInstructor.learning_contributor_ratings.forEach(
        (item: IndividualContributorProps) => {
          _newData.push(item)
        }
      )
      setAllInstructorData(_newData)
    }
  }, [allInstructorData, dataAllInstructor])

  React.useEffect(() => {
    if (refetchTopContributor) {
      refetchTopContributor()
      setAllInstructorData([])
    }
  }, [
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.contributorLabels,
    learnerAcademicContext.reducerState.academicFilter.ratingGQL,
    refetchTopContributor,
  ])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  const learningContributorReducer = {
    topContributor: {
      data: dataTopContributor?.learning_contributor_ratings || [],
      total: dataTopContributor?.count?.aggregate?.count || 0,
      loading: loadingTopContributor,
    },
    topInstructor: {
      data: dataTopInstructor?.learning_contributor_ratings || [],
      total: dataTopInstructor?.count?.aggregate?.count || 0,
      loading: loadingTopInstructor,
    },
    topMentor: {
      data: dataTopMentor?.learning_contributor_ratings || [],
      total: dataTopMentor?.count?.aggregate?.count || 0,
      loading: loadingTopMentor,
    },
    topCoach: {
      data: dataTopCoach?.learning_contributor_ratings || [],
      total: dataTopCoach?.count?.aggregate?.count || 0,
      loading: loadingTopCoach,
    },
    topExpert: {
      data: dataTopExpert?.learning_contributor_ratings || [],
      total: dataTopExpert?.count?.aggregate?.count || 0,
      loading: loadingTopExpert,
    },
    topTrainer: {
      data: dataTopTrainer?.learning_contributor_ratings || [],
      total: dataTopTrainer?.count?.aggregate?.count || 0,
      loading: loadingTopTrainer,
    },
    allInstructor: {
      data: allInstructorData || [],
      total: dataAllInstructor?.count?.aggregate?.count || 0,
      loading: loadingAllInstructor,
    },
  }

  return (
    <LearnerLearningContributorContext.Provider
      value={{
        changePageLimit: changePageLimitHandler,

        reducerState: state,

        learningContributor: learningContributorReducer,
        refetchTopContributor,
      }}
    >
      {props.children}
    </LearnerLearningContributorContext.Provider>
  )
}
