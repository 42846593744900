import {Typography} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {FC, ReactElement, useState} from 'react'
import {IEvent, IUpcommingEvent} from '../../../interfaces'
import {UPCOMING_EVENT} from '../../../provider/services/Event.service'
import {LearningType} from '../../../utils/enum'
import LoadingCircular from '../../loading/LoadingCircular.component'
import LearnerCatalogCard from '../catalog/LearnerCatalogCard.component'
import useStyles, {LoadingBox} from './Event.style'

const UpcomingEvent: FC<IUpcommingEvent> = (
  props: IUpcommingEvent
): ReactElement => {
  const {creator} = props
  const classes = useStyles()
  const [pagination, setPagination] = useState<{
    limit: number
    offset: number
    page: number
  }>({limit: 4, offset: 0, page: 1})

  const {data: upcomingEventData, error: upcomingEventError} = UPCOMING_EVENT({
    creator: creator,
    limit: pagination.limit,
    offset: pagination.offset,
  })

  if (upcomingEventData && upcomingEventError) {
    return (
      <LoadingBox>
        <LoadingCircular />
      </LoadingBox>
    )
  }

  return (
    <div id="upcoming-event" className="w-full mt-10 lg:w-9/12 lg:mt-0">
      {upcomingEventData?.event_schedules_aggregate?.aggregate?.count !== 0 && (
        <>
          <div className="flex justify-between items-center mt-10">
            <Typography className="text-sm font-extrabold">
              Another Upcoming Event
            </Typography>

            <div className="flex w-fit h-fit">
              {pagination.page > 1 ? (
                <div className={classes.chevronContainer}>
                  <ChevronLeft
                    className="cursor-pointer"
                    onClick={() => {
                      let {page, offset} = pagination
                      const {limit} = pagination

                      page -= 1
                      offset = limit * (page - 1)

                      setPagination({
                        page,
                        offset,
                        limit,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classes.chevronContainer}
                  style={{backgroundColor: '#e5e5e5'}}
                >
                  <ChevronLeft />
                </div>
              )}
              {pagination.page * pagination.limit <
              upcomingEventData?.event_schedules_aggregate?.aggregate?.count ? (
                <div className={classes.chevronContainer}>
                  <ChevronRight
                    className="cursor-pointer"
                    onClick={() => {
                      let {page, offset} = pagination
                      const {limit} = pagination

                      page += 1
                      offset = limit * (page - 1)

                      setPagination({
                        page,
                        offset,
                        limit,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classes.chevronContainer}
                  style={{backgroundColor: '#e5e5e5'}}
                >
                  <ChevronRight />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            {upcomingEventData?.event_schedules?.map(
              (item: IEvent, i: number) => (
                <LearnerCatalogCard
                  key={`upcoming-event${i}`}
                  type={LearningType.EVENT}
                  item={item}
                  hidePrice={false}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default UpcomingEvent
