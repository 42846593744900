import {
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  Select,
  TablePagination,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import React, {useContext} from 'react'
import LearningDiaryResume from '../../../components/card/profileCard/LearningDiaryResume.component'
import {TextFieldSearch} from '../../../components/filter/FilterStyles'
import {FormControlSearch} from '../../../components/utils-components/GlobalStyles'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'
import ListLearningDiaryResume from '../partial/ListLearningDiaryResume.component'

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingLeft: 20,
    color: '#014a62',
    border: '1px solid #e5e5e5',
    borderRadius: '5px',
  },

  selectIcon: {
    color: '#000000',
  },

  input: {
    '&::placeholder': {
      fontWeight: 'lighter',
      color: '#a9a8a8',
    },
  },
  rootMenuItem: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
}))

const LearningDiaryResumePage = () => {
  const classes = useStyles()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const {dataResume} = useContext(LearningProfileContext)
  const [search, setSearch] = React.useState('')
  const [sortValue, setSortValue] = React.useState('longest')

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value)

  const handleSort = (e: React.ChangeEvent<{value: unknown}>) =>
    setSortValue(e.target.value as string)

  const _dataResume = dataResume
    ?.sort((a, b) => {
      const aDuration = a.learning_diaries_aggregate?.aggregate?.sum?.duration
      const bDuration = b.learning_diaries_aggregate?.aggregate?.sum?.duration
      return sortValue === 'longest'
        ? bDuration - aDuration
        : aDuration - bDuration
    })
    .filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))

  return (
    <LearningDiaryResume type="summary">
      <div className="px-6 flex items-conter justify-between">
        <Select
          value={sortValue}
          onChange={handleSort}
          classes={{root: classes.rootMenuItem}}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          variant="standard"
          disableUnderline={true}
        >
          <MenuItem style={{width: '170px'}} value="longest">
            Longest
          </MenuItem>
          <MenuItem style={{width: '170px'}} value="shortest">
            Shortest
          </MenuItem>
        </Select>
        <FormControlSearch variant="outlined">
          <TextFieldSearch
            placeholder="Search Competency"
            name="search_competency"
            value={search}
            onChange={handleSearch}
            autoComplete="off"
            style={{height: 'auto', boxShadow: 'none'}}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.root,
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon style={{color: '#014a62'}} className="mr-4" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControlSearch>
      </div>
      <div className="my-6">
        <ListLearningDiaryResume
          dataResume={_dataResume?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )}
        />
      </div>
      <hr />
      <div className="py-5">
        <TablePagination
          component="div"
          count={_dataResume?.length || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{color: '#a9a8a8'}}
        />
      </div>
    </LearningDiaryResume>
  )
}

export default LearningDiaryResumePage
