import React from 'react'
import {Avatar, Menu, MenuItem} from '@material-ui/core'
import {
  CustomFlex,
  CustomTypography,
  MoreButton,
  FollowButton,
} from '../../../components/utils-components/GlobalStyles'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import {
  SOSMED_APP_URL,
  CHAT_URL,
  CHAT_HOME_SERVER,
  NEW_LEARNING_APP_URL,
} from '../../../utils/globals'

import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import LinesEllipsis from 'react-lines-ellipsis'

type circleProps = {
  item: {
    avatar?: string
    id: string
    name: string
    isFollowing?: boolean
    position?: string
  }
  followingLoading: boolean
  handleFollow: () => void
}

const LearningCircleList = (props: circleProps) => {
  return (
    <CustomFlex justify="space-between" className="mb-6">
      <CustomFlex>
        <Avatar className="mr-6" src={props.item?.avatar} />
        <CustomFlex direction="column">
          <CustomTypography
            fweight="bold"
            fsize="14px"
            style={{
              maxWidth: '350px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.item?.name}
          </CustomTypography>
          <CustomTypography color="textSecondary">
            <LinesEllipsis
              text={props.item?.position}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </CustomTypography>
        </CustomFlex>
      </CustomFlex>
      <CustomFlex>
        <FollowButton
          size="small"
          variant={props.item.isFollowing ? 'outlined' : 'contained'}
          color="primary"
          className="mr-2"
          disabled={props.followingLoading}
          onClick={props.handleFollow}
        >
          {props.item.isFollowing ? 'Mengikuti' : 'Ikuti'}
        </FollowButton>
        <PopupState variant="popover">
          {(popupState) => (
            <React.Fragment>
              <MoreButton {...bindTrigger(popupState)}>
                <MoreHorizIcon />
              </MoreButton>
              <Menu
                {...bindMenu(popupState)}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                getContentAnchorEl={null}
              >
                <MenuItem
                  component="a"
                  href={`${SOSMED_APP_URL}/profile/${props.item.id}/detail`}
                  target="_blank"
                  onClick={popupState.close}
                >
                  Lihat profil
                </MenuItem>
                <MenuItem
                  component="a"
                  href={`${CHAT_URL}?chat=@${props.item.id}:${CHAT_HOME_SERVER}`}
                  target="_blank"
                  onClick={popupState.close}
                >
                  Pesan
                </MenuItem>
                <MenuItem
                  component="a"
                  href={`${NEW_LEARNING_APP_URL}/learner/create-social-learning`}
                  target="_blank"
                  onClick={popupState.close}
                >
                  Mulai Sesi individu
                </MenuItem>
              </Menu>
            </React.Fragment>
          )}
        </PopupState>
      </CustomFlex>
    </CustomFlex>
  )
}

export default LearningCircleList
