import React, { type ChangeEvent } from 'react'

import {
  Box,
  Button,
  Divider,
  // Divider,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'

import {
  CategoryScale,
  Chart,
  LinearScale,
  LineElement,
  TimeScale,
  Tooltip,
  type ChartData,
  // type TooltipModel,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import 'chartjs-adapter-date-fns'

import { type DateRange } from '@smartasn/wlb-utils-components'
import {
  format,
  isSameMonth,
  subDays,
  subWeeks,
  subMonths,
  addDays,
  differenceInDays,
} from 'date-fns'

// import { convertToRupiah } from '../../../utils/helpers'
import { formatCompactNominal } from '../../../utils/contributor-helpers'

Chart.register(LineElement, CategoryScale, LinearScale, Tooltip, TimeScale)

export enum TimeKind {
  ACTIVITY,
  EARNINGS,
}

export enum RangeType {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
  LIFETIME = 'lifetime',
  CUSTOM = 'custom',
}

interface EarningRange {
  timeType: string
  startDate: string
  endDate: string
  actualEndDate: string
  label: string
}

const EarningLabels: Record<RangeType, string> = {
  [RangeType.WEEK]: 'Week',
  [RangeType.MONTH]: 'Month',
  [RangeType.YEAR]: 'Year',
  [RangeType.LIFETIME]: 'Lifetime',
  [RangeType.CUSTOM]: 'Custom Date',
}

const getISOLocalDate = (date: Date) => {
  return format(date, 'yyyy-MM-dd')
}

const formatRange = (start: Date, end: Date) => {
  return `${format(start, 'dd MMMM yyyy')}\u2013${format(end, 'dd MMMM yyyy')}`
}

export const getDateRange = (type: RangeType, ranges?: DateRange) => {
  const now = new Date()
  const tomorrow = addDays(now, 1)

  let startDate: Date
  let endDate: Date = tomorrow
  let actualEndDate: Date = now
  let timeType: 'day' | 'month' | 'year'

  if (type === RangeType.WEEK) {
    startDate = subDays(now, 7)
    timeType = 'day'
  } else if (type === RangeType.MONTH) {
    startDate = subWeeks(now, 4)
    timeType = 'day'
  } else if (type === RangeType.YEAR) {
    startDate = subMonths(now, 12)
    timeType = 'month'
  } else if (type === RangeType.LIFETIME) {
    startDate = new Date(0)
    timeType = 'month'
  } else if (type === RangeType.CUSTOM) {
    startDate = new Date(ranges!.startDate!)
    actualEndDate = new Date(ranges!.endDate!)
    endDate = addDays(actualEndDate, 1)
    timeType = differenceInDays(endDate, startDate) > 30 ? 'month' : 'day'
  } else {
    throw new Error(`unknown option: ${type}`)
  }

  const data: EarningRange = {
    timeType: timeType,
    startDate: getISOLocalDate(startDate),
    endDate: getISOLocalDate(endDate),
    actualEndDate: getISOLocalDate(actualEndDate),
    label: formatRange(startDate, actualEndDate),
  }

  return data
}

const getUnit = (type: RangeType, ranges?: EarningRange) => {
  switch (type) {
    case RangeType.WEEK:
      return 'day'
    case RangeType.MONTH:
      return 'week'
    case RangeType.YEAR:
      return 'year'
    case RangeType.LIFETIME:
      return 'year'
    case RangeType.CUSTOM: {
      const startDate = new Date(ranges!.startDate!)
      const endDate = new Date(ranges!.actualEndDate!)
      return differenceInDays(endDate, startDate) > 30 ? 'month' : 'day'
    }
  }
}

export interface IndividualEarningData {
  date: string
  total_payment: number
}

export interface IndividualActivityData {
  date: string
  total: number
}

export interface EarningsData {
  earnings: IndividualEarningData[]
}

export interface ActivityData {
  enrollments: IndividualActivityData[]
  wishlists: IndividualActivityData[]
}

interface AggregatedActivityData {
  date: number
  label?: string
  enrollments?: number
  wishlists?: number
}

interface BaseTimeChartCardProps {
  rangeType: RangeType
  ranges: EarningRange
  onTypeChange?: (next: RangeType) => void
  onCustomRangeChange?: (next: DateRange) => void
  onReportClick?: () => void
}

export type TimeChartCardProps = BaseTimeChartCardProps &
  (
    | { kind: TimeKind.ACTIVITY; data?: ActivityData | null }
    | { kind: TimeKind.EARNINGS; data?: EarningsData | null }
  )

const TimeChartCard = (props: TimeChartCardProps) => {
  // NOTE(intrnl): destructuring breaks strict type checking for `kind` and `data`
  // use `props.kind` and `props.data` instead.
  const { rangeType, ranges, onTypeChange, onReportClick } = props

  const isActivity = props.kind === TimeKind.ACTIVITY
  const isEarnings = false

  // NOTE(intrnl): Chart.js seems to have difficulties with trying to resize
  // line charts properly, so we'll try manually invoking resize just in case
  const chartRef = React.useRef<Chart<'line'>>(null)

  React.useEffect(() => {
    const chart = chartRef.current

    if (!chart) {
      return
    }

    let timeout: any
    let locked = false

    const handleDebouncedResize = () => {
      const canvas = chart.canvas
      const parent = canvas.parentNode!

      locked = true
      canvas.remove()

      setTimeout(() => {
        parent.appendChild(canvas)
        locked = false
      }, 0)
    }

    const handleWindowResize = () => {
      if (locked) {
        return
      }

      clearTimeout(timeout)
      setTimeout(() => handleDebouncedResize(), 150)
    }

    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  const breakdownRange = React.useMemo(() => {
    if (!props.data) {
      return null
    }

    let firstDate: Date | undefined
    let lastDate: Date | undefined

    for (const key in props.data) {
      if (key === 'total') {
        continue
      }

      // @ts-expect-error
      const array = props.data[key] as
        | IndividualActivityData[]
        | IndividualEarningData[]

      if (!array || array.length < 1) {
        continue
      }

      const first = array[0]
      const last = array[array.length - 1]

      const _firstDate = new Date(first.date)
      const _lastDate = new Date(last.date)

      if (!firstDate || firstDate > _firstDate) {
        firstDate = _firstDate
      }

      if (!lastDate || lastDate < _lastDate) {
        lastDate = _lastDate
      }
    }

    if (!firstDate || !lastDate) {
      return ''
    }

    const dateFormat = 'MMMM yyyy'

    if (isSameMonth(firstDate, lastDate)) {
      return `(${format(firstDate, dateFormat)})`
    } else {
      const start = format(firstDate, dateFormat)
      const end = format(lastDate, dateFormat)

      return `(${start}\u2013${end})`
    }
  }, [props.data])

  const data = React.useMemo((): ChartData<'line'> => {
    if (isActivity) {
      const aggregates = new Map<number, AggregatedActivityData>()

      if (props.data) {
        for (const key in props.data) {
          const _key = key as keyof ActivityData
          const array = props.data[_key]

          for (const v of array) {
            // NOTE(intrnl): this is required because while the timestamp
            // format is using UTC, it's supposed to be the local date instead
            const [datestr] = v.date.split('T')
            const date = new Date(datestr + 'T00:00:00.000+07:00').getTime()

            let obj = aggregates.get(date)

            if (!obj) {
              aggregates.set(date, (obj = { date }))
            }

            obj[_key] = v.total
          }
        }
      }

      const values = Array.from(aggregates.values())
      values.sort((a, b) => a.date - b.date)

      return {
        labels: values.map((v) => v.date),
        datasets: [
          {
            label: 'Enrolled',
            data: values.map((v) => v.enrollments || 0),
            borderColor: '#4CAF50',
            backgroundColor: '#4CAF50',
            cubicInterpolationMode: 'monotone',
          },
          {
            label: 'Wishlisted',
            data: values.map((v) => v.wishlists || 0),
            borderColor: '#039BE5',
            backgroundColor: '#039BE5',
            cubicInterpolationMode: 'monotone',
          },
        ],
      }
    } else if (isEarnings) {
      const earnings = props.data?.earnings || []

      return {
        labels: earnings.map((e) => {
          const [datestr] = e.date.split('T')
          const date = new Date(datestr + 'T00:00:00.000+07:00').getTime()

          return date
        }),
        datasets: [
          {
            label: 'Earnings',
            data: earnings.map((e) => e.total_payment),
            backgroundColor: '#039BE5',
            borderColor: '#039BE5',
            cubicInterpolationMode: 'monotone',
          },
        ],
      }
    } else {
      return { datasets: [] }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data, ranges])

  const handleSelectChange = (ev: ChangeEvent<{ value: unknown }>) => {
    const value = ev.target.value as RangeType

    if (value !== RangeType.CUSTOM && onTypeChange) {
      onTypeChange(value)
    }
  }

  const handleCustomClick = () => {
    if (onTypeChange) {
      onTypeChange(RangeType.CUSTOM)
    }
  }

  return (
    <Paper className="overflow-hidden">
      <Box
        bgcolor={isEarnings ? 'primary.main' : undefined}
        className={`flex flex-col justify-between gap-4 px-6 py-4 xl:flex-row xl:items-center ${isEarnings ? 'text-white' : ''
          }`}
      >
        <div>
          <Typography
            color={!isEarnings ? 'primary' : undefined}
            className="font-bold"
          >
            {isEarnings ? 'Earnings by Time' : 'Activity'}
          </Typography>
          <Typography
            color={!isEarnings ? 'textSecondary' : undefined}
            className="text-sm"
          >
            {EarningLabels[rangeType]}{' '}
            {rangeType === RangeType.LIFETIME ? (
              breakdownRange !== null ? (
                breakdownRange
              ) : (
                <Skeleton width={228} inline />
              )
            ) : (
              `(${ranges.label})`
            )}
          </Typography>
        </div>

        <div className="flex items-center gap-4">
          {onReportClick && (
            <Button
              onClick={onReportClick}
              variant="outlined"
              color="inherit"
              className="h-9"
            >
              Download Report
            </Button>
          )}

          <Select
            value={rangeType}
            onChange={handleSelectChange}
            variant="outlined"
            className="box-border h-9 bg-white py-2 text-sm"
            classes={{ root: 'h-9 py-0 flex items-center' }}
          >
            <MenuItem value={RangeType.WEEK}>
              {EarningLabels[RangeType.WEEK]}
            </MenuItem>
            <MenuItem value={RangeType.MONTH}>
              {EarningLabels[RangeType.MONTH]}
            </MenuItem>
            <MenuItem value={RangeType.YEAR}>
              {EarningLabels[RangeType.YEAR]}
            </MenuItem>
            <MenuItem value={RangeType.LIFETIME}>
              {EarningLabels[RangeType.LIFETIME]}
            </MenuItem>
            <MenuItem value={RangeType.CUSTOM} onClick={handleCustomClick}>
              {EarningLabels[RangeType.CUSTOM]}
            </MenuItem>
          </Select>
        </div>
      </Box>

      {isEarnings && (
        <Box
          bgcolor="primary.main"
          className="flex justify-center px-6 pb-4 text-white"
        >
          {/* <span className="text-2xl font-bold">
            {props.data ? (
              // NOTE(intrnl): TypeScript would throw an error here if we had used
              // object destructuring for props.
              convertToRupiah(
                props.data.earnings.reduce((a, x) => a + x.total_payment, 0),
                true
              )
            ) : (
              <Skeleton width={178} />
            )}
          </span> */}
        </Box>
      )}

      {isActivity && <Divider />}

      <div className="relative py-4 px-6">
        <Line
          ref={chartRef}
          options={{
            plugins: {
              // @ts-expect-error
              title: false,
              // @ts-expect-error
              legend: false,
              tooltip: {
                // enabled: false,
                mode: 'index',
                intersect: false,
                position: 'nearest',
                displayColors: false,
                padding: 8,
                titleFont: {
                  size: 14,
                },
                bodyFont: {
                  size: 14,
                },
                callbacks: {
                  label: () => '',
                  beforeBody: (items) => {
                    const item = items[0]
                    const idx = item.dataIndex

                    return data.datasets.map(
                      (el) =>
                        el.label +
                        ': ' +
                        formatCompactNominal(el.data[idx] as number)
                    )
                  },
                },
                // external: setTooltipData,
                // // @ts-expect-error
                // animation: false,
              },
            },
            scales: {
              x: {
                offset: true,
                type: 'time',
                min:
                  rangeType === RangeType.LIFETIME
                    ? (data.labels?.[0] as string | undefined)
                    : ranges.startDate,
                max: ranges.actualEndDate,
                time: {
                  tooltipFormat: 'MMMM d, yyyy',
                  unit: getUnit(rangeType, ranges),
                },
              },
              y: {
                offset: true,
                suggestedMin: isEarnings ? 100000 : 0,
                suggestedMax:
                  data.datasets.length < 1 || data.labels!.length < 1
                    ? isEarnings
                      ? 1000000
                      : 5
                    : data.datasets.length || data.labels!.length,
                ticks: {
                  precision: 0,
                  callback: (value) => formatCompactNominal(value as number),
                },
              },
            },
          }}
          data={data}
        />
      </div>

      {isActivity && (
        <div className="flex items-center justify-center gap-12 p-6 pt-0 text-sm">
          {data.datasets.map((dataset, idx) => (
            <div key={idx} className="flex items-center gap-4">
              <div
                className="h-4 w-4 rounded"
                style={{ backgroundColor: dataset.backgroundColor as string }}
              />
              <span>{dataset.label}</span>
            </div>
          ))}
        </div>
      )}
    </Paper>
  )
}

export default TimeChartCard
