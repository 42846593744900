import React from 'react'
import {createStyles, makeStyles, Typography, Icon} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'

import {ILessonQuestion, IAnswerProps} from '../../../../../../interfaces'

import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'

type QuizLessonCheckBoxProps = {
  data: ILessonQuestion

  isDecrementQuestionNumberAvailable: boolean
  isIncrementQuestionNumberAvailable: boolean
  isResult: boolean
  answers: IAnswerProps[]
  setAnswers: (answers: IAnswerProps[]) => void
}

type CheckBoxQuestionProps = {
  classes: ClassNameMap
  data: ILessonQuestion
  index: number
  choice: string
  questionExistInAnswers: IAnswerProps | undefined
  setAnswers: (answers: IAnswerProps[]) => void
  answers: IAnswerProps[]
  isResult: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    styledCheckbox: {
      cursor: 'pointer',
      paddingLeft: '1rem',
      paddingTop: '1.3rem',
    },
    activeCheckboxColor: {
      backgroundColor: '#034A62',
    },
    activeCheckbox: {
      height: 28,
      width: 28,
      border: '2px solid #034A62',
      borderRadius: 3,

      cursor: 'pointer',
      backgroundColor: '#034A62',
    },
    inActiveCheckbox: {
      height: 28,
      width: 28,
      border: '2px solid #a9a8a8',
      borderRadius: 3,

      cursor: 'pointer',
      backgroundColor: '#FFF',
    },
  })
)

const CheckBoxQuestion = (props: CheckBoxQuestionProps) => {
  const {
    classes,
    index,
    choice,
    questionExistInAnswers,
    setAnswers,
    answers,
    data,
  } = props

  return (
    <div className={classes.styledCheckbox} key={'checkbox' + index}>
      <div className="flex flex-row items-center">
        <div
          className={
            questionExistInAnswers?.answers.includes(choice)
              ? classes.activeCheckbox
              : classes.inActiveCheckbox
          }
          onClick={() => {
            if (!props.isResult) {
              if (questionExistInAnswers) {
                // TODO: exist
                const indexExistingAnswer = answers.findIndex(
                  (item: IAnswerProps) => item.questionId === data.id
                )

                const _answers = [...answers]

                const answersController = questionExistInAnswers.answers.find(
                  (answer: string) => answer === choice
                )

                _answers[indexExistingAnswer] = {
                  questionId: questionExistInAnswers.questionId,
                  answers: answersController
                    ? questionExistInAnswers.answers.filter(
                        (answer: string) => answer !== choice
                      )
                    : [...questionExistInAnswers.answers, choice],
                }

                props.setAnswers(_answers)
              } else {
                // TODO: not exist
                setAnswers([
                  ...answers,
                  {questionId: data.id, answers: [choice]},
                ])
              }
            }
          }}
        >
          <Icon
            className="text-white font-extrabold"
            hidden={!questionExistInAnswers?.answers.includes(choice)}
          >
            done
          </Icon>
        </div>
        <Typography className="ml-2">{choice}</Typography>
      </div>
    </div>
  )
}

const QuizLessonCheckBox = (props: QuizLessonCheckBoxProps) => {
  const classes = useStyles()
  const id = props.data.id
  const answerKey = props.data?.answerKeys
  const currentAnswer = props.answers.find((data) => data.questionId === id)

  const [questionExistInAnswers, setQuestionExistInAnswers] =
    React.useState<IAnswerProps>()

  React.useEffect(() => {
    setQuestionExistInAnswers(
      props.answers.find(
        (answer: IAnswerProps) => answer.questionId === props.data.id
      )
    )
  }, [props.data.id, props.answers])

  const correction = (choice: string) => {
    if (answerKey) {
      const isCorretChoice = answerKey.includes(choice)

      if (currentAnswer?.answers.includes(choice)) {
        if (isCorretChoice) {
          return <CheckIcon className="mt-6 ml-4" style={{color: '#4CAF50'}} />
        } else {
          return <ClearIcon className="mt-6 ml-4" style={{color: '#DC004E'}} />
        }
      } else {
        if (isCorretChoice) {
          return <CheckIcon className="mt-6 ml-4" style={{color: '#4CAF50'}} />
        }
      }
    }
  }

  return (
    <>
      <div className="pb-36">
        {props.data.choices.map((choice: string, index: number) => (
          <div className="flex flex-row" key={index}>
            <CheckBoxQuestion
              classes={classes}
              data={props.data}
              index={index}
              choice={choice}
              questionExistInAnswers={questionExistInAnswers}
              setAnswers={props.setAnswers}
              answers={props.answers}
              isResult={props.isResult}
            />
            {props.isResult && correction(choice)}
          </div>
        ))}
      </div>
    </>
  )
}

export default QuizLessonCheckBox
