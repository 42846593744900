import gql from 'graphql-tag'

export const GET_RECOMMEND_CATEGORY_MICROLEARNING = gql`
  query ($userId: uuid!, $offset: Int, $limit: Int) {
    academy_course_categories_aggregate(
      where: {
        microlearnings: {microlearning_members: {user_id: {_eq: $userId}}}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_course_categories(
      where: {
        microlearnings: {microlearning_members: {user_id: {_eq: $userId}}}
      }
      offset: $offset
      limit: $limit
    ) {
      id
      name
    }
  }
`
