import React, { ChangeEvent, useState } from 'react'
import { Filter } from '@smartasn/wlb-utils-components'
import { LearnerSidebar } from '../../../components/learner'
import {
  CustomTypography,
  StyledTabsWhite,
} from '../../../components/utils-components/GlobalStyles'
import {
  Tab,
  InputAdornment,
  IconButton,
  makeStyles,
  FormControl,
  MenuItem,
  Select,
  Button,
  Icon,
  Typography,
} from '@material-ui/core'

import {
  FormControlSearch,
  TextFieldSearch,
} from '../../../components/filter/FilterStyles'
import { IFilter } from '../../../interfaces/Filter.interface'
import { LearnerCourseProvider } from '../../../provider/LearnerCourse.provider'
import { listFilter } from '../../../config/filter/DefaultFilter.config'
import { mapFilterResults } from '../../../utils/helpers'
import MyCourseCertificateComponent from './tabs/CourseCertificate.component'
import useDebounce from '../../../hooks/useDebounce'
import { ICertificateFilterData } from '../../../interfaces'

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingTop: 10,
    border: 'none',
    color: '#014a62',
  },

  selectIcon: {
    color: '#000000',
  },

  input: {
    '&::placeholder': {
      fontWeight: 'bolder',
      color: '#014a62',
    },
  },
}))

export default function MyCertificatePage() {
  const [tabValue, setTabValue] = useState(0)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sort, setSort] = useState<string>('date-desc')
  const [filterData, setFilterData] = useState<ICertificateFilterData>({})
  const debouncedSearchTerm: string = useDebounce<string>(searchTerm, 500)

  const classes = useStyles()

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <LearnerCourseProvider>
            <MyCourseCertificateComponent
              search={debouncedSearchTerm}
              sort={sort}
              filterData={filterData}
            />
          </LearnerCourseProvider>
        )
      default:
        return true
    }
  }

  const mapFilterData = (result: IFilter) => {
    const mappedResult = mapFilterResults(result[0])
    setFilterData(mappedResult)
  }

  return (
    <div className="bg-[#fafafa]">
      <LearnerSidebar>
        <div className="px-5">
          <CustomTypography fsize="20px" fweight="bold">
            Sertifikat Saya
          </CustomTypography>
          <div className="mt-5">
            <FormControlSearch variant="outlined">
              <TextFieldSearch
                placeholder="Cari Sertifikat saya"
                name="search_filter"
                autoComplete="off"
                onChange={(event: ChangeEvent<HTMLInputElement>) =>
                  setSearchTerm(event.target.value)
                }
                setSearchTerm
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.root,
                    input: classes.input,
                  },
                  startAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end">
                        <Icon style={{ color: '#014a62' }} className="mr-4">
                          search_icon
                        </Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlSearch>
          </div>
          <div className="mt-6">
            <StyledTabsWhite
              value={tabValue}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleChange}
            >
              <Tab label="Kursus" />
            </StyledTabsWhite>
          </div>

          <FormControl className="mt-8 flex flex-row justify-between">
            <Select
              variant="standard"
              disableUnderline={true}
              value={sort}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              onChange={(event: ChangeEvent<{ value: unknown }>) =>
                setSort(event.target.value as string)
              }
            >
              <MenuItem value="date-desc">Terbaru</MenuItem>
              <MenuItem value="date-asc">Terlama</MenuItem>
              <MenuItem value="name-asc">Nama (A-Z)</MenuItem>
              <MenuItem value="name-desc">Nama (Z-A)</MenuItem>
            </Select>

            <Filter
              language="indonesian"
              onApply={mapFilterData}
              listFilter={listFilter}
              includeExclude={false}
              options={{
                isSetActiveMenuWhenOpen: true,
                setActiveMenuToIndex: 0,
                delayResetMenu: 500,
              }}
            >
              <Button size="large" variant="outlined" className="bg-white">
                <Icon color="primary" className="mr-4">
                  filter_list
                </Icon>
                <Typography className="font-bold">Saring</Typography>
              </Button>
            </Filter>
          </FormControl>

          <div className="py-10">{renderContent()}</div>
        </div>
      </LearnerSidebar>
    </div>
  )
}
