import React, {type ChangeEvent} from 'react'
import {useSearchParams, useNavigate} from 'react-router-dom'
import {useTitle} from 'hoofd'

import {
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import LinesEllipsis from 'react-lines-ellipsis'

const learnerData = [
  {
    id: 0,
    name: 'Mira Bergson',
    position: 'Visual Designet at R&D Office WorkLifeAnd Beyond',
    email: 'Livia.Herwitz@gmail.com',
    total: 20,
  },
  {
    id: 1,
    name: 'Zain Korsgaard',
    position: 'Visual Designet at R&D Office WorkLifeAnd Beyond',
    email:
      'KaylynnRhielMadsenNorthAndreasWilsosafasfdfafasfafasfnCroch.work@example.com',
    total: 16,
  },
  {
    id: 2,
    name: 'Phillip Gouse',
    position: 'Visual Designet at R&D Office WorkLifeAnd Beyond',
    email: 'Jaxson.Philips@gmail.com',
    total: 5,
  },
  {
    id: 3,
    name: 'Cristofer Vetrovs',
    position: 'Visual Designet at R&D Office WorkLifeAnd Beyond',
    email: 'Emerson.Bothman@gmail.com',
    total: 30,
  },
  {
    id: 4,
    name: 'Craig Vaccaro',
    position: 'Craig Vaccaro',
    email: 'Kianna.Lubin@gmail.com',
    total: 2,
  },
]

const headerData = [
  {id: 0, name: '', width: '10%', textAlign: 'text-left'},
  {id: 1, name: 'Name', width: '30%', textAlign: 'text-left'},
  {id: 2, name: 'Email', width: '30%', textAlign: 'text-left'},
  {id: 3, name: 'Total Catalog', width: '12%', textAlign: 'text-center'},
  {id: 4, name: 'Action', width: '10%', textAlign: 'text-center'},
]

interface LearnerProps {
  data: {
    id: number
    name: string
    position: string
    email: string
    total: number
  }
}

const Item = (props: LearnerProps) => {
  const {data} = props
  const navigate = useNavigate()
  return (
    <TableRow className="focus:bg-[#f5f5f5] hover:bg-[#f5f5f5] transition">
      <TableCell className="border-none">
        <img
          src={require('../../../assets/images/learner-img.png')}
          alt="learner-thumbnail"
        />
      </TableCell>
      <TableCell className="border-none">
        <div className="flex flex-col gap-2">
          <LinesEllipsis
            text={data.name}
            className="font-medium text-base overflow-hidden"
            maxLine={2}
          />

          <LinesEllipsis
            text={data.position}
            className="text-xs text-gray-400 overflow-hidden"
            maxLine={2}
          />
        </div>
      </TableCell>

      <TableCell className="border-none">
        <div className="flex flex-col gap-2">
          {/* <div className=""> */}
          <LinesEllipsis
            text={data.email}
            className="font-medium text-base overflow-hidden"
            maxLine={1}
          />
          {/* </div> */}
        </div>
      </TableCell>
      <TableCell className="border-none">
        <div className="flex flex-col gap-0.5">
          <div className="font-medium text-base text-center">{data.total}</div>
        </div>
      </TableCell>
      <TableCell className="border-none text-center">
        <button
          className="text-xs bg-transparent text-[#039be5]"
          onClick={() => navigate('/contributor/learner/detail')}
        >
          Detail
        </button>
      </TableCell>
    </TableRow>
  )
}

const ContributorLearnerPage = () => {
  useTitle('Contributor - Learner')

  const [searchParams, setSearchParams] = useSearchParams()

  const searchTimeoutRef = React.useRef<any>()

  const search = searchParams.get('query') ?? ''
  const orderBy = searchParams.get('order') ?? 'desc'

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, {replace: true})
    }, 500)
  }

  const handleOrderByChange = (
    ev: ChangeEvent<{name?: string; value: unknown}>
  ) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('order', ev.target.value as string)

    clearTimeout(searchTimeoutRef.current)
    setSearchParams(next, {replace: true})
  }

  return (
    <Paper>
      <div className="h-14 px-6 py-1 flex items-center">
        <Typography color="primary" className="font-bold">
          Learner (5)
        </Typography>
      </div>

      <Divider />

      <div className="px-6 pt-6 pb-4">
        <TextField
          key={search}
          placeholder="Search..."
          variant="outlined"
          fullWidth
          defaultValue={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="px-2 pb-4 flex justify-between items-center">
        <Select
          value={orderBy}
          onChange={handleOrderByChange}
          variant="standard"
          disableUnderline={true}
          SelectDisplayProps={{
            className:
              'text-sm pl-4 pr-6 py-2 focus:bg-[#f5f5f5] outline-none rounded transition',
          }}
        >
          <MenuItem value="#">Sorting</MenuItem>
          <MenuItem value="desc">Latest</MenuItem>
          <MenuItem value="asc">Oldest</MenuItem>
          <MenuItem value="highest">Highest Rating</MenuItem>
          <MenuItem value="lowest">Lowest Rating</MenuItem>
          <MenuItem value="most-enrolled">Most enrolled</MenuItem>
          <MenuItem value="fewest">Fewest enrolled</MenuItem>
        </Select>
      </div>
      <Divider />
      <TableContainer className="overflow-x-visible">
        <Table className="table-fixed">
          <TableHead>
            <TableRow>
              {headerData.map((res) => (
                <TableCell
                  key={res.id}
                  style={{width: res.width}}
                  className={res.textAlign}
                >
                  {res.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {learnerData.map((item) => {
              return <Item data={item} key={item.id} />
            })}
          </TableBody>
        </Table>
        <Divider />
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={0}
          rowsPerPage={10}
          page={0}
          onPageChange={() => console.info(1)}
          onRowsPerPageChange={() => console.info(2)}
        />
      </TableContainer>
    </Paper>
  )
}

export default ContributorLearnerPage
