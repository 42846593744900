// import React from 'react'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Badge,
  makeStyles,
} from '@material-ui/core'
import { NavLink } from 'react-router-dom'
// import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
// import RestoreIcon from '@material-ui/icons/Restore'
import {
  OLD_LEARNING_APP_URL,
  ROLES,
  USER_ID,
  // VENDOR_URL,
} from '../../utils/globals'
import { GET_NOTIF } from '../../graphql/queries/getNotifCount.query'
import { useQuery } from '@apollo/client'

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.primary.main,
    minWidth: 'unset',
    marginRight: theme.spacing(1),
  },
  label: {
    fontWeight: 700,
    fontSize: theme.spacing(1.75),
  },
  badge: {
    transform: 'unset',
    position: 'unset',
  },
}))

function NavItem(props: any) {
  const { icon, to, count = 0, children } = props

  const styles = useStyles()

  const noIconStyle = {
    paddingTop: '0px',
    paddingBottom: '0px',
  }

  return (
    <ListItem
      component={NavLink}
      to={to}
      button
      style={!icon ? noIconStyle : {}}
    >
      {icon && <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>}
      <ListItemText primary={children} classes={{ primary: styles.label }} />
      {count > 0 && (
        <Badge
          badgeContent={count}
          color="error"
          classes={{ badge: styles.badge }}
        />
      )}
    </ListItem>
  )
}

// type TNavCrossItem = {
//   link: string
//   title: string
//   icon?: React.ReactNode
//   count?: number
// }

// const NavCrossItem = (props: TNavCrossItem) => {
//   const { link, title, icon, count = 0 } = props
//   const styles = useStyles()

//   return (
//     <div className="flex px-4 justify-between hover:bg-[#0000000a] cursor-pointer">
//       <div className="flex">
//         {icon || ''}
//         <a className="text-[14px] block font-bold w-36" href={link}>
//           {title}
//         </a>
//       </div>
//       {icon && count > 0 && (
//         <Badge
//           badgeContent={count}
//           color="error"
//           classes={{ badge: styles.badge }}
//         />
//       )}
//     </div>
//   )
// }

const UserNavigationComponent = () => {
  const { data } = useQuery(GET_NOTIF, {
    variables: {
      user: USER_ID,
    },
  })
  const userData = ROLES
  const findRole = userData.find((role: string) => role === 'instructor')
  return (
    <List
      className="flex flex-col gap-2"
      component="nav"
      aria-label="navigation"
    >
      {/* <NavCrossItem
        link={`${VENDOR_URL}/profile/shopping-cart`}
        title="Keranjang Belanja"
        icon={<ShoppingCartOutlinedIcon color="primary" className="mr-2" />}
        count={data?.cart?.aggregate?.count}
      /> */}
      <NavItem
        to="/learner/wishlist/"
        icon={<FavoriteBorderIcon />}
        count={data?.wishlist?.aggregate?.count}
      >
        Daftar Keinginan
      </NavItem>
      {/* <NavCrossItem
        link={`${VENDOR_URL}/transaction`}
        title="Transaksi"
        icon={<RestoreIcon color="primary" className="mr-2" />}
      /> */}
      <div className="mt-2" />
      <NavItem to="/">Katalog Pembelajaran</NavItem>
      {findRole ? (
        <NavItem to="/contributor">Dasbor Kontributor</NavItem>
      ) : (
        <a
          className="text-[14px] block m-0 hover:bg-[#0000000a] px-4 font-bold py-1"
          href={`${OLD_LEARNING_APP_URL}/instructor/become`}
        >
          Menjadi Kontributor Pembelajaran
        </a>
      )}
      {/* <NavItem to="/">Peringkat</NavItem> */}
      <NavItem to="/learner/my-learning/">Pembelajaran Saya</NavItem>
      <NavItem to="/learner/my-certificate">Sertifikat Saya</NavItem>
    </List>
  )
}

export default UserNavigationComponent
