import gql from 'graphql-tag'

export const GET_RECOMMENDED_TRENDING_CLASSROOM = gql`
  query (
    $offset: Int, 
    $limit: Int, 
    $category_id: [Int], 
    $isRecommended: Boolean, 
    $sortByTrending: Boolean
  ) {
    getListClassroom(
      isRecommended: $isRecommended
      sortByTrending: $sortByTrending
      category_id: $category_id
      offset: $offset
      limit: $limit
      showJoined: $showJoined
      rating: $rating
      min_price: $min_price
      max_price: $max_price
      free: $free
      paid: $paid
      search: $search
    ) {
      count
      classrooms {
        id
        name
        description
        category_id
        price
        avatar_url
        average_review
        review_count
        is_finished
        owner {
          id
          avatar
          name
          email
        }
        members {
          id
          is_admin
          is_mentor
          user {
            id
            name
            avatar
          }
        }
        privacy
      }
    }
  }
`
