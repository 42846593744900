import gql from 'graphql-tag'

export const GET_LIST_CLASSROOM_CATEGORIES = gql`
  query {
    academy_course_categories(
      where: {parent: {_is_null: true}, deletedAt: {_is_null: true}}
    ) {
      id
      name
    }
  }
`

export const GET_LIST_CLASSROOM_SUB_CATEGORIES = gql`
  query ($parent: Int) {
    academy_course_categories(
      where: {parent: {_eq: $parent}, deletedAt: {_is_null: true}}
    ) {
      id
      name
    }
  }
`

export const GET_LIST_MENTOR = gql`
  query ($offset: Int, $limit: Int, $searchQuery: String) {
    global_users(
      offset: $offset
      limit: $limit
      where: {
        name: {_ilike: $searchQuery}
        contributor_label: {_is_null: false}
      }
    ) {
      id
      name
      avatar
      contributor_label
      people_work_placements {
        id
        company_job_profile {
          id
          position
          title
        }
      }
    }
  }
`
