import React from 'react'

import ReactJWPlayer from 'react-jw-player'
import Skeleton from 'react-loading-skeleton'

import {IconButton} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import EditIcon from '@material-ui/icons/Edit'

import {GET_COURSE_VIDEO_PREVIEW} from '../../../provider/services/Course.service'

export interface JWVideoPreviewProps {
  src: string
  slug?: string
  onChange?: () => void
}

const JWVideoPreview = (props: JWVideoPreviewProps) => {
  const {src, slug, onChange} = props

  const playerId = 'preview' + src

  const {data} = GET_COURSE_VIDEO_PREVIEW(slug, src)

  const playerRef = React.useRef<any>()
  const [isReady, setIsReady] = React.useState(false)
  const [isPlaying, setIsPlaying] = React.useState(false)

  const handleReady = () => {
    const player = (window as any).jwplayer(playerId)

    playerRef.current = player
    setIsReady(true)
  }

  const handlePlay = () => {
    const player = playerRef.current!

    player.play()
    player.setControls(true)
    setIsPlaying(true)
  }

  const handlePause = () => {
    const player = playerRef.current!

    if (!player.getFullscreen()) {
      player.setControls(false)
    }

    setIsPlaying(false)
  }

  if (!data || !data.url) {
    return <Skeleton className="rounded h-full w-full" />
  }

  return (
    <div className="rounded overflow-hidden relative">
      <ReactJWPlayer
        playerId={playerId}
        playerScript={'https://cdn.jwplayer.com/libraries/1gQfwd2J.js'}
        playlist={data.url.playlist}
        onReady={handleReady}
        onPause={handlePause}
        onOneHundredPercent={handlePause}
        style={{height: '100%', width: '100%'}}
        customProps={{
          displaytitle: false,
          displaydescription: false,
          controls: false,
        }}
      />

      {isReady && !isPlaying && (
        <div className="group flex items-center justify-center gap-4 absolute z-[1] inset-0 rounded-none transition-colors bg-black bg-opacity-0 hover:bg-opacity-50 focus-within:bg-opacity-50">
          <IconButton
            title="Play video"
            onClick={handlePlay}
            className="text-white invisible group-hover:visible group-focus-within:visible"
          >
            <PlayArrowIcon />
          </IconButton>

          {onChange && (
            <IconButton
              title="Replace video"
              onClick={onChange}
              className="text-white invisible group-hover:visible group-focus-within:visible"
            >
              <EditIcon />
            </IconButton>
          )}
        </div>
      )}
    </div>
  )
}

export default JWVideoPreview
