import React, {FC} from 'react'
import {LearningType} from '../../../../utils/enum'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import {Button} from '@material-ui/core'
import {TContributorSection} from '../../../../interfaces/Contributor.interface'
import {LIST_CONTRIBUTOR_BOOKS} from '../../../../provider/services/LearningContributor.service'
import {IBookList} from '../../../../interfaces'
import {useSearchParams} from 'react-router-dom'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCircular from '../../../loading/LoadingCircular.component'

const _buttonBook = [
  {
    id: '2',
    name: 'Buku Elektronik',
  },
  {
    id: '3',
    name: 'Buku Audio',
  },
]

const Book: FC<TContributorSection> = ({id, search, sort}) => {
  const [searchParams, setSearchParams] = useSearchParams()
  const handleActive = (_active: string) => {
    searchParams.set('type', _active)
    setSearchParams(searchParams)
  }

  const type = searchParams.get('type') ?? '2'

  const {data, loading} = LIST_CONTRIBUTOR_BOOKS(
    search,
    parseInt(type),
    id,
    sort
  )

  if (loading) {
    return <LoadingCircular height="500px" />
  }

  return (
    <>
      <div className="flex mb-6">
        {_buttonBook.map((btn) => (
          <Button
            key={btn.id}
            variant="contained"
            color={type === btn.id ? 'primary' : 'default'}
            className="mr-6"
            size="large"
            onClick={() => handleActive(btn.id)}
          >
            {btn.name}
          </Button>
        ))}
      </div>
      {data?.academy_books.length === 0 ? (
        <div className="flex flex-col justify-center" style={{height: 500}}>
          <NoListComponent
            title="Maaf, tidak ada daftar"
            subTitle={`${
              type === '2' ? 'Buku Elektronik' : 'Buku Audio'
            } tidak ditemukan`}
          />
        </div>
      ) : (
        <div className="flex flex-wrap -mx-3">
          {data?.academy_books.map((item: IBookList, i: number) => (
            <LearnerCatalogCard
              type={LearningType.BOOK}
              item={item}
              key={i}
              hidePrice={false}
            />
          ))}
        </div>
      )}
    </>
  )
}

export default Book
