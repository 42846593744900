import {gql} from '@apollo/react-hooks'

export const GET_CURRICULUM_COURSE_LESSONS = gql`
  query ($userId: uuid!, $courseId: Int) {
    user: global_users_by_pk(id: $userId) {
      id
      name
      avatar
    }
    lessons: academy_course_lessons(
      where: {course_id: {_eq: $courseId}, deletedAt: {_is_null: true}}
      order_by: [{academy_course_section: {order: asc}}, {order: asc}]
    ) {
      id
      title
    }
  }
`

export const GET_COURSE_COMMENT_CONTEXT = gql`
  query ($courseId: Int!, $lessonId: Int!) {
    course: academy_courses_by_pk(id: $courseId) {
      id
      global_user {
        id
        name
        avatar
      }
    }
    lesson: academy_course_lessons_by_pk(id: $lessonId) {
      id
      title
    }
  }
`

export const GET_COURSE_COMMENTS = gql`
  query getCourseComments(
    $userId: uuid!
    $where: academy_discussions_bool_exp
    $order: [academy_discussions_order_by!]
    $limit: Int
    $offset: Int
  ) {
    total: academy_discussions_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    comments: academy_discussions(
      where: $where
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      id
      fulltext
      date_added
      count_helpful
      count_response: academy_discussion_responses_aggregate(
        where: {deletedAt: {_is_null: true}}
      ) {
        aggregate {
          count
        }
      }
      lesson: academy_course_lesson {
        id
        title
      }
      self_helpful: academy_discussion_helpfuls(
        where: {user_id: {_eq: $userId}}
      ) {
        id
        user_id
      }
      user: global_user {
        id
        name
        email
        biography
        roles
        avatar
        placements: people_work_placements(
          limit: 1
          where: {status: {_eq: "ACTIVE"}}
        ) {
          id
          company_profile {
            id
            brand_name
          }
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`

export const GET_COURSE_COMMENTS_REPLIES = gql`
  query (
    $userId: uuid!
    $discussionId: Int!
    $order: [academy_discussion_responses_order_by!]
    $limit: Int
    $offset: Int
  ) {
    responses: academy_discussion_responses(
      where: {discussion_id: {_eq: $discussionId}, deletedAt: {_is_null: true}}
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      id
      fulltext
      date_added
      count_helpful
      self_helpful: academy_discussion_helpfuls(
        where: {user_id: {_eq: $userId}}
      ) {
        id
        user_id
      }
      user: global_user {
        id
        name
        email
        biography
        roles
        avatar
        placements: people_work_placements(
          limit: 1
          where: {status: {_eq: "ACTIVE"}}
        ) {
          id
          company_profile {
            id
            brand_name
          }
          company_job_profile {
            id
            title
          }
        }
      }
    }
  }
`
