import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import TabRow from '../../catalog/tabs/TabRow.component'
import {LearningType} from '../../../../utils/enum'

import {LIST_TOP_LEARNING_CONTRIBUTOR} from '../../../../provider/services/LearningContributor.service'
import Skeleton from 'react-loading-skeleton'
import {useSnackbar} from 'notistack'

type LearningContributorSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: {
    rating: string
    categoriesREST: string
    levelsREST: string
    languagesREST: string
    priceMin: string
    priceMax: string
    priceGQL: {
      _lte: number
      _gte: number
    }
    categoriesGQL: []
    ratingGQL: []
    contributorLabels: []
  }
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  contributorFilter: string
  activeTab: LearningType
}

type IndividualContributorProps = {
  total_catalog_count: number
  total_learner_count: number
  total_rating_avg: number
  total_rating_count: number
  global_user: {
    name: string
    avatar: string
  }
}

function LearningContributorSearchFilterTab(
  props: LearningContributorSearchFilterTabProps
) {
  const {countResult, setCountResult} = props
  const {enqueueSnackbar} = useSnackbar()

  const {
    data: dataListAllContributor,
    loading: loadingListAllContributor,
    error: errorListAllContributor,
    refetch: refetchListAllContributor,
  } = LIST_TOP_LEARNING_CONTRIBUTOR({
    limit: -1,
    offset: 0,
    contrib_labels: props.academicFilter.contributorLabels,
    queryName: props.searchKeyword,
    ratingGte: Number(props.academicFilter.rating),
  })

  React.useEffect(() => {
    if (
      dataListAllContributor?.countFilter?.aggregate?.count !==
      countResult.countResultContributor
    ) {
      setCountResult({
        ...countResult,
        countResultContributor:
          dataListAllContributor?.countFilter?.aggregate?.count,
      })
    }
  }, [
    dataListAllContributor,
    countResult,
    setCountResult,
    errorListAllContributor,
  ])

  React.useEffect(() => {
    if (refetchListAllContributor) {
      refetchListAllContributor()
    }
  }, [
    props.searchKeyword,
    props.academicFilter,
    props.priceFilter,
    refetchListAllContributor,
    props.activeTab,
  ])

  if (loadingListAllContributor) {
    return <Skeleton count={4} className="my-2" width={'100%'} />
  } else if (errorListAllContributor) {
    enqueueSnackbar(JSON.stringify(errorListAllContributor), {
      variant: 'error',
    })
  }

  const contributorData = [
    ...dataListAllContributor.learning_contributor_ratings,
  ]

  return (
    <Grid container className="px-2 pb-2">
      <TabRow
        title={''}
        data={
          props.contributorFilter === 'Rating (Low - High)'
            ? contributorData.sort(
                (
                  a: IndividualContributorProps,
                  b: IndividualContributorProps
                ) => a.total_rating_avg - b.total_rating_avg
              )
            : contributorData.sort(
                (
                  a: IndividualContributorProps,
                  b: IndividualContributorProps
                ) => b.total_rating_avg - a.total_rating_avg
              )
        }
        type={LearningType.LEARNING_CONTRIBUTOR}
        isAll={true}
        isFilterCatalog={true}
      />
    </Grid>
  )
}

export default LearningContributorSearchFilterTab
