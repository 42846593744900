import {makeStyles} from '@material-ui/core'
import {useTitle} from 'hoofd'
import React from 'react'
import {useParams} from 'react-router-dom'
import Header from '../../components/header/HeaderComponent'
import LearnerContributorBody from '../../components/learner/contributor/Body.component'
import LearnerContributorHeader from '../../components/learner/contributor/Header.component'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/GlobalStyles'
import {DETAIL_CONTRIBUTOR_HEADER} from '../../provider/services/LearningContributor.service'
import LoadingCircular from '../../components/loading/LoadingCircular.component'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const useStyles = makeStyles(() => ({
  navLink: {
    color: '#95989a',
    fontWeight: 600,
    display: 'block',
    padding: '10px 46px',
    fontSize: 14,
    margin: '0 -8px',
  },
}))

const Contributor: React.FC<{}> = () => {
  const classes = useStyles()
  const {contribId} = useParams()

  const {data, loading} = DETAIL_CONTRIBUTOR_HEADER(contribId)

  useTitle('Rincian Kontributor Pembelajaran')

  if (loading) {
    return <LoadingCircular />
  }

  return (
    <div style={{background: '#fafafa'}}>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'detail-contributor'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab
            label="Rincian Kontributor Pembelajaran "
            value="detail-contributor"
          />
        </StyledTabs>
      </TabsWrapper>
      <div className="px-4 md:px-20 py-4 md:py-8">
        <LearnerContributorHeader data={data} />
        <LearnerContributorBody classes={classes} />
      </div>
    </div>
  )
}

export default Contributor
