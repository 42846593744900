import React, {FC, ReactElement} from 'react'
import {Typography} from '@material-ui/core'
import {Favorite, FavoriteBorder, Share} from '@material-ui/icons'
import ShareCatalogItemDialog from '../dialog/ShareCatalogItemDialog.component'
import DisplayRatingStar from '../../../review-rating/DisplayRatingStar.component'

type ItemInformationDetailCardProps = {
  addtoWishlist: () => void
  authorWritten: string
  averageRating?: number
  countReviews?: number
  removeFromWishlist: () => void
  subtitle?: string
  thumbnail: string
  title: string
  totalStudentEnrolled?: number
  wishlist?: boolean
  actionButton?: ReactElement
  isAudioBook: Boolean
}

const ItemInformationDetailCard: FC<ItemInformationDetailCardProps> = ({
  addtoWishlist,
  authorWritten,
  averageRating,
  countReviews,
  removeFromWishlist,
  subtitle,
  thumbnail,
  title,
  totalStudentEnrolled,
  wishlist,
  actionButton,
  isAudioBook,
}: ItemInformationDetailCardProps): ReactElement => {
  const [isListed, setIsListed] = React.useState<boolean>(Boolean(wishlist))
  const [shareData, setShareData] = React.useState({
    isOpen: false,
    copyStatus: false,
  })

  const shareableURL =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname

  const wishlistToggle = (): void => {
    try {
      if (isListed) removeFromWishlist()
      else addtoWishlist()

      setIsListed(!isListed)
    } catch (error) {
      console.error(`Error: ${error}`)
    }
  }

  React.useEffect(() => {
    setIsListed(Boolean(wishlist))
  }, [wishlist])

  return (
    <div className="flex flex-row space-x-6 h-full">
      <div className="shrink-0">
        <img className="h-64 w-full object-cover" src={thumbnail} alt="book" />
      </div>

      <div className="grow">
        <div className="flex flex-col h-full space-y-2">
          <Typography
            variant="h5"
            color="primary"
            className="whitespace-normal font-bold"
          >
            {title}
          </Typography>

          {subtitle && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              className="pb-2"
            >
              {subtitle}
            </Typography>
          )}

          <Typography variant="subtitle1" color="textSecondary">
            Oleh {authorWritten || <i>unknown</i>}
          </Typography>

          <div className="flex flex-row w-96">
            <DisplayRatingStar count={averageRating || 0} starSize="medium" />
            <Typography className="ml-3 font-extrabold text-base text-gray-700">
              {`${averageRating?.toFixed(1)} (${countReviews})`}
            </Typography>

            {totalStudentEnrolled && (
              <div className="flex flex-row ml-6">
                <Typography variant="caption" className="font-extrabold">
                  {totalStudentEnrolled}
                </Typography>

                <Typography
                  variant="caption"
                  className="ml-1 font-thin"
                  color="textSecondary"
                >
                  Siswa Terdaftar
                </Typography>
              </div>
            )}
          </div>

          <div className="flex space-x-4 pt-2">
            {wishlist !== undefined && (
              <div className="flex cursor-pointer" onClick={wishlistToggle}>
                {isListed ? (
                  <Favorite color="primary" />
                ) : (
                  <FavoriteBorder color="primary" />
                )}

                <Typography
                  color="primary"
                  className="ml-3 font-extrabold text-base"
                >
                  {isListed && 'Hapus dari'} Daftar Keinginan
                </Typography>
              </div>
            )}

            <div
              className="flex cursor-pointer"
              onClick={() => setShareData({...shareData, isOpen: true})}
            >
              <Share color="primary" />
              <Typography
                color="primary"
                className="ml-3 font-extrabold text-base"
              >
                <b>Bagikan</b>
              </Typography>
            </div>
          </div>

          <div className="grow flex items-end">{actionButton ?? ''}</div>
        </div>

        {shareData?.isOpen && (
          <ShareCatalogItemDialog
            shareData={shareData}
            setShareData={setShareData}
            shareableUrl={shareableURL}
            title={title || ''}
            featureName={isAudioBook ? 'Buku Audio' : 'Buku Elektronik'}
          />
        )}
      </div>
    </div>
  )
}

export default ItemInformationDetailCard
