import React from 'react'
import {useField} from 'react-final-form'

import {
  FormHelperText,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@material-ui/core'

import {
  parseAsInt,
  showErrorOnBlur,
} from '../../../../../../components/input-forms/validators'
import {type SelectItem} from '../../../../../../components/input-forms/FormSelect'

export interface NotificationFieldProps {
  label?: string
}

const enum UnitType {
  MINUTES = 1,
  DAYS = 2,
  WEEKS = 3,
}

const UNIT_OPTIONS: SelectItem[] = [
  {value: '', label: 'Choose Time Unit', disabled: true},
  {value: UnitType.MINUTES, label: 'Minutes'},
  {value: UnitType.DAYS, label: 'Days'},
  {value: UnitType.WEEKS, label: 'Weeks'},
]

const UNIT_BLURB_LABEL = {
  [UnitType.MINUTES]: 'minutes',
  [UnitType.DAYS]: 'days',
  [UnitType.WEEKS]: 'weeks',
}

const NotificationField = (props: NotificationFieldProps) => {
  const {label} = props

  const {input: timeInput, meta} = useField(`notif_timer`, {
    validateFields: ['notif_unit_of_time'],
    parse: parseAsInt,
    validate: (value, context: any) => {
      if (value == null) {
        return `This field is required`
      }

      if (context && context.notif_unit_of_time == null) {
        return `The time unit must be specified`
      }
    },
  })

  const {input: unitInput} = useField(`notif_unit_of_time`, {
    parse: parseAsInt,
  })

  const {error, submitError} = meta
  const isError = showErrorOnBlur(meta)

  const renderedOptions = React.useMemo(() => {
    return UNIT_OPTIONS.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        disabled={option.disabled}
      >
        {option.label}
      </MenuItem>
    ))
  }, [])

  return (
    <div onFocus={timeInput.onFocus} onBlur={timeInput.onBlur}>
      {label && (
        <Typography
          component="label"
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <div className="flex gap-4">
        <OutlinedInput
          {...timeInput}
          error={isError}
          placeholder="Add Notification Time"
          className="text-sm"
        />

        <Select
          {...unitInput}
          error={isError}
          displayEmpty
          variant="outlined"
          className="text-sm"
        >
          {renderedOptions}
        </Select>
      </div>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : timeInput.value !== '' && unitInput.value !== '' ? (
        <FormHelperText>
          Notification will be received{' '}
          <span className="text-black">
            {timeInput.value} {UNIT_BLURB_LABEL[unitInput.value as UnitType]}
          </span>{' '}
          before event start
        </FormHelperText>
      ) : null}
    </div>
  )
}

export default NotificationField
