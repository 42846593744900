import gql from 'graphql-tag'

export const GET_PRICING_TIER = gql`
  query {
    academy_price_tier(
      where: {deletedAt: {_is_null: true}}
      order_by: [{pricing_idr: asc}, {id: asc}]
      distinct_on: [pricing_idr]
    ) {
      id
      name
      pricing_idr
      pricing_usd
    }
  }
`

export const GET_CONTRIBUTOR_RECENT_TRANSACTIONS = gql`
  query (
    $where: global_invoices_bool_exp
    $order: [global_invoices_order_by!]
    $limit: Int
    $offset: Int
  ) {
    total: global_invoices_aggregate(where: $where) {
      aggregate {
        count
      }
    }
    invoices: global_invoices(
      where: $where
      order_by: $order
      limit: $limit
      offset: $offset
    ) {
      id
      code
      status
      final_price
      date_added
      global_wallets {
        id
        global_users {
          id
          avatar
          name
          placements: people_work_placements(
            limit: 1
            where: {status: {_eq: "ACTIVE"}}
          ) {
            id
            profile: company_job_profile {
              id
              title
            }
          }
        }
      }
    }
  }
`
