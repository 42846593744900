import React, {FC, ReactElement} from 'react'
import {useTitle} from 'hoofd'

import {MainWrapper} from '../../components/job-portal/JobPortalStyles'
import BackNavigation from '../../components/navigation/BackNavigation.component'
import BookDetail from '../../components/learner/book/BookDetail'
import Header from '../../components/header/HeaderComponent'

import useStyles, {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/GlobalStyles'

const Book: FC = (): ReactElement => {
  useTitle('Detil Buku')
  const classes = useStyles()

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          className={classes.tabs}
          centered
        >
          <StyledTab label="Katalog Pembelajaran" value="learning-catalog" />
        </StyledTabs>
      </TabsWrapper>

      <MainWrapper>
        <div className="w-full flex flex-col">
          <BackNavigation label="Kembali" />
          <BookDetail />
        </div>
      </MainWrapper>
    </>
  )
}

export default Book
