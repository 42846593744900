import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_EVENT_DETAILS = gql`
  query ($eventId: Int!, $creator: uuid) {
    event_schedules(where: {id: {_eq: $eventId}, creator: {_eq: $creator}}) {
      id
      slug
      title
      thumbnail
      status
    }
  }
`

export const GET_CONTRIBUTOR_EVENT_DETAILS_PROGRESS = gql`
  query ($eventId: Int!) {
    getProgressEventDetail(eventId: $eventId) {
      total
      information
    }
  }
`

export const GET_CONTRIBUTOR_EVENT_ACTIVITY_BREAKDOWN = gql`
  query (
    $startDate: String
    $endDate: String
    $timeType: String
    $eventId: Int!
  ) {
    getActivityByTimeEventDetail(
      startDate: $startDate
      endDate: $endDate
      eventId: $eventId
      timeType: $timeType
    ) {
      wishlist_event
      enrollment_event
    }
  }
`

export const GET_CONTRIBUTOR_MY_EVENTS = gql`
  query (
    $userId: uuid!
    $limit: Int
    $offset: Int
    $orderBy: [event_schedules_order_by!]
    $status: [String!]
    $search: String
    $createdDate: timestamp_comparison_exp
  ) {
    event_schedules_aggregate(
      where: {
        creator: {_eq: $userId}
        status: {_in: $status}
        title: {_ilike: $search}
      }
    ) {
      aggregate {
        count
      }
    }

    event_schedules(
      where: {
        creator: {_eq: $userId}
        status: {_in: $status}
        title: {_ilike: $search}
        date_added: $createdDate
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      title
      date_added
      thumbnail
      status
      pricing
      pricing_idr
      average_rating
      event_enrollments_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const GET_CONTRIBUTOR_EVENT_LANDING_DETAILS = gql`
  query ($userId: uuid!, $eventId: Int!) {
    event_schedules(
      where: {
        creator: {_eq: $userId}
        id: {_eq: $eventId}
        deletedAt: {_is_null: true}
      }
      limit: 1
    ) {
      id
      thumbnail
      title
      slug
      description
      datestart
      dateend
      pricing_idr
      schedule_fields
      location_info
      notif_unit_of_time
      notif_timer
      poster
      type
      event_category {
        id
        event_category {
          id
        }
      }
      event_schedule_files {
        id
        name
        type
        size
        url: file
      }
    }
  }
`
