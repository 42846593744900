import React from 'react'
import {useQuery} from '@apollo/client'

import {type Validator} from '../../input-forms/validators'
import FormSelect, {SelectItem} from '../../input-forms/FormSelect'

import {GET_EVENT_TYPES} from '../../../graphql/queries'

export interface EventTypeFieldProps {
  name: string
  validate?: Validator
  label?: string
  autoFocus?: boolean
  className?: string
}

const EventTypeField = (props: EventTypeFieldProps) => {
  const {name, validate, label, autoFocus, className} = props

  const {data} = useQuery(GET_EVENT_TYPES)

  const typeOptions = React.useMemo(() => {
    const options: SelectItem[] = [
      {value: '', label: 'Select Event Type', disabled: true},
    ]

    if (data) {
      for (const item of data.event_types) {
        options.push({value: item.id, label: item.name})
      }
    }

    return options
  }, [data])

  return (
    <FormSelect
      name={name}
      options={typeOptions}
      validate={validate}
      disabled={!data}
      label={label}
      autoFocus={autoFocus}
      className={className}
    />
  )
}

export default EventTypeField
