import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  IconButton,
  Icon,
  TextField,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
} from '@material-ui/core'
import React, {ChangeEvent, FC, ReactElement, useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'
import {IDays, ISettingMicro} from '../../../interfaces'
import {daysType} from '../../../utils/constans'
import {USER_ID} from '../../../utils/globals'
import {WhiteModalHeader} from '../../utils-components/GlobalStyles'
import useStyles from './MicroLearning.style'

type SettingMicroDialogProps = {
  open: boolean
  onClose: React.Dispatch<React.SetStateAction<boolean>>
  onSave: (variable: ISettingMicro) => void
  dataSetting: {
    dailyQuestion: number
    schedule: string
    scheduleTime: string
  }
}

const SettingMicroDialog: FC<SettingMicroDialogProps> = (
  props: SettingMicroDialogProps
): ReactElement => {
  const classes = useStyles()
  const {open, onClose, onSave, dataSetting} = props
  const {id} = useParams()
  const [day, setDay] = useState<IDays>({
    sunday: false,
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
  })

  const [values, setValues] = useState<ISettingMicro>({
    userId: USER_ID,
    microlearningId: id,
    dailyQuestion: '',
    schedule: '0000000',
    scheduleTime: '08:30:00',
  })

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.name === 'scheduleTime') {
      setValues({...values, [event.target.name]: event.target.value})
    } else if (event.target.name === 'dailyQuestion') {
      const valueInt = parseInt(event.target.value)
      setValues({...values, [event.target.name]: valueInt >= 0 ? valueInt : 0})
    } else {
      const latestDay = {...day, [event.target.name]: event.target.checked}
      const arrayDay = Object.values(latestDay)
      const tempDays: string[] = []
      arrayDay.forEach((data) => tempDays.push(data ? '1' : '0'))
      setDay(latestDay)
      setValues({...values, schedule: tempDays.join('')})
    }
  }

  const handleSave = () => {
    onSave(values)
    onClose(false)
  }

  useEffect(() => {
    if (dataSetting) {
      setValues((prev) => ({
        ...prev,
        schedule: dataSetting.schedule,
        dailyQuestion: dataSetting.dailyQuestion,
        scheduleTime: dataSetting.scheduleTime,
      }))
      const arrayDays = dataSetting.schedule.split('')
      const tempData = {
        sunday: arrayDays[0] === '1',
        monday: arrayDays[1] === '1',
        tuesday: arrayDays[2] === '1',
        wednesday: arrayDays[3] === '1',
        thursday: arrayDays[4] === '1',
        friday: arrayDays[5] === '1',
        saturday: arrayDays[6] === '1',
      }
      setDay(tempData)
    }
  }, [dataSetting])

  return (
    <Dialog onClose={() => onClose(false)} open={open} fullWidth maxWidth="sm">
      <WhiteModalHeader>
        <Typography color="primary" variant="subtitle2" className="font-bold">
          Atur Pembelajaran Mikro
        </Typography>
        <IconButton aria-label="close" onClick={() => onClose(false)}>
          <Icon style={{fontSize: 30}}>close</Icon>
        </IconButton>
      </WhiteModalHeader>
      <DialogContent className={classes.content}>
        <Typography variant="subtitle2" className="mb-3">
          Jawab Pertanyaan Setiap:
        </Typography>
        <FormGroup>
          {daysType.map((val, index) => {
            return (
              <FormControlLabel
                key={index}
                control={
                  <Checkbox
                    color="primary"
                    size="medium"
                    checked={day[val.value as keyof IDays]}
                    onChange={handleChange}
                    name={val.value}
                  />
                }
                label={
                  <Typography variant="subtitle2" className="text-[#707070]">
                    {val.name}
                  </Typography>
                }
              />
            )
          })}
        </FormGroup>
        <Typography variant="subtitle2" className="mt-6 mb-3">
          Waktu
        </Typography>
        <TextField
          value={values.scheduleTime}
          type="time"
          variant="outlined"
          name="scheduleTime"
          onChange={handleChange}
        />
        <Typography variant="subtitle2" className="mt-6 mb-3">
          Jumlah Pertanyaan
        </Typography>
        <TextField
          value={values.dailyQuestion}
          type="number"
          variant="outlined"
          name="dailyQuestion"
          placeholder="Tambah jumlah Pertanyaan"
          onChange={handleChange}
        />
        <Typography color="textSecondary" variant="subtitle2" className="my-3">
          Kolom ini untuk menentukan jumlah soal yang akan dijawab sesuai dengan
          waktu yang ditentukan
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button size="large" className="w-36" onClick={() => onClose(false)}>
          Batal
        </Button>
        <Button
          size="large"
          variant="contained"
          color="primary"
          className="w-36"
          onClick={handleSave}
        >
          Simpan
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SettingMicroDialog
