import React from 'react'
import {useOutletContext} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import {CoursePageContext} from '../ContributorCourseDetailPageLayout'

import EnrollmentsCard, {
  type EnrollmentStatsData,
} from '../../../../../components/contributor/dashboard/EnrollmentsCard'

import {GET_CONTRIBUTOR_COURSE_ENROLLMENT_STATS} from '../../../../../graphql/queries'

const CourseEnrollmentsCard = () => {
  const {courseId} = useOutletContext<CoursePageContext>()

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_ENROLLMENT_STATS, {
    skip: courseId === undefined,
    variables: {courseId},
  })

  const enrollments = React.useMemo(() => {
    if (!data) {
      return null
    }

    const stats = data.getDashboardLearnerContributorCourseDetail

    const object: EnrollmentStatsData = {
      learners: stats.total_learner,
      age_demographics: {
        eighteen_to_twenty_four: stats.eighteen_to_twenty_four,
        twenty_five_to_thirty_four: stats.twenty_five_to_thirty_four,
        thirty_five_to_forty_nine: stats.thirty_five_to_forty_nine,
        over_fifty: stats.over_fifty,
      },
      gender_demographics: {
        male: stats.male,
        female: stats.female,
      },
    }

    return object
  }, [data])

  return <EnrollmentsCard data={enrollments} />
}

export default CourseEnrollmentsCard
