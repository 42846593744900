import React from 'react'
import {Link as RouterLink} from 'react-router-dom'

import {Link} from '@material-ui/core'

import {
  TextBlack,
  FlexRow,
  FlexRowEnd,
} from '../../../utils-components/VendorStyles'
import LoadingCard from '../../../loading/LoadingCard.component'

import {
  TabRowNavigationCourseLeft,
  TabRowNavigationCourseRight,
  TabRowNavigationBookLeft,
  TabRowNavigationBookRight,
  TabRowNavigationEventLeft,
  TabRowNavigationEventRight,
  TabRowNavigationSocialLearningLeft,
  TabRowNavigationSocialLearningRight,
  TabRowNavigationMicroLearningLeft,
  TabRowNavigationMicroLearningRight,
  TabRowNavigationLearningContributorLeft,
  TabRowNavigationLearningContributorRight,
} from './TabRowNavigation.component'
import {
  TabRowCardCourse,
  TabRowCardBook,
  TabRowCardEvent,
  TabRowCardSocialLearning,
  TabRowCardMicroLearning,
  TabRowCardContributor,
} from './TabRowCard.component'

import {LearnerBookContext} from '../../../../provider/LearnerBook.provider'
import {LearningType} from '../../../../utils/enum'

type TabRowProps = {
  title: string
  data: any
  type: LearningType
  isAll?: boolean
  isFilterCatalog?: boolean
  loading?: boolean
  hideSeeAll?: boolean
}

type TSectionType = {
  [key: string]: string
}

const url: TSectionType = {
  'Sedang Tren di SmartASN': 'trending',
  'Yang sedang tren di SmartASN': 'trending',
  'Rekomendasi untuk Anda': 'recommended',
  'Acara terdekat': 'nearby',
  'Acara-acara untuk anda': 'for-you',
  'Kontributor Pembelajaran Teratas': 'top-learning-contributor',
  'Instruktur / Pengajar Teratas': 'top-instructor',
  'Pembimbing Teratas': 'top-mentor',
  'Pelatih Teratas': 'top-coach',
  'Ahli Teratas': 'top-expert',
  'Pelatih Individu Teratas': 'top-trainer',
}

const getMiddleUrl = (title: string) => {
  const isIncludeTop = title.includes('Top')

  // handle titles that are still in English
  if (isIncludeTop) return title.split(' ').join('-').toLocaleLowerCase()

  return url[title] || ''
}

const TabRow = (props: TabRowProps) => {
  const renderTabRowNavigation = () => {
    switch (props.type) {
      case LearningType.COURSE:
        return (
          <FlexRowEnd>
            <TabRowNavigationCourseLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationCourseRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      case LearningType.BOOK:
        return (
          <FlexRowEnd>
            <TabRowNavigationBookLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationBookRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      case LearningType.EVENT:
        return (
          <FlexRowEnd>
            <TabRowNavigationEventLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationEventRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      case LearningType.MICRO_LEARNING:
        return (
          <FlexRowEnd>
            <TabRowNavigationMicroLearningLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationMicroLearningRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      case LearningType.SOCIAL_LEARNING:
        return (
          <FlexRowEnd>
            <TabRowNavigationSocialLearningLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationSocialLearningRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      case LearningType.LEARNING_CONTRIBUTOR:
        return (
          <FlexRowEnd>
            <TabRowNavigationLearningContributorLeft
              title={props.title}
              data={props.data}
              type={props.type}
            />
            <TabRowNavigationLearningContributorRight
              title={props.title}
              data={props.data}
              type={props.type}
            />
          </FlexRowEnd>
        )
      default:
        return true
    }
  }
  const renderTabRowCard = () => {
    switch (props.type) {
      case LearningType.COURSE:
        return (
          <TabRowCardCourse
            title={props.title}
            type={props.type}
            data={props.data}
            isAll={props.isAll}
          />
        )

      case LearningType.BOOK:
        return (
          <TabRowCardBook
            title={props.title}
            type={props.type}
            data={props.data}
            isAll={props.isAll}
          />
        )

      case LearningType.EVENT:
        return (
          <TabRowCardEvent
            title={props.title}
            type={props.type}
            data={props.data}
            isAll={props.isAll}
          />
        )
      case LearningType.MICRO_LEARNING:
        return (
          <TabRowCardMicroLearning
            title={props.title}
            type={props.type}
            data={props.data}
            loading={props.loading}
            isAll={props.isAll}
          />
        )
      case LearningType.SOCIAL_LEARNING:
        return (
          <TabRowCardSocialLearning
            title={props.title}
            type={props.type}
            data={props.data}
            isAll={props.isAll}
            loading={props.loading}
          />
        )
      case LearningType.LEARNING_CONTRIBUTOR:
        return (
          <TabRowCardContributor
            title={props.title}
            type={props.type}
            data={props.data}
            loading={props.loading}
            isAll={props.isAll}
          />
        )
      default:
        return true
    }
  }
  const learnerBookContext = React.useContext(LearnerBookContext)
  const renderTo = () => {
    let _url

    const _middleUrl = getMiddleUrl(props.title)

    switch (props.type) {
      case LearningType.COURSE:
        _url = 'course'
        break
      case LearningType.SOCIAL_LEARNING:
        _url = 'social-learning'
        break
      case LearningType.EVENT:
        _url = 'event'
        break
      case LearningType.BOOK:
        if (learnerBookContext.reducerState.bookType === 2) {
          _url = 'ebook'
        } else {
          _url = 'audiobook'
        }
        break
      case LearningType.MICRO_LEARNING:
        _url = 'micro-learning'
        break
      case LearningType.LEARNING_CONTRIBUTOR:
        _url = 'learning-contributor'
        break
      default:
        _url = '#'
    }

    if (props.title === 'Kursus Saya') {
      return '/learner/my-learning/courses'
    } else if (props.title === 'Buku Elektronik Saya') {
      return '/learner/my-learning/books?type=2'
    } else if (props.title === 'Buku Audio Saya') {
      return '/learner/my-learning/books?type=3'
    } else if (props.title === 'Pembelajaran sosial saya') {
      return '/learner/my-learning/social-learnings'
    } else if (props.title === 'Pembelajaran mikro saya') {
      return '/learner/my-learning/micro-learnings'
    }

    return `/learner/see-all/${_middleUrl}/${_url}`
  }

  return (
    <div
      className={`mb-10 w-full ${
        props?.data?.length === 0 && !props.isAll && !props.loading
          ? 'hidden'
          : ''
      }`}
    >
      <div className="mx-5 flex justify-between items-center">
        <div className="flex items-center">
          <TextBlack>{props.title}</TextBlack>

          {!props.isAll && !props.hideSeeAll && (
            <Link
              component={RouterLink}
              to={renderTo()}
              color="secondary"
              className="text-sm font-bold ml-2 lg:ml-4"
            >
              {' '}
              Lihat Semua
            </Link>
          )}
        </div>
        {!props.isAll && renderTabRowNavigation()}
      </div>

      <FlexRow className={`mb-4 ${props.isFilterCatalog ? 'mt-1' : 'mt-2'}`}>
        {!props.isAll && props.loading ? (
          <LoadingCard
            length={props.type === LearningType.LEARNING_CONTRIBUTOR ? 2 : 4}
            isContributor={props.type === LearningType.LEARNING_CONTRIBUTOR}
          />
        ) : (
          renderTabRowCard()
        )}
      </FlexRow>
    </div>
  )
}

export default TabRow
