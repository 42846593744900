import {gql} from '@apollo/client'

export const GET_GLOBAL_LANGUAGE = gql`
  query ($where: global_languages_bool_exp, $limit: Int) {
    global_languages(where: $where, limit: $limit) {
      id
      name
    }
  }
`
