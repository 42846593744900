import {Typography} from '@material-ui/core'
import React, {FC, ReactElement, ReactNode} from 'react'
import Skeleton from 'react-loading-skeleton'
import {IEventAbout, IEventAboutMap} from '../../../../interfaces'
import Dompurify from 'dompurify'
import {convertToHour, convertToShortDate} from '../../../../utils/helpers'

const TabAboutEvent: FC<IEventAbout> = (props: IEventAbout): ReactElement => {
  const isLoading = !props
  if (isLoading) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }
  const {type, category, startDate, endDate, location, description} = props

  const startDateEvent = startDate && convertToShortDate(startDate)
  const endDateEvent = endDate && convertToShortDate(endDate)
  const startTimeEvent = startDate && convertToHour(startDate)
  const endTimeEvent = endDate && convertToHour(endDate)

  const data: IEventAboutMap = {
    'Tipe Acara': type ?? '-',
    Kategori: category ?? '-',
    Tanggal:
      startDateEvent !== endDateEvent
        ? `${startDateEvent} - ${endDateEvent}`
        : startDateEvent,
    Waktu:
      startDateEvent === endDateEvent
        ? `${startTimeEvent} - ${endTimeEvent}`
        : startTimeEvent,
    Lokasi: location ?? '-',
  }

  return (
    <div id="tab-about-event">
      <Typography variant="body1" className="font-bold">
        Rincian Acara
      </Typography>

      <table className="mt-4 w-fit">
        <tbody>
          {Object.entries(data).map(([key, value], index): ReactNode => {
            return (
              <tr className="h-8" key={`${key}-${index}`}>
                <td className="align-top w-min-fit">
                  <Typography variant="subtitle2" className="text-gray-700">
                    {key}
                  </Typography>
                </td>

                <td className="align-top w-5">
                  <Typography
                    variant="subtitle2"
                    className="text-gray-700 text-center"
                  >
                    :
                  </Typography>
                </td>

                <td className="align-top">
                  <Typography
                    variant="subtitle2"
                    className="text-gray-400 whitespace-normal"
                  >
                    {value}
                  </Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Typography variant="body1" className="mt-8 font-bold">
        Keterangan
      </Typography>

      <div
        className="mt-4 text-sm text-gray-700 whitespace-normal"
        dangerouslySetInnerHTML={{__html: Dompurify.sanitize(description)}}
      />
    </div>
  )
}

export default TabAboutEvent
