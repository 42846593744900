import React from 'react'
import {useParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import EnrollmentsCard, {
  type EnrollmentStatsData,
} from '../../../../../components/contributor/dashboard/EnrollmentsCard'

import {GET_CONTRIBUTOR_MICROLEARING_LEARNER_STATS} from '../../../../../graphql/queries'

const MicrolearningEnrollmentsCard = () => {
  const {microlearningId} = useParams()

  const {data} = useQuery(GET_CONTRIBUTOR_MICROLEARING_LEARNER_STATS, {
    skip: microlearningId === undefined,
    variables: {microlearningId},
  })

  const enrollments = React.useMemo(() => {
    if (!data) {
      return null
    }

    const stats = data.getDashboardLearnerContributorMicrolearningDetail

    const object: EnrollmentStatsData = {
      learners: stats.total_learner,
      age_demographics: {
        eighteen_to_twenty_four: stats.eighteen_to_twenty_four,
        twenty_five_to_thirty_four: stats.twenty_five_to_thirty_four,
        thirty_five_to_forty_nine: stats.thirty_five_to_forty_nine,
        over_fifty: stats.over_fifty,
      },
      gender_demographics: {
        male: stats.male,
        female: stats.female,
      },
    }

    return object
  }, [data])

  return <EnrollmentsCard data={enrollments} />
}

export default MicrolearningEnrollmentsCard
