import {type FieldMetaState} from 'react-final-form'
import {type FieldValidator} from 'final-form'

export type Validator = FieldValidator<any>
export type SyncValidator = Validator
export type AsyncValidator = Validator

export const SKIP_VALIDATION = Symbol('skip validation')

export const combineValidators = (...validators: SyncValidator[]) => {
  return (...args: Parameters<Validator>) => {
    for (let idx = 0, len = validators.length; idx < len; idx++) {
      const validate = validators[idx]
      const error = validate(...args)

      if (error === SKIP_VALIDATION) {
        return
      }

      if (typeof error === 'string') {
        return error
      }
    }
  }
}

/** @deprecated */
export const combineAsyncValidators = (...validators: Validator[]) => {
  return async (...args: Parameters<Validator>) => {
    for (let idx = 0, len = validators.length; idx < len; idx++) {
      const validate = validators[idx]
      const error = await validate(...args)

      if (error === SKIP_VALIDATION) {
        return
      }

      if (typeof error === 'string') {
        return error
      }
    }
  }
}

// Configuration for when an error should be shown
export type ShowErrorFn = (meta: FieldMetaState<any>) => boolean

export const showErrorOnBlur = ({
  submitError,
  error,
  touched,
}: FieldMetaState<any>) => {
  return !!((touched && error) || submitError)
}

export const showErrorOnChange = ({
  submitError,
  error,
  touched,
  modified,
}: FieldMetaState<any>) => {
  return !!(((touched || modified) && error) || submitError)
}

// Coercions
export const parseAsInt = (value: any) => {
  const int = parseInt(value, 10)
  return Number.isNaN(int) ? undefined : int
}

// Default validations
export const isRequired = (value: any) =>
  (value === undefined || value === '') && 'This field is required'

export const isBlobSizeCorrect = (megabytes: number) => {
  const bytes = megabytes * 1024 * 1024

  return (value: string | Blob) => {
    if (value instanceof Blob) {
      if (value.size > bytes) {
        return `Uploaded file cannot exceed ${megabytes} MB`
      }
    }

    return false
  }
}
