import React from 'react'

import { Button, Dialog, Link, Typography } from '@material-ui/core'

import {
  DateRangePicker,
  type DateRange,
} from '@smartasn/wlb-utils-components'

import { format, type Locale } from 'date-fns'

import { ModalActions, ModalHeader } from './Modal'

export interface DateDisplayProps {
  date?: Date | number
  locale?: Locale
}

export const DateDisplay = (props: DateDisplayProps) => {
  const { date, locale } = props

  const formatted = React.useMemo(() => {
    if (!date) {
      return null
    }

    const parsed = date instanceof Date ? date : new Date(date)

    return {
      date: parsed.getDate(),
      month: format(parsed, 'MMMM yyyy', { locale }),
      day: format(parsed, 'eeee', { locale }),
    }
  }, [locale, date])

  return (
    <div className="flex gap-2">
      <Typography color="primary" className="font-bold text-2xl">
        {formatted?.date || '-'}
      </Typography>

      <div className="flex flex-col">
        <Typography color="primary">{formatted?.month || '-'}</Typography>
        <Typography color="textSecondary" className="text-xs">
          {formatted?.day || '-'}
        </Typography>
      </div>
    </div>
  )
}

export interface DateRangeModalProps {
  open?: boolean
  initialRange?: DateRange
  startDate?: Date | number
  endDate?: Date | number
  onSubmit?: (range: Required<DateRange>) => void
  onClose?: () => void
}

const DateRangeModal = (props: DateRangeModalProps) => {
  const { open = false, initialRange = {}, onSubmit, onClose } = props

  const [range, setRange] = React.useState(initialRange)
  const [resetKey, setResetKey] = React.useState(0)

  const handleReset = () => {
    setResetKey((resetKey + 1) % 2)
    setRange({})
  }

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(range as Required<DateRange>)
    }
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md">
      <ModalHeader onClose={onClose} className="gap-4">
        <div>
          <Typography color="textSecondary" className="text-sm mb-1">
            From
          </Typography>

          <DateDisplay date={range?.startDate} />
        </div>

        {range?.endDate && (
          <>
            <span>-</span>

            <div>
              <Typography color="textSecondary" className="text-sm mb-1">
                To
              </Typography>

              <DateDisplay date={range.endDate} />
            </div>
          </>
        )}
      </ModalHeader>

      <div className="grid gap-4 p-6">
        <div className="flex justify-end">
          <Link
            component="button"
            onClick={handleReset}
            color="secondary"
            className="text-sm"
          >
            Reset
          </Link>
        </div>

        <DateRangePicker
          key={resetKey}
          initialDateRange={range}
          onChange={setRange}
        />
      </div>

      <ModalActions>
        <Button onClick={onClose}>Cancel</Button>

        <Button
          disabled={!range || !range.endDate}
          onClick={handleSubmit}
          variant="contained"
          color="primary"
        >
          Submit
        </Button>
      </ModalActions>
    </Dialog>
  )
}

export default DateRangeModal
