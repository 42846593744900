import {useQuery} from '@apollo/client'

import {Chart, ArcElement} from 'chart.js'

import {INSTRUCTOR_CONTEXT} from '../../../utils/contributor-helpers'

import EnrollmentsCard, {
  type ContributorEnrollmentStatsData,
} from '../../../components/contributor/dashboard/EnrollmentsCard'

import {USER_ID} from '../../../utils/globals'
import {
  GET_CONTRIBUTOR_ENROLLMENT_STATS,
  GET_CONTRIBUTOR_DEMOGRAPHIC_STATS,
} from '../../../graphql/queries'

Chart.register(ArcElement)

const ContributorEnrollmentSection = () => {
  const {data: enrollmentData} = useQuery(GET_CONTRIBUTOR_ENROLLMENT_STATS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const {data: demographicData} = useQuery(GET_CONTRIBUTOR_DEMOGRAPHIC_STATS, {
    fetchPolicy: 'cache-and-network',
    context: INSTRUCTOR_CONTEXT,
  })

  const data: ContributorEnrollmentStatsData | null =
    enrollmentData && demographicData
      ? {
          learners:
            enrollmentData.course_enrollments.aggregate.count +
            enrollmentData.ebook_enrollments.aggregate.count +
            enrollmentData.audiobook_enrollments.aggregate.count +
            enrollmentData.event_enrollments.aggregate.count +
            enrollmentData.microlearning_enrollments.aggregate.count,
          enrollments: {
            course_enrollments:
              enrollmentData.course_enrollments.aggregate.count,
            ebook_enrollments: enrollmentData.ebook_enrollments.aggregate.count,
            audiobook_enrollments:
              enrollmentData.audiobook_enrollments.aggregate.count,
            event_enrollments: enrollmentData.event_enrollments.aggregate.count,
            microlearning_enrollments:
              enrollmentData.microlearning_enrollments.aggregate.count,
          },
          age_demographics: demographicData.getTotalLearnerByAge,
          gender_demographics: demographicData.getTotalLearnerByGender,
        }
      : null

  return <EnrollmentsCard isMainDashboard data={data} />
}

export default ContributorEnrollmentSection
