import {FC, useState} from 'react'
import {Button} from '@material-ui/core'
import MyLearningCardComponent from '../../../../components/card/MyLearningCard.component'
import {LearningType} from '../../../../utils/enum'
import {IMySocialLearning} from '../../../../interfaces'
import NoList from '../../../../components/empty-state/NoList.component'
import {GET_MY_SOCIAL_LEARNING} from '../../../../graphql/queries'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import {useQuery} from '@apollo/client'
import Skeleton from 'react-loading-skeleton'
import {TMyLearning} from '..'
import classNames from 'classnames'
import useStyles from '../../../../components/utils-components/GlobalStyles'

const MySocialLearningComponent: FC<TMyLearning> = ({
  search,
  sort,
  filterData,
}) => {
  const classes = useStyles()
  const [socialLearningType, setSocialLearningType] = useState('Group')
  let orderBy

  switch (sort) {
    case 'oldest':
      orderBy = {
        date_added: 'asc',
      }
      break
    case 'nameASC':
      orderBy = {
        name: 'asc',
      }
      break
    case 'nameDESC':
      orderBy = {
        name: 'desc',
      }
      break
    default:
      orderBy = {
        date_added: 'desc',
      }
  }

  const _filter: any = filterData

  let filterStatus
  const isFinish = _filter.status?.find((status: string) => status === 'done')

  const isOngoing = _filter.status?.find(
    (status: string) => status === 'ongoing'
  )

  if (isFinish) {
    filterStatus = true
  }

  if (isOngoing) {
    filterStatus = false
  }

  if (isFinish && isOngoing) {
    filterStatus = null
  }

  const {data, loading} = useQuery(GET_MY_SOCIAL_LEARNING, {
    variables: {
      memberUserId: USER_ID,
      search: `%${search}%`,
      orderBy: [orderBy],
      isFinish: filterStatus,
      isIndividual: socialLearningType === 'Individual',
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })

  const isSearching = search || Object.keys(filterData).length > 0

  if (loading) {
    return (
      <>
        {Array.from({length: 2}).map((res, i) => (
          <Skeleton height="140px" className="mb-4" key={i} />
        ))}
      </>
    )
  }

  return (
    <>
      <div className="mb-10 flex">
        <Button
          disableRipple
          variant="contained"
          color={socialLearningType === 'Group' ? 'primary' : 'default'}
          className={classNames(
            classes.buttonSwitchType,
            'w-full md:w-auto lg:w-auto'
          )}
          onClick={() => setSocialLearningType('Group')}
          classes={{
            root:
              socialLearningType === 'Group'
                ? classes.buttonActive
                : classes.buttonInactive,
          }}
        >
          Kelompok
        </Button>
        <Button
          disableRipple
          variant="contained"
          color={socialLearningType === 'Individual' ? 'primary' : 'default'}
          className={classNames(
            classes.buttonSwitchType,
            'ml-4 w-full md:ml-8 md:w-auto lg:w-auto lg:md-8'
          )}
          onClick={() => setSocialLearningType('Individual')}
          classes={{
            root:
              socialLearningType === 'Individual'
                ? classes.buttonActive
                : classes.buttonInactive,
          }}
        >
          Individu
        </Button>
      </div>
      {data.classrooms.length === 0 ? (
        <NoList
          imageSize={180}
          title={
            isSearching
              ? 'Tidak ada hasil yang ditemukan'
              : 'Maaf, tidak ada daftar'
          }
          subTitle={
            isSearching
              ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
              : 'Saat ini Anda tidak memiliki pembelajaran sosial'
          }
        />
      ) : (
        data.classrooms.map((val: IMySocialLearning) => {
          return (
            <MyLearningCardComponent
              key={val.id}
              data={val}
              type={LearningType.SOCIAL_LEARNING}
            />
          )
        })
      )}
    </>
  )
}

export default MySocialLearningComponent
