import {gql} from '@apollo/client'

export const ADD_LEARNING_DIARY = gql`
  mutation (
    $subject: String!
    $activity_id: bigint
    $date: date
    $duration: Int
    $description: String!
    $participants: learning_diary_participants_arr_rel_insert_input!
    $files: learning_diary_files_arr_rel_insert_input
    $competencyDictionaryId: bigint
    $diaryCompetencyId: uuid
  ) {
    insert_learning_diaries_one(
      object: {
        learning_diary_files: $files
        activity: $activity_id
        subject: $subject
        duration: $duration
        description: $description
        date: $date
        learning_diary_participants: $participants
        competency_dictionary_id: $competencyDictionaryId
        diary_competency_id: $diaryCompetencyId
      }
    ) {
      id
    }
  }
`
