import gql from 'graphql-tag'

export const GET_MY_SOCIAL_LEARNING = gql`
  query getMySocialLearning(
    $memberUserId: uuid!
    $category: [Int!]
    $isIndividual: Boolean
    $isFinish: Boolean
    $search: String
    $limit: Int
    $offset: Int
    $orderBy: [classrooms_order_by!]
  ) {
    classrooms_aggregate(
      where: {
        classroom_members: {member_user_id: {_eq: $memberUserId}}
        category_id: {_in: $category}
        is_individual: {_eq: $isIndividual}
        is_finished: {_eq: $isFinish}
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    classrooms(
      where: {
        classroom_members: {member_user_id: {_eq: $memberUserId}}
        category_id: {_in: $category}
        is_individual: {_eq: $isIndividual}
        is_finished: {_eq: $isFinish}
        _or: [
          {name: {_ilike: $search}}
          {description: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      is_individual
      is_awaiting_contrib
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        id
        global_user {
          id
          name
          avatar
        }
      }
      contributor_requests: classroom_contrib_requests(
        where: {status: {_eq: 0}}
        order_by: {date_added: desc, contrib_user_id: asc}
        limit: 1
      ) {
        id
        status
        global_user {
          id
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        user: global_user {
          id
          avatar
          name
        }
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
    }
  }
`
