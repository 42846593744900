import React from 'react'
import {Field} from 'react-final-form'

import {Draggable} from 'react-beautiful-dnd'

import {Collapse, IconButton, Paper} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import DragIndicatorIcon from '@material-ui/icons/DragIndicator'
import EditIcon from '@material-ui/icons/Edit'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ListAltIcon from '@material-ui/icons/ListAlt'
import NotesIcon from '@material-ui/icons/Notes'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'

import {type LessonFormProps, type LessonViewProps} from './lessons/_Lesson'
import ArticleLessonForm from './lessons/ArticleLessonForm'
import ArticleLessonView from './lessons/ArticleLessonView'
import QuizLessonForm from './lessons/QuizLessonForm'
import QuizLessonView from './lessons/QuizLessonView'
import VideoLessonForm from './lessons/VideoLessonForm'
import VideoLessonView from './lessons/VideoLessonView'

import {isInterimId, useFieldValue, EditState, LessonType} from './_utils'

interface LessonProps {
  id: string
  name: string
  index: number
  onDelete?: () => void
}

type Views = {
  [K in LessonType]: {
    view: (props: LessonViewProps) => any
    form: (props: LessonFormProps) => any
  }
}

export const LESSON_TYPES = [
  {
    label: 'Video',
    value: LessonType.VIDEO,
    icon: <VideocamOutlinedIcon />,
  },
  {
    label: 'Article',
    value: LessonType.ARTICLE,
    icon: <NotesIcon />,
  },
  {
    label: 'Quiz',
    value: LessonType.QUIZ,
    icon: <ListAltIcon />,
  },
]

const LESSON_VIEWS: Views = {
  [LessonType.ARTICLE]: {
    view: ArticleLessonView,
    form: ArticleLessonForm,
  },
  [LessonType.QUIZ]: {
    view: QuizLessonView,
    form: QuizLessonForm,
  },
  [LessonType.VIDEO]: {
    view: VideoLessonView,
    form: VideoLessonForm,
  },
}

const Noop = () => null

const Lesson = (props: LessonProps) => {
  const {id, name, index, onDelete} = props

  const type = useFieldValue<LessonType>(`${name}.lesson_type`)

  const isNew = isInterimId(id)
  const [editState, setEditState] = React.useState(
    isNew ? EditState.ADDING : EditState.NONE
  )

  const isEditing = editState !== EditState.NONE

  const [isOpened, setIsOpened] = React.useState(isEditing)

  if (isEditing) {
    const Form = LESSON_VIEWS[type].form

    return (
      <Paper elevation={0} className="mb-6">
        <Field name={name} subscription={{value: true}}>
          {({input: {value, onChange}}) => (
            <Form
              initialValues={value}
              editState={editState}
              onCancel={() => {
                if (editState === EditState.ADDING) {
                  if (onDelete) {
                    onDelete()
                  }
                } else {
                  setEditState(EditState.NONE)
                }
              }}
              onSubmit={(next: any) => {
                onChange(next)
                setEditState(EditState.NONE)
              }}
            />
          )}
        </Field>
      </Paper>
    )
  }

  const toggleOpen = () => {
    setIsOpened(!isOpened)
  }

  const View = (type as LessonType) ? LESSON_VIEWS[type].view : Noop

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="mb-6 min-w-0"
          elevation={snapshot.isDragging ? 2 : 0}
        >
          <div className="flex items-center px-6 py-1">
            <IconButton {...provided.dragHandleProps} edge="start">
              <DragIndicatorIcon />
            </IconButton>

            <div className="px-2 empty:hidden">
              <Field name={`${name}.lesson_type`} subscription={{value: true}}>
                {({input: {value}}) => {
                  const type = LESSON_TYPES.find((item) => item.value === value)

                  if (!type) {
                    return null
                  }

                  return type.icon
                }}
              </Field>
            </div>

            <Field name={`${name}.title`} subscription={{value: true}}>
              {({input: {value}}) => (
                <span className="grow text-sm font-bold mx-2">{value}</span>
              )}
            </Field>

            <IconButton
              title="Edit lesson"
              onClick={() => setEditState(EditState.EDITING)}
              disabled={!type}
              color="secondary"
            >
              <EditIcon />
            </IconButton>

            <IconButton title="Delete lesson" onClick={onDelete}>
              <DeleteIcon color="error" />
            </IconButton>

            <IconButton
              title="Open/close lesson"
              onClick={toggleOpen}
              disabled={!type}
              edge="end"
            >
              <ExpandMoreIcon className={isOpened ? 'rotate-180' : ''} />
            </IconButton>
          </div>
          <Collapse
            in={isOpened}
            mountOnEnter
            // NOTE(intrnl): quiz lessons shouldn't unmount on exit because you
            // can open individual questions to edit them, we can't track them
            // here unfortunately.
            unmountOnExit={!isEditing && type !== LessonType.QUIZ}
          >
            <View name={name} />
          </Collapse>
        </Paper>
      )}
    </Draggable>
  )
}

export default Lesson
