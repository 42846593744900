import React from 'react'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import {
  Button,
  Divider,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import { Filter } from '@smartasn/wlb-utils-components'

import { CoursePageContext } from '../ContributorCourseDetailPageLayout'

import { CommentAction } from './DiscussionComment'
import CommentRootListing, { Answered, DateRange } from './CommentRootListing'

import { USER_ID } from '../../../../../utils/globals'
import { INSTRUCTOR_CONTEXT } from '../../../../../utils/contributor-helpers'
import { ADD_COMMENT } from '../../../../../graphql/mutations'
import { GET_CONTRIBUTOR_COURSE_LESSONS } from '../../../../../graphql/queries'

const enum SortState {
  OLDEST = 'asc',
  LATEST = 'desc',
}

function ContributorCourseCommentPage() {
  const { courseId } = useOutletContext<CoursePageContext>()

  const commentFilters = React.useMemo(() => {
    const filters: any[] = [
      {
        name: 'Comment Status',
        fieldName: 'status',
        type: 'checkbox',
        options: {
          list: [
            {
              label: 'Answered',
              value: Answered.ANSWERED,
            },
            {
              label: 'Unanswered',
              value: Answered.UNANSWERED,
            },
          ],
        },
      },

      {
        name: 'Comment Date',
        fieldName: 'date',
        type: 'date',
      },
    ]

    if (courseId) {
      filters.push({
        name: 'Curriculum',
        fieldName: 'lessons',
        type: 'checkbox',
        options: {
          fetch: {
            query: GET_CONTRIBUTOR_COURSE_LESSONS,
            options: {
              variables: {
                courseId: courseId,
                limit: 10,
              },
              context: INSTRUCTOR_CONTEXT,
            },
            setData: (data: any) => {
              if (data?.lessons) {
                const lessons = data.lessons.map((item: any) => ({
                  value: item.id,
                  label: item.title,
                }))

                return [lessons, data.total.aggregate.count]
              }
            },
          },
        },
      })
    }

    return filters
  }, [courseId])

  const [addComment] = useMutation(ADD_COMMENT)

  const [searchParams, setSearchParams] = useSearchParams()

  const sort = (searchParams.get('sort') as SortState) ?? SortState.LATEST
  const status = (searchParams.get('status') as Answered) ?? Answered.NONE

  // NOTE(intrnl): these needs to be memoized for <CommentRoot>
  const lessons = React.useMemo(() => {
    const array = searchParams.getAll('lessons')

    return array.length > 0 ? array.map((id) => parseInt(id)) : undefined
  }, [searchParams])

  const date = React.useMemo((): DateRange | undefined => {
    const from = searchParams.get('from') ?? undefined
    const to = searchParams.get('to') ?? undefined

    if (from || to) {
      return { from, to }
    }

    return undefined
  }, [searchParams])

  const handleSubmit = (text: string, lesson: any) => {
    addComment({
      variables: {
        objects: {
          fulltext: text,
          user_id: USER_ID,
          title: `Discussion on ${lesson.title}`,
          lesson_id: lesson.id,
        },
      },
      refetchQueries: ['getCourseComments'],
      context: INSTRUCTOR_CONTEXT,
    })
  }

  const handleFilterApply = ({ 0: include }: any) => {
    const next = new URLSearchParams(searchParams)

    const status = include.status
    const date = include.date
    const lessons = include.lessons

    if (status && status.length === 1) {
      next.set('status', status[0].value)
    } else {
      next.delete('status')
    }

    if (date?.from) {
      next.set('from', date.from.toISOString())
    } else {
      next.delete('from')
    }

    if (date?.to) {
      next.set('to', date.to.toISOString())
    } else {
      next.delete('to')
    }

    if (lessons && lessons.length > 0) {
      next.delete('lessons')

      for (const lesson of lessons) {
        next.append('lessons', lesson.value)
      }
    } else {
      next.delete('lessons')
    }

    setSearchParams(next, { replace: true })
  }

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const next = new URLSearchParams(searchParams)
    next.set('sort', event.target.value as SortState)

    setSearchParams(next, { replace: true })
  }

  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        Comment
      </Typography>

      <Divider />

      <CommentAction courseId={courseId} onSubmit={handleSubmit} />

      <div className="flex items-center justify-between px-2 pb-6">
        <Select
          value={sort}
          defaultValue="desc"
          variant="standard"
          disableUnderline
          SelectDisplayProps={{
            className:
              'text-sm font-semibold pr-6 pl-4 py-2 focus:bg-white outline-none rounded transition',
          }}
          onChange={handleSortChange}
        >
          <MenuItem value="desc">Latest</MenuItem>
          <MenuItem value="asc">Oldest</MenuItem>
        </Select>

        <Filter
          onApply={handleFilterApply}
          listFilter={commentFilters}
          includeExclude={false}
        >
          <Button className="font-semibold">Filter</Button>
        </Filter>
      </div>

      <div className="p-6 pt-0">
        {courseId && (
          <CommentRootListing
            courseId={courseId}
            lessonId={lessons}
            answers={status}
            date={date}
            sort={sort}
          />
        )}
      </div>
    </Paper>
  )
}

export default ContributorCourseCommentPage
