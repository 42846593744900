import React, {FC, ReactElement} from 'react'
import {Icon} from '@material-ui/core'
import useStyles from '../utils-components/GlobalStyles'

type DisplayRatingStarProps = {
  count: number
  starSize?: 'small' | 'medium' | 'large' | undefined
}

const DisplayRatingStar: FC<DisplayRatingStarProps> = ({
  count,
  starSize = 'small',
}: DisplayRatingStarProps): ReactElement => {
  const classes = useStyles()
  const starIcons: ReactElement[] = []
  const halfStar = count % 1 > 0 ? 1 : 0
  let i: number

  for (i = 0; i < count - halfStar; i++) {
    starIcons.push(
      <Icon
        fontSize={starSize}
        className={classes.skyBlueColor}
        key={Math.random() * 10}
      >
        star
      </Icon>
    )
  }
  if (halfStar) {
    starIcons.push(
      <Icon
        fontSize={starSize}
        className={classes.skyBlueColor}
        key={Math.random() * 3}
      >
        star_half
      </Icon>
    )
  }
  for (i = 0; i < 5 - (count + halfStar); i++) {
    starIcons.push(
      <Icon
        fontSize={starSize}
        className={classes.greyColor}
        key={Math.random() * 17}
      >
        star
      </Icon>
    )
  }

  return <div className="flex flex-row items-center min-w-fit">{starIcons}</div>
}

export default DisplayRatingStar
