import React from 'react'
import {useForm, useFormState} from 'react-final-form'
import {useQuery} from '@apollo/client'

import FormSelect, {type SelectItem} from '../../input-forms/FormSelect'
import {isRequired} from '../../input-forms/validators'

import {
  GET_COURSE_CATEGORIES,
  GET_EVENT_CATEGORIES,
} from '../../../graphql/queries'

export interface AcademyCategoryFieldsProps {
  showMark?: boolean
  autoFocus?: boolean
  isEvent?: boolean
}

const AcademyCategoryFields = (props: AcademyCategoryFieldsProps) => {
  const {showMark, autoFocus, isEvent} = props

  const form = useForm()
  const {values} = useFormState({subscription: {values: true}})

  const selectedCategory = values.category

  const {data: categoriesData} = useQuery(GET_COURSE_CATEGORIES, {
    skip: isEvent,
  })
  const {data: eventCategories} = useQuery(GET_EVENT_CATEGORIES, {
    skip: !isEvent,
  })

  const categoryOptions = React.useMemo(() => {
    const options: SelectItem[] = [
      {value: '', label: 'Select Category', disabled: true},
    ]

    if (categoriesData && !isEvent) {
      for (const cat of categoriesData.academy_course_categories) {
        if (cat.academy_course_categories.length < 1) {
          continue
        }

        options.push({value: cat.id, label: cat.name})
      }
    }

    if (eventCategories && isEvent) {
      for (const cat of eventCategories.event_categories) {
        if (cat.event_categories.length < 1) {
          continue
        }

        options.push({value: cat.id, label: cat.name})
      }
    }

    return options
  }, [categoriesData, eventCategories, isEvent])

  const subcategoryOptions = React.useMemo(() => {
    const options: SelectItem[] = [
      {value: '', label: 'Select Sub Category', disabled: true},
    ]

    if (categoriesData && !Number.isNaN(selectedCategory) && !isEvent) {
      for (const cat of categoriesData.academy_course_categories) {
        if (cat.id !== selectedCategory) {
          continue
        }

        for (const subcat of cat.academy_course_categories) {
          options.push({value: subcat.id, label: subcat.name})
        }

        break
      }
    }

    if (eventCategories && !Number.isNaN(selectedCategory) && isEvent) {
      for (const cat of eventCategories.event_categories) {
        if (cat.id !== selectedCategory) {
          continue
        }

        for (const subcat of cat.event_categories) {
          options.push({value: subcat.id, label: subcat.name})
        }

        break
      }
    }

    return options
  }, [categoriesData, selectedCategory, eventCategories, isEvent])

  return (
    <>
      <FormSelect
        name="category"
        validate={isRequired}
        parse={Number}
        label={'Category' + (showMark ? '*' : '')}
        options={categoryOptions}
        autoFocus={autoFocus}
        onChange={() => {
          form.change('subcategory', undefined)
        }}
      />

      <FormSelect
        name="subcategory"
        disabled={!selectedCategory}
        validate={isRequired}
        parse={Number}
        label={'Sub Category' + (showMark ? '*' : '')}
        options={subcategoryOptions}
      />
    </>
  )
}

export default AcademyCategoryFields
