import React from 'react'

import DOMPurify from 'dompurify'

import {Checkbox, FormControlLabel, FormGroup, Radio} from '@material-ui/core'

import {QuestionType} from '../../../manage/curriculums/components/QuestionSubform'

export interface QuizDetailsProps {
  lesson: any
}

const ChoicesView = (props: {
  choices: any[]
  answers: any[]
  multiple: boolean
}) => {
  const {choices, answers, multiple} = props

  const Input = multiple ? Checkbox : Radio

  return (
    <FormGroup className="mt-2">
      {choices.map((value) => (
        <FormControlLabel
          key={value}
          label={value}
          control={
            <Input checked={answers.includes(value)} readOnly color="primary" />
          }
        />
      ))}
    </FormGroup>
  )
}

const QuizDetails = (props: QuizDetailsProps) => {
  const {lesson} = props

  return (
    <div className="p-6 text-sm">
      <ol className="flex flex-col gap-6 list-decimal pl-6 marker:font-semibold">
        {lesson.questions.map((question: any) => (
          <li key={question.id}>
            <div
              className="font-semibold"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(question.fulltext),
              }}
            />

            {question.fieldtype === QuestionType.SHORT_ANSWER ? null : (
              <ChoicesView
                multiple={question.fieldtype === QuestionType.MULTIPLE_ANSWER}
                choices={question.choices}
                answers={question.answer_keys}
              />
            )}
          </li>
        ))}
      </ol>
    </div>
  )
}

export default React.memo(QuizDetails)
