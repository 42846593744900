import {Routes, Route} from 'react-router-dom'
import CertificateCard from '../components/card/profileCard/CertificateCard.component'
import FavoriteCategoriesCard from '../components/card/profileCard/FavoriteCategoriesCard.component'
import LearningCircleCard from '../components/card/profileCard/LearningCircle.component'
import LearningDiaryCard from '../components/card/profileCard/LearningDiaryCard.component'
import {LearnerSidebar} from '../components/learner'
import {Left, Right} from '../components/utils-components/GlobalStyles'
import LearningProfile from '../pages/profile'
import LearningCircle from '../pages/profile/learning-circle'
import AddLearningDiary from '../pages/profile/learning-diary/AddLearningDiary.page'
import LearningDiaryPage from '../pages/profile/learning-diary/LearningDiary.page'
import LearningDiaryDetailPage from '../pages/profile/learning-diary/LearningDiaryDetail.page'
import LearningDiaryResumePage from '../pages/profile/learning-diary/LearningDiaryResume.page'
import LearningAddNewCompetence from '../pages/profile/learning-summary/LearningAddNewCompetence.page'
import LearningSummaryPage from '../pages/profile/learning-summary/LearningSummary.page'
import {LearnerProfileProvider} from '../provider/LearningProfile.provider'

const RouteConfig = [
  {path: '/', element: <LearningProfile />},
  {path: '/learning-summary', element: <LearningSummaryPage />},
  {path: '/competence/add', element: <LearningAddNewCompetence />},
  {path: '/competence/edit/:id', element: <LearningAddNewCompetence />},
  {
    path: '/learning-diary-resume',
    element: <LearningDiaryResumePage />,
  },
  {
    path: '/learning-diary',
    element: <LearningDiaryPage />,
  },
  {
    path: '/learning-diary/:id/detail',
    element: <LearningDiaryDetailPage />,
  },
  {
    path: '/learning-diary/create',
    element: <AddLearningDiary />,
  },
  {
    path: '/learning-diary/:id/edit',
    element: <AddLearningDiary />,
  },
  {
    path: '/learning-circle',
    element: <LearningCircle />,
  },
  {
    path: '/favorite-category',
    element: <FavoriteCategoriesCard />,
  },
]

const RouteComponent = RouteConfig.map((route, i) => (
  <Route path={route.path} element={route.element} key={i} />
))

export default function ProfileRouter() {
  return (
    <LearnerProfileProvider>
      <div className="bg-[#fafafa]">
        <LearnerSidebar profile>
          <div className="pl-5 gap-7 flex flex-col lg:flex-row">
            <Left>
              <Routes>{RouteComponent}</Routes>
            </Left>
            <Right>
              <LearningDiaryCard />
              {/* <BadgeCard /> */}
              <LearningCircleCard />
              <CertificateCard />
            </Right>
          </div>
        </LearnerSidebar>
      </div>
    </LearnerProfileProvider>
  )
}
