import React from 'react'
import {Link as RouterLink, useSearchParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import {
  Avatar,
  Divider,
  InputAdornment,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from 'react-loading-skeleton'

import {INSTRUCTOR_CONTEXT} from '../../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSES} from '../../../../../../graphql/queries'
import {Pagination} from '../../../../../../components/contributor/Pagination'

export interface QuizResponsesProps {
  lessonId: number
}

const QuizResponses = (props: QuizResponsesProps) => {
  const {lessonId} = props

  const [searchParams, setSearchParams] = useSearchParams()

  const search = searchParams.get('search') ?? ''

  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      lessonId,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const response = data?.response

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const params = new URLSearchParams(searchParams)
    const next = ev.target.value

    params.set('search', next)
    setSearchParams(params, {replace: true})
  }

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setSearchParams(next, {replace: true})
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setSearchParams(next, {replace: true})
  }

  return (
    <div>
      <div className="flex gap-18 p-6 text-center justify-center">
        <div>
          <p className="mb-6">Lowest Score</p>

          <Typography className="text-red-400 text-5xl font-semibold">
            {response ? Math.floor(response.lowest_score) : <Skeleton />}
          </Typography>
        </div>

        <div>
          <p className="mb-6">Average Score</p>

          <Typography color="secondary" className="text-5xl font-semibold">
            {response ? Math.floor(response.average_score) : <Skeleton />}
          </Typography>
        </div>

        <div>
          <p className="mb-6">Highest Score</p>

          <Typography color="primary" className="text-5xl font-semibold">
            {response ? Math.floor(response.highest_score) : <Skeleton />}
          </Typography>
        </div>
      </div>

      <Divider />

      <div className="flex flex-col gap-6 p-6">
        <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0 text-sm`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{width: 72}}>
              <span className="sr-only">Avatar</span>
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell className="text-right" style={{width: 128}}>
              Highest Score
            </TableCell>
            <TableCell className="text-right" style={{width: 128}}>
              Lowest Score
            </TableCell>
            <TableCell className="text-right" style={{width: 128}}>
              Total Repeats
            </TableCell>
            <TableCell className="text-center" style={{width: 0}}>
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!response
            ? Array.from({length: limit}, (_, idx) => (
                <TableRow key={idx}>
                  <TableCell>
                    <div className="h-10 w-10 bg-gray-200 rounded-full -my-1" />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                  <TableCell>
                    <Skeleton />
                  </TableCell>
                </TableRow>
              ))
            : response!.data.map((item: any) => (
                <TableRow key={item.user_id}>
                  <TableCell>
                    <Avatar
                      alt={item.user_name}
                      src={item.user_avatar}
                      className="-my-1"
                    />
                  </TableCell>
                  <TableCell>{item.user_name}</TableCell>
                  <TableCell className="text-right">
                    <Typography color="primary" className="text-sm">
                      {Math.floor(item.highest_score)}
                    </Typography>
                  </TableCell>
                  <TableCell className="text-right text-red-400">
                    {Math.floor(item.lowest_score)}
                  </TableCell>
                  <TableCell className="text-right">
                    {item.total_repeat}
                  </TableCell>
                  <TableCell className="text-center">
                    <Link
                      component={RouterLink}
                      to={`responses/${item.user_id}`}
                      color="secondary"
                      className="text-sm"
                    >
                      Detail
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
        </TableBody>
      </Table>

      <Pagination
        count={response ? response.count : 0}
        page={page}
        rowsPerPage={limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </div>
  )
}

export default QuizResponses
