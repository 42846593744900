import {gql} from '@apollo/client'

export const GET_LEARNING_PROFILE_SUMMARY = gql`
  query ($placement: [Int!]) {
    competency_categories(
      where: {
        competency_dictionaries: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_in: $placement}}
              }
            }
          }
        }
      }
    ) {
      id
      name
      date_added
      last_modified
      competency_dictionaries(
        where: {
          competency_version_dictionaries: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_in: $placement}}
              }
            }
          }
        }
      ) {
        name
        description
        date_added
        competency_version_dictionaries(
          where: {
            competency_version: {status: {_eq: "ACTIVE"}}
            competency_model_items: {
              company_job_profile: {
                people_work_placements: {id: {_in: $placement}}
              }
            }
          }
        ) {
          competency_model_items_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {
                    people_work_placements: {id: {_in: $placement}}
                  }
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
          competency_individuals_aggregate(
            where: {
              competency_version_dictionary: {
                competency_version: {status: {_eq: "ACTIVE"}}
                competency_model_items: {
                  company_job_profile: {
                    people_work_placements: {id: {_in: $placement}}
                  }
                }
              }
            }
          ) {
            aggregate {
              avg {
                level
              }
            }
          }
        }
      }
    }
  }
`
