import {gql} from '@apollo/client'

export const GET_DETAIL_CONTRIBUTOR_EVENT = gql`
  query (
    $id: uuid!
    $type: Int
    $search: String
    $ordering: [event_schedules_order_by!]
  ) {
    event_schedules(
      where: {
        creator: {_eq: $id}
        type: {_eq: $type}
        title: {_ilike: $search}
        status: {_eq: "active"}
      }
      order_by: $ordering
    ) {
      id
      thumbnail
      description
      title
      CreatorUser: global_user {
        id
        name
      }
      dateStart: datestart
      status
      type
      pricing
      average_rating
      location_info
      EventType: event_type {
        id
        name
      }
      EventCategory: event_category {
        id
        name
      }
    }
  }
`
