/* eslint-disable no-useless-constructor */

// NOTE(intrnl): change to id-ID for SmartASN
const compactFormatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  maximumFractionDigits: 2,
})

export function formatCompactNominal(num: number) {
  const res = compactFormatter.format(num)
  // NOTE(intrnl): return res directly for SmartASN
  return res.replace(/[.,]/g, (ch) => (ch === '.' ? ',' : '.'))
}

export const INSTRUCTOR_CONTEXT = {
  headers: {
    'X-Hasura-Role': 'instructor',
  },
}

// NOTE(intrnl): containers like this makes it easier to coordinate dependencies
// across phases, such as if a lesson needs to have files uploaded, or if a new
// section needs to be added 

// they make it easier to spot if some process gets wrong and didn't complete
// correctly.
export class IdContainer {
  constructor(public interimId: string, public newId?: number) {}

  toJSON() {
    if (!this.newId) {
      throw new Error(`This section or lesson hasn't been created`)
    }

    return this.newId
  }
}

export class FileContainer {
  constructor(public raw: File, public path?: string, public url?: string) {}

  toJSON() {
    if (!this.url) {
      throw new Error(`This file hasn't been uploaded`)
    }

    return this.url
  }
}
