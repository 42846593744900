import gql from 'graphql-tag'

export const GET_CONTRIBUTOR_INFO = gql`
  query ($userId: uuid!) {
    learning_contributor_ratings_by_pk(user_id: $userId) {
      global_user {
        id
        name
        avatar
        contributor_label
        contributor_banner
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
        }
      }
      total_rating_avg
      total_rating_count
    }
  }
`

export const GET_CONTRIBUTOR_INFO_USER = gql`
  query ($userId: uuid!) {
    learning_contributor_ratings_by_pk(user_id: $userId) {
      global_user {
        id
        name
        avatar
        contributor_label
        contributor_banner
        people_phones(where: {is_main: {_eq: true}}) {
          id
          country_code
          phone
          is_main
          is_visible
        }
        people_emails {
          email
        }
        people_addresses(where: {is_main: {_eq: true}}) {
          id
          address
          address_fields
          global_province {
            id
            name
          }
          global_city {
            id
            name
          }
          is_main
          is_visible
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
  }
`
export const GET_CONTRIBUTOR_INFO_SHORT = gql`
  query ($userId: uuid!) {
    learning_contributor_ratings_by_pk(user_id: $userId) {
      global_user {
        id
        name
        contributor_label
        avatar
        people_work_placements(limit: 1, where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
  }
`
