import React from 'react'
import {
  Card,
  makeStyles,
  Avatar,
  Grid,
  Button,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import {
  CustomFlex,
  FlexColumnCenter,
  CustomTypography,
  BorderLinearProgress,
} from '../../components/utils-components/GlobalStyles'
import RatingComponent from '../utils-components/Rating.component'
// import {getPercent} from '../../utils/helpers'
import {LearningType} from '../../utils/enum'
import {
  IEnrolledCourse,
  IBook,
  IMicroLearning,
  IMySocialLearning,
  IEvent,
} from '../../interfaces'
import {useNavigate} from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'
import {socialLearningPriv} from '../../utils/constans'
import {SOSMED_APP_URL} from '../../utils/globals'
import classNames from 'classnames'

const useStyles = makeStyles({
  root: {
    minWidth: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: '25px',
  },
  landscapeBanner: {
    width: '187px',
    height: '119px',
    objectFit: 'cover',
    marginRight: '20px',
  },
  squareBanner: {
    width: '130px',
    height: '100%',
    marginRight: '20px',
  },
  potraitBanner: {
    width: '84px',
    height: '100%',
    marginRight: '20px',
  },
  buttonLightBlueOutlined: {
    border: ' 1px solid #039be5',
    color: '#039be5',
  },
  buttonLightBlue: {
    background: '#039be5',
    color: '#fff',
  },
  buttonGreen: {
    border: ' 1px solid #28A745',
    color: '#28A745',
  },
  linearProgress: {
    marginTop: '5px',
  },
  smallAvatar: {height: 24, width: 24},
})

type MyLearningProps = {
  data:
    | IEnrolledCourse
    | IBook
    | IMySocialLearning
    | IMicroLearning
    | IEvent
    | any
  type: LearningType
}

const MyLearningCardComponent = (
  props: MyLearningProps
): React.ReactElement => {
  const classes = useStyles()
  const {data, type} = props
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const courseCompletionRate =
    data.academy_course_enrollments?.[0]?.completion_rate

  const courseCertificate =
    data.academy_course_enrollments?.[0]?.academy_certificates

  const microdata = data.microlearning
  const certificateId = data.academy_course_enrollments?.[0]?.id

  const renderButton = () => {
    if (type === LearningType.COURSE) {
      const toDetailCourse = () =>
        navigate(`/learner/course/${data.slug}`, {
          state: {prevRouteName: 'My Learning'},
        })
      const toDetailCertificate = () =>
        navigate(`/learner/my-certificate/detail/${certificateId}`)

      if (courseCompletionRate === 0) {
        return (
          <Button variant="contained" color="primary" onClick={toDetailCourse}>
            Mulai Kursus
          </Button>
        )
      } else if (courseCompletionRate === 100) {
        return (
          <CustomFlex>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonLightBlueOutlined}
              onClick={toDetailCourse}
            >
              Lihat Kursus
            </Button>
            {courseCertificate?.length > 0 && (
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonGreen}
                style={{marginLeft: 10}}
                onClick={toDetailCertificate}
              >
                Lihat Sertifikat
              </Button>
            )}
          </CustomFlex>
        )
      } else if (courseCompletionRate > 0 && courseCompletionRate < 100) {
        return (
          <Button variant="contained" color="primary" onClick={toDetailCourse}>
            Lanjutkan Kursus
          </Button>
        )
      } else {
        return (
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonLightBlueOutlined}
            onClick={toDetailCourse}
          >
            Lihat Kursus
          </Button>
        )
      }
    } else if (type === LearningType.BOOK) {
      const progress = data?.academy_book_owners[0]?.progress || 0
      if (data.type.id === 3) {
        return (
          <div className="flex flex-1 flex-col md:flex-row">
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => navigate(`/learner/book/${data.isbn}`)}
            >
              Lihat Buku Audio
            </Button>
            <Button
              className="mt-2 md:ml-4 md:mt-0 lg:ml-4 lg:mt-0"
              variant={progress === 0 ? 'contained' : 'outlined'}
              color={progress === 0 ? 'primary' : 'secondary'}
              onClick={() => navigate(`/learner/book/${data.isbn}`)}
            >
              Dengarkan Buku Audio
            </Button>
          </div>
        )
      } else {
        if (progress !== 100) {
          return (
            <div className="flex flex-1 flex-col md:flex-row">
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => navigate(`/learner/book/${data.isbn}`)}
              >
                Lihat Buku elektronik
              </Button>
              <Button
                className="mt-2 md:ml-4 md:mt-0 lg:ml-4 lg:mt-0"
                variant={progress === 0 ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => navigate(`/learner/book/${data.isbn}`)}
              >
                Baca Buku elektronik
              </Button>
            </div>
          )
        } else {
          if (data.average_rating === 0) {
            return (
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonGreen}
                style={{marginLeft: 10}}
              >
                Lihat Sertifikat
              </Button>
            )
          } else {
            return (
              <RatingComponent
                averageRating={Number(data.average_rating || 0)}
                ratingFilter={true}
                isSmallRating
                totalEnrollment={data.count_reviews}
              />
            )
          }
        }
      }
      /*   else if (type === LearningType.EVENT) {
        if (data?.isFinished) {
          return (
            <CustomFlex>
              {data?.rating === 0 ? (
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.buttonGreen}
                >
                  Leave a rating
                </Button>
              ) : (
                <div className="mt-3 mr-10">
                  <RatingComponent
                    // averageRating={Number(data?.rating || 0)}
                    averageRating={0}
                    ratingFilter={true}
                    isSmallRating
                  />
                </div>
              )}
              <Button variant="outlined" color="primary" style={{marginLeft: 10}}>
                View Certificate
              </Button>
            </CustomFlex>
          )
        } else {
          return (
            <Button variant="contained" className={classes.buttonLightBlue}>
              Attend
            </Button>
          )
        }
      }  */
    } else if (type === LearningType.SOCIAL_LEARNING) {
      return (
        <div className="flex">
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonLightBlueOutlined}
            component="a"
            href={`${SOSMED_APP_URL}/social-learning/${data.id}/discussion`}
            target="_blank"
          >
            Lihat Pembelajaran sosial
          </Button>
        </div>
      )
    } else if (type === LearningType.MICRO_LEARNING) {
      return (
        <CustomFlex className="flex-col md:flex-row">
          <Button
            variant="outlined"
            color="primary"
            className={classNames(
              classes.buttonLightBlueOutlined,
              'w-full md:w-auto lg:w-auto'
            )}
            onClick={() => navigate(`/learner/micro_learning/${microdata?.id}`)}
          >
            Lihat Pembelajaran Mikro
          </Button>
          {data.schedule === '0000000' && (
            <Button
              variant="contained"
              color="primary"
              className="mt-2 w-full md:ml-4 md:mt-0 md:w-auto lg:w-auto"
              onClick={() =>
                navigate(`/learner/micro_learning/${microdata?.id}`)
              }
            >
              Mulai Pembelajaran Mikro
            </Button>
          )}
        </CustomFlex>
      )
    }
  }

  const socialLearningAdditional = () => (
    <>
      <CustomTypography fsize="14px" fcolor="gray" mb="6px">
        {socialLearningPriv[data.privacy]}
      </CustomTypography>
      {!data.is_finished && !data.is_awaiting_contrib && (
        <CustomTypography fsize="14px" fcolor="#039be5" mb="6px">
          Dalam pelaksanaan
        </CustomTypography>
      )}
      {data.is_awaiting_contrib && !data.is_finished && (
        <CustomTypography fsize="14px" fcolor="#ffa000" mb="6px">
          Menunggu Persetujuan
        </CustomTypography>
      )}
      {data.is_finished && (
        <CustomTypography fsize="14px" fcolor="#4caf50" mb="6px">
          Sesi Berakhir
        </CustomTypography>
      )}
    </>
  )

  const microLearningScore = () => {
    return (
      <FlexColumnCenter className="items-start md:items-center lg:items-center">
        {data?.progress === 100 ? (
          <CustomFlex direction="column" alignItems={isMobile ? '' : 'center'}>
            <CustomTypography fweight={isMobile ? '' : 'bold'} fsize="14px">
              Skor
            </CustomTypography>
            <CustomTypography
              fweight="bold"
              fsize={isMobile ? '28px' : '32px'}
              align={isMobile ? 'left' : 'right'}
              fcolor="#4CAF50"
            >
              {data?.score}
            </CustomTypography>
          </CustomFlex>
        ) : (
          <CustomFlex direction="column" alignItems={isMobile ? '' : 'right'}>
            <CustomTypography fweight={isMobile ? '' : 'bold'} fsize="14px">
              Pertanyaan Dijawab ({data?.question_generated_count}/
              {microdata?.question_count})
            </CustomTypography>
            <CustomTypography
              fweight="bold"
              fsize={isMobile ? '28px' : '32px'}
              align={isMobile ? 'left' : 'right'}
              fcolor={data?.progress === 0 ? '#A9A8A8' : '#FFA000'}
            >
              {data?.progress}%
            </CustomTypography>
          </CustomFlex>
        )}
      </FlexColumnCenter>
    )
  }

  const courseScore = () => (
    <FlexColumnCenter className="w-1/2 mb-4 md:pl-6 md:m-0">
      <Grid container className="flex-col md:flex-row lg:flex-row">
        <Grid item md={4} className="order-2 md:order-1 lg:order-1">
          <span className="text-sm md:font-semibold">
            {isMobile ? (
              `${courseCompletionRate}%`
            ) : (
              <>
                {courseCompletionRate === 100 ? 'Selesai' : 'Progres'} (
                {courseCompletionRate}%)
              </>
            )}
          </span>
        </Grid>
        <Grid item md={8} className="order-1 md:order-2 lg:order-2">
          <BorderLinearProgress
            variant="determinate"
            value={courseCompletionRate || 0}
            className={classes.linearProgress}
          />
        </Grid>
      </Grid>
    </FlexColumnCenter>
  )

  const LIMIT_AVATAR = isMobile ? 3 : 5
  const socialLearningParticipants = () => {
    if (data.members.length > 0) {
      return (
        <div className="flex flex-col mb-2 md:justify-end md:mb-0">
          <div className="flex flex-col md:items-end">
            <CustomTypography fsize="12px" mb="8px">
              Peserta
            </CustomTypography>
            <div className="flex">
              {data.members.slice(0, LIMIT_AVATAR).map((val: any) => {
                return (
                  <Tooltip title={val?.user?.name} key={val.id}>
                    <Avatar
                      src={val?.user?.avatar}
                      className={classNames(
                        classes.smallAvatar,
                        'mr-1 cursor-pointer'
                      )}
                    />
                  </Tooltip>
                )
              })}
              {data.members.length > LIMIT_AVATAR && (
                <Avatar className={classes.smallAvatar}>
                  <CustomTypography fcolor="#fff" fsize="12px">
                    {data.members.length - LIMIT_AVATAR}+
                  </CustomTypography>
                </Avatar>
              )}
            </div>
          </div>
        </div>
      )
    }
  }

  const renderScore = () => {
    switch (type) {
      case LearningType.COURSE:
        return courseScore()
      case LearningType.MICRO_LEARNING:
        return microLearningScore()
      case LearningType.SOCIAL_LEARNING:
        return socialLearningParticipants()
      default:
        return null
    }
  }

  const containerWidth =
    type === LearningType.MICRO_LEARNING
      ? '65%'
      : type === LearningType.COURSE
      ? '55%'
      : '100%'

  const thumbnailSrc =
    type === LearningType.SOCIAL_LEARNING
      ? data.avatar_url
      : type === LearningType.MICRO_LEARNING
      ? microdata.icon_url
      : data.thumbnail

  const thumbnailClassname =
    type === LearningType.SOCIAL_LEARNING
      ? classes.squareBanner
      : type === LearningType.BOOK
      ? classes.potraitBanner
      : classes.landscapeBanner

  const title =
    type === LearningType.MICRO_LEARNING
      ? microdata?.name
      : data.title ?? data.name

  const creatorUser =
    type === LearningType.MICRO_LEARNING
      ? microdata?.global_user?.name
      : data.global_user?.name ?? data.CreatorUser?.name

  return (
    <Card className={classes.root} style={{height: 'auto'}}>
      <div
        className="flex"
        style={{
          width: isMobile ? '100%' : containerWidth,
        }}
      >
        <Avatar
          variant="square"
          src={thumbnailSrc}
          className={
            isMobile ? 'w-24 h-full object-cover mr-3' : thumbnailClassname
          }
        />
        <div className="flex flex-col justify-between w-full">
          <div>
            <LinesEllipsis
              text={title}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
              style={{fontSize: 16, fontWeight: 'bold', marginBottom: 6}}
            />
            <LinesEllipsis
              text={creatorUser}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
              style={{fontSize: 14, color: 'gray', marginBottom: 6}}
            />
            {isMobile && renderScore()}

            {type === LearningType.SOCIAL_LEARNING &&
              socialLearningAdditional()}
          </div>
          <div>{renderButton()}</div>
        </div>
      </div>
      {!isMobile && renderScore()}
    </Card>
  )
}

export default MyLearningCardComponent
