import {useNavigate} from 'react-router-dom'
import {Field} from 'react-final-form'
import {useApolloClient} from '@apollo/client'
import {useTitle} from 'hoofd'

import CreateWizard from '../../../components/input-forms/CreateWizard'
import FormValidatedText from '../../../components/input-forms/FormValidatedText'
import {
  combineValidators,
  isRequired,
  showErrorOnChange,
} from '../../../components/input-forms/validators'

import AcademyCategoryFields from '../../../components/contributor/forms/AcademyCategoryFields'

import {GET_COURSE_SLUG_AVAILABILITY} from '../../../graphql/queries'
import {CREATE_COURSE} from '../../../graphql/mutations'
import {USER_ID} from '../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../utils/contributor-helpers'

export const validateCourseTitle = combineValidators(
  isRequired,

  (value: string) =>
    value.length < 5 &&
    `Course title is too short, it must be 5 characters or longer`,

  (value: string) =>
    value.length > 150 &&
    `Course title is too long, only 150 characters can be submitted`
)

const RE_VALID_SLUG = /^[a-z0-9-]+$/

export const validateCourseSlug = combineValidators(
  isRequired,

  (value: string) =>
    value.length > 150 &&
    `Slug is too long, only 150 characters can be submitted`,

  (value: string) =>
    !RE_VALID_SLUG.test(value) &&
    `Slug can only contain lowercase letters "a-z", numbers "0-9" and hyphen "-"`,

  (value: string) => value === 'create' && `Slug already used`
)

interface FormValues {
  title: string
  category: number
  subcategory: number
  slug: string
}

const ContributorCourseCreatePage = () => {
  useTitle(`Create Course`)

  const client = useApolloClient()
  const navigate = useNavigate()

  const handleSubmit = async (data: FormValues) => {
    const {data: availabilityData} = await client.query({
      query: GET_COURSE_SLUG_AVAILABILITY,
      fetchPolicy: 'network-only',
      variables: {slug: data.slug},
    })

    if (availabilityData.academy_courses.length > 0) {
      return {
        slug: `Slug already used`,
      }
    }

    try {
      await client.mutate({
        mutation: CREATE_COURSE,
        variables: {
          objects: {
            creator: USER_ID,
            type: 1,
            title: data.title,
            slug: data.slug,
            category: data.subcategory,
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })

      navigate(`/contributor/courses/${data.slug}/manage`)
    } catch {
      return {
        slug: `Failed to create course`,
      }
    }
  }

  return (
    <CreateWizard
      onSubmit={handleSubmit}
      onCancel={() => navigate(-1)}
      title="Create Course"
    >
      <CreateWizard.Page
        label="Course Title"
        title="How About Course Title?"
        description="It's ok if you can't think of a good title now. You can change it later."
      >
        {/* NOTE(intrnl): we want slug to reflect the title by default */}
        <Field name="slug" subscription={{}}>
          {({input: {onChange}}) => (
            <FormValidatedText
              name="title"
              label="Course Title"
              placeholder="Add course title"
              validate={validateCourseTitle}
              showCharLimit={150}
              fullWidth
              autoFocus
              onChange={(ev) => {
                const value = ev.target.value

                const rand = Math.random().toString(16).slice(2, 7)
                const title = value
                  .toLowerCase()
                  .replace(/\s+/g, '-')
                  .replace(/[^a-z0-9-]/g, '')
                  .slice(0, 48 - rand.length - 1)

                onChange(`${title}-${rand}`)
              }}
            />
          )}
        </Field>
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Course Category"
        title="What category best fits the knowledge you'll share?"
        description="It's ok if you can't think of a good category now. You can change it later."
      >
        <AcademyCategoryFields autoFocus />
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Course Slug"
        title="Give a slug for your new course"
        description="Slug is the short identity of your course. Example 'advance-economics'"
      >
        <FormValidatedText
          name="slug"
          label="Course Slug"
          placeholder="Add course slug"
          validate={validateCourseSlug}
          showError={showErrorOnChange}
          fullWidth
          autoFocus
        />
      </CreateWizard.Page>
    </CreateWizard>
  )
}

export default ContributorCourseCreatePage
