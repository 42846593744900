import React from 'react'
import {Outlet, useParams, useNavigate} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {useTitle} from 'hoofd'
import {useSnackbar} from 'notistack'

import {
  Button,
  Divider,
  LinearProgress,
  Paper,
  Typography,
} from '@material-ui/core'

import Skeleton from 'react-loading-skeleton'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'

import {
  SidebarMenu,
  SidebarNavItem,
} from '../../../../components/contributor/SidebarMenu'
import ContributorCreatorDetailLayout from '../../../../components/contributor/layout/ContributorCreatorDetailLayout'

import {USER_ID} from '../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../utils/contributor-helpers'
import {
  GET_CONTRIBUTOR_EVENT_DETAILS,
  GET_CONTRIBUTOR_EVENT_DETAILS_PROGRESS,
} from '../../../../graphql/queries'
import {CONTRIBUTOR_UPDATE_EVENT} from '../../../../graphql/mutations'

const enum EventStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DRAFT = 'draft',
}

const StatusColors: any = {
  [EventStatus.DRAFT]: undefined,
  [EventStatus.ACTIVE]: '#4CAF50',
  [EventStatus.PENDING]: '#EF4D5E',
} as const

const ContributorEventDetailPageLayout = () => {
  const {eventId} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const navigate = useNavigate()

  const [updatingData, setUpdatingData] = React.useState(false)

  const {data, refetch} = useQuery(GET_CONTRIBUTOR_EVENT_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      eventId: eventId,
      creator: USER_ID,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const [updateEvent] = useMutation(CONTRIBUTOR_UPDATE_EVENT, {
    context: INSTRUCTOR_CONTEXT,
  })

  const onUpdateStatus = (status: string) => {
    if (!status) {
      return
    }
    setUpdatingData(true)

    updateEvent({
      variables: {
        eventId,
        set: {
          status,
        },
      },
    })
      .then(() => {
        refetch()
        setUpdatingData(false)
      })
      .catch(() => {
        enqueueSnackbar(`Failed to update status`, {variant: 'error'})
        setUpdatingData(false)
      })
  }

  const {data: eventProgress} = useQuery(
    GET_CONTRIBUTOR_EVENT_DETAILS_PROGRESS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        eventId: eventId,
      },
      context: INSTRUCTOR_CONTEXT,
    }
  )
  const event = data?.event_schedules[0]
  const progress = eventProgress?.getProgressEventDetail

  const Image = event && event.thumbnail ? 'img' : 'div'

  useTitle(event?.title || '')

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  if (data && data.event_schedules.length < 1) {
    return (
      <ContributorCreatorDetailLayout label="Event Detail" to="../events">
        <div>Event not found</div>
      </ContributorCreatorDetailLayout>
    )
  }

  return (
    <ContributorCreatorDetailLayout label="Event Detail" to="../events">
      <Paper className="flex items-stretch gap-12 p-6">
        <div className="my-2 flex grow flex-col justify-between">
          <div>
            {event ? (
              <LinesEllipsis
                component={Typography}
                text={event.title}
                maxLine={3}
                color="primary"
                className="mb-1 text-lg font-bold"
              />
            ) : (
              <p className="mb-1 text-lg">
                <Skeleton width="50%" />
              </p>
            )}

            <Typography
              color="textSecondary"
              className="mb-4 text-sm capitalize"
              style={{color: event && StatusColors[event.status]}}
            >
              {event ? event.status : <Skeleton width={45} />}
            </Typography>
          </div>

          <div>
            {!event ? null : event.status === EventStatus.DRAFT ? (
              <>
                <Typography className="mb-2">
                  Progress {Math.round(progress?.total || 0)}%
                </Typography>

                <LinearProgress
                  variant="determinate"
                  value={progress?.total || 0}
                  color="secondary"
                  className="h-2 grow rounded"
                  classes={{bar: 'rounded'}}
                />

                <div className="mt-6 flex gap-4">
                  <Button
                    color="primary"
                    variant="outlined"
                    disabled={updatingData}
                    onClick={() => onUpdateStatus('draft')}
                  >
                    Save Draft
                  </Button>

                  <Button
                    color="primary"
                    variant="contained"
                    disableElevation
                    disabled={updatingData}
                    onClick={() => onUpdateStatus('pending')}
                  >
                    Publish
                  </Button>
                </div>
              </>
            ) : event.status === EventStatus.PENDING ? (
              <div className="mt-6 flex gap-4">
                <Button
                  color="inherit"
                  variant="outlined"
                  className={!updatingData ? 'text-red-500' : ''}
                  disabled={updatingData}
                  onClick={() => onUpdateStatus('draft')}
                >
                  Unpublish
                </Button>
              </div>
            ) : event.status === EventStatus.ACTIVE ? (
              <div className="mt-6 flex gap-4">
                <Button
                  color="inherit"
                  variant="outlined"
                  className="text-red-500"
                  disabled={updatingData}
                  onClick={() => onUpdateStatus('pending')}
                >
                  Unpublish
                </Button>
              </div>
            ) : null}
          </div>
        </div>

        <Image
          alt={event?.title}
          src={event?.thumbnail}
          className="aspect-video w-96 shrink-0 rounded bg-gray-200 object-cover"
        />
      </Paper>

      <div className="grid items-baseline gap-8 lg:grid-cols-[320px_minmax(0,_1fr)]">
        <SidebarMenu>
          <SidebarNavItem to="." end>
            Dashboard
          </SidebarNavItem>
          <SidebarNavItem to="manage">Manage</SidebarNavItem>

          <Divider />

          <Button
            onClick={() =>
              navigate(`/contributor/event/${event?.id}/preview`, {
                state: {prevRouteName: 'Learning Dashboard'},
              })
            }
            color="secondary"
            variant="contained"
            disableElevation
            className="mx-4 my-2"
          >
            Preview My Event
          </Button>
        </SidebarMenu>

        <Outlet />
      </div>
    </ContributorCreatorDetailLayout>
  )
}

export default ContributorEventDetailPageLayout
