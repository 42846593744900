import React, {ReactElement} from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {LearningType} from '../../../../utils/enum'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import {useLocation} from 'react-router-dom'
import SearchSeeAll from '../SearchSeeAll.component'
import {ICourse} from '../../../../interfaces'
import {SeeAllPageContext} from '../../../../pages/learner/see-all'
import {useQuery} from '@apollo/client'
import {GET_RECOMMENDED_TRENDING_COURSES} from '../../../../graphql/queries'

import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'

interface CourseProps extends SeeAllPageContext {}

const Course = (props: CourseProps): ReactElement => {
  const LIMIT = 16
  const [search, setSearch] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [page, setPage] = React.useState(0)
  const [total, setTotal] = React.useState(0)
  const [courses, setCourses] = React.useState<ICourse[]>([])

  const disableLoading = () => setLoading(false)
  const resetData = () => {
    setCourses([])
    setTotal(0)
    setPage(0)
  }

  const isFree = React.useMemo(() => {
    return props.filter.priceMin === '0' && props.filter.priceMax === '0'
  }, [props.filter])

  const isPaid = React.useMemo(() => {
    return (
      Number(props.filter.priceMin) > 0 && Number(props.filter.priceMax) > 0
    )
  }, [props.filter])

  const {
    data,
    refetch: refetchData,
    loading: loadingData,
  } = useQuery(GET_RECOMMENDED_TRENDING_COURSES, {
    variables: {
      isRecommended: props.type !== 'trending',
      sortByTrending: props.type === 'trending',
      offset: page,
      limit: LIMIT,
      search: search,
      rating: Number(props.filter.rating),
      min_price: Number(props.filter.priceMin),
      max_price: Number(props.filter.priceMax),
      language: props.filter.languagesGQL,
      category_id: props.filter.categoriesGQL,
      level: props.filter.levelsGQL,
      free: isFree || !isPaid,
      paid: isPaid || !isFree,
    },
  })

  const location = useLocation()

  const isRecommended = location.pathname.includes('recommended')

  const title = isRecommended ? 'Rekomendasi di SmartASN' : 'Tren di SmartASN'

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  React.useEffect(() => {
    if (data) {
      const _data = courses
      if (data?.getListCourses?.count > 0) {
        setTotal(data?.getListCourses?.count)
      }
      data?.getListCourses?.courses.map((item: ICourse) =>
        _data.push({
          ...item,
          Price: {
            ...item.Price,
            pricing: {IDR: String(item.Price.pricing_idr), USD: ''},
          },
        })
      )
      setCourses(_data)
    }
  }, [data, courses])

  React.useEffect(() => {
    setLoading(true)
    resetData()

    setTimeout(refetchData, 1000)

    setTimeout(disableLoading, 1000)
  }, [refetchData, props.filter, search])

  const fetchMoreData = () => {
    setPage(page + LIMIT)
  }

  return (
    <div>
      <SearchSeeAll value={search} handleChange={handleChange} />
      <div className="mt-8">
        <p className="mt-5 text-[#a9a8a8] text-sm">
          {total} Hasil dari {title}
        </p>
      </div>
      <div>
        {(courses.length === 0 && loadingData) || loading ? (
          <LoadingCardComponent length={4} />
        ) : courses.length === 0 ? (
          <NoListComponent
            title="Tidak ada hasil yang ditemukan"
            subTitle="Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda"
          />
        ) : (
          <InfiniteScroll
            dataLength={data?.getListCourses?.count}
            next={fetchMoreData}
            hasMore={
              data?.getListCourses?.count < LIMIT
                ? false
                : data?.getListCourses?.count > page
            }
            loader={<LoadingCardComponent length={4} />}
          >
            <div className="grid grid-cols-2 lg:grid-cols-4">
              {courses.map((e: ICourse, index: number) => (
                <LearnerCatalogCard
                  type={LearningType.COURSE}
                  item={e}
                  key={index}
                  hidePrice={false}
                  width="w-full"
                />
              ))}
            </div>
          </InfiniteScroll>
        )}
      </div>
    </div>
  )
}

export default Course
