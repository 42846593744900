import {type ReactNode} from 'react'

import {Link} from 'react-router-dom'

import {IconButton, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../utils-components/VendorStyles'

export interface ContributorCreatorDetailLayoutProps {
  label: string
  to?: string
  children?: ReactNode
}

const ContributorCreatorDetailLayout = (
  props: ContributorCreatorDetailLayoutProps
) => {
  const {label, to, children} = props

  return (
    <>
      <TabsWrapper>
        <StyledTabs value="detail" centered className="bg-[#f6f8f9]">
          <StyledTab label={label} value="detail" className="font-bold" />
        </StyledTabs>
      </TabsWrapper>

      <div className="grid gap-4 px-4 md:px-20 py-4 md:py-8 max-w-screen-xl box-content mx-auto">
        <div className="h-14 flex items-center">
          {to && (
            <IconButton component={Link} to={to} edge="start">
              <ArrowBackIcon />
            </IconButton>
          )}

          <Typography className="font-bold">Back to Dashboard</Typography>
        </div>

        {children}
      </div>
    </>
  )
}

export default ContributorCreatorDetailLayout
