import axios from 'axios'
import {useSnackbar} from 'notistack'
import {QueryObserverResult, useMutation, useQuery} from 'react-query'
import {
  API_URL,
  JW_URL,
  SSO_URL,
  TOKEN,
  SOSMED_API_URL,
  UPLOAD_URL,
} from '../utils/globals'

const _createAxiosInterceptor = (url: any) => {
  const axiosCreate = axios.create({
    baseURL: url,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'es',
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })

  axiosCreate.interceptors.request.use(
    (config) => {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${TOKEN}`
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    }
  )

  axiosCreate.interceptors.response.use(
    (response) => {
      return response.data
    },
    (error) => {
      if (process.env.NODE_ENV !== 'development') {
        if (
          error.response.status === 401 &&
          error.response.config.baseURL !== JW_URL
        ) {
          // @ts-ignore
          window.location = SSO_URL
        }
      } else {
        console.error(error.response)
      }

      return Promise.reject(error)
    }
  )

  return axiosCreate
}

const service = _createAxiosInterceptor(API_URL)
const serviceJW = _createAxiosInterceptor(JW_URL)
const serviceSocmed = _createAxiosInterceptor(SOSMED_API_URL)
const serviceUpload = _createAxiosInterceptor(UPLOAD_URL)

type ServiceProps = {
  data: any
  isLoading: boolean
  error: any
  isError: boolean
  refetch: () => Promise<QueryObserverResult<any, any>>
  isFetching: boolean
}

const useQueryService = (key: any, url: string, properties?: any) => {
  const query: ServiceProps = useQuery(
    key,
    () => {
      return service.get(`${API_URL}/${url}`)
    },
    properties
  )
  const {enqueueSnackbar} = useSnackbar()

  if (query.isError) {
    console.error(key, query.error)
    enqueueSnackbar(query.error.message)
  }

  return query
}

const useQueryServiceSocmed = (key: any, url: string) => {
  const query: ServiceProps = useQuery(key, () => {
    return serviceSocmed.get(`${SOSMED_API_URL}/${url}`)
  })
  const {enqueueSnackbar} = useSnackbar()

  if (query.isError) {
    console.error(key, query.error)
    enqueueSnackbar(query.error.message)
  }

  return query
}

const useQueryJWService = (
  key: string[],
  slug: string,
  mediaKey: string,
  enabled: boolean
) => {
  const query: ServiceProps = useQuery(
    key,
    () =>
      serviceJW.get(
        slug ? `${JW_URL}/${slug}/${mediaKey}` : `${JW_URL}/${mediaKey}`
      ),
    {
      enabled,
    }
  )

  if (query.isError) {
    console.error(key, query.error)
  }

  return query
}

const useMutationService = (url: string, type: 'POST' | 'DELETE' | 'PATCH') => {
  const mutation = useMutation((obj: any) => {
    if (type === 'POST') {
      return service.post(`${API_URL}/${url}`, obj)
    } else if (type === 'DELETE') {
      return service.delete(`${API_URL}/${url}`)
    } else if (type === 'PATCH') {
      return service.patch(`${API_URL}/${url}`, obj)
    } else {
      return service.post(`${API_URL}/${url}`, obj)
    }
  })

  const {enqueueSnackbar} = useSnackbar()

  if (mutation.isError) {
    enqueueSnackbar(JSON.stringify(mutation.error))
  }

  return mutation
}

const useMutationServiceSocmed = (
  url: string,
  type: 'POST' | 'DELETE' | 'PATCH'
) => {
  const mutation = useMutation((obj: any) => {
    if (type === 'POST') {
      return serviceSocmed.post(`${SOSMED_API_URL}/${url}`, obj)
    } else if (type === 'DELETE') {
      return serviceSocmed.delete(`${SOSMED_API_URL}/${url}`)
    } else if (type === 'PATCH') {
      return serviceSocmed.patch(`${SOSMED_API_URL}/${url}`, obj)
    } else {
      return serviceSocmed.post(`${SOSMED_API_URL}/${url}`, obj)
    }
  })

  const {enqueueSnackbar} = useSnackbar()

  if (mutation.isError) {
    enqueueSnackbar(JSON.stringify(mutation.error))
  }

  return mutation
}

const useMutationUpload = () => {
  const mutation = useMutation((obj: any) => {
    return serviceUpload.post(`${UPLOAD_URL}`, obj)
  })

  const {enqueueSnackbar} = useSnackbar()

  if (mutation.isError) {
    enqueueSnackbar(JSON.stringify(mutation.error))
  }

  return mutation
}

export {
  useQueryService,
  useQueryServiceSocmed,
  useMutationService,
  useQueryJWService,
  useMutationServiceSocmed,
  useMutationUpload,
  serviceUpload,
}
