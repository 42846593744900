import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import TabRow from '../../catalog/tabs/TabRow.component'
import {LearningType} from '../../../../utils/enum'
import {LIST_ALL_EVENTS} from '../../../../provider/services/Event.service'
import LoadingCard from '../../../loading/LoadingCard.component'
import {useSnackbar} from 'notistack'
import {IEvent} from '../../../../interfaces/Event.interface'

type EventSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: {
    rating: string
    categoriesREST: string
    levelsREST: string
    languagesREST: string
    priceMin: string
    priceMax: string
    priceGQL: {
      _lte: number
      _gte: number
    }
    categoriesGQL: []
    ratingGQL: []
    contributorLabels: []
  }
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  activeTab: LearningType
}

function EventSearchFilterTab(props: EventSearchFilterTabProps) {
  const {countResult, setCountResult} = props
  const {enqueueSnackbar} = useSnackbar()
  const [loading, setLoading] = React.useState(false)
  // const [filterPriceCount, setFilterPriceCount] = React.useState(-1)

  const disableLoading = () => setLoading(false)

  const {
    data: dataListAllEvents,
    isLoading: loadingListAllEvents,
    error: errorListAllEvents,
    refetch: refetchListAllEvents,
  } = LIST_ALL_EVENTS(
    -1,
    props.searchKeyword,
    props.academicFilter.priceMin,
    props.academicFilter.priceMax,
    props.academicFilter.categoriesREST,
    props.priceFilter === 'Latest'
      ? 'orders[0][0]=dateAdded&orders[0][1]=desc'
      : ''
  )

  React.useEffect(() => {
    if (dataListAllEvents?.total !== countResult.countResultEvent) {
      setCountResult({
        ...countResult,
        countResultEvent: dataListAllEvents?.total,
      })
    }
  }, [dataListAllEvents, countResult, setCountResult])

  React.useEffect(() => {
    setLoading(true)

    setTimeout(refetchListAllEvents, 1000)
    setTimeout(disableLoading, 1000)
  }, [
    props.searchKeyword,
    props.academicFilter,
    props.priceFilter,
    refetchListAllEvents,
    props.activeTab,
  ])

  if (loadingListAllEvents || loading) {
    return <LoadingCard length={2} isContributor />
  } else if (errorListAllEvents) {
    enqueueSnackbar(JSON.stringify(errorListAllEvents), {
      variant: 'error',
    })
  }

  return (
    <Grid container className="px-2 pb-2">
      <TabRow
        title={''}
        data={
          props.priceFilter === 'Price (Low - High)'
            ? dataListAllEvents.data.sort(
                (a: IEvent, b: IEvent) =>
                  Number(a.pricing?.IDR) - Number(b.pricing?.IDR)
              )
            : props.priceFilter === 'Price (High - Low)'
            ? dataListAllEvents.data.sort(
                (a: IEvent, b: IEvent) =>
                  Number(b.pricing?.IDR) - Number(a.pricing?.IDR)
              )
            : dataListAllEvents.data
        }
        type={LearningType.EVENT}
        isAll={true}
        isFilterCatalog={true}
      />
    </Grid>
  )
}

export default EventSearchFilterTab
