import EventActivityCard from './EventActivityCard'

const ContributorEventDashboardPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <EventActivityCard />
    </div>
  )
}

export default ContributorEventDashboardPage
