import {useState, useEffect, useCallback} from 'react'
import {
  AccordionSummary,
  Typography,
  Theme,
  createStyles,
  makeStyles,
  List,
  ListItemText,
  ListItemIcon,
  Divider,
  Button,
  ListItemSecondaryAction,
} from '@material-ui/core'
import {
  ExpandMore,
  PlayCircleFilled,
  Subject,
  ListAlt,
} from '@material-ui/icons'
import {
  CustomAccordion,
  CustomAccordionDetails,
  CustomListItem,
} from '../../../utils-components/GlobalStyles'
import {
  ICourse,
  ISection,
  ILesson,
  ICourseEnrollment,
} from '../../../../interfaces/Course.interface'
import classNames from 'classnames'
import {UPDATE_COURSE_ENROLLMENT_PROGRESS} from '../../../../provider/services/Course.service'

import VideoLesson from './lesson-type/VideoLesson.component'
import ArticleLesson from './lesson-type/ArticleLesson.component'
import QuizLesson from './lesson-type/quiz/QuizLesson.component'
import {convertToRupiah} from '../../../../utils/helpers'
import LessonCompletionChecbox from './LessonCompletionCheckbox.component'
import {useParams, useNavigate} from 'react-router-dom'

type LessonProps = {
  course: ICourse
  refetchCourseDetail: () => void
  isPreview?: boolean
}

type LessonControllerProps = {
  lesson: ILesson
  courseSlug: string
  coursePrice: string
  enrollment: ICourseEnrollment
  creatorId: string
  onVideoEnd?: () => void
  isPreview?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    headingCourseContent: {
      fontSize: theme.typography.pxToRem(15),

      color: '#000',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: 400,
      color: '#004A62',
    },
    subHeading: {
      fontSize: theme.typography.pxToRem(12),
      fontWeight: 400,
      color: '#000',
    },
    lessonDetailContainer: {
      minHeight: 380,
      border: 2,
    },
    courseThumbnail: {
      width: '100%',
      height: 415,
    },
  })
)

const LessonController = (props: LessonControllerProps) => {
  if (!props.enrollment && !props.isPreview) {
    return (
      <div className="flex flex-col w-full justify-center items-center pt-24">
        <Typography variant="h5">Sesi ini terkunci.</Typography>
        <Typography variant="h5">
          {' '}
          Silahkan beli untuk mengikuti kursus ini
        </Typography>
        <Typography color="primary" variant="h5" className="mt-12">
          {Number(props.coursePrice || 0) === 0
            ? 'Gratis'
            : convertToRupiah(props.coursePrice)}
        </Typography>
        <Button variant="contained" color="primary" className="w-1/3 mt-12">
          Beli Sekarang
        </Button>
      </div>
    )
  } else if (props.lesson.lessonType === 'article') {
    return <ArticleLesson lesson={props.lesson} />
  } else if (props.lesson.lessonType === 'video') {
    return (
      <VideoLesson video={props.lesson.videoUrl} onFinish={props.onVideoEnd} />
    )
  } else if (props.lesson.lessonType === 'quiz') {
    return (
      <QuizLesson
        lesson={props.lesson}
        contributorId={props.creatorId}
        isPreview={props.isPreview}
      />
    )
  } else {
    return <></>
  }
}
const Lesson = (props: LessonProps) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const [lesson, setLesson] = useState<ILesson>({
    id: 0,
    courseId: 0,
    sectionId: 0,
    caption: '',
    title: '',
    summary: '',
    duration: '',
    videoType: 0,
    videoUrl: '',
    lessonType: '',
    resources: [],
    attachmentType: '',
    dateAdded: '',
    lastModified: '',
    order: 0,
    section_id: 0,
    Questions: [],
  })
  const [currentLessonOrder, setCurrentLessonOrder] = useState<number>(0)
  const [lessons, setLessons] = useState<ILesson[]>([])
  const {activeLessonId} = useParams()

  const {
    mutate: onSubmitCourseEnrollmentProgress,
    isSuccess: isSuccessCourseEnrollmentProgress,
  } = UPDATE_COURSE_ENROLLMENT_PROGRESS(props.course.Enrollment?.id)

  const mappingLessonOrder = useCallback(() => {
    const _lessons: ILesson[] = props.course.Sections.flatMap(
      (section: ISection) => section.Lessons
    )
    _lessons.forEach((lesson: ILesson, index: number) => {
      lesson.order = index + 1
    })

    return _lessons
  }, [props.course.Sections])

  useEffect(() => {
    setLessons(mappingLessonOrder())
  }, [mappingLessonOrder])

  useEffect(() => {
    if (lesson) {
      setCurrentLessonOrder(lesson.order)
    }
  }, [lesson])

  useEffect(() => {
    if (currentLessonOrder > 0) {
      setLesson(lessons[currentLessonOrder - 1])
    }
  }, [currentLessonOrder, lessons])

  useEffect(() => {
    if (activeLessonId && lessons.length > 0) {
      const activeLesson = lessons.find(
        (lesson: ILesson) => lesson.id === Number(activeLessonId)
      )
      setLesson(activeLesson || lesson)
    }
  }, [activeLessonId, lessons, lesson])

  const onVideoEnd = () => {
    onSubmitCourseEnrollmentProgress({
      courseId: props.course.id,
      enrollmentId: props.course.Enrollment.id,
      lessonId: lesson.id,
      grade: 100,
      status: 'DONE',
    })
    props.refetchCourseDetail()
    if (props.course.countLesson > currentLessonOrder) {
      setCurrentLessonOrder(currentLessonOrder + 1)
    }
  }

  return (
    <div className="flex flex-row justify-between">
      <div className="w-3/5 mr-10">
        <div className={classes.lessonDetailContainer}>
          {props.course.video && lesson?.id === 0 ? (
            <VideoLesson slug={props.course.slug} video={props.course.video} />
          ) : !props.course.video && lesson?.id === 0 ? (
            <img
              src={
                props.course.thumbnail ||
                require('../../../../assets/images/defaultCourseCover.image.png')
              }
              alt="test"
              className={classes.courseThumbnail}
            />
          ) : (
            <LessonController
              courseSlug={props.course.slug}
              enrollment={props.course.Enrollment}
              lesson={lesson}
              coursePrice={props.course.Price?.pricing?.IDR}
              creatorId={props.course.CreatorUser.id}
              onVideoEnd={onVideoEnd}
              isPreview={props.isPreview}
            />
          )}
        </div>

        <div
          className={`flex flex-row justify-between mt-4 ${
            lessons.length === currentLessonOrder ? 'invisible' : 'visible'
          }`}
          style={{position: 'relative'}}
        >
          <Button
            size="large"
            className={`w-32 ${currentLessonOrder === 0 && 'invisible'}`}
            variant="outlined"
            color={lesson.order > 1 ? 'primary' : 'default'}
            disabled={currentLessonOrder === 0 || lesson.order === 1}
            onClick={() => {
              setCurrentLessonOrder(currentLessonOrder - 1)
            }}
          >
            Sebelumnya
          </Button>
          <Button
            size="large"
            className={`w-32`}
            variant="contained"
            color={
              currentLessonOrder >= props.course.countLesson
                ? 'default'
                : 'primary'
            }
            disabled={currentLessonOrder >= props.course.countLesson}
            onClick={() => {
              return setCurrentLessonOrder(currentLessonOrder + 1)
            }}
          >
            Selanjutnya
          </Button>
        </div>
      </div>
      <div className="w-2/5 h-96 overflow-auto border-2">
        <CustomAccordion expanded>
          <AccordionSummary>
            <div className="w-full flex flex-row justify-between">
              <Typography
                className={classNames(
                  classes.headingCourseContent,
                  'font-bold'
                )}
              >
                Konten Kursus
              </Typography>
              <Typography className={classes.headingCourseContent}>
                {props.course.countLesson} Pelajaran
              </Typography>
            </div>
          </AccordionSummary>
        </CustomAccordion>
        {props.course.Sections.map((section: ISection, index: number) => {
          let preCountLesson = 0
          if (index > 0) {
            // preCountLesson += section.countLesson
            preCountLesson += props.course.Sections[index - 1].countLesson
          }
          return (
            <CustomAccordion defaultExpanded key={`section-${index}`}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="flex flex-col">
                  <Typography
                    className={classNames(classes.heading, 'font-bold')}
                  >
                    {section.title}
                  </Typography>
                  <Typography className={classes.subHeading}>
                    {section.Lessons.length} Pelajaran
                  </Typography>
                </div>
              </AccordionSummary>
              <CustomAccordionDetails>
                <List component="nav" className="w-full bg-white">
                  {section.Lessons.sort(
                    ({id: previousLessonId}, {id: currentLessonId}) =>
                      previousLessonId - currentLessonId
                  ).map((lessonItem: ILesson, index: number) => (
                    <div
                      key={`lesson-${index}`}
                      onClick={() => {
                        if (activeLessonId) {
                          return navigate(
                            `/learner/course/${props.course.slug}/${lessonItem.id}`
                          )
                        }
                        setLesson({
                          ...lessonItem,
                          order: index + 1 + preCountLesson,
                        })
                      }}
                    >
                      {index > 0 && <Divider />}
                      <CustomListItem
                        button
                        style={
                          lessonItem.id === lesson.id
                            ? {backgroundColor: '#039BE5'}
                            : {}
                        }
                      >
                        <ListItemIcon>
                          {lessonItem.lessonType === 'video' ? (
                            <PlayCircleFilled />
                          ) : lessonItem.lessonType === 'article' ? (
                            <Subject />
                          ) : (
                            <ListAlt />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={lessonItem.title}
                          secondary={lessonItem.duration}
                        />
                        {props.course.Enrollment && (
                          <ListItemSecondaryAction>
                            <LessonCompletionChecbox
                              course={props.course}
                              lessonId={lessonItem.id}
                              refetchCourseDetail={props.refetchCourseDetail}
                              isActive={lessonItem.id === lesson.id}
                              onSubmitCourseEnrollmentProgress={
                                onSubmitCourseEnrollmentProgress
                              }
                              isSuccessCourseEnrollmentProgress={
                                isSuccessCourseEnrollmentProgress
                              }
                            />
                          </ListItemSecondaryAction>
                        )}
                      </CustomListItem>
                      <Divider />
                    </div>
                  ))}
                </List>
              </CustomAccordionDetails>
            </CustomAccordion>
          )
        })}
      </div>
    </div>
  )
}

export default Lesson
