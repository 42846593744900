import {ReactElement} from 'react'
import FavoriteCategoriesCard from '../../components/card/profileCard/FavoriteCategoriesCard.component'
import LearningCollectionCard from '../../components/card/profileCard/LearningCollectionCard.component'
import LearningDiaryResume from '../../components/card/profileCard/LearningDiaryResume.component'
// import LearningStatisticCard from '../../components/card/profileCard/LearningStatistikCard.component'
import LearningSummaryCard from '../../components/card/profileCard/LearningSummaryCard.component'

const LearningProfile = (): ReactElement => {
  return (
    <>
      <LearningSummaryCard />
      <LearningCollectionCard />
      <LearningDiaryResume />
      {/* <LearningStatisticCard /> */}
      <FavoriteCategoriesCard />
    </>
  )
}

export default LearningProfile
