import {Link as RouterLink, useOutletContext} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import {Checkbox, Divider, Link, Paper, Typography} from '@material-ui/core'

import {CoursePageContext} from '../ContributorCourseDetailPageLayout'
import {GET_CONTRIBUTOR_COURSE_PROGRESS} from '../../../../../graphql/queries'

const MANAGE_FIELDS = [
  {
    label: 'Course Information',
    children: [
      {
        label: 'Course Landing Page',
        key: 'landing_page',
        to: 'landing',
      },
      {
        label: 'Target your students',
        key: 'target',
        to: 'targets',
      },
    ],
  },
  {
    label: 'Content',
    children: [
      {
        label: 'Curriculum',
        key: 'content',
        to: 'curriculums',
      },
    ],
  },
]

const ContributorCourseManagePage = () => {
  const {courseId} = useOutletContext<CoursePageContext>()

  const {data, loading} = useQuery(GET_CONTRIBUTOR_COURSE_PROGRESS, {
    skip: !courseId,
    fetchPolicy: 'cache-and-network',
    variables: {
      courseId: courseId,
    },
  })

  const progress = data?.getProgressCourseDetail

  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        Manage Course
      </Typography>

      <Divider />

      <div className="flex flex-col gap-4 p-6">
        {MANAGE_FIELDS.map((category) => (
          <Paper key={category.label} className="bg-[#f7f8f9]">
            <Typography color="primary" className="px-6 py-4 font-bold">
              {category.label}
            </Typography>

            <Divider />

            <div className="flex flex-col gap-4 py-4 pl-4 pr-6">
              {category.children.map((item) => (
                <div key={item.label} className="flex items-center gap-4">
                  <Checkbox
                    checked={progress ? progress[item.key] > 0 : false}
                    disabled={loading}
                    readOnly
                    color="primary"
                  />
                  <span className="grow text-sm">{item.label}</span>
                  <Link
                    component={RouterLink}
                    to={item.to}
                    color="primary"
                    className="text-sm font-bold"
                  >
                    Manage
                  </Link>
                </div>
              ))}
            </div>
          </Paper>
        ))}
      </div>
    </Paper>
  )
}

export default ContributorCourseManagePage
