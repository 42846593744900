import {useNavigate} from 'react-router-dom'
import {useTitle} from 'hoofd'
import {Field} from 'react-final-form'
import {useMutation, useApolloClient} from '@apollo/client'

import {GET_EVENT_SLUG_AVAILABILITY} from '../../../graphql/queries'
import {CONTRIBUTOR_CREATE_EVENT} from '../../../graphql/mutations'
import {INSTRUCTOR_CONTEXT} from '../../../utils/contributor-helpers'
import {USER_ID} from '../../../utils/globals'

import CreateWizard from '../../../components/input-forms/CreateWizard'
import FormValidatedText from '../../../components/input-forms/FormValidatedText'
import {
  combineValidators,
  isRequired,
  SKIP_VALIDATION,
} from '../../../components/input-forms/validators'

import AcademyCategoryFields from '../../../components/contributor/forms/AcademyCategoryFields'
import EventTypeField from '../../../components/contributor/forms/EventTypeField'

interface FormValues {
  title: string
  category: number
  subcategory: number
  type: string
  slug: string
}

export const validateEventTitle = combineValidators(
  isRequired,

  (value: string) =>
    value.length < 5 &&
    `Event title is too short, it must be 5 characters or longer`,

  (value: string) =>
    value.length > 150 &&
    `Event title is too long, only 150 characters can be submitted`
)

// We don't want to match on empty string here because Manage Landing form
// requires that we fill it in with a random slug if empty
const RE_INVALID_SLUG = /[^a-z0-9-]/g

export const validateEventSlug = combineValidators(
  (value: string) => (value === undefined || value === '') && SKIP_VALIDATION,

  (value: string) =>
    value.length > 150 &&
    `Slug is too long, only 150 characters can be submitted`,

  (value: string) =>
    RE_INVALID_SLUG.test(value) &&
    `Slug can only contain lowercase letters "a-z", numbers "0-9" and hyphen "-"`,

  (value: string) => value === 'create' && `Slug already used`
)

export const validateRequiredEventSlug = combineValidators(
  isRequired,
  validateEventSlug
)

export const getRandomEventSlug = (value: string) => {
  const rand = Math.random().toString(16).slice(2, 7)

  const title = value
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(RE_INVALID_SLUG, '')
    .slice(0, 48 - rand.length - 1)

  return `${title}-${rand}`
}

const ContributorEventCreatePage = () => {
  useTitle('Create Event')
  const navigate = useNavigate()
  const client = useApolloClient()

  const [createEvent] = useMutation(CONTRIBUTOR_CREATE_EVENT, {
    context: INSTRUCTOR_CONTEXT,
  })

  const handleSubmit = async (data: FormValues) => {
    const {data: availabilityData} = await client.query({
      query: GET_EVENT_SLUG_AVAILABILITY,
      fetchPolicy: 'network-only',
      variables: {slug: data.slug},
    })

    if (availabilityData.event_schedules.length > 0) {
      return {
        slug: `Slug already used`,
      }
    }

    await createEvent({
      variables: {
        objects: {
          slug: data.slug,
          type: data.type,
          category: data.subcategory,
          title: data.title,
          creator: USER_ID,
          status: 'draft',
        },
      },
    })
      .then((res: any) => {
        const id = res.data.insert_event_schedules.returning[0].id
        navigate(`/contributor/event/${id}`)
      })
      .catch(() => {
        return {
          slug: `Failed to create course`,
        }
      })
  }

  return (
    <CreateWizard
      onSubmit={handleSubmit}
      onCancel={() => navigate(-1)}
      title="Create Event"
    >
      <CreateWizard.Page
        label="Event Title"
        title="How about Event Title?"
        description="It's ok if you can't think of a good title now. You can change it later."
      >
        <Field name="slug" subscription={{}}>
          {({input: {onChange}}) => (
            <FormValidatedText
              name="title"
              label="Event Title"
              placeholder="Add event title"
              validate={validateEventTitle}
              showCharLimit={150}
              fullWidth
              autoFocus
              onChange={(ev) => {
                const value = ev.target.value
                onChange(getRandomEventSlug(value))
              }}
            />
          )}
        </Field>
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Event Category"
        title="What category fits the event you'll create?"
        description="It's ok if you can't think of a good category now. You can change it later."
      >
        <AcademyCategoryFields autoFocus isEvent />
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Event Type"
        title="What is the type of event you'll create?"
        description="It's ok if you can't think of a right type now. You can change it later."
      >
        <EventTypeField
          name="type"
          validate={isRequired}
          label="Type"
          autoFocus
        />
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Event Slug"
        title="Give a slug for your new event"
        description="Slug is the short identity of your event. Example 'advance-economics'"
      >
        <FormValidatedText
          name="slug"
          validate={validateRequiredEventSlug}
          label="Event Slug"
          placeholder="Add event slug"
          fullWidth
          autoFocus
        />
      </CreateWizard.Page>
    </CreateWizard>
  )
}

export default ContributorEventCreatePage
