import React, {FC, ReactElement} from 'react'
import Skeleton from 'react-loading-skeleton'
import {
  Avatar,
  Button,
  Divider,
  Icon,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {format, formatDistance} from 'date-fns'
import DisplayRatingStar from '../../../review-rating/DisplayRatingStar.component'
import useStyles, {TextBlue} from '../../../utils-components/GlobalStyles'
import {BOOK_REVIEWS} from '../../../../provider/services/Book.service'

const ReviewList: FC<{isbn: string}> = ({isbn}): ReactElement => {
  const classes = useStyles()
  const [pagination, setPagination] = React.useState<{
    limit: number
    offset: number
  }>({limit: 5, offset: 0})
  const [selectedRateFilter, setSelectedRateFilter] = React.useState<
    number | null
  >(null)

  const {data: bookReviewsData, loading: bookReviewLoading} = BOOK_REVIEWS({
    isbn: isbn || '',
    limit: pagination.limit,
    offset: pagination.offset,
    rating: selectedRateFilter,
  })

  if (bookReviewLoading && !bookReviewsData) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }

  return (
    <>
      <div className="flex flex-row w-3/5 pt-4 pb-2 space-x-4">
        <Button variant="outlined" onClick={() => setSelectedRateFilter(null)}>
          Semua
        </Button>

        {[...Array(5)].map((_, index) => {
          index += 1
          return (
            <Button
              key={`filter-rating-e-book-${index}`}
              variant={selectedRateFilter === index ? 'contained' : 'outlined'}
              onClick={() => setSelectedRateFilter(index)}
              onDoubleClick={() => {
                setSelectedRateFilter(null)
              }}
            >
              <Icon fontSize="small" className={classes.skyBlueColor}>
                star
              </Icon>
              <Typography className="ml-1">{index}</Typography>
            </Button>
          )
        })}
      </div>

      <Divider />
      {bookReviewsData?.academy_book_reviews?.length > 0 ? (
        <>
          {bookReviewsData?.academy_book_reviews.map(
            (review: any, index: number) => (
              <div className="flex flex-col" key={`book-review-${index}`}>
                <div
                  className="flex flex-row pt-3 pb-2"
                  key={`book-review-list-${index}`}
                >
                  <Avatar
                    style={{width: 64, height: 64}}
                    src={review.global_user.avatar}
                  />

                  <div className="w-full flex flex-col ml-6 space-y-1">
                    <Typography>
                      {review.global_user.name
                        ?.split(' ')
                        ?.slice(0, 4)
                        ?.join(' ')}
                    </Typography>

                    {review?.global_user?.people_work_placements?.[0] && (
                      <Typography color="textSecondary" variant="caption">
                        {
                          review?.global_user?.people_work_placements?.[0]
                            ?.company_job_profile?.title
                        }{' '}
                        {review?.global_user?.people_work_placements?.[0]
                          ?.company_profile?.brand_name &&
                          'at ' +
                            review?.global_user?.people_work_placements?.[0]
                              ?.company_profile?.brand_name}
                      </Typography>
                    )}

                    <div className="flex flex-row items-center space-x-4">
                      <DisplayRatingStar count={review.rating} />

                      <Tooltip
                        title={format(
                          new Date(review.date_added),
                          'd MMM yyyy'
                        )}
                        placement="right-start"
                      >
                        <Typography color="textSecondary" className="text-xs">
                          {formatDistance(
                            new Date(review.date_added),
                            new Date(),
                            {
                              addSuffix: true,
                            }
                          )}
                        </Typography>
                      </Tooltip>
                    </div>

                    <Typography variant="subtitle1" color="textSecondary">
                      {review.review_text}
                    </Typography>

                    {review.review_reply && review.review_reply_date && (
                      <div
                        className="flex flex-row pt-3"
                        key={`book-review-reply-${index}`}
                      >
                        <Avatar
                          style={{width: 64, height: 64}}
                          src={review.globalUserByReviewReplyUser.avatar}
                        />
                        <div className="w-full flex flex-col ml-6 space-y-1">
                          <Typography>
                            {review.globalUserByReviewReplyUser.name
                              ?.split(' ')
                              ?.slice(0, 4)
                              ?.join(' ')}
                          </Typography>
                          <Typography
                            className={classes.skyBlueColor}
                            variant="caption"
                          >
                            Instructur
                          </Typography>

                          <div className="flex flex-row items-center ">
                            <Tooltip
                              title={format(
                                new Date(review.date_added),
                                'd MMM yyyy'
                              )}
                              placement="right-start"
                            >
                              <Typography
                                color="textSecondary"
                                className="text-xs"
                              >
                                {formatDistance(
                                  new Date(review.date_added),
                                  new Date(),
                                  {
                                    addSuffix: true,
                                  }
                                )}
                              </Typography>
                            </Tooltip>
                          </div>

                          <Typography variant="subtitle1" color="textSecondary">
                            {review.review_reply}
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )
          )}

          <Divider />
        </>
      ) : (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className="italic text-center"
        >
          Tidak ada ulasan untuk rating ini
        </Typography>
      )}

      {pagination.limit <
      bookReviewsData?.academy_book_reviews_aggregate?.aggregate?.count ? (
        <TextBlue
          className="w-fit cursor-pointer"
          onClick={() => {
            setPagination({
              ...pagination,
              limit: pagination.limit + 5,
            })
          }}
        >
          Ulasan Lebih Banyak
        </TextBlue>
      ) : bookReviewsData?.academy_book_reviews_aggregate?.aggregate?.count >
        5 ? (
        <TextBlue
          className="w-fit cursor-pointer"
          onClick={() => {
            setPagination({limit: 5, offset: 0})
          }}
        >
          Lebih Sedikit Ulasan
        </TextBlue>
      ) : (
        ''
      )}
    </>
  )
}

export default ReviewList
