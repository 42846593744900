import gql from 'graphql-tag'

export const GET_RECOMMENDATION_TRENDING_MICRO_LEARNING = gql`
  query (
    $offset: Int
    $limit: Int
    $isRecommended: Boolean
    $sortByTrending: Boolean
    $min_price: Int
    $max_price: Int
    $free: Boolean
    $paid: Boolean
    $search: String
    $rating: Float
    $category_id: [Int]
    $language: [Int]
    $level: [Int]
  ) {
    getListMicrolearning(
      isRecommended: $isRecommended
      sortByTrending: $sortByTrending
      offset: $offset
      limit: $limit
      min_price: $min_price
      max_price: $max_price
      free: $free
      paid: $paid
      search: $search
      rating: $rating
      category_id: $category_id
      language: $language
      level: $level
    ) {
      count
      microlearnings {
        id
        name
        description
        price
        icon_url
        average_rating
        review_count
        question_count
        language_id
        people_skill_types {
          id
          name
        }
        global_user: owner {
          id
          name
          avatar
        }
        category_id
        academy_course_categories {
          id
          name
        }
        level
        global_languages {
          id
          name
        }
      }
    }
  }
`
