import {Route, Routes} from 'react-router-dom'
import ErrorBoundary from '../ErrorBoundary'
import Catalog from '../pages/learner/Catalog.page'
import {LearnerRouter, ContributorRouter, ProfileRouter} from '../routes'
// import PrivateRoute from './PrivateRoute.service'
import {useEffect} from 'react'
import {listenCookieChange} from '../utils/helpers'

const RouteConfig = [
  {
    path: '/',
    element: <Catalog />,
  },
  {path: 'contributor/*', element: <ContributorRouter />},
  {path: 'learner/*', element: <LearnerRouter />},
  {path: 'profile/*', element: <ProfileRouter />},
]

const RouteComponent = RouteConfig.map((route, i) => (
  <Route path={route.path} element={route.element} key={i} />
))

export default function Router() {
  useEffect(() => {
    // detect if user has logged out in another tabs browser
    if (process.env.NODE_ENV !== 'development') {
      listenCookieChange()
    }
  }, [])
  return (
    <ErrorBoundary>
      <Routes>{RouteComponent}</Routes>
    </ErrorBoundary>
  )
}
