import React from 'react'
import { type UseFieldConfig } from 'react-final-form'
// import { useQuery } from '@apollo/client'

import FormSelect, { SelectItem } from '../../input-forms/FormSelect'

// import { GET_PRICING_TIER } from '../../../graphql/queries'
// import { INSTRUCTOR_CONTEXT } from '../../../utils/contributor-helpers'
// import { convertToRupiah } from '../../../utils/helpers'

export interface PriceTierFieldProps {
  name: string
  validate?: UseFieldConfig<any>['validate']
  format?: UseFieldConfig<any>['format']
  parse?: UseFieldConfig<any>['parse']
  label?: string
  className?: string
}

const PriceTierField = (props: PriceTierFieldProps) => {
  const { name, validate, format, parse, label, className } = props

  // const data = [{
  //   disabled: true,
  //   label: "Select Price",
  //   value: ''
  // }, {
  //   value: 0,
  //   Label: "FREE",
  // }]

  const options = React.useMemo(() => {
    const base: SelectItem[] = [
      {
        value: '',
        label: 'Select price',
        disabled: true,
      },
      {
        value: 0,
        label: "FREE",
      }
    ]

    return base


    // const mapped = data.academy_price_tier.map((item: any) => {
    //   const price = item.pricing_idr

    //   const option: SelectItem = {
    //     value: item.id,
    //     label: price === 0 ? 'FREE' : convertToRupiah(price),
    //   }

    //   return option
    // })


  }, [])
  return (
    <FormSelect
      name={name}
      options={options}
      validate={validate}
      format={format}
      parse={parse}
      label={label}
      className={className}
    />
  )
}

export default PriceTierField
