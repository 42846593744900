import {useQuery} from '@apollo/client'

import {Divider, Paper, Typography} from '@material-ui/core'

import Skeleton from 'react-loading-skeleton'

import {
  INSTRUCTOR_CONTEXT,
  formatCompactNominal,
} from '../../../utils/contributor-helpers'

import {USER_ID} from '../../../utils/globals'
import {GET_CONTRIBUTOR_CATALOG_STATS} from '../../../graphql/queries'

const aggregatesList = [
  {
    key: 'course_created',
    label: 'Course',
  },
  {
    key: 'ebook_created',
    label: 'E-Book',
  },
  {
    key: 'audiobook_created',
    label: 'Audiobook',
  },
  {
    key: 'event_created',
    label: 'Event',
  },
  {
    key: 'microlearning_created',
    label: 'Microlearning',
  },
] as const

const ContributorCatalogAggregateCard = () => {
  const {data} = useQuery(GET_CONTRIBUTOR_CATALOG_STATS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const totalItems = data
    ? data.course_created.aggregate.count +
      data.ebook_created.aggregate.count +
      data.audiobook_created.aggregate.count +
      data.event_created.aggregate.count +
      data.microlearning_created.aggregate.count
    : 0

  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        Catalog
      </Typography>

      <Divider />

      <div className="p-6">
        <Typography
          title={`${totalItems} items on catalog`}
          color="primary"
          className="text-2xl font-bold"
        >
          {data ? formatCompactNominal(totalItems) : <Skeleton width={44} />}
        </Typography>
      </div>

      <div className="grid gap-2 p-6 pt-0">
        {aggregatesList.map((item) => (
          <div key={item.key} className="flex justify-between gap-2">
            <span>{item.label}</span>
            <Typography color="primary" className="font-bold">
              {data ? (
                formatCompactNominal(data[item.key].aggregate.count)
              ) : (
                <Skeleton width={29} />
              )}
            </Typography>
          </div>
        ))}
      </div>
    </Paper>
  )
}

export default ContributorCatalogAggregateCard
