import {FC} from 'react'
// import SortFilterComponent from '../SortFilter.component'
import MyLearningCardComponent from '../../../../components/card/MyLearningCard.component'
import {LearningType} from '../../../../utils/enum'
import {IMicroLearning} from '../../../../interfaces'
import NoList from '../../../../components/empty-state/NoList.component'
import {TMyLearning} from '..'
import {useQuery} from '@apollo/client'
import {GET_MYLEARNING_MICROLEARINNG} from '../../../../graphql/queries'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import Skeleton from 'react-loading-skeleton'

const MyMicroLearningComponent: FC<TMyLearning> = ({
  search,
  sort,
  filterData,
}) => {
  let orderBy

  switch (sort) {
    case 'oldest':
      orderBy = {date_added: 'asc'}
      break
    case 'nameASC':
      orderBy = {microlearning: {name: 'asc'}}
      break
    case 'nameDESC':
      orderBy = {microlearning: {name: 'desc'}}
      break
    case 'progressASC':
      orderBy = {score: 'desc', progress: 'asc'}
      break
    case 'progressDESC':
      orderBy = {score: 'desc', progress: 'desc'}
      break
    default:
      orderBy = {date_added: 'desc'}
  }

  const {data, loading} = useQuery(GET_MYLEARNING_MICROLEARINNG, {
    variables: {
      userId: USER_ID,
      search: `%${search}%`,
      orderBy: [orderBy],
      ...filterData,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })

  const isSearching = search || Object.keys(filterData).length > 0

  if (loading) {
    return (
      <>
        {Array.from({length: 2}).map((res, i) => (
          <Skeleton height="140px" className="mb-4" key={i} />
        ))}
      </>
    )
  }

  return (
    <>
      {data.microlearning_members.length === 0 ? (
        <NoList
          imageSize={180}
          title={
            isSearching
              ? 'Tidak ada hasil yang ditemukan'
              : 'Maaf, tidak ada daftar'
          }
          subTitle={
            isSearching
              ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
              : 'Saat ini Anda tidak memiliki pembelajaran mikro'
          }
        />
      ) : (
        data.microlearning_members.map((val: IMicroLearning) => {
          return (
            <MyLearningCardComponent
              key={val.id}
              data={val}
              type={LearningType.MICRO_LEARNING}
            />
          )
        })
      )}
    </>
  )
}

export default MyMicroLearningComponent
