import React from 'react'
import {ILesson} from '../../../../../../interfaces/Course.interface'

import {
  Button,
  Icon,
  Typography,
  createStyles,
  makeStyles,
} from '@material-ui/core'

import Countdown from 'react-countdown'
import {countDownTimer} from '../../../../../../utils/helpers'

import Dompurify from 'dompurify'
import {ListAlt, AccessAlarm} from '@material-ui/icons'
import {QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID} from '../../../../../../provider/services/Course.service'
import {ICourseQuizSubmission} from '../../../../../../interfaces'
import {useNavigate, useParams} from 'react-router-dom'
import Skeleton from 'react-loading-skeleton'

type QuizLessonProps = {
  lesson: ILesson
  contributorId: string
  isPreview?: boolean
}

type CountDownProps = {
  isCompeleted: boolean
  minutes: number
  hours: number
  total: number
}

const useStyles = makeStyles(() =>
  createStyles({
    runOutColor: {
      color: '#666',
    },
    greenColor: {
      color: '#4caf50',
    },
    orangeYellow: {
      color: '#ffa000',
    },
  })
)

const QuizLesson = (props: QuizLessonProps) => {
  const navigate = useNavigate()
  const {activeLessonId} = useParams()
  const classes = useStyles()
  const [quizAttempt, setQuizAttempt] = React.useState({
    duration: props.lesson.duration,
    isFinished: false,
    questionNumber: 0,
    correctAnswerNumber: 0,
  })

  const {data: dataSubmission, refetch: refetchSubmission} =
    QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID(props.lesson.id)

  React.useEffect(() => {
    if (activeLessonId && dataSubmission?.length === 0) {
      refetchSubmission()
    }
  }, [activeLessonId, dataSubmission, refetchSubmission])

  const countDown = (countDown: CountDownProps) => {
    /* currentCounter -= 1
    actualCounter -= 1 */

    if (countDown.isCompeleted) {
      setQuizAttempt({...quizAttempt, isFinished: true})

      return <div className={classes.runOutColor}>Time is has run out</div>
    } else {
      if (countDown.hours < 1 && countDown.minutes < 1)
        return <div>{countDownTimer(countDown.total)}</div>
      return (
        <div className={classes.greenColor}>
          {countDownTimer(countDown.total)}
        </div>
      )
    }
  }

  const Preliminary = () => (
    <>
      <div className="h-96 flex flex-col justify-center items-center">
        <Typography variant="h5">{props.lesson.title}</Typography>
        <div className="flex flex-row justify-evenly w-80 my-8">
          <div className="flex flex-row items-center">
            <ListAlt color="primary" className="mr-2" fontSize="large" />
            <Typography color="primary">
              {props.lesson.Questions.length} Questions
            </Typography>
          </div>
          <div className="flex flex-row items-center">
            <AccessAlarm color="primary" className="mr-2" fontSize="large" />
            <Typography color="primary">
              {parseInt(props.lesson.duration.split(':')[1])}
              {':'}
              {parseInt(props.lesson.duration.split(':')[2])}
            </Typography>
          </div>
        </div>

        {!dataSubmission?.some(
          (submission: ICourseQuizSubmission) =>
            submission.AcademyUserAnswers.length > 0
        ) || props.isPreview ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() =>
              navigate(`/learner/answer_submission/quiz/${props.lesson.id}`)
            }
            size="large"
            className="w-44"
          >
            Start Quiz
          </Button>
        ) : (
          <div className="flex flex-col">
            <div className="flex flex-row justify-center items-center">
              <Typography variant="body2">Score:</Typography>
              <Typography variant="h5" className="text-green-700 mx-2">
                {(
                  (dataSubmission?.filter(
                    (submission: ICourseQuizSubmission) =>
                      submission?.AcademyUserAnswers[
                        submission?.AcademyUserAnswers.length - 1
                      ]?.grade === 100
                  ).length /
                    dataSubmission?.length) *
                  100
                ).toFixed(0)}
              </Typography>
              <Typography variant="body2">
                (
                {
                  dataSubmission?.filter(
                    (submission: ICourseQuizSubmission) =>
                      submission?.AcademyUserAnswers[
                        submission?.AcademyUserAnswers.length - 1
                      ]?.grade === 100
                  ).length
                }
                /{dataSubmission?.length})
              </Typography>
            </div>
            <div className="flex flex-row mt-12">
              <Button
                variant="outlined"
                color="primary"
                // onClick={() => handleChangeQuestionNumber('increment', null)}
                onClick={() =>
                  navigate(`/learner/answer_submission/quiz/${props.lesson.id}`)
                }
                size="large"
                className="w-44"
              >
                Retry
              </Button>
              <Button
                variant="contained"
                color="primary"
                // onClick={() => handleChangeQuestionNumber('increment', null)}
                onClick={() =>
                  navigate(`/learner/result/quiz/${props.lesson.id}`)
                }
                size="large"
                className="w-44 ml-8"
              >
                Result Detail
              </Button>
            </div>
          </div>
        )}
      </div>
      <div className="flex pt-24"></div>
    </>
  )

  if (activeLessonId && dataSubmission?.length === 0) {
    return <Skeleton height={500} width={'30vw'} />
  }
  if (quizAttempt.questionNumber === 0) return <Preliminary />

  return (
    <>
      <div className="flex justify-end items-center">
        <Icon>access_alarms</Icon>

        <Countdown
          date={quizAttempt.duration}
          // @ts-ignore
          renderer={countDown}
          controlled={false}
        >
          <div>done!</div>
        </Countdown>
      </div>
      <div
        className="mt-24"
        dangerouslySetInnerHTML={{
          __html: Dompurify.sanitize(
            props.lesson.Questions[quizAttempt.questionNumber - 1].fulltext
          ),
        }}
      />
    </>
  )
}

export default QuizLesson
