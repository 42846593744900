import {
  ADMIN_APP_URL,
  HOLDING_APP_URL,
  NEW_LEARNING_APP_URL,
  SOSMED_APP_URL,
} from '../../utils/globals'
import { HeaderStyled } from './HeaderStyles'
import { GlobalHeader } from '@smartasn/wlb-utils-components'

export default function Header() {
  const env = {
    REACT_APP_SOSMED: SOSMED_APP_URL + '/',
    REACT_APP_HC_ADMIN: ADMIN_APP_URL,
    REACT_APP_HC_HOLDING: HOLDING_APP_URL,
    REACT_APP_DOMAIN: process.env.REACT_APP_COOKIE,
    HOME_URL: NEW_LEARNING_APP_URL,
    REACT_APP_CHAT: process.env.REACT_APP_CHAT,
    NOTIF_PATH: 'learning',
    SSO_URL: process.env.REACT_APP_SSO + '/',
    REACT_APP_LEARNING: process.env.REACT_APP_HC_LEARNING,
    rest_url: process.env.REACT_APP_API_SOSMED_URL,
    wlb_rest_url: process.env.REACT_APP_API_URL,
    USER_URL: process.env.REACT_APP_HC_TIME,
    PERFORMANCE_URL: process.env.REACT_APP_HC_PERFORMANCE,
    LEARNING_URL: process.env.REACT_APP_NEW_LEARNING,
    TALENT_URL: process.env.REACT_APP_HC_TALENT,
    VENDOR_URL: process.env.REACT_APP_HC_VENDOR,
  }

  return (
    <HeaderStyled>
      <GlobalHeader env={env} language="indonesian" />
    </HeaderStyled>
  )
}
