import {useEffect, useState} from 'react'
import {Button} from '@material-ui/core'
import {useQuery} from '@apollo/client'
import {GET_WISHLIST_BOOK, GET_USER_CART} from '../../../../graphql/queries'
import {DELETE_BOOK_WISHLIST} from '../../../../provider/services/Book.service'
import {ADD_TO_CART} from '../../../../provider/services/Academy.service'
import {LearningType} from '../../../../utils/enum'
import {USER_ID} from '../../../../utils/globals'
import {useSnackbar} from 'notistack'
import {IBookType, IBookList} from '../../../../interfaces'

import LoadingCard from '../../../../components/loading/LoadingCard.component'
import LearnerCatalogCard from '../../../../components/learner/catalog/LearnerCatalogCard.component'
import NoListComponent from '../../../../components/empty-state/NoList.component'

type bookWishlistProps = {
  searchValue: any
}

const bookTypes = [
  {id: 2, name: 'Buku Elektronik'},
  {id: 3, name: 'Buku Audio'},
]

const BookWishlistComponent = (props: bookWishlistProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [list, setList] = useState<any[]>([])
  const [activeType, setActiveType] = useState(2)
  const [wishlistLoading, setWishlistLoading] = useState(false)
  const [tempId, setTempId] = useState(0)

  const [deleteFromWishlist] = DELETE_BOOK_WISHLIST()
  const [addToCart] = ADD_TO_CART()

  const {
    data: cartList,
    loading: cartLoading,
    refetch: refetchCart,
  } = useQuery(GET_USER_CART)

  const {
    data: books,
    loading: booksLoading,
    refetch,
  } = useQuery(GET_WISHLIST_BOOK, {
    variables: {
      type: activeType,
    },
  })

  const onChangeType = (id: number) => {
    setActiveType(id)
  }

  useEffect(() => {
    if (books) {
      setList(books.academy_book_wishlists)
    }
  }, [books])

  const handleAddToCart = (item: any) => {
    const variables = {
      objects: [
        {
          item_id: item.isbn,
          item_object: {
            name: item.academy_book.title,
            image: item.academy_book.thumbnail,
            price: item?.pricing_idr || 0,
            weight: 0,
            note: '',
          },
          item_table: 'academy_books',
          item_vendor: item.academy_book?.global_user?.name,
          item_vendor_id: item.academy_book?.global_user?.id,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    addToCart({
      variables,
    })
      .then(() => {
        enqueueSnackbar(
          `Berhasil!! ${
            activeType === 2 ? 'Buku elektronik' : 'Buku audio'
          } ditambahkan ke keranjang`,
          {
            variant: 'success',
          }
        )
        refetchCart()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menambahkan ke keranjang', {variant: 'error'})
      })
  }

  const removeFromWishlist = (isbn: string, id: number) => {
    if (isbn) {
      setWishlistLoading(true)
      setTempId(id)
      deleteFromWishlist({
        variables: {
          userId: USER_ID,
          isbn,
          id,
        },
      })
        .then(() => {
          enqueueSnackbar('Book removed from wishlist', {variant: 'success'})
          if (refetch) refetch()
        })
        .catch(() => {
          enqueueSnackbar('Remove book from wishlish failed', {
            variant: 'error',
          })
        })
        .finally(() => {
          setWishlistLoading(false)
          setTempId(0)
        })
    }
  }

  if (booksLoading || cartLoading) return <LoadingCard length={4} />

  const bookList = () => {
    const _list: IBookList[] = []
    list.forEach((item: any) => {
      _list.push({
        ...item,
        academy_book: {
          ...item.academy_book,
          isInCart: cartList?.v_marketplace_user_carts.some(
            (val: any) => val.item_id === String(item.isbn)
          ),
        },
      })
    })

    if (props.searchValue) {
      return _list.filter((item: IBookList) =>
        item?.academy_book?.title
          .toLowerCase()
          .includes(props.searchValue.toLowerCase())
      )
    }

    return _list
  }

  return (
    <div>
      <div className="flex px-3 mb-4">
        {bookTypes.map((item: IBookType, idx: number) => {
          return (
            <Button
              variant="contained"
              color={activeType === item.id ? 'primary' : 'default'}
              className="mr-4"
              key={idx}
              onClick={() => onChangeType(item.id)}
            >
              {item.name}
            </Button>
          )
        })}
      </div>
      {bookList().length === 0 ? (
        <NoListComponent
          title="Maaf, Tidak Ada Daftar"
          subTitle={`Saat ini, Anda tidak memiliki daftar keinginan ${
            activeType === 2 ? 'buku elektronik' : 'buku audio'
          }`}
        />
      ) : (
        <div className="flex flex-wrap">
          {bookList().map((item: IBookList, key: number) => {
            return (
              <LearnerCatalogCard
                type={LearningType.BOOK}
                item={item.academy_book}
                key={key}
                hidePrice={false}
                isWishlist
                removeFromWishlist={() =>
                  removeFromWishlist(item.isbn || '', item.id || 0)
                }
                wishlistLoading={wishlistLoading && tempId === item.id}
                addToCart={() => handleAddToCart(item)}
              />
            )
          })}
        </div>
      )}
    </div>
  )
}

export default BookWishlistComponent
