import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Form} from 'react-final-form'
import {useApolloClient, useQuery} from '@apollo/client'

import {type FormApi} from 'final-form'
import arrayMutators from 'final-form-arrays'

import axios from 'axios'

import {Button, Divider, IconButton, Paper, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {
  combineValidators,
  isRequired,
  parseAsInt,
} from '../../../../../components/input-forms/validators'
import FormLoading from '../../../../../components/input-forms/FormLoading'
import FormText from '../../../../../components/input-forms/FormText'
import AcademyCategoryFields from '../../../../../components/contributor/forms/AcademyCategoryFields'
import EventTypeField from '../../../../../components/contributor/forms/EventTypeField'
import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'

import {
  getRandomEventSlug,
  validateEventSlug,
  validateEventTitle,
} from '../../ContributorEventCreatePage'
import ScheduleField from './landing/ScheduleField'
import NotificationField from './landing/NotificationField'
import PosterField from './landing/PosterField'

import {TOKEN, UPLOAD_URL, USER_ID} from '../../../../../utils/globals'
import {
  FileContainer,
  INSTRUCTOR_CONTEXT,
} from '../../../../../utils/contributor-helpers'
import {
  GET_CONTRIBUTOR_EVENT_LANDING_DETAILS,
  GET_EVENT_SLUG_AVAILABILITY,
} from '../../../../../graphql/queries'
import FormPrice from '../../../../../components/input-forms/FormPrice'
import AttachmentField from '../../../../../components/contributor/forms/AttachmentField'
import useEvent from '../../../../../hooks/useEvent'
import {
  ADD_EVENT_LANDING_FILES,
  DELETE_EVENT_LANDING_FILES,
  UPDATE_EVENT_LANDING_DETAILS,
  UPDATE_EVENT_LANDING_FILES,
} from '../../../../../graphql/mutations'

const validateEventPosters = combineValidators(isRequired, (value) => {
  if (!value || value.length < 1) {
    return 'This field is required'
  }
})

const ContributorEventManageLandingPage = () => {
  const {eventId} = useParams()

  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const client = useApolloClient()

  const {data, loading, refetch} = useQuery(
    GET_CONTRIBUTOR_EVENT_LANDING_DETAILS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: USER_ID,
        eventId: eventId,
      },
      context: INSTRUCTOR_CONTEXT,
    }
  )

  const initialValues = React.useMemo(() => {
    if (!data || data.event_schedules.length < 1) {
      return
    }

    const event = data.event_schedules[0]

    return {
      title: event.title,
      slug: event.slug,
      category: event.event_category?.event_category?.id,
      subcategory: event.event_category?.id,
      type: event.type,
      datestart: event.datestart,
      dateend: event.dateend,
      notif_timer: event.notif_timer,
      notif_unit_of_time: event.notif_unit_of_time,
      location_info: event.location_info,
      pricing_idr: event.pricing_idr,
      description: event.description,
      poster: event.poster,
      schedule_fields: event.schedule_fields,
      event_schedule_files: event.event_schedule_files,
    }
  }, [data])

  const handleSubmit = useEvent(async (data: any, form: FormApi) => {
    const pendingUploads: FileContainer[] = []

    const posters: any[] = []

    const insertFiles: any[] = []
    const updateFiles: any[] = []

    const prevFileIds = new Set<number>()

    // 1. gather the IDs of files so we know which to remove
    for (const item of initialValues!.event_schedule_files) {
      prevFileIds.add(item.id)
    }

    // 2. gather the files we need to upload
    for (let idx = 0; idx < data.poster.length; idx++) {
      let file = data.poster[idx]

      if (file instanceof File) {
        file = new FileContainer(file, `poster[${idx}]`)
        pendingUploads.push(file)
      }

      posters.push(file)
    }

    for (let idx = 0; idx < data.event_schedule_files.length; idx++) {
      const item = data.event_schedule_files[idx]
      let file = item.url

      if (file instanceof File) {
        file = new FileContainer(file, `event_schedule_files[${idx}].url`)
        pendingUploads.push(file)
      }

      const inputs = {
        event_schedule_id: eventId,

        name: item.name,
        type: item.type,
        size: item.size,
        file: file,
      }

      if (!item.id) {
        insertFiles.push(inputs)
      } else {
        prevFileIds.delete(item.id)
        updateFiles.push([item.id, inputs])
      }
    }

    // 3. delete event files
    if (prevFileIds.size > 0) {
      await client.mutate({
        mutation: DELETE_EVENT_LANDING_FILES,
        variables: {
          fileIds: Array.from(prevFileIds),
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    // 4. upload files
    if (pendingUploads.length > 0) {
      for (const file of pendingUploads) {
        const raw = file.raw

        const dataForm = new FormData()
        dataForm.append('file', raw)

        try {
          const response = await axios.post(UPLOAD_URL!, dataForm, {
            headers: {
              Authorization: `Bearer ${TOKEN}`,
              'Content-Type': 'multipart/form-data',
            },
            withCredentials:true
          })

          const url = response.data.url
          file.url = url

          if (file.path) {
            form.change(file.path, url)
          }
        } catch {
          // TODO(intrnl): should we break here?
          // UploadContainer already throws anyway so I'm not sure if we need to
          // throw here specifically.
        }
      }
    }

    // 5. insert files
    if (insertFiles.length > 0) {
      await client.mutate({
        mutation: ADD_EVENT_LANDING_FILES,
        variables: {
          objects: insertFiles,
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    // 6. update existing files
    if (updateFiles.length > 0) {
      for (const [fileId, inputs] of updateFiles) {
        await client.mutate({
          mutation: UPDATE_EVENT_LANDING_FILES,
          variables: {
            fileId: fileId,
            objects: inputs,
          },
          context: INSTRUCTOR_CONTEXT,
        })
      }
    }

    // 7. check for slug availability here, we can only do it now because there
    // can be a huge gap between uploading files and getting here
    const slug = data.slug || getRandomEventSlug(data.title)

    if (slug !== initialValues!.slug) {
      const {data: availabilityData} = await client.query({
        query: GET_EVENT_SLUG_AVAILABILITY,
        fetchPolicy: 'network-only',
        variables: {slug: slug},
      })

      if (availabilityData.event_schedules.length > 0) {
        return {
          slug: `Slug already used`,
        }
      }
    }

    // 8. update event
    await client.mutate({
      mutation: UPDATE_EVENT_LANDING_DETAILS,
      variables: {
        eventId: eventId,
        objects: {
          thumbnail: posters[0],
          title: data.title,
          slug: slug,
          category: data.subcategory,
          type: data.type,
          datestart: data.datestart,
          dateend: data.dateend,
          notif_timer: data.notif_timer,
          notif_unit_of_time: data.notif_unit_of_time,
          location_info: data.location_info,
          pricing_idr: data.pricing_idr,
          pricing: {
            IDR: data.pricing_idr,
          },
          description: data.description,
          poster: posters,
          schedule_fields: data.schedule_fields,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    refetch()
  })

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{...arrayMutators}}
    >
      {({handleSubmit, pristine, submitting}) => (
        <Paper component="form" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 px-6 py-1">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              Event Landing Page
            </Typography>

            <Button
              disabled={pristine || submitting}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col gap-4 p-6">
            {(submitting || loading) && <FormLoading />}

            <FormText
              name="title"
              validate={validateEventTitle}
              label="Title*"
              placeholder="Add Event Title"
              fullWidth
              showCharLimit={150}
            />

            <FormText
              name="slug"
              validate={validateEventSlug}
              label="Event Slug"
              placeholder="Add event slug"
              fullWidth
              helperText="If event slug is empty, default slug will same with the event title"
            />

            <div className="flex flex-col gap-4 sm:max-w-xs">
              <AcademyCategoryFields showMark isEvent />
            </div>

            <EventTypeField
              name="type"
              validate={isRequired}
              label="Event Type*"
              className="sm:max-w-xs"
            />

            <ScheduleField />

            <NotificationField label="Notification*" />

            <FormText
              name="location_info.formatted_address"
              label="Location*"
              placeholder="Add Location"
            />

            <FormText
              name="schedule_fields.maximum_participant"
              parse={parseAsInt}
              label="Maximum Participant"
              placeholder="Add Maximum Participant"
              className="sm:max-w-xs"
            />

            <FormPrice
              name="pricing_idr"
              validate={isRequired}
              label="Price*"
              className="sm:max-w-xs"
            />

            <FormText
              name="description"
              validate={isRequired}
              label="Description*"
              fullWidth
              multiline
              minRows={3}
            />

            <PosterField
              name="poster"
              label="Event Poster*"
              validate={validateEventPosters}
              description={
                <>
                  Resolution Recommendation: 2560 x 1600 pixels
                  <br />
                  Type File: jpg, jpeg, png
                  <br />
                  Max Size: 25 MB
                </>
              }
            />

            <AttachmentField
              name="event_schedule_files"
              label="Attachments (Optional)"
            />
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorEventManageLandingPage
