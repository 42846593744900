import {gql} from '@apollo/client'

export const GET_FAVORITE_CATEGORY = gql`
  query ($userId: String!) {
    favoriteLearningCategories(userId: $userId) {
      id
      name
      audio_book_count
      course_count
      e_book_count
      microlearning_count
      total
    }
  }
`
