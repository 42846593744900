// @ts-nocheck
import {getCookie} from './helpers'

const {
  REACT_APP_GRAPHQL_URL,
  REACT_APP_GRAPHQL_URL_WS,
  REACT_APP_SSO,
  REACT_APP_UPLOAD_URL,
  REACT_APP_API_URL,
  REACT_APP_HC_SOSMED,
  REACT_APP_HC_HOLDING,
  REACT_APP_HC_ADMIN,
  REACT_APP_COOKIE,
  REACT_APP_JW_URL,
  REACT_APP_HC_VENDOR,
  REACT_APP_CHAT,
  REACT_APP_MATRIX_URL,
  REACT_APP_NEW_LEARNING,
  REACT_APP_LANDING,
  REACT_APP_HC_LEARNING,
  REACT_APP_API_SOSMED_URL,
  REACT_APP_SENTRY,
  REACT_APP_SENTRY_ENV,
} = process.env

export const userData = getCookie('userData')
  ? JSON.parse(getCookie('userData'))
  : []
let access_token = ''
let company_id = ''
let company_logo = ''
let user_id = ''
let wallet_id = ''
let chat_home_server = ''
let roles = []
let organization = ''
let avatar = ''
if (userData?.oauth) {
  company_id = userData.company_id
  company_logo = userData.company_logo
  access_token = userData.oauth.access_token
  user_id = userData.id
  wallet_id = userData.walletAccount
  roles = userData.roles
  organization = userData.position_id
  avatar = userData.avatar
  chat_home_server = userData.chat.home_server
}

export const GRAPHQL_URL = REACT_APP_GRAPHQL_URL
export const GRAPHQL_WS_URL = REACT_APP_GRAPHQL_URL_WS
export const TOKEN = access_token
export const COMPANY_ID = company_id
export const COMPANY_LOGO = company_logo
export const ROLE = 'organization-staff'
export const ROLES = roles
export const USER_ID = user_id
export const WALLET_ID = wallet_id
export const ORGANIZATION = organization
export const UPLOAD_URL = REACT_APP_UPLOAD_URL
export const SOSMED_APP_URL = REACT_APP_HC_SOSMED
export const HOLDING_APP_URL = REACT_APP_HC_HOLDING
export const ADMIN_APP_URL = REACT_APP_HC_ADMIN
export const REACT_APP_COOKIE_URL = REACT_APP_COOKIE
export const SSO_URL = REACT_APP_SSO
export const API_URL = REACT_APP_API_URL
export const SOSMED_API_URL = REACT_APP_API_SOSMED_URL
export const JW_URL = REACT_APP_JW_URL
export const VENDOR_URL = REACT_APP_HC_VENDOR
export const CHAT_URL = REACT_APP_CHAT
export const MATRIX_URL = REACT_APP_MATRIX_URL
export const CHAT_HOME_SERVER = chat_home_server
export const NEW_LEARNING_APP_URL = REACT_APP_NEW_LEARNING
export const LANDING_APP_URL = REACT_APP_LANDING
export const OLD_LEARNING_APP_URL = REACT_APP_HC_LEARNING
export const AVATAR = avatar
export const HASURA_ROLE_USER_CONTEXT = {
  context: {
    headers: {
      'X-Hasura-Role': 'user',
    },
  },
}
export const SENTRY_URL = REACT_APP_SENTRY
export const SENTRY_ENV = REACT_APP_SENTRY_ENV
