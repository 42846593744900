import React from 'react'

import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {LESSON_TYPES} from './Lesson'

import {LessonType} from './_utils'

interface LessonAddProps {
  onAdd: (next: LessonType) => void
}

const LessonAdd = (props: LessonAddProps) => {
  const {onAdd} = props

  const [isAdding, setIsAdding] = React.useState(false)

  if (isAdding) {
    return (
      <div>
        <p className="text-sm">
          Select the main type of content. Files and links can be added as
          resources
        </p>
        <div className="mt-4 flex gap-4">
          {LESSON_TYPES.map((item) => (
            <Button
              key={item.value}
              onClick={() => {
                onAdd(item.value)
                setIsAdding(false)
              }}
              variant="outlined"
              color="primary"
              startIcon={item.icon}
              className="h-10 w-36"
            >
              {item.label}
            </Button>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div>
      <Button
        onClick={() => setIsAdding(true)}
        variant="outlined"
        color="secondary"
        className="h-12 w-44 bg-white"
        startIcon={<AddIcon />}
      >
        Add Lesson
      </Button>
    </div>
  )
}

export default LessonAdd
