import {gql} from '@apollo/react-hooks'

export const GET_MY_BOOKS_COUNT = gql`
  query ($userId: uuid!, $bookIsbns: [String!]) {
    academy_books_aggregate(
      where: {
        academy_book_owners: {user_id: {_eq: $userId}}
        isbn: {_in: $bookIsbns}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_MY_BOOKS = gql`
  query (
    $offset: Int
    $limit: Int
    $userId: uuid
    $progress: Int_comparison_exp
    $keyword: String
    $category: [Int!]
    $orderBy: [academy_books_order_by!]
    $type: Int
  ) {
    academy_books_aggregate(
      where: {
        academy_book_owners: {user_id: {_eq: $userId}, progress: $progress}
        _or: [
          {title: {_ilike: $keyword}}
          {global_user: {name: {_ilike: $keyword}}}
        ]
        category: {_in: $category}
        book_type: {_eq: $type}
      }
    ) {
      aggregate {
        count
      }
    }
    academy_books(
      where: {
        academy_book_owners: {user_id: {_eq: $userId}, progress: $progress}
        _or: [
          {title: {_ilike: $keyword}}
          {global_user: {name: {_ilike: $keyword}}}
        ]
        category: {_in: $category}
        book_type: {_eq: $type}
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      isbn
      title
      summary
      thumbnail
      type: academy_book_type {
        id
      }
      academy_book_owners {
        progress
      }
      academy_course_category {
        id
        name
      }
      count_reviews
      average_rating
      global_user {
        id
        name
        avatar
        learning_contributor_ratings {
          total_rating_avg
          total_rating_count
        }
      }
    }
  }
`

export const GET_RECOMMENDED_BOOK_CATEGORIES = gql`
  query ($userId: uuid!, $offset: Int, $limit: Int) {
    academy_course_categories_aggregate(
      where: {academy_books: {academy_book_owners: {user_id: {_eq: $userId}}}}
    ) {
      aggregate {
        count
      }
    }
    academy_course_categories(
      where: {academy_books: {academy_book_owners: {user_id: {_eq: $userId}}}}
      offset: $offset
      limit: $limit
    ) {
      id
      name
    }
  }
`

export const GET_BOOK_DETAIL_BY_ISBN = gql`
  query GetBookDetail($isbn: String!) {
    academy_books_by_pk(isbn: $isbn) {
      id
      isbn
      author_written
      copyright_year
      duration_seconds
      edition
      pricing_idr
      publisher_id
      publisher_written
      slug
      status
      summary
      teaser_file
      thumbnail
      title
      owner
      academy_book_type {
        id
        name
      }
      book_category: academy_course_category {
        id
        name
        parent_category: academy_course_category {
          id
          name
        }
      }
      count_reviews
      count_discussions
      average_rating
      contributor: global_user {
        id
        name
        avatar
        contributor_label
        people_work_placements {
          id
          company_job_profile {
            id
            position
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
        learning_contributor_ratings {
          total_rating_avg
          total_rating_count
          total_learner_count
          total_catalog_count
        }
      }
    }
  }
`

export const GET_RELATED_BOOKS = gql`
  query GetRelatedBooks(
    $author: String
    $category: Int
    $contributor: uuid
    $limit: Int
    $offset: Int
    $type: Int
  ) {
    academy_books_aggregate(
      where: {
        book_type: {_eq: $type}
        _or: [
          {category: {_eq: $category}}
          {
            academy_course_category: {
              academy_course_category: {id: {_eq: $category}}
            }
          }
          {owner: {_eq: $contributor}}
          {author_written: {_ilike: $author}}
        ]
      }
    ) {
      aggregate {
        count
      }
    }
    academy_books(
      where: {
        book_type: {_eq: $type}
        _or: [
          {category: {_eq: $category}}
          {
            academy_course_category: {
              academy_course_category: {id: {_eq: $category}}
            }
          }
          {owner: {_eq: $contributor}}
          {author_written: {_ilike: $author}}
        ]
      }
      order_by: [
        {average_rating: desc_nulls_last}
        {date_added: desc_nulls_last}
      ]
      offset: $offset
      limit: $limit
    ) {
      id
      isbn
      title
      summary
      thumbnail
      type: academy_book_type {
        id
      }
      academy_book_owners {
        progress
      }
      academy_course_category {
        id
        name
      }
      count_reviews
      average_rating
      global_user {
        id
        name
        avatar
        learning_contributor_ratings {
          total_rating_avg
          total_rating_count
        }
      }
    }
  }
`

export const GET_BOOK_OWNED = gql`
  query CheckBookOwned($isbn: String, $user: uuid) {
    academy_book_owners(where: {isbn: {_eq: $isbn}, user_id: {_eq: $user}}) {
      id
      progress
      updated_at
    }
  }
`

export const GET_BOOK_FIELDS_BY_ISBN = gql`
  query GetBookFields($isbn: String!) {
    book: academy_books_by_pk(isbn: $isbn) {
      id
      isbn
      book_fields
    }
  }
`
