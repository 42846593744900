import {gql} from '@apollo/react-hooks'

export const ADD_BOOK_REVIEW = gql`
  mutation AddBookReview(
    $rating: float8!
    $isbn: String
    $review_text: String
  ) {
    insert_academy_book_reviews_one(
      object: {rating: $rating, isbn: $isbn, review_text: $review_text}
    ) {
      id
    }
  }
`

export const UPDATE_BOOK_REVIEW = gql`
  mutation UpdateBookReview(
    $rating: float8!
    $isbn: String
    $review_text: String
    $userId: uuid!
  ) {
    update_academy_book_reviews(
      _set: {rating: $rating, review_text: $review_text}
      where: {isbn: {_eq: $isbn}, user: {_eq: $userId}}
    ) {
      affected_rows
    }
  }
`
