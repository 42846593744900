import {useTitle} from 'hoofd'
import {FC, ReactElement} from 'react'
import Header from '../../components/header/HeaderComponent'
import {DetailWrapper} from '../../components/learner/micro-learning/MicroLearning.style'
import MicroLearningDetail from '../../components/learner/micro-learning/MicroLearningDetail.component'
import BackNavigation from '../../components/navigation/BackNavigation.component'

import useStyles, {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/GlobalStyles'

const MicroLearning: FC = (): ReactElement => {
  useTitle('Detil Pembelajaran Mikro')
  const classes = useStyles()

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          className={classes.tabs}
          centered
        >
          <StyledTab label="Katalog Pembelajaran" value="learning-catalog" />
        </StyledTabs>
      </TabsWrapper>

      <DetailWrapper>
        <div className="w-full flex flex-col">
          <BackNavigation label="Kembali" />

          <MicroLearningDetail />
        </div>
      </DetailWrapper>
    </>
  )
}

export default MicroLearning
