import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Box, Button, CircularProgress, Tab, Tabs} from '@material-ui/core'

import CheckoutCard, {CartItem} from '../catalog/card/CheckoutCard.component'
import ContributorCard from '../catalog/card/ContributorCard.component'
import InformationDetailCard from '.././catalog/card/InformationDetailCard.component'
import TabAbout from './tab/TabAbout.component'
import TabReview from './tab/TabReview.component'
import {
  BOOK_BY_ISBN,
  BOOK_FIELDS_BY_ISBN,
  BOOK_WISHLISTS,
  CHECK_BOOK_OWNED,
  CONTRIBUTOR_DETAILS,
} from '../../../provider/services/Book.service'
import {useMutation} from '@apollo/client'
import {
  ADD_BOOK_FROM_WISHLIST,
  DELETE_BOOK_FROM_WISHLIST,
} from '../../../graphql/mutations'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../utils/globals'
import {useSnackbar} from 'notistack'
import RelatedBook from './RelatedBook.component'
import ConfirmationModal from '../../modal/ConfirmationModal'

const BookDetail = () => {
  const {isbn} = useParams()
  const {enqueueSnackbar} = useSnackbar()

  const navigate = useNavigate()

  const [authorId, setAuthorId] = React.useState()
  const [tab, setTab] = React.useState(0)
  const [skipFetchingContributorWishlist, setSkipFetchingContributorWishlist] =
    React.useState(true)
  const [skipFetchingBookFields, setSkipFetchingBookFields] =
    React.useState(true)

  const [isAcquiredOpen, setIsAcquiredOpen] = React.useState(false)

  const {
    data: bookDetailData,
    loading: bookDetailLoading,
    refetch: bookDetailRefetch,
  } = BOOK_BY_ISBN(isbn!)

  const {data: contributorData, loading: contributorLoading} =
    CONTRIBUTOR_DETAILS(authorId || '', skipFetchingContributorWishlist)

  const {data: bookWishlistedData, loading: bookWishlistedLoading} =
    BOOK_WISHLISTS({
      isbns: [isbn!],
      skipFetching: skipFetchingContributorWishlist,
    })

  const isAudioBook =
    bookDetailData?.academy_books_by_pk?.academy_book_type?.id === 3
  const isBookWishlisted =
    bookWishlistedData && bookWishlistedData.wishlist.length > 0

  const {
    data: bookOwnedData,
    loading: bookOwnedLoading,
    refetch: bookOwnedRefetch,
  } = CHECK_BOOK_OWNED(isbn || '')

  const {data: bookFieldsData} = BOOK_FIELDS_BY_ISBN(
    isbn || '',
    skipFetchingBookFields
  )

  const [addToWishlist] = useMutation(
    ADD_BOOK_FROM_WISHLIST,
    HASURA_ROLE_USER_CONTEXT
  )

  const [removeFromWishlist] = useMutation(
    DELETE_BOOK_FROM_WISHLIST,
    HASURA_ROLE_USER_CONTEXT
  )

  React.useEffect(() => {
    if (bookDetailData?.academy_books_by_pk) {
      setAuthorId(bookDetailData.academy_books_by_pk.contributor?.id)
      setSkipFetchingContributorWishlist(false)
    }
  }, [bookDetailData])

  React.useEffect(() => {
    if (bookOwnedData?.academy_book_owners?.[0]?.id) {
      setSkipFetchingBookFields(false)
    }
  }, [bookOwnedData])

  const addToWishlistMutation = () => {
    addToWishlist({
      variables: {isbn: isbn, userId: USER_ID},
    })
      .then(() => {
        enqueueSnackbar('Book added to wishlist', {
          variant: 'success',
        })
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar(
          'Added book to wishlist failed, please try again later',
          {
            variant: 'error',
          }
        )
      })
  }

  const RemoveFromWishlistMutation = () => {
    // NOTE(intrnl): for some reason `deleted_at` is a unique key...
    // problem comes when the user unknowingly has the same item on the wishlist
    // multiple times, we'll do a partial workaround of only deleting one at a
    // time instead.

    removeFromWishlist({
      variables: {id: bookWishlistedData!.wishlist[0]?.id, isbn: isbn},
    })
      .then(() => {
        enqueueSnackbar('Book removed from wishlist', {
          variant: 'success',
        })
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar(
          'Removed book from wishlist failed, please try again later',
          {
            variant: 'error',
          }
        )
      })
  }

  const changeTab = (event: React.ChangeEvent<{}>, newActiveTab: number) => {
    setTab(newActiveTab)
  }

  const handleFreeAcquisition = () => {
    // refetch book detail just to be sure
    bookOwnedRefetch()
    bookDetailRefetch()

    setIsAcquiredOpen(true)
  }

  const dataItemInformationDetail = {
    title: bookDetailData?.academy_books_by_pk?.title,
    authorWritten: bookDetailData?.academy_books_by_pk?.author_written,
    thumbnail: bookDetailData?.academy_books_by_pk?.thumbnail,
    averageRating: Number(
      bookDetailData?.academy_books_by_pk?.average_rating?.toFixed(1)
    ),
    countReviews: bookDetailData?.academy_books_by_pk?.count_reviews,
    wishlist: bookOwnedData?.academy_book_owners?.[0]?.id
      ? undefined
      : isBookWishlisted,
    addtoWishlist: addToWishlistMutation,
    removeFromWishlist: RemoveFromWishlistMutation,
    actionButton: bookOwnedData?.academy_book_owners?.[0]?.id &&
      bookFieldsData && (
        <a
          href={bookFieldsData?.book?.book_fields?.url}
          target="blank"
          rel="noreferrer"
        >
          <Button color="primary" variant="contained">
            {isAudioBook ? 'Dengarkan Buku Audio' : 'Baca Buku Elektronik'}
          </Button>
        </a>
      ),
  }

  const relatedBookParams = {
    authorWritten: bookDetailData?.academy_books_by_pk?.author_written,
    subBookCategoryId: bookDetailData?.academy_books_by_pk?.book_category?.id,
    constributorId: authorId || '',
    type: bookDetailData?.academy_books_by_pk?.academy_book_type?.id,
  }

  const dataTabAbout = {
    title: bookDetailData?.academy_books_by_pk?.title,
    authorWritten: bookDetailData?.academy_books_by_pk?.author_written,
    publisherWritten: bookDetailData?.academy_books_by_pk?.publisher_written,
    isbn: bookDetailData?.academy_books_by_pk?.isbn,
    copyrightYear: bookDetailData?.academy_books_by_pk?.copyright_year,
    edition: bookDetailData?.academy_books_by_pk?.edition,
    bookCategory:
      bookDetailData?.academy_books_by_pk?.book_category?.parent_category?.name,
    subBookCategory: bookDetailData?.academy_books_by_pk?.book_category?.name,
    summary: bookDetailData?.academy_books_by_pk?.summary,
  }
  dataTabAbout.bookCategory =
    bookDetailData?.academy_books_by_pk?.book_category?.parent_category?.name ||
    dataTabAbout.subBookCategory

  const dataCart: CartItem = {
    item_id: bookDetailData?.academy_books_by_pk?.isbn,
    item_table: 'academy_books',
    item_object: {
      name: bookDetailData?.academy_books_by_pk.title,
      image: bookDetailData?.academy_books_by_pk.thumbnail,
      price: bookDetailData?.academy_books_by_pk.pricing_idr || 0,
      weight: 0,
    },
    item_vendor: bookDetailData?.academy_books_by_pk.contributor.name,
    item_vendor_id: bookDetailData?.academy_books_by_pk.contributor.id,
    item_vendor_table: 'global_users',
    item_quantity: 1,
  }

  const isMyBook = bookOwnedData?.academy_book_owners?.[0]?.id || false

  if (
    (bookDetailLoading && !bookDetailData) ||
    (bookWishlistedLoading && !bookWishlistedData)
  ) {
    return (
      <Box sx={{display: 'flex'}}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div
      id="book-detail-wrap"
      className="flex justify-between space-x-4 b mt-2"
    >
      <div id="left-section" className="flex flex-col space-y-12 basis-2/3">
        <InformationDetailCard
          isAudioBook={isAudioBook}
          {...dataItemInformationDetail}
        />

        <div id="tab" className="space-y-4">
          <Tabs
            value={tab}
            onChange={changeTab}
            indicatorColor="secondary"
            textColor="secondary"
            TabIndicatorProps={{
              style: {
                height: 5,
              },
            }}
          >
            <Tab label="Tentang" />
            <Tab label="Ulasan" />
          </Tabs>

          <div className="mt-8">
            {tab === 0 && <TabAbout {...dataTabAbout} />}
            {tab === 1 && (
              <TabReview
                isMyBook={isMyBook}
                isAudioBook={isAudioBook}
                bookDetailRefetch={bookDetailRefetch}
              />
            )}
          </div>
        </div>

        {authorId && <RelatedBook {...relatedBookParams} />}
      </div>

      <div id="right-section" className="flex flex-col basis-1/4 space-y-8">
        {!contributorLoading && contributorData && (
          <ContributorCard
            item={contributorData.learning_contributor_ratings_by_pk}
            isLearningCatalogChat={true}
          />
        )}

        {!bookOwnedLoading && !bookOwnedData?.academy_book_owners?.[0]?.id && (
          <CheckoutCard
            itemType={
              bookDetailData?.academy_books_by_pk.academy_book_type.id === 2
                ? 'E-Book'
                : 'Audio Book'
            }
            cartData={dataCart}
            onFreeAcquisition={handleFreeAcquisition}
          />
        )}
      </div>

      <ConfirmationModal
        open={isAcquiredOpen}
        title="Buku berhasil diambil"
        message="Produk ini telah ditambahkan ke Pembelajaran Saya."
        cancellation="Kembali"
        confirmation="Pergi ke Pembelajaran Saya"
        onClose={() => setIsAcquiredOpen(false)}
        onConfirm={() => navigate(`/learner/my-learning/books`)}
      />
    </div>
  )
}

export default BookDetail
