import clsx from 'clsx'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  type DialogActionsProps,
  type DialogContentProps,
  type DialogTitleProps,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

export interface ModalHeaderProps
  extends Omit<DialogTitleProps, 'disableTypography'> {
  white?: boolean
  onClose?: () => void
  disabled?: boolean
}

export const ModalHeader = (props: ModalHeaderProps) => {
  const {children, className, white, onClose, disabled, ...rest} = props

  const cn = clsx([
    'flex items-center m-0 p-6 pr-16 relative',
    white ? 'bg-white' : 'bg-[#f5f5f5]',
    className,
  ])

  return (
    <DialogTitle disableTypography className={cn} {...rest}>
      {children}

      <IconButton
        title="Close dialog"
        disabled={disabled}
        onClick={onClose}
        className="absolute -m-3 right-6"
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  )
}

export interface ModalContentProps extends DialogContentProps {}

export const ModalContent = (props: ModalContentProps) => {
  const {className, ...rest} = props

  const cn = clsx(['p-6', props.dividers && 'border-b-0', className])

  return <DialogContent className={cn} {...rest} />
}

export interface ModalActionsProps extends DialogActionsProps {}

export const ModalActions = (props: ModalActionsProps) => {
  const {className, ...rest} = props

  const cn = clsx(['p-6 pt-0', className])

  return <DialogActions className={cn} {...rest} />
}
