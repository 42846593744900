import {
  LocalArticleView,
  RemoteArticleView,
} from '../../../manage/curriculums/lessons/ArticleLessonView'

export interface ArticleDetailsProps {
  lesson: any
}

const ArticleDetails = (props: ArticleDetailsProps) => {
  const {lesson} = props

  const summary = lesson.summary
  const resources = lesson.resources

  if (summary === null) {
    if (!resources || resources.length < 1) {
      return null
    }

    const file = resources[0]
    const url = typeof file === 'string' ? file : file.url

    return (
      <div className="p-6">
        <RemoteArticleView src={url} height="75vh" />
      </div>
    )
  }

  return (
    <div className="p-6">
      <LocalArticleView value={summary} />
    </div>
  )
}

export default ArticleDetails
