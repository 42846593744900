import React from 'react'
import {useApolloClient} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {
  Checkbox,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline'

import {ReplyTextarea} from '../../../../../components/review-rating/RatingComment'
import DeletePopup from '../../../../shared-component/popup/DeletePopup'

import {DiscussionComment} from './DiscussionComment'
import CommentChildListing from './CommentChildListing'

import {USER_ID} from '../../../../../utils/globals'
import {
  ADD_COMMENT_RESPONSE,
  ADD_HELPFUL,
  DELETE_COMMENT,
  DELETE_HELPFUL,
  EDIT_COMMENT,
} from '../../../../../graphql/mutations'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'

export interface CommentRootProps {
  data: any
  refetch?: () => void
}

const enum CommentState {
  NONE,
  EDITING,
  ADDING,
  DELETING,
}

const CommentRoot = (props: CommentRootProps) => {
  const {data, refetch} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  // NOTE(intrnl): `key` is used to reset replies
  const [key, setKey] = React.useState(0)
  const [state, setState] = React.useState(CommentState.NONE)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()

  const placement = data.user.placements[0]
  const isHelpful = data.self_helpful.length > 0

  const handleHelpfulChange = (
    ev: React.ChangeEvent<HTMLInputElement>,
    next: boolean
  ) => {
    let promise

    if (next) {
      promise = client.mutate({
        mutation: ADD_HELPFUL,
        variables: {
          objects: {
            discussion_id: data.id,
            user_id: USER_ID,
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })
    } else {
      promise = client.mutate({
        mutation: DELETE_HELPFUL,
        variables: {
          objects: {
            discussion_id: {_eq: data.id},
            user_id: {_eq: USER_ID},
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    promise.then(
      () => {
        refetch?.()
      },
      () => {
        enqueueSnackbar(`Failed to ${next ? 'like' : 'unlike'} comment`, {
          variant: 'error',
        })
      }
    )
  }

  const handleTextareaSubmit = (next: string) => {
    setState(CommentState.NONE)

    if (state === CommentState.EDITING) {
      const promise = client.mutate({
        mutation: EDIT_COMMENT,
        variables: {
          id: data.id,
          set: {
            fulltext: next,
          },
        },
        context: INSTRUCTOR_CONTEXT,
      })

      promise.catch(() => {
        enqueueSnackbar(`Failed to edit comment`, {variant: 'error'})
      })

      return
    }

    const promise = client.mutate({
      mutation: ADD_COMMENT_RESPONSE,
      variables: {
        objects: {
          fulltext: next,
          user_id: USER_ID,
          discussion_id: data.id,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    promise.then(
      () => {
        refetch?.()
        setKey((key) => key + 1)
      },
      () => {
        enqueueSnackbar(`Failed to add reply`, {variant: 'error'})
      }
    )
  }

  const handleDelete = () => {
    setState(CommentState.NONE)

    const promise = client.mutate({
      mutation: DELETE_COMMENT,
      variables: {
        commentId: data.id,
      },
      context: INSTRUCTOR_CONTEXT,
    })

    promise.then(
      () => refetch?.(),
      () => enqueueSnackbar(`Failed to delete comment`, {variant: 'error'})
    )
  }

  const handleMenuClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <DiscussionComment
        author={data.user.name}
        avatarUrl={data.user.avatar}
        position={`${placement?.company_job_profile.title} at ${placement?.company_profile.brand_name}`}
        date={data.date_added}
        body={state !== CommentState.EDITING ? data.fulltext : null}
        onMenu={(ev) => setAnchorEl(ev.currentTarget)}
        footer={
          <div>
            {state !== CommentState.EDITING ? (
              <>
                <Typography color="secondary" className="mt-2 text-sm">
                  In Lesson: {data.lesson.title}
                </Typography>

                <Typography
                  color="textSecondary"
                  className="mt-2 text-sm flex gap-2"
                >
                  <span>Like ({data.count_helpful || 0})</span>
                  <span>•</span>
                  <span>Comment ({data.count_response.aggregate.count})</span>
                </Typography>

                <div className="flex gap-1 mt-1 -mb-4 -mx-3">
                  <Checkbox
                    title="Like this comment"
                    checked={isHelpful}
                    icon={<FavoriteBorderIcon />}
                    checkedIcon={<FavoriteIcon color="error" />}
                    onChange={handleHelpfulChange}
                    size="small"
                    color="default"
                    className="h-11 w-11"
                  />

                  <IconButton
                    disabled={state !== CommentState.NONE}
                    onClick={() => setState(CommentState.ADDING)}
                  >
                    <ChatBubbleOutlineIcon fontSize="small" />
                  </IconButton>
                </div>
              </>
            ) : null}

            {(state === CommentState.ADDING ||
              state === CommentState.EDITING) && (
              <ReplyTextarea
                editing={state === CommentState.EDITING}
                comment
                initialValue={
                  state === CommentState.EDITING ? data.fulltext : ''
                }
                onCancel={() => setState(CommentState.NONE)}
                onSubmit={handleTextareaSubmit}
              />
            )}
          </div>
        }
      />

      {data.count_response.aggregate.count > 0 && (
        <>
          <Divider className="mb-6" />

          <div className="ml-16">
            <CommentChildListing key={key} discussionId={data.id} sort="asc" />
          </div>
        </>
      )}

      <Divider className="mb-6" />

      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
        {data.user.id === USER_ID && (
          <MenuItem
            onClick={() => {
              setState(CommentState.EDITING)
              handleMenuClose()
            }}
          >
            Edit
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setState(CommentState.DELETING)
            handleMenuClose()
          }}
          className="text-red-600"
        >
          Delete
        </MenuItem>
      </Menu>

      <DeletePopup
        open={state === CommentState.DELETING}
        name="comment"
        checkboxText="I understand that deleting this comment is permanent and cannot be undone. This action will also delete replies in this comment."
        mutation={handleDelete}
        handleClose={() => setState(CommentState.NONE)}
      />
    </>
  )
}

export default CommentRoot
