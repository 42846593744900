import {
  useQueryService,
  useQueryServiceSocmed,
  useMutationServiceSocmed,
  useMutationUpload,
} from '../../config/axiosQuery.service'
import {useMutation} from '@apollo/react-hooks'
import {ADD_ITEM_TO_CART} from '../../graphql/mutations'
import {USER_ID} from '../../utils/globals'

export const LIST_ACADEMY_CATEGORIES = (limit: number = 4, page: number = 1) =>
  useQueryService(
    ['listAcademyCategories'],
    `academy/category/get?limit=${limit}&page=${page}`
  )

export const LIST_ACADEMY_CATEGORIES_WT_PAGINATION = () =>
  useQueryService(['listAcademyCategoriesWtPagination'], `academy/category/get`)

export const ADD_TO_CART = () => {
  return useMutation(ADD_ITEM_TO_CART)
}

export const LIST_FOLLOWING = () =>
  useQueryServiceSocmed(
    ['listFollowing'],
    `users/${USER_ID}/friendships/following`
  )

export const LIST_FOLLOWERS = () =>
  useQueryServiceSocmed(
    ['listFollowers'],
    `users/${USER_ID}/friendships/followers`
  )

export const FOLLOW_USER = () =>
  useMutationServiceSocmed(`users/${USER_ID}/friendships/follow`, 'POST')

export const UNFOLLOW_USER = () =>
  useMutationServiceSocmed(`users/${USER_ID}/friendships/unfollow`, 'POST')

export const UPLOAD_FILE = () => useMutationUpload()

// mutation example
// export const ADD_COURSE=()=>useMutationService('academy/course/post')

// !on other page!
// const {mutate:onSubmitCourse} = ADD_COURSE()

// const handleSubmit=()=>{
//   onSubmitCourse({id:'x',name:'x'})
// }
