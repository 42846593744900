import {ReactElement} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

type StateInterface = {
  prevRoute?: string
  prevRouteName?: string
}

type BackNavigationProps = {
  label?: string
}

const BackNavigation = (props: BackNavigationProps): ReactElement => {
  const navigate = useNavigate()
  const location = useLocation()

  const state = location.state as StateInterface
  const prevRoute = state?.prevRoute
  const prevRouteName = state?.prevRouteName

  return (
    <div
      className="flex flex-row w-fit mb-5 cursor-pointer"
      onClick={() => (prevRoute ? navigate(prevRoute) : navigate(-1))}
    >
      <ArrowBackIcon className="cursor-pointer" />
      <Typography className="ml-3 font-bold">
        {props.label || `Kembali ${prevRouteName && `ke ${prevRouteName}`}`}
      </Typography>
    </div>
  )
}

export default BackNavigation
