import React from 'react'
import {useNavigate} from 'react-router-dom'
import {Form} from 'react-final-form'
import {
  Button,
  Divider,
  IconButton,
  OutlinedInput,
  Paper,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'
import {CloudUpload} from '@material-ui/icons'

type BookProps = {
  type: string
}
const ContributorBookManageUpload = ({type}: BookProps) => {
  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  const submit = async (data: any) => {
    console.info(data)
  }

  return (
    <Form onSubmit={submit} initialValues={{}}>
      {({handleSubmit, pristine, submitting}) => (
        <Paper component="form" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 px-6 py-6">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              Upload {type === 'ebooks' ? 'E-Book' : 'Audiobook'}
            </Typography>

            <Button
              disabled={pristine || submitting}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Submit
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col gap-4 p-6">
            <div className="my-3">
              <Typography
                component="label"
                className="block text-sm"
                gutterBottom
              >
                Estimate reading duration
              </Typography>

              <div className="flex gap-4">
                <div className="flex gap-2 items-center text-sm">
                  <OutlinedInput
                    type="number"
                    className="w-24"
                    inputProps={{min: 0}}
                  />
                  <Typography color="textSecondary" aria-label="hour(s)">
                    h
                  </Typography>
                </div>

                <div className="flex gap-2 items-center text-sm">
                  <OutlinedInput
                    type="number"
                    className="w-24"
                    inputProps={{min: 0}}
                  />
                  <Typography color="textSecondary" aria-label="minute(s)">
                    m
                  </Typography>
                </div>

                <div className="flex gap-2 items-center text-sm">
                  <OutlinedInput
                    type="number"
                    className="w-24"
                    inputProps={{min: 0}}
                  />
                  <Typography color="textSecondary" aria-label="second(s)">
                    s
                  </Typography>
                </div>
              </div>
            </div>

            <div>
              <Button
                variant="outlined"
                className="block w-full aspect-video h-80 border-2 border-dashed normal-case "
              >
                <CloudUpload className="w-36 h-36 text-[#A9A8A8]" />
                <Typography
                  color="textSecondary"
                  className="mt-2 text-lg font-semibold"
                >
                  Select File to upload
                </Typography>
                <Typography
                  color="textSecondary"
                  className="mt-2 text-sm text-[#A9A8A8]"
                >
                  or drag the e-Book file here
                </Typography>
                <Typography
                  color="textSecondary"
                  className="mt-2 text-sm text-[#A9A8A8]"
                >
                  Type File: pdf
                </Typography>
                <Typography
                  color="textSecondary"
                  className="mt-2 text-sm text-[#A9A8A8]"
                >
                  Max Size: 25MB
                </Typography>
              </Button>
            </div>
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorBookManageUpload
