import React from 'react'
import {Link, useParams, useSearchParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import Skeleton from 'react-loading-skeleton'
import {Divider, IconButton, Paper, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import {
  StyledTab,
  StyledTabs,
} from '../../../../../../components/utils-components/VendorStyles'
import LoadingCircular from '../../../../../../components/loading/LoadingCircular.component'

import {LessonType} from '../../manage/curriculums/_utils'
import LessonDetail from './LessonDetail'
import LessonComments from './LessonComments'
import QuizResponses from './QuizResponses'

import {INSTRUCTOR_CONTEXT} from '../../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_COURSE_LESSON_DETAILS} from '../../../../../../graphql/queries'

const enum LessonTab {
  DETAIL = 'details',
  COMMENT = 'comments',
  // Quiz only
  RESPONSE = 'responses',
}

const ContributorCourseCurriculumDetailLayout = () => {
  const {sectionId, lessonId} = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_LESSON_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      lessonId: +lessonId!,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const tab = searchParams.get('tab') ?? LessonTab.DETAIL

  const handleTabChange = (ev: React.ChangeEvent<{}>, next: LessonTab) => {
    if (next === tab) {
      return
    }

    const params = new URLSearchParams()
    params.set('tab', next)

    setSearchParams(params)
  }

  return (
    <Paper>
      <div className="flex items-center gap-2 px-6 py-1">
        <IconButton
          component={Link}
          to={`../curriculums/${sectionId}`}
          edge="start"
          color="primary"
          title="Go back to previous page"
        >
          <ArrowBackIcon />
        </IconButton>

        <Typography color="primary" className="grow font-bold">
          {data ? data.lesson.title : <Skeleton width="25%" />}
        </Typography>
      </div>

      <Divider />

      <StyledTabs
        value={tab}
        className="bg-[#f6f8f9]"
        onChange={handleTabChange}
      >
        <StyledTab value={LessonTab.DETAIL} label="Detail" />

        {data && data.lesson.lesson_type === LessonType.QUIZ && (
          <StyledTab value={LessonTab.RESPONSE} label="Response" />
        )}

        <StyledTab value={LessonTab.COMMENT} label="Comment" />
      </StyledTabs>

      {!data ? (
        <LoadingCircular height="50vh" />
      ) : tab === LessonTab.DETAIL ? (
        <LessonDetail type={data.lesson.lesson_type} lesson={data.lesson} />
      ) : tab === LessonTab.RESPONSE ? (
        <QuizResponses lessonId={+lessonId!} />
      ) : tab === LessonTab.COMMENT ? (
        <LessonComments
          courseId={data.lesson.section.course_id}
          lessonId={+lessonId!}
        />
      ) : null}
    </Paper>
  )
}

export default ContributorCourseCurriculumDetailLayout
