import {Routes, Route} from 'react-router-dom'

import ContributorDashboardPageLayout from '../pages/contributor/ContributorDashboardPageLayout'
import ContributorCreatorDetailPageLayout from '../pages/contributor/ContributorCreatorDetailPageLayout'

import ContributorDashboardPage from '../pages/contributor/dashboard/ContributorDashboardPage'

import ContributorOrdersPage from '../pages/contributor/orders/ContributorOrdersPage'
import ContributorOrderDetailPage from '../pages/contributor/orders/ContributorOrderDetailPage'
import ContributorOrderPrintPage from '../pages/contributor/orders/ContributorOrderPrintPage'

import ContributorEarningsPage from '../pages/contributor/earnings/ContributorEarningsPage'

import ContributorLearnerPage from '../pages/contributor/learner/ContributorLearnerPage'
import ContributorLearnerDetailPage from '../pages/contributor/learner/ContributorLearnerDetailPage'

import ContributorBankAccountPage from '../pages/contributor/bank-account/ContributorBankAccountPage'
import ContributorAddBankAccountPage from '../pages/contributor/bank-account/ContributorAddBankAccountPage'

import ContributorManageDetailLayout from '../components/contributor/layout/ContributorManageDetailLayout'

import ContributorCoursesPage from '../pages/contributor/courses/ContributorCoursesPage'
import ContributorCourseCreatePage from '../pages/contributor/courses/ContributorCourseCreatePage'

import ContributorCourseDetailPageLayout from '../pages/contributor/courses/details/ContributorCourseDetailPageLayout'
import ContributorCourseDashboardPage from '../pages/contributor/courses/details/dashboard/ContributorCourseDashboardPage'
import ContributorCourseManagePage from '../pages/contributor/courses/details/manage/ContributorCourseManagePage'
import ContributorCourseLearnersPage from '../pages/contributor/courses/details/learners/ContributorCourseLearnersPage'
import ContributorCourseManageLandingPage from '../pages/contributor/courses/details/manage/ContributorCourseManageLandingPage'
import ContributorCourseManageTargetsPage from '../pages/contributor/courses/details/manage/ContributorCourseManageTargetsPage'
import ContributorCourseManageCurriculumsPage from '../pages/contributor/courses/details/manage/ContributorCourseManageCurriculumsPage'
import ContributorCourseCommentPage from '../pages/contributor/courses/details/comment/ContributorCourseCommentPage'
import ContributorCourseEarningsPage from '../pages/contributor/courses/details/earnings/ContributorCourseEarningsPage'
import ContributorCoursePreview from '../pages/contributor/courses/details/preview/ContributorCoursePreview'
import ContributorCourseCurriculumPage from '../pages/contributor/courses/details/curriculums/ContributorCourseCurriculumPage'

import ContributorCourseCurriculumDetailPage from '../pages/contributor/courses/details/curriculums/details/ContributorCourseCurriculumDetailPage'
import ContributorCourseCurriculumResponseDetailPage from '../pages/contributor/courses/details/curriculums/ContributorCourseCurriculumResponseDetailPage'

import ContributorEventsPage from '../pages/contributor/events/ContributorEventsPage'
import ContributorEventCreatePage from '../pages/contributor/events/ContributorEventCreatePage'
import ContributorEventeDetailPageLayout from '../pages/contributor/events/details/ContributorEventDetailPageLayout'
import ContributorEventDashboardPage from '../pages/contributor/events/details/dashboard/ContributorEventDashboardPage'
import ContributorEventManagePage from '../pages/contributor/events/details/manage/ContributorEventManagePage'
import ContributorEventManageLandingPage from '../pages/contributor/events/details/manage/ContributorEventManageLandingPage'
import ContributorEventPreviewPage from '../pages/learner/Event.page'

import ContributorBooksPage from '../pages/contributor/books/ContributorBooksPage'
import ContributorBookCreatePage from '../pages/contributor/books/ContributorBookCreatePage'

import ContributorMicrolearningsPage from '../pages/contributor/microlearnings/ContributorMicrolearningsPage'
import ContributorMicrolearningCreatePage from '../pages/contributor/microlearnings/ContributorCreateMicrolearningPage'
import ContributorMicroLearningDetailPageLayout from '../pages/contributor/microlearnings/details/ContributorMicrolearningDetailPageLayout'
import ContribMicroLearningDashboarPage from '../pages/contributor/microlearnings/details/dashboard/ContributorMicrolearningDashboardPage'
import ContributorBookDetailPageLayout from '../pages/contributor/books/details/ContributorBookDetailPageLayout'
import ContributorBookDashboardPage from '../pages/contributor/books/details/dashboard/ContributorBookDashboardPage'
import ContributorBookManagePage from '../pages/contributor/books/details/manage/ContributorBookManagePage'
import ContributorBookManageLandingPage from '../pages/contributor/books/details/manage/ContributorBookManageLandingPage'
import ContributorBookManageUpload from '../pages/contributor/books/details/manage/ContributorBookManageUpload'
import ContributorBookLearnersPage from '../pages/contributor/books/details/learners/ContributorBookLearnersPage'
import ContributorBookEarningsPage from '../pages/contributor/books/details/earnings/ContributorBookEarningsPage'
import ContributorBookReviewPage from '../pages/contributor/books/details/review/ContributorBookReviewPage'

const ContributorRoutes = () => {
  return (
    <Routes>
      <Route element={<ContributorDashboardPageLayout />}>
        <Route index element={<ContributorDashboardPage />} />

        <Route path="/orders" element={<ContributorOrdersPage />} />
        <Route
          path="/orders/:invoiceId"
          element={<ContributorOrderDetailPage />}
        />

        <Route path="/courses" element={<ContributorCoursesPage />} />
        <Route
          path="/microlearnings"
          element={<ContributorMicrolearningsPage />}
        />
        <Route path="/learner" element={<ContributorLearnerPage />} />
        <Route
          path="/learner/detail"
          element={<ContributorLearnerDetailPage />}
        />
        <Route path="/bankaccount" element={<ContributorBankAccountPage />} />
        <Route
          path="/bankaccount/add"
          element={<ContributorAddBankAccountPage />}
        />
        <Route
          path="/bankaccount/:id"
          element={<ContributorAddBankAccountPage />}
        />
        <Route path="/events" element={<ContributorEventsPage />} />
        <Route path="/ebooks" element={<ContributorBooksPage type="ebook" />} />
        <Route
          path="/audiobooks"
          element={<ContributorBooksPage type="audiobook" />}
        />
        <Route path="/earnings" element={<ContributorEarningsPage />} />
      </Route>

      <Route
        path="/orders/:invoiceId/print"
        element={<ContributorOrderPrintPage />}
      />

      <Route element={<ContributorCreatorDetailPageLayout />}>
        <Route
          path="/courses/:courseSlug"
          element={<ContributorCourseDetailPageLayout />}
        >
          <Route index element={<ContributorCourseDashboardPage />} />
          <Route path="manage" element={<ContributorCourseManagePage />} />
          <Route path="learners" element={<ContributorCourseLearnersPage />} />
          <Route path="earnings" element={<ContributorCourseEarningsPage />} />
          <Route path="curriculums" element={<div>curriculum course</div>} />
          <Route
            path="curriculums/:sectionId"
            element={<ContributorCourseCurriculumPage />}
          />
          <Route path="reviews" element={<div>course reviews</div>} />
          <Route path="comments" element={<ContributorCourseCommentPage />} />
        </Route>

        <Route
          path="/courses/:courseSlug"
          element={<ContributorManageDetailLayout label="Course Detail" />}
        >
          <Route
            path="curriculums/:sectionId/:lessonId"
            element={<ContributorCourseCurriculumDetailPage />}
          />

          <Route
            path="curriculums/:sectionId/:lessonId/responses/:userId"
            element={<ContributorCourseCurriculumResponseDetailPage />}
          />
        </Route>

        <Route
          path="/courses/:courseSlug"
          element={<ContributorManageDetailLayout label="Manage Course" />}
        >
          <Route
            path="manage/landing"
            element={<ContributorCourseManageLandingPage />}
          />
          <Route
            path="manage/targets"
            element={<ContributorCourseManageTargetsPage />}
          />
          <Route
            path="manage/curriculums"
            element={<ContributorCourseManageCurriculumsPage />}
          />
        </Route>

        <Route
          path="/microlearning/:microlearningId"
          element={<ContributorMicroLearningDetailPageLayout />}
        >
          <Route index element={<ContribMicroLearningDashboarPage />} />
          <Route path="manage" element={<div>Micro Learning Manage</div>} />
          <Route path="learners" element={<div>Micro Learning Learner</div>} />
          <Route path="earnings" element={<div>Micro Learning Earnings</div>} />
          <Route
            path="questions"
            element={<div>Micro Learning Questions</div>}
          />
          <Route path="reviews" element={<div>Micro Learning Reviews</div>} />
        </Route>
        <Route
          path="/event/:eventId"
          element={<ContributorEventeDetailPageLayout />}
        >
          <Route index element={<ContributorEventDashboardPage />} />
          <Route path="manage" element={<ContributorEventManagePage />} />
        </Route>

        <Route
          path="/event/:eventId"
          element={<ContributorManageDetailLayout label="Manage Event" />}
        >
          <Route
            path="manage/landing"
            element={<ContributorEventManageLandingPage />}
          />
        </Route>

        <Route
          path="/ebooks/:bookSlug"
          element={<ContributorBookDetailPageLayout type="ebooks" />}
        >
          <Route index element={<ContributorBookDashboardPage />} />
          <Route
            path="manage"
            element={<ContributorBookManagePage type="ebook" />}
          />
          <Route path="learners" element={<ContributorBookLearnersPage />} />
          <Route path="earnings" element={<ContributorBookEarningsPage />} />
          <Route
            path="reviews"
            element={<ContributorBookReviewPage type="ebook" />}
          />
        </Route>
        <Route
          path="/ebooks/:bookSlug"
          element={<ContributorManageDetailLayout label="Manage E-Book" />}
        >
          <Route
            path="manage/landing"
            element={<ContributorBookManageLandingPage type="ebooks" />}
          />
          <Route
            path="manage/upload"
            element={<ContributorBookManageUpload type="ebooks" />}
          />
        </Route>
        <Route
          path="/audiobooks/:bookSlug"
          element={<ContributorBookDetailPageLayout type="audiobooks" />}
        >
          <Route index element={<ContributorBookDashboardPage />} />
          <Route
            path="manage"
            element={<ContributorBookManagePage type="audiobook" />}
          />
          <Route path="learners" element={<ContributorBookLearnersPage />} />
          <Route path="earnings" element={<ContributorBookEarningsPage />} />
          <Route
            path="reviews"
            element={<ContributorBookReviewPage type="audiobook" />}
          />
        </Route>
        <Route
          path="/audiobooks/:bookSlug"
          element={<ContributorManageDetailLayout label="Manage Audiobook" />}
        >
          <Route
            path="manage/landing"
            element={<ContributorBookManageLandingPage type="audiobook" />}
          />
          <Route
            path="manage/upload"
            element={<ContributorBookManageUpload type="audiobook" />}
          />
        </Route>
        <Route
          path="/event/:eventId"
          element={<ContributorEventeDetailPageLayout />}
        >
          <Route index element={<ContributorEventDashboardPage />} />
          <Route path="manage" element={<ContributorEventManagePage />} />
        </Route>
        <Route
          path="/event/:eventId"
          element={<ContributorManageDetailLayout label="Manage Event" />}
        >
          <Route
            path="manage/landing"
            element={<ContributorEventManageLandingPage />}
          />
        </Route>
      </Route>

      <Route path="/courses/create" element={<ContributorCourseCreatePage />} />
      <Route
        path="/ebooks/create"
        element={<ContributorBookCreatePage type="ebook" />}
      />
      <Route
        path="/audiobooks/create"
        element={<ContributorBookCreatePage type="audiobook" />}
      />
      <Route path="/events/create" element={<ContributorEventCreatePage />} />
      <Route
        path="/microlearnings/create"
        element={<ContributorMicrolearningCreatePage />}
      />
      <Route
        path="/courses/:courseSlug/preview"
        element={<ContributorCoursePreview />}
      />
      <Route
        path="/event/:eventId/preview"
        element={<ContributorEventPreviewPage isPreview={true} />}
      />
    </Routes>
  )
}

export default ContributorRoutes
