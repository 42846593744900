import gql from 'graphql-tag'

export const GET_TOP_EXPERT = gql`
  query ($all_offset: Int, $all_limit: Int) {
    learning_contributor_ratings(
      order_by: {
        total_learner_count: desc_nulls_last
        total_rating_avg: desc_nulls_last
      }
      limit: $all_limit
      offset: $all_offset
    ) {
      global_user {
        id
        name
        contributor_label
        avatar
        academy_books(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        academy_courses(
          limit: 5
          where: {thumbnail: {_is_null: false, _neq: ""}}
        ) {
          id
          title
          thumbnail
        }
        classrooms(
          limit: 5
          where: {avatar_url: {_is_null: false, _neq: ""}, privacy: {_eq: 0}}
        ) {
          id
          title: name
          thumbnail: avatar_url
        }
        event_schedules(limit: 5, where: {thumbnail: {_is_null: false}}) {
          id
          title
          thumbnail
        }
        people_work_placements {
          company_job_profile {
            position
            title
          }
          company_profile {
            brand_name
            legal_name
          }
        }
      }
      total_rating_avg
      total_rating_count
      total_learner_count
      total_catalog_count
    }
  }
`
