import gql from 'graphql-tag'

// Root comments
export const ADD_COMMENT = gql`
  mutation ($objects: [academy_discussions_insert_input!]!) {
    insert_academy_discussions(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const EDIT_COMMENT = gql`
  mutation ($set: academy_discussions_set_input, $id: Int!) {
    update_academy_discussions_by_pk(_set: $set, pk_columns: {id: $id}) {
      id
      fulltext
    }
  }
`

export const DELETE_COMMENT = gql`
  mutation ($commentId: Int!) {
    courseDeleteCommentInstructor(commentId: $commentId) {
      success
    }
  }
`

// Child comments
export const ADD_COMMENT_RESPONSE = gql`
  mutation ($objects: [academy_discussion_responses_insert_input!]!) {
    insert_academy_discussion_responses(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const EDIT_COMMENT_RESPONSE = gql`
  mutation ($set: academy_discussion_responses_set_input, $id: Int!) {
    update_academy_discussion_responses_by_pk(
      _set: $set
      pk_columns: {id: $id}
    ) {
      id
      fulltext
    }
  }
`

export const DELETE_COMMENT_RESPONSE = gql`
  mutation ($commentId: Int!) {
    courseDeleteCommentResponseInstructor(commentId: $commentId) {
      success
    }
  }
`

// Mark as helpful
export const ADD_HELPFUL = gql`
  mutation ($objects: [academy_discussion_helpful_insert_input!]!) {
    insert_academy_discussion_helpful(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`
export const DELETE_HELPFUL = gql`
  mutation ($objects: academy_discussion_helpful_bool_exp!) {
    delete_academy_discussion_helpful(where: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`
