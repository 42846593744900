import React from 'react'
import {useQuery as useApolloQuery} from '@apollo/client'

import DOMPurify from 'dompurify'

import {
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  Paper,
  Radio,
  Typography,
} from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import {QuestionType} from '../manage/curriculums/components/QuestionSubform'

import {useQueryService} from '../../../../../config/axiosQuery.service'

import {COMPANY_LOGO} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_COURSE_LESSON_DETAILS} from '../../../../../graphql/queries'

export interface Answer {
  grade: number
  answers: string[]
}

export interface Question {
  id: number
  fulltext: string
  fieldtype: QuestionType
  passingGrade: number
  choices: string[]
  answer_keys: string[]
  submitted_answer?: Answer
}

const SanitizedTitle = (props: {title: string}) => {
  const {title} = props

  const sanitized = React.useMemo(() => {
    return DOMPurify.sanitize(title, {
      // NOTE(intrnl): unwrap paragraphs entirely.
      FORBID_TAGS: ['p'],
    })
  }, [title])

  return (
    <Typography
      color="primary"
      className="text-sm font-bold whitespace-nowrap text-ellipsis overflow-hidden"
      dangerouslySetInnerHTML={{__html: sanitized}}
    />
  )
}

const ChoicesView = (props: {
  choices: string[]
  answers: string[]
  submitted_answers?: string[]
  multiple: boolean
}) => {
  const {choices, answers, submitted_answers, multiple} = props

  const Input = multiple ? Checkbox : Radio

  return (
    <FormGroup className="mt-2">
      {choices.map((value) => {
        const isAnswer = answers.includes(value)
        const isSubmitted = submitted_answers
          ? submitted_answers.includes(value)
          : false

        return (
          <FormControlLabel
            key={value}
            control={
              <Input
                checked={isSubmitted}
                readOnly
                color="primary"
                className="cursor-default"
              />
            }
            label={
              <>
                <span className="mr-4 text-sm">{value}</span>

                {isAnswer ? (
                  <CheckIcon className="text-green-600" />
                ) : (isSubmitted && !isAnswer) || (!isSubmitted && isAnswer) ? (
                  <CloseIcon className="text-red-600" />
                ) : null}
              </>
            }
            className="cursor-default"
          />
        )
      })}
    </FormGroup>
  )
}

const QuestionView = (props: {index: number; question: Question}) => {
  const {question} = props

  const sanitized = React.useMemo(() => {
    return DOMPurify.sanitize(question.fulltext)
  }, [question])

  return (
    <>
      <div dangerouslySetInnerHTML={{__html: sanitized}} />

      {question.fieldtype === QuestionType.SHORT_ANSWER ? null : (
        <ChoicesView
          multiple={question.fieldtype === QuestionType.MULTIPLE_ANSWER}
          choices={question.choices}
          answers={question.answer_keys}
          submitted_answers={question.submitted_answer?.answers}
        />
      )}
    </>
  )
}

export interface QuizResponsesModalProps {
  open?: boolean
  title?: string
  questions: Question[]
  onClose?: () => void
}

const QuizResponsesModal = (props: QuizResponsesModalProps) => {
  const {open = false, title, questions, onClose} = props

  const [derived, setDerived] = React.useState(questions)
  const [selected, setSelected] = React.useState(0)

  if (derived !== questions) {
    setDerived(questions)
    setSelected(0)
  }

  const selectedQuestion =
    selected < questions.length ? questions[selected] : null

  const score = React.useMemo(() => {
    let value = -1
    let correct = 0

    for (const question of questions) {
      const answer = question.submitted_answer

      if (!answer) {
        continue
      }

      value = (value === -1 ? 0 : value) + answer.grade

      if (answer.grade === question.passingGrade) {
        correct++
      }
    }

    if (value === -1) {
      return null
    }

    return {value: value / questions.length, correct}
  }, [questions])

  return (
    <Dialog
      open={open}
      fullScreen
      classes={{paper: 'bg-[#fafafa] items-stretch'}}
    >
      <Paper square elevation={2} className="z-10">
        <div className="mx-auto flex items-center justify-between h-14 max-w-screen-xl px-4">
          <img src={COMPANY_LOGO} alt="Company logo" className="h-10" />

          <Typography color="primary" className="font-semibold">
            {title}
          </Typography>

          <div>
            <Button onClick={onClose}>
              <Typography color="error" className="text-sm">
                Close
              </Typography>
            </Button>
          </div>
        </div>
      </Paper>

      <div className="mx-auto grid grow gap-8 lg:grid-cols-[320px_minmax(0,_1fr)] w-full max-w-screen-xl px-4 py-8 lg:overflow-hidden">
        <Paper className="flex flex-col lg:min-h-0 min-w-0">
          <div className="flex items-center h-14 px-6 shrink-0">
            <Typography color="primary" className="font-bold">
              Question
            </Typography>
          </div>

          <Divider />

          <List disablePadding className="grow overflow-y-auto">
            {questions.map((question, index) => {
              const answer = question.submitted_answer

              const icon =
                !answer || answer.grade === 0 ? (
                  <HighlightOffIcon className="text-red-600" />
                ) : answer.grade !== question.passingGrade ? (
                  <CheckCircleOutlineIcon className="text-yellow-600" />
                ) : (
                  <CheckCircleOutlineIcon className="text-green-600" />
                )

              return (
                <ListItem
                  key={question.id}
                  button
                  selected={index === selected}
                  onClick={() => setSelected(index)}
                  className="h-12 gap-4"
                >
                  <SanitizedTitle
                    title={`${index + 1}. ${question.fulltext}`}
                  />

                  {icon}
                </ListItem>
              )
            })}
          </List>
        </Paper>

        <Paper className="flex flex-col">
          <div className="flex items-center justify-between px-6 h-20">
            <Typography color="textSecondary" className="text-sm">
              {selected + 1} of {questions.length} questions
            </Typography>

            {score && (
              <div className="flex flex-col text-sm">
                <p className="font-semibold">Score:</p>

                <div className="flex gap-3 items-end">
                  <span className="text-green-500 text-2xl">{score.value}</span>

                  <span className="mb-1">
                    ({score.correct}/{questions.length})
                  </span>
                </div>
              </div>
            )}
          </div>

          <Divider />

          <div className="p-6 text-sm grow">
            {selectedQuestion && (
              <QuestionView index={selected} question={selectedQuestion} />
            )}
          </div>

          <div className="flex p-6 gap-6">
            <Button
              disabled={selected === 0}
              onClick={() => setSelected(selected - 1)}
              variant="outlined"
              color="primary"
            >
              Previous
            </Button>

            <div className="grow" />

            <Button
              onClick={() =>
                selected !== questions.length - 1
                  ? setSelected(selected + 1)
                  : onClose?.()
              }
              variant="contained"
              color="primary"
              disableElevation
            >
              {selected !== questions.length - 1 ? 'Next' : 'Finish'}
            </Button>
          </div>
        </Paper>
      </div>
    </Dialog>
  )
}

export default QuizResponsesModal

export interface RemoteQuizResponsesModalProps {
  open?: boolean
  lessonId: number
  userId: number
  sequenceId: number
  onClose?: () => void
}

export const RemoteQuizResponsesModal = (
  props: RemoteQuizResponsesModalProps
) => {
  const {open, lessonId, userId, sequenceId, onClose} = props

  const {data: lessonData} = useApolloQuery(
    GET_CONTRIBUTOR_COURSE_LESSON_DETAILS,
    {
      skip: !open,
      fetchPolicy: 'cache-first',
      variables: {
        lessonId,
      },
      context: INSTRUCTOR_CONTEXT,
    }
  )

  const restUrl = `academy/lesson/${lessonId}/question?student=${userId}`

  const {data} = useQueryService(restUrl, restUrl, {
    enabled: open,
  })

  const questions = React.useMemo(() => {
    const array: Question[] = []

    if (data) {
      for (const question of data) {
        const answer =
          question.Answer && question.Answer.logSequence === sequenceId
            ? question.Answer
            : question.AcademyUserAnswers.find(
                (answer: any) => answer.logSequence === sequenceId
              )

        array.push({
          id: question.id,
          fulltext: question.fulltext,
          fieldtype: question.fieldtype,
          passingGrade: question.passingGrade,
          choices: question.choices,
          answer_keys: question.answerKeys,
          submitted_answer: answer
            ? {
                grade: answer.grade,
                answers: answer.answers,
              }
            : undefined,
        })
      }
    }

    return array
  }, [data, sequenceId])

  return (
    <QuizResponsesModal
      open={open}
      title={lessonData ? lessonData.lesson.title : 'Quiz'}
      questions={questions}
      onClose={onClose}
    />
  )
}
