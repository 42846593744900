import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
} from '@material-ui/core'
import { useTitle } from 'hoofd'
import React, { ChangeEvent } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import { Filter } from '@smartasn/wlb-utils-components'
import { format } from 'date-fns'
import { USER_ID } from '../../../utils/globals'
import {
  capitalize,
  convertToRupiah,
  mapFilterResults,
} from '../../../utils/helpers'
import { useQuery } from '@apollo/client'
import { GET_CONTRIBUTOR_MY_EVENTS } from '../../../graphql/queries'
import { INSTRUCTOR_CONTEXT } from '../../../utils/contributor-helpers'
import NoListComponent from '../../../components/empty-state/NoList.component'

interface VariableInterface {
  limit: number
  offset: number
  userId: string
  search: string
  dateLastmonth: string
  orderBy: {
    date_added: string
  }
  createdDate: {
    _lte: null
    _gte: null
  }
  status?: string[]
}
interface Event {
  id: number
  title: string
  status: string
  thumbnail: string
  date_added: Date
  pricing: {
    IDR: string
  }
  pricing_idr: number
  average_rating: number
}

type ItemProps = {
  data: Event
}

export const listFilter = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        { value: 'draft', label: 'Draft' },
        { value: 'pending', label: 'Pending' },
        { value: 'active', label: 'Active' },
      ],
    },
  },
  {
    name: 'Created Date',
    fieldName: 'date',
    type: 'date',
  },
]

const sortOptions = [
  { label: 'Latest', value: { date_added: 'desc' } },
  { label: 'Oldest', value: { date_added: 'asc' } },
]

const Item = (props: ItemProps) => {
  const defaultThumbnail = require('../../../assets/images/defaultEventCover.image.png')
  const { data } = props

  return (
    <TableRow
      component={Link}
      to={`/contributor/event/${data.id}`}
      className="cursor-pointer hover:bg-[#f5f5f5] transition"
    >
      <TableCell className="w-20 pr-0 lg:w-52">
        <img src={data.thumbnail || defaultThumbnail} alt="event" />
      </TableCell>
      <TableCell>
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-2">
            <p className="text-sm font-semibold">{data.title}</p>
            <p className="text-xs text-gray-400">
              Created {format(new Date(data.date_added), 'MMM d, yyyy')}
            </p>
          </div>
          <div className="flex w-[28%] justify-between items-center">
            <p
              className={`text-xs ${data.status === 'active'
                  ? 'text-green-500'
                  : data.status === 'pending'
                    ? 'text-red-500'
                    : 'text-gray-400'
                }`}
            >
              {capitalize(data.status)}
            </p>
            <p className="text-xs text-gray-400">
              {data.pricing_idr
                ? convertToRupiah(data.pricing_idr, true)
                : 'FREE'}
            </p>
          </div>
        </div>
      </TableCell>
    </TableRow>
  )
}

const ContributorEventsPage = () => {
  useTitle('Contributor - Events')
  const dateNow = format(new Date(), 'yyyy-MM-dd')
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [orderBy, setOrderBy] = React.useState({ date_added: 'desc' })
  const [filter, setFilter] = React.useState({
    date: {
      _lte: null,
      _gte: null,
    },
    status: [],
  })
  const [searchParams, setSearchParams] = useSearchParams()
  const searchTimeoutRef = React.useRef<any>()

  const search = searchParams.get('query') ?? ''

  const variables: VariableInterface = {
    limit: limit,
    offset: page * limit,
    userId: USER_ID,
    search: `%${search}%`,
    dateLastmonth: dateNow,
    orderBy,
    createdDate: filter.date,
    status: filter.status,
  }

  if (!filter.status.length) {
    delete variables.status
  }

  const { data, loading } = useQuery(GET_CONTRIBUTOR_MY_EVENTS, {
    variables,
    context: INSTRUCTOR_CONTEXT,
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const onLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLimit(Number(e.target.value))
  }
  const handleFilterApply = (result: any) => {
    const mappedResult: any = mapFilterResults(result[0])

    if (!Object.keys(mappedResult).length) {
      setFilter({
        date: {
          _lte: null,
          _gte: null,
        },
        status: [],
      })
    } else {
      if (mappedResult.date) {
        setFilter({
          ...filter,
          ...mappedResult,
          date: {
            _gte: mappedResult.date.from,
            _lte: mappedResult.date.to,
          },
        })
      } else {
        setFilter({ ...filter, ...mappedResult })
      }
    }
  }

  const handleOrderByChange = (value: any) => {
    setOrderBy(JSON.parse(value))
  }

  return (
    <Paper>
      <div className="px-6 h-[92px] flex items-center justify-between">
        <Typography color="primary" className="font-bold">
          My Event
        </Typography>
        <Button
          component={Link}
          to="create"
          variant="contained"
          color="primary"
          disableElevation
          className="px-9 py-[14px]"
        >
          Create Event
        </Button>
      </div>
      <Divider />
      <div className="px-6 pb-4 pt-6">
        <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          autoFocus
          onChange={handleSearchChange}
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Divider />
      <div className="flex items-center justify-between px-2 py-5">
        <Select
          value={JSON.stringify(orderBy)}
          variant="standard"
          disableUnderline
          onChange={(e) => handleOrderByChange(e.target.value)}
          SelectDisplayProps={{
            className:
              'text-sm font-semibold pr-6 pl-4 py-2 focus:bg-white outline-none rounded transition',
          }}
        >
          {sortOptions.map((option: any, idx: number) => {
            return (
              <MenuItem value={JSON.stringify(option.value)} key={idx}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
        <Filter
          onApply={handleFilterApply}
          listFilter={listFilter}
          includeExclude={false}
        >
          <Button className="font-semibold">Filter</Button>
        </Filter>
      </div>
      {search && (
        <div className="px-6 pb-4">
          <Typography color="textSecondary" className="text-sm">
            Showing {data?.event_schedules_aggregate.aggregate.count || 0}{' '}
            results based on your search &quot;
            <span className="whitespace-pre-wrap">{search}</span>&quot;
          </Typography>
        </div>
      )}
      <Divider />
      <TableContainer className="overflow-x-visible">
        <Table className="h-full">
          <TableBody className="h-full">
            {loading ? (
              <TableRow className="flex justify-center py-8">
                <CircularProgress />
              </TableRow>
            ) : data?.event_schedules.length > 0 ? (
              data.event_schedules.map((data: Event) => (
                <Item data={data} key={data.id} />
              ))
            ) : (
              <TableRow className="flex justify-center py-8">
                <NoListComponent title="Sorry, No List" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data?.event_schedules_aggregate?.aggregate?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={onLimitChange}
      />
    </Paper>
  )
}

export default ContributorEventsPage
