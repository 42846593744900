import {Typography} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import {FC, ReactElement, useState} from 'react'
import {IMicroLearning, IMicroRelated} from '../../../interfaces'
import {MICRO_LEARNING_BY_CATEGORY} from '../../../provider/services/MicroLearning.service'
import {LearningType} from '../../../utils/enum'
import LoadingCircular from '../../loading/LoadingCircular.component'
import LearnerCatalogCard from '../catalog/LearnerCatalogCard.component'
import useStyles, {LoadingBox} from './MicroLearning.style'

const RelatedMicroLearning: FC<IMicroRelated> = (
  props: IMicroRelated
): ReactElement => {
  const {categoryId} = props
  const classes = useStyles()
  const [pagination, setPagination] = useState<{
    limit: number
    offset: number
    page: number
  }>({limit: 4, offset: 0, page: 1})

  const {data: relatedMicroData, error: relatedMicroError} =
    MICRO_LEARNING_BY_CATEGORY({
      categoryId: categoryId,
      limit: pagination.limit,
      offset: pagination.offset,
    })

  if (relatedMicroData && relatedMicroError) {
    return (
      <LoadingBox>
        <LoadingCircular />
      </LoadingBox>
    )
  }

  return (
    <div id="related-micro-learning" className="w-full lg:w-9/12">
      {relatedMicroData?.microlearnings_aggregate?.aggregate?.count !== 0 && (
        <>
          <div className="flex justify-between items-center mt-10">
            <Typography className="text-sm font-extrabold">
              Pembelajaran Mikro Terkait
            </Typography>

            <div className="flex w-fit h-fit">
              {pagination.page > 1 ? (
                <div className={classes.chevronContainer}>
                  <ChevronLeft
                    className="cursor-pointer"
                    onClick={() => {
                      let {page, offset} = pagination
                      const {limit} = pagination

                      page -= 1
                      offset = limit * (page - 1)

                      setPagination({
                        page,
                        offset,
                        limit,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classes.chevronContainer}
                  style={{backgroundColor: '#e5e5e5'}}
                >
                  <ChevronLeft />
                </div>
              )}
              {pagination.page * pagination.limit <
              relatedMicroData?.microlearnings_aggregate?.aggregate?.count ? (
                <div className={classes.chevronContainer}>
                  <ChevronRight
                    className="cursor-pointer"
                    onClick={() => {
                      let {page, offset} = pagination
                      const {limit} = pagination

                      page += 1
                      offset = limit * (page - 1)

                      setPagination({
                        page,
                        offset,
                        limit,
                      })
                    }}
                  />
                </div>
              ) : (
                <div
                  className={classes.chevronContainer}
                  style={{backgroundColor: '#e5e5e5'}}
                >
                  <ChevronRight />
                </div>
              )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            {relatedMicroData?.microlearnings?.map(
              (item: IMicroLearning, i: number) => (
                <LearnerCatalogCard
                  key={`related-micro-learning${i}`}
                  type={LearningType.MICRO_LEARNING}
                  item={item}
                  hidePrice={false}
                />
              )
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default RelatedMicroLearning
