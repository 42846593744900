import React, {type ReactElement, type ReactNode} from 'react'
import {Form, type FormProps} from 'react-final-form'

import {Button, Paper, Typography} from '@material-ui/core'

import CreateStepper from '../contributor/CreateStepper'

import {COMPANY_LOGO} from '../../utils/globals'

export interface CreateWizardProps {
  title: string
  children: ReactNode
  onSubmit: FormProps<any>['onSubmit']
  onCancel?: () => void
  initialValues?: FormProps['initialValues']
  stepContainerProps?: React.HTMLAttributes<HTMLDivElement>
}

export interface CreateWizardPageProps {
  label: string
  title?: string
  description?: string
  children?: ReactNode | undefined
  validate?: FormProps<any>['validate']
}

const CreateWizardPage = (props: CreateWizardPageProps) => {
  const {children, title, description} = props

  return (
    <>
      {title && (
        <Typography gutterBottom className="text-center text-lg font-bold">
          {title}
        </Typography>
      )}

      {description && (
        <Typography color="textSecondary" className="text-center text-sm mb-8">
          {description}
        </Typography>
      )}

      <div className="flex flex-col gap-4">{children}</div>
    </>
  )
}

// NOTE(intrnl): implemented as class components because react-final-form
// forbids changing the onSubmit and validate props, even though we'd need that
// to make sure that we see the latest props and state at all times
class CreateWizard extends React.Component<CreateWizardProps> {
  static Page = CreateWizardPage

  state: {
    page: number
    children: ReactElement<CreateWizardPageProps>[]
  }

  constructor(props: CreateWizardProps) {
    super(props)

    const children = React.Children.toArray(this.props.children) as any

    this.state = {
      page: 0,
      children,
    }
  }

  handleSubmit = (values: any, form: any) => {
    const {page, children} = this.state

    const lastPage = page === children.length - 1

    if (lastPage) {
      return this.props.onSubmit(values, form)
    } else {
      this.handleNext()
    }
  }

  handleValidate = (values: any) => {
    const {page, children} = this.state
    const active = children[page]

    if (active && active.props.validate) {
      return active.props.validate(values)
    }
  }

  handleNext = () => {
    this.setState({page: this.state.page + 1})
  }

  handlePrev = () => {
    this.setState({page: this.state.page - 1})
  }

  componentDidUpdate(prev: CreateWizardProps) {
    if (prev.children !== this.props.children) {
      this.setState({children: React.Children.toArray(this.props.children)})
    }
  }

  render() {
    const {title, initialValues, onCancel} = this.props
    const {page, children} = this.state

    const labels = children.map((el) => el.props.label)
    const child = children[page]

    const firstPage = page === 0
    const lastPage = page === children.length - 1

    return (
      <Form
        onSubmit={this.handleSubmit}
        validate={this.handleValidate}
        initialValues={initialValues}
      >
        {({handleSubmit, pristine, submitting}) => (
          <form onSubmit={handleSubmit} className="min-h-screen bg-white">
            <Paper square elevation={2}>
              <div className="mx-auto flex h-14 max-w-screen-lg items-center justify-between">
                <img src={COMPANY_LOGO} alt="Company logo" className="h-10" />

                <Typography color="primary" className="font-semibold">
                  {title}
                </Typography>

                <div>
                  <Button disabled={submitting} onClick={onCancel}>
                    <Typography
                      color={submitting ? 'inherit' : 'error'}
                      className="text-sm"
                    >
                      Cancel
                    </Typography>
                  </Button>
                </div>
              </div>
            </Paper>

            <div className="mx-auto mt-4 max-w-screen-lg">
              <CreateStepper active={page} labels={labels} />
            </div>

            <div className="mx-auto mt-4 max-w-screen-sm">{child}</div>

            <div className="mx-auto absolute bottom-4 left-0 right-0 bg-white flex gap-4 max-w-screen-sm">
              <Button
                disabled={firstPage || submitting}
                onClick={this.handlePrev}
                color="primary"
                variant="outlined"
                size="large"
                fullWidth
              >
                Previous
              </Button>

              <Button
                type="submit"
                disabled={pristine || submitting}
                color="primary"
                variant="contained"
                size="large"
                disableElevation
                fullWidth
              >
                {lastPage ? 'Submit' : 'Next'}
              </Button>
            </div>
          </form>
        )}
      </Form>
    )
  }
}

export default CreateWizard
