import React from 'react'
import {useApolloClient, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {Button, Card, Typography} from '@material-ui/core'

import {VENDOR_URL} from '../../../../utils/globals'
import {convertToRupiah} from '../../../../utils/helpers'
import {GET_USER_CART_COUNT} from '../../../../graphql/queries'
import {
  ADD_ITEM_TO_CART,
  CHECKOUT_FREE_ITEM,
} from '../../../../graphql/mutations'
import {LEARNING_DIARY_ACTIVITY} from '../../../../utils/constans'

export interface CartItem {
  item_id: string
  item_table: string
  item_object: {
    name: string
    image: string
    price: number
    weight: number
    note?: string
  }
  item_vendor: string
  item_vendor_id: string
  item_vendor_table: 'global_users' | 'company_profiles'
  item_quantity: number
}

export interface CheckoutCardProps {
  itemType: string
  cartData: CartItem
  actualPrice?: number
  onFreeAcquisition?: () => void
  isPreview?: boolean
}

const CheckoutCard = (props: CheckoutCardProps) => {
  const {itemType, actualPrice, cartData, onFreeAcquisition} = props

  const [dispatching, setDispatching] = React.useState(false)

  const client = useApolloClient()
  const {enqueueSnackbar} = useSnackbar()

  const {data, refetch} = useQuery(GET_USER_CART_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      itemTable: cartData.item_table,
      itemIds: [cartData.item_id],
    },
  })

  const price = cartData.item_object.price

  const isFree = price < 1
  const isInCart = data && data.marketplace_user_carts.length > 0

  const handleCartAddition = (redirect: boolean) => {
    if (props.isPreview) return

    if (!isFree && isInCart) {
      const cartId = data.marketplace_user_carts[0].id

      window.open(`${VENDOR_URL}/profile/shopping-cart?s=${cartId}`)
      return
    }

    setDispatching(true)

    let promise = client.mutate({
      mutation: ADD_ITEM_TO_CART,
      variables: {
        objects: [cartData],
      },
    })

    if (isFree) {
      promise = promise.then((res: any) => {
        const cartId = res.data.insert_marketplace_user_carts.returning[0].id

        return client.mutate({
          mutation: CHECKOUT_FREE_ITEM,
          variables: {
            cart: [{id: cartId, quantity: cartData.item_quantity}],
            shipping: [],
          },
        })
      })
    }

    promise.then(
      (res: any) => {
        setDispatching(false)

        if (isFree) {
          if (onFreeAcquisition) {
            onFreeAcquisition()
          }
        } else {
          const cartId = res.data.insert_marketplace_user_carts.returning[0].id

          refetch()

          if (redirect) {
            window.open(`${VENDOR_URL}/profile/shopping-cart?s=${cartId}`)
          }
        }
      },
      () => {
        const msg = isFree
          ? `Failed to take item, please try again.`
          : `Failed to add item to shopping cart, please try again.`

        setDispatching(false)
        enqueueSnackbar(msg, {variant: 'error'})
      }
    )
  }

  return (
    <Card className="p-4">
      <div className="grid gap-2 mb-4">
        <div>
          <Typography variant="h6" className="font-bold" display="inline">
            {isFree ? 'Gratis' : convertToRupiah(price)}
          </Typography>

          {actualPrice != null && (
            <Typography
              variant="subtitle1"
              color="textSecondary"
              display="inline"
              className="ml-2"
            >
              {convertToRupiah(actualPrice)}
            </Typography>
          )}
        </div>

        {actualPrice != null && (
          <Typography color="textSecondary">
            {((100 * (actualPrice - price)) / price).toFixed(0)}% discount
          </Typography>
        )}
      </div>

      <div className="grid gap-2">
        {isFree ? (
          <Button
            disabled={dispatching}
            onClick={() => handleCartAddition(false)}
            variant="contained"
            color="primary"
          >
            Ambil {LEARNING_DIARY_ACTIVITY?.[itemType] || itemType}
          </Button>
        ) : (
          <>
            <Button
              disabled={dispatching}
              onClick={() => handleCartAddition(false)}
              variant="contained"
              color="primary"
            >
              {isInCart ? `Pergi ke Keranjang` : 'Masukkan Ke keranjang'}
            </Button>

            <Button
              disabled={dispatching}
              onClick={() => handleCartAddition(true)}
              variant="outlined"
              color="primary"
            >
              Beli Sekarang
            </Button>
          </>
        )}
      </div>
    </Card>
  )
}

export default CheckoutCard
