import {Divider, Typography} from '@material-ui/core'
import {FC, ReactElement, useEffect, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {IReviewMicro} from '../../../../interfaces'
import {
  ADD_MY_REVIEW_MICRO,
  LIST_REVIEW_MICRO_LEARNING,
  MY_REVIEW_MICRO,
  REVIEW_MICRO_LEARNING,
} from '../../../../provider/services/MicroLearning.service'
import Dompurify from 'dompurify'
import FilterRating from '../../../filter/FilterRating.component'
import DisplayRatingStar from '../../../review-rating/DisplayRatingStar.component'
import RatingSummarySection from '../../../review-rating/RatingSummarySection.component'
import ReviewList from '../../../review-rating/ReviewList.component'
import ReviewDialog from '../../catalog/dialog/AddEditReviewDialog.component'
import {useSnackbar} from 'notistack'

const TabReviewMicro: FC<IReviewMicro> = (
  props: IReviewMicro
): ReactElement => {
  const {microlearningId, isOwned, titleRefetch} = props
  const {enqueueSnackbar} = useSnackbar()
  const [pagination, setPagination] = useState<{
    limit: number
    offset: number
  }>({limit: 5, offset: 0})
  const [selectedRateFilter, setSelectedRateFilter] = useState<number | null>(0)
  const [ratingSum, setRatingSum] = useState<Array<number>>([])
  const [userRateData, setUserRateData] = useState<number>(0)
  const [reviewDialogData, setReviewDialogData] = useState({
    isOpen: false,
    rate: 0,
    description: '',
  })

  const {
    data: microLearningReviewData,
    loading: microLearningReviewLoading,
    refetch: microLearningReviewRefetch,
  } = REVIEW_MICRO_LEARNING(microlearningId || '')

  const {data: myReviewMicroData, refetch: myReviewMicroRefetch} =
    MY_REVIEW_MICRO(microlearningId || '')

  const {
    data: microListReviewData,
    loading: microListReviewLoading,
    refetch: microListReviewRefetch,
  } = LIST_REVIEW_MICRO_LEARNING({
    microlearningId: microlearningId,
    limit: pagination.limit,
    offset: pagination.offset,
    star: selectedRateFilter === 0 ? null : selectedRateFilter,
  })

  const [addMyReviewMicro] = ADD_MY_REVIEW_MICRO()

  const microReviewData = microLearningReviewData?.microlearningReviews
  const isReviewed = myReviewMicroData?.microlearning_reviews?.length > 0
  useEffect(() => {
    const countStars = microReviewData?.star_count
    const tempData = []
    for (const key in countStars) {
      const value = countStars[key]
      if (Number.isInteger(value)) {
        tempData.push(Math.round((value / microReviewData?.review_count) * 100))
      }
    }
    setRatingSum([...tempData].reverse())
  }, [microReviewData])

  if (microLearningReviewLoading && !microLearningReviewData) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }

  const dataFilterRating = {
    selectedRateFilter: selectedRateFilter,
    setSelectedRateFilter: setSelectedRateFilter,
  }

  const dataListReview = {
    microListReviewData: microListReviewData?.microlearning_reviews,
    microListReviewLoading: microListReviewLoading,
    ratingCount: microReviewData?.review_count,
    pagination: pagination,
    setPagination: setPagination,
  }

  const handleCloseDialogReview: any = () => {
    setReviewDialogData({...reviewDialogData, isOpen: false})
  }

  const handleSaveMyReview = () => {
    addMyReviewMicro({
      variables: {
        microlearningId: microlearningId,
        description: reviewDialogData.description,
        star: userRateData,
      },
    })
      .then(() => {
        enqueueSnackbar('Review successfully submitted', {
          variant: 'success',
        })
        titleRefetch()
        microLearningReviewRefetch()
        myReviewMicroRefetch()
        microListReviewRefetch()
        handleCloseDialogReview()
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)
        enqueueSnackbar('Review failed to submit, please try again later', {
          variant: 'error',
        })
      })
  }

  const handleOpenReview = () => {
    setUserRateData(myReviewMicroData?.microlearning_reviews?.[0]?.star)
    setReviewDialogData({
      ...reviewDialogData,
      rate: myReviewMicroData?.microlearning_reviews?.[0]?.star,
      description: myReviewMicroData?.microlearning_reviews?.[0]?.description,
      isOpen: true,
    })
  }

  return (
    <div>
      <Typography variant="body1" className="font-bold">
        Ulasan Pembelajaran Mikro
      </Typography>
      <Divider className="mt-4" />
      {isOwned && (
        <>
          <Typography variant="body1" className="font-bold my-5">
            Ulasan Anda
          </Typography>
          <DisplayRatingStar
            count={myReviewMicroData?.microlearning_reviews?.[0]?.star ?? 0}
            starSize="large"
          />
          <div
            className="text-sm text-gray-500 mt-3"
            dangerouslySetInnerHTML={{
              __html: Dompurify.sanitize(
                myReviewMicroData?.microlearning_reviews?.[0]?.description
              ),
            }}
          />
          <Typography
            variant="body1"
            className="text-[#039be5] mt-5 cursor-pointer"
            onClick={handleOpenReview}
          >
            {`${isReviewed ? 'Ubah' : 'Tambahkan'} Ulasan`}
          </Typography>
          <Divider className="mt-4" />
        </>
      )}
      <div className="py-4">
        <RatingSummarySection
          ratingAvg={microReviewData?.average_star ?? 0}
          ratingCount={microReviewData?.review_count}
          ratingSum={ratingSum}
        />
      </div>
      <FilterRating {...dataFilterRating} />
      <Divider className="my-4" />
      <ReviewList {...dataListReview} />

      {reviewDialogData.isOpen && (
        <ReviewDialog
          userRateData={userRateData}
          setUserRateData={setUserRateData}
          reviewData={reviewDialogData}
          setReviewData={setReviewDialogData}
          featureName="Pembelajaran Mikro"
          submiteviewAction={handleSaveMyReview}
          cancelReviewAction={handleCloseDialogReview}
        />
      )}
    </div>
  )
}

export default TabReviewMicro
