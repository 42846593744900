import React, {useContext} from 'react'
import {CustomTypography} from '../../utils-components/GlobalStyles'
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
} from 'chart.js'
import {Radar} from 'react-chartjs-2'
import {Link, useNavigate} from 'react-router-dom'
import {Button} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'
import {ICompetencyCategory} from '../../../interfaces/Competency.interface'
import NoListComponent from '../../empty-state/NoList.component'

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip)

type LearningSummaryCardProps = {
  children?: React.ReactNode
}

const LearningSummaryCard = (props: LearningSummaryCardProps) => {
  const navigate = useNavigate()
  const {dataSummary} = useContext(LearningProfileContext)

  const data = {
    labels: dataSummary?.map((cat: ICompetencyCategory) => cat.name),
    datasets: [
      {
        label: 'Kompetensi Individu',
        data: dataSummary?.map(
          (cat: ICompetencyCategory) =>
            cat.competency_dictionaries[0]?.competency_version_dictionaries[0]
              ?.competency_individuals_aggregate.aggregate.avg.level
        ),
        backgroundColor: 'rgba(135, 76, 175, 0.3)',
        borderColor: '#874caf',
        borderWidth: 1,
      },
      {
        label: 'Kompetensi Model',
        data: dataSummary?.map(
          (cat: ICompetencyCategory) =>
            cat.competency_dictionaries[0]?.competency_version_dictionaries[0]
              ?.competency_model_items_aggregate.aggregate.avg.level
        ),
        backgroundColor: 'rgba(3, 155, 229, 0.3)',
        borderColor: '#039be5',
        borderWidth: 1,
      },
    ],
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        {props.children ? (
          <Button
            component={Link}
            to="/profile"
            color="primary"
            startIcon={<ArrowBack style={{fontSize: '16px'}} />}
            style={{fontSize: '16px'}}
          >
            Ringkasan
          </Button>
        ) : (
          <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
            Ringkasan Pembelajaran
          </CustomTypography>
        )}
      </div>
      <hr />
      {!dataSummary[0] ? (
        <div className="my-4">
          <NoListComponent title="Kompetensi tidak ditemukan" />
        </div>
      ) : (
        <div className="py-2">
          <Radar
            data={data}
            height={400}
            width={400}
            options={{
              responsive: true,
              aspectRatio: 2,
              maintainAspectRatio: false,
              plugins: {
                legend: {
                  position: 'bottom',
                  labels: {
                    color: '#000',
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                  },
                },
              },
              scales: {
                r: {
                  max: 5,
                  min: 0,
                  ticks: {
                    display: false,
                    stepSize: 0.5,
                  },
                  pointLabels: {
                    color: '#014a62',
                  },
                },
              },
            }}
          />
        </div>
      )}
      {!props.children && <hr />}
      <>
        {props.children ? (
          <div>{props.children}</div>
        ) : (
          <div className="p-6 items-center flex justify-center">
            <button
              onClick={() => navigate('/profile/learning-summary')}
              className="text-xs bg-transparent text-[#039be5]"
            >
              Lihat Rincian
            </button>
          </div>
        )}
      </>
    </div>
  )
}

export default LearningSummaryCard
