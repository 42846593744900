import React from 'react'
import {useField} from 'react-final-form'

import {type FieldValidator} from 'final-form'

import {
  makeStyles,
  Typography,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormHelperText,
} from '@material-ui/core'
import EventIcon from '@material-ui/icons/Event'

import {showErrorOnBlur} from '../../../../../../components/input-forms/validators'

export interface DateTimeFieldProps {
  name: string
  validate?: FieldValidator<any>
  validateFields?: string[]
  label?: string
  showTime?: boolean
  endTime?: boolean
  onButtonClick?: () => void
}

const useStyles = makeStyles({
  input: {
    '&::-webkit-inner-spin-button, &::-webkit-calendar-picker-indicator': {
      display: 'none',
      WebkitAppearannce: 'none',
    },
  },
})

const pad = (value: number, length = 2) => {
  return ('' + value).padStart(length, '0')
}

const formatDate = (date: Date | null) => {
  if (!date) {
    return ''
  }

  return `${pad(date.getFullYear(), 4)}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`
}

const formatTime = (date: Date | null) => {
  if (!date) {
    return ''
  }

  return `${pad(date.getHours())}:${pad(date.getMinutes())}`
}

const DateTimeField = (props: DateTimeFieldProps) => {
  const {
    name,
    validate,
    validateFields,
    label,
    showTime,
    endTime,
    onButtonClick,
  } = props

  const styles = useStyles()

  const {input, meta} = useField(name, {validate, validateFields})

  const {value, onChange, onFocus, onBlur} = input
  const {error, submitError} = meta
  const isError = showErrorOnBlur(meta)

  const date = React.useMemo(() => {
    return value ? new Date(value) : null
  }, [value])

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      {label && (
        <Typography
          component="label"
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <div className="flex gap-4">
        <OutlinedInput
          type="date"
          value={formatDate(date)}
          error={isError}
          onChange={(ev) => {
            const target = ev.currentTarget
            const next = (target as any).valueAsDate as Date

            if (!next) {
              return
            }

            if (showTime && date) {
              next.setHours(
                date.getHours(),
                date.getMinutes(),
                date.getSeconds(),
                date.getMilliseconds()
              )
            } else if (endTime) {
              next.setHours(23, 59, 59, 999)
            } else {
              next.setHours(0, 0, 0, 0)
            }

            onChange(next.toISOString())
          }}
          className="text-sm"
          inputProps={{className: styles.input}}
          endAdornment={
            onButtonClick && (
              <InputAdornment position="end">
                <IconButton onClick={onButtonClick} edge="end">
                  <EventIcon />
                </IconButton>
              </InputAdornment>
            )
          }
        />

        {!showTime && (
          <OutlinedInput
            type="time"
            value={formatTime(date)}
            error={isError}
            onChange={(ev) => {
              const target = ev.currentTarget
              const next = (target as any).valueAsDate as Date

              if (!next) {
                return
              }

              // NOTE(intrnl): <input type="time"> works in UTC time, and it also
              // bases from timestamp 0, so set to current date if `date` is null
              const hours = next.getUTCHours()
              const minutes = next.getUTCMinutes()

              next.setTime(date ? date.getTime() : Date.now())

              if (endTime) {
                next.setHours(hours, minutes, 59, 999)
              } else {
                next.setHours(hours, minutes, 0, 0)
              }

              onChange(next.toISOString())
            }}
            className="text-sm"
          />
        )}
      </div>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : null}
    </div>
  )
}

export default DateTimeField
