import React, {FC} from 'react'
import {IMySocialLearning} from '../../../../interfaces'
import {TContributorSection} from '../../../../interfaces/Contributor.interface'
import {LIST_CONTRIBUTOR_SOCIAL_LEARNING} from '../../../../provider/services/LearningContributor.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCircular from '../../../loading/LoadingCircular.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'

const SocialLearning: FC<TContributorSection> = ({id, search, sort}) => {
  const {data, loading} = LIST_CONTRIBUTOR_SOCIAL_LEARNING(search, id, sort)

  if (loading) {
    return <LoadingCircular height="500px" />
  }

  return data?.classrooms.length === 0 ? (
    <div className="flex flex-col justify-center" style={{height: 500}}>
      <NoListComponent
        title="Maaf, tidak ada daftar"
        subTitle="Pembelajaran Sosial tidak ditemukan"
      />
    </div>
  ) : (
    <div className="flex flex-wrap -mx-3">
      {data?.classrooms.map((item: IMySocialLearning, i: number) => (
        <LearnerCatalogCard
          type={LearningType.SOCIAL_LEARNING}
          item={item}
          key={i}
          hidePrice
        />
      ))}
    </div>
  )
}

export default SocialLearning
