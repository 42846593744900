import React, {ChangeEvent, KeyboardEvent} from 'react'
import {
  Typography,
  Avatar,
  Icon,
  TextField,
  Menu,
  MenuItem,
} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import classNames from 'classnames'
import {
  ICourseEnrollment,
  ICourseComment,
  ICourseCommentResponse,
} from '../../../../../interfaces/Course.interface'
import {
  ADD_COURSE_COMMENT_RESPONSE,
  ADD_COMMENT_HELPFUL,
  REMOVE_COMMENT_HELPFUL,
  REMOVE_COMMENT,
} from '../../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import {formatDistance} from 'date-fns'
import {USER_ID} from '../../../../../utils/globals'

import {strMaxLength} from '../../../../../utils/helpers'
import PopupState, {bindTrigger, bindMenu} from 'material-ui-popup-state'
import TabCommentResponse from './TabCommentResponse.component'

type CommentProps = {
  data: ICourseComment
  classes: ClassNameMap
  enrollment: ICourseEnrollment
  refetchComment: () => void
}

const TabCommentIndividual = (props: CommentProps) => {
  const {data: comment, classes, enrollment, refetchComment} = props
  const [response, setResponse] = React.useState<string>('')
  const {
    mutate: onSubmitAddCommentCourse,
    isSuccess: isSuccessAddCommentCourse,
  } = ADD_COURSE_COMMENT_RESPONSE(comment.id)

  const {
    mutate: onSubmitCommentHelpful,
    isSuccess: isSuccessCommentHelpful,
    isLoading: isLoadingCommentHelpful,
  } = ADD_COMMENT_HELPFUL(comment.id)

  const {
    mutate: onSubmitRemoveCommentHelpful,
    isSuccess: isSuccessRemoveCommentHelpful,
    isLoading: isLoadingRemoveCommentHelpful,
  } = REMOVE_COMMENT_HELPFUL(comment.id)

  const {
    mutate: onSubmitRemoveComment,
    isSuccess: isSuccessRemoveComment,
    isLoading: isLoadingRemoveComment,
  } = REMOVE_COMMENT(comment.Lesson.courseId, comment.id)

  React.useEffect(() => {
    if (
      isSuccessAddCommentCourse ||
      isSuccessCommentHelpful ||
      isSuccessRemoveCommentHelpful
    ) {
      refetchComment()
      if (isSuccessAddCommentCourse) {
        setResponse('')
      }
    }
  }, [
    isSuccessAddCommentCourse,
    isSuccessCommentHelpful,
    isSuccessRemoveCommentHelpful,
    refetchComment,
  ])

  React.useEffect(() => {
    if (isSuccessRemoveComment) {
      refetchComment()
    }
  }, [isSuccessRemoveComment, refetchComment])

  if (isLoadingRemoveComment) {
    return <Skeleton height={50} width={650} />
  }
  return (
    <div>
      <div className="flex flex-row pt-10 pb-2">
        <Avatar style={{width: 64, height: 64}} src={comment.Student?.avatar} />
        <div className="ml-6 w-full flex flex-col">
          <div className="flex flex-row justify-between">
            <Typography className="font-bold">
              {comment.Student && strMaxLength(comment.Student?.name, 50)}
            </Typography>
            <div className="flex flex-col items-end">
              {comment.userId === USER_ID && (
                <PopupState variant="popover">
                  {(popupState) => (
                    <>
                      <Icon
                        {...bindTrigger(popupState)}
                        className="cursor-pointer"
                      >
                        more_horiz
                      </Icon>

                      <Menu {...bindMenu(popupState)}>
                        <MenuItem
                          onClick={() => {
                            onSubmitRemoveComment({})
                            popupState.close()
                          }}
                        >
                          Hapus Komentar
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                </PopupState>
              )}
              <Typography color="textSecondary" variant="caption">
                {formatDistance(new Date(comment.dateAdded), new Date(), {
                  addSuffix: true,
                })}
              </Typography>
            </div>
          </div>
          {comment.Student?.PeopleWorkPlacement?.length > 0 && (
            <Typography
              color="textSecondary"
              variant="caption"
              className="mb-2"
            >
              {
                comment.Student.PeopleWorkPlacement[
                  comment.Student.PeopleWorkPlacement.length - 1
                ].CompanyJobProfile?.title
              }{' '}
              at{' '}
              {
                comment.Student.PeopleWorkPlacement[
                  comment.Student.PeopleWorkPlacement.length - 1
                ].CompanyProfile?.legal_name
              }
            </Typography>
          )}

          <Typography className="text-gray-500 mb-2">
            {comment.fulltext}
          </Typography>
          <Typography variant="body2" color="secondary">
            In&nbsp;{comment.Lesson.title}
          </Typography>
          {isLoadingCommentHelpful || isLoadingRemoveCommentHelpful ? (
            <Skeleton height={50} width={650} />
          ) : (
            <>
              <div className="flex flex-row my-2">
                <Typography color="textSecondary" variant="caption">
                  Suka({comment.countHelpful || 0})
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  &nbsp;&#9679;&nbsp;
                </Typography>
                <Typography color="textSecondary" variant="caption">
                  Komentar({comment.Responses.length})
                </Typography>
              </div>

              <div
                className={`flex flex-row ${enrollment ? 'visible' : 'hidden'}`}
              >
                <Icon
                  className={classNames(
                    !comment.isMarkedHelpfulByMe
                      ? classes.greyColor
                      : classes.redColor,
                    'cursor-pointer'
                  )}
                  onClick={() =>
                    !comment.isMarkedHelpfulByMe
                      ? onSubmitCommentHelpful({
                          lessonId: comment.Lesson.id,
                          userId: USER_ID,
                        })
                      : onSubmitRemoveCommentHelpful({})
                  }
                >
                  {!comment.isMarkedHelpfulByMe
                    ? 'favorite_border'
                    : 'favorite'}
                </Icon>
                <Icon
                  className={classNames(
                    classes.greyColor,
                    'ml-4 cursor-pointer'
                  )}
                >
                  chat_bubble_outline
                </Icon>
              </div>
            </>
          )}
        </div>
      </div>
      {/* Responses */}
      {comment.Responses.length > 0 && (
        <div>
          {comment.Responses.map((response: ICourseCommentResponse) => (
            <TabCommentResponse
              key={`response-${response.id}`}
              classes={classes}
              enrollment={enrollment}
              response={response}
              refetchComment={refetchComment}
            />
          ))}
        </div>
      )}
      {props.enrollment && (
        <TextField
          placeholder="Tambahkan tanggapan anda  (enter untuk kirim)"
          multiline
          rows="2"
          className="ml-20 text-xs w-5/12"
          value={response}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setResponse(e.target.value)
          }}
          onKeyDown={(e: KeyboardEvent<{}>) => {
            if (e.key === 'Enter') {
              onSubmitAddCommentCourse({
                userId: USER_ID,
                fulltext: response,
              })
            }
          }}
        />
      )}
    </div>
  )
}

export default TabCommentIndividual
