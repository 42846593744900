import {useEffect, useState} from 'react'
import {GET_USER_CART} from '../../../../graphql/queries'
import {
  useQueryService,
  useMutationService,
} from '../../../../config/axiosQuery.service'
import {LearningType} from '../../../../utils/enum'
import {
  ICourse,
  ICourseWishlistList,
} from '../../../../interfaces/Course.interface'
import {ADD_TO_CART} from '../../../../provider/services/Course.service'
import {useSnackbar} from 'notistack'
import {useQuery} from '@apollo/client'

import LoadingCard from '../../../../components/loading/LoadingCard.component'
import LearnerCatalogCard from '../../../../components/learner/catalog/LearnerCatalogCard.component'
import NoListComponent from '../../../../components/empty-state/NoList.component'

type courseWishlistProps = {
  searchValue: any
}

const CourseWishlistComponent = (props: courseWishlistProps) => {
  const {enqueueSnackbar} = useSnackbar()
  const [list, setList] = useState<any[]>([])
  const [tempId, setTempId] = useState(0)
  const [wishlistLoading, setWishlistLoading] = useState(false)

  const {
    data: cartList,
    loading: cartLoading,
    refetch: refetchCart,
  } = useQuery(GET_USER_CART)

  const {data, isLoading, refetch} = useQueryService(
    ['courseWishlistList'],
    `academy/wishlist/get`
  )

  const {mutate: deleteFromWishlist, isSuccess: isSuccessDelete} =
    useMutationService(`academy/wishlist/${tempId}/remove`, 'DELETE')

  const [addToCart] = ADD_TO_CART()

  useEffect(() => {
    if (isSuccessDelete && refetch) refetch()
    if (refetchCart) refetchCart()
  }, [isSuccessDelete, refetch, refetchCart])

  useEffect(() => {
    if (data) {
      setList(data)
    }
    if (tempId !== 0) {
      setWishlistLoading(true)
      try {
        deleteFromWishlist({})
        enqueueSnackbar('Course deleted from wishlist', {variant: 'success'})
      } catch (error) {
        enqueueSnackbar(JSON.stringify(error), {variant: 'error'})
      }
      setTempId(0)
      setWishlistLoading(false)
    }
  }, [data, tempId, cartList, deleteFromWishlist, enqueueSnackbar])

  const removeFromWishlist = (id: number) => {
    setTempId(id)
  }

  const handleAddToCart = (item: ICourse) => {
    const variables = {
      objects: [
        {
          item_id: String(item.id),
          item_object: {
            name: item.title,
            image: item.thumbnail,
            price: item?.Price?.pricing?.IDR || 0,
            weight: 0,
            note: '',
          },
          item_table: 'academy_courses',
          item_vendor: item.CreatorUser?.name,
          item_vendor_id: item.CreatorUser?.id,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    addToCart({
      variables,
    })
      .then(() => {
        enqueueSnackbar('Berhasil!! Pembelajaran ditambahkan ke keranjang', {
          variant: 'success',
        })
        refetchCart()
      })
      .catch(() => {
        enqueueSnackbar('Gagal menambahkan ke keranjang', {variant: 'error'})
      })
  }

  if (isLoading || cartLoading) return <LoadingCard length={4} />

  const courseList = () => {
    const _list: any[] = []
    list.forEach((item: any) => {
      _list.push({
        ...item,
        Course: {
          ...item.Course,
          isInCart: cartList?.v_marketplace_user_carts.some(
            (val: any) => val.item_id === String(item.Course.id)
          ),
        },
      })
    })

    if (props.searchValue) {
      return _list.filter((item: any) =>
        item.Course.title
          .toLowerCase()
          .includes(props.searchValue.toLowerCase())
      )
    }

    return _list
  }

  return (
    <>
      {courseList().length === 0 ? (
        <NoListComponent
          title="Maaf, Tidak Ada Daftar"
          subTitle="Saat ini, Anda tidak memiliki daftar keinginan pembelajaran"
        />
      ) : (
        <div className="flex flex-wrap">
          {courseList().map((item: ICourseWishlistList, key: number) => {
            return (
              <LearnerCatalogCard
                type={LearningType.COURSE}
                item={item}
                key={key}
                hidePrice={false}
                isWishlist
                removeFromWishlist={() => removeFromWishlist(item.id)}
                addToCart={() => handleAddToCart(item.Course)}
                wishlistLoading={wishlistLoading && tempId === item.id}
              />
            )
          })}
        </div>
      )}
    </>
  )
}

export default CourseWishlistComponent
