import styled from 'styled-components'

export const HeaderStyled = styled.div`
  display: inline-block;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 3;
  background: #fff;
`
