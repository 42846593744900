import {
  Button,
  Divider,
  Paper,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core'
import {useTitle} from 'hoofd'
import React from 'react'
import {} from '../../../components/utils-components/GlobalStyles'
import {useNavigate, useParams} from 'react-router-dom'
import ConfirmationModal from '../../../components/modal/ConfirmationModal'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

const bankName = [
  {
    id: 0,
    activity: 'BNI',
  },
  {
    id: 1,
    activity: 'BCA',
  },
  {
    id: 2,
    activity: 'Mandiri',
  },
  {
    id: 3,
    activity: 'BTN',
  },
]

const ContributorAddBankAccountPage = () => {
  useTitle('Contributor - Add bank account')
  const [openDelete, setOpenDelete] = React.useState(false)
  const [openCancel, setOpenCancel] = React.useState(false)
  const {id} = useParams()

  const navigate = useNavigate()
  return (
    <Paper>
      <div className="flex h-14 items-center px-6 ">
        <Button onClick={() => setOpenCancel(true)} disableElevation>
          <ArrowBackIcon />
        </Button>
        <Typography color="primary" className="font-bold">
          {id ? 'Edit' : 'Add'} Bank Account
        </Typography>

        <div className="grow" />

        <Button
          className="mr-3"
          onClick={() => setOpenCancel(true)}
          disabled={false}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          disableElevation
          onClick={() => setOpenDelete(true)}
        >
          Save
        </Button>
      </div>
      <Divider />

      <form>
        <div className="flex flex-col pl-6 lg:pr-28 pr-4 mt-7 pb-16">
          <label className="text-sm mb-3" htmlFor="name">
            Bank Name*
          </label>
          <Select
            variant="outlined"
            id="activity"
            defaultValue="test"
            className="mb-3"
          >
            <MenuItem value="test" disabled className="invisble">
              Choose Bank Account
            </MenuItem>
            {bankName.map((item, i) => {
              return (
                <MenuItem value={item.id} key={i}>
                  {item.activity}
                </MenuItem>
              )
            })}
          </Select>
          <div></div>
          <label className="text-sm mb-3" htmlFor="name">
            Name of Account Holder*
          </label>
          <input
            type="text"
            id="name"
            placeholder="Add Name of Account Holder"
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            autoComplete="off"
          />

          <label className="text-sm mb-3 mt-6" htmlFor="activity">
            Account Number*
          </label>
          <input
            type="text"
            id="name"
            placeholder="Add Account Number"
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            autoComplete="off"
          />
        </div>
        <hr />
      </form>
      <ConfirmationModal
        open={openCancel}
        title="Discard Changes?"
        message="Are you sure want to discard unsaved changes?"
        onClose={() => setOpenCancel(false)}
        onConfirm={() => {
          navigate(-1)
        }}
      />

      <ConfirmationModal
        open={openDelete}
        title="Add Bank Account?"
        message="Are you sure want to add new bank account?"
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false)
        }}
      />
    </Paper>
  )
}

export default ContributorAddBankAccountPage
