// FIXME(intrnl): there is currently an unknown issue where editing this form
// while it is open would cause the app to crash.

import React from 'react'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'

import arrayMutators from 'final-form-arrays'

import {Button, Divider, FormHelperText, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {isRequired} from '../../../../../../../components/input-forms/validators'
import FormText from '../../../../../../../components/input-forms/FormText'

import {type LessonFormProps} from './_Lesson'
import {EditState, getInterimId} from '../_utils'
import DurationField from '../fields/DurationField'

import QuestionSubform, {QuestionType} from '../components/QuestionSubform'
import {
  formatQuestionValues,
  parseQuestionValues,
} from '../components/QuestionForm'

import useEvent from '../../../../../../../hooks/useEvent'

const validateQuestions = (value: any[]) => {
  return value.length < 1 && `This field is required`
}

const QuizLessonForm = (props: LessonFormProps) => {
  const {initialValues, editState, onSubmit, onCancel} = props

  const isAdding = editState === EditState.ADDING
  const onSubmitCallback = useEvent(onSubmit)

  const fixedInitialValues = React.useMemo(() => {
    const questions: any[] = []

    if (initialValues.academy_course_questions) {
      for (const question of initialValues.academy_course_questions) {
        const fixed = formatQuestionValues(question)

        questions.push(fixed)
      }
    }

    return {
      ...initialValues,
      academy_course_questions: questions,
    }
  }, [initialValues])

  const handleSubmit = (values: any) => {
    const questions: any[] = []

    for (const question of values.academy_course_questions) {
      const unfixed = parseQuestionValues(question)

      questions.push(unfixed)
    }

    onSubmitCallback({
      ...values,
      academy_course_questions: questions,
    })
  }

  return (
    <Form
      initialValues={fixedInitialValues}
      onSubmit={handleSubmit}
      subscription={{pristine: true}}
      mutators={{...arrayMutators}}
    >
      {({handleSubmit, pristine}) => (
        <div className="flex flex-col gap-6 p-6 text-sm">
          <Typography color="primary" className="font-bold text-sm">
            {isAdding ? 'Add' : 'Edit'} Quiz
          </Typography>

          <h3 className="font-bold">Quiz Information</h3>
          <FormText
            name="title"
            validate={isRequired}
            label="Quiz Title*"
            placeholder="Add quiz title"
          />

          <DurationField name="duration" label="Quiz Duration" />

          <FieldArray
            name="academy_course_questions"
            validate={validateQuestions}
          >
            {({fields, meta}) => (
              <div>
                <div className="flex">
                  <h3 className="font-bold grow">Question List</h3>
                </div>

                <div>
                  {fields.map((name, index) => (
                    <QuestionSubform
                      key={index}
                      prefix={`${name}.`}
                      number={index + 1}
                      inline
                      onDelete={() => fields.remove(index)}
                    />
                  ))}
                </div>

                <Divider className="mt-2 mb-6" />

                <Button
                  onClick={() => {
                    fields.push({
                      id: getInterimId(),
                      fieldtype: QuestionType.SINGLE_ANSWER,
                      choices: [],
                      answer_keys: [],
                    })
                  }}
                  variant="outlined"
                  color="secondary"
                  className="h-12 bg-white"
                  startIcon={<AddIcon />}
                >
                  Add More Question
                </Button>

                {meta.error && typeof meta.error === 'string' && (
                  <FormHelperText error>{meta.error}</FormHelperText>
                )}
              </div>
            )}
          </FieldArray>

          <div className="flex flex-row-reverse gap-4">
            <Button
              disabled={pristine}
              onClick={() => handleSubmit()}
              variant="contained"
              color="secondary"
              disableElevation
              className="h-12 w-24"
            >
              Confirm
            </Button>

            <Button
              onClick={onCancel}
              color="inherit"
              className="h-12 w-24 text-red-400"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Form>
  )
}

export default QuizLessonForm
