import React, {type ReactNode} from 'react'
import {useApolloClient} from '@apollo/client'

import {Card, Avatar, Divider, Tooltip} from '@material-ui/core'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'
import Rating from '../../../utils-components/Rating.component'

import {
  CustomFlex,
  CustomTypography,
} from '../../../utils-components/GlobalStyles'

import {USER_ID, SOSMED_APP_URL} from '../../../../utils/globals'
import {IMySocialLearning, IRecommendedTrending} from '../../../../interfaces'
import {GET_SOCIAL_LEARNING_MEMBERSHIP_STATUS} from '../../../../graphql/queries'

type SocialLearningCardProps = {
  classes: any
  item: IMySocialLearning | IRecommendedTrending
  handlePopper: () => void
  hidePrice?: boolean
  width?: string
}

// User doesn't match the criteria below
const PENDING_NONE = 0
// User joined the group, but the group is waiting for contributors to join
const PENDING_CONTRIBUTOR = 1
// User is waiting for approval to join
const PENDING_MEMBER = 2

// NOTE(intrnl): Recommended and Trending list uses getListClassroom GQL
// endpoint which returns `ClassroomType` instead of `classrooms` type and so
// we can't easily get pending membership status in one go.

// at the moment we show 4 recommended and 4 trending learning groups at a time
// but that could change at any moment, so rather than trying to make one
// request for each group, we'll try to batch it to the best we can.

// TODO(intrnl): based off the following gist code, move this as a reusable
// library if it turns out other things needs to be batched like this.
// https://gist.github.com/intrnl/115a04e6a5ad73a6ccd7d4fc50c220b9

const BATCH_DELAY = 100
let BATCH_MAP: Map<string, Deferred<number[]>> = new Map()
let BATCH_TIMEOUT: any

interface Deferred<T = unknown> {
  promise: Promise<T>
  resolve: (value: T) => void
  reject: (reason?: any) => void
}

const createDeferred = <T,>(): Deferred<T> => {
  const deferred: any = {}

  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  return deferred
}

const renderStatus = (
  status: number,
  isJoined: boolean,
  isFinished: boolean
) => {
  if (status === PENDING_CONTRIBUTOR) {
    return (
      <CustomTypography fcolor="#ce8000">
        Waiting for Learning Contributor Confirmation
      </CustomTypography>
    )
  } else if (status === PENDING_MEMBER) {
    return (
      <CustomTypography fcolor="#ce8000">
        Waiting for Confirmation
      </CustomTypography>
    )
  }

  if (isJoined) {
    if (isFinished) {
      return <CustomTypography fcolor="#78C37B">Session End</CustomTypography>
    } else {
      return <CustomTypography fcolor="#039be5">On Progress</CustomTypography>
    }
  }

  return null
}

const SocialLearningPendingStatus = (props: {
  item: SocialLearningCardProps['item']
  isJoined: boolean
  isFinished: boolean
}) => {
  const {item, isJoined, isFinished} = props

  const client = useApolloClient()
  const [status, setStatus] = React.useState(PENDING_NONE)

  React.useEffect(() => {
    const performBatchQuery = async (map: typeof BATCH_MAP) => {
      const keys = Array.from(map.keys())

      try {
        const response = await client.query({
          query: GET_SOCIAL_LEARNING_MEMBERSHIP_STATUS,
          variables: {
            userId: USER_ID,
            classroomIds: keys,
          },
        })

        const responseMap = new Map<Deferred<any>, number[]>()

        // We're doing the same thing with both sets of data, so we'll just
        // turn the actual iteration into a curry function
        const add = (status: number) => (membership: any) => {
          const key = membership.classroom_id
          const deferred = map.get(key)

          if (!deferred) {
            return
          }

          let array = responseMap.get(deferred)

          if (!array) {
            responseMap.set(deferred, (array = []))
          }

          array!.push(status)
        }

        const addContributor = add(PENDING_CONTRIBUTOR)
        const addMember = add(PENDING_MEMBER)

        response.data.classroom_contrib_requests.forEach(addContributor)
        response.data.classroom_member_requests.forEach(addMember)

        for (const deferred of Array.from(map.values())) {
          const array = responseMap.get(deferred)
          deferred.resolve(array || [PENDING_NONE])
        }
      } catch (error) {
        for (const deferred of Array.from(map.values())) {
          deferred.reject(error)
        }
      }
    }

    let deferred = BATCH_MAP.get(item.id)
    let cancelled = false

    if (!deferred) {
      deferred = createDeferred<number[]>()

      BATCH_MAP.set(item.id, deferred)
      clearTimeout(BATCH_TIMEOUT)

      BATCH_TIMEOUT = setTimeout(() => {
        const map = BATCH_MAP
        BATCH_MAP = new Map()

        performBatchQuery(map)
      }, BATCH_DELAY)
    }

    deferred.promise.then((next) => {
      if (cancelled) {
        return
      }

      if (next.includes(PENDING_CONTRIBUTOR)) {
        setStatus(PENDING_CONTRIBUTOR)
      } else if (!isJoined && next.includes(PENDING_MEMBER)) {
        setStatus(PENDING_MEMBER)
      } else {
        setStatus(PENDING_NONE)
      }
    })

    return () => {
      cancelled = true
    }
  }, [client, item, isJoined])

  return renderStatus(status, isJoined, isFinished)
}

const SocialLearningCard = ({
  classes,
  item,
  width = 'w-1/4',
}: SocialLearningCardProps): React.ReactElement => {
  const isAlternate = 'owner' in item
  const isFinished = item.is_finished

  const isJoined = React.useMemo(() => {
    if (isAlternate) {
      return (
        item.owner.id === USER_ID ||
        item.members.some((x) => x.user.id === USER_ID)
      )
    }

    return (
      item.global_user.id === USER_ID ||
      item.learning_contributors.some((x) => x.global_user.id === USER_ID) ||
      item.members.some((x) => x.user.id === USER_ID)
    )
  }, [item, isAlternate])

  const pendingStatus = React.useMemo(() => {
    if (isAlternate) {
      return PENDING_NONE
    }

    if (
      'contributor_requests' in item &&
      item.contributor_requests!.length > 0
    ) {
      return PENDING_CONTRIBUTOR
    }

    if (
      !isJoined &&
      'member_requests' in item &&
      item.member_requests!.length > 0
    ) {
      return PENDING_MEMBER
    }

    return PENDING_NONE
  }, [item, isAlternate, isJoined])

  const contributor = React.useMemo(() => {
    if (isAlternate) {
      for (const member of item.members) {
        if (member.is_mentor) {
          return member.user
        }
      }

      return item.owner
    }

    return item.learning_contributors.length > 0
      ? item.learning_contributors[0].global_user
      : item.global_user
  }, [item, isAlternate])

  const participants = React.useMemo(() => {
    const array: ReactNode[] = []
    const limit = item.members.length > 7 ? 5 : 7
    let count = 0

    for (const member of item.members) {
      // NOTE(intrnl): we can't filter the `members` from the `getListClassroom`
      // query ahead of time, we need to manually filter for them here.

      // @ts-expect-error `isAlternate` specifies the correct type for `item`
      // but this for-loop still sees both, the error on `member` is expected.
      if (isAlternate && (member.is_admin || member.is_mentor)) {
        continue
      }

      if (count++ >= limit) {
        break
      }

      array.push(
        <Tooltip key={member.id} title={member.user.name}>
          <Avatar src={member.user.avatar} className="h-6 w-6 mr-1" />
        </Tooltip>
      )
    }

    if (limit === 5) {
      array.push(
        <Avatar key={null} className="h-6 w-6 mr-1">
          <CustomTypography fcolor="#fff" fsize="10px">
            {item.members.length - limit}+
          </CustomTypography>
        </Avatar>
      )
    }

    return array
  }, [item, isAlternate])

  return (
    <div className={`w-full md:w-1/2 lg:${width} pb-1`}>
      <Card
        className={classes.root}
        onClick={() =>
          window.open(
            `${SOSMED_APP_URL}/social-learning/${item.id}/discussion`,
            '_blank'
          )
        }
      >
        <CustomFlex
          direction="column"
          justify="space-between"
          className={classes.socialLearningCardContent}
        >
          <CustomFlex direction="column" className="items-stretch">
            <CustomFlex>
              <Avatar src={item.avatar_url} className="mr-2" />

              <CustomFlex direction="column">
                <LinesEllipsis
                  text={item.name}
                  maxLine={2}
                  className="font-bold text-sm break-words overflow-hidden"
                />

                <LinesEllipsis
                  text={contributor.name}
                  maxLine={1}
                  className="text-gray-500 text-xs overflow-hidden"
                />

                <CustomTypography color="textSecondary">
                  Pembelajaran Sosial{' '}
                  {item.privacy === 0 ? 'Terbuka' : 'Privat'}
                </CustomTypography>
                {isAlternate ? (
                  <SocialLearningPendingStatus
                    item={item}
                    isJoined={isJoined}
                    isFinished={isFinished}
                  />
                ) : (
                  renderStatus(pendingStatus, isJoined, isFinished)
                )}
              </CustomFlex>
            </CustomFlex>

            <Divider className="my-2" />

            <LinesEllipsis
              text={item.description}
              maxLine={5}
              className="text-gray-500 text-xs overflow-hidden"
            />
          </CustomFlex>
          <CustomFlex direction="column" className="gap-1.5 items-stretch">
            {participants.length > 0 && (
              <div className="gap-2">
                <CustomTypography fweight="500" fsize="11px">
                  Peserta
                </CustomTypography>

                <CustomFlex>{participants}</CustomFlex>
              </div>
            )}

            <Divider className="my-1" />

            <Rating
              averageRating={
                isAlternate
                  ? item.average_review
                  : item.classroom_reviews_aggregate.aggregate.avg.star
              }
              totalEnrollment={
                isAlternate
                  ? item.review_count
                  : item.classroom_reviews_aggregate.aggregate.count
              }
              isSmallRating
            />
          </CustomFlex>
        </CustomFlex>
      </Card>
    </div>
  )
}

export default SocialLearningCard
