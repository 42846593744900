import {
  Icon,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/core/styles/withStyles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  ChangeEvent,
  FC,
  // useEffect,
  useState,
} from 'react'
import {
  NavLink,
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {sortOptions} from '../../../utils/constans'
import {
  FlatAccordion,
  FlatAccordionDetails,
  FlatAccordionSummary,
  GreyTypography,
} from '../../utils-components/GlobalStyles'

type BodyProps = {
  classes: ClassNameMap
}

type State = {
  search: string
  sort: string
}

const _link = [
  {
    name: 'Kursus',
    to: 'course',
  },
  {
    name: 'Buku',
    to: 'book',
  },
  {
    name: 'Acara',
    to: 'event',
  },
  {
    name: 'Pembelajaran Sosial',
    to: 'social-learning',
  },
  {
    name: 'Pembelajaran Mikro',
    to: 'micro-learning',
  },
]

const Body: FC<BodyProps> = ({classes}) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const [searchParams, setSearchParams] = useSearchParams()
  const searchValue = searchParams.get('search') ?? ''
  const sortValue = searchParams.get('sort') ?? 'latest'
  const handleExpand = () => setExpanded(!expanded)
  const navigate = useNavigate()
  const {active} = useParams()

  const activeTab = _link.filter(({to}) => to === active)?.[0]?.name || ''

  const activeStyle = (props: {isActive: boolean}) => {
    const {isActive} = props
    return isActive
      ? {
          color: '#014a62',
          background: '#f7f8f9',
          borderLeft: '4px solid #039be5',
          paddingLeft: 42,
        }
      : {}
  }

  const handleChange =
    (name: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      searchParams.set(name, event.target.value)
      setSearchParams(searchParams)
    }

  return (
    <div className="flex flex-col md:flex-row">
      <Paper className="w-full md:w-1/4 md:mr-8" style={{maxHeight: 338}}>
        <GreyTypography
          className="cursor-pointer py-4 px-6"
          variant="body2"
          // onClick={() => navigate('/learner/learning_contributor')}
          onClick={() => navigate(-1)}
        >
          Kembali
        </GreyTypography>
        <Divider />
        <FlatAccordion
          expanded={expanded}
          onChange={handleExpand}
          className="p-2 pb-4"
        >
          <FlatAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              className="font-semibold"
              color="primary"
              variant="body2"
            >
              Kontributor Pembelajaran
            </Typography>
          </FlatAccordionSummary>
          <FlatAccordionDetails>
            {_link.map((link, idx) => (
              <NavLink
                to={link.to}
                key={idx}
                className={classes.navLink}
                style={activeStyle}
              >
                {link.name}
              </NavLink>
            ))}
          </FlatAccordionDetails>
        </FlatAccordion>
      </Paper>
      <div className="w-full" style={{minHeight: 700}}>
        <div className="flex justify-between items-center mb-6 mt-8 md:mt-0">
          <TextField
            select
            value={sortValue}
            onChange={handleChange('sort')}
            SelectProps={{
              classes: {
                icon: 'text-black',
                root: 'font-bold',
              },
              disableUnderline: true,
            }}
          >
            {sortOptions.map((_sort, i) => {
              return (
                <MenuItem key={i} value={_sort.value}>
                  {_sort.label}
                </MenuItem>
              )
            })}
          </TextField>
          <TextField
            className="bg-white  w-1/2 md:w-2/5"
            placeholder={`Cari ${activeTab}...`}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon color="primary">search</Icon>
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={handleChange('search')}
            variant="outlined"
          />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

export default Body
