import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Form} from 'react-final-form'
import {FieldArray} from 'react-final-form-arrays'
import {useApolloClient, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import arrayMutators from 'final-form-arrays'

import {
  Button,
  Divider,
  IconButton,
  Link,
  Paper,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import CloseIcon from '@material-ui/icons/Close'

import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'
import FormLoading from '../../../../../components/input-forms/FormLoading'
import FormText from '../../../../../components/input-forms/FormText'

import {USER_ID} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_COURSE_TARGET_DETAILS} from '../../../../../graphql/queries'
import {UPDATE_COURSE_TARGET_DETAILS} from '../../../../../graphql/mutations'

interface MultiAnswerFieldProps {
  label: string
  name: string
}

const MultiAnswerField = (props: MultiAnswerFieldProps) => {
  const {label, name} = props

  return (
    <div>
      <p className="mb-4 text-sm font-semibold">{label}</p>

      <FieldArray name={name}>
        {({fields}) => (
          <div>
            {fields.map((answer, index) => (
              <div key={answer} className="mb-4 flex items-center gap-6">
                <FormText
                  name={answer}
                  placeholder="Add answer"
                  formControlProps={{
                    className: 'grow',
                  }}
                />

                <IconButton
                  onClick={() => fields.remove(index)}
                  title="Remove answer"
                  size="small"
                >
                  <CloseIcon />
                </IconButton>
              </div>
            ))}

            <Link
              component="button"
              type="button"
              onClick={() => fields.push('')}
              color="secondary"
              className="-mt-2 text-sm"
            >
              + Add an answer
            </Link>
          </div>
        )}
      </FieldArray>
    </div>
  )
}

interface FormData {
  id: number
  goals: string[]
  requirements: string[]
  target_audience: string[]
}

const ContributorCourseManageTargetsPage = () => {
  const {courseSlug} = useParams()

  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const {data, loading} = useQuery(GET_CONTRIBUTOR_COURSE_TARGET_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      slug: courseSlug,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const initialValues = React.useMemo(() => {
    if (!data || data.academy_courses.length < 1) {
      return
    }

    const course = data.academy_courses[0]

    const formData: FormData = {
      id: course.id,
      goals: course.goals || [],
      requirements: course.requirements || [],
      target_audience: course.target_audience || [],
    }

    return formData
  }, [data])

  const handleSubmit = (data: FormData) => {
    const normalizeArray = (array: string[]) => {
      return array
        .map((str) => (str ? str.trim() : ''))
        .filter((str) => str.length > 0)
    }

    const promise = client.mutate({
      mutation: UPDATE_COURSE_TARGET_DETAILS,
      variables: {
        courseId: data.id,
        objects: {
          goals: normalizeArray(data.goals),
          requirements: normalizeArray(data.requirements),
          target_audience: normalizeArray(data.target_audience),
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    return promise.catch(() => {
      enqueueSnackbar(`Failed to update course targets`)
    })
  }

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      mutators={{...arrayMutators}}
      initialValues={initialValues}
    >
      {({handleSubmit, pristine, submitting}) => (
        <Paper component="form" onSubmit={handleSubmit}>
          <div className="flex items-center gap-2 px-6 py-1">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              Target your students
            </Typography>

            <Button
              disabled={pristine || submitting}
              type="submit"
              color="primary"
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col gap-4 p-6">
            {(submitting || loading) && <FormLoading />}

            <MultiAnswerField
              name="goals"
              label="What will students learn in your course?"
            />

            <MultiAnswerField
              name="requirements"
              label="Are there any course requirements or prerequisites?"
            />

            <MultiAnswerField
              name="target_audience"
              label="Who are your target students?"
            />
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorCourseManageTargetsPage
