import {Tab, Tabs} from '@material-ui/core'
import {ChangeEvent, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import DEFAULT_EVENT_IMG from '../../../assets/images/defaultEventCover.image.png'
import {
  CONTRIBUTOR_EVENT_DETAILS,
  EVENT_BY_ID,
} from '../../../provider/services/Event.service'
import LoadingCircular from '../../loading/LoadingCircular.component'
import ContributorCard from '../catalog/card/ContributorCard.component'
import TabAboutEvent from './event-tabs/TabAboutEvent.component'
import useStyles, {LoadingBox} from './Event.style'
import EventTitle from './EventTitle.component'
import UpcomingEvent from './UpcomingEvent.component'

type EventDetailProps = {
  isPreview?: boolean
}

const EventDetail = (props: EventDetailProps) => {
  const {isPreview} = props
  const classes = useStyles()
  const {id, eventId} = useParams()
  const [tab, setTab] = useState(0)
  const [authorId, setAuthorId] = useState(null)
  const [skipFetchingContributorEvent, setSkipFetchingContributorEvent] =
    useState(true)

  const {data: eventDetailData, error: eventDetailError} = EVENT_BY_ID(
    id || eventId || ''
  )

  const {data: contributorData, loading: contributorLoading} =
    CONTRIBUTOR_EVENT_DETAILS(authorId || '', skipFetchingContributorEvent)

  const eventData = eventDetailData?.event_schedules_by_pk

  useEffect(() => {
    if (eventData) {
      setAuthorId(eventData?.contributor?.id)
      setSkipFetchingContributorEvent(false)
    }
  }, [eventData])

  if (eventDetailData && eventDetailError) {
    return (
      <LoadingBox>
        <LoadingCircular />
      </LoadingBox>
    )
  }

  const dataEventTitle = {
    title: eventData?.title ?? '-',
    autor: eventData?.author?.name ?? '-',
  }

  const dataEventAbout = {
    type: eventData?.event_type?.name ?? '-',
    category: eventData?.event_category?.name ?? '-',
    startDate: eventData?.datestart,
    endDate: eventData?.dateend,
    location: eventData?.location_info?.formatted_address ?? '-',
    description: eventData?.description ?? '-',
  }

  const dataUpcommingEvent = {
    creator: eventData?.author?.id || '',
  }

  const changeTab = (_event: ChangeEvent<{}>, newActiveTab: number) => {
    setTab(newActiveTab)
  }

  return (
    <div id="event-detail">
      <img
        className={classes.imgDetailEvent}
        src={eventData?.thumbnail ?? DEFAULT_EVENT_IMG}
        alt={eventData?.title}
      />
      <div
        id="event-detail-wrap"
        className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between"
      >
        <div id="left-side" className="basis-2/3">
          <EventTitle isPreview={isPreview} {...dataEventTitle} />
          <div id="tab-event" className="mt-10">
            <Tabs
              value={tab}
              onChange={changeTab}
              indicatorColor="secondary"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  height: 5,
                },
              }}
            >
              <Tab label="Tentang" />
            </Tabs>

            <div className="mt-8">
              {tab === 0 && <TabAboutEvent {...dataEventAbout} />}
            </div>
          </div>
        </div>
        <div id="right-side" className="basis-1/4 mt-8">
          {!contributorLoading && contributorData && (
            <ContributorCard
              item={contributorData?.learning_contributor_ratings_by_pk}
              isLearningCatalogChat={true}
            />
          )}
        </div>
      </div>
      {!isPreview && <UpcomingEvent {...dataUpcommingEvent} />}
    </div>
  )
}

export default EventDetail
