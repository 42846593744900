import React, {useEffect, Dispatch, SetStateAction, ChangeEvent} from 'react'
import {Paper, Typography, Divider, makeStyles} from '@material-ui/core'
import {useTitle} from 'hoofd'
import ExpansionFilter from './ExpansionFilter.component'
import {useQuery} from '@apollo/react-hooks'
import {
  GET_COURSE_CATEGORIES,
  GET_EVENT_CATEGORIES,
} from '../../../graphql/queries'
import {
  levelOptions,
  priceOptions,
  ratingOptions,
  // languageOptions,
  languageOptionsGQL,
  contributorTypeOptions,
} from '../../../utils/constans'
import {LearningType} from '../../../utils/enum'

import {ICategoryGQL} from '../../../interfaces/Academic.interface'
import {
  ISearchFilter,
  IAcademicFilter,
} from '../../../interfaces/Filter.interface'

type SearchFilterSiderbarProps = {
  activeTab?: LearningType
  academicFilter: IAcademicFilter
  setAcademicFilter: Dispatch<SetStateAction<IAcademicFilter>>
}

type CategoriesType = {
  label: string
  value: number
  options: ICategoryGQL[]
}

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingTop: 10,
    border: 'none',
  },
  paper: {
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16);',
  },
}))

const SearchFilterSidebar = (props: SearchFilterSiderbarProps) => {
  useTitle('Saring & Cari')
  const classes = useStyles()

  const isCourse =
    location.pathname.includes('/course') || props.activeTab === 0
  const isBook = location.pathname.includes('/book')
  const isEvent = location.pathname.includes('/event')
  const isMicrolearning = location.pathname.includes('/micro-learning')
  const isSocialLearning = location.pathname.includes('/social-learning')
  const isContributor = location.pathname.includes('/learning-contributor')

  const [expanded, setExpanded] = React.useState({
    category: true,
    topic: true,
    level: true,
    language: true,
    price: true,
    ratings: true,
    duration: true,
    type: true,
    contributorType: true,
  })
  const [subExpanded, setSubExpanded] = React.useState({})
  const [moreOptions, setMoreOptions] = React.useState({
    category: {isHidden: false, maxLength: 4},
    topic: {isHidden: false, maxLength: 4},
  })
  const [categories, setCategories] = React.useState<CategoriesType[]>([])

  const {data: globalCategories} = useQuery(GET_COURSE_CATEGORIES, {
    skip: isEvent,
  })
  const {data: eventCategories} = useQuery(GET_EVENT_CATEGORIES, {
    skip: !isEvent,
  })

  const [filterBy, setFilterBy] = React.useState<ISearchFilter>({
    category: [],
    level: [],
    language: [],
    price: '',
    priceMin: '',
    priceMax: '',
    ratings: '',
    duration: [],
    priceGQL: '',
    categoryIdsGQL: [],
    ratingsGQL: [],
    type: [],
  })

  useEffect(() => {
    if (globalCategories && !isEvent) {
      const _categories: ICategoryGQL[] =
        globalCategories.academy_course_categories.filter(
          (category: ICategoryGQL) => {
            return !category.parent
          }
        )

      _categories.forEach((category: ICategoryGQL) => {
        setCategories((cat) => [
          ...cat,
          {
            label: category.name,
            value: category.id,
            options: category.academy_course_categories,
          },
        ])
      })
    }
    if (eventCategories && isEvent) {
      setCategories([
        {
          value: 0,
          label: 'Events',
          options: eventCategories.event_categories,
        },
      ])
    }
  }, [globalCategories, eventCategories, isEvent])

  useEffect(() => {
    if (
      filterBy.ratings ||
      filterBy.category ||
      filterBy.level ||
      filterBy.language ||
      filterBy.priceGQL ||
      filterBy.categoryIdsGQL ||
      filterBy.ratingsGQL ||
      filterBy.type
    ) {
      const isGQL =
        isMicrolearning || isSocialLearning || isContributor || isCourse

      // TODO: CATEGORIES FILTER
      let categories: number[] | string = isGQL ? [] : ''

      if (filterBy.category.length > 0) {
        filterBy.category.forEach((category: string, index: number) => {
          if (isGQL && Array.isArray(categories)) {
            return categories.push(parseInt(category))
          } else {
            if (isEvent) {
              return categories === ''
                ? (categories = category)
                : (categories += `,${category}`)
            } else {
              return (categories += `category[${index}]=${category}&`)
            }
          }
        })
      }

      // TODO: LEVELS FILTER
      let levels: string = ''
      const levelsGQL: number[] = []
      if (filterBy.level.length > 0) {
        filterBy.level.forEach((level: string, index: number) => {
          if (isGQL) {
            return levelsGQL.push(Number(level))
          }
          return (levels += `level[${index}]=${level}&`)
        })
      }

      // TODO: LANGUAGES FILTER
      let languages: string = ''
      const languagesGQL: number[] = []
      if (filterBy.language.length > 0) {
        filterBy.language.forEach((language: string, index: number) => {
          if (isGQL) {
            return languagesGQL.push(Number(language))
          }
          return (languages += `language[${index}]=${language}&`)
        })
      }

      props.setAcademicFilter({
        ...props.academicFilter,
        rating: filterBy.ratings,
        categoriesREST: String(categories),
        categoriesGQL: categories,
        levelsREST: levels,
        languagesREST: languages,
        priceMin: filterBy.priceMin,
        priceMax: filterBy.priceMax,
        levelsGQL,
        languagesGQL,
        contributorLabels: filterBy.type,
        price: filterBy.price,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterBy.ratings,
    filterBy.category,
    filterBy.level,
    filterBy.language,
    filterBy.priceMin,
    filterBy.priceMax,
    filterBy.price,
    filterBy.type,
  ])

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = e.target

    if (name !== 'priceMin' && name !== 'priceMax' && name !== 'ratings') {
      if (name === 'price') {
        setFilterBy((prevState: ISearchFilter) => ({
          ...prevState,
          price: value,
          priceMin: '',
          priceMax: '',
        }))
      } else {
        const _filterBy: {[key: string]: any} = filterBy

        let filters = [..._filterBy[name]]
        const removedFilters = filters.filter((item) => item !== value)

        if (filters.includes(value)) {
          filters = removedFilters
        } else {
          filters.push(value)
        }
        setFilterBy((prevState: ISearchFilter) => ({
          ...prevState,
          [name]: filters,
        }))
      }
    } else {
      setFilterBy((prevState: ISearchFilter) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  const handlePanel = (panel: string) => {
    // @ts-ignore
    setExpanded((prevState) => ({...prevState, [panel]: !expanded[panel]}))
  }

  const handleSubPanel = (subPanel: string) => {
    setSubExpanded((prevState) => ({
      ...prevState,
      // @ts-ignore
      [subPanel]: !expanded[subPanel],
    }))
  }
  const handleSeeMore = (
    e: ChangeEvent<HTMLInputElement>,
    panel: string,
    maxLength: number
  ) => {
    e.preventDefault()
    // @ts-ignore
    setMoreOptions((prevState) => ({
      ...prevState,
      [panel]: {isHidden: true, maxLength},
    }))
  }
  return (
    <>
      <Typography className="font-extrabold">Saring</Typography>

      <Paper
        className="py-4 mt-5"
        classes={{
          root: classes.paper,
        }}
      >
        {isContributor ? (
          <>
            <ExpansionFilter
              expanded={expanded.contributorType}
              onChangePanel={() => handlePanel('contributorType')}
              summary="Type"
              label="Tipe"
              details={contributorTypeOptions}
              onChangeCheckbox={handleOnChange}
            />
            <Divider />
          </>
        ) : (
          <>
            {categories.length > 0 && (
              <ExpansionFilter
                expanded={expanded.category}
                subExpanded={subExpanded}
                onChangePanel={() => handlePanel('category')}
                onChangeSubPanel={handleSubPanel}
                summary="Category"
                label="Kategori"
                details={categories}
                onChangeCheckbox={handleOnChange}
                moreOptions={{
                  onClick: (e: ChangeEvent<HTMLInputElement>) =>
                    handleSeeMore(e, 'category', 0),
                  isHidden: moreOptions.category.isHidden,
                  maxLength: moreOptions.category.maxLength,
                }}
                hasSubCategories={true}
              />
            )}
            <Divider />
          </>
        )}
        {!isSocialLearning && !isContributor && !isBook && !isEvent && (
          <>
            <>
              <ExpansionFilter
                expanded={expanded.level}
                onChangePanel={() => handlePanel('level')}
                summary="Level"
                label="Tingkatan"
                details={levelOptions}
                onChangeCheckbox={handleOnChange}
              />
              <Divider />
            </>
            <>
              <ExpansionFilter
                expanded={expanded.language}
                onChangePanel={() => handlePanel('language')}
                summary="Language"
                label="Bahasa"
                details={languageOptionsGQL}
                onChangeCheckbox={handleOnChange}
              />
              <Divider />
            </>
          </>
        )}
        {!isContributor && !isSocialLearning && (
          <>
            <ExpansionFilter
              expanded={expanded.price}
              onChangePanel={() => handlePanel('price')}
              summary="Price"
              label="Harga"
              details={priceOptions}
              onChangeRadio={handleOnChange}
              rangeInputNumber={{
                isOpen: true,
                onChangeNumberMin: handleOnChange,
                onChangeNumberMax: handleOnChange,
              }}
              value={{
                ratings: String(filterBy.price),
                price: {
                  priceMin: filterBy.priceMin,
                  priceMax: filterBy.priceMax,
                },
              }}
            />
            {!isEvent && <Divider />}
          </>
        )}

        {!isEvent && (
          <ExpansionFilter
            expanded={expanded.ratings}
            onChangePanel={() => handlePanel('ratings')}
            summary="Ratings"
            label="Penilaian"
            isRating
            details={ratingOptions}
            onChangeRadio={handleOnChange}
            value={{ratings: String(filterBy.ratings)}}
          />
        )}
      </Paper>
    </>
  )
}

export default SearchFilterSidebar
