import {gql} from '@apollo/react-hooks'

export const ADD_BOOK_FROM_WISHLIST = gql`
  mutation AddBookFromWishlist($isbn: String!) {
    insert_academy_book_wishlists_one(object: {isbn: $isbn}) {
      id
    }
  }
`

export const DELETE_BOOK_FROM_WISHLIST = gql`
  mutation DeleteBookFromWishlist($userId: uuid, $id: bigint, $isbn: String) {
    update_academy_book_wishlists(
      where: {user_id: {_eq: $userId}, id: {_eq: $id}, isbn: {_eq: $isbn}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        id
      }
    }
  }
`
