import {gql} from '@apollo/react-hooks'

export const GET_BOOK_WISHLISTS = gql`
  query GetBookWishlist(
    $type: Int
    $isbns: [String!]
    $limit: Int
    $offset: Int
    $query: String
  ) {
    wishlist: academy_book_wishlists(
      offset: $offset
      limit: $limit
      where: {
        academy_book: {
          isbn: {_in: $isbns}
          title: {_ilike: $query}
          book_type: {_eq: $type}
        }
        deleted_at: {_is_null: true}
      }
    ) {
      id
      isbn
      academy_book {
        id
        title
        thumbnail
        author_written
        pricing_idr
        average_rating
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`
