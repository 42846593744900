import React, { ChangeEvent, KeyboardEvent } from 'react'
import {
  Typography,
  Divider,
  Avatar,
  makeStyles,
  createStyles,
  TextField,
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core'
import {
  ICourse,
  ICourseComment,
  ISection,
  ILesson,
} from '../../../../../interfaces/Course.interface'
import {
  COURSE_COMMENT,
  ADD_COURSE_COMMENT,
} from '../../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import { AVATAR } from '../../../../../utils/globals'
import NoList from '../../../../../components/empty-state/NoList.component'
import { useSnackbar } from 'notistack'
import TabCommentIndividual from './TabCommentIndividual.component'
import { Filter } from '@smartasn/wlb-utils-components'
import { IFilter } from '../../../../../interfaces'

type FiltersProps = {
  name: string
  fieldName: string
  type: string
  options: {
    list: LessonFilterProps[]
  }
}

type LessonFilterProps = {
  value: number
  label: string
}

type LessonFilterCommentProps = {
  value: number
}

const useStyles = makeStyles(() =>
  createStyles({
    selectOption: {
      fontWeight: 'bold',
    },
    skyBlueColor: {
      color: '#039be5',
    },
    greyColor: {
      color: '#DCDBDB',
    },
    redColor: {
      color: `#c0392b`,
    },
  })
)

const TabComment = (props: ICourse) => {
  const [lessons, setLessons] = React.useState<ILesson[]>([])
  const [filters, setFilters] = React.useState<FiltersProps[]>([
    {
      name: 'Pembelajaran',
      fieldName: 'lesson',
      type: 'checkbox',
      options: {
        list: [],
      },
    },
  ])

  const [selectedLesson, setSelectedLesson] = React.useState<number>(0)
  const [commentText, setCommentText] = React.useState<string>('')
  const [commentFilter, setFilterComment] = React.useState<string>('Latest')
  const [lessonIdsFilter, setLessonIdsFilter] = React.useState<number[]>([])
  const { enqueueSnackbar } = useSnackbar()

  const handleChangeFilter = (event: ChangeEvent<{ value: any }>) => {
    setFilterComment(event.target.value)
  }

  const handleApply = (result: IFilter) => {
    const _lessonIdsFilter: number[] = []
    if (result[0]?.lesson) {
      result[0]?.lesson.forEach((lesson: LessonFilterCommentProps) => {
        return _lessonIdsFilter.push(lesson.value)
      })
    }

    setLessonIdsFilter(_lessonIdsFilter)
  }

  const { mutate: onSubmitAddComment, isSuccess: isSuccessAddComment } =
    ADD_COURSE_COMMENT()

  const classes = useStyles()

  const { data, isLoading, refetch } = COURSE_COMMENT(props.id)

  const Comments = () => {
    return data.data
      .sort((a: ICourseComment, b: ICourseComment) => {
        return commentFilter === 'Latest'
          ? Date.parse(b.dateAdded) - Date.parse(a.dateAdded)
          : Date.parse(a.dateAdded) - Date.parse(b.dateAdded)
      })
      .filter((comment: ICourseComment) => {
        if (lessonIdsFilter.length > 0) {
          return lessonIdsFilter.includes(comment.Lesson.id)
        }

        return comment
      })
      .map((comment: ICourseComment, index: number) => (
        <TabCommentIndividual
          key={`comment-${index}`}
          data={comment}
          classes={classes}
          enrollment={props.Enrollment}
          refetchComment={refetch}
        />
      ))
  }

  React.useEffect(() => {
    if (props.Sections && filters[0].options.list.length === 0) {
      const _lessons: ILesson[] = props.Sections.flatMap(
        (section: ISection) => section.Lessons
      )
      setLessons(_lessons)

      const _lessonsFilter: LessonFilterProps[] = []

      _lessons.forEach((lesson: ILesson) => {
        return _lessonsFilter.push({
          value: lesson.id,
          label: lesson.title,
        })
      })

      setFilters([{ ...filters[0], options: { list: _lessonsFilter } }])
    }
  }, [props.Sections, filters])

  React.useEffect(() => {
    if (isSuccessAddComment) {
      enqueueSnackbar('Comment added.')
      refetch()
      setCommentText('')
    }
  }, [isSuccessAddComment, refetch, enqueueSnackbar])

  if (isLoading) {
    return <Skeleton height={300} className="mx-4 my-2" width={'50vw'} />
  }

  return (
    <div className="flex flex-col w-10/12">
      <Typography variant="body1" className={`mt-8 mb-2 font-bold`}>
        Komentar Pembelajaran
      </Typography>
      <Divider />
      <div
        className={`flex flex-row items-center justify-between my-6 ${data.data.length === 0 ? 'hidden' : 'visible'
          }`}
      >
        <FormControl>
          <Select
            value={commentFilter}
            onChange={handleChangeFilter}
            disableUnderline={true}
            className="font-bold"
          >
            <MenuItem value={'Latest'} className="font-bold">
              Terbaru
            </MenuItem>
            <MenuItem value={'Oldest'} className="font-bold">
              Terlama
            </MenuItem>
          </Select>
        </FormControl>

        <Filter
          id="filter-wlb-learning"
          onApply={handleApply}
          // listFilter={filters2}
          listFilter={filters}
          includeExclude={false}
          language="indonesian"
        >
          <Typography className="cursor-pointer font-bold">Saring</Typography>
        </Filter>
      </div>
      <div
        className={`flex flex-row py-10  ${props.Enrollment ? '' : 'hidden'}`}
      >
        <Avatar style={{ width: 64, height: 64 }} src={AVATAR} />
        <div className={`pl-6 w-full flex flex-col`}>
          <Typography className="mb-2" color="textSecondary">
            Pelajaran
          </Typography>
          <TextField
            variant="outlined"
            select
            className="mb-6"
            value={selectedLesson}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setSelectedLesson(parseInt(e.target.value))
            }}
          >
            <MenuItem value={0} disabled>
              Pilih Pelajaran
            </MenuItem>
            {lessons.map((lesson: ILesson) => (
              <MenuItem key={lesson.id} value={lesson.id}>
                {lesson.title}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            placeholder="Tambahkan komentar anda (Enter untuk kirim)"
            multiline
            rows="2"
            value={commentText}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setCommentText(e.target.value)
            }}
            onKeyDown={(e: KeyboardEvent<{}>) => {
              if (e.key === 'Enter' && selectedLesson !== 0) {
                onSubmitAddComment({
                  courseId: props.id,
                  title: 'Discussion on ' + props.title,
                  lessonId: selectedLesson,
                  fulltext: commentText,
                })
              }
            }}
          />
        </div>
      </div>

      {data.data.length === 0 ? (
        <div className="py-14">
          <NoList
            imageSize={180}
            title="Maaf, tidak ada daftar"
            subTitle="Tidak ada komentar untuk kursus ini"
          />
        </div>
      ) : (
        <Comments />
      )}
    </div>
  )
}

export default TabComment
