import React from 'react'
import {useNavigate} from 'react-router-dom'
// import { LearnerContext } from '../../../../provider'
import {LearnerSocialLearningContext} from '../../../../provider/LearnerSocialLearning.provider'
import {
  Grid,
  // makeStyles,
  Button,
} from '@material-ui/core'
import {LearningType} from '../../../../utils/enum'
import TabRow from './TabRow.component'
// import {RecommendedBookCategoryRow} from './CategoryRow.component'
import {FlexRowEnd} from '../../../utils-components/GlobalStyles'

type SocialLearningTabProps = {
  isSearchFilter?: boolean
}

// const useStyles = makeStyles(() => ({
//   categoryContainer: {
//     padding: 15,
//     backgroundColor: '#eff2f4',
//     marginTop: 50,
//     marginBottom: 50,
//     marginLeft: 15,
//     marginRight: 15,
//   },
// }))

function SocialLearningTab(props: SocialLearningTabProps) {
  const navigation = useNavigate()
  // const learnerContext = React.useContext(LearnerContext)
  const learnerSocialLearningContext = React.useContext(
    LearnerSocialLearningContext
  )

  return (
    <Grid container className="lg:p-5 p-1">
      {!props.isSearchFilter && (
        <>
          <FlexRowEnd className="mb-10" style={{paddingRight: '7px'}}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                navigation('/learner/create-social-learning')
              }}
            >
              Membuat catatan pembelajaran sosial
            </Button>
          </FlexRowEnd>
          <TabRow
            title="Pembelajaran Sosial Saya"
            data={
              learnerSocialLearningContext.socialLearning.mySocialLearning.data
            }
            type={LearningType.SOCIAL_LEARNING}
            loading={
              learnerSocialLearningContext.socialLearning.mySocialLearning
                .loading
            }
          />

          <TabRow
            title="Sedang Tren di SmartASN"
            data={learnerSocialLearningContext.socialLearning.trending.data}
            type={LearningType.SOCIAL_LEARNING}
            loading={
              learnerSocialLearningContext.socialLearning.trending.loading
            }
            // hideSeeAll={true}
          />

          <TabRow
            title="Rekomendasi untuk Anda"
            data={learnerSocialLearningContext.socialLearning.recommended.data}
            // data={learnerSocialLearningContext.socialLearning.recommended.data}
            type={LearningType.SOCIAL_LEARNING}
            loading={
              learnerSocialLearningContext.socialLearning.recommended.loading
            }
            // hideSeeAll={true}
          />

          {/* {learnerSocialLearningContext.socialLearning.category.data.length >
            0 && (
            <Grid item sm={12} className={classes.categoryContainer}>
              <RecommendedBookCategoryRow
                title=""
                type={LearningType.SOCIAL_LEARNING}
                data={learnerSocialLearningContext.socialLearning.category.data.slice(
                  0,
                  4
                )}
              />
            </Grid>
          )} */}
        </>
      )}

      <TabRow
        title={props.isSearchFilter ? '' : 'Semua Pembelajaran Sosial'}
        data={learnerSocialLearningContext.socialLearning.global.data}
        type={LearningType.SOCIAL_LEARNING}
        isAll={true}
      />
    </Grid>
  )
}

export default SocialLearningTab
