import gql from 'graphql-tag'

export const CONTRIBUTOR_UPDATE_BOOK = gql`
  mutation ($isbn: String!, $objects: academy_books_set_input!) {
    update_academy_books_by_pk(pk_columns: {isbn: $isbn}, _set: $objects) {
      isbn
    }
  }
`
export const CONTRIBUTOR_CREATE_BOOK = gql`
  mutation ($objects: [academy_books_insert_input!]!) {
    insert_academy_books(objects: $objects) {
      returning {
        isbn
        date_added
      }
    }
  }
`
export const UPDATE_BOOK_LANDING_DETAILS = gql`
  mutation ($isbn: String!, $objects: academy_books_set_input!) {
    update_academy_books_by_pk(pk_columns: {isbn: $isbn}, _set: $objects) {
      isbn
      thumbnail
      title
      summary
      language
      level
      academy_course_category {
        id
        academy_course_category {
          id
        }
      }
    }
  }
`

export const UPDATE_BOOK_REVIEW_REPLY = gql`
  mutation ($reviewId: Int!, $set: academy_book_reviews_set_input!) {
    update_academy_book_reviews_by_pk(pk_columns: {id: $reviewId}, _set: $set) {
      id
      review_reply
      review_reply_date
      globalUserByReviewReplyUser {
        id
        name
        avatar
        contributor_label
      }
    }
  }
`
