import {
  Button,
  CircularProgress,
  InputAdornment,
  MenuItem,
  Tab,
  TextField,
} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {ChangeEvent, useEffect, useState, useContext} from 'react'
import {Link} from 'react-router-dom'
import {
  CustomFlex,
  GrayTabs,
  ThinTextfield,
} from '../../../components/utils-components/GlobalStyles'
import {
  LIST_FOLLOWING,
  FOLLOW_USER,
  UNFOLLOW_USER,
} from '../../../provider/services/Academy.service'
import {useSnackbar} from 'notistack'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'

import SearchIcon from '@material-ui/icons/Search'
import NoListComponent from '../../../components/empty-state/NoList.component'
import LoadingUserComponent from '../../../components/loading/LoadingUser.component'
import UserComponent from './LearningCircleUser.component'

type UserProfile = {
  avatar?: string
  id: string
  name: string
  isFollowing?: boolean
  position?: string
}

const tabsCircle = [
  {id: 0, name: 'Kontributor Pembelajaran'},
  {id: 1, name: 'Peserta'},
]

const LearningCirclePage = () => {
  const {enqueueSnackbar} = useSnackbar()
  const {data} = useContext(LearningProfileContext)

  const [tabValue, setTabValue] = useState(0)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('asc')
  const [loading, setLoading] = useState(false)
  const [contributor, setContributor] = useState<UserProfile[]>([])
  const [participant, setParticipant] = useState<UserProfile[]>([])

  const {data: following, isLoading} = LIST_FOLLOWING()

  const {mutate: followUser} = FOLLOW_USER()
  const {mutate: unfollowUser} = UNFOLLOW_USER()

  useEffect(() => {
    setLoading(true)
    if (data) {
      const mentors: UserProfile[] = []
      const mentees: UserProfile[] = []

      data.learningProfile.mentors.forEach((item: UserProfile) => {
        const isExist = mentors.some((val: UserProfile) => val.id === item.id)
        if (isExist) return

        mentors.push(item)
      })

      data.learningProfile.mentees.forEach((item: UserProfile) => {
        const isExist = mentees.some((val: UserProfile) => val.id === item.id)
        if (isExist) return

        mentees.push(item)
      })

      setContributor(mentors)
      setParticipant(mentees)

      if (following) {
        mentors.forEach((item: UserProfile, i: number) => {
          const isFollowing = following.data.some(
            (val: UserProfile) => val.id === item.id
          )

          mentors[i] = {...item, isFollowing}
        })

        mentees.forEach((item: UserProfile, i: number) => {
          const isFollowing = following.data.some(
            (val: UserProfile) => val.id === item.id
          )

          mentees[i] = {...item, isFollowing}
        })

        setContributor(mentors)
        setParticipant(mentees)
      }

      setLoading(false)
    }
  }, [data, following])

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
  }

  const onSort = (e: ChangeEvent<HTMLInputElement>) => {
    setSort(e.target.value)
  }

  const handleChangeTab = (e: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  const handleFollow = (data: UserProfile) => {
    const list = tabValue === 0 ? contributor : participant

    if (data.isFollowing) {
      unfollowUser({userId: data.id})
    } else {
      followUser({userId: data.id})
    }

    enqueueSnackbar(
      `Success ${data.isFollowing ? 'unfollow' : 'follow'} ${data.name}`,
      {variant: 'success'}
    )

    list.forEach((val: UserProfile, i: number) => {
      if (val.id === data.id) list[i] = {...val, isFollowing: !data.isFollowing}
    })

    if (tabValue === 0) {
      setContributor(list)
    } else {
      setParticipant(list)
    }
  }

  const renderList = () => {
    const list = tabValue === 0 ? contributor : participant
    list?.sort((a, b) =>
      a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
        ? 1
        : a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
        ? -1
        : 0
    )

    if (search) {
      const _list = list.filter((item: UserProfile) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
      return sort === 'desc' ? _list.reverse() : _list
    }

    return sort === 'desc' ? list.reverse() : list
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <Button
          component={Link}
          to="/profile"
          color="primary"
          startIcon={<ArrowBack style={{fontSize: '16px'}} />}
          style={{fontSize: '16px'}}
          className="font-bold"
        >
          Lingkaran pembelajaran
        </Button>
      </div>
      <GrayTabs
        value={tabValue}
        onChange={handleChangeTab}
        indicatorColor="secondary"
      >
        {tabsCircle.map((val: any, i: number) => {
          return (
            <Tab
              label={val.name}
              key={i}
              className={tabValue === i ? 'font-bold' : ''}
            />
          )
        })}
      </GrayTabs>
      <div className="pr-6 pl-3 py-6">
        <CustomFlex justify="space-between">
          <ThinTextfield
            style={{border: 'none', width: '130px'}}
            select
            value={sort}
            onChange={onSort}
            variant="standard"
          >
            <MenuItem value="asc" className="font-bold">
              Nama (A-Z)
            </MenuItem>
            <MenuItem value="desc" className="font-bold">
              Nama (Z-A)
            </MenuItem>
          </ThinTextfield>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Cari kontributor pembelajaran"
            style={{width: 280}}
            value={search}
            onChange={onSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon style={{color: '#014a62'}} />
                </InputAdornment>
              ),
            }}
          />
        </CustomFlex>
      </div>
      <div className="p-6">
        {loading ? (
          <CustomFlex justify="space-between">
            <LoadingUserComponent />
            <CircularProgress size={20} className="mt-2" />
          </CustomFlex>
        ) : renderList().length > 0 ? (
          renderList().map((val: UserProfile, i: number) => {
            return (
              <UserComponent
                key={i}
                item={val}
                followingLoading={isLoading}
                handleFollow={() => handleFollow(val)}
              />
            )
          })
        ) : (
          <NoListComponent imageSize={150} title="Maaf, tidak ada daftar" />
        )}
      </div>
    </div>
  )
}

export default LearningCirclePage
