import React from 'react'
import {Routes, Route} from 'react-router-dom'
import SearchFilter from '../pages/learner/SearchFilter.page'
import Book from '../pages/learner/Book.page'
import Course from '../pages/learner/Course.page'
import AnswerSubmission from '../pages/learner/AnswerSubmission.page'
import {LearningType} from '../utils/enum'
import MyLearningPage from '../pages/learner/my-learning'
import Catalog from '../pages/learner/Catalog.page'
import MyCertificatePage from '../pages/learner/my-certificate'
import DetailCertificate from '../pages/learner/my-certificate/DetailCertificate.page'
import Contributor from '../pages/learner/Contributor.page'
import ContributorSection from '../components/learner/contributor/section'
import Event from '../pages/learner/Event.page'
import WishListPage from '../pages/learner/wishlist'
import MicroLearning from '../pages/learner/MicroLearning.page'
import SeeAllPage from '../pages/learner/see-all'
import SeeAllSection from '../components/learner/see-all'
import QuestionReview from '../pages/learner/QuestionReview.page'
import CreateSocialLearning from '../pages/learner/social-learning/CreateSocialLearning.page'

export default function LearnerRouter() {
  return (
    <div>
      <Routes>
        <Route
          path="/tabs-all"
          element={<SearchFilter activeTab={LearningType.COURSE} />}
        />
        <Route
          path="/tabs-all/:keyword"
          element={<SearchFilter activeTab={LearningType.COURSE} />}
        />
        <Route
          path="/tabs-all/course/:keyword"
          element={<SearchFilter activeTab={LearningType.COURSE} />}
        />
        <Route
          path="/tabs-all/book/:keyword"
          element={<SearchFilter activeTab={LearningType.BOOK} />}
        />
        <Route
          path="/tabs-all/event/:keyword"
          element={<SearchFilter activeTab={LearningType.EVENT} />}
        />
        <Route
          path="/tabs-all/social-learning/:keyword"
          element={<SearchFilter activeTab={LearningType.SOCIAL_LEARNING} />}
        />
        <Route
          path="/tabs-all/micro-learning/:keyword"
          element={<SearchFilter activeTab={LearningType.MICRO_LEARNING} />}
        />
        <Route
          path="/tabs-all/learning-contributor/:keyword"
          element={
            <SearchFilter activeTab={LearningType.LEARNING_CONTRIBUTOR} />
          }
        />

        <Route path="/event/:id" element={<Event />} />
        <Route path="/micro_learning/:id" element={<MicroLearning />}>
          <Route path=":setting" element={<MicroLearning />} />
        </Route>
        <Route
          path="/micro_learning/question/:id"
          element={<QuestionReview />}
        />

        <Route path="/:active_tab" element={<Catalog />} />
        <Route path="/my_course" element={<SearchFilter />} />
        <Route path="/my-learning/" element={<MyLearningPage />}>
          <Route path=":active" element={<MyLearningPage />} />
        </Route>
        <Route path="/book/:isbn" element={<Book />} />
        <Route path="/course/:slug" element={<Course />} />
        <Route path="learning_contributor/:contribId" element={<Contributor />}>
          <Route path=":active" element={<ContributorSection />} />
        </Route>
        <Route path="/course/:slug/:activeLessonId" element={<Course />} />
        <Route
          path="/answer_submission/:type/:id"
          element={<AnswerSubmission />}
        />
        <Route path="/result/:type/:id" element={<AnswerSubmission />} />
        <Route path="/wishlist/" element={<WishListPage />}>
          <Route path=":active" element={<WishListPage />} />
        </Route>

        <Route
          path="see-all/trending/"
          element={<SeeAllPage type="trending" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>
        <Route
          path="see-all/recommended/"
          element={<SeeAllPage type="recommended" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>
        <Route path="see-all/nearby/" element={<SeeAllPage type="nearby" />}>
          <Route path=":active" element={<SeeAllSection />} />
        </Route>
        <Route path="see-all/for-you/" element={<SeeAllPage type="for-you" />}>
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        {/* See All Learning Contributor */}
        <Route
          path="/see-all/top-learning-contributor"
          element={<SeeAllPage type="top-learning-contributor" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        <Route
          path="/see-all/top-instructor"
          element={<SeeAllPage type="top-instructor" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        <Route
          path="/see-all/top-mentor"
          element={<SeeAllPage type="top-mentor" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        <Route
          path="/see-all/top-coach"
          element={<SeeAllPage type="top-coach" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        <Route
          path="/see-all/top-expert"
          element={<SeeAllPage type="top-expert" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>

        <Route
          path="/see-all/top-trainer"
          element={<SeeAllPage type="top-trainer" />}
        >
          <Route path=":active" element={<SeeAllSection />} />
        </Route>
        {/* End of See All Learning Contributor */}
        <Route path="/my-certificate/" element={<MyCertificatePage />} />
        <Route
          path="/my-certificate/detail/:id"
          element={<DetailCertificate />}
        />
        <Route
          path="/create-social-learning"
          element={<CreateSocialLearning />}
        />
      </Routes>
    </div>
  )
}
