import {FC, ReactElement} from 'react'
import {
  Paper,
  Typography,
  Divider,
  Avatar,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
} from '@material-ui/core'
import {Check, Close} from '@material-ui/icons'
import {IQuestionContent} from '../../../../interfaces'
import Dompurify from 'dompurify'
import useStyles, {QuestionSection} from '../MicroLearning.style'
import {useNavigate, useParams} from 'react-router-dom'

const QuestionReviewContent: FC<IQuestionContent> = (
  props: IQuestionContent
): ReactElement => {
  const {
    microMembers,
    questionSelected,
    questionNumber,
    questionAnsCount,
    questionLockAnsCount,
    people,
    questionCount,
    progress,
    selectQuestion,
    setSelectQuestion,
  } = props

  const navigate = useNavigate()
  const {id} = useParams()
  const classes = useStyles()

  const renderQuestion = () => {
    const renderLabel = (
      answerName: string,
      isAnswered: boolean,
      isCorrect: boolean
    ) => {
      return (
        <div className="flex">
          <Typography variant="subtitle2" className="text-[#707070] self-end">
            {answerName}
          </Typography>
          <div className="ml-3">
            {isCorrect ? (
              <Check className="text-[#4caf50]" />
            ) : (
              isAnswered && <Close className="text-[#ef4d5e]" />
            )}
          </div>
        </div>
      )
    }
    if (questionSelected?.question_type === 0) {
      return (
        <>
          <RadioGroup aria-label="question" name="question" value={true}>
            {questionSelected?.microlearning_question_answers?.map(
              (data, key) => {
                const isAnswered =
                  data?.microlearning_member_answer_options?.[0]
                    ?.microlearning_member_answer?.is_answered
                return (
                  <FormControlLabel
                    key={key}
                    value={isAnswered}
                    control={<Radio color="primary" />}
                    label={renderLabel(
                      data?.answer,
                      isAnswered,
                      data?.is_correct
                    )}
                  />
                )
              }
            )}
          </RadioGroup>
        </>
      )
    } else {
      return (
        <div className="flex flex-col">
          {questionSelected?.microlearning_question_answers?.map(
            (data, key) => {
              const isAnswered =
                data?.microlearning_member_answer_options?.[0]
                  ?.microlearning_member_answer?.is_answered
              return (
                <>
                  <FormControlLabel
                    key={key}
                    control={
                      <Checkbox
                        color="primary"
                        size="medium"
                        checked={isAnswered}
                        name="answer"
                        className={classes.checkboxQuestion}
                        disabled
                      />
                    }
                    label={renderLabel(
                      data?.answer,
                      isAnswered,
                      data?.is_correct
                    )}
                  />
                </>
              )
            }
          )}
        </div>
      )
    }
  }

  const renderContent = () => {
    if (
      microMembers?.question_generated_count === 0 &&
      microMembers.schedule === '0000000'
    ) {
      return (
        <div className="flex flex-col items-center justify-center text-center h-full">
          <Typography className="w-96  font-bold text-lg">
            You haven’t set up your micro learning yet, please set up your micro
            learning first
          </Typography>
          <Button
            variant="contained"
            color="primary"
            className="w-64 mt-6"
            onClick={() => navigate(`/learner/micro_learning/${id}/setting`)}
          >
            Setting Micro Learning
          </Button>
        </div>
      )
    } else if (selectQuestion?.locked) {
      return (
        <div className="flex flex-col items-center justify-center text-center h-full">
          <Typography className="w-2/3  font-bold text-lg">
            This question can only be seen after it has been answered
          </Typography>
        </div>
      )
    } else {
      return (
        <QuestionSection>
          <div
            className="flex font-bold text-sm"
            dangerouslySetInnerHTML={{
              __html: Dompurify.sanitize(
                `<span style="margin-right: 4px">${questionNumber}.</span> ${questionSelected?.question}`
              ),
            }}
          />
          <div className="m-4">{renderQuestion()}</div>
        </QuestionSection>
      )
    }
  }

  return (
    <Paper elevation={3} className="w-[70%] h-[26rem]">
      <div className="flex flex-row justify-between p-6">
        <div id="profile-section" className="flex flex-row grow-[4]">
          <Avatar
            src={people?.avatar}
            alt={people?.name}
            className="w-12 h-12"
          />
          <div className="ml-5">
            <Typography color="primary" className="font-bold text-lg">
              {people?.name ?? '-'}
            </Typography>
            <Typography variant="subtitle2" className="text-gray-400">
              Graded
            </Typography>
          </div>
        </div>
        <div id="progress-section" className="grow-0">
          <Typography variant="subtitle2" className="font-bold">
            Progress:
          </Typography>
          <Typography
            variant="caption"
            className="text-[#ffa000] font-bold text-3xl mr-3"
          >
            {`${progress ?? 0}%`}
          </Typography>
          <Typography variant="caption" className="font-bold">{`(${
            questionAnsCount ?? 0
          }/${questionCount ?? 0})`}</Typography>
        </div>
      </div>
      <Divider />

      <div className="flex flex-col h-80 p-6">
        {renderContent()}
        <div className="flex mt-auto justify-between">
          <Button
            variant="outlined"
            color="primary"
            className="w-24"
            disabled={
              selectQuestion.locked && questionAnsCount > 0
                ? false
                : selectQuestion.number === 0
            }
            onClick={() =>
              setSelectQuestion({
                ...selectQuestion,
                locked:
                  selectQuestion.locked && selectQuestion.number === 0
                    ? false
                    : selectQuestion.locked,
                number:
                  selectQuestion.locked && selectQuestion.number === 0
                    ? questionAnsCount - 1
                    : selectQuestion.number - 1,
              })
            }
          >
            Previous
          </Button>
          <Button
            variant="contained"
            color="primary"
            className="w-24"
            disabled={
              !selectQuestion.locked && questionLockAnsCount > 0
                ? microMembers?.question_generated_count === 0
                : questionLockAnsCount === 0
                ? selectQuestion.number + 1 === questionCount
                : selectQuestion.number + questionAnsCount + 1 >= questionCount
            }
            onClick={() =>
              setSelectQuestion({
                ...selectQuestion,
                locked: !selectQuestion.locked
                  ? selectQuestion.number + 1 === questionAnsCount
                  : true,
                number:
                  selectQuestion.number + 1 === questionAnsCount
                    ? 0
                    : selectQuestion.number + 1,
              })
            }
          >
            Next
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default QuestionReviewContent
