import {Form} from 'react-final-form'

import {Button, Paper} from '@material-ui/core'

import {isRequired} from '../../../../../../components/input-forms/validators'
import FormText from '../../../../../../components/input-forms/FormText'

import {EditState} from './_utils'

import useEvent from '../../../../../../hooks/useEvent'

export interface SectionFormProps {
  initialValues: any
  editState: EditState
  onSubmit: (values: any) => void
  onCancel?: () => void
}

const SectionForm = (props: SectionFormProps) => {
  const {initialValues, editState, onSubmit, onCancel} = props

  const onSubmitCallback = useEvent(onSubmit)

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      subscription={{pristine: true}}
    >
      {({handleSubmit, pristine}) => (
        <Paper className="bg-[#f7f8f9] p-6">
          <FormText
            name="title"
            validate={isRequired}
            initialValue={editState === EditState.ADDING ? '' : undefined}
            label="Section Title"
            placeholder="Add section title"
            fullWidth
          />

          <div className="mt-6 flex flex-row-reverse gap-4">
            <Button
              disabled={pristine}
              onClick={() => handleSubmit()}
              variant="contained"
              color="secondary"
              disableElevation
              className="h-12 w-24"
            >
              Confirm
            </Button>

            <Button
              onClick={onCancel}
              color="inherit"
              className="h-12 w-24 text-red-400"
            >
              Cancel
            </Button>
          </div>
        </Paper>
      )}
    </Form>
  )
}

export default SectionForm
