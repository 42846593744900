import gql from 'graphql-tag'

export const GET_WISHLIST_MICROLEARNING = gql`
  query ($user_id: uuid!) {
    microlearning_wishlists(
      where: {user_id: {_eq: $user_id}, deleted_at: {_is_null: true}}
    ) {
      id
      microlearningByMicrolearning {
        id
        name
        description
        price
        icon_url
        average_rating
        review_count
        question_count
        global_user {
          id
          name
          avatar
        }
      }
    }
  }
`

export const GET_WISHLIST_MICROLEARNING_COUNT = gql`
  query ($userId: uuid, $microlearningIds: [uuid!]) {
    microlearning_wishlists(
      where: {
        user_id: {_eq: $userId}
        microlearning: {_in: $microlearningIds}
        deleted_at: {_is_null: true}
      }
    ) {
      id
      microlearning
    }
  }
`
