import React from 'react'

import {Button} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'

// import {LearnerContext} from '../../../../provider'
import {LearnerCourseContext} from '../../../../provider/LearnerCourse.provider'
import {LearnerBookContext} from '../../../../provider/LearnerBook.provider'
import {LearnerEventContext} from '../../../../provider/LearnerEvent.provider'
import {LearnerSocialLearningContext} from '../../../../provider/LearnerSocialLearning.provider'
import {LearnerMicroLearningContext} from '../../../../provider/LearnerMicroLearning.provider'
import {LearnerLearningContributorContext} from '../../../../provider/LearnerLearningContributor.provider'
import {LearningType} from '../../../../utils/enum'
// import {IBook} from '../../../../interfaces'

type TabRowProps = {
  title: string
  data: any
  type: LearningType
}

const renderNavRight = (disabled: boolean, onClick?: () => void) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="secondary"
      className="min-w-0 h-8 w-8 p-0"
      disableElevation
    >
      <ChevronRightIcon />
    </Button>
  )
}

const renderNavLeft = (disabled: boolean, onClick?: () => void) => {
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      variant="contained"
      color="secondary"
      className="min-w-0 h-8 w-8 p-0 mr-2"
      disableElevation
    >
      <ChevronLeftIcon />
    </Button>
  )
}

export const TabRowNavigationCourseRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerCourseContext)

  const limit = 4
  let page = 0
  let allowed = false
  let dispatchType: string | undefined
  if (props.title.includes('Saya')) {
    page = ctx.reducerState.course.pageMyCourse
    allowed = page < ctx.myCourse.pages
    dispatchType = 'CHANGE_PAGE_LIMIT_MY_COURSE'
  } else if (props.title.includes('Tren')) {
    page = ctx.reducerState.course.offsetTrendingCourse
    allowed = page + limit < ctx.trendings.total
    dispatchType = 'CHANGE_PAGE_LIMIT_TRENDING_COURSE'
  } else if (props.title.includes('Anda')) {
    page = ctx.reducerState.course.offsetRecommendedCourse
    allowed = page + limit < ctx.courseRecommended.total
    dispatchType = 'CHANGE_PAGE_LIMIT_RECOMMENDED_COURSE'
  }

  return renderNavRight(!allowed, () => {
    if (
      props.title.includes('Trending') ||
      props.title.includes('Recommended')
    ) {
      ctx.changePageLimit(
        0,
        limit,
        dispatchType!,
        page + limit,
        props.type,
        false
      )
    } else {
      ctx.changePageLimit(page + 1, limit, dispatchType!)
    }
  })
}
export const TabRowNavigationCourseLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerCourseContext)

  const limit = 4
  let page = 0
  let dispatchType: string | undefined

  if (props.title.includes('Saya')) {
    page = ctx.reducerState.course.pageMyCourse
    dispatchType = 'CHANGE_PAGE_LIMIT_MY_COURSE'
  } else if (props.title.includes('Tren')) {
    page = ctx.reducerState.course.offsetTrendingCourse
    dispatchType = 'CHANGE_PAGE_LIMIT_TRENDING_COURSE'
  } else if (props.title.includes('Anda')) {
    page = ctx.reducerState.course.offsetRecommendedCourse
    dispatchType = 'CHANGE_PAGE_LIMIT_RECOMMENDED_COURSE'
  }

  if (props.title.includes('Trending') || props.title.includes('Recommended')) {
    return renderNavLeft(page < limit, () =>
      ctx.changePageLimit(
        0,
        limit,
        dispatchType!,
        page - limit,
        props.type,
        false
      )
    )
  } else {
    return renderNavLeft(page < 2, () =>
      ctx.changePageLimit(page - 1, 4, dispatchType!)
    )
  }
}

export const TabRowNavigationBookRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerBookContext)

  let allowed = false
  let onClick: (() => void) | undefined

  if (props.title.includes('Saya')) {
    allowed =
      ctx.myBooks?.academy_books_aggregate?.aggregate.count >
      ctx.reducerState.offsetMyBooks + ctx.reducerState.limitMyBooks
    onClick = () =>
      ctx.changePageLimit(
        0,
        4,
        'CHANGE_PAGE_LIMIT_MY_BOOKS',
        ctx.reducerState.offsetMyBooks + 4,
        ctx.reducerState.bookType,
        false
      )
  } else if (props.title.includes('Tren')) {
    allowed = ctx.reducerState.pageTrendingBooks < ctx.bookTrendings.pages
    onClick = () =>
      ctx.changePageLimit(
        ctx.reducerState.pageTrendingBooks + 1,
        4,
        'CHANGE_PAGE_LIMIT_TRENDING_BOOKS'
      )
  } else if (props.title.includes('Anda')) {
    allowed = ctx.reducerState.pageRecommendedBooks < ctx.bookRecommended.pages
    onClick = () =>
      ctx.changePageLimit(
        ctx.reducerState.pageRecommendedBooks + 1,
        4,
        'CHANGE_PAGE_LIMIT_RECOMMENDED_BOOKS'
      )
  }

  return renderNavRight(!allowed, onClick)
}
export const TabRowNavigationBookLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerBookContext)

  let allowed = false
  let onClick: (() => void) | undefined

  if (props.title.includes('Saya')) {
    allowed =
      ctx.myBooks?.academy_books_aggregate?.aggregate.count <=
      ctx.reducerState.offsetMyBooks + ctx.reducerState.limitMyBooks
    onClick = () =>
      ctx.changePageLimit(
        0,
        4,
        'CHANGE_PAGE_LIMIT_MY_BOOKS',
        ctx.reducerState.offsetMyBooks - 4,
        ctx.reducerState.bookType,
        false
      )
  } else if (props.title.includes('Tren')) {
    allowed = ctx.reducerState.pageTrendingBooks > 1
    onClick = () =>
      ctx.changePageLimit(
        ctx.reducerState.pageTrendingBooks - 1,
        4,
        'CHANGE_PAGE_LIMIT_TRENDING_BOOKS'
      )
  } else if (props.title.includes('Anda')) {
    allowed = ctx.reducerState.pageRecommendedBooks > 1
    onClick = () =>
      ctx.changePageLimit(
        ctx.reducerState.pageRecommendedBooks - 1,
        4,
        'CHANGE_PAGE_LIMIT_RECOMMENDED_BOOKS'
      )
  }

  return renderNavLeft(!allowed, onClick)
}

export const TabRowNavigationEventRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerEventContext)

  let page = 0
  let allowed = false
  let dispatchType: string | undefined

  if (props.title === 'Acara terdekat') {
    page = ctx.reducerState.pageEventsNearby
    allowed = page < ctx.eventsNearBy.pages
    dispatchType = 'CHANGE_PAGE_LIMIT_EVENTS_NEARBY'
  } else if (props.title === 'Acara-acara untuk Anda') {
    page = ctx.reducerState.pageEventsForYou
    allowed = page < ctx.eventsForYou.pages
    dispatchType = 'CHANGE_PAGE_LIMIT_EVENTS_FOR_YOU'
  }

  return renderNavRight(!allowed, () =>
    ctx.changePageLimit(page + 1, 4, dispatchType!)
  )
}

export const TabRowNavigationEventLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerEventContext)

  let page = 0
  let dispatchType: string | undefined

  if (props.title === 'Acara terdekat') {
    page = ctx.reducerState.pageEventsNearby
    dispatchType = 'CHANGE_PAGE_LIMIT_EVENTS_NEARBY'
  } else if (props.title === 'Acara-acara untuk Anda') {
    page = ctx.reducerState.pageEventsForYou
    dispatchType = 'CHANGE_PAGE_LIMIT_EVENTS_FOR_YOU'
  }

  return renderNavLeft(page < 2, () =>
    ctx.changePageLimit(page - 1, 4, dispatchType!)
  )
}

export const TabRowNavigationSocialLearningRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerSocialLearningContext)
  const state = ctx.reducerState

  let disabled = false
  let offset = 0
  let dispatchType: string | undefined

  if (props.title.includes('Saya')) {
    offset = state.socialLearning.offsetMySocialLearning
    disabled = ctx.socialLearning.mySocialLearning.total <= offset + 4
    dispatchType = 'CHANGE_PAGE_MY_SOCIAL_LEARNING'
  } else if (props.title.includes('Tren')) {
    offset = state.socialLearning.offsetTrending
    disabled = ctx.socialLearning.trending.total <= offset + 4
    dispatchType = 'CHANGE_OFFSET_TRENDING_SOCIAL_LEARNING'
  } else if (props.title.includes('Anda')) {
    offset = state.socialLearning.offsetRecommended
    disabled = ctx.socialLearning.recommended.total <= offset + 4
    dispatchType = 'CHANGE_OFFSET_RECOMMENDED_SOCIAL_LEARNING'
  }

  return renderNavRight(disabled, () =>
    ctx.changePageLimit(0, 4, dispatchType!, offset + 4, props.type, false)
  )
}

export const TabRowNavigationSocialLearningLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerSocialLearningContext)
  const state = ctx.reducerState

  let offset = 0
  let dispatchType: string | undefined

  if (props.title.includes('Saya')) {
    offset = state.socialLearning.offsetMySocialLearning
    dispatchType = 'CHANGE_PAGE_MY_SOCIAL_LEARNING'
  } else if (props.title.includes('Tren')) {
    offset = state.socialLearning.offsetTrending
    dispatchType = 'CHANGE_OFFSET_TRENDING_SOCIAL_LEARNING'
  } else if (props.title.includes('Anda')) {
    offset = state.socialLearning.offsetRecommended
    dispatchType = 'CHANGE_OFFSET_RECOMMENDED_SOCIAL_LEARNING'
  }

  return renderNavLeft(offset < 1, () =>
    ctx.changePageLimit(
      0,
      4,
      dispatchType!,
      Math.max(offset - 4, 0),
      props.type,
      false
    )
  )
}

export const TabRowNavigationMicroLearningRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerMicroLearningContext)
  const state = ctx.reducerState.microlearning

  let disabled = false
  let offset = 0
  let dispatchType: string | undefined

  if (props.title.includes('Saya')) {
    offset = state.offsetMyMicroLearning
    disabled = ctx.microlearning.myMicrolearning?.total <= offset + 4
    dispatchType = 'CHANGE_OFFSET_MY_MICROLEARNING'
  } else if (props.title.includes('Tren')) {
    offset = state.offsetTrendingMicroLearning
    disabled = ctx.microlearning.trendingMicroLearning?.total <= offset + 4
    dispatchType = 'CHANGE_OFFSET_TRENDING_MICROLEARNING'
  } else if (props.title.includes('Anda')) {
    offset = state.offsetRecommendedMicroLearning
    disabled = ctx.microlearning.recommendedMicroLearning?.total <= offset + 4
    dispatchType = 'CHANGE_OFFSET_RECOMMENDED_MICROLEARNING'
  }

  return renderNavRight(disabled, () =>
    ctx.changePageLimit(0, 4, dispatchType!, offset + 4, props.type, false)
  )
}

export const TabRowNavigationMicroLearningLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerMicroLearningContext)
  const state = ctx.reducerState.microlearning

  let offset = 0
  let dispatchType: string | undefined

  if (props.title.includes('Saya')) {
    offset = state.offsetMyMicroLearning
    dispatchType = 'CHANGE_OFFSET_MY_MICROLEARNING'
  } else if (props.title.includes('Tren')) {
    offset = state.offsetTrendingMicroLearning
    dispatchType = 'CHANGE_OFFSET_TRENDING_MICROLEARNING'
  } else if (props.title.includes('Anda')) {
    offset = state.offsetRecommendedMicroLearning
    dispatchType = 'CHANGE_OFFSET_RECOMMENDED_MICROLEARNING'
  }

  return renderNavLeft(offset < 1, () =>
    ctx.changePageLimit(
      0,
      4,
      dispatchType!,
      Math.max(offset - 4, 0),
      props.type,
      false
    )
  )
}

export const TabRowNavigationLearningContributorRight = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerLearningContributorContext)

  let offset = 0
  let allowed = false
  let dispatchType: string | undefined

  if (props.title.includes('Kontributor')) {
    offset = ctx.reducerState.learningContributor.offsetTopContributor
    allowed =
      ctx.learningContributor.topContributor?.total >
      offset + ctx.reducerState.learningContributor.limitTopContributor
    dispatchType = 'CHANGE_OFFSET_TOP_CONTRIBUTOR'
  } else if (props.title.includes('Instruktur')) {
    offset = ctx.reducerState.learningContributor.offsetTopInstructor
    allowed =
      ctx.learningContributor.topInstructor?.total >
      offset + ctx.reducerState.learningContributor.limitTopInstructor
    dispatchType = 'CHANGE_OFFSET_TOP_INSTRUCTOR'
  } else if (props.title.includes('Pembimbing')) {
    offset = ctx.reducerState.learningContributor.offsetTopMentor
    allowed =
      ctx.learningContributor.topMentor?.total >
      offset + ctx.reducerState.learningContributor.limitTopMentor
    dispatchType = 'CHANGE_OFFSET_TOP_MENTOR'
  } else if (props.title === 'Pelatih Teratas') {
    offset = ctx.reducerState.learningContributor.offsetTopCoach
    allowed =
      ctx.learningContributor.topCoach?.total >
      offset + ctx.reducerState.learningContributor.limitTopCoach
    dispatchType = 'CHANGE_OFFSET_TOP_COACH'
  } else if (props.title.includes('Ahli')) {
    offset = ctx.reducerState.learningContributor.offsetTopExpert
    allowed =
      ctx.learningContributor.topExpert?.total >
      offset + ctx.reducerState.learningContributor.limitTopExpert
    dispatchType = 'CHANGE_OFFSET_TOP_EXPERT'
  } else if (props.title === 'Pelatih Individu Teratas') {
    offset = ctx.reducerState.learningContributor.offsetTopTrainer
    allowed =
      ctx.learningContributor.topTrainer?.total >
      offset + ctx.reducerState.learningContributor.limitTopTrainer
    dispatchType = 'CHANGE_OFFSET_TOP_TRAINER'
  }

  return renderNavRight(!allowed, () =>
    ctx.changePageLimit(0, 4, dispatchType!, offset + 4, props.type, false)
  )
}

export const TabRowNavigationLearningContributorLeft = (
  props: TabRowProps
): React.ReactElement => {
  const ctx = React.useContext(LearnerLearningContributorContext)

  let offset = 0
  let dispatchType: string | undefined

  if (props.title.includes('Kontributor')) {
    offset = ctx.reducerState.learningContributor.offsetTopContributor
    dispatchType = 'CHANGE_OFFSET_TOP_CONTRIBUTOR'
  } else if (props.title.includes('Instruktur')) {
    offset = ctx.reducerState.learningContributor.offsetTopInstructor
    dispatchType = 'CHANGE_OFFSET_TOP_INSTRUCTOR'
  } else if (props.title.includes('Pembimbing')) {
    offset = ctx.reducerState.learningContributor.offsetTopMentor
    dispatchType = 'CHANGE_OFFSET_TOP_MENTOR'
  } else if (props.title === 'Pelatih Teratas') {
    offset = ctx.reducerState.learningContributor.offsetTopCoach
    dispatchType = 'CHANGE_OFFSET_TOP_COACH'
  } else if (props.title.includes('Ahli')) {
    offset = ctx.reducerState.learningContributor.offsetTopExpert
    dispatchType = 'CHANGE_OFFSET_TOP_EXPERT'
  } else if (props.title === 'Pelatih Individu Teratas') {
    offset = ctx.reducerState.learningContributor.offsetTopTrainer
    dispatchType = 'CHANGE_OFFSET_TOP_TRAINER'
  }

  return renderNavLeft(offset < 1, () =>
    ctx.changePageLimit(
      0,
      4,
      dispatchType!,
      Math.max(offset - 4, 0),
      props.type,
      false
    )
  )
}
