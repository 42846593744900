import React from 'react'

import {Grid} from '@material-ui/core'
// import { LearnerContext } from '../../../../provider'
import {LearnerLearningContributorContext} from '../../../../provider/LearnerLearningContributor.provider'
import {LearningType} from '../../../../utils/enum'
import TabRow from './TabRow.component'
import {LearnerAcademicContext} from '../../../../provider/Academic.provider'

type ContributorTabProps = {
  isSearchFilter?: boolean
}

function ContributorTab(props: ContributorTabProps) {
  // const learnerContext = React.useContext(LearnerContext)
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)
  const learnerLearningContributorContext = React.useContext(
    LearnerLearningContributorContext
  )
  const {reducerState} = learnerAcademicContext
  const {changePageLimit} = learnerLearningContributorContext

  const [searchKeyword, setSearchKeyword] = React.useState<String>(
    reducerState.searchKeyword
  )
  const [contributorLabels, setContributorLabels] = React.useState<String[]>(
    reducerState.academicFilter.contributorLabels
  )
  const [rating, setRating] = React.useState<number>(
    reducerState.academicFilter.ratingGQL[0]
  )

  const changePageLimitCallback = React.useCallback(
    (page, limit, type, offset) => {
      if (
        contributorLabels !== reducerState.academicFilter.contributorLabels ||
        searchKeyword !== reducerState.searchKeyword ||
        (reducerState.academicFilter.ratingGQL.length > 0 &&
          rating !== reducerState.academicFilter.ratingGQL[0])
      ) {
        setContributorLabels(reducerState.academicFilter.contributorLabels)
        setSearchKeyword(reducerState.searchKeyword)
        if (reducerState.academicFilter.ratingGQL.length > 0) {
          setRating(reducerState.academicFilter.ratingGQL[0])
        }
        changePageLimit(page, limit, type, offset)
        learnerLearningContributorContext.refetchTopContributor()
      }
    },
    [
      changePageLimit,
      reducerState.academicFilter.contributorLabels,
      reducerState.academicFilter.ratingGQL,
      searchKeyword,
      reducerState.searchKeyword,
      contributorLabels,
      learnerLearningContributorContext,
      rating,
    ]
  )

  React.useEffect(() => {
    if (props.isSearchFilter) {
      changePageLimitCallback(0, 0, 'CHANGE_OFFSET_ALL_INSTRUCTOR', 0)
      learnerLearningContributorContext.refetchTopContributor()
    }
  }, [
    props.isSearchFilter,
    changePageLimitCallback,
    reducerState.academicFilter.contributorLabels,
    reducerState.academicFilter.ratingGQL,
    learnerLearningContributorContext,
  ])

  return (
    <Grid container style={{padding: '10 20 20 20'}}>
      {!props.isSearchFilter && (
        <>
          <TabRow
            title="Kontributor Pembelajaran Teratas"
            data={
              learnerLearningContributorContext.learningContributor
                ?.topContributor?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor
                ?.topContributor?.loading
            }
          />
          <TabRow
            title="Instruktur / Pengajar Teratas"
            data={
              learnerLearningContributorContext.learningContributor
                ?.topInstructor?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor
                ?.topInstructor?.loading
            }
          />
          <TabRow
            title="Pembimbing Teratas"
            data={
              learnerLearningContributorContext.learningContributor?.topMentor
                ?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor?.topMentor
                ?.loading
            }
          />
          <TabRow
            title="Pelatih Teratas"
            data={
              learnerLearningContributorContext.learningContributor?.topCoach
                ?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor?.topCoach
                ?.loading
            }
          />
          <TabRow
            title="Ahli Teratas"
            data={
              learnerLearningContributorContext.learningContributor?.topExpert
                ?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor?.topExpert
                ?.loading
            }
          />
          <TabRow
            title="Pelatih Individu Teratas"
            data={
              learnerLearningContributorContext.learningContributor?.topTrainer
                ?.data
            }
            type={LearningType.LEARNING_CONTRIBUTOR}
            loading={
              learnerLearningContributorContext.learningContributor?.topTrainer
                ?.loading
            }
          />
        </>
      )}
      <TabRow
        title={props.isSearchFilter ? '' : 'Semua Kontributor Pembelajaran'}
        data={
          learnerLearningContributorContext.learningContributor?.allInstructor
            ?.data
        }
        type={LearningType.LEARNING_CONTRIBUTOR}
        loading={
          learnerLearningContributorContext.learningContributor?.allInstructor
            ?.loading
        }
        isAll={true}
      />
    </Grid>
  )
}

export default ContributorTab
