import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'
import React from 'react'
import {Button, Card, Typography} from '@material-ui/core'
import {FavoriteBorder} from '@material-ui/icons'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'
import FavoriteIcon from '@material-ui/icons/Favorite'
import AddToCartButton from '../../../button/AddToCartButton.component'

import {PopperProps} from '../../../../interfaces'
import {microlearningType} from '../../../../utils/constans'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import {ADD_TO_CART} from '../../../../provider/services/Academy.service'
import {
  GET_MY_MICROLEARNING_COUNT,
  GET_USER_CART_COUNT,
  GET_WISHLIST_MICROLEARNING_COUNT,
} from '../../../../graphql/queries'
import {ADD_MICROLEARNING_TO_WISHLIST} from '../../../../graphql/mutations'
import {DELETE_MICROLEARNING_WISHLIST} from '../../../../provider/services/MicroLearning.service'

const PopperMicroLearningActions = (props: {item: any}) => {
  const {item} = props

  const isFree = item.price === 0
  const [loading, setLoading] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const [addToCart] = ADD_TO_CART()
  const [addToWishlist] = useMutation(
    ADD_MICROLEARNING_TO_WISHLIST,
    HASURA_ROLE_USER_CONTEXT
  )
  const [deleteFromWishlist] = DELETE_MICROLEARNING_WISHLIST()

  const {data: cartData, refetch: refetchCart} = useQuery(GET_USER_CART_COUNT, {
    skip: isFree,
    fetchPolicy: 'cache-and-network',
    variables: {
      itemTable: 'microlearnings',
      itemIds: [item.id],
    },
  })

  const {data: wishlistData, refetch: refetchWishlist} = useQuery(
    GET_WISHLIST_MICROLEARNING_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: USER_ID,
        microlearningIds: [item.id],
      },
    }
  )

  const {data: ownedData} = useQuery(GET_MY_MICROLEARNING_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      microlearningIds: [item.id],
    },
  })

  const isInCart = cartData && cartData.marketplace_user_carts.length > 0
  const isInWishlist =
    wishlistData && wishlistData.microlearning_wishlists.length > 0
  const isOwned =
    ownedData && ownedData.microlearnings_aggregate.aggregate.count > 0

  const handleCartAddition = () => {
    const variables = {
      objects: [
        {
          item_id: item.id,
          item_object: {
            name: item.name,
            image: item.icon_url,
            price: item?.price || 0,
            weight: 0,
            note: '',
          },
          item_table: 'microlearnings',
          item_vendor: item.global_user?.name,
          item_vendor_id: item.global_user?.id,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    const promise = addToCart({variables})

    promise.then(
      () => {
        refetchCart()
      },
      () => {
        enqueueSnackbar(`Failed to add microlearning to cart`, {
          variant: 'error',
        })
      }
    )
  }

  const handleWishlistAddition = () => {
    setLoading(true)
    const promise = addToWishlist({
      variables: {
        objects: {microlearning: item.id},
      },
    })

    promise.then(
      () => {
        refetchWishlist()
        setLoading(false)
        enqueueSnackbar('Added to wishlist', {variant: 'success'})
      },
      () => {
        enqueueSnackbar(`Failed to add microlearning to wishlist`, {
          variant: 'error',
        })
      }
    )
  }

  const handleRemoveWishlist = () => {
    const id = wishlistData?.microlearning_wishlists[0]?.id
    if (id) {
      setLoading(true)
      deleteFromWishlist({
        variables: {
          id: id,
        },
      })
        .then(() => {
          enqueueSnackbar('Micro learning removed from wishlist', {
            variant: 'success',
          })
          refetchWishlist()
        })
        .catch(() => {
          enqueueSnackbar('Remove micro learning from wishlish failed', {
            variant: 'error',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (isOwned) {
    return null
  }

  return (
    <div className="flex flex-row justify-between mt-4">
      {isFree ? (
        <Button
          component={Link}
          to={`/learner/micro_learning/${item.id}`}
          variant="contained"
          color="primary"
          size="large"
          className="mr-4 text-center"
        >
          Ambil Pembelajaran Mikro
        </Button>
      ) : (
        <AddToCartButton
          isInCart={isInCart}
          action={handleCartAddition}
          size="large"
          className="mr-4"
        />
      )}

      {isInWishlist ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
          onClick={handleRemoveWishlist}
        >
          <FavoriteIcon />
        </Button>
      ) : (
        <Button
          onClick={handleWishlistAddition}
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
        >
          <FavoriteBorder />
        </Button>
      )}
    </div>
  )
}

const PopperMicroLearning = (props: PopperProps) => {
  const {item, hidePrice, featureName} = props

  const dot = <div className="h-1 w-1 rounded-full bg-gray-600" />

  const questionCount =
    item.microlearning_questions_aggregate?.aggregate.count ||
    item.question_count ||
    0

  const language = item.global_language?.name
    ? item.global_language
    : item.global_languages?.name
    ? item.global_languages
    : null

  const level = item.level

  const category = item.academy_course_category?.name
    ? item.academy_course_category
    : item.academy_course_categories?.name
    ? item.academy_course_categories
    : null

  const blurbs = [
    language && <span>{language.name}</span>,
    level && <span>{microlearningType[level]}</span>,
    category && <span>{category.name}</span>,
  ]

  const blurbElements = blurbs.filter(Boolean).reduce((acc, next) => {
    return acc ? (
      <>
        {acc}
        {dot}
        {next}
      </>
    ) : (
      next
    )
  }, null)

  return (
    <Card style={{width: 320}} className="p-4">
      <div>
        <LinesEllipsis
          text={item.name}
          maxLine={2}
          component={Link}
          to={`/learner/micro_learning/${item.id}`}
          state={{prevRouteName: featureName}}
          className="font-bold overflow-hidden"
        />

        <div className="flex flex-wrap items-center gap-x-1 text-gray-600 text-xs mt-1 empty:hidden">
          {blurbElements}
        </div>

        <Typography color="primary" className="font-bold text-sm mt-2 mb-0">
          {questionCount} Pertanyaan
        </Typography>

        <Typography gutterBottom className="font-bold text-sm mt-4">
          Keterangan
        </Typography>

        <LinesEllipsis
          text={item.description}
          maxLine={5}
          component="p"
          className="text-sm overflow-hidden mt-2 mb-0 empty:hidden"
        />
      </div>

      {!hidePrice && <PopperMicroLearningActions item={item} />}
    </Card>
  )
}

export {PopperMicroLearning}
