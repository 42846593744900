import {useState, useEffect, ChangeEvent} from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Icon,
  Divider,
  TextField,
  makeStyles,
  InputAdornment,
} from '@material-ui/core'
import {ILearningDiaryCompetency} from '../../../interfaces/LearningDiary.interface'
import {ArrowDropDown} from '@material-ui/icons'

const useStyle = makeStyles({
  dataList: {
    width: '100%',
    backgroundColor: '#fff',
    position: 'absolute',
    maxHeight: '360px',
    overflowY: 'auto',
    zIndex: 999,
    top: '75%',
    borderRadius: '4px',
    boxShadow:
      'rgb(0 0 0 / 20%) 0px 1px 5px 0px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 3px 1px -2px',
  },
  input: {
    '&::placeholder': {
      color: 'rgba(0,0,0,1)',
      opacity: 1,
    },
  },
})

type propsType = {
  open: boolean
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void
  options: ILearningDiaryCompetency[]
  loading: boolean
  onBlur: () => void
  onFocus: () => void
  selected: (val: ILearningDiaryCompetency) => void
  textValue: string
}

const Autocomplete = (props: propsType) => {
  const classes = useStyle()
  const {
    open,
    onInputChange,
    options,
    loading,
    onBlur,
    onFocus,
    selected,
    textValue,
  } = props

  const [list, setList] = useState<ILearningDiaryCompetency[]>([])

  useEffect(() => {
    if (textValue) {
      const _list = options.filter((item: ILearningDiaryCompetency) =>
        item.name.toLowerCase().includes(textValue.toLowerCase())
      )
      return setList(_list)
    }
    setList(options)
  }, [textValue, options])

  const renderListItem = loading ? (
    <List className={classes.dataList}>
      <ListItem alignItems="flex-start">
        <ListItemText primary="Loading..." />
      </ListItem>
    </List>
  ) : (
    <List className={classes.dataList}>
      {textValue && (
        <>
          <ListItem
            button
            alignItems="flex-start"
            onMouseDown={() => selected({id: 'add', name: textValue})}
            className="flex justify-between"
          >
            <ListItemText primary={textValue} />
            <Icon>add</Icon>
          </ListItem>
          <Divider />
        </>
      )}

      {list.length > 0 ? (
        list.map((option: ILearningDiaryCompetency, i: number) => {
          return (
            <>
              <ListItem
                button
                alignItems="flex-start"
                key={i}
                onMouseDown={() => selected(option)}
                className="flex justify-between"
              >
                <ListItemText primary={option.name} />
              </ListItem>
            </>
          )
        })
      ) : (
        <ListItem
          button
          alignItems="flex-start"
          className="flex justify-between"
          disabled
        >
          <ListItemText primary="No Result" />
        </ListItem>
      )}
    </List>
  )

  return (
    <div className="relative">
      <TextField
        variant="outlined"
        type="text"
        onChange={onInputChange}
        value={textValue}
        autoComplete="off"
        fullWidth
        placeholder="Pilih Kompetensi"
        onBlur={onBlur}
        onFocus={onFocus}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <ArrowDropDown style={{color: '#757575', marginRight: '-5px'}} />
            </InputAdornment>
          ),
          classes: {input: classes.input},
        }}
      />

      {open && renderListItem}
    </div>
  )
}

export default Autocomplete
