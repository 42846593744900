import gql from 'graphql-tag'

export const GET_WISHLIST_COURSE = gql`
  query ($limit: Int, $offset: Int, $query: String, $userId: uuid!) {
    academy_wishlists(
      limit: $limit
      offset: $offset
      where: {academy_course: {title: {_ilike: $query}}}
    ) {
      id
      academy_course {
        id
        title
        thumbnail
        slug
        global_user {
          id
          name
          avatar
        }
        average_rating
        academy_price_tier {
          pricing_idr
        }
        academy_course_enrollments_aggregate(where: {user_id: {_eq: $userId}}) {
          aggregate {
            is_enrolled: count
          }
        }
      }
    }
  }
`
