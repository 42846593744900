import React from 'react'

import {
  Grid,
  // makeStyles
} from '@material-ui/core'
import {LearnerMicroLearningContext} from '../../../../provider/LearnerMicroLearning.provider'
import {LearningType} from '../../../../utils/enum'
// import {RecommendedBookCategoryRow} from './CategoryRow.component'
import TabRow from './TabRow.component'

type MicroLearningTabProps = {
  isSearchFilter?: boolean
}

// const useStyles = makeStyles(() => ({
//   categoryContainer: {
//     padding: 15,
//     backgroundColor: '#eff2f4',
//     marginTop: 50,
//     marginBottom: 50,
//     marginRight: 15,
//   },
// }))

function MicroLearningTab(props: MicroLearningTabProps) {
  // const classes = useStyles()
  const learnerMicroLearningContext = React.useContext(
    LearnerMicroLearningContext
  )
  return (
    <Grid container className="lg:p-5 p-1">
      {!props.isSearchFilter && (
        <>
          <TabRow
            title="Pembelajaran Mikro Saya"
            data={
              learnerMicroLearningContext?.microlearning?.myMicroLearning?.data
            }
            type={LearningType.MICRO_LEARNING}
            loading={
              learnerMicroLearningContext?.microlearning?.myMicroLearning
                ?.loading
            }
          />

          <TabRow
            title="Sedang Tren di SmartASN"
            data={
              learnerMicroLearningContext?.microlearning?.trendingMicroLearning
                ?.data
            }
            type={LearningType.MICRO_LEARNING}
            loading={
              learnerMicroLearningContext?.microlearning?.trendingMicroLearning
                ?.loading
            }
          />

          <TabRow
            title="Rekomendasi untuk Anda"
            data={
              learnerMicroLearningContext?.microlearning
                ?.recommendedMicroLearning?.data
            }
            type={LearningType.MICRO_LEARNING}
            loading={
              learnerMicroLearningContext?.microlearning
                ?.recommendedMicroLearning?.loading
            }
          />

          {/* {learnerMicroLearningContext.microlearning.category.data.length >
            0 && (
            <Grid item sm={12} className={classes.categoryContainer}>
              <RecommendedBookCategoryRow
                title=""
                type={LearningType.MICRO_LEARNING}
                data={learnerMicroLearningContext.microlearning.category.data.slice(
                  0,
                  4
                )}
              />
            </Grid>
          )} */}
        </>
      )}

      <TabRow
        title={props.isSearchFilter ? '' : 'Semua Pembelajaran Mikro'}
        data={learnerMicroLearningContext.microlearning.allMicroLearning.data}
        type={LearningType.MICRO_LEARNING}
        isAll={true}
      />
    </Grid>
  )
}

export default MicroLearningTab
