import React, { type ChangeEvent } from 'react'
import { Link, useSearchParams, useNavigate } from 'react-router-dom'
import { useTitle } from 'hoofd'

import {
  Button,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  CircularProgress,
  Icon,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { BorderLinearProgress } from '../../../components/utils-components/GlobalStyles'
import { Filter } from '@smartasn/wlb-utils-components'

import { useQuery } from '@apollo/client'
import { GET_CONTRIBUTOR_MICROLEARNINGS } from '../../../graphql/queries'
import { USER_ID } from '../../../utils/globals'
import { format } from 'date-fns'
import { mapFilterResults, convertToRupiah } from '../../../utils/helpers'
import { INSTRUCTOR_CONTEXT } from '../../../utils/contributor-helpers'

import NoListComponent from '../../../components/empty-state/NoList.component'

interface MicrolearningItemProps {
  data: {
    id: string
    status: string
    slug: string
    average_rating: number
    icon_url: string
    date_added: Date
    name: string
    progress?: number
    total_enrolled: {
      aggregate: {
        count: number
        __typename: string
      }
      __typename: string
    }
    this_month: {
      aggregate: {
        count: number
        __typename: string
      }
      __typename: string
    }
    price: number
    total_rating: {
      aggregate: {
        count: number
        __typename: string
      }
      __typename: string
    }
    microlearning_members_aggregate: {
      aggregate: {
        count: number
        __typename: string
      }
      __typename: string
    }
    __typename: string
  }
  onClick?: () => void
}

interface VariableInterface {
  limit: number
  offset: number
  userId: string
  search: string
  dateLastmonth: string
  orderBy: {
    date_added: string
  }
  createdDate: {
    _lte: null
    _gte: null
  }
  status?: string[]
}

const filterOptions = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          label: 'Active',
          value: 'ACTIVE',
        },
        {
          label: 'Pending',
          value: 'PENDING',
        },
        {
          label: 'Draft',
          value: 'DRAFT',
        },
      ],
    },
  },
  {
    name: 'Created Date',
    fieldName: 'date',
    type: 'date',
  },
]

const sortOptions = [
  { label: 'Latest', value: { date_added: 'desc' } },
  { label: 'Oldest', value: { date_added: 'asc' } },
  { label: 'Highest Rating', value: { average_rating: 'desc_nulls_last' } },
  { label: 'Lowest Rating', value: { average_rating: 'asc_nulls_first' } },
  {
    label: 'Most Enrolled',
    value: {
      microlearning_members_aggregate: {
        count: 'desc_nulls_last',
      },
    },
  },
  {
    label: 'Fewest Enrolled',
    value: {
      microlearning_members_aggregate: {
        count: 'asc_nulls_first',
      },
    },
  },
]

const Item = (props: MicrolearningItemProps) => {
  const defaultThumbnail = require('../../../assets/images/defaultCourseCover.image.png')
  const { data, onClick } = props

  return (
    <TableRow
      className="cursor-pointer focus:bg-[#f5f5f5] hover:bg-[#f5f5f5] transition w-full"
      onClick={onClick}
    >
      <TableCell className="w-20 lg:w-52 pr-0">
        <img
          src={data.icon_url || defaultThumbnail}
          alt="microlearning-thumbnail"
          className="w-20 h-16 lg:w-60 lg:h-28"
        />
      </TableCell>
      <TableCell className="w-[28%]">
        <div className="flex flex-col justify-between h-16 lg:h-28">
          <div className="flex flex-col gap-2">
            <div
              className="font-semibold text-base w-full text-ellipsis overflow-hidden"
              style={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
              }}
            >
              {data.name}
            </div>
            <div className="text-xs text-gray-400">
              Created {format(new Date(data.date_added), 'MMM d, yyyy')}
            </div>
          </div>
          <div className="flex justify-between">
            <div
              className={
                data.status === 'ACTIVE'
                  ? 'text-green-500'
                  : data.status === 'PENDING'
                    ? 'text-red-500'
                    : 'text-gray-400'
              }
            >
              {data.status}
            </div>
            <div className="text-gray-400">
              {data.price ? convertToRupiah(data.price, true) : 'FREE'}
            </div>
          </div>
        </div>
      </TableCell>
      {!data.progress ? (
        <>
          <TableCell className="flex gap-x-4 pl-0">
            {/* <div className="flex flex-col h-16 lg:h-28 gap-y-6">
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-400 w-max">Earned this month</div>
                <div className="font-semibold text-base">
                  {convertToRupiah(
                    data.this_month.aggregate.count * data.price,
                    true
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-400 w-max">Total Earned</div>
                <div className="font-semibold text-base">
                  {convertToRupiah(
                    data.total_enrolled.aggregate.count * data.price,
                    true
                  )}
                </div>
              </div>
            </div> */}
          </TableCell>
          <TableCell>
            <div className="flex flex-col h-16 lg:h-28 gap-y-6">
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-400 w-max">Rating</div>
                <div className="flex flex-row items-center gap-1">
                  <Icon className="text-[#039be5]" fontSize="small">
                    star
                  </Icon>
                  <div className="font-semibold text-base">
                    {(data.average_rating || 0).toFixed(1)}
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-400 w-max">Total Rating</div>
                <div className="font-semibold text-base">
                  {data.total_rating.aggregate.count}
                </div>
              </div>
            </div>
          </TableCell>
          <TableCell className="pl-0">
            <div className="flex flex-col h-16 lg:h-28">
              <div className="flex flex-col gap-0.5">
                <div className="text-gray-400 w-max">Enrolled</div>
                <div className="font-semibold text-base">
                  {data.total_enrolled.aggregate.count}
                </div>
              </div>
            </div>
          </TableCell>
        </>
      ) : (
        <TableCell colSpan={3} className="pl-0">
          <div className="flex gap-x-4">
            <div className="font-semibold">Finish your course</div>
            <div className="grow">
              <BorderLinearProgress
                variant="determinate"
                value={10}
                className="mt-1"
              />
            </div>
          </div>
        </TableCell>
      )}
    </TableRow>
  )
}

const ContributorMicrolearningsPage = () => {
  useTitle('Contributor - Microlearnings')
  const dateNow = format(new Date(), 'yyyy-MM-dd')
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const searchTimeoutRef = React.useRef<any>()

  const search = searchParams.get('query') ?? ''

  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [orderBy, setOrderBy] = React.useState({ date_added: 'desc' })
  const [filter, setFilter] = React.useState({
    date: {
      _lte: null,
      _gte: null,
    },
    status: [],
  })

  const variables: VariableInterface = {
    limit: limit,
    offset: page * limit,
    userId: USER_ID,
    search: `%${search}%`,
    dateLastmonth: dateNow,
    orderBy,
    createdDate: filter.date,
    status: filter.status,
  }

  if (!filter.status.length) {
    delete variables.status
  }

  const { data, loading } = useQuery(GET_CONTRIBUTOR_MICROLEARNINGS, {
    fetchPolicy: 'cache-and-network',
    variables,
    context: INSTRUCTOR_CONTEXT,
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const handleOrderByChange = (value: any) => {
    setOrderBy(JSON.parse(value))
  }

  const onApplyFilter = (result: any) => {
    const mappedResult: any = mapFilterResults(result[0])

    if (!Object.keys(mappedResult).length) {
      setFilter({
        date: {
          _lte: null,
          _gte: null,
        },
        status: [],
      })
    } else {
      if (mappedResult.date) {
        setFilter({
          ...filter,
          ...mappedResult,
          date: {
            _gte: mappedResult?.date.from,
            _lte: mappedResult?.date.to,
          },
        })
      } else {
        setFilter({ ...filter, ...mappedResult })
      }
    }
  }

  const onPageChange = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const onLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLimit(Number(e.target.value))
  }

  return (
    <Paper>
      <div className="h-max p-6 flex items-center">
        <Typography color="primary" className="font-bold">
          My Micro Learning
        </Typography>

        <div className="grow" />

        <Button
          component={Link}
          to="create"
          variant="contained"
          color="primary"
          disableElevation
          size="large"
        >
          Create Micro Learning
        </Button>
      </div>

      <Divider />

      <div className="px-6 pt-6 pb-4">
        <TextField
          key={search}
          placeholder="Search..."
          variant="outlined"
          fullWidth
          defaultValue={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="px-2 pb-4 flex justify-between items-center">
        <Select
          value={JSON.stringify(orderBy)}
          onChange={(e) => handleOrderByChange(e.target.value)}
          variant="standard"
          disableUnderline={true}
          SelectDisplayProps={{
            className:
              'text-sm pl-4 pr-6 py-2 focus:bg-[#f5f5f5] outline-none rounded transition font-semibold',
          }}
        >
          {sortOptions.map((option: any, idx: number) => {
            return (
              <MenuItem value={JSON.stringify(option.value)} key={idx}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>

        <Filter
          onApply={onApplyFilter}
          listFilter={filterOptions}
          includeExclude={false}
          options={{
            isSetActiveMenuWhenOpen: true,
            setActiveMenuToIndex: 0,
            delayResetMenu: 500,
          }}
        >
          <Button className="px-4 font-semibold">Filter</Button>
        </Filter>
      </div>
      <TableContainer className="overflow-x-visible">
        <Table className="table-fixed">
          <TableBody>
            {loading && !data?.microlearnings.length ? (
              <TableRow className="flex justify-center py-8">
                <CircularProgress />
              </TableRow>
            ) : data?.microlearnings.length ? (
              data?.microlearnings.map((item: any) => {
                return (
                  <Item
                    data={item}
                    key={item.id}
                    onClick={() =>
                      navigate(`/contributor/microlearning/${item.id}`)
                    }
                  />
                )
              })
            ) : (
              <TableRow className="flex justify-center py-8">
                <NoListComponent title="Sorry, No List" />
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={data?.microlearnings_aggregate?.aggregate?.count || 0}
          rowsPerPage={limit}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={onLimitChange}
        />
      </TableContainer>
    </Paper>
  )
}

export default ContributorMicrolearningsPage
