import {Button, Dialog, Typography} from '@material-ui/core'

import {ModalActions, ModalContent, ModalHeader} from './Modal'

export interface ConfirmationModalProps {
  open?: boolean
  disabled?: boolean
  danger?: boolean
  title?: string
  message?: string
  confirmation?: string
  cancellation?: string
  onClose?: () => void
  onConfirm?: () => void
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  const {
    open = false,
    disabled = false,
    danger = false,
    title = 'Are you sure?',
    message = 'Are you sure you want to do this?',
    confirmation = 'Confirm',
    cancellation = 'Cancel',
    onClose,
    onConfirm,
  } = props

  const handleClose = disabled ? undefined : onClose

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <ModalHeader onClose={handleClose} disabled={disabled} white>
        <Typography className={danger ? 'text-[#ef4d5e]' : ''}>
          {title}
        </Typography>
      </ModalHeader>
      <ModalContent dividers>
        <Typography>{message}</Typography>
      </ModalContent>
      <ModalActions>
        <Button disabled={disabled} onClick={onClose}>
          {cancellation}
        </Button>

        <Button
          disabled={disabled}
          onClick={onConfirm}
          variant="contained"
          color="primary"
          className={danger ? 'bg-[#ef4d5e]' : ''}
        >
          {confirmation}
        </Button>
      </ModalActions>
    </Dialog>
  )
}

export default ConfirmationModal
