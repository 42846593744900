import {
  Button,
  CircularProgress,
  Divider,
  InputAdornment,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { type ChangeEvent } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import SearchIcon from '@material-ui/icons/Search'
import { useTitle } from 'hoofd'
import { Filter } from '@smartasn/wlb-utils-components'
import StarIcon from '@material-ui/icons/Star'
import {
  capitalize,
  convertToRupiah,
  mapFilterResults,
} from '../../../utils/helpers'
import { format } from 'date-fns'
import { USER_ID } from '../../../utils/globals'
import { useQuery } from '@apollo/client'
import { GET_CONTRIBUTOR_MY_BOOKS } from '../../../graphql/queries'
import NoListComponent from '../../../components/empty-state/NoList.component'
import LinesEllipsis from 'react-lines-ellipsis'
import { INSTRUCTOR_CONTEXT } from '../../../utils/contributor-helpers'

interface Book {
  id: number
  title: string
  status: string
  thumbnail: string
  date_added: Date
  isbn: string
  enrolled: {
    aggregate: {
      count: number
    }
  }
  this_month: {
    aggregate: {
      count: number
    }
  }
  pricing: {
    IDR: string
  }
  pricing_idr: number
  average_rating: number
  total_rating: {
    aggregate: {
      count: number
    }
  }
}
interface VariableInterface {
  bookType: number
  limit: number
  offset: number
  userId: string
  search: string
  dateLastmonth: string
  orderBy: {
    date_added: string
  }
  createdDate: {
    _lte: null
    _gte: null
  }
  status?: string[]
}

export const listFilter = [
  {
    name: 'Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          label: 'Active',
          value: 'active',
        },
        {
          label: 'Pending',
          value: 'pending',
        },
        {
          label: 'Draft',
          value: 'draft',
        },
      ],
    },
  },
  {
    name: 'Created Date',
    fieldName: 'date',
    type: 'date',
  },
]

const sortOptions = [
  { label: 'Latest', value: { date_added: 'desc' } },
  { label: 'Oldest', value: { date_added: 'asc' } },
  { label: 'Highest Rating', value: { average_rating: 'desc_nulls_last' } },
  { label: 'Lowest Rating', value: { average_rating: 'asc_nulls_first' } },
  {
    label: 'Most Enrolled',
    value: {
      academy_book_owners_aggregate: {
        count: 'desc',
      },
    },
  },
  {
    label: 'Fewest Enrolled',
    value: {
      academy_book_owners_aggregate: {
        count: 'asc',
      },
    },
  },
]

type BookProps = {
  type: string
}

type ItemProps = {
  data: Book
  to: string
}

const Item = (props: ItemProps) => {
  const defaultThumbnail = require('../../../assets/images/defaultCourseCover.image.png')
  const { data, to } = props

  return (
    <TableRow
      component={Link}
      to={to}
      className="cursor-pointer hover:bg-[#f5f5f5] transition"
    >
      <TableCell className="w-24 pr-0">
        <img
          src={data.thumbnail || defaultThumbnail}
          alt="book"
          className="h-28"
        />
      </TableCell>
      <TableCell className="w-[28%]">
        <div className="flex h-full flex-col justify-between">
          <div className="flex flex-col gap-2">
            <LinesEllipsis
              text={data.title}
              maxLine="2"
              ellipsis="..."
              trimRight
              className="text-sm font-semibold"
            />

            <p className="text-xs text-gray-400">
              Created {format(new Date(data.date_added), 'MMM d, yyyy')}
            </p>
          </div>
          <div className="flex justify-between items-center">
            <div
              className={
                data.status === 'active'
                  ? 'text-green-500'
                  : data.status === 'pending'
                    ? 'text-red-500'
                    : 'text-gray-400'
              }
            >
              {capitalize(data.status)}
            </div>
            <p className="text-xs text-gray-400">
              {data.pricing_idr
                ? convertToRupiah(data.pricing_idr, true)
                : 'FREE'}
            </p>
          </div>
        </div>
      </TableCell>
      <TableCell>
        {/* <div className="flex h-full flex-col justify-between">
          <div>
            <div className="text-gray-400 w-max">Earned this month</div>
            <div className="font-semibold text-base">
              {convertToRupiah(
                data.this_month.aggregate.count * data.pricing_idr,
                true
              )}
            </div>
          </div>
          <div>
            <div className="text-gray-400 w-max">Total Earned</div>
            <div className="font-semibold text-base">
              {convertToRupiah(
                data.enrolled.aggregate.count * data.pricing_idr,
                true
              )}
            </div>
          </div>
        </div> */}
      </TableCell>
      <TableCell>
        <div className="flex h-full flex-col justify-between">
          <div>
            <div className="text-gray-400 w-max">Rating</div>
            <div className="flex flex-row items-center gap-1">
              <StarIcon className="text-[#039be5]" fontSize="small">
                star
              </StarIcon>
              <div className="font-semibold text-base">
                {(data.average_rating || 0).toFixed(1)}
              </div>
            </div>
          </div>
          <div>
            <div className="text-gray-400 w-max">Total Rating</div>
            <div className="font-semibold text-base">
              {data.total_rating.aggregate.count}
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell>
        <div className="h-full">
          <p className="text-xs text-gray-400">Enrolled</p>
          <p>{data.enrolled.aggregate.count}</p>
        </div>
      </TableCell>
    </TableRow>
  )
}

const ContributorBooksPage = ({ type }: BookProps) => {
  useTitle(`Contributor - ${type === 'ebook' ? 'E-Book' : capitalize(type)}`)
  const dateNow = format(new Date(), 'yyyy-MM-dd')
  const [limit, setLimit] = React.useState(10)
  const [page, setPage] = React.useState(0)
  const [orderBy, setOrderBy] = React.useState({ date_added: 'desc' })
  const [filter, setFilter] = React.useState({
    date: {
      _lte: null,
      _gte: null,
    },
    status: [],
  })
  const [searchParams, setSearchParams] = useSearchParams()

  const searchTimeoutRef = React.useRef<any>()

  const search = searchParams.get('query') ?? ''

  const variables: VariableInterface = {
    bookType: type === 'ebook' ? 2 : 3,
    limit: limit,
    offset: page * limit,
    userId: USER_ID,
    search: `%${search}%`,
    dateLastmonth: dateNow,
    orderBy,
    createdDate: filter.date,
    status: filter.status,
  }

  if (!filter.status.length) {
    delete variables.status
  }

  const { data, loading } = useQuery(GET_CONTRIBUTOR_MY_BOOKS, {
    variables,
    context: INSTRUCTOR_CONTEXT,
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const handleOrderByChange = (value: any) => {
    setOrderBy(JSON.parse(value))
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const onLimitChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLimit(Number(e.target.value))
  }

  const handleFilterApply = (result: any) => {
    const mappedResult: any = mapFilterResults(result[0])

    if (!Object.keys(mappedResult).length) {
      setFilter({
        date: {
          _lte: null,
          _gte: null,
        },
        status: [],
      })
    } else {
      if (mappedResult.date) {
        setFilter({
          ...filter,
          ...mappedResult,
          date: {
            _gte: mappedResult.date.from,
            _lte: mappedResult.date.to,
          },
        })
      } else {
        setFilter({ ...filter, ...mappedResult })
      }
    }
  }

  return (
    <Paper>
      <div className="px-6 h-[92px] flex items-center justify-between">
        <Typography color="primary" className="font-bold">
          My {type === 'ebook' ? 'E-Book' : capitalize(type)}
        </Typography>
        <Button
          component={Link}
          to="create"
          variant="contained"
          color="primary"
          disableElevation
          className="px-9 py-[14px]"
        >
          Create {type === 'ebook' ? 'E-Book' : type}
        </Button>
      </div>
      <Divider />
      <div className="px-6 pb-4 pt-6">
        <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          autoFocus
          onChange={handleSearchChange}
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <Divider />
      <div className="flex items-center justify-between px-2 py-5">
        <Select
          value={JSON.stringify(orderBy)}
          variant="standard"
          disableUnderline
          onChange={(e) => handleOrderByChange(e.target.value)}
          SelectDisplayProps={{
            className:
              'text-sm font-semibold pr-6 pl-4 py-2 focus:bg-white outline-none rounded transition',
          }}
        >
          {sortOptions.map((option: any, idx: number) => {
            return (
              <MenuItem value={JSON.stringify(option.value)} key={idx}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>

        <Filter
          onApply={handleFilterApply}
          listFilter={listFilter}
          includeExclude={false}
        >
          <Button className="font-semibold">Filter</Button>
        </Filter>
      </div>
      {search && (
        <div className="px-6 pb-4">
          <Typography color="textSecondary" className="text-sm">
            Showing {data?.academy_books_aggregate.aggregate.count || 0} results
            based on your search &quot;
            <span className="whitespace-pre-wrap">{search}</span>&quot;
          </Typography>
        </div>
      )}
      <Divider />
      <TableContainer className="overflow-x-visible">
        <Table className="h-full">
          <TableBody className="h-full">
            {loading ? (
              <TableRow className="flex justify-center py-8">
                <CircularProgress />
              </TableRow>
            ) : data?.academy_books.length > 0 ? (
              data.academy_books.map((data: Book) => (
                <Item data={data} key={data.id} to={`${data.isbn}`} />
              ))
            ) : (
              <TableRow className="flex justify-center py-8">
                <NoListComponent title="Sorry, No List" />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={data?.academy_books_aggregate?.aggregate?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={onLimitChange}
      />
    </Paper>
  )
}

export default ContributorBooksPage
