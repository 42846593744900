import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  GET_MY_MICRO_LEARNING,
  GET_RECOMMENDATION_TRENDING_MICRO_LEARNING,
  GET_ALL_MICROLEARNING,
  GET_RECOMMEND_CATEGORY_MICROLEARNING,
  GET_MICRO_LEARNING_DETAIL,
  GET_MICRO_LEARNING_WISHLIST,
  GET_OWNED_MICRO_LEARNING,
  GET_RELATED_MICRO_LEARNING,
  GET_REVIEW_MICRO_LEARNING,
  GET_LIST_REVIEW_MICRO,
  GET_ANALYTIC_MICRO,
  GET_MY_REVIEW_MICRO,
  GET_QUESTION_REVIEW_MICRO,
  GET_CONTRIBUTOR_INFO_SHORT,
} from '../../graphql/queries'
import {DELETE_MICROLEARNING_FROM_WISHLIST} from '../../graphql/mutations'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../utils/globals'
import {PriceType} from '../../interfaces'
import {
  INSERT_MICRO_WISHLIST,
  INSERT_MY_REVIEW_MICRO,
  INSERT_SETTING_MICRO,
  REMOVE_MICRO_WISHLIST,
} from '../../graphql/mutations/micro-learning/microWishlist.mutation'

export const MY_MICROLEARNING = (limit: number = 4, offset: number = 0) => {
  return useQuery(GET_MY_MICRO_LEARNING, {
    variables: {
      userId: USER_ID,
      limit,
      offset,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const RECOMMENDED_MICROLEARNING = (
  limit: number = 4,
  offset: number = 0,
  search?: string,
  rating?: number,
  min_price?: string,
  max_price?: string,
  language?: number[],
  category_id?: string | number[],
  level?: number[]
) => {
  let variables: {
    limit?: number
    offset?: number
    sortByTrending?: boolean
    isRecommended?: boolean
    search?: string
    rating?: number
    min_price?: number
    max_price?: number
    language?: number[]
    category_id?: string | number[]
    level?: number[]
    free?: boolean
    paid?: boolean
  } = {
    limit,
    offset,
    sortByTrending: false,
    isRecommended: true,
    search,
    rating,
    min_price: Number(min_price),
    max_price: Number(max_price),
    language,
    category_id,
    level,
  }

  if (min_price === '' || max_price === '') {
    variables = {
      ...variables,
      free: true,
      paid: true,
    }
  }

  if (min_price === '0' || max_price === '0') {
    variables = {
      ...variables,
      free: true,
      paid: false,
    }
  }

  if (!category_id || category_id.length === 0) {
    delete variables.category_id
  }

  return useQuery(GET_RECOMMENDATION_TRENDING_MICRO_LEARNING, {
    variables,
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })
}

export const TRENDING_MICROLEARNING = (
  limit: number = 4,
  offset: number = 0,
  search?: string,
  rating?: number,
  min_price?: string,
  max_price?: string,
  language?: number[],
  category_id?: string | number[],
  level?: number[]
) => {
  let variables: {
    limit?: number
    offset?: number
    sortByTrending?: boolean
    isRecommended?: boolean
    search?: string
    rating?: number
    min_price?: number
    max_price?: number
    language?: number[]
    category_id?: string | number[]
    level?: number[]
    free?: boolean
    paid?: boolean
  } = {
    limit,
    offset,
    sortByTrending: true,
    isRecommended: false,
    search,
    rating,
    min_price: Number(min_price),
    max_price: Number(max_price),
    language,
    category_id,
    level,
  }

  if (min_price === '' || max_price === '') {
    variables = {
      ...variables,
      free: true,
      paid: true,
    }
  }

  if (min_price === '0' || max_price === '0') {
    variables = {
      ...variables,
      free: true,
      paid: false,
    }
  }

  if (!category_id || category_id.length === 0) {
    delete variables.category_id
  }

  return useQuery(GET_RECOMMENDATION_TRENDING_MICRO_LEARNING, {
    variables,
    context: {
      headers: {
        'X-Hasura-Role': 'organization-staff',
      },
    },
  })
}

export const ALL_MICROLEARNING = (
  limit: number,
  offset: number,
  search: string = '',
  priceLte: number,
  priceGte: number,
  categoryIds: number[] = [],
  levels: number[],
  languageIds: number[],
  ratingGt?: number
) => {
  const variables: {
    limit?: number
    offset?: number
    query?: string
    price?: PriceType
    categoryIds?: number[]
    levels?: number[]
    languageIds?: number[]
    ratingGt?: number
  } = {
    limit,
    offset,
    query: `%${search}%`,
    price: {
      _lte: priceLte,
      _gte: priceGte,
    },
    categoryIds,
    levels,
    languageIds,
    ratingGt,
  }

  if (limit === -1) {
    delete variables.limit
    delete variables.offset
  }

  if (!search) {
    delete variables.query
  }

  if (!priceLte || !priceGte) {
    delete variables.price
  }
  if (categoryIds.length === 0 || categoryIds.includes(0)) {
    delete variables.categoryIds
  }

  if (levels.length === 0) {
    delete variables.levels
  }

  if (languageIds.length === 0) {
    delete variables.languageIds
  }

  if (!ratingGt) {
    delete variables.ratingGt
  }

  return useQuery(GET_ALL_MICROLEARNING, {
    variables,
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const RECOMMENDED_CATEGORY_MICROLEARNING = (userId: string) => {
  return useQuery(GET_RECOMMEND_CATEGORY_MICROLEARNING, {
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const DELETE_MICROLEARNING_WISHLIST = () => {
  return useMutation(DELETE_MICROLEARNING_FROM_WISHLIST)
}

export const MICRO_LEARNING_BY_ID = (id?: string) => {
  return useQuery(GET_MICRO_LEARNING_DETAIL, {
    variables: {
      id: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const CONTRIBUTOR_MICRO_LEARNING_DETAILS = (
  id: string,
  skipFetching: boolean
) => {
  return useQuery(GET_CONTRIBUTOR_INFO_SHORT, {
    variables: {
      userId: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    skip: skipFetching,
  })
}

export const MICRO_LEARNING_WISHLIST = (id?: string) => {
  return useQuery(GET_MICRO_LEARNING_WISHLIST, {
    variables: {
      id: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const CHECK_OWNED_MICROLEARNING = (id?: string) => {
  return useQuery(GET_OWNED_MICRO_LEARNING, {
    variables: {
      userId: USER_ID,
      id: id,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

type RelatedMicroParam = {
  categoryId: string
  limit?: number
  offset?: number
}

export const MICRO_LEARNING_BY_CATEGORY = ({
  categoryId,
  limit,
  offset,
}: RelatedMicroParam) => {
  return useQuery(GET_RELATED_MICRO_LEARNING, {
    variables: {
      category_id: categoryId,
      limit: limit,
      offset: offset,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const REVIEW_MICRO_LEARNING = (microlearningId: string) => {
  return useQuery(GET_REVIEW_MICRO_LEARNING, {
    variables: {
      microlearningId: microlearningId,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

type ListMicroReviewParam = {
  microlearningId: string
  limit?: number
  offset?: number
  star?: number | null
}

export const LIST_REVIEW_MICRO_LEARNING = ({
  microlearningId,
  limit,
  offset,
  star,
}: ListMicroReviewParam) => {
  return useQuery(GET_LIST_REVIEW_MICRO, {
    variables: {
      microlearningId: microlearningId,
      limit: limit,
      offset: offset,
      star: star,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const MY_REVIEW_MICRO = (microlearningId: string) => {
  return useQuery(GET_MY_REVIEW_MICRO, {
    variables: {
      microlearningId: microlearningId,
      userId: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const ANALYTIC_MICRO_LEARNING = (microlearningId: string) => {
  return useQuery(GET_ANALYTIC_MICRO, {
    variables: {
      id: microlearningId,
      userId: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const QUESTION_REVIEW_MICRO = (microlearningId: string) => {
  return useQuery(GET_QUESTION_REVIEW_MICRO, {
    variables: {
      microlearningId: microlearningId,
      userId: USER_ID,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

// Mutation
export const ADD_MICRO_WISHLIST = () => {
  return useMutation(INSERT_MICRO_WISHLIST, HASURA_ROLE_USER_CONTEXT)
}

export const DELETE_MICRO_WISHLIST = () => {
  return useMutation(REMOVE_MICRO_WISHLIST, HASURA_ROLE_USER_CONTEXT)
}

export const ADD_MY_REVIEW_MICRO = () => {
  return useMutation(INSERT_MY_REVIEW_MICRO, HASURA_ROLE_USER_CONTEXT)
}

export const ADD_SETTING_MICRO = () => {
  return useMutation(INSERT_SETTING_MICRO, HASURA_ROLE_USER_CONTEXT)
}
