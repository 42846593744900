import {FC, ReactElement, useState} from 'react'
import {IQuestionReview} from '../../../../interfaces'
import QuestionReviewContent from './QuestionReviewContent.component'
import QuestionReviewSidebar from './QuestionReviewSidebar.component'

const QuestionReviewDetail: FC<IQuestionReview> = (
  props: IQuestionReview
): ReactElement => {
  const {
    microMembers,
    microQuestion,
    microQuestionLocked,
    people,
    questionCount,
  } = props
  const [selectQuestion, setSelectQuestion] = useState({
    locked: microQuestionLocked?.length > 0 && microQuestion?.length === 0,
    number: 0,
  })

  const selectedQuestion = selectQuestion?.locked
    ? microQuestionLocked
    : microQuestion

  const questionSelected = selectedQuestion[selectQuestion.number]
  const questionNumber = selectQuestion.number + 1
  const questionAnsCount = microQuestion?.length ?? 0
  const questionLockAnsCount = microQuestionLocked?.length ?? 0
  const progress =
    Math.round((microQuestion?.length / questionCount) * 100) || 0

  const dataContent = {
    microMembers,
    questionSelected,
    questionNumber,
    questionAnsCount,
    questionLockAnsCount,
    people,
    questionCount,
    progress,
    selectQuestion,
    setSelectQuestion,
  }

  const dataSidebar = {
    questionAnsCount,
    microQuestion,
    microQuestionLocked,
    selectQuestion,
    setSelectQuestion,
  }

  return (
    <div className="flex flex-row justify-between">
      <QuestionReviewSidebar {...dataSidebar} />
      <QuestionReviewContent {...dataContent} />
    </div>
  )
}

export default QuestionReviewDetail
