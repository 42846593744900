import React, {ChangeEvent} from 'react'

import Header from '../../components/header/HeaderComponent'
import {
  TabsWrapper,
  StyledTab,
  StyledTabs,
} from '../../components/utils-components/GlobalStyles'
import styled from 'styled-components'
import {
  LeftSideWrapper,
  MainWrapper,
} from '../../components/job-portal/JobPortalStyles'
import {
  SearchFilterSidebar,
  SearchFilterTab,
} from '../../components/learner/search-filter'
import {LearningType} from '../../utils/enum'
import {Typography} from '@material-ui/core'
import {useTitle} from 'hoofd'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import {useNavigate, useParams} from 'react-router-dom'
import LearnerSearchForm from '../../components/learner/catalog/LearnerSearchForm.component'
import {LearnerAcademicProvider} from '../../provider/Academic.provider'

type SearchFilterProps = {
  activeTab?: LearningType
}

type AcademicFilter = {
  rating: string
  categoriesREST: string
  levelsREST: string
  languagesREST: string
  price: string
  priceMin: string
  priceMax: string
  priceGQL: {
    _lte: number
    _gte: number
  }
  categoriesGQL: string | number[]
  ratingGQL: never[]
  contributorLabels: never[]
  levelsGQL: never[]
  languagesGQL: never[]
}

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const AppWrapper = styled.div`
  width: 100%;
`

const SearchFilter = (props: SearchFilterProps) => {
  const navigate = useNavigate()
  const {keyword} = useParams()
  const [searchKeyword, setSearchKeyword] = React.useState(keyword || '')
  const [academicFilter, setAcademicFilter] = React.useState<AcademicFilter>({
    rating: '',
    categoriesREST: '',
    levelsREST: '',
    languagesREST: '',
    price: '',
    priceMin: '',
    priceMax: '',
    priceGQL: {
      _lte: 0,
      _gte: 0,
    },
    categoriesGQL: [],
    ratingGQL: [],
    contributorLabels: [],
    levelsGQL: [],
    languagesGQL: [],
  })
  const [priceFilter, setPriceFilter] = React.useState<string>('Latest')
  const [contributorFilter, setContributorFilter] = React.useState<string>(
    'Rating (Low - High)'
  )

  const handleChangePriceFilter = (event: ChangeEvent<{value: any}>) => {
    setPriceFilter(event.target.value)
  }

  const handleChangeContributorFilter = (event: ChangeEvent<{value: any}>) => {
    setContributorFilter(event.target.value)
  }

  useTitle('Saring & Cari')

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab
            label="Katalog Pembelajaran"
            value="learning-catalog"
            fontSize="12px"
          />
        </StyledTabs>
      </TabsWrapper>
      <LearnerAcademicProvider>
        <MainWrapper>
          <LeftSideWrapper className="w-1/5">
            <SearchFilterSidebar
              activeTab={props.activeTab}
              academicFilter={academicFilter}
              setAcademicFilter={setAcademicFilter}
            />
          </LeftSideWrapper>
          <AppWrapper className="w-4/5">
            <div className="flex flex-1 flex-col px-5 mb-5">
              <div
                className="flex flex-1 flex-row  mb-5 cursor-pointer"
                onClick={() => navigate('/')}
              >
                <ArrowBackIcon className="cursor-pointer" />
                <Typography className="ml-3 font-bold">Cari Katalog</Typography>
              </div>

              <LearnerSearchForm
                initialQuery={keyword}
                searchKeyword={searchKeyword}
                setSearchKeyword={setSearchKeyword}
              />
            </div>

            <SearchFilterTab
              activeTab={props.activeTab}
              initialQuery={keyword}
              searchKeyword={searchKeyword}
              academicFilter={academicFilter}
              priceFilter={priceFilter}
              handleChangePriceFilter={handleChangePriceFilter}
              contributorFilter={contributorFilter}
              handleChangeContributorFilter={handleChangeContributorFilter}
            />
          </AppWrapper>
        </MainWrapper>
      </LearnerAcademicProvider>
    </>
  )
}

export default SearchFilter
