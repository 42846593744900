import Header from '../../components/header/HeaderComponent'
import {
  TabsWrapper,
  StyledTab,
  StyledTabs,
} from '../../components/utils-components/GlobalStyles'
import styled from 'styled-components'
import {MainWrapper} from '../../components/job-portal/JobPortalStyles'
import {useTitle} from 'hoofd'
import {useParams} from 'react-router-dom'
import {CourseDetail} from '../../components/learner/course'
import {COURSE_BY_SLUG} from '../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import BackNavigation from '../../components/navigation/BackNavigation.component'

const customStyle = {
  tabs: {
    background: 'rgb(246, 248, 249)',
    boxShadow:
      'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
  },
}

const AppWrapper = styled.div``

const Course = () => {
  const {slug} = useParams()

  const {data, isLoading, refetch} = COURSE_BY_SLUG(slug || '')

  useTitle(data?.title || '')

  if (isLoading) {
    return <Skeleton height={300} className="mx-16 my-40" width={'50vw'} />
  }

  return (
    <>
      <Header />
      <TabsWrapper>
        <StyledTabs
          value={'learning-catalog'}
          style={customStyle.tabs}
          centered
        >
          <StyledTab label="Katalog Pembelajaran" value="learning-catalog" />
        </StyledTabs>
      </TabsWrapper>
      <MainWrapper>
        <AppWrapper className="w-full flex flex-col">
          <BackNavigation label="Kembali" />
          <CourseDetail course={data} refetchCourseDetail={refetch} />
        </AppWrapper>
      </MainWrapper>
    </>
  )
}

export default Course
