import gql from 'graphql-tag'

export const GET_ORDERS = gql`
  query (
    $userId: uuid!
    $search: String
    $orderBy: order_by
    $offset: Int
    $limit: Int
    $statuses: [String!]
    $priceFrom: float8
    $priceTo: float8
    $dateFrom: timestamp
    $dateTo: timestamp
  ) {
    global_invoices_aggregate(
      where: {
        global_wallet_vendor: {global_users: {id: {_eq: $userId}}}
        module_name: {_nin: ["marketplace_products"]}
        status: {_in: $statuses}
        final_price: {_gte: $priceFrom, _lte: $priceTo}
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        _or: [{invoice_to: {_ilike: $search}}, {code: {_ilike: $search}}]
        _not: {
          _and: [
            {status: {_eq: "pending"}}
            {global_payment: {payment_method: {_neq: "direct_transfer"}}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }

    global_invoices(
      where: {
        global_wallet_vendor: {global_users: {id: {_eq: $userId}}}
        module_name: {_nin: ["marketplace_products"]}
        status: {_in: $statuses}
        final_price: {_gte: $priceFrom, _lte: $priceTo}
        date_added: {_gte: $dateFrom, _lte: $dateTo}
        _or: [{invoice_to: {_ilike: $search}}, {code: {_ilike: $search}}]
        _not: {
          _and: [
            {status: {_eq: "pending"}}
            {global_payment: {payment_method: {_neq: "direct_transfer"}}}
          ]
        }
      }
      offset: $offset
      limit: $limit
      order_by: {date_added: $orderBy}
    ) {
      id
      code
      status
      date_added
      invoice_to
      final_price
    }
  }
`

export const GET_ORDER_DETAILS = gql`
  query ($invoiceId: uuid!) {
    global_invoices_by_pk(id: $invoiceId) {
      id
      code
      status
      invoice_to
      final_price
      date_added
      global_wallets {
        global_users {
          id
          name
        }
      }
      global_payment {
        id
        global_direct_transfer {
          id
          global_direct_transfer_proofs {
            id
            name
            type
            file
            date_added
          }
        }
      }
      global_invoice_items {
        id
        item_table
        item_table_id
        itemname
        qty
        single_price
        type
        thumbnail
        weight
      }
    }
  }
`
