import {Box, Modal} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {ArcElement, Chart as ChartJS, Tooltip} from 'chart.js'
import {FC} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {CustomTypography} from '../../../components/utils-components/GlobalStyles'
import {IDiaryResume} from '../../../interfaces/LearningDiary.interface'
import {formattedTimeConvert} from '../../../utils/helpers'
import {emptyDoughnoutData} from '../../../config/chart.config'
import {LEARNING_DIARY_ACTIVITY} from '../../../utils/constans'

ChartJS.register(ArcElement, Tooltip)

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
}

type TDetailDiaryResume = {
  open: boolean
  handleClose: () => void
  data: IDiaryResume | null
}

const DetailLearningDiaryResume: FC<TDetailDiaryResume> = ({
  open,
  handleClose,
  data,
}) => {
  const courseDuration = data?.count_course?.aggregate?.sum?.duration
  const ebookDuration = data?.count_ebook?.aggregate?.sum?.duration
  const audiobookDuration = data?.count_audiobook?.aggregate?.sum?.duration
  const otherDuration = data?.count_others?.aggregate?.sum?.duration

  const countData = [
    courseDuration,
    ebookDuration,
    audiobookDuration,
    otherDuration,
  ]

  const isChartEmpty = countData.every((res) => !res)

  const chartData = isChartEmpty
    ? emptyDoughnoutData
    : {
        labels: [
          LEARNING_DIARY_ACTIVITY.Course,
          LEARNING_DIARY_ACTIVITY['E-Book'],
          LEARNING_DIARY_ACTIVITY.Audiobook,
          LEARNING_DIARY_ACTIVITY.Others,
        ],
        datasets: [
          {
            data: countData,
            backgroundColor: ['#039be5', '#ef4d5e', '#ffa000', '#4caf50'],
            borderColor: ['#039be5', '#ef4d5e', '#ffa000', '#4caf50'],
            borderWidth: 1,
          },
        ],
      }

  const items = [
    {
      id: 1,
      color: '#039be5',
      name: LEARNING_DIARY_ACTIVITY.Course,
      number: formattedTimeConvert(courseDuration, true),
    },
    {
      id: 2,
      color: '#ef4d5e',
      name: LEARNING_DIARY_ACTIVITY['E-Book'],
      number: formattedTimeConvert(ebookDuration, true),
    },
    {
      id: 3,
      color: '#ffa000',
      name: LEARNING_DIARY_ACTIVITY.Audiobook,
      number: formattedTimeConvert(audiobookDuration, true),
    },
    {
      id: 4,
      color: '#4caf50',
      name: LEARNING_DIARY_ACTIVITY.Others,
      number: formattedTimeConvert(otherDuration, true),
    },
  ]

  const customLabel = (context: any) =>
    `${context.label}: ${formattedTimeConvert(context.parsed, true)}`

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{style: {backgroundColor: '#0000001a'}}}
    >
      <Box sx={style} className="w-96 lg:w-[700px]">
        <div className="bg-[#eff2f4] px-6 py-7 flex items-center justify-between">
          <CustomTypography fsize="16px" fweight="bold">
            {data?.name}
          </CustomTypography>
          <button onClick={handleClose}>
            <Close style={{color: '#a9a8a8', fontSize: '30px'}} />
          </button>
        </div>
        <div className="mt-7 px-6 pb-7">
          <div className="lg:flex lg:items-center pl-6 py-6">
            <div className="w-auto h-auto lg:w-1/2 lg:h-80 relative px-2 lg:px-0">
              <Doughnut
                data={chartData}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  cutout: '80%',
                  plugins: {
                    legend: {
                      position: 'right',
                      display: false,
                    },
                    tooltip: {
                      enabled: !isChartEmpty,
                      callbacks: {
                        label: customLabel,
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="w-full lg:px-8">
              {items.map((res) => (
                <div className="flex justify-between px-7 mb-6" key={res.id}>
                  <div className="flex gap-6">
                    <div
                      className={`w-4 h-4 bg-transparent border-4 rounded-full`}
                      style={{borderColor: res.color}}
                    />
                    <CustomTypography fsize="14px">{res.name}</CustomTypography>
                  </div>
                  <CustomTypography fsize="14px" fcolor="#a9a8a8">
                    {res.number}
                  </CustomTypography>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default DetailLearningDiaryResume
