import {Divider, Tab, Tabs} from '@material-ui/core'
import {ChangeEvent, FC, ReactElement, useEffect, useState} from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import ContributorCard from '../catalog/card/ContributorCard.component'
import {LoadingBox} from './MicroLearning.style'
import TabAboutMicro from './tabs/TabAboutMicro.component'
import TabReviewMicro from './tabs/TabReviewMicro.component'
import MicroLearningTitle from './MicroLearningTitle.component'
import RelatedMicroLearning from './RelatedMicroLearning.component'
import AnalyticMicroLearning from './AnalyticMicroLearning.component'
import {
  ADD_MICRO_WISHLIST,
  ADD_SETTING_MICRO,
  ANALYTIC_MICRO_LEARNING,
  CHECK_OWNED_MICROLEARNING,
  CONTRIBUTOR_MICRO_LEARNING_DETAILS,
  DELETE_MICRO_WISHLIST,
  MICRO_LEARNING_BY_ID,
  MICRO_LEARNING_WISHLIST,
} from '../../../provider/services/MicroLearning.service'
import LoadingCircular from '../../loading/LoadingCircular.component'
import {useSnackbar} from 'notistack'
import CheckoutCard, {CartItem} from '../catalog/card/CheckoutCard.component'
import {IMicroWishlist, ISettingMicro} from '../../../interfaces'
import ConfirmationModal from '../../modal/ConfirmationModal'

const MicroLearningDetail: FC = (): ReactElement => {
  const navigate = useNavigate()

  const {id} = useParams()
  const {enqueueSnackbar} = useSnackbar()
  const [tab, setTab] = useState(0)
  const [authorId, setAuthorId] = useState(null)
  const [skipFetchingContributorMicro, setSkipFetchingContributorMicro] =
    useState(true)

  const [isAcquiredOpen, setIsAcquiredOpen] = useState(false)

  const {
    data: microDetailData,
    error: microDetailError,
    refetch: microDetailRefetch,
  } = MICRO_LEARNING_BY_ID(id || '')

  const {data: contributorData, loading: contributorLoading} =
    CONTRIBUTOR_MICRO_LEARNING_DETAILS(
      authorId || '',
      skipFetchingContributorMicro
    )

  const {data: microWishlistedData, error: microWishlistedError} =
    MICRO_LEARNING_WISHLIST(id || '')

  const {
    data: getOwnedMicroData,
    loading: getOwnedMicroLoading,
    refetch: getOwnedMicroRefetch,
  } = CHECK_OWNED_MICROLEARNING(id || '')

  const {
    data: analyticMicroData,
    loading: analyticMicroLoading,
    refetch: analyticMicroRefetch,
  } = ANALYTIC_MICRO_LEARNING(id || '')

  const [addToWishlist] = ADD_MICRO_WISHLIST()

  const [removeFromWishlist] = DELETE_MICRO_WISHLIST()

  const [addSettingMicro] = ADD_SETTING_MICRO()

  const microData = microDetailData?.microlearnings?.[0]
  const isMyMicroLearning = getOwnedMicroData?.microlearnings?.[0]?.id

  useEffect(() => {
    if (microData) {
      setAuthorId(microData?.global_user?.id)
      setSkipFetchingContributorMicro(false)
    }
  }, [microData])

  if (
    (microDetailData && microDetailError) ||
    (microWishlistedData && microWishlistedError)
  ) {
    return (
      <LoadingBox>
        <LoadingCircular />
      </LoadingBox>
    )
  }

  const addToWishlistMutation = () => {
    const object = {
      microlearning: id,
      name: microData?.name,
      description: microData?.description,
      price: microData?.price,
      icon_url: microData?.icon_url,
      average_rating: microData?.average_rating,
      review_count: microData?.review_count,
      question_count: microData?.question?.aggregate?.count,
    }

    addToWishlist({
      variables: {object},
    })
      .then(() => {
        enqueueSnackbar('Micro Learning added to wishlist', {
          variant: 'success',
        })
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar(
          'Added micro learning to wishlist failed, please try again later',
          {
            variant: 'error',
          }
        )
      })
  }

  const RemoveFromWishlistMutation = () => {
    removeFromWishlist({
      variables: {microlearning: id},
    })
      .then(() => {
        enqueueSnackbar('Micro Learning removed from wishlist', {
          variant: 'success',
        })
      })
      .catch((error: any) => {
        console.error(`graphql: ${error}`)

        enqueueSnackbar(
          'Removed micro learning from wishlist failed, please try again later',
          {
            variant: 'error',
          }
        )
      })
  }

  const settingMicroLearning = (variable: ISettingMicro) => {
    if (variable?.dailyQuestion === 0) {
      enqueueSnackbar('Please input minimal 1 question', {
        variant: 'error',
      })
    } else {
      addSettingMicro({
        variables: variable,
      })
        .then(() => {
          enqueueSnackbar('Schedule Updated', {
            variant: 'success',
          })
          analyticMicroRefetch()
        })
        .catch((error: any) => {
          console.error(`graphql: ${error}`)

          enqueueSnackbar('Update Schedule failed, please try again later', {
            variant: 'error',
          })
        })
    }
  }

  const handleFreeAcquisition = () => {
    // refetch microlearning detail just to be sure
    getOwnedMicroRefetch()
    microDetailRefetch()

    setIsAcquiredOpen(true)
  }

  const analyticData = analyticMicroData?.microlearnings?.[0]
  const memberAnalyticData = analyticData?.microlearning_members?.[0]
  const progress =
    Math.round(
      (analyticData?.question?.aggregate?.count /
        analyticData?.question_count) *
        100
    ) || 0
  const isAnswered = progress === 100
  const correct =
    memberAnalyticData?.score !== 0
      ? Math.round(
          (analyticData?.question_count * memberAnalyticData?.score) / 100
        )
      : 0
  const wrong =
    memberAnalyticData?.question_answered_count !== 0
      ? analyticData?.question?.aggregate?.count - correct
      : analyticData?.question?.aggregate?.count

  const isMicroWishlisted = Boolean(
    microWishlistedData?.microlearning_wishlists?.find(
      (item: IMicroWishlist) =>
        item?.microlearningByMicrolearning?.id === microData?.id
    )
  )

  const dataMicroTitle = {
    name: microData?.name ?? '-',
    microImage: microData?.icon_url ?? null,
    autor: microData?.global_user?.name ?? '-',
    averageRating: microData?.average_rating ?? 0,
    countReviews: microData?.review_count ?? 0,
    wishlist: isMyMicroLearning ? undefined : isMicroWishlisted,
    isOwned: isMyMicroLearning,
    dailyQuestion: memberAnalyticData?.daily_question,
    schedule: memberAnalyticData?.schedule,
    scheduleTime: memberAnalyticData?.schedule_time,
    settingMicroLearning: settingMicroLearning,
    addtoWishlist: addToWishlistMutation,
    removeFromWishlist: RemoveFromWishlistMutation,
    allowSetting:
      analyticMicroData?.microlearnings[0]?.microlearning_members[0]
        ?.user_settings !== false,
  }

  const dataMicroAbout = {
    countQuestions: microData?.question?.aggregate?.count ?? null,
    language: microData?.global_language?.name ?? '-',
    category: microData?.category_sub?.cat_parent?.name ?? '-',
    subCategory: microData?.category_sub?.name ?? '-',
    level: microData?.level ?? '-',
    description: microData?.description ?? '-',
    answerQuestion: memberAnalyticData?.schedule,
    time: memberAnalyticData?.schedule_time,
    isOwned: isMyMicroLearning,
  }

  const dataRelatedMicro = {
    categoryId: microData?.category_id || '',
  }

  const dataReviewMicro = {
    microlearningId: id || '',
    isOwned: isMyMicroLearning,
    titleRefetch: microDetailRefetch,
  }

  const dataAnalytic = {
    name: microData?.name ?? '-',
    progress: progress,
    questionAnsweredCount: analyticData?.question?.aggregate?.count,
    questionCount: analyticData?.question_count,
    score: memberAnalyticData?.score,
    isAnswered: isAnswered,
    correct: correct,
    wrong: wrong,
    loadingAnalytic: analyticMicroLoading,
  }

  const dataCart: CartItem = {
    item_id: microData?.id,
    item_table: 'microlearnings',
    item_object: {
      name: microData?.name,
      image: microData?.icon_url,
      price: microData?.price || 0,
      weight: 0,
    },
    item_vendor: microData?.global_user?.name,
    item_vendor_id: microData?.global_user?.id,
    item_vendor_table: 'global_users',
    item_quantity: 1,
  }

  const changeTab = (_event: ChangeEvent<{}>, newActiveTab: number) => {
    setTab(newActiveTab)
  }

  return (
    <div id="micro-learning-detail">
      <div
        id="micro-detail-wrap"
        className="flex flex-col sm:flex-col md:flex-col lg:flex-row justify-between"
      >
        <div id="left-side" className="basis-[74%]">
          <MicroLearningTitle {...dataMicroTitle} />
          {isMyMicroLearning && (
            <div id="analytic-micro-learning" className="mt-10">
              <AnalyticMicroLearning {...dataAnalytic} />
              <Divider />
            </div>
          )}

          <div id="tab-micro-learning" className="mt-10">
            <Tabs
              value={tab}
              onChange={changeTab}
              indicatorColor="secondary"
              textColor="secondary"
              TabIndicatorProps={{
                style: {
                  height: 5,
                },
              }}
            >
              <Tab label="Tentang" />
              <Tab label="Ulasan" />
            </Tabs>

            <div className="mt-8">
              {tab === 0 && <TabAboutMicro {...dataMicroAbout} />}
              {tab === 1 && <TabReviewMicro {...dataReviewMicro} />}
            </div>
          </div>
        </div>
        <div id="right-side" className="basis-1/4">
          {!contributorLoading && contributorData && (
            <ContributorCard
              item={contributorData?.learning_contributor_ratings_by_pk}
              isLearningCatalogChat={true}
            />
          )}
          <div className="my-12">
            {!getOwnedMicroLoading && !isMyMicroLearning && (
              <CheckoutCard
                itemType="Micro Learning"
                cartData={dataCart}
                onFreeAcquisition={handleFreeAcquisition}
              />
            )}
          </div>
        </div>
      </div>
      <RelatedMicroLearning {...dataRelatedMicro} />

      <ConfirmationModal
        open={isAcquiredOpen}
        title="Pembelajaran Mikro berhasil diambil"
        message="Produk ini telah ditambahkan ke Pembelajaran Saya."
        cancellation="Kembali"
        confirmation="Pergi ke Pembelajaran Saya"
        onClose={() => setIsAcquiredOpen(false)}
        onConfirm={() => navigate(`/learner/my-learning/micro-learnings`)}
      />
    </div>
  )
}

export default MicroLearningDetail
