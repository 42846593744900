import {gql} from '@apollo/react-hooks'

export const GET_BOOK_REVIEWS = gql`
  query GetBookReviews(
    $isbn: String
    $rating: float8
    $limit: Int
    $offset: Int
  ) {
    academy_book_reviews_aggregate(
      where: {isbn: {_eq: $isbn}, rating: {_eq: $rating}}
    ) {
      aggregate {
        count
      }
    }

    academy_book_reviews(
      where: {isbn: {_eq: $isbn}, rating: {_eq: $rating}}
      order_by: {date_added: desc_nulls_last}
      limit: $limit
      offset: $offset
    ) {
      global_user {
        id
        name
        avatar
        email
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      id
      date_added
      review_text
      rating
      review_fields
      review_reply
      review_reply_date
      globalUserByReviewReplyUser {
        id
        name
        avatar
        email
      }
    }
  }
`

export const GET_BOOK_REVIEWS_AGGREGATE = gql`
  query GetBookReviewsAggregate($isbn: String) {
    academy_book_reviews_aggregate(where: {isbn: {_eq: $isbn}}) {
      aggregate {
        avg {
          rating
        }
        count
      }
    }

    academy_book_reviews(where: {isbn: {_eq: $isbn}}) {
      id
      rating
    }
  }
`

export const GET_MY_BOOK_REVIEWS = gql`
  query GetMyBookReviews($isbn: String, $user: uuid) {
    academy_book_reviews(where: {isbn: {_eq: $isbn}, user: {_eq: $user}}) {
      global_user {
        id
        name
        avatar
        email
        people_work_placements(where: {status: {_eq: "ACTIVE"}}) {
          id
          company_job_profile {
            id
            title
          }
          company_profile {
            id
            brand_name
            legal_name
          }
        }
      }
      id
      date_added
      review_text
      rating
    }
  }
`
