import CourseEnrollmentsCard from './CourseEnrollmentsCard'
import CourseActivityCard from './CourseActivityCard'
import CourseEarningsCard from './CourseEarningsCard'

const ContributorCourseDashboardPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <CourseEnrollmentsCard />

      <CourseActivityCard />
      <CourseEarningsCard />
    </div>
  )
}

export default ContributorCourseDashboardPage
