import JWP from '@jwplayer/jwplayer-react'
import SmartASNLogo from '../../../assets/images/smartasn-logo-horizontal.svg'

type JWPlayerType = {
  playlist: [any]
  onBeforePlay?: () => {}
  onPlay?: () => {}
  className?: string
}

export default function JWPlayer(props: JWPlayerType) {
  return (
    <div className={props.className || 'rounded mb-4 overflow-hidden'}>
      <JWP
        {...props}
        library="https://cdn.jwplayer.com/libraries/1gQfwd2J.js"
        config={{
          stretching: 'uniform',
          logo: {
            file: SmartASNLogo,
          },
        }}
      />
    </div>
  )
}
