import {Icon, Typography} from '@material-ui/core'
import classNames from 'classnames'
import {FC, ReactElement} from 'react'
import {IFilterRating} from '../../interfaces'
import useStyles from './FilterStyles'

const FilterRating: FC<IFilterRating> = (
  props: IFilterRating
): ReactElement => {
  const {selectedRateFilter, setSelectedRateFilter} = props
  const classes = useStyles()
  return (
    <>
      <div className="flex flex-row w-3/5 pt-4 pb-2 space-x-4">
        {[...Array(6)].map((rating, index) => {
          return (
            <div
              key={`${rating}-${index}`}
              onClick={() => {
                setSelectedRateFilter(index)
              }}
              className={classNames(
                `border rounded border-gray-300 flex flex-row justify-center w-12 h-8 items-center cursor-pointer`,
                selectedRateFilter === index
                  ? classes.skyBlueBgColor
                  : 'bg-white'
              )}
            >
              <Icon
                className={
                  index !== selectedRateFilter
                    ? classes.skyBlueColor
                    : 'text-white'
                }
                hidden={index === 0}
                fontSize="small"
              >
                star
              </Icon>
              <Typography
                className={classNames(
                  `text-sm ${
                    selectedRateFilter === index ? 'text-white' : 'text-black'
                  }`
                )}
              >
                {index === 0 ? 'Semua' : index}
              </Typography>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default FilterRating
