import React from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import {
  Avatar,
  Divider,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import Skeleton from 'react-loading-skeleton'

import NoListComponent from '../../../../../components/empty-state/NoList.component'
import {Pagination} from '../../../../../components/contributor/Pagination'

import useDebounce from '../../../../../hooks/useDebounce'
import {USER_ID} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_BOOK_LEARNERS} from '../../../../../graphql/queries'

export interface ContributorBookLearnersPageProps {
  type: 'ebook' | 'audiobook'
}

const ContributorBookLearnersPage = () => {
  const rootRef = React.useRef<HTMLElement>(null)

  const {bookSlug} = useParams()
  const [searchParams, setSearchParams] = useSearchParams()

  const search = searchParams.get('query') ?? ''
  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const debouncedSearch = useDebounce(search)

  const {data} = useQuery(GET_CONTRIBUTOR_BOOK_LEARNERS, {
    fetchPolicy: 'cache-and-network',
    context: INSTRUCTOR_CONTEXT,
    variables: {
      ownerId: USER_ID,
      isbn: bookSlug!,
      search: `%${debouncedSearch}%`,
      limit,
      offset: page * limit,
    },
  })

  const setParams = (next: URLSearchParams, scroll?: boolean) => {
    setSearchParams(next, {replace: true})

    if (scroll) {
      setTimeout(() => {
        rootRef.current!.scrollIntoView({block: 'start', behavior: 'smooth'})
      })
    }
  }

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    setParams(next)
  }

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setParams(next, true)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setParams(next, true)
  }

  return (
    <Paper ref={rootRef} className="scroll-m-32">
      <Typography color="primary" className="px-6 py-4 font-bold">
        Learner ({data?.total_unfiltered.aggregate.count || 0})
      </Typography>

      <Divider />

      <div className="px-6 py-6">
        <TextField
          placeholder="Search"
          variant="outlined"
          fullWidth
          value={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Divider />

      {debouncedSearch && (
        <div className="px-6 py-4">
          <Typography color="textSecondary" className="text-sm">
            Showing {data?.total?.aggregate?.count || 0} results based on your
            search &quot;
            <span className="whitespace-pre-wrap">{debouncedSearch}</span>
            &quot;
          </Typography>
        </div>
      )}

      <Table>
        <TableHead>
          <TableRow>
            <TableCell className="w-0">
              <span className="invisible">Avatar</span>
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell className="w-1/3">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!data ? (
            Array.from({length: limit}, (_, idx) => (
              <TableRow key={idx}>
                <TableCell>
                  <Skeleton className="h-10 w-10 rounded-full" />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
              </TableRow>
            ))
          ) : data.owners.length > 0 ? (
            data.owners.map((owner: any) => (
              <TableRow key={owner.id}>
                <TableCell>
                  <Avatar
                    alt={owner.global_user.name}
                    src={owner.global_user.avatar}
                  />
                </TableCell>
                <TableCell>
                  <Typography className="text-sm">
                    {owner.global_user.name}
                  </Typography>
                  <Typography color="textSecondary" className="text-sm">
                    {
                      owner.global_user.people_work_placements[0]
                        ?.company_job_profile.title
                    }
                  </Typography>
                </TableCell>
                <TableCell>{owner.global_user.email}</TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} className="py-16">
                <NoListComponent title="Sorry, No List" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Pagination
        rowsPerPageOptions={[10, 25, 100]}
        count={data?.total?.aggregate?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default ContributorBookLearnersPage
