import {gql} from '@apollo/react-hooks'

export const GET_LIST_PERSPECTIVE = gql`
  query getPerspective($search: String) {
    kpi_perspectives(where: {name: {_ilike: $search}}, offset: 0, limit: 10) {
      id
      name
      description
      status
    }
  }
`
