import React, { useState } from 'react'
import { Button, makeStyles, Typography } from '@material-ui/core'
import AddAPhoto from '@material-ui/icons/AddAPhotoOutlined'
import { UPLOAD_URL } from '../../../../utils/globals'
import axios from 'axios'
import { IStepTwoSocialLearning } from '../../../../interfaces'

const useStyles = makeStyles({
  uploadButton: {
    // margin: theme.spacing.unit,
    backgroundColor: '#f7f8f9',
    color: '#014a62',
    height: 120,
    width: 120,
    borderRadius: '50%',
  },
  uploadedButton: {
    // margin: theme.spacing.unit,
    height: 120,
    width: 120,
    borderRadius: '50%',
    padding: 0,
  },
  uploadedImg: {
    height: 120,
    width: 120,
    borderRadius: '50%',
    objectFit: 'cover',
  },
  uploadIcon: {
    fontSize: 46,
  },
})

function FormStep2(props: IStepTwoSocialLearning) {
  const { formData, setFormData, setActiveStep, setSubmitClicked } = props
  const classes = useStyles()

  const [error, setError] = useState<{ avatar_url: string }>({ avatar_url: '' })
  const [uploading, setUploading] = useState(false)

  const uploadAvatar = (e: any) => {
    const tempFile = e.target.files[0]
    if (tempFile.size > 2000000) {
      setError({ avatar_url: ' maximum file size is 2MB' })
      setTimeout(() => {
        setError({ avatar_url: '' })
      }, 3000)
    } else {
      const fileFormData = new FormData()
      fileFormData.append('file', tempFile)
      setUploading(true)
      axios
        .post(UPLOAD_URL || '', fileFormData, {
          headers: {
            'Content-Type': tempFile.type,
          },
          withCredentials: true
        })
        .then((response) => {
          const { url } = response.data

          setUploading(false)

          setFormData({
            ...formData,
            avatar_url: url,
            avatar_file_name: tempFile.name,
          })
        })
        .catch(() => { })
        .finally(() => {
          setUploading(false)
        })
    }
  }

  const handleCreate = () => {
    if (!formData.avatar_url) {
      setError({ avatar_url: ' is required' })
      setTimeout(() => {
        setError({ avatar_url: '' })
      }, 3000)
    } else {
      setSubmitClicked()
    }
  }

  return (
    <div className="flex flex-col">
      <div className="w-full text-center pb-[48px]">
        <Typography className="text-[20px] pb-[15px]">
          Tambah Avatar Sesi Pembelajaran
        </Typography>
        <Typography className="text-[14px] text-[#a9a8a8]">
          Bantu orang lain mengenali Anda
        </Typography>
      </div>

      <div className="flex flex-col items-center pb-[48px]">
        <label htmlFor="button-file">
          <Button
            color="default"
            className={
              !formData.avatar_url
                ? classes.uploadButton
                : classes.uploadedButton
            }
            variant="contained"
            component="span"
          >
            {formData.avatar_url ? (
              <img
                src={formData.avatar_url}
                alt={formData.avatar_file_name || ''}
                className={classes.uploadedImg}
              />
            ) : (
              <AddAPhoto className={classes.uploadIcon} />
            )}
          </Button>
        </label>
        {error.avatar_url ? (
          <Typography variant="body2" color="error" className="pt-12">
            {'Avatar' + error.avatar_url}
          </Typography>
        ) : (
          <Typography variant="body2" className="pt-12">
            {uploading
              ? 'Loading...'
              : !formData.avatar_file_name
                ? 'Ukuran unggah maksimal: 2MB'
                : formData.avatar_file_name}
          </Typography>
        )}
        <input
          id="button-file"
          onChange={uploadAvatar}
          type="file"
          accept="image/*"
          multiple={false}
          hidden={true}
        />
      </div>

      <div className="w-full flex flex-row justify-between gap-4">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => setActiveStep(0)}
        >
          Sebelumnya
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleCreate}
        >
          Buat
        </Button>
      </div>
    </div>
  )
}

export default FormStep2
