import {CircularProgress} from '@material-ui/core'

export interface LoadingCircularProps {
  message?: string
  height?: string
}

const LoadingCircular = (props: LoadingCircularProps) => {
  const {height = '100vh', message = 'Loading Data...'} = props

  return (
    <div
      className="w-full flex flex-col items-center justify-center"
      style={{height}}
    >
      <CircularProgress />
      <p className="mt-6">{message}</p>
    </div>
  )
}

export default LoadingCircular
