import gql from 'graphql-tag'

export const UPDATE_CONTRIBUTOR_INFO = gql`
  mutation ($userId: uuid!, $objects: global_users_set_input!) {
    update_global_users_by_pk(pk_columns: {id: $userId}, _set: $objects) {
      id
      contributor_label
      contributor_banner
    }
  }
`
