import React, {ChangeEvent} from 'react'
import {IconButton} from '@material-ui/core'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'

const WishlistButtonComponent = (props: {
  isWishlist: Boolean
  action?: () => void
  loading: Boolean
}): React.ReactElement => {
  const handleClick = (e: ChangeEvent<{}>) => {
    e.stopPropagation()

    if (props.action) {
      props.action()
    }
  }

  const color = {
    default: {color: '#EF4D5E'},
    disabled: {color: '#adadad'},
  }

  return (
    <div
      style={{
        position: 'absolute',
        borderRadius: '4px',
        background: '#fff',
        margin: '4px',
      }}
    >
      <IconButton
        style={{padding: 2}}
        onClick={(e: ChangeEvent<{}>) => handleClick(e)}
        disabled={Boolean(props.loading)}
      >
        {props.isWishlist ? (
          <FavoriteIcon
            style={props.loading ? color.disabled : color.default}
          />
        ) : (
          <FavoriteBorderIcon
            style={props.loading ? color.disabled : color.default}
          />
        )}
      </IconButton>
    </div>
  )
}

export default WishlistButtonComponent
