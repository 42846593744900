import {useMutation, useQuery} from '@apollo/react-hooks'
import {
  GET_MY_SOCIAL_LEARNING,
  GET_RECOMMENDED_TRENDING_CLASSROOM,
  GET_PUBLIC_CLASSROOM,
  GET_RECOMMENDED_CATEGORY_CLASSROOM,
  GET_LIST_CLASSROOM_CATEGORIES,
  GET_LIST_CLASSROOM_SUB_CATEGORIES,
  GET_LIST_MENTOR,
} from '../../graphql/queries'
import {USER_ID, HASURA_ROLE_USER_CONTEXT} from '../../utils/globals'
import {PriceType, RatingType} from '../../interfaces'
import {
  INSERT_SOCIAL_GROUP,
  INSERT_SOCIAL_INDIVIDU,
} from '../../graphql/mutations'

export const MY_SOCIAL_LEARNING = (limit: number = 4, offset: number = 0) => {
  return useQuery(GET_MY_SOCIAL_LEARNING, {
    variables: {
      memberUserId: USER_ID,
      limit,
      offset,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const RECOMMENDED_TRENDING_CLASSROOM = (
  isSkip: boolean = false,
  isRecommended: boolean = false,
  sortByTrending: boolean = false,
  limit: number = 4,
  offset: number = 0,
  search?: string,
  category_id?: string | number[],
  rating?: number,
  min_price?: number,
  max_price?: number,
  showJoined?: boolean,
  free?: boolean,
  paid?: boolean
) => {
  return useQuery(GET_RECOMMENDED_TRENDING_CLASSROOM, {
    variables: {
      isRecommended,
      sortByTrending,
      limit,
      offset,
      search,
      category_id,
      rating,
      min_price,
      max_price,
      showJoined,
      free,
      paid,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
    skip: isSkip,
  })
}

export const PUBLIC_SOCIAL_LEARNING = (
  limit: number = 4,
  offset: number = 0,
  search: string = '',
  // priceLte: number,
  // priceGte: number,
  categoryIds: number[] = [],
  rating: RatingType[] = []
) => {
  const variables: {
    userId: string
    limit?: number
    offset?: number
    keyword?: string
    price?: PriceType
    categoryIds?: number[]
    rating?: RatingType[]
  } = {
    userId: USER_ID,
    limit,
    offset,
    keyword: `%${search}%`,
    // price: {
    //   _lte: priceLte,
    //   _gte: priceGte,
    // },
    categoryIds,
    rating,
  }

  if (limit === -1) {
    delete variables.limit
    delete variables.offset
  }

  if (!search) {
    delete variables.keyword
  }
  // if (!priceLte || !priceGte) {
  //   delete variables.price
  // }
  if (categoryIds.length === 0 || categoryIds.includes(0)) {
    delete variables.categoryIds
  }

  if (rating.length === 0) {
    variables.rating = [
      {
        average_rating: {
          _neq: -1,
        },
      },
    ]
  } else if (rating.length === 1 && rating[0].average_rating._gte === 0) {
    delete variables.rating
  }

  return useQuery(GET_PUBLIC_CLASSROOM, {
    variables,
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const RECOMMENDED_CATEGORY_CLASSROOM = (userId: string = '4') => {
  return useQuery(GET_RECOMMENDED_CATEGORY_CLASSROOM, {
    variables: {
      userId,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const LIST_CLASSROOM_CATEGORIES = () => {
  return useQuery(GET_LIST_CLASSROOM_CATEGORIES, {
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

export const LIST_CLASSROOM_SUB_CATEGORIES = (parent?: number | null) => {
  return useQuery(GET_LIST_CLASSROOM_SUB_CATEGORIES, {
    variables: {
      parent,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

type listMentorType = {
  offset: number
  limit: number
  searchQuery: string
}

export const LIST_MENTOR_SOCIAL = ({
  offset,
  limit,
  searchQuery,
}: listMentorType) => {
  return useQuery(GET_LIST_MENTOR, {
    variables: {
      offset: offset,
      limit: limit,
      searchQuery: searchQuery,
    },
    ...HASURA_ROLE_USER_CONTEXT,
    fetchPolicy: 'cache-and-network',
  })
}

// Mutation
export const ADD_SOCIAL_GROUP = () => {
  return useMutation(INSERT_SOCIAL_GROUP, HASURA_ROLE_USER_CONTEXT)
}

export const ADD_SOCIAL_INDIVIDU = () => {
  return useMutation(INSERT_SOCIAL_INDIVIDU, HASURA_ROLE_USER_CONTEXT)
}
