import gql from 'graphql-tag'

export const CREATE_COURSE = gql`
  mutation ($objects: [academy_courses_insert_input!]!) {
    insert_academy_courses(objects: $objects) {
      affected_rows
      returning {
        id
        slug
      }
    }
  }
`

export const UPDATE_COURSE_STATUS = gql`
  mutation ($courseId: Int!, $objects: academy_courses_set_input!) {
    update_academy_courses_by_pk(pk_columns: {id: $courseId}, _set: $objects) {
      id
      status
    }
  }
`

export const UPDATE_COURSE_TARGET_DETAILS = gql`
  mutation ($courseId: Int!, $objects: academy_courses_set_input!) {
    update_academy_courses_by_pk(pk_columns: {id: $courseId}, _set: $objects) {
      id
      goals
      requirements
      target_audience
    }
  }
`

export const UPDATE_COURSE_LANDING_DETAILS = gql`
  mutation ($courseId: Int!, $objects: academy_courses_set_input!) {
    update_academy_courses_by_pk(pk_columns: {id: $courseId}, _set: $objects) {
      id
      thumbnail
      video
      title
      description
      language
      level
      academy_course_category {
        id
        academy_course_category {
          id
        }
      }
      academy_price_tier {
        id
      }
    }
  }
`

export const ADD_COURSE_CURRICULUM_SECTIONS = gql`
  mutation ($objects: [academy_course_sections_insert_input!]!) {
    insert_academy_course_sections(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const UPDATE_COURSE_CURRICULUM_SECTION = gql`
  mutation ($sectionId: Int!, $objects: academy_course_sections_set_input!) {
    update_academy_course_sections_by_pk(
      pk_columns: {id: $sectionId}
      _set: $objects
    ) {
      id
    }
  }
`

export const DELETE_COURSE_CURRICULUM_SECTIONS = gql`
  mutation ($sectionIds: [Int!]!) {
    delete_academy_course_sections(where: {id: {_in: $sectionIds}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_COURSE_CURRICULUM_LESSONS = gql`
  mutation ($objects: [academy_course_lessons_insert_input!]!) {
    insert_academy_course_lessons(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_COURSE_CURRICULUM_LESSON = gql`
  mutation ($lessonId: Int!, $objects: academy_course_lessons_set_input!) {
    update_academy_course_lessons_by_pk(
      pk_columns: {id: $lessonId}
      _set: $objects
    ) {
      id
    }
  }
`

export const DELETE_COURSE_CURRICULUM_LESSONS = gql`
  mutation ($lessonIds: [Int!]!) {
    delete_academy_course_lessons(where: {id: {_in: $lessonIds}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const ADD_COURSE_CURRICULUM_QUESTIONS = gql`
  mutation ($objects: [academy_course_questions_insert_input!]!) {
    insert_academy_course_questions(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_COURSE_CURRICULUM_QUESTION = gql`
  mutation ($questionId: Int!, $objects: academy_course_questions_set_input!) {
    update_academy_course_questions_by_pk(
      pk_columns: {id: $questionId}
      _set: $objects
    ) {
      id
    }
  }
`

export const DELETE_COURSE_CURRICULUM_QUESTIONS = gql`
  mutation ($questionIds: [Int!]!) {
    delete_academy_course_questions(where: {id: {_in: $questionIds}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`
