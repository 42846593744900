import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'
import React from 'react'
import {Button, Card, Typography} from '@material-ui/core'
import {FavoriteBorder} from '@material-ui/icons'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'
import FavoriteIcon from '@material-ui/icons/Favorite'
import AddToCartButton from '../../../button/AddToCartButton.component'

import {IBook, IBookREST, PopperProps} from '../../../../interfaces'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import {
  GET_MY_BOOKS_COUNT,
  GET_USER_CART_COUNT,
  GET_WISHLIST_BOOK_COUNT,
} from '../../../../graphql/queries'
import {ADD_BOOK_FROM_WISHLIST} from '../../../../graphql/mutations'
import {ADD_TO_CART} from '../../../../provider/services/Academy.service'
import {DELETE_BOOK_WISHLIST} from '../../../../provider/services/Book.service'

export interface PopperBookProps extends PopperProps {
  item: IBook | IBookREST
}

const PopperBookActions = (props: Pick<PopperBookProps, 'item'>) => {
  const {item} = props

  const pricing = (item as any).pricing_idr ?? item.pricingIdr
  const isFree = !pricing || '' + pricing === '0'
  const [loading, setLoading] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()

  const [addToCart] = ADD_TO_CART()
  const [addToWishlist] = useMutation(
    ADD_BOOK_FROM_WISHLIST,
    HASURA_ROLE_USER_CONTEXT
  )
  const [deleteFromWishlist] = DELETE_BOOK_WISHLIST()

  const {data: cartData, refetch: refetchCart} = useQuery(GET_USER_CART_COUNT, {
    skip: isFree,
    fetchPolicy: 'cache-and-network',
    variables: {
      itemTable: 'academy_books',
      itemIds: [item.isbn],
    },
  })

  const {data: wishlistData, refetch: refetchWishlist} = useQuery(
    GET_WISHLIST_BOOK_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        bookIsbns: [item.isbn],
      },
    }
  )

  const {data: ownedData} = useQuery(GET_MY_BOOKS_COUNT, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      bookIsbns: [item.isbn],
    },
  })

  const isInCart = cartData && cartData.marketplace_user_carts.length > 0
  const isInWishlist =
    wishlistData &&
    wishlistData.academy_book_wishlists_aggregate.aggregate.count > 0
  const isOwned =
    ownedData && ownedData.academy_books_aggregate.aggregate.count > 0

  const handleCartAddition = () => {
    const isIBookREST = (item: any): item is IBookREST => {
      return item && 'OwnerUser' in item
    }

    let pricing = 0
    let vendorId = null
    let vendorName = null

    if (isIBookREST(item)) {
      pricing = +item.pricing.IDR || 0
      vendorId = item.OwnerUser.id
      vendorName = item.OwnerUser.name
    } else {
      pricing = item.pricing_idr || 0
      vendorId = item.global_user.id
      vendorName = item.global_user.name
    }

    const variables = {
      objects: [
        {
          item_id: item.isbn,
          item_object: {
            name: item.title,
            image: item.thumbnail,
            price: pricing,
            weight: 0,
            note: '',
          },
          item_table: 'academy_books',
          item_vendor: vendorName,
          item_vendor_id: vendorId,
          item_vendor_table: 'global_users',
          item_quantity: 1,
        },
      ],
    }

    const promise = addToCart({variables})

    promise.then(
      () => {
        refetchCart()
      },
      () => {
        enqueueSnackbar('Failed to add book to cart', {variant: 'error'})
      }
    )
  }

  const handleWishlistAddition = () => {
    setLoading(true)
    const variables = {isbn: item.isbn}

    const promise = addToWishlist({variables})

    promise.then(
      () => {
        refetchWishlist()
        setLoading(false)
        enqueueSnackbar('Added to wishlist', {variant: 'success'})
      },
      () => {
        enqueueSnackbar(`Failed to add book to wishlist`, {variant: 'error'})
      }
    )
  }

  const handleRemoveWishlist = () => {
    setLoading(true)
    if (item.isbn) {
      deleteFromWishlist({
        variables: {
          userId: USER_ID,
          isbn: item.isbn,
        },
      })
        .then(() => {
          refetchWishlist()
          enqueueSnackbar('Book removed from wishlist', {variant: 'success'})
        })
        .catch(() => {
          enqueueSnackbar('Remove book from wishlish failed', {
            variant: 'error',
          })
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }

  if (isOwned) {
    return null
  }

  const bookType = 'bookType' in item ? item.bookType : item.type.id

  return (
    <div className="flex flex-row justify-between mt-4">
      {isFree ? (
        <Button
          component={Link}
          to={`/learner/book/${item.isbn}`}
          variant="contained"
          color="primary"
          size="large"
          className="mr-4"
        >
          Ambil {bookType === 3 ? 'Buku Audio' : 'Buku Elektronik'}
        </Button>
      ) : (
        <AddToCartButton
          isInCart={isInCart}
          action={handleCartAddition}
          size="large"
          className="mr-4"
        />
      )}

      {isInWishlist ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={handleRemoveWishlist}
          disabled={loading}
        >
          <FavoriteIcon />
        </Button>
      ) : (
        <Button
          onClick={handleWishlistAddition}
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
        >
          <FavoriteBorder />
        </Button>
      )}
    </div>
  )
}

const PopperBook = (props: PopperBookProps) => {
  const {item, hidePrice, featureName} = props

  return (
    <Card style={{width: 320}} className="bg-white p-4">
      <div>
        <LinesEllipsis
          text={item.title}
          maxLine={2}
          component={Link}
          to={`/learner/book/${item.isbn}`}
          state={{prevRouteName: featureName}}
          className="font-bold overflow-hidden"
        />

        <div className="flex flex-wrap gap-x-1 text-gray-600 text-xs mt-1">
          <span>
            {'BookCategory' in item
              ? item.BookCategory.name
              : item.academy_course_category.name}
          </span>
        </div>

        <Typography gutterBottom className="font-bold text-sm mt-4">
          Keterangan
        </Typography>

        <LinesEllipsis
          text={item.summary}
          maxLine={5}
          component="p"
          className="text-sm overflow-hidden mt-2 mb-0 empty:hidden"
        />
      </div>

      {!hidePrice && <PopperBookActions item={item} />}
    </Card>
  )
}

export {PopperBook}
