import {useState, ChangeEvent} from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  TextField,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemSecondaryAction,
  ListItemText,
  Checkbox,
  makeStyles,
} from '@material-ui/core'

import LoadingUserComponent from '../../components/loading/LoadingUser.component'
import NoListComponent from '../../components/empty-state/NoList.component'

type propsType = {
  open: boolean
  loading: boolean
  onClose: () => void
  onSave: () => void
  data: any[]
  selected: any[]
  handleChange: (id: any) => void
}

const useStyles = makeStyles(() => ({
  participantList: {
    width: '100%',
    position: 'relative',
    overflow: 'auto',
    maxHeight: 340,
    marginBottom: '1.6rem',
  },
}))
const DialogParticipantComponent = (props: propsType) => {
  const classes = useStyles()
  const [search, setSearch] = useState('')

  const list = search
    ? props.data.filter((item: any) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    : props.data

  return (
    <Dialog
      onClose={() => props.onClose()}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle id="customized-dialog-title">
        <Typography className="text-18 font-bold">Peserta</Typography>
      </DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          placeholder="Cari Peserta"
          fullWidth
          value={search}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setSearch(e.target.value)
          }
        />
        {props.loading ? (
          <div className="p-4">
            <LoadingUserComponent />
          </div>
        ) : list.length > 0 ? (
          <List className={classes.participantList}>
            {list.map((item: any, i: number) => {
              const labelId = `checkbox-list-secondary-label-${item.id}`

              return (
                <ListItem key={i} button>
                  <ListItemAvatar>
                    <Avatar alt={`Avatar n°${i + 1}`} src={item.avatar} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={item.name}
                    secondary={
                      item?.people_work_placements?.company_employee_position
                        ?.name
                    }
                  />
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      onChange={() => props.handleChange(item.id)}
                      checked={props.selected.some(
                        (val: any) => val.participant_user_id === item.id
                      )}
                      inputProps={{'aria-labelledby': labelId}}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              )
            })}
          </List>
        ) : (
          <div className="mt-6">
            <NoListComponent
              imageSize={100}
              title="Sorry, No List"
              subTitle="You don't have followers or following"
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button autoFocus color="primary" onClick={() => props.onClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => props.onSave()}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DialogParticipantComponent
