import {FC} from 'react'
import {Button} from '@material-ui/core'
import useStyles, {
  CustomFlex,
} from '../../../../components/utils-components/GlobalStyles'
import MyLearningCardComponent from '../../../../components/card/MyLearningCard.component'
import {LearningType} from '../../../../utils/enum'
import {IBook} from '../../../../interfaces'
import NoList from '../../../../components/empty-state/NoList.component'
import {useQuery} from '@apollo/client'
import {GET_MY_BOOKS} from '../../../../graphql/queries'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../../../utils/globals'
import Skeleton from 'react-loading-skeleton'
import {TMyLearning} from '..'
import classNames from 'classnames'
import {useSearchParams} from 'react-router-dom'

const MyBookComponent: FC<TMyLearning> = ({search, sort, filterData}) => {
  const classes = useStyles()
  const [searchParams, setSearchParams] = useSearchParams()
  const type = Number(searchParams.get('type') ?? '2')
  let orderBy

  switch (sort) {
    case 'oldest':
      orderBy = {
        date_added: 'asc',
      }
      break
    case 'nameASC':
      orderBy = {
        title: 'asc',
      }
      break
    case 'nameDESC':
      orderBy = {
        title: 'desc',
      }
      break
    default:
      orderBy = {
        date_added: 'desc',
      }
  }

  const _filter: any = filterData

  const filterCategory = _filter.category

  const {data, loading} = useQuery(GET_MY_BOOKS, {
    variables: {
      type: type,
      userId: USER_ID,
      keyword: `%${search}%`,
      orderBy: [orderBy],
      category: filterCategory,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })

  const handleChangeTab = (value: number) => {
    searchParams.set('type', String(value))
    setSearchParams(searchParams)
  }

  const isSearching = search || Object.keys(filterData).length > 0

  if (loading) {
    return (
      <>
        {Array.from({length: 2}).map((res, i) => (
          <Skeleton height="140px" className="mb-4" key={i} />
        ))}
      </>
    )
  }

  return (
    <div>
      <CustomFlex className="mb-10">
        <Button
          variant="contained"
          color={type === 2 ? 'primary' : 'default'}
          onClick={() => handleChangeTab(2)}
          className={classNames(
            classes.buttonSwitchType,
            'w-full md:w-auto lg:w-auto'
          )}
          classes={{
            root: type === 2 ? classes.buttonActive : classes.buttonInactive,
          }}
        >
          Buku elektronik
        </Button>
        <Button
          variant="contained"
          color={type === 3 ? 'primary' : 'default'}
          onClick={() => handleChangeTab(3)}
          className={classNames(
            classes.buttonSwitchType,
            'ml-4 w-full md:w-auto md:ml-8 lg:w-auto lg:ml-8'
          )}
          classes={{
            root: type === 3 ? classes.buttonActive : classes.buttonInactive,
          }}
        >
          Buku Audio
        </Button>
      </CustomFlex>
      {data.academy_books.length === 0 ? (
        <NoList
          imageSize={180}
          title={
            isSearching
              ? 'Tidak ada hasil yang ditemukan'
              : 'Maaf, tidak ada daftar'
          }
          subTitle={
            isSearching
              ? 'Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda'
              : `Saat ini Anda tidak memilik ${
                  type === 2 ? 'buku elektronik' : 'buku audio'
                }`
          }
        />
      ) : (
        data.academy_books.map((val: IBook) => {
          return (
            <MyLearningCardComponent
              key={val.isbn}
              data={val}
              type={LearningType.BOOK}
            />
          )
        })
      )}
    </div>
  )
}

export default MyBookComponent
