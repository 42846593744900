import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { type DateRange } from '@smartasn/wlb-utils-components'

import { CoursePageContext } from '../ContributorCourseDetailPageLayout'

import DateRangeModal from '../../../../../components/modal/DateRangeModal'
import TimeChartCard, {
  TimeKind,
  RangeType,
  getDateRange,
  type ActivityData,
} from '../../../../../components/contributor/dashboard/TimeChartCard'

import { INSTRUCTOR_CONTEXT } from '../../../../../utils/contributor-helpers'
import { GET_CONTRIBUTOR_COURSE_ACTIVITY_BREAKDOWN } from '../../../../../graphql/queries'

const CourseActivityCard = () => {
  const { courseId } = useOutletContext<CoursePageContext>()

  const [rangeType, setRangeType] = React.useState(RangeType.WEEK)
  const [customRange, setCustomRange] = React.useState<DateRange | undefined>()
  const [openPicker, setOpenPicker] = React.useState(false)

  const range = React.useMemo(() => {
    return getDateRange(rangeType, customRange)
  }, [rangeType, customRange])

  const { data } = useQuery(GET_CONTRIBUTOR_COURSE_ACTIVITY_BREAKDOWN, {
    skip: courseId === undefined,
    fetchPolicy: 'network-only',
    variables: {
      courseId,
      timeType: range.timeType,
      startDate: range.startDate,
      endDate: range.endDate,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const activityData = React.useMemo((): ActivityData | null => {
    if (!data) {
      return null
    }

    const activity = data.getActivityByTimeCourseDetail

    return {
      enrollments: activity.enrollment_course || [],
      wishlists: activity.wishlist_course || [],
    }
  }, [data])

  const handleRangeChange = (next: RangeType) => {
    if (next === RangeType.CUSTOM) {
      setOpenPicker(true)
    } else {
      setRangeType(next)
    }
  }

  const handlePickerClose = () => {
    setOpenPicker(false)
  }

  const handlePickerSubmit = (range: DateRange) => {
    setRangeType(RangeType.CUSTOM)
    setCustomRange(range)
    setOpenPicker(false)
  }

  return (
    <>
      <TimeChartCard
        kind={TimeKind.ACTIVITY}
        rangeType={rangeType}
        ranges={range}
        data={activityData}
        onTypeChange={handleRangeChange}
      />

      <DateRangeModal
        open={openPicker}
        initialRange={customRange}
        onClose={handlePickerClose}
        onSubmit={handlePickerSubmit}
      />
    </>
  )
}

export default CourseActivityCard
