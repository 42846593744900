import {Button} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {ArcElement, Chart as ChartJS, Tooltip} from 'chart.js'
import React, {useContext} from 'react'
import {Doughnut} from 'react-chartjs-2'
import {Link, useNavigate} from 'react-router-dom'
import {emptyDoughnoutData} from '../../../config/chart.config'
import ListLearningDiaryResume from '../../../pages/profile/partial/ListLearningDiaryResume.component'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'
import {formattedTimeConvert} from '../../../utils/helpers'
import {CustomTypography} from '../../utils-components/GlobalStyles'

ChartJS.register(ArcElement, Tooltip)

type LearningDiaryResumeCardProps = {
  children?: React.ReactNode
  type?: string
}

const LearningDiaryResume = (props: LearningDiaryResumeCardProps) => {
  const navigate = useNavigate()
  const {dataResume} = useContext(LearningProfileContext)
  const chartData = dataResume?.[0]
    ? {
        labels: dataResume?.map((res) => res.name),
        datasets: [
          {
            data: dataResume?.map(
              (res) => res.learning_diaries_aggregate?.aggregate?.sum?.duration
            ),
            backgroundColor: dataResume?.map((res) => res.color),
            borderColor: dataResume?.map((res) => res.color),
            borderWidth: 1,
          },
        ],
      }
    : emptyDoughnoutData

  const customAfterLabel = (context: any) =>
    ` ${formattedTimeConvert(context.parsed, true)}`
  const customLabel = (context: any) => ` ${context.label}`

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '80%',
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: (dataResume || []).length > 0,
        callbacks: {
          label: customLabel,
          afterLabel: customAfterLabel,
        },
        backgroundColor: '#F7F8F9',
        bodyColor: '#000',
      },
    },
  }

  let totalTime = 0
  dataResume?.forEach(
    (res) =>
      (totalTime += res.learning_diaries_aggregate?.aggregate?.sum?.duration)
  )

  const renderContent = () => {
    switch (props.type) {
      case 'diary':
        return (
          <div className="flex items-center justify-between">
            <Button
              component={Link}
              to="/profile"
              color="primary"
              startIcon={<ArrowBack style={{fontSize: '16px'}} />}
              style={{fontSize: '16px'}}
            >
              Catatan Pembelajaran Harian
            </Button>
            <button
              onClick={() => navigate('/profile/learning-diary/create')}
              className="py-3 px-11 text-white text-sm bg-[#014a62] rounded-md"
            >
              Buat
            </button>
          </div>
        )
      case 'summary':
        return (
          <Button
            component={Link}
            to="/profile"
            color="primary"
            startIcon={<ArrowBack style={{fontSize: '16px'}} />}
            style={{fontSize: '16px'}}
          >
            Ringkasan Catatan Pembelajaran
          </Button>
        )

      default:
        return (
          <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
            Ringkasan Catatan Pembelajaran
          </CustomTypography>
        )
    }
  }
  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">{renderContent()}</div>
      <hr />
      <div className="lg:flex lg:items-center pl-6 py-6 lg:gap-24">
        <div className="w-auto h-auto lg:w-1/2 lg:h-80 relative px-2 lg:px-0">
          <Doughnut data={chartData} options={chartOptions} />
        </div>
        <div className="w-full lg:px-8">
          <CustomTypography fsize="16px">Total Waktu</CustomTypography>
          <CustomTypography
            fsize="32px"
            fcolor="#4caf50"
            mt="12px"
            fweight="bold"
          >
            {formattedTimeConvert(totalTime, true)}
          </CustomTypography>
          <CustomTypography fsize="16px" mt="40px">
            Total Kompetensi
          </CustomTypography>
          <CustomTypography
            fsize="32px"
            fcolor="#039be5"
            mt="12px"
            fweight="bold"
          >
            {dataResume?.length}
          </CustomTypography>
          {props.type === 'diary' && (
            <button
              onClick={() => navigate('/profile/learning-diary-resume')}
              className="py-3 mt-5 px-4 border font-bold border-[#014a62] bg-white text-sm text-[#014a62] rounded-md"
            >
              Lihat Rincian Ringkasan
            </button>
          )}
        </div>
      </div>
      <hr className="mb-6" />
      {props.children ? (
        <div>{props.children}</div>
      ) : (
        <>
          <ListLearningDiaryResume dataResume={dataResume?.slice(0, 5)} />
          <hr className="mt-6" />
          {(dataResume || []).length > 5 && (
            <div className="p-6 items-center flex justify-center">
              <button
                onClick={() => navigate('/profile/learning-diary-resume')}
                className="text-xs bg-transparent text-[#039be5]"
              >
                View All
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default LearningDiaryResume
