import React from 'react'

import {Grid} from '@material-ui/core'
import {LearningType} from '../../../../utils/enum'
import TabRow from './TabRow.component'
import {LearnerCourseContext} from '../../../../provider/LearnerCourse.provider'

type CourseTabProps = {
  isSearchFilter?: boolean
}

function CourseTab(props: CourseTabProps) {
  const learnerCourseContext = React.useContext(LearnerCourseContext)

  return (
    <Grid container className="lg:p-5 p-1">
      {!props.isSearchFilter && (
        <>
          <TabRow
            title="Kursus Saya"
            data={learnerCourseContext.myCourse.data}
            type={LearningType.COURSE}
          />
          <TabRow
            title="Sedang Tren di SmartASN"
            data={learnerCourseContext?.trendings?.data}
            type={LearningType.COURSE}
            loading={learnerCourseContext?.trendings?.loading}
          />

          <TabRow
            title="Rekomendasi untuk Anda"
            data={learnerCourseContext?.courseRecommended?.data}
            type={LearningType.COURSE}
            loading={learnerCourseContext?.courseRecommended?.loading}
          />
          {/* <Grid item sm={12} className={classes.categoryContainer}>
            <RecommendedCourseCategoryRow />
          </Grid> */}
        </>
      )}

      <TabRow
        title={props.isSearchFilter ? '' : 'Semua Kursus'}
        data={learnerCourseContext.courseAll.data}
        type={LearningType.COURSE}
        isAll={true}
      />
    </Grid>
  )
}

export default CourseTab
