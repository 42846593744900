import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import {Grid} from '@material-ui/core'

import {LearnerCourseContext} from '../../../../provider/LearnerCourse.provider'
import {LearnerBookContext} from '../../../../provider/LearnerBook.provider'
import {LearnerEventContext} from '../../../../provider/LearnerEvent.provider'
import {LearnerSocialLearningContext} from '../../../../provider/LearnerSocialLearning.provider'
import {LearnerMicroLearningContext} from '../../../../provider/LearnerMicroLearning.provider'
import {LearnerLearningContributorContext} from '../../../../provider/LearnerLearningContributor.provider'
import {LearningType} from '../../../../utils/enum'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import LoadingCard from '../../../loading/LoadingCard.component'
import NoListComponent from '../../../empty-state/NoList.component'

import {
  IBook,
  IBookREST,
  ICourse,
  IEnrolledCourse,
  IEvent,
  IMicroLearning,
} from '../../../../interfaces'

type TabRowCardCourseProps = {
  title: string
  type: LearningType
  isAll?: boolean
  data: ICourse[]
}

type TabRowCardBookProps = {
  title: string
  type: LearningType
  isAll?: boolean
  data: any
}

type TabRowCardMicroLearningProps = {
  title: string
  type: LearningType
  isAll?: boolean
  data: any
  loading?: boolean
}

export const TabRowCardCourse = (
  props: TabRowCardCourseProps
): React.ReactElement => {
  const learnerCourseContext = React.useContext(LearnerCourseContext)

  const data = props.data

  const isOnMy = props.title.includes('Saya')
  const myPage = learnerCourseContext.reducerState.course.pageMyCourse
  const myLimit = learnerCourseContext.reducerState.course.limitMyCourse

  const list = React.useMemo(() => {
    if (!data) {
      return []
    }

    const sliced = isOnMy
      ? data.slice(myPage - 1 < 1 ? 0 : (myPage - 1) * 4, myLimit * myPage)
      : data

    return sliced

    // NOTE(intrnl): this is mostly complaining about the logical AND operator,
    // we need this because we don't want the memoization to be busted when
    // the pagination changes for owned courses when we might not be the one
    // showing the list.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isOnMy && myPage, isOnMy && myLimit])

  const fetchMoreData = () => {
    const changeOffset = () =>
      learnerCourseContext.changePageLimit(
        0,
        8,
        'CHANGE_OFFSET_ALL_COURSES',
        learnerCourseContext.reducerState.course.offsetAllCourse + 8
      )

    setTimeout(changeOffset, 1000)
  }

  return (
    <>
      {isOnMy ? (
        <>
          {list.map((item: IEnrolledCourse | any) => (
            <LearnerCatalogCard
              featureName="Learning Catalog"
              key={item.id}
              type={props.type}
              item={item.Course}
              hidePrice={true}
            />
          ))}
        </>
      ) : props.isAll ? (
        <div className="w-full">
          <InfiniteScroll
            dataLength={learnerCourseContext.courseAll.total}
            next={fetchMoreData}
            hasMore={
              learnerCourseContext.courseAll.total >
              learnerCourseContext.reducerState.course.offsetAllCourse
            }
            loader={<LoadingCard length={4} />}
          >
            <div className="flex flex-wrap">
              {list.length > 0 ? (
                list.map((item: ICourse) => (
                  <LearnerCatalogCard
                    featureName="Learning Catalog"
                    key={item.id}
                    type={props.type}
                    item={item}
                    hidePrice={false}
                  />
                ))
              ) : (
                <div className="w-full">
                  <NoListComponent
                    title={
                      props.isAll && props.title === ''
                        ? 'No Result Found'
                        : 'Sorry, No List'
                    }
                    subTitle={
                      props.isAll && props.title === ''
                        ? `It seems we can't find any result based on your search`
                        : 'Coming Soon! Learning contributors are preparing the best course for you'
                    }
                  />
                </div>
              )}
            </div>
          </InfiniteScroll>
        </div>
      ) : (
        <>
          {list.map((item: ICourse) => (
            <LearnerCatalogCard
              featureName="Learning Catalog"
              key={item.id}
              type={props.type}
              item={item}
              hidePrice={false}
            />
          ))}
        </>
      )}
    </>
  )
}

export const TabRowCardBook = (
  props: TabRowCardBookProps
): React.ReactElement => {
  const learnerBookContext = React.useContext(LearnerBookContext)

  const isOnAll = props.title.includes('Semua') || props.isAll
  const isOnMy = !isOnAll && props.title.includes('Saya')
  const data =
    isOnAll && props.title !== '' ? learnerBookContext.bookAll.data : props.data

  const list = React.useMemo(() => {
    return data.map((item: IBook) => ({
      ...item,
      average_rating: item.average_rating || item.averageRating,
    }))
  }, [data])

  const fetchMoreData = () => {
    learnerBookContext.changePageLimit(
      1,
      learnerBookContext.reducerState.limitAllBooks + 8,
      'CHANGE_LIMIT_ALL_BOOKS'
    )
  }

  if (!isOnAll) {
    return (
      <>
        {list.map((item: IBook | IBookREST) => (
          <LearnerCatalogCard
            featureName="Learning Catalog"
            key={item.isbn}
            type={props.type}
            item={item}
            hidePrice={isOnMy}
          />
        ))}
      </>
    )
  } else {
    return (
      <div className="w-full">
        <InfiniteScroll
          dataLength={learnerBookContext.bookAll.data.length}
          next={fetchMoreData}
          hasMore={
            learnerBookContext.bookAll.total >
            learnerBookContext.reducerState.limitAllBooks
          }
          loader={<LoadingCard length={4} />}
        >
          <Grid container className="mb-14">
            {list.length > 0 ? (
              list.map((item: IBookREST) => (
                <LearnerCatalogCard
                  featureName="Learning Catalog"
                  key={item.isbn}
                  type={props.type}
                  item={item}
                  hidePrice={false}
                />
              ))
            ) : (
              <div className="w-full">
                <NoListComponent
                  title={
                    props.isAll && props.title === ''
                      ? 'No Result Found'
                      : 'Sorry, No List'
                  }
                  subTitle={
                    props.isAll && props.title === ''
                      ? `It seems we can't find any result based on your search and filter`
                      : 'Coming Soon! Learning contributors are preparing the best book for you'
                  }
                />
              </div>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    )
  }
}

export const TabRowCardEvent = (
  props: TabRowCardBookProps
): React.ReactElement => {
  const learnerEventContext = React.useContext(LearnerEventContext)

  const isOnMy = props.title.includes('Saya')
  const data = props.data

  const fetchMoreData = () => {
    learnerEventContext.changePageLimit(
      1,
      learnerEventContext.reducerState.limitAllEvents + 8,
      'CHANGE_PAGE_LIMIT_ALL_EVENTS'
    )
  }

  React.useEffect(() => {
    if (props.title === 'Acara terdekat') {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          learnerEventContext.changeEventsForYouLocation(
            position.coords.latitude,
            position.coords.longitude
          )
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }, [props.title, learnerEventContext])

  if (props.isAll) {
    return (
      <div className="w-full">
        <InfiniteScroll
          dataLength={learnerEventContext.eventsAll.data.length}
          next={fetchMoreData}
          hasMore={
            learnerEventContext.eventsAll.total >
            learnerEventContext.reducerState.limitAllEvents
          }
          loader={<LoadingCard length={2} isContributor />}
        >
          <Grid container className="mb-8">
            {data.length > 0 ? (
              data.map((item: IEvent) => (
                <LearnerCatalogCard
                  featureName="Learning Catalog"
                  key={item.id}
                  type={props.type}
                  item={item}
                  hidePrice={isOnMy}
                />
              ))
            ) : (
              <div className="w-full">
                <NoListComponent
                  title="Sorry, No List"
                  subTitle="Coming Soon! Learning contributors are preparing the best event for you"
                />
              </div>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    )
  } else {
    return (
      <>
        {data.map((item: any) => (
          <LearnerCatalogCard
            featureName="Learning Catalog"
            key={item.id}
            type={props.type}
            item={item}
            hidePrice={isOnMy}
          />
        ))}
      </>
    )
  }
}

export const TabRowCardSocialLearning = (
  props: TabRowCardMicroLearningProps
): React.ReactElement => {
  const learnerSocialLearningContext = React.useContext(
    LearnerSocialLearningContext
  )
  const page = learnerSocialLearningContext.reducerState
  const totalPublicLearning =
    learnerSocialLearningContext.socialLearning.global.total

  const fetchMoreData = () => {
    learnerSocialLearningContext.changePageLimit(
      0,
      12,
      'CHANGE_OFFSET_PUBLIC_SOCIAL_LEARNING',
      learnerSocialLearningContext.reducerState.socialLearning.offsetGlobal + 12
    )
  }

  if (props.isAll) {
    return (
      <div className="w-full">
        <InfiniteScroll
          dataLength={learnerSocialLearningContext.socialLearning.global.total}
          next={fetchMoreData}
          hasMore={totalPublicLearning > page.socialLearning.offsetGlobal}
          loader={<LoadingCard length={4} />}
        >
          <div className="flex flex-wrap mb-8 w-full">
            {props.data.length > 0 && !props.loading ? (
              props.data.map((item: any) => (
                <LearnerCatalogCard
                  key={item.id}
                  type={props.type}
                  item={item}
                  hidePrice={true}
                />
              ))
            ) : (
              <div className="w-full">
                <NoListComponent
                  title="Sorry, No List"
                  subTitle="Coming Soon! Learning contributors are preparing the best social learning for you"
                />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    )
  } else {
    return (
      <>
        {props.data.map((item: any) => (
          <LearnerCatalogCard
            key={item.id}
            type={props.type}
            item={item}
            hidePrice={true}
          />
        ))}
      </>
    )
  }
}

export const TabRowCardMicroLearning = (
  props: TabRowCardMicroLearningProps
): React.ReactElement => {
  const learnerMicroContext = React.useContext(LearnerMicroLearningContext)

  const isOnMy = props.title.includes('Saya')
  const data = props.data

  const fetchMoreData = () => {
    learnerMicroContext.changePageLimit(
      0,
      8,
      'CHANGE_OFFSET_ALL_MICROLEARNING',
      learnerMicroContext.reducerState.microlearning.offsetAllMicroLearning + 12
    )
  }

  if (props.isAll) {
    const totalData = learnerMicroContext.microlearning.allMicroLearning?.total

    return (
      <div className="w-full">
        <InfiniteScroll
          dataLength={totalData}
          next={fetchMoreData}
          hasMore={
            totalData >
            learnerMicroContext.reducerState.microlearning
              .offsetAllMicroLearning
          }
          loader={<LoadingCard length={4} />}
        >
          <div className="flex flex-wrap mb-8">
            {data.length > 0 ? (
              data.map((item: IMicroLearning) => (
                <LearnerCatalogCard
                  featureName="Learning Catalog"
                  key={item.id}
                  type={props.type}
                  item={item}
                  hidePrice={isOnMy}
                />
              ))
            ) : (
              <div className="w-full">
                <NoListComponent
                  title="Sorry, No List"
                  subTitle="Coming Soon! Learning contributors are preparing the best micro learning for you"
                />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </div>
    )
  } else {
    return (
      <>
        {data.map((item: IMicroLearning) => (
          <LearnerCatalogCard
            featureName="Learning Catalog"
            key={item.id}
            type={props.type}
            item={item}
            hidePrice={isOnMy}
          />
        ))}
      </>
    )
  }
}

export const TabRowCardContributor = (
  props: TabRowCardMicroLearningProps
): React.ReactElement => {
  const learnerLearningContributorContext = React.useContext(
    LearnerLearningContributorContext
  )
  const totalAllInstructor =
    learnerLearningContributorContext.learningContributor.allInstructor?.total

  const fetchMoreData = () => {
    learnerLearningContributorContext.changePageLimit(
      0,
      8,
      'CHANGE_OFFSET_ALL_INSTRUCTOR',
      learnerLearningContributorContext.reducerState.learningContributor
        .offsetAllInstructor + 8
    )
  }

  if (props.isAll) {
    return (
      <div className="w-full">
        <InfiniteScroll
          dataLength={totalAllInstructor}
          next={fetchMoreData}
          hasMore={
            totalAllInstructor >
            learnerLearningContributorContext.reducerState.learningContributor
              .offsetAllInstructor
          }
          loader={<LoadingCard length={2} isContributor />}
        >
          <Grid container className="mb-8">
            {props.data.length > 0 ? (
              props.data.map((item: any, index: number) => (
                <LearnerCatalogCard
                  key={item.global_user?.id || index}
                  type={props.type}
                  item={item}
                  hidePrice={true}
                />
              ))
            ) : (
              <div className="w-full">
                <NoListComponent
                  title="Sorry, No List"
                  subTitle="Coming Soon! Learning contributors are preparing the best social learning for you"
                />
              </div>
            )}
          </Grid>
        </InfiniteScroll>
      </div>
    )
  } else {
    return (
      <>
        {props.data.map((item: any, index: number) => (
          <LearnerCatalogCard
            key={item.global_user?.id || index}
            type={props.type}
            item={item}
            hidePrice={true}
          />
        ))}
      </>
    )
  }
}
