import {FC, useContext} from 'react'
import {CustomTypography} from '../../utils-components/GlobalStyles'
import {useNavigate} from 'react-router-dom'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'
import {Avatar} from '@material-ui/core'

const LearningCircleCard: FC<{}> = () => {
  const {data} = useContext(LearningProfileContext)
  const navigate = useNavigate()

  const contributorData = data?.learningProfile.mentors.filter(
    (val, i, arr) => arr.findIndex((_val) => _val.id === val.id) === i
  )

  const participantData = data?.learningProfile.mentees.filter(
    (val, i, arr) => arr.findIndex((_val) => _val.id === val.id) === i
  )

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <CustomTypography fsize="16px" fweight="bold" fcolor="#000">
          Lingkaran pembelajaran
        </CustomTypography>
      </div>
      <hr />
      <div className="p-5">
        <CustomTypography fsize="12px" fweight="bold" fcolor="#014a62">
           Saya ({contributorData?.length})
        </CustomTypography>
        <div className="flex items-center gap-4 mt-3">
          {contributorData?.slice(0, 5).map((res, i) => (
            <Avatar className="w-8 h-8" src={res.avatar} key={i} alt="avatar" />
          ))}
        </div>
        <CustomTypography
          fsize="12px"
          mt="25px"
          fweight="bold"
          fcolor="#014a62"
        >
          Peserta Saya ({participantData?.length})
        </CustomTypography>
        <div className="flex items-center gap-4 mt-3">
          {participantData?.slice(0, 5).map((res, i) => (
            <Avatar className="w-8 h-8" src={res.avatar} key={i} alt="avatar" />
          ))}
        </div>
      </div>
      <hr />
      <div className="p-6 ">
        <button
          className="text-xs bg-transparent text-[#039be5]"
          onClick={() => navigate('/profile/learning-circle')}
        >
          Lihat Semua
        </button>
      </div>
    </div>
  )
}

export default LearningCircleCard
