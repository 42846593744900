import {gql} from '@apollo/client'

export const GET_LEARNING_PROFILE = gql`
  query {
    learningProfile {
      #classrooms {
      #id
      #name
      #}
      learning_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
      }
      teach_duration {
        audiobook
        quiz
        course
        event_tracker
        practice_test
        quiz
      }
      mentees {
        id
        avatar
        name
        position
      }
      mentors {
        id
        avatar
        name
        position
      }
      badges {
        name
        badge
      }
      learning_material {
        audiobook
        course
        # event
        # event_tracker
        # practice_test
        # quiz
        ebook
        classroom
      }
      #microlearnings {
      #  name
      #  false_answer_count
      #  correct_answer_count
      #  question_total
      #}
    }
  }
`
