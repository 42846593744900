import React, {ChangeEvent} from 'react'
import {
  withStyles,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  FormControl,
  TextField,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {Theme} from '@material-ui/core/styles'
import RatingComponent from '../../utils-components/Rating.component'
import {useLocation} from 'react-router-dom'
import {ClassNameMap} from '@material-ui/core/styles/withStyles'
import {ICategoryGQL} from '../../../interfaces/Academic.interface'

type ConstantDataNumber = {
  label: string
  value: number
  options?: SubDetail[]
}

type ConstantDataString = {
  label: string
  value: string
  options?: SubDetail[]
}

type SubDetail = {
  id: number
  name: string
  value: string
  label: string
  options: SubDetail[]
}

type RangeInputNumber = {
  isOpen: boolean
  onChangeNumberMin: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeNumberMax: (e: ChangeEvent<HTMLInputElement>) => void
}

type SubExpanded = {
  [key: string]: boolean
}

type Value = {
  ratings: number | string
  price?: {
    priceMin: number | string
    priceMax: number | string
  }
}

type MoreOptions = {
  onClick: (e: ChangeEvent<HTMLInputElement>) => void
  isHidden: boolean
  maxLength: number
}

type CategoriesType = {
  label: string
  value: number
  options: ICategoryGQL[] | SubDetail[]
}

type ExpansionFilterProps = {
  expanded: boolean
  onChangePanel: (event: ChangeEvent<{}>, expanded: boolean) => void
  summary: string
  details: ConstantDataNumber[] | ConstantDataString[] | CategoriesType[]
  onChangeCheckbox?: (e: ChangeEvent<HTMLInputElement>) => void
  classes?: ClassNameMap
  rangeInputNumber?: RangeInputNumber
  hasSubCategories?: boolean
  subExpanded?: SubExpanded | undefined
  onChangeSubPanel?: (subPanel: string) => void
  moreOptions?: MoreOptions
  isRating?: boolean
  onChangeRadio?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: Value
  label: String
}

type SubCategoryListProps = {
  summary: string
  subSummary: string
  onChangeSubPanel?: (subPanel: string) => void
  subExpanded?: SubExpanded
  panelId: string
  subDetails: SubDetail[] | ICategoryGQL[]
  onChangeCheckbox?: (e: ChangeEvent<HTMLInputElement>) => void
}

type OptionListProps = {
  summary: string
  isRating?: boolean | undefined
  data: ConstantDataNumber | ConstantDataString | CategoriesType
  onChangeCheckbox?: (e: ChangeEvent<HTMLInputElement>) => void
  onChangeRadio?: (e: ChangeEvent<HTMLInputElement>) => void
  value?: Value
}

const styles = (theme: Theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  skyBlueColor: {
    color: '#039be5',
  },
  rangeInputRoot: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
})

const SubCategoryList = (props: SubCategoryListProps) => {
  const {
    summary,
    subSummary,
    onChangeSubPanel,
    subExpanded,
    panelId,
    subDetails,
    onChangeCheckbox,
  } = props

  return (
    <div className="pr-4">
      <ExpansionPanel
        elevation={0}
        expanded={subExpanded && subExpanded[Number(panelId)]}
        onChange={() => (onChangeSubPanel ? onChangeSubPanel(panelId) : true)}
      >
        <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className="font-bold" style={{fontSize: 12}}>
            {subSummary}
          </Typography>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <FormGroup>
            {subDetails?.map(
              (subDetail: SubDetail | ICategoryGQL, index: number) => {
                return (
                  <FormControlLabel
                    key={index}
                    name={summary.toLowerCase()}
                    control={
                      <Checkbox
                        value={subDetail.id}
                        onChange={onChangeCheckbox}
                      />
                    }
                    label={
                      <div className="flex flex-1 flex-row">
                        <Typography style={{fontSize: 12}}>
                          {subDetail.name}
                        </Typography>
                        {/* <Typography className="text-grey">
                        &nbsp;
                        {detail.foundLength || 0}
                      </Typography> */}
                      </div>
                    }
                  />
                )
              }
            )}
          </FormGroup>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

const OptionList = (props: OptionListProps) => {
  const {summary, isRating, data, onChangeCheckbox, onChangeRadio, value} =
    props
  const ratingValue = value && Number(value.ratings)

  if (isRating || summary === 'Price') {
    return (
      <FormControlLabel
        name={summary.toLowerCase()}
        control={
          <Radio
            value={data.value}
            onChange={onChangeRadio}
            checked={data.value === ratingValue}
          />
        }
        label={
          <div className="flex flex-1 flex-row">
            {summary !== 'Price' && (
              <RatingComponent
                averageRating={Number(data.value)}
                isSmallRating={true}
                ratingFilter={true}
                {...props}
              />
            )}
            <Typography
              variant="body2"
              className="textSecondary"
              style={{fontSize: '12px', marginLeft: '3px'}}
            >
              {data.label}
            </Typography>
            {/* <Typography className="text-grey">
            &nbsp;
            {data.foundLength || 0}
            </Typography> */}
          </div>
        }
      />
    )
  } else {
    return (
      <FormControlLabel
        name={summary.toLowerCase()}
        control={<Checkbox value={data.value} onChange={onChangeCheckbox} />}
        label={
          <div className="flex flex-1 flex-row">
            <Typography>{data.label}</Typography>
            {/* <Typography className="text-grey">
            &nbsp;
            {data.foundLength || 0}
            </Typography> */}
          </div>
        }
      />
    )
  }
}

const ExpansionFilter = (props: ExpansionFilterProps) => {
  const location = useLocation()
  const {pathname} = location

  const {
    classes,
    expanded,
    onChangePanel,
    summary,
    details,
    // moreOptions,
    rangeInputNumber,
    hasSubCategories,
    value,
    label,
  } = props

  const [listItem, setListItem] = React.useState(
    details.length > 4 && summary === 'Category' ? details.slice(0, 4) : details
  )

  /* let newDetails = details
  if (details.length > 4 && summary === 'Category') {
    newDetails = details.slice(0, 4)
  } */

  const renderRangeInputNumber = () => {
    const {onChangeNumberMin, onChangeNumberMax} = rangeInputNumber || {}

    const formMinValue = value?.price?.priceMin
    const formMaxValue = value?.price?.priceMax

    return (
      <>
        <FormControl className={classes?.margin}>
          <TextField
            type="number"
            id="priceMin"
            name="priceMin"
            placeholder="Min"
            classes={{
              root: classes?.rangeInputRoot,
              // input: classes.rangeInput,
            }}
            value={formMinValue}
            variant="outlined"
            fullWidth
            onChange={onChangeNumberMin}
            size="small"
            disabled={
              summary === 'Price' && value && Number(value.ratings) !== 2
            }
            InputProps={{inputProps: {min: 1}}}
          />
        </FormControl>
        <FormControl className={classes?.margin}>
          <TextField
            type="number"
            id="priceMax"
            name="priceMax"
            placeholder="Max"
            classes={{
              root: classes?.rangeInputRoot,
              // input: classes.rangeInput,
            }}
            value={formMaxValue}
            variant="outlined"
            fullWidth
            onChange={onChangeNumberMax}
            size="small"
            disabled={
              summary === 'Price' && value && Number(value.ratings) !== 2
            }
            InputProps={{inputProps: {min: formMinValue}}}
          />
        </FormControl>
      </>
    )
  }

  const renderSeeMoreBtn = (onClick: () => void) => {
    return (
      <Typography
        color="secondary"
        variant="body2"
        className="cursor-pointer px-4 my-2"
        onClick={onClick}
      >
        {listItem.length > 4 ? 'Lebih Sedikit' : 'Lebih Banyak'}
      </Typography>
    )
  }

  React.useEffect(() => {
    if (pathname) {
      setListItem(summary === 'Category' ? details.slice(0, 4) : details)
    }
  }, [pathname, details, summary])

  return (
    <ExpansionPanel elevation={0} expanded={expanded} onChange={onChangePanel}>
      <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          className="font-extrabold"
          color="primary"
          style={{fontSize: 14}}
        >
          {label || summary}
        </Typography>
      </ExpansionPanelSummary>
      {hasSubCategories ? (
        listItem.map(
          (
            detail: ConstantDataNumber | ConstantDataString | CategoriesType
          ) => {
            return (
              <SubCategoryList
                key={detail.value}
                panelId={String(detail.value)}
                subSummary={detail.label}
                subDetails={detail.options || []}
                {...props}
              />
            )
          }
        )
      ) : (
        <ExpansionPanelDetails>
          <FormGroup>
            {listItem?.map(
              (
                detail:
                  | ConstantDataNumber
                  | ConstantDataString
                  | CategoriesType,
                index: number
              ) => {
                return <OptionList key={index} data={detail} {...props} />
              }
            )}
            {/*             {moreOptions &&
              !moreOptions.isHidden &&
              newDetails.length >= moreOptions.maxLength && (
                <SeeMoreBtn onClick={moreOptions.onClick} />
              )} */}
            {rangeInputNumber &&
              rangeInputNumber.isOpen &&
              renderRangeInputNumber()}
          </FormGroup>
        </ExpansionPanelDetails>
      )}
      {summary === 'Category' &&
        details.length > 4 &&
        renderSeeMoreBtn(() => {
          if (listItem.length > 4) {
            return setListItem(details.slice(0, 4))
          } else {
            return setListItem(details)
          }
          /* if (newDetails.length > 4) {
            return (newDetails = details.slice(0, 4))
          } else {
            return (newDetails = details)
          } */
        })}
      {/* <SeeMoreBtn onClick={moreOptions?.onClick} /> */}
    </ExpansionPanel>
  )
}

// @ts-ignore
export default withStyles(styles, {withTheme: true})(ExpansionFilter)
