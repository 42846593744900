import React from 'react'

import {makeStyles, Tooltip} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey[300],
    color: theme.palette.common.black,
    fontSize: 14,
    maxWidth: 400,
    padding: 8,
  },
  arrow: {
    '&::before': {
      color: theme.palette.grey[300],
    },
  },
  icon: {
    fontSize: '1.25em',
    marginLeft: '0.5em',
    marginRight: '0.5em',
    position: 'relative',
  },
}))

function FieldTooltip(props) {
  const {title, placement = 'right', interactive} = props

  const styles = useStyles()

  return (
    <Tooltip
      title={title}
      placement={placement}
      arrow
      interactive={interactive}
      classes={{tooltip: styles.tooltip, arrow: styles.arrow}}
    >
      <ErrorOutlineIcon className={styles.icon} color="primary" />
    </Tooltip>
  )
}

export default FieldTooltip
