import React, {FC, ReactElement} from 'react'
import {Box, CircularProgress, Typography} from '@material-ui/core'
import {ChevronLeft, ChevronRight} from '@material-ui/icons'
import useStyles from '../../utils-components/GlobalStyles'
import LearnerCatalogCard from '../catalog/LearnerCatalogCard.component'
import {IBookList} from '../../../interfaces'
import {LearningType} from '../../../utils/enum'
import {RELATED_BOOKS} from '../../../provider/services/Book.service'

type RelatedBookProps = {
  authorWritten: string
  subBookCategoryId: number
  constributorId: string
  type: number
}

const RelatedBook: FC<RelatedBookProps> = ({
  authorWritten,
  subBookCategoryId,
  constributorId,
  type,
}): ReactElement => {
  const classes = useStyles()
  const [pagination, setPagination] = React.useState<{
    limit: number
    offset: number
    page: number
  }>({limit: 4, offset: 0, page: 1})

  const {data: relatedBookData, loading: relatedBookLoading} = RELATED_BOOKS({
    author: `%${authorWritten}%`,
    category: subBookCategoryId,
    contributor: constributorId,
    limit: pagination.limit,
    offset: pagination.offset,
    type: type,
  })

  if (relatedBookLoading && !relatedBookData) {
    return (
      <Box sx={{display: 'flex'}}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <div id="related-book">
      <div className="flex justify-between items-center">
        <Typography variant="h6" className="py-10 font-extrabold">
          {type === 2 ? 'Buku Elektronik' : 'Buku Audio'} Terkait
        </Typography>

        <div className="flex w-fit h-fit">
          {pagination.page > 1 ? (
            <div className={classes.chevronContainer}>
              <ChevronLeft
                className="cursor-pointer"
                onClick={() => {
                  let {page, offset} = pagination
                  const {limit} = pagination

                  page -= 1
                  offset = limit * (page - 1)

                  setPagination({
                    page,
                    offset,
                    limit,
                  })
                }}
              />
            </div>
          ) : (
            <div
              className={classes.chevronContainer}
              style={{backgroundColor: '#e5e5e5'}}
            >
              <ChevronLeft />
            </div>
          )}
          {pagination.page * pagination.limit <
          relatedBookData?.academy_books_aggregate?.aggregate?.count ? (
            <div className={classes.chevronContainer}>
              <ChevronRight
                className="cursor-pointer"
                onClick={() => {
                  let {page, offset} = pagination
                  const {limit} = pagination

                  page += 1
                  offset = limit * (page - 1)

                  setPagination({
                    page,
                    offset,
                    limit,
                  })
                }}
              />
            </div>
          ) : (
            <div
              className={classes.chevronContainer}
              style={{backgroundColor: '#e5e5e5'}}
            >
              <ChevronRight />
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-wrap -mx-3">
        {relatedBookData?.academy_books.map((item: IBookList, i: number) => (
          <LearnerCatalogCard
            type={LearningType.BOOK}
            item={item}
            key={`related-e-book-card${i}`}
            hidePrice={false}
          />
        ))}
      </div>
    </div>
  )
}

export default RelatedBook
