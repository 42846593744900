import React from 'react'
import {Field} from 'react-final-form'

import {JWVideo} from '../../../../../../../components/contributor/forms/JWVideoField'
import JWVideoPreview from '../../../../../../../components/contributor/misc/JWVideoPreview'
import LocalVideoPreview from '../../../../../../../components/contributor/misc/LocalVideoPreview'

import {type LessonViewProps} from './_Lesson'

interface VideoRef {
  src: JWVideo
  url: string
}

const VideoPreview = (props: {src: JWVideo}) => {
  const {src} = props

  const videoRef = React.useRef<VideoRef>()

  if (!videoRef.current || videoRef.current.src !== src) {
    if (videoRef.current && videoRef.current.src instanceof File) {
      URL.revokeObjectURL(videoRef.current.url)
    }

    const url = src instanceof File ? URL.createObjectURL(src) : src
    videoRef.current = {src, url}
  }

  return src instanceof File ? (
    <LocalVideoPreview src={videoRef.current!.url} />
  ) : src ? (
    <JWVideoPreview src={src} />
  ) : null
}

const VideoLessonView = (props: LessonViewProps) => {
  const {name} = props

  return (
    <div className="p-6">
      <div className="w-1/2 mx-auto aspect-video">
        <Field
          name={`${name}.video_url`}
          validateFields={[]}
          subscription={{value: true}}
        >
          {({input: {value}}) => <VideoPreview src={value} />}
        </Field>
      </div>
    </div>
  )
}

export default VideoLessonView
