import React from 'react'
import {Link, Outlet, useLocation, useParams} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {
  Button,
  Collapse,
  Divider,
  LinearProgress,
  List,
  Paper,
  Typography,
} from '@material-ui/core'

import Skeleton from 'react-loading-skeleton'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'

import {
  SidebarItem,
  SidebarMenu,
  SidebarNavItem,
} from '../../../../components/contributor/SidebarMenu'
import ContributorCreatorDetailLayout from '../../../../components/contributor/layout/ContributorCreatorDetailLayout'

import {USER_ID} from '../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../utils/contributor-helpers'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  GET_CONTRIBUTOR_COURSE_DETAILS,
  GET_CONTRIBUTOR_COURSE_PROGRESS,
} from '../../../../graphql/queries'
import {UPDATE_COURSE_STATUS} from '../../../../graphql/mutations'

// NOTE(intrnl): the queries for course statistics takes in the course ID
// instead of slug, which we don't have from the get go, we need to query for that.
export interface CoursePageContext {
  courseId?: number
}

const enum CourseStatus {
  ACTIVE = 'active',
  PENDING = 'pending',
  DRAFT = 'draft',
}

interface SidebarChild {
  title: string
  path: string
}

interface SidebarChildProps {
  children: React.ReactNode
  items?: SidebarChild[]
}

interface SectionData {
  id: string
  title: string
}

const CourseStatusLabels: {[key: string]: string | undefined} = {
  [CourseStatus.ACTIVE]: 'Active',
  [CourseStatus.PENDING]: 'Pending',
  [CourseStatus.DRAFT]: 'Draft',
} as const

const CourseStatusColors: {[key: string]: string | undefined} = {
  [CourseStatus.DRAFT]: undefined,
  [CourseStatus.ACTIVE]: '#4CAF50',
  [CourseStatus.PENDING]: '#EF4D5E',
} as const

/**
 * @params children
 * @params {Array} items(optional)
 * example items: example [{title: string, value: string}]
 *
 * */
const SidebarNavChildrens = ({
  children,
  items = [{title: 'title', path: '/'}],
}: SidebarChildProps) => {
  const {pathname} = useLocation()
  const [openChild, setOpenChild] = React.useState(() =>
    pathname.includes('/curriculums/')
  )

  const handleOpenChild = () => {
    setOpenChild((prevState) => !prevState)
  }
  return (
    <List disablePadding>
      <SidebarItem button onClick={handleOpenChild}>
        {children}
        <ExpandMoreIcon className={`ml-auto rotate-${openChild ? '180' : 0}`} />
      </SidebarItem>

      <Collapse in={openChild}>
        <List className="flex flex-col gap-2 mt-2" disablePadding>
          {items?.map((item, index) => (
            <SidebarNavItem to={item.path} key={index} className="pl-10">
              {item.title}
            </SidebarNavItem>
          ))}
        </List>
      </Collapse>
    </List>
  )
}

const CourseActions = (props: {courseId: number; status: CourseStatus}) => {
  const {courseId, status} = props

  const {enqueueSnackbar} = useSnackbar()

  const [dispatchStatusChange, {loading: dispatching}] = useMutation(
    UPDATE_COURSE_STATUS,
    {
      context: INSTRUCTOR_CONTEXT,
    }
  )

  const {data: progressionData} = useQuery(GET_CONTRIBUTOR_COURSE_PROGRESS, {
    skip: status !== CourseStatus.DRAFT,
    fetchPolicy: 'cache-and-network',
    variables: {
      courseId,
    },
  })

  const changeStatus = (next: CourseStatus) => {
    const promise = dispatchStatusChange({
      variables: {
        courseId,
        objects: {
          status: next,
        },
      },
    })

    promise.catch(() => {
      enqueueSnackbar(`Failed to update course status`, {variant: 'error'})
    })
  }

  if (status === CourseStatus.DRAFT) {
    let total = 0

    if (progressionData) {
      const prog = progressionData.getProgressCourseDetail
      total = Math.ceil(prog.landing_page + prog.target + prog.content)
    }

    return (
      <>
        {progressionData && (
          <>
            <Typography className="mb-2">Progress {total}%</Typography>

            <LinearProgress
              variant="determinate"
              value={total}
              color="secondary"
              className="h-2 grow rounded"
              classes={{bar: 'rounded'}}
            />
          </>
        )}

        <div className="mt-6 flex gap-4">
          <Button
            onClick={() => changeStatus(CourseStatus.DRAFT)}
            disabled={dispatching}
            color="primary"
            variant="outlined"
          >
            Save Draft
          </Button>

          <Button
            onClick={() => changeStatus(CourseStatus.PENDING)}
            disabled={dispatching}
            color="primary"
            variant="contained"
            disableElevation
          >
            Publish
          </Button>
        </div>
      </>
    )
  }

  if (status === CourseStatus.PENDING) {
    return (
      <div className="mt-6 flex gap-4">
        <Button
          onClick={() => changeStatus(CourseStatus.DRAFT)}
          disabled={dispatching}
          color="inherit"
          variant="outlined"
          className={!dispatching ? 'text-red-500' : ''}
        >
          Unpublish
        </Button>
      </div>
    )
  }

  if (status === CourseStatus.ACTIVE) {
    return (
      <div className="mt-6 flex gap-4">
        <Button
          onClick={() => changeStatus(CourseStatus.DRAFT)}
          disabled={dispatching}
          color="inherit"
          variant="outlined"
          className={!dispatching ? 'text-red-500' : ''}
        >
          Unpublish
        </Button>

        <Button
          onClick={() => changeStatus(CourseStatus.PENDING)}
          disabled={dispatching}
          color="primary"
          variant="contained"
          disableElevation
        >
          Publish
        </Button>
      </div>
    )
  }

  return null
}

const ContributorCourseDetailPageLayout = () => {
  const {courseSlug} = useParams()

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      slug: courseSlug,
      userId: USER_ID,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const course = data?.academy_courses[0]

  const Image = course && course.thumbnail ? 'img' : 'div'

  const sections = course?.academy_course_sections.map(
    (section: SectionData, idx: number) => ({
      title: `Section ${idx + 1}: ${section.title}`,
      path: `curriculums/${section.id}`,
    })
  )

  const context = React.useMemo(() => {
    const object: CoursePageContext = {
      courseId: course?.id,
    }

    return object
  }, [course])

  React.useEffect(() => {
    window.scrollTo({top: 0, behavior: 'auto'})
  }, [])

  // NOTE(intrnl): loading the data for the layout shouldn't block nested routes
  // from getting rendered and doing their thing.
  if (data && data.academy_courses.length < 1) {
    return (
      <ContributorCreatorDetailLayout label="Course Detail" to="../courses">
        <div>Course not found</div>
      </ContributorCreatorDetailLayout>
    )
  }

  return (
    <ContributorCreatorDetailLayout label="Course Detail" to="../courses">
      <Paper className="flex items-stretch gap-12 p-6">
        <div className="my-2 flex grow flex-col justify-between">
          <div>
            {course ? (
              <LinesEllipsis
                component={Typography}
                text={course.title}
                maxLine={3}
                color="primary"
                className="mb-1 text-lg font-bold"
              />
            ) : (
              <p className="mb-1 text-lg">
                <Skeleton width="50%" />
              </p>
            )}

            <Typography
              color="textSecondary"
              className="mb-4 text-sm"
              style={{color: course && CourseStatusColors[course.status]}}
            >
              {course ? (
                CourseStatusLabels[course.status]
              ) : (
                <Skeleton width={45} />
              )}
            </Typography>
          </div>

          <div>
            {course && (
              <CourseActions courseId={course.id} status={course.status} />
            )}
          </div>
        </div>

        <Image
          alt={course?.title}
          src={course?.thumbnail}
          className="aspect-video w-96 shrink-0 rounded bg-gray-200 object-cover"
        />
      </Paper>

      <div className="grid items-baseline gap-8 lg:grid-cols-[320px_minmax(0,_1fr)]">
        <SidebarMenu>
          <SidebarNavItem to="." end>
            Dashboard
          </SidebarNavItem>
          <SidebarNavItem to="manage">Manage</SidebarNavItem>
          <SidebarNavItem to="learners">Learner</SidebarNavItem>
          <SidebarNavItem to="earnings">Earnings</SidebarNavItem>
          <SidebarNavChildrens items={sections}>Curriculum</SidebarNavChildrens>
          {/* <SidebarNavItem to="reviews">Review</SidebarNavItem> */}
          <SidebarNavItem to="comments">Comment</SidebarNavItem>

          <Divider />

          <Button
            color="secondary"
            variant="contained"
            disableElevation
            className="mx-4 my-2"
            component={Link}
            to={`/contributor/courses/${course?.slug}/preview`}
          >
            Preview My Course
          </Button>
        </SidebarMenu>

        <Outlet context={context} />
      </div>
    </ContributorCreatorDetailLayout>
  )
}

export default ContributorCourseDetailPageLayout
