import {Form} from 'react-final-form'

import {Button, Typography} from '@material-ui/core'

import {
  combineValidators,
  isBlobSizeCorrect,
  isRequired,
} from '../../../../../../../components/input-forms/validators'
import FormText from '../../../../../../../components/input-forms/FormText'
import JWVideoField from '../../../../../../../components/contributor/forms/JWVideoField'

import {type LessonFormProps} from './_Lesson'
import {EditState} from '../_utils'

import useEvent from '../../../../../../../hooks/useEvent'

const validateVideo = combineValidators(isRequired, isBlobSizeCorrect(2048))

const VideoLessonForm = (props: LessonFormProps) => {
  const {initialValues, editState, onSubmit, onCancel} = props

  const isAdding = editState === EditState.ADDING
  const onSubmitCallback = useEvent(onSubmit)

  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmitCallback}
      subscription={{pristine: true}}
    >
      {({handleSubmit, pristine}) => (
        <div className="flex flex-col gap-6 p-6 text-sm">
          <Typography color="primary" className="font-bold text-sm">
            {isAdding ? 'Add' : 'Edit'} Video
          </Typography>

          <FormText
            name="title"
            validate={isRequired}
            label="Video Title*"
            placeholder="Add video title"
          />

          <JWVideoField
            name="video_url"
            validate={validateVideo}
            label="Upload Video*"
            buttonLabel="Add lesson video"
            description={
              <>
                Min Resolution Recommendation: 1280 x 720 (HD, 720p)
                <br />
                Type File: mp4
                <br />
                Max Size: 2 GB
              </>
            }
          />

          <div className="flex flex-row-reverse gap-4">
            <Button
              disabled={pristine}
              onClick={() => handleSubmit()}
              variant="contained"
              color="secondary"
              disableElevation
              className="h-12 w-24"
            >
              Confirm
            </Button>

            <Button
              onClick={onCancel}
              color="inherit"
              className="h-12 w-24 text-red-400"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </Form>
  )
}

export default VideoLessonForm
