import React from 'react'
import {Link as RouterLink, useParams, useSearchParams} from 'react-router-dom'
import {useQuery} from '@apollo/client'

import {format} from 'date-fns'

import {
  Avatar,
  Divider,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import Skeleton from 'react-loading-skeleton'

import {Pagination} from '../../../../../components/contributor/Pagination'

import {RemoteQuizResponsesModal} from './QuizResponsesModal'

import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSE_DETAILS} from '../../../../../graphql/queries'

const enum ModalState {
  NEVER_OPENED,
  UNOPEN,
  OPEN,
}

const ResponseRow = (props: {data?: any; lessonId: number; userId: number}) => {
  const {data, lessonId, userId} = props

  const [state, setState] = React.useState(ModalState.NEVER_OPENED)

  const formattedDate = React.useMemo(() => {
    if (!data) {
      return null
    }

    const date = new Date(data.date_submitted)

    return (
      <div className="flex gap-2 items-center">
        <span>{format(date, 'MMMM dd, yyyy')}</span>
        <Typography
          color="textSecondary"
          className="bg-current h-1 w-1 rounded-full"
        />
        <span>{format(date, 'hh.mm')}</span>
      </div>
    )
  }, [data])

  return (
    <TableRow>
      <TableCell>{formattedDate || <Skeleton />}</TableCell>
      <TableCell className="text-right">
        {data ? `${data.correct_answer}/${data.question}` : <Skeleton />}
      </TableCell>
      <TableCell className="text-right">
        {data ? Math.floor(data.score) : <Skeleton />}
      </TableCell>
      <TableCell className="text-right">
        {data ? (
          <Link
            component="button"
            onClick={() => setState(ModalState.OPEN)}
            color="secondary"
            className="text-sm"
          >
            Detail
          </Link>
        ) : (
          <Skeleton />
        )}
      </TableCell>

      {state !== ModalState.NEVER_OPENED && (
        <RemoteQuizResponsesModal
          open={state === ModalState.OPEN}
          lessonId={lessonId}
          userId={userId}
          sequenceId={data.log_sequence}
          onClose={() => setState(ModalState.UNOPEN)}
        />
      )}
    </TableRow>
  )
}

const ContributorCourseCurriculumResponseDetailPage = () => {
  const {sectionId, lessonId, userId} = useParams()

  const [searchParams, setSearchParams] = useSearchParams()

  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_QUIZ_RESPONSE_DETAILS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      lessonId: +lessonId!,
      userId: userId!,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const response = data?.response

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setSearchParams(next, {replace: true})
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setSearchParams(next, {replace: true})
  }

  return (
    <div className="flex flex-col gap-4">
      <div className="h-14 flex items-center gap-4">
        <IconButton
          component={RouterLink}
          to={`../curriculums/${sectionId}/${lessonId}?tab=responses`}
          edge="start"
        >
          <ArrowBackIcon />
        </IconButton>

        <Avatar alt={response?.user.name} src={response?.user.avatar} />

        <div className="grow">
          <Typography color="primary" className="text-lg">
            {response ? response.user.name : <Skeleton width="15%" />}
          </Typography>
          <Typography color="textSecondary" className="text-sm">
            {response ? (
              `${response.user.job_title} at ${response.user.company_name}`
            ) : (
              <Skeleton width="25%" />
            )}
          </Typography>
        </div>
      </div>

      <Paper>
        <Typography color="primary" className="px-6 py-4 font-bold">
          Quiz Resume
        </Typography>

        <Divider />

        <div className="flex gap-18 p-6 text-center justify-center">
          <div>
            <p className="mb-6">Lowest Score</p>

            <Typography className="text-red-400 text-5xl font-semibold">
              {response ? Math.floor(response.lowest_score) : <Skeleton />}
            </Typography>
          </div>

          <div>
            <p className="mb-6">Highest Score</p>

            <Typography color="primary" className="text-5xl font-semibold">
              {response ? Math.floor(response.highest_score) : <Skeleton />}
            </Typography>
          </div>
        </div>

        <Divider />

        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Repetition Date Submitted</TableCell>
              <TableCell className="text-right" style={{width: 240}}>
                Correct answer
              </TableCell>
              <TableCell className="text-right" style={{width: 240}}>
                Score
              </TableCell>
              <TableCell className="text-center" style={{width: 0}}>
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!response
              ? Array.from({length: limit}, (_, idx) => (
                  <ResponseRow key={idx} lessonId={+lessonId!} userId={0} />
                ))
              : response.data.map((item: any) => (
                  <ResponseRow
                    key={item.date_submitted}
                    data={item}
                    lessonId={+lessonId!}
                    userId={response.user.user_id}
                  />
                ))}
          </TableBody>
        </Table>

        <Pagination
          count={response ? response.count : 0}
          page={page}
          rowsPerPage={limit}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      </Paper>
    </div>
  )
}

export default ContributorCourseCurriculumResponseDetailPage
