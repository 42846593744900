import React from 'react'
import {Card, Avatar, Typography} from '@material-ui/core'
import {IEvent} from '../../../../interfaces'
import {
  CustomFlex,
  TextBlue,
  CustomTypography,
} from '../../../utils-components/GlobalStyles'
import {convertToRupiah} from '../../../../utils/helpers'
import {useNavigate} from 'react-router-dom'
import {format, parseISO} from 'date-fns'
import LinesEllipsis from 'react-lines-ellipsis'
import {bindHover, PopupState} from 'material-ui-popup-state/core'
import classNames from 'classnames'

import WishlistButton from '../../../button/WishlistButton.component'

type EventCardProps = {
  classes: any
  item: IEvent
  hidePrice: boolean
  popupState: PopupState
  isWishlist?: boolean
  removeFromWishlist?: () => void
  width?: string
  featureName?: string
  wishlistLoading?: boolean
}

const EventCard = ({
  classes,
  item,
  popupState,
  isWishlist,
  removeFromWishlist,
  featureName,
  width = 'w-full md:w-1/2',
  wishlistLoading,
}: EventCardProps): React.ReactElement => {
  const navigate = useNavigate()

  enum EventTypeID {
    Offline = 'Luring',
    Online = 'Daring',
  }

  return (
    <div className={width}>
      <Card
        className={classes.eventCard}
        {...bindHover(popupState)}
        onClick={() =>
          navigate(`/learner/event/${item.id}`, {
            state: {prevRouteName: featureName},
          })
        }
      >
        <div className={classes.eventCardContent}>
          <Avatar
            className={classes.mediaSquare}
            src={
              item.thumbnail ||
              'https://www.freecodecamp.org/news/content/images/size/w2000/2020/07/algebra.png'
            }
            variant="square"
            style={{margin: '10px'}}
          />
          {isWishlist && (
            <span style={{margin: '10px', position: 'absolute'}}>
              <WishlistButton
                isWishlist={true}
                action={removeFromWishlist}
                loading={Boolean(wishlistLoading)}
              />
            </span>
          )}

          <CustomFlex
            direction="column"
            justify="space-between"
            style={{
              height: '100%',
              padding: '5px 20px 5px 0px',
              width: '100%',
            }}
          >
            <CustomFlex direction="column">
              <CustomTypography
                fweight="bold"
                fsize="16px"
                className={classes.marginTextContent}
                style={{minHeight: 48}}
              >
                <LinesEllipsis
                  text={item.title}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </CustomTypography>
              <Typography
                variant="body2"
                color="textSecondary"
                className="mt-2 w-full"
              >
                <LinesEllipsis
                  text={item.CreatorUser?.name}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </Typography>
              {item.dateStart && (
                <CustomTypography fweight="bold" fsize="14px" mt="10px">
                  {format(parseISO(item.dateStart), 'MMM dd, yyyy')}
                </CustomTypography>
              )}

              <TextBlue
                className={classNames(classes.marginTextContent, 'mt-1')}
                variant="body2"
              >
                {EventTypeID[item?.EventType?.name as keyof typeof EventTypeID]}
              </TextBlue>
            </CustomFlex>
            {!isWishlist && (
              <CustomFlex justify="flex-end" className="w-full">
                <CustomTypography fweight="bold" fsize="14px" align="right">
                  {parseInt(item.pricing?.IDR) !== 0 &&
                  !isNaN(parseInt(item.pricing?.IDR))
                    ? convertToRupiah(parseInt(item.pricing?.IDR))
                    : 'Gratis'}
                </CustomTypography>
              </CustomFlex>
            )}
          </CustomFlex>
        </div>
      </Card>
    </div>
  )
}

export default EventCard
