import React from 'react'
import {InputAdornment, IconButton, makeStyles} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import {FormControlSearch, TextFieldSearch} from '../../filter/FilterStyles'

type LearnerSearchFormProps = {
  placeholder?: string
  value?: string
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingTop: 10,
    border: 'none',
  },
  dropdownWrapper: {
    maxHeight: '300px',
    position: 'absolute',
    marginTop: '55px',
    width: '100%',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    zIndex: 2,
    padding: 20,
  },
}))

function SearchSeeAll(props: LearnerSearchFormProps) {
  const classes = useStyles()

  return (
    <FormControlSearch variant="outlined">
      <TextFieldSearch
        placeholder={props.placeholder || 'Cari Kursus'}
        onChange={props.handleChange}
        value={props.value}
        name="search_filter"
        autoComplete="off"
        InputProps={{
          disableUnderline: true,
          classes: {
            root: classes.root,
          },
          startAdornment: (
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon style={{color: '#014a62'}} className="mr-4" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </FormControlSearch>
  )
}

export default SearchSeeAll
