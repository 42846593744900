import {LinearProgress} from '@material-ui/core'

export interface FormLoadingProps {
  progress?: number
}

const FormLoading = (props: FormLoadingProps) => {
  const {progress} = props

  return (
    <div className="absolute inset-0 z-[1]">
      <LinearProgress
        variant={progress === undefined ? 'indeterminate' : 'determinate'}
        value={progress || 0}
      />
    </div>
  )
}

export default FormLoading
