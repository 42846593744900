import React, {FC, ReactElement} from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core'
import {Close, Twitter, Facebook, Email} from '@material-ui/icons'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from 'react-share'

type ShareData = {
  isOpen: boolean
  copyStatus: boolean
}

type ShareCatalogItemDialogProps = {
  shareData: ShareData
  setShareData: (value: ShareData) => void
  shareableUrl: string
  title: string
  featureName?: string
}

const ShareCatalogItemDialog: FC<ShareCatalogItemDialogProps> = ({
  shareData,
  setShareData,
  shareableUrl,
  title,
  featureName = 'Kursus',
}: ShareCatalogItemDialogProps): ReactElement => {
  return (
    <Dialog
      fullWidth
      open={shareData.isOpen}
      onClose={() => setShareData({...shareData, isOpen: false})}
      maxWidth="sm"
    >
      <DialogTitle className="bg-slate-200">
        <div className="flex flex-row justify-between items-center">
          <Typography variant="h6">Bagikan {featureName}</Typography>
          <Close
            className="cursor-pointer"
            onClick={() => setShareData({...shareData, isOpen: false})}
          />
        </div>
      </DialogTitle>
      <Divider />

      <DialogContent className="flex flex-col py-6">
        <Typography variant="subtitle1" color="textSecondary" className="pb-2">
          Tautan {featureName}
        </Typography>
        <TextField
          fullWidth
          id="clipboardArea"
          variant="outlined"
          placeholder={`Shareable URL`}
          value={shareableUrl?.toString()}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Tooltip
                  title={shareData.copyStatus ? 'Copied!' : 'Click to copy'}
                  placement="top"
                >
                  <CopyToClipboard
                    text={shareableUrl}
                    onCopy={() =>
                      setShareData({
                        ...shareData,
                        copyStatus: true,
                      })
                    }
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        setShareData({
                          ...shareData,
                          copyStatus: true,
                        })
                      }}
                      color="primary"
                      variant="outlined"
                    >
                      <Typography>Salin</Typography>
                    </Button>
                  </CopyToClipboard>
                </Tooltip>
              </InputAdornment>
            ),
          }}
        />

        <Typography
          variant="subtitle1"
          className="pt-4 pb-2"
          color="textSecondary"
        >
          Bagikan ke:
        </Typography>
        <div className="flex space-x-5">
          <EmailShareButton
            url={shareableUrl}
            subject={title}
            body={shareableUrl}
            // @ts-ignore
            openWindow={true}
          >
            <Button variant="outlined" color="primary" startIcon={<Email />}>
              <Typography color="primary" variant="subtitle1">
                Email
              </Typography>
            </Button>
          </EmailShareButton>

          <FacebookShareButton url={shareableUrl} quote={title}>
            <Button
              variant="contained"
              className="bg-[#4267B2] text-white"
              startIcon={<Facebook />}
            >
              <Typography variant="subtitle1">Facebook</Typography>
            </Button>
          </FacebookShareButton>

          <TwitterShareButton
            url={shareableUrl}
            title={title}
            via="WLB Learning"
          >
            <Button
              variant="contained"
              className="bg-[#1DA1F2] text-white"
              startIcon={<Twitter />}
            >
              <Typography variant="subtitle1">Twitter</Typography>
            </Button>
          </TwitterShareButton>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ShareCatalogItemDialog
