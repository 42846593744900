import {gql} from '@apollo/client'

export const GET_CERTIFICATE_PROFILE = gql`
  query ($userId: uuid!) {
    academy_certificates(where: {student: {_eq: $userId}}) {
      id
      course
      code
      score
      name_to
      instructors
      date_added
      academy_course_enrollment {
        id
        academy_course {
          id
          title
          thumbnail
        }
      }
    }
  }
`
