import React, {type ChangeEvent} from 'react'
import {useQuery} from '@apollo/client'
import {type DocumentNode} from 'graphql'

import {Button, Divider, Paper, Tab, Tabs, Typography} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'

import NoListComponent from '../../../components/empty-state/NoList.component'

import {
  INSTRUCTOR_CONTEXT,
  formatCompactNominal,
} from '../../../utils/contributor-helpers'

import {USER_ID} from '../../../utils/globals'
import {
  GET_CONTRIBUTOR_POPULAR_BOOK,
  GET_CONTRIBUTOR_POPULAR_COURSE,
  GET_CONTRIBUTOR_POPULAR_MICROLEARNING,
} from '../../../graphql/queries'

const enum ItemType {
  COURSE = 'course',
  BOOK = 'book',
  MICROLEARNING = 'microlearning',
}

const enum BookSubtype {
  EBOOK = 'ebook',
  AUDIOBOOK = 'audiobook',
}

interface PopularItemsStatsData {
  id: string
  title: string
  thumbnail: string
  enrollments: {
    aggregate: {
      count: number
    }
  }
}

const ItemTypeQueries: Record<ItemType, DocumentNode> = {
  [ItemType.COURSE]: GET_CONTRIBUTOR_POPULAR_COURSE,
  [ItemType.BOOK]: GET_CONTRIBUTOR_POPULAR_BOOK,
  [ItemType.MICROLEARNING]: GET_CONTRIBUTOR_POPULAR_MICROLEARNING,
}

const renderBookSubtypeButton = (
  label: string,
  selected: boolean,
  onClick?: () => void
) => {
  return (
    <Button
      onClick={onClick}
      variant={selected ? 'contained' : 'text'}
      color={selected ? 'primary' : 'default'}
      className="px-6 py-2"
      disableElevation
    >
      {label}
    </Button>
  )
}

const ContributorPopularCatalogCard = () => {
  const [selected, setSelected] = React.useState(ItemType.COURSE)
  const [bookSubtype, setBookSubtype] = React.useState(BookSubtype.EBOOK)

  const {data} = useQuery(ItemTypeQueries[selected], {
    fetchPolicy: 'network-only',
    variables: {
      userId: USER_ID,
      bookType:
        selected === ItemType.BOOK
          ? bookSubtype === BookSubtype.EBOOK
            ? 2
            : 3
          : undefined,
      limit: 5,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const handleTabChange = (event: ChangeEvent<{}>, value: ItemType) => {
    setSelected(value)

    if (value === ItemType.BOOK && selected !== value) {
      setBookSubtype(BookSubtype.EBOOK)
    }
  }

  const bindBookChange = (value: BookSubtype) =>
    bookSubtype !== value ? () => setBookSubtype(value) : undefined

  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        Popular Catalog
      </Typography>

      <Tabs value={selected} onChange={handleTabChange} className="bg-gray-100">
        <Tab value={ItemType.COURSE} label="Course" />
        <Tab value={ItemType.BOOK} label="Book" />
        <Tab value={ItemType.MICROLEARNING} label="Micro Learning" />
      </Tabs>

      <Divider />

      {selected === ItemType.BOOK && (
        <div className="flex gap-4 px-6 py-4">
          {renderBookSubtypeButton(
            'E-Book',
            bookSubtype === BookSubtype.EBOOK,
            bindBookChange(BookSubtype.EBOOK)
          )}
          {renderBookSubtypeButton(
            'Audio Book',
            bookSubtype === BookSubtype.AUDIOBOOK,
            bindBookChange(BookSubtype.AUDIOBOOK)
          )}
        </div>
      )}

      {data ? (
        data.items.length < 1 ? (
          <div className="px-6 py-16">
            <NoListComponent
              title="Sorry, No List"
              subTitle="You don't have a popular catalog yet."
            />
          </div>
        ) : (
          <div className="pb-2">
            <div className="flex justify-between px-6 pt-4 pb-2 text-sm">
              <span>Catalog</span>
              <span className="text-right">Sold</span>
            </div>

            {data.items.map((item: PopularItemsStatsData) => {
              const Image = item.thumbnail ? 'img' : 'div'

              return (
                <div
                  key={item.id}
                  className="flex items-center gap-4 px-6 py-2"
                >
                  <Image
                    src={item.thumbnail}
                    className="h-[76px] w-[76px] shrink-0 rounded bg-gray-200 object-cover"
                  />

                  <LinesEllipsis
                    text={item.title}
                    maxLine={2}
                    className="grow overflow-hidden font-bold"
                  />

                  <span className="text-right font-bold text-2xl">
                    {formatCompactNominal(item.enrollments.aggregate.count)}
                  </span>
                </div>
              )
            })}
          </div>
        )
      ) : (
        <div className="pb-2">
          <div className="flex justify-between px-6 pt-4 pb-2 text-sm">
            <span>Catalog</span>
            <span className="text-right">Sold</span>
          </div>

          {Array.from({length: 5}, (_, idx) => (
            <div key={idx} className="-mt-1 flex items-center gap-4 px-6 py-2">
              <Skeleton className="h-16 w-16 shrink-0 rounded" />

              <span className="grow">
                <Skeleton width={`${Math.max(25, Math.random() * 100)}%`} />
              </span>
              <Skeleton width={29} />
            </div>
          ))}
        </div>
      )}
    </Paper>
  )
}

export default ContributorPopularCatalogCard
