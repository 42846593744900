import React from 'react'
import {useQuery} from '@apollo/client'

import {CircularProgress, Link} from '@material-ui/core'

import NoListComponent from '../../../../../components/empty-state/NoList.component'
import CommentRoot from './CommentRoot'

import {USER_ID} from '../../../../../utils/globals'
import {GET_COURSE_COMMENTS} from '../../../../../graphql/queries'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'

export interface DateRange {
  from?: Date | string
  to?: Date | string
}

export enum Answered {
  NONE = 'none',
  ANSWERED = 'answered',
  UNANSWERED = 'unanswered',
}

interface ChunkedRootListProps {
  courseId: number
  lessonId?: number | number[]
  date?: DateRange
  answers?: Answered
  sort?: 'asc' | 'desc'
  offset: number
  limit: number
  onShowMore?: () => void
}

const ChunkedRootList = (props: ChunkedRootListProps) => {
  const {
    courseId,
    lessonId,
    date,
    answers = Answered.NONE,
    sort = 'desc',
    offset,
    limit,
    onShowMore,
  } = props

  const {data, refetch} = useQuery(GET_COURSE_COMMENTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
      where: {
        deletedAt: {_is_null: true},
        lesson_id: lessonId ? {_in: lessonId} : undefined,
        academy_course_lesson: !lessonId
          ? {
              deletedAt: {_is_null: true},
              course_id: {_eq: courseId},
            }
          : undefined,
        date_added: date ? {_gte: date.from, _lte: date.to} : undefined,
        user_id: answers !== Answered.NONE ? {_nin: USER_ID} : undefined,
        _or:
          answers === Answered.UNANSWERED
            ? [
                {_not: {academy_discussion_responses: {}}},
                {academy_discussion_responses: {user_id: {_nin: USER_ID}}},
              ]
            : answers === Answered.ANSWERED
            ? [{academy_discussion_responses: {user_id: {_in: USER_ID}}}]
            : undefined,
      },
      order: {date_added: sort},
      offset,
      limit,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  if (!data) {
    return (
      <div className={`grid place-items-center ${offset === 0 ? 'py-12' : ''}`}>
        <CircularProgress />
      </div>
    )
  }

  const comments = data.comments

  if (comments.length < 1) {
    if (offset === 0) {
      return (
        <div className="py-6">
          <NoListComponent title="Sorry, No Comment List" />
        </div>
      )
    }

    return null
  }

  return (
    <>
      {comments.map((comment: any) => (
        <CommentRoot key={comment.id} data={comment} refetch={refetch} />
      ))}

      {onShowMore && comments.length >= limit && (
        <div>
          <Link
            component="button"
            color="secondary"
            className="text-sm"
            onClick={onShowMore}
          >
            See {sort === 'asc' ? 'Newer' : 'Older'} Comments
          </Link>
        </div>
      )}
    </>
  )
}

const MemoizedChunkedRootList = React.memo(ChunkedRootList)

export interface CommentRootListingProps {
  courseId: number
  lessonId?: number | number[]
  date?: DateRange
  answers?: Answered
  sort?: 'asc' | 'desc'
}

const CommentRootListing = (props: CommentRootListingProps) => {
  const {courseId, lessonId, date, answers = Answered.NONE, sort} = props

  const [pages, setPages] = React.useState(1)
  const MAX_ITEMS = 10

  const handleShowMore = React.useCallback(() => {
    return setPages((pages) => pages + 1)
  }, [])

  return (
    <>
      {Array.from({length: pages}, (_, idx) => (
        <MemoizedChunkedRootList
          key={idx}
          courseId={courseId}
          lessonId={lessonId}
          date={date}
          answers={answers}
          sort={sort}
          offset={idx * MAX_ITEMS}
          limit={MAX_ITEMS}
          onShowMore={idx === pages - 1 ? handleShowMore : undefined}
        />
      ))}
    </>
  )
}

export default CommentRootListing
