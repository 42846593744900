import {Modal, Box} from '@material-ui/core'
import {CustomTypography} from '../../../components/utils-components/GlobalStyles'
import {Close} from '@material-ui/icons'
import {
  IDictionaryTable,
  ILevel,
  IDetailDictionaryLevel,
} from '../../../interfaces/LearningProfile.interface'

type propsType = {
  open: boolean
  handleClose: () => void
  detail: IDetailDictionaryLevel
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
}

const DetailDictionaryModal = (props: propsType) => {
  const {open, handleClose, detail} = props

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{style: {backgroundColor: '#0000001a'}}}
    >
      <Box
        sx={style}
        className="w-96 lg:w-[700px] max-h-[600px] overflow-y-auto"
      >
        <div className="bg-[#eff2f4] px-6 py-7 flex items-center justify-between">
          <CustomTypography fsize="16px" fweight="bold">
            {detail.title}
          </CustomTypography>
          <button onClick={handleClose}>
            <Close style={{color: '#a9a8a8', fontSize: '30px'}} />
          </button>
        </div>
        <div className="mt-7 px-6 pb-7">
          {detail.datas.map((item: IDictionaryTable, i: number) => (
            <div key={i} className="flex gap-4 mb-5">
              <CustomTypography
                fcolor="#a9a8a8"
                fsize="12px"
                style={{width: '30%'}}
              >
                {item.name}
              </CustomTypography>
              <CustomTypography fsize="12px" style={{width: '70%'}}>
                {item.value}
              </CustomTypography>
            </div>
          ))}
          <div className="flex gap-4">
            <CustomTypography
              fcolor="#a9a8a8"
              fsize="12px"
              style={{width: '30%'}}
            >
              Level
            </CustomTypography>
            <div
              style={{
                width: '70%',
                boxSizing: 'border-box',
                overflowY: 'auto',
              }}
            >
              {detail.descriptionLevels.map((item: ILevel, i: number) => {
                return (
                  <div className="mb-2" key={i}>
                    <CustomTypography
                      fsize="12px"
                      dangerouslySetInnerHTML={{__html: item.description}}
                    />
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  )
}

export default DetailDictionaryModal
