import gql from 'graphql-tag'

export const GET_COURSE_CATEGORIES = gql`
  query getAcademyCategories {
    academy_course_categories(where: {parent: {_is_null: true}}) {
      id
      name
      parent
      academy_course_categories {
        id
        name
      }
    }
  }
`

export const GET_EVENT_CATEGORIES = gql`
  query getEventCategories {
    event_categories(where: {parent: {_is_null: true}}) {
      id
      name
      parent
      event_categories {
        id
        name
      }
    }
  }
`
