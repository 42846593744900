import React from 'react'
import {useLocation, useParams} from 'react-router-dom'
import {IBook} from '../../../../interfaces'
import {SeeAllPageContext} from '../../../../pages/learner/see-all'
import {
  LIST_RECOMMENDATION_BOOKS,
  LIST_TRENDING_BOOKS,
} from '../../../../provider/services/Book.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import SearchSeeAll from '../SearchSeeAll.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'

interface BookProps extends SeeAllPageContext {}

const Book = (props: BookProps): React.ReactElement => {
  const [search, setSearch] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const location = useLocation()
  const {active} = useParams()

  const isRecommended = location.pathname.includes('recommended')
  const bookType = active === 'ebook' ? 'Buku Elektronik' : 'Buku Audio'

  const title = isRecommended
    ? `Rekomendasi ${bookType} di SmartASN`
    : `Tren ${bookType} di SmartASN`

  const disableLoading = () => setLoading(false)

  const {
    data: dataTrending,
    refetch: refetchTrending,
    isLoading: trendingLoading,
  } = LIST_TRENDING_BOOKS(
    -1,
    1,
    active === 'ebook' ? 2 : 3,
    props.filter.priceMin,
    props.filter.priceMax,
    props.filter.rating,
    props.filter.categoriesREST as any,
    props.filter.levelsREST,
    props.filter.languagesREST
  )

  const {
    data: dataRecommended,
    refetch: refetchRecommended,
    isLoading: recommendedLoading,
  } = LIST_RECOMMENDATION_BOOKS(
    -1,
    1,
    active === 'ebook' ? 2 : 3,
    props.filter.priceMin,
    props.filter.priceMax,
    props.filter.rating,
    props.filter.categoriesREST as any,
    props.filter.levelsREST,
    props.filter.languagesREST
  )

  React.useEffect(() => {
    setLoading(true)
    if (location.pathname.includes('recommended')) {
      setTimeout(refetchRecommended, 1000)
    } else {
      setTimeout(refetchTrending, 1000)
    }
    setTimeout(disableLoading, 1000)
    window.scrollTo(0, 0)
  }, [search, refetchRecommended, refetchTrending, props.filter, location])

  const _response = isRecommended ? dataRecommended : dataTrending

  if (trendingLoading || recommendedLoading) {
    return <LoadingCardComponent length={4} />
  }

  const filtered = _response.data.filter((item: IBook) =>
    item.title.toLowerCase().includes(search.toLowerCase())
  )

  return (
    <div>
      <SearchSeeAll
        placeholder={`Cari ${bookType}`}
        value={search}
        handleChange={handleChange}
      />
      <div className="mt-8">
        <p className="mt-5 text-[#a9a8a8] text-sm">
          {filtered?.length} Hasil dari {title}
        </p>
        {trendingLoading || recommendedLoading || loading ? (
          <LoadingCardComponent length={4} />
        ) : filtered.length < 1 ? (
          <NoListComponent
            title="Tidak ada hasil yang ditemukan"
            subTitle="Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda"
          />
        ) : (
          <div className="grid grid-cols-2 lg:grid-cols-4">
            {filtered.map((e: IBook, index: number) => (
              <LearnerCatalogCard
                type={LearningType.BOOK}
                item={{...e, average_rating: e.averageRating}}
                key={index}
                hidePrice={false}
                width="w-full"
              />
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
export default Book
