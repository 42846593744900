import React, {useState} from 'react'
import {
  DialogContent,
  Typography,
  DialogActions,
  Button,
  Dialog,
  IconButton,
  Icon,
  Checkbox,
  makeStyles,
} from '@material-ui/core'

import {ModalHeader} from '../../../components/utils-components/GlobalStyles'

const useStyles = makeStyles(() => ({
  checkContainer: {
    display: 'flex',
    padding: '16px 0 0',
    alignItems: 'center',
  },
  checkbox: {padding: 5, marginRight: 10},

  content: {borderTop: '1px solid rgba(0, 0, 0, 0.12)', padding: 24},
  btnDel: {
    backgroundColor: '#ef4d5e',
    marginLeft: 10,
    color: '#fff',
    '&:hover': {
      backgroundColor: '#ef4d5e',
    },
  },
  actions: {paddingRight: 24, paddingBottom: 24},
  wordBreak: {wordBreak: 'break-all'},
}))

type DeletePopupProps = {
  open: boolean
  name?: String
  handleClose: () => void
  mutation?: () => void
  checkboxText?: string
}

export default function DeletePopup({
  open,
  handleClose,
  name,
  mutation,
  checkboxText = 'I understand that deleting this competence is permanent and cannot be undone',
}: DeletePopupProps) {
  const classes = useStyles()

  const [checked, setChecked] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setChecked(e.target.checked)
  return (
    <Dialog
      onClose={() => {
        handleClose()
        setChecked(false)
      }}
      open={open}
      fullWidth
      maxWidth="sm"
    >
      <ModalHeader bg="#fff">
        <Typography variant="h6" color="error" className={classes.wordBreak}>
          Delete {name}?
        </Typography>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          <Icon>close</Icon>
        </IconButton>
      </ModalHeader>
      <DialogContent className={classes.content}>
        <>
          {' '}
          <Typography variant="body2" display="inline">
            Are you sure to delete
          </Typography>
          <Typography
            variant="body2"
            color="error"
            display="inline"
            className={classes.wordBreak}
          >
            {' '}
            {name}{' '}
          </Typography>
          <Typography variant="body2" display="inline">
            permanently?
          </Typography>
          <div className={classes.checkContainer}>
            <Checkbox
              checked={checked}
              onChange={handleChange}
              color="primary"
              className={classes.checkbox}
            />
            <Typography variant="body2">{checkboxText}</Typography>
          </div>
        </>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={() => {
            handleClose()
            setChecked(false)
          }}
        >
          Cancel
        </Button>
        <Button
          className={classes.btnDel}
          variant="contained"
          disabled={!checked}
          onClick={() => {
            setChecked(false)
            if (mutation) mutation()
          }}
          style={{backgroundColor: '#ef4d5e', color: '#fff'}}
        >
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}
