import React from 'react'
import {
  createStyles,
  makeStyles,
  FormControlLabel,
  FormGroup,
  RadioGroup,
  Radio,
} from '@material-ui/core'

import {ILessonQuestion, IAnswerProps} from '../../../../../../interfaces'

import CheckIcon from '@material-ui/icons/Check'
import ClearIcon from '@material-ui/icons/Clear'

type QuizLessonMultipleChoiceProps = {
  data: ILessonQuestion

  isDecrementQuestionNumberAvailable: boolean
  isIncrementQuestionNumberAvailable: boolean
  isResult: boolean
  answers: IAnswerProps[]
  setAnswers: (answers: IAnswerProps[]) => void
}

const useStyles = makeStyles(() =>
  createStyles({
    styledCheckbox: {
      marginBottom: '3px',

      cursor: 'pointer',
      paddingLeft: '1rem',
    },
  })
)
const QuizLessonMultipleChoice = (props: QuizLessonMultipleChoiceProps) => {
  const classes = useStyles()
  const id = props.data.id
  const currentAnswer = props.answers.find((data) => data.questionId === id)
  const answerKey = props.data?.answerKeys

  const [questionExistInAnswers, setQuestionExistInAnswers] =
    React.useState<IAnswerProps>()

  React.useEffect(() => {
    setQuestionExistInAnswers(
      props.answers.find(
        (answer: IAnswerProps) => answer.questionId === props.data.id
      )
    )
  }, [props.data.id, props.answers])

  const correction = (choice: string) => {
    if (answerKey) {
      const isCorrect = answerKey.every((e) =>
        currentAnswer?.answers.includes(e)
      )
      if (choice === currentAnswer?.answers[0]) {
        if (isCorrect) {
          return <CheckIcon className="mt-2" style={{color: '#4CAF50'}} />
        } else {
          return <ClearIcon className="mt-2" style={{color: '#DC004E'}} />
        }
      } else {
        if (answerKey[0] === choice) {
          return <CheckIcon className="mt-2" style={{color: '#4CAF50'}} />
        } else {
          return ''
        }
      }
    }
  }

  return (
    <>
      <div className=" pb-36">
        <FormGroup>
          <RadioGroup
            onChange={(e) => {
              if (!props.isResult) {
                if (questionExistInAnswers) {
                  // TODO: exist
                  const indexExistingAnswer = props.answers.findIndex(
                    (item: IAnswerProps) => item.questionId === props.data.id
                  )

                  const _answers = [...props.answers]

                  _answers[indexExistingAnswer] = {
                    questionId: questionExistInAnswers.questionId,
                    answers: [e.target.value],
                  }

                  props.setAnswers(_answers)
                } else {
                  // TODO: not exist
                  props.setAnswers([
                    ...props.answers,
                    {questionId: props.data.id, answers: [e.target.value]},
                  ])
                }
              }
            }}
          >
            {props.data.choices.map((choice, index) => (
              <div className="flex flex-row" key={index}>
                <FormControlLabel
                  className={classes.styledCheckbox}
                  control={<Radio color="primary" />}
                  label={`${choice}`}
                  value={choice}
                  checked={questionExistInAnswers?.answers.includes(choice)}
                />
                {props.isResult && correction(choice)}
              </div>
            ))}
          </RadioGroup>
        </FormGroup>
      </div>
    </>
  )
}

export default QuizLessonMultipleChoice
