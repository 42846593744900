import React from 'react'

import {
  // LIST_ACADEMY_CATEGORIES,
  LIST_ACADEMY_CATEGORIES_WT_PAGINATION,
} from './services/Academy.service'

import {CircularProgress} from '@material-ui/core'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  changeActiveCatalogTab,
  changeKeyword,
  changeAcademicFilter,
  initialValue,
} from './reducers/Learner.reducer'

import {LearningType} from '../utils/enum'
import {LearnerAcademicContextType, PriceType, RatingType} from '../interfaces'

type LearnerAcademicProviderProps = {
  children: React.ReactNode
}

export const LearnerAcademicContext =
  React.createContext<LearnerAcademicContextType>(initialValue)

export const LearnerAcademicProvider = (
  props: LearnerAcademicProviderProps
) => {
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )

  const {data: allCategories, isLoading: categoriesLoading} =
    LIST_ACADEMY_CATEGORIES_WT_PAGINATION()

  // const {
  //   data: dataListAcademyCategories,
  //   isLoading: loadingListAcademyCategories,
  //   refetch: refetchAcademyCategories,
  // } = LIST_ACADEMY_CATEGORIES(
  //   state.limitAcademicCategories,
  //   state.pageAcademicCategories
  // )

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }
  const changeSearchKeywordHandler = async (keyword: string = '') => {
    return changeKeyword(dispatch, keyword)
  }
  const changeAcademicFilterHandler = async (
    rating: number,
    categories: string,
    levels: string,
    languages: string,
    priceMin: number,
    priceMax: number,
    priceGQL: PriceType,
    categoriesGQL: number[],
    ratingGQL: RatingType[],
    contributorLabels: string[]
  ) => {
    return changeAcademicFilter(
      dispatch,
      rating,
      categories,
      levels,
      languages,
      priceMin,
      priceMax,
      priceGQL,
      categoriesGQL,
      ratingGQL,
      contributorLabels
    )
  }

  const changeActiveTabHandler = async (activeTabCatalog: LearningType) => {
    return changeActiveCatalogTab(dispatch, activeTabCatalog)
  }

  // React.useEffect(() => {
  //   if (refetchAcademyCategories) refetchAcademyCategories()
  // }, [state.pageAcademicCategories, refetchAcademyCategories])

  if (categoriesLoading) {
    return <CircularProgress />
  }

  return (
    <LearnerAcademicContext.Provider
      value={{
        allCategories: allCategories || initialValue.academicCategories,
        academicCategories:
          // dataListAcademyCategories ||
          initialValue.academicCategories,
        changePageLimit: changePageLimitHandler,
        changeActiveTab: changeActiveTabHandler,
        changeSearchKeyword: changeSearchKeywordHandler,
        changeAcademicFilter: changeAcademicFilterHandler,
        reducerState: state,
        refetchAcademyCategories: undefined,
      }}
    >
      {props.children}
    </LearnerAcademicContext.Provider>
  )
}
