import React, {type ChangeEvent} from 'react'

import {Button, Dialog, Slider, Typography} from '@material-ui/core'

import ReactCrop, {
  type Crop,
  type PixelCrop,
  type ReactCropProps,
} from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import {ModalHeader} from './Modal'

// NOTE(intrnl): react-image-crop is a component that entirely off-hands its
// state, the problem is that we'd end up with hundreds or thousands of calls to
// onChange as the user tries to move around, or resize the crop area

// omit onChange from the wrapper's public API, use onCompleted instead
interface ReactCropWrapperProps extends Omit<ReactCropProps, 'onChange'> {}

const ReactCropWrapper = (props: ReactCropWrapperProps) => {
  const [initialCrop, setInitialCrop] = React.useState<Crop | undefined>()
  const [crop, setCrop] = React.useState<Crop | undefined>()

  if (initialCrop !== props.crop) {
    setInitialCrop(props.crop)
    setCrop(props.crop)

    return null
  }

  return <ReactCrop {...props} crop={crop} onChange={setCrop} />
}

export interface ImageCropModalProps {
  open?: boolean
  disabled?: boolean
  initialSource?: string | null
  aspect?: number
  onSubmit?: (blob: Blob) => void
  onClose?: () => void
}

// TODO(intrnl): check if canvas rendering is enabled on the browser
const ImageCropModal = (props: ImageCropModalProps) => {
  const {
    open = false,
    disabled,
    initialSource,
    aspect,
    onSubmit,
    onClose,
  } = props

  const imageRef = React.useRef<HTMLImageElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const [derivedState, setDerivedState] = React.useState(initialSource)

  const [source, setSource] = React.useState<string | null>(null)
  const [scale, setScale] = React.useState(1)
  const [crop, setCrop] = React.useState<PixelCrop | undefined>(undefined)

  if (derivedState !== initialSource) {
    setDerivedState(initialSource)
    setSource(initialSource || null)
    setCrop(undefined)
    return null
  }

  const handleFileChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target

    if (target.files && target.files.length > 0) {
      const reader = new FileReader()

      reader.addEventListener('load', () => {
        setSource(reader.result!.toString() || null)
        setCrop(undefined)
      })

      reader.readAsDataURL(target.files[0])
    }

    target.value = ''
  }

  const handleSliderChange = (_: ChangeEvent<{}>, value: number | number[]) => {
    if (typeof value === 'number') {
      setScale(value)
    }
  }

  const handleUploadClick = () => {
    inputRef.current!.click()
  }

  const handleSaveClick = () => {
    if (!onSubmit) {
      return
    }

    const image = imageRef.current!

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = window.devicePixelRatio

    const imageX = crop!.width * scaleX * pixelRatio
    const imageY = crop!.height * scaleY * pixelRatio

    const cropX = crop!.x * scaleX
    const cropY = crop!.y * scaleY

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    // NOTE(intrnl): TypeScript seems to be finicky with OffscreenCanvas
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')!

    canvas.width = imageX
    canvas.height = imageY

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    ctx.translate(-cropX, -cropY)
    ctx.translate(centerX, centerY)
    ctx.scale(scale, scale)
    ctx.translate(-centerX, -centerY)

    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight
    )

    canvas.toBlob((blob) => {
      // FIXME(intrnl): `blob` can be null
      if (!blob) {
        return
      }

      onSubmit(blob)
    })
  }

  return (
    <Dialog
      open={open}
      onClose={!disabled ? onClose : undefined}
      maxWidth="sm"
      fullWidth
    >
      <input
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />

      <ModalHeader onClose={onClose} disabled={disabled}>
        <Typography>Crop Photo</Typography>
      </ModalHeader>

      {source !== null ? (
        <ReactCropWrapper
          crop={crop}
          aspect={aspect}
          onComplete={setCrop}
          disabled={disabled}
          className="w-full"
        >
          <img
            ref={imageRef}
            src={source}
            style={{
              transform: `scale(${scale})`,
              height: '100%',
              width: '100%',
            }}
          />
        </ReactCropWrapper>
      ) : (
        <div
          style={{aspectRatio: '16 / 9'}}
          className="grid place-items-center"
        >
          <Typography color="textSecondary">No photo selected</Typography>
        </div>
      )}

      <div className="p-6 flex items-end justify-between gap-8 bg-[#f5f5f5]">
        <div className="grow flex flex-col">
          <span className="text-sm">Zoom</span>
          <Slider
            disabled={disabled || source === null}
            value={scale}
            onChange={handleSliderChange}
            min={1}
            max={3}
            step={0.1}
            valueLabelDisplay="auto"
            className="w-auto mx-1"
          />
        </div>

        <div className="flex gap-2">
          <Button
            disabled={disabled}
            onClick={handleUploadClick}
            variant="outlined"
            color="primary"
          >
            Upload Photo
          </Button>

          <Button
            disabled={
              disabled || !crop || crop.height === 0 || crop.width === 0
            }
            onClick={handleSaveClick}
            variant="contained"
            color="primary"
          >
            Save
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

export default ImageCropModal
