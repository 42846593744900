import {LinearProgress} from '@material-ui/core'

import SecondaryRating from './SecondaryRating'

interface RatingSummaryProps {
  average?: number
  total?: number

  total5?: number
  total4?: number
  total3?: number
  total2?: number
  total1?: number
}

const RatingBar = ({star, value}: {star: number; value: number}) => {
  const percentage = Math.trunc(value * 100)

  return (
    <tr>
      <td className="w-0">
        <SecondaryRating value={star} readOnly />
      </td>
      <td>
        <LinearProgress
          variant="determinate"
          color="secondary"
          value={percentage}
          className="grow h-2 rounded"
        />
      </td>
      <td className="w-10">
        <span>{percentage}%</span>
      </td>
    </tr>
  )
}

const RatingSummary = (props: RatingSummaryProps) => {
  const {average, total, total5, total4, total3, total2, total1} = props

  return (
    <div className="flex gap-6 items-center">
      <div className="flex flex-col gap-2 text-center">
        <span className="text-6xl">{(average || 0).toFixed(1)}</span>
        <div>
          <SecondaryRating value={average || 0} precision={0.5} readOnly />
        </div>
        <span className="text-xs">Based on {total || 0} reviews</span>
      </div>

      <table className="grow border-separate border-spacing-x-4 border-spacing-y-1 -mx-4 -my-1">
        <tbody className="text-sm text-right">
          <RatingBar star={5} value={(total5 || 0) / (total || 1)} />
          <RatingBar star={4} value={(total4 || 0) / (total || 1)} />
          <RatingBar star={3} value={(total3 || 0) / (total || 1)} />
          <RatingBar star={2} value={(total2 || 0) / (total || 1)} />
          <RatingBar star={1} value={(total1 || 0) / (total || 1)} />
        </tbody>
      </table>
    </div>
  )
}

export default RatingSummary
