import MicrolearningEnrollmentsCard from './MicrolearningEnrollmentsCard'
import MicroLearningActivityCard from './MicrolearningActivityCard'
import MicroLearningEarningsCard from './MicrolearningEarningsCard'

const ContribMicroLearningDashboarPage = () => {
  return (
    <div className="flex flex-col gap-4">
      <MicrolearningEnrollmentsCard />
      <MicroLearningActivityCard />
      <MicroLearningEarningsCard />
    </div>
  )
}

export default ContribMicroLearningDashboarPage
