import {gql} from '@apollo/client'

export const GET_MY_COURSE = gql`
  query (
    $userId: uuid!
    $search: String
    $category: Int_comparison_exp
    $level: Int_comparison_exp
    $language: String_comparison_exp
    $offset: Int
    $limit: Int
    $orderBy: [academy_courses_order_by!]
  ) {
    total: academy_courses_aggregate(
      where: {
        academy_course_enrollments: {user_id: {_eq: $userId}}
        _or: [
          {title: {_ilike: $search}}
          {subtitle: {_ilike: $search}}
          {description: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        category: $category
        level: $level
        language: $language
      }
    ) {
      aggregate {
        count
      }
    }
    academy_courses(
      where: {
        academy_course_enrollments: {user_id: {_eq: $userId}}
        _or: [
          {title: {_ilike: $search}}
          {subtitle: {_ilike: $search}}
          {description: {_ilike: $search}}
          {global_user: {name: {_ilike: $search}}}
        ]
        category: $category
        level: $level
        language: $language
      }
      offset: $offset
      limit: $limit
      order_by: $orderBy
    ) {
      id
      title
      subtitle
      slug
      thumbnail
      global_user {
        id
        name
      }
      academy_course_enrollments(where: {user_id: {_eq: $userId}}) {
        id
        completion_rate
        academy_certificates {
          id
          code
          name_to
          course_name
          score
          instructors
        }
      }
    }
  }
`
