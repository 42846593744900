import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { LearnerSidebar } from '../../../components/learner'
import {
  CustomTypography,
  StyledTabsWhite,
  StyledTabWhite,
} from '../../../components/utils-components/GlobalStyles'

import MyCourseComponent from './tabs/Course.component'
import MyBookComponent from './tabs/Book.component'
// import MyEventComponent from './tabs/Event.component'
import MySocialLearningComponent from './tabs/SocialLearning.component'
import MyMicroLearningComponent from './tabs/MicroLearning.component'

import {
  Icon,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
  Button,
  useMediaQuery,
} from '@material-ui/core'
import {
  sortOptMylearning,
  sortOptMylearningMicro,
} from '../../../utils/constans'
import { Filter } from '@smartasn/wlb-utils-components'

import { IFilter } from '../../../interfaces/Filter.interface'
import { mapFilterResults } from '../../../utils/helpers'
import {
  listFilter,
  listFilterBooks,
  listFilterSocial,
} from '../../../config/filter/DefaultFilter.config'
import UserLoggedInMobile from '../../../components/user-logged-in/UserLoggedInMobile.component'

export type TMyLearning = {
  search: string
  sort: string
  filterData: { [key: string]: number[] } | {}
}

const routes = ['courses', 'books', 'social-learnings', 'micro-learnings']

export default function MyLearningPage() {
  const navigate = useNavigate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))

  const { active } = useParams()
  const [tabValue, setTabValue] = useState(0)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState('latest')
  const [filterData, setFilterData] = useState({})

  const handleSort = (event: ChangeEvent<HTMLInputElement>) => {
    setSort(event.target.value)
  }

  const handleSearch = (evt: ChangeEvent<HTMLInputElement>) =>
    setSearch(evt.target.value)

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
    setSort('latest')
    navigate(routes[newValue])
  }

  const onApplyFilter = (result: IFilter) => {
    const mappedResult = mapFilterResults(result[0])
    setFilterData(mappedResult)
  }

  useEffect(() => {
    if (active) {
      const index = routes.indexOf(active)
      setTabValue(index)
    } else {
      setTabValue(0)
    }
  }, [active])

  const renderContent = () => {
    switch (tabValue) {
      case 1:
        return (
          <MyBookComponent
            search={search}
            sort={sort}
            filterData={filterData}
          />
        )
      /* case 4:
        return <MyEventComponent /> */
      case 2:
        return (
          <MySocialLearningComponent
            search={search}
            sort={sort}
            filterData={filterData}
          />
        )
      case 3:
        return (
          <MyMicroLearningComponent
            search={search}
            sort={sort}
            filterData={filterData}
          />
        )

      default:
        return (
          <MyCourseComponent
            search={search}
            sort={sort}
            filterData={filterData}
          />
        )
    }
  }

  const _sortOptions =
    tabValue === 3 ? sortOptMylearningMicro : sortOptMylearning

  const _filterOptions =
    tabValue === 1
      ? listFilterBooks
      : tabValue === 2
        ? listFilterSocial
        : listFilter

  return (
    <LearnerSidebar>
      <div className="p-6 md:pl-8 lg:pl-8">
        {isMobile && <UserLoggedInMobile />}
        <CustomTypography fsize="18px" fweight="bold">
          Pembelajaran Saya
        </CustomTypography>
        <TextField
          variant="outlined"
          placeholder="Cari pembelajaran saya"
          fullWidth
          value={search}
          onChange={handleSearch}
          style={{ boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)' }}
          className="mt-4 mb-6 bg-white"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Icon>search</Icon>
              </InputAdornment>
            ),
          }}
        />
        <StyledTabsWhite
          value={tabValue}
          indicatorColor="secondary"
          textColor="secondary"
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <StyledTabWhite label="Kursus" />
          <StyledTabWhite label="Buku" />
          {/* <StyledTabWhite label="Event" /> */}
          <StyledTabWhite label="Pembelajaran Sosial" />
          <StyledTabWhite label="Pembelajaran Mikro" />
        </StyledTabsWhite>
        <div className="flex justify-between items-center my-6">
          <TextField
            select
            value={sort}
            onChange={handleSort}
            SelectProps={{
              classes: {
                icon: 'text-black',
                root: 'font-bold',
              },
              disableUnderline: true,
            }}
          >
            {_sortOptions.map((_sort, i) => {
              return (
                <MenuItem value={_sort.value} key={i}>
                  {_sort.label}
                </MenuItem>
              )
            })}
          </TextField>
          <Filter
            language="indonesian"
            onApply={onApplyFilter}
            listFilter={_filterOptions}
            includeExclude={false}
            options={{
              isSetActiveMenuWhenOpen: true,
              setActiveMenuToIndex: 0,
              delayResetMenu: 500,
            }}
          >
            <Button size="large" variant="outlined" className="bg-white">
              <Icon color="primary" className="mr-4">
                filter_list
              </Icon>
              <Typography className="font-bold">Saring</Typography>
            </Button>
          </Filter>
        </div>
        {renderContent()}
      </div>
    </LearnerSidebar>
  )
}
