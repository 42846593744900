import {Typography} from '@material-ui/core'
import {FC, ReactElement, ReactNode} from 'react'
import Skeleton from 'react-loading-skeleton'
import {IMicroAboutMap, IMicroTabAbout} from '../../../../interfaces'
import Dompurify from 'dompurify'
import {microlearningType} from '../../../../utils/constans'
import {
  generateShrtDaySchedule,
  generateTimeSchedule,
} from '../../../../utils/helpers'

const TabAboutMicro: FC<IMicroTabAbout> = (
  props: IMicroTabAbout
): ReactElement => {
  const isLoading = !props
  const {
    countQuestions,
    language,
    category,
    subCategory,
    level,
    description,
    answerQuestion,
    time,
    isOwned,
  } = props

  const resultAnswerQuestion = generateShrtDaySchedule(answerQuestion)
  const isScheduled = resultAnswerQuestion?.length > 0
  const resultTime = generateTimeSchedule(time)
  const data: IMicroAboutMap = {
    Pertanyaan: countQuestions ?? '-',
    ...(isOwned && {
      'Jawab Pertanyaan': isScheduled ? `Setiap ${resultAnswerQuestion}` : '-',
      Waktu: isScheduled ? resultTime : '-',
    }),
    Bahasa: language ?? '-',
    Kategori: category ?? '-',
    'Sub Kategori': subCategory ?? '-',
    Tingkatan: microlearningType[level] ?? '-',
  }

  if (isLoading) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }
  return (
    <div id="tab-about-event">
      <Typography variant="body1" className="font-bold">
        Rincian
      </Typography>

      <table className="mt-4 w-fit">
        <tbody>
          {Object.entries(data).map(([key, value], index): ReactNode => {
            return (
              <tr className="h-8" key={`${key}-${index}`}>
                <td className="align-top w-min-fit">
                  <Typography variant="subtitle2" className="text-gray-700">
                    {key}
                  </Typography>
                </td>

                <td className="align-top w-5">
                  <Typography
                    variant="subtitle2"
                    className="text-gray-700 text-center"
                  >
                    :
                  </Typography>
                </td>

                <td className="align-top">
                  <Typography
                    variant="subtitle2"
                    className="text-gray-400 whitespace-normal"
                  >
                    {value}
                  </Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Typography variant="body1" className="mt-8 font-bold">
        Deskripsi
      </Typography>

      <div
        className="mt-4 mb-10 lg:mb-0 text-sm text-gray-700 whitespace-normal"
        dangerouslySetInnerHTML={{__html: Dompurify.sanitize(description)}}
      />
    </div>
  )
}

export default TabAboutMicro
