import {gql} from '@apollo/client'

export const ADD_MICROLEARNING_TO_WISHLIST = gql`
  mutation ($objects: [microlearning_wishlists_insert_input!]!) {
    insert_microlearning_wishlists(objects: $objects) {
      affected_rows
      returning {
        id
        microlearning
        user_id
      }
    }
  }
`

export const DELETE_MICROLEARNING_FROM_WISHLIST = gql`
  mutation ($id: bigint!) {
    update_microlearning_wishlists(
      where: {id: {_eq: $id}}
      _set: {deleted_at: "now()"}
    ) {
      returning {
        id
        microlearning
      }
    }
  }
`
