import React, {FC, ReactElement} from 'react'
import {Typography} from '@material-ui/core'
import {LinearProgressRating} from '../utils-components/GlobalStyles'
import DisplayRatingStar from './DisplayRatingStar.component'

type RatingSummarySectionProps = {
  ratingAvg: number
  ratingCount: number
  ratingSum: Array<number>
}

const RatingSummarySection: FC<RatingSummarySectionProps> = ({
  ratingAvg,
  ratingCount,
  ratingSum,
}: RatingSummarySectionProps): ReactElement => {
  return (
    <div className="flex w-full pt-4 space-x-14">
      <div className="flex flex-col w-fit">
        <Typography className="text-grey-darker text-7xl">
          {ratingAvg?.toFixed(1) || 0}
        </Typography>

        <div className="flex mt-1">
          <DisplayRatingStar count={Number(ratingAvg)} starSize="medium" />
        </div>

        <Typography variant="subtitle2" className="whitespace-nowrap">
          {`Berdasarkan ${ratingCount} ulasan`}
        </Typography>
      </div>

      <div className="w-2/3">
        {ratingSum.map((ratingItem: number, index: number) => (
          <div
            key={`start-linear-book-rating-${index}`}
            className="flex items-center space-x-2"
          >
            <DisplayRatingStar count={5 - index} />
            <LinearProgressRating
              variant="determinate"
              value={ratingItem || 0}
              className="w-full"
            />
            <Typography
              className="text-right min-w-[40px]"
              variant="subtitle2"
              color="textSecondary"
            >
              {ratingItem || 0}%
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}

export default RatingSummarySection
