import {gql} from '@apollo/client'

export const GET_DETAIL_CONTRIBUTOR_BOOKS = gql`
  query (
    $id: uuid!
    $search: String
    $type: Int
    $ordering: [academy_books_order_by!]
  ) {
    academy_books(
      where: {
        owner: {_eq: $id}
        title: {_ilike: $search}
        book_type: {_eq: $type}
        status: {_eq: "active"}
      }
      order_by: $ordering
    ) {
      id
      title
      isbn
      thumbnail
      type: academy_book_type {
        id
      }
      global_user {
        id
        name
      }
      average_rating
      pricing_idr
      book_type
      count_reviews
      summary
      academy_course_category {
        id
        name
      }
    }
  }
`
