import {useFieldArray} from 'react-final-form-arrays'

import {Droppable} from 'react-beautiful-dnd'
import {Button} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Section from './Section'
import {getInterimId, EMPTY_ARRAY} from './_utils'

export interface SectionListProps {
  name: string
}

const SectionList = (props: {name: string}) => {
  const {name} = props

  const {fields} = useFieldArray(name)

  return (
    <>
      <Droppable droppableId="sections" type="section">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {(fields.value || EMPTY_ARRAY).map((value, index) => {
              const id = '' + value.id

              return (
                <Section
                  key={id}
                  id={id}
                  index={index}
                  name={`${name}[${index}]`}
                  disableDelete={index === 0}
                  onDelete={() => fields.remove(index)}
                />
              )
            })}

            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <div>
        <Button
          onClick={() => {
            fields.push({
              id: getInterimId(),
            })
          }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          className="h-12 w-44"
        >
          Add Section
        </Button>
      </div>
    </>
  )
}

export default SectionList
