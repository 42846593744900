import gql from 'graphql-tag'

export const CONTRIBUTOR_UPDATE_EVENT = gql`
  mutation ($set: event_schedules_set_input, $eventId: Int!) {
    update_event_schedules_by_pk(_set: $set, pk_columns: {id: $eventId}) {
      id
    }
  }
`

export const CONTRIBUTOR_CREATE_EVENT = gql`
  mutation ($objects: [event_schedules_insert_input!]!) {
    insert_event_schedules(objects: $objects) {
      returning {
        id
      }
    }
  }
`

export const ADD_EVENT_LANDING_FILES = gql`
  mutation ($objects: [event_schedule_files_insert_input!]!) {
    insert_event_schedule_files(objects: $objects) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_EVENT_LANDING_FILES = gql`
  mutation ($fileId: bigint!, $objects: event_schedule_files_set_input!) {
    update_event_schedule_files_by_pk(
      pk_columns: {id: $fileId}
      _set: $objects
    ) {
      id
    }
  }
`

export const DELETE_EVENT_LANDING_FILES = gql`
  mutation ($fileIds: [Int!]) {
    delete_event_schedule_files(where: {id: {_in: $fileIds}}) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const UPDATE_EVENT_LANDING_DETAILS = gql`
  mutation ($eventId: Int!, $objects: event_schedules_set_input!) {
    update_event_schedules_by_pk(pk_columns: {id: $eventId}, _set: $objects) {
      id
    }
  }
`
