import gql from 'graphql-tag'

export const GET_MY_MICRO_LEARNING = gql`
  query getMyMicrolearning(
    $userId: uuid!
    $orderBy: [microlearnings_order_by!]
    $offset: Int
    $limit: Int
    $level: [Int!]
    $category: [Int!]
    $language: [Int!]
    $search: String
  ) {
    finished_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: true}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    on_progress_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: false}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings_aggregate(
      where: {
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
        level: {_in: $level}
        category_id: {_in: $category}
        language_id: {_in: $language}
        _or: [{name: {_ilike: $search}}, {description: {_ilike: $search}}]
      }
    ) {
      aggregate {
        count
      }
    }
    microlearnings(
      where: {
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
        level: {_in: $level}
        category_id: {_in: $category}
        language_id: {_in: $language}
        _or: [{name: {_ilike: $search}}, {description: {_ilike: $search}}]
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      name
      description
      price
      icon_url
      average_rating
      review_count
      question_count
      global_user {
        id
        name
        avatar
      }
      academy_course_category {
        id
        name
      }
      global_language {
        id
        name
      }
      level
      microlearning_members(where: {user_id: {_eq: $userId}}) {
        id
        schedule
        progress
        question_generated_count
        score
      }
    }
  }
`

export const GET_MY_MICROLEARNING_COUNT = gql`
  query ($userId: uuid!, $microlearningIds: [uuid!]) {
    microlearnings_aggregate(
      where: {
        id: {_in: $microlearningIds}
        microlearning_members: {user_id: {_eq: $userId}, is_valid: {_eq: true}}
      }
    ) {
      aggregate {
        count
      }
    }
  }
`

export const GET_MYLEARNING_MICROLEARINNG = gql`
  query getMyLearningMicrolearning(
    $userId: uuid!
    $orderBy: [microlearning_members_order_by!]
    $offset: Int
    $limit: Int
    $level: [Int!]
    $category: [Int!]
    $language: [Int!]
    $search: String
  ) {
    finished_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: true}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    on_progress_count: microlearnings_aggregate(
      where: {
        microlearning_members: {
          is_finished: {_eq: false}
          user_id: {_eq: $userId}
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearning_members_aggregate(
      where: {
        user_id: {_eq: $userId}
        is_valid: {_eq: true}
        microlearning: {
          level: {_in: $level}
          category_id: {_in: $category}
          language_id: {_in: $language}
          _or: [
            {name: {_ilike: $search}}
            {description: {_ilike: $search}}
            {global_user: {name: {_ilike: $search}}}
          ]
        }
      }
    ) {
      aggregate {
        count
      }
    }
    microlearning_members(
      where: {
        user_id: {_eq: $userId}
        is_valid: {_eq: true}
        microlearning: {
          level: {_in: $level}
          category_id: {_in: $category}
          language_id: {_in: $language}
          _or: [
            {name: {_ilike: $search}}
            {description: {_ilike: $search}}
            {global_user: {name: {_ilike: $search}}}
          ]
        }
      }
      order_by: $orderBy
      offset: $offset
      limit: $limit
    ) {
      id
      schedule
      progress
      question_generated_count
      score
      microlearning {
        id
        name
        description
        price
        icon_url
        average_rating
        review_count
        question_count
        global_user {
          id
          name
          avatar
        }
        academy_course_category {
          id
          name
        }
        global_language {
          id
          name
        }
        level
      }
    }
  }
`
