import {gql} from '@apollo/client'

export const ADD_ITEM_TO_CART = gql`
  mutation ($objects: [marketplace_user_carts_insert_input!]!) {
    insert_marketplace_user_carts(
      objects: $objects
      on_conflict: {
        constraint: marketplace_user_carts_user_item_table_item_id_key
        update_columns: [item_object, item_quantity, item_vendor]
      }
    ) {
      affected_rows
      returning {
        id
      }
    }
  }
`

export const CHECKOUT_FREE_ITEM = gql`
  mutation ($cart: JSON!, $shipping: JSON!) {
    checkout(payment: "wallet", cart: $cart, shipping: $shipping) {
      success
      payment_data
    }
  }
`
