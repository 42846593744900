import {format, parseISO} from 'date-fns'
import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {ILearningDiary} from '../../../interfaces/LearningDiary.interface'
import {LIST_LEARNING_DIARY} from '../../../provider/services/LearningProfile.service'

import {timeConvert} from '../../../utils/helpers'
import {LEARNING_DIARY_ACTIVITY} from '../../../utils/constans'
import LoadingCircular from '../../loading/LoadingCircular.component'
import {CustomTypography} from '../../utils-components/GlobalStyles'
import LinesEllipsis from 'react-lines-ellipsis'
import NoListComponent from '../../empty-state/NoList.component'

const LearningDiaryCard = () => {
  const navigate = useNavigate()
  const {data, loading, refetch} = LIST_LEARNING_DIARY(4)

  useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  if (loading) {
    return <LoadingCircular />
  }

  const dataDiary = data.learning_diaries

  // console.log({data})
  return (
    <div className="shadow-xl bg-white">
      <div className="p-6 flex justify-between items-center">
        <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
          Catatan Pembelajaran Harian
        </CustomTypography>
        <button
          onClick={() => navigate('/profile/learning-diary/create')}
          className="text-2xl bg-transparent text-[#039be5]"
        >
          +
        </button>
      </div>
      <hr />
      {dataDiary[0] ? (
        <>
          <div className="pl-5 mt-7 space-y-5 pb-5">
            {dataDiary?.map((item: ILearningDiary, id: number) => {
              const {activity} = item.learning_diary_activity || {}
              return (
                <div
                  key={id}
                  className=""
                  onClick={() =>
                    navigate(`/profile/learning-diary/${item.id}/detail`)
                  }
                >
                  <CustomTypography fsize="14px" fweight="bold">
                    <LinesEllipsis
                      text={item.subject}
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                    />
                  </CustomTypography>
                  <CustomTypography
                    fsize="12px"
                    mt="4px"
                    mb="4px"
                    fcolor="#039be5"
                  >
                    {LEARNING_DIARY_ACTIVITY?.[activity] || activity} -{' '}
                    {item.learning_diary_competency?.name ||
                      item.competency_dictionary?.name?.id ||
                      ''}
                  </CustomTypography>
                  <CustomTypography
                    fsize="12px"
                    fcolor="#a9a8a8"
                    fweight="bold"
                  >
                    {format(parseISO(item.date), 'MMMM dd, yyyy')} -{' '}
                    {timeConvert(item.duration)}
                  </CustomTypography>
                </div>
              )
            })}
          </div>
          <hr />
          <div className="p-6 ">
            <button
              onClick={() => navigate('/profile/learning-diary')}
              className="text-xs bg-transparent text-[#039be5]"
            >
              Lihat Semua
            </button>
          </div>
        </>
      ) : (
        <div className="p-5">
          <NoListComponent
            imageSize={150}
            title="Maaf, tidak ada daftar"
            subTitle="Catatan pembelajaran harian tidak ditemukan"
          />
        </div>
      )}
    </div>
  )
}

export default LearningDiaryCard
