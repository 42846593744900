import {InputAdornment, Typography} from '@material-ui/core'

import FormText, {type FormTextProps} from './FormText'

import {formatNominal} from '../../utils/helpers'

export interface FormPriceProps
  extends Omit<FormTextProps, 'format' | 'parse'> {}

const parsePrice = (value: string) => {
  const numbers = value.replace(/[^\d]/g, '')
  return numbers ? parseInt(numbers) : 0
}

const formatPrice = (value: any) => {
  return value === undefined ? '0' : formatNominal(value)
}

const FormPrice = (props: FormPriceProps) => {
  const {inputProps, ...rest} = props

  return FormText({
    parse: parsePrice,
    format: formatPrice,
    inputProps: {
      startAdornment: (
        <InputAdornment position="start" disableTypography>
          <Typography color="textSecondary" className="text-sm">
            Rp
          </Typography>
        </InputAdornment>
      ),
      classes: {input: 'text-right'},
      ...inputProps,
    },
    ...rest,
  })
}

export default FormPrice
