import React, {ChangeEvent} from 'react'
import {Button} from '@material-ui/core'
import {VENDOR_URL} from '../../utils/globals'

const AddToCartButtonComponent = (props: {
  isInCart: Boolean
  action?: () => void
  className?: string
  size?: 'small' | 'medium' | 'large'
  fullWidth?: boolean
}): React.ReactElement => {
  const handleClick = (e: ChangeEvent<{}>) => {
    e.stopPropagation()
    if (props.isInCart) {
      window.open(`${VENDOR_URL}/profile/shopping-cart`)
      return
    }

    if (props.action) {
      props.action()
    }
  }

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      size={props.size}
      className={props.className}
      fullWidth={props.fullWidth}
      style={{textTransform: 'none'}}
    >
      {props.isInCart ? 'Pergi' : 'Tambahkan'} ke Keranjang
    </Button>
  )
}

export default AddToCartButtonComponent
