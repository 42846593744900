import {useNavigate} from 'react-router-dom'
import {Field} from 'react-final-form'
import {useApolloClient, useMutation} from '@apollo/client'
import {useTitle} from 'hoofd'

import CreateWizard from '../../../components/input-forms/CreateWizard'
import FormValidatedText from '../../../components/input-forms/FormValidatedText'
import {
  combineValidators,
  isRequired,
  showErrorOnChange,
} from '../../../components/input-forms/validators'

import AcademyCategoryFields from '../../../components/contributor/forms/AcademyCategoryFields'

import {GET_CONTRIBUTOR_MICROLEARNING_SLUG_AVAILABILITY} from '../../../graphql/queries'
import {CREATE_MICROLEARNING} from '../../../graphql/mutations'

export const validateMicrolearningTitle = combineValidators(
  isRequired,

  (value: string) =>
    value.length < 5 &&
    `Micro Learning title is too short, it must be 5 characters or longer`,

  (value: string) =>
    value.length > 150 &&
    `Micro Learning title is too long, only 150 characters can be submitted`
)

const RE_VALID_SLUG = /^[a-z0-9-]+$/

export const validateMicrolearningSlug = combineValidators(
  isRequired,

  (value: string) =>
    value.length > 150 &&
    `Slug is too long, only 150 characters can be submitted`,

  (value: string) =>
    !RE_VALID_SLUG.test(value) &&
    `Slug can only contain lowercase letters "a-z", numbers "0-9" and hyphen "-"`,

  (value: string) => value === 'create' && `Slug already used`
)

interface FormValues {
  title: string
  category: number
  subcategory: number
  slug: string
}

const ContributorMicroLearningCreatePage = () => {
  useTitle(`Create Micro Learning`)

  const client = useApolloClient()
  const navigate = useNavigate()

  const [createMicrolearning] = useMutation(CREATE_MICROLEARNING, {
    context: {
      headers: {
        'X-Hasura-Role': 'organization-hr-admin',
      },
    },
  })

  const handleSubmit = async (data: FormValues) => {
    const {data: availabilityData} = await client.query({
      query: GET_CONTRIBUTOR_MICROLEARNING_SLUG_AVAILABILITY,
      fetchPolicy: 'network-only',
      variables: {slug: data.slug},
    })

    if (availabilityData.microlearnings.length > 0) {
      return {
        slug: `Slug already used`,
      }
    }

    await createMicrolearning({
      variables: {
        name: data.title,
        category_id: data.subcategory,
        slug: data.slug,
        description: '',
        price: 0,
        avatar_url: '',
      },
    })
      .then((res: any) => {
        const microlearningId = res?.data?.microlearningNew?.id

        navigate(`/contributor/microlearning/${microlearningId}`)
      })
      .catch(() => {
        return {
          slug: `Failed to create micro learning`,
        }
      })
  }

  return (
    <CreateWizard
      onSubmit={handleSubmit}
      onCancel={() => navigate(-1)}
      title="Create Micro Learning"
    >
      <CreateWizard.Page
        label="Micro Learning Title"
        title="How About Micro Learning Title?"
        description="It's ok if you can't think of a good title now. You can change it later."
      >
        <Field name="slug" subscription={{}}>
          {({input: {onChange}}) => (
            <FormValidatedText
              name="title"
              label="Micro Learning Title"
              placeholder="Add micro learning title"
              validate={validateMicrolearningTitle}
              showCharLimit={150}
              fullWidth
              autoFocus
              onChange={(ev) => {
                const value = ev.target.value

                const rand = Math.random().toString(16).slice(2, 7)
                const title = value
                  .toLowerCase()
                  .replace(/\s+/g, '-')
                  .replace(/[^a-z0-9-]/g, '')
                  .slice(0, 48 - rand.length - 1)

                onChange(`${title}-${rand}`)
              }}
            />
          )}
        </Field>
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Micro Learning Category"
        title="What category best fits the knowledge you'll share?"
        description="It's ok if you can't think of a good category now. You can change it later."
      >
        <AcademyCategoryFields autoFocus />
      </CreateWizard.Page>

      <CreateWizard.Page
        label="Micro Learning Slug"
        title="Give a slug for your new micro learning"
        description="Slug is the short identity of your micro learning. Example 'advance-economics'"
      >
        <FormValidatedText
          name="slug"
          label="Micro Learning Slug"
          placeholder="Add micro learning slug"
          validate={validateMicrolearningSlug}
          showError={showErrorOnChange}
          fullWidth
          autoFocus
        />
      </CreateWizard.Page>
    </CreateWizard>
  )
}

export default ContributorMicroLearningCreatePage
