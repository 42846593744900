import {AppBar, Step, StepLabel, Stepper, Typography} from '@material-ui/core'
import {useTitle} from 'hoofd'
import React, {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import FormStep1 from '../../../components/learner/social-learning/create/FormStep1.component'
import FormStep2 from '../../../components/learner/social-learning/create/FormStep2.component'
import {COMPANY_LOGO} from '../../../utils/globals'
import useStyles, {
  CustomConnector,
} from '../../../components/learner/social-learning/SocialLearning.style'
import {ISocialLearningFormData} from '../../../interfaces'
import {
  ADD_SOCIAL_GROUP,
  ADD_SOCIAL_INDIVIDU,
} from '../../../provider/services/SocialLearning.service'
import LogoSmartAsn from '../../../assets/images/smartasn-logo-horizontal.svg'

function CreateSocialLearning() {
  const classes = useStyles()
  const navigate = useNavigate()
  useTitle('Create Social Learning')
  const [activeStep, setActiveStep] = useState<number>(0)
  const [categoryDropdown, setCategoryDropdown] = useState(null)
  const [subCategoryDropdown, setSubCategoryDropdown] = useState(null)
  const [mentorDropdown, setMentorDropdown] = useState(null)
  const [formData, setFormData] = useState<ISocialLearningFormData>({
    type: 'group',
    name: '',
    description: '',
    rules: '',
    avatar_url: '',
    avatar_file_name: '',
    price: '0',
    privacy: '0',
    category: null,
    categoryId: null,
    mentor_id: '',
  })

  const getSteps = () => {
    return ['Data Catatan Pembelajaran Sosial', 'Avatar']
  }

  const steps = getSteps()

  const setFormDataValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {name} = event.target
    const value = (event.target as HTMLInputElement).value
    setFormData({...formData, [name]: value})
  }

  const cancelUrl = '/learner/social_learning'

  const [createSocialGroup] = ADD_SOCIAL_GROUP()
  const [createSocialIndividu] = ADD_SOCIAL_INDIVIDU()

  const setSubmitClicked = () => {
    if (formData.type === 'group') {
      createSocialGroup({
        variables: {
          name: formData.name,
          description: formData.description,
          rules: formData.rules,
          avatar_url: formData.avatar_url,
          privacy: parseInt(formData.privacy),
          price: parseInt(formData.price),
          categoryId: formData.categoryId,
          mentor_id: formData.mentor_id,
        },
      })
        .then(() => {
          navigate('/learner/my-learning/social-learnings')
        })
        .catch((error: any) => {
          console.error(`graphql: ${error}`)
        })
    } else {
      createSocialIndividu({
        variables: {
          name: formData.name,
          mentor_id: formData.mentor_id,
          description: formData.description,
          rules: formData.rules,
          avatar_url: formData.avatar_url,
          category_id: formData.categoryId,
        },
      })
        .then(() => {
          navigate('/learner/my-learning/social-learnings')
        })
        .catch((error: any) => {
          console.error(`graphql: ${error}`)
        })
    }
  }
  return (
    <div className="min-h-screen bg-[#fff]">
      <AppBar className="md:px-64 px-16 py-2 flex flex-row items-center bg-[#fff]">
        <div className="w-1/4">
          <img src={COMPANY_LOGO || LogoSmartAsn} alt="wlb-logo" width={50} />
        </div>

        <div className="w-full text-center">
          <Typography
            variant="subtitle1"
            style={{fontWeight: 'bold'}}
            color="primary"
          >
            Buat Catatan Pembelajaran Sosial
          </Typography>
        </div>

        <div className="w-1/4 text-right">
          <Link to={cancelUrl} className="text-right text-[#cb2519]">
            <Typography variant="subtitle1">Batal</Typography>
          </Link>
        </div>
      </AppBar>

      <div className="w-full h-full py-[85px] md:px-72 px-2">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          className="mb-[20px]"
          connector={<CustomConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel
                StepIconProps={{
                  classes: {
                    root: classes.iconRoot,
                    text: classes.iconText,
                    active: classes.iconActive,
                    completed: classes.iconActive,
                  },
                }}
              >
                {label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        {
          /* Step 1 */
          activeStep === 0 ? (
            <FormStep1
              cancelUrl={cancelUrl}
              formData={formData}
              setFormDataValue={setFormDataValue}
              setFormData={setFormData}
              setActiveStep={setActiveStep}
              categoryDropdown={categoryDropdown}
              setCategoryDropdown={setCategoryDropdown}
              subCategoryDropdown={subCategoryDropdown}
              setSubCategoryDropdown={setSubCategoryDropdown}
              mentorDropdown={mentorDropdown}
              setMentorDropdown={setMentorDropdown}
            />
          ) : (
            <FormStep2
              formData={formData}
              setFormData={setFormData}
              setActiveStep={setActiveStep}
              setSubmitClicked={setSubmitClicked}
            />
          )
        }
      </div>
    </div>
  )
}

export default CreateSocialLearning
