import gql from 'graphql-tag'

export const UPDATE_ORDER = gql`
  mutation ($invoiceId: uuid!, $status: String!, $paymentStatus: String!) {
    update_global_payments(
      where: {global_invoices: {id: {_eq: $invoiceId}}}
      _set: {payment_status: $paymentStatus}
    ) {
      affected_rows
      returning {
        id
        payment_status
      }
    }

    update_global_invoices_by_pk(
      pk_columns: {id: $invoiceId}
      _set: {status: $status}
    ) {
      id
      status
    }
  }
`
