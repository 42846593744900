import React from 'react'
import {capitalize} from '@material-ui/core'
import {useNavigate} from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'

import {ICertificateItem} from '../../interfaces'
import {LANDING_APP_URL} from '../../utils/globals'

const MyCertificateCard = ({
  id,
  cert_name,
  cert_no,
  cert_url,
  instructor_name,
  thumbnail,
}: ICertificateItem): React.ReactElement => {
  const navigate = useNavigate()
  return (
    <div
      className="flex p-5 flex-col lg:flex-row items-center lg:items-start shadow-lg rounded-md gap-5 cursor-pointer"
      onClick={() => navigate(`/learner/my-certificate/detail/${id}`)}
    >
      <img
        src={
          thumbnail ||
          'https://matematika.uinsu.ac.id/wp-content/uploads/2021/02/book.jpeg'
        }
        className="h-full object-cover w-[187px]"
        alt="book"
      />
      <div className="">
        <h3 className="text-sm font-semibold">{capitalize(cert_name || '')}</h3>

        <LinesEllipsis
          className="text-xs text-[#95989a] mt-1"
          text={instructor_name || ''}
          maxLine="1"
          ellipsis=""
          trimRight
          basedOn="letters"
        />
        <p className="text-xs text-[#95989a] mt-7">
          Nomor Sertifikat:{' '}
          <span className="text-[#014a62] font-bold">{cert_no || ''}</span>
        </p>
        <p className="text-xs text-[#95989a] mt-2">
          Tautan Sertifikat:{' '}
          <span className="text-[#014a62] font-bold">{`${LANDING_APP_URL}/screen/d?s=cert&id=${cert_url}`}</span>
        </p>
      </div>
    </div>
  )
}

export default MyCertificateCard
