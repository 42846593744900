import React from 'react'
import {Grid, useMediaQuery} from '@material-ui/core'
import LearningCatalogTab from './LearnerCatalogTab.component'
// import {CategoryRow} from './tabs/CategoryRow.component'
// import {LearnerProvider} from '../../../provider'
// import {LearnerAcademicProvider} from '../../../provider/Academic.provider'
import LearnerSearchForm from './LearnerSearchForm.component'
import UserLoggedInMobile from '../../user-logged-in/UserLoggedInMobile.component'

const LearnerCatalog = () => {
  const [searchKeyword, setSearchKeyword] = React.useState('')
  const isMobileScreen = useMediaQuery('(max-width: 1200px)')

  return (
    <>
      <Grid container>
        <Grid container>
          <div className="px-5 w-full">
            {isMobileScreen ? (
              <UserLoggedInMobile />
            ) : (
              <img
                src={require('../../../assets/images/learning-banner.png')}
                height="120"
                className="pb-5 "
              />
            )}

            <LearnerSearchForm
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
            />
          </div>
        </Grid>

        {/* <Grid container className="px-5 py-5">
          <CategoryRow title="Kategori" isNavigationShow={true} />
        </Grid> */}
        <LearningCatalogTab />
      </Grid>
    </>
  )
}

export default LearnerCatalog
