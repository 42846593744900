import {gql} from '@apollo/react-hooks'

export const CHECK_CART = gql`
  query CheckCart($isbn: String, $user: uuid, $item_table: String) {
    marketplace_user_carts(
      where: {
        item_table: {_eq: $item_table}
        item_id: {_eq: $isbn}
        user: {_eq: $user}
      }
    ) {
      item_object
      item_id
    }
  }
`
