import {useField} from 'react-final-form'

import {FormHelperText, OutlinedInput, Typography} from '@material-ui/core'

import {
  showErrorOnBlur,
  Validator,
} from '../../../../../../../components/input-forms/validators'

export interface DurationFieldProps {
  name: string
  validate?: Validator
  label?: string
}

const NUMBER_RE = /^0+|[^0-9]+/g

export const parseDuration = (value: string) => {
  const [hour = '', minute = '', second = ''] = value
    .replace(/(^|:)0-9+/g, '$1')
    .split(':')

  return {hour, minute, second}
}

export const formatDuration = (value: ReturnType<typeof parseDuration>) => {
  const {hour, minute, second} = value

  const pad = (str: string) => str.padStart(2, '0')

  return `${pad(hour)}:${pad(minute)}:${pad(second)}`
}

const DurationField = (props: DurationFieldProps) => {
  const {name, validate, label} = props

  const id = `durationField-${name}`
  const {input, meta} = useField(name, {
    validate,
    format: (value) => {
      return parseDuration(value || '')
    },
    parse: (value) => {
      return formatDuration(value)
    },
  })

  const {value, onChange, onFocus, onBlur} = input
  const {error, submitError} = meta
  const isError = showErrorOnBlur(meta)

  const {hour, minute, second} = value

  return (
    <div onFocus={onFocus} onBlur={onBlur}>
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <div className="flex gap-4">
        <div className="flex gap-2 items-center text-sm">
          <OutlinedInput
            type="number"
            value={hour}
            onChange={(ev) => {
              const next = ev.target.value.replace(NUMBER_RE, '')
              onChange({hour: next, minute, second})
            }}
            className="w-24"
            inputProps={{min: 0}}
          />
          <Typography color="textSecondary" aria-label="hour(s)">
            h
          </Typography>
        </div>

        <div className="flex gap-2 items-center text-sm">
          <OutlinedInput
            type="number"
            value={minute}
            onChange={(ev) => {
              const next = ev.target.value.replace(NUMBER_RE, '')
              onChange({hour, minute: next, second})
            }}
            className="w-24"
            inputProps={{min: 0}}
          />
          <Typography color="textSecondary" aria-label="minute(s)">
            m
          </Typography>
        </div>

        <div className="flex gap-2 items-center text-sm">
          <OutlinedInput
            type="number"
            value={second}
            onChange={(ev) => {
              const next = ev.target.value.replace(NUMBER_RE, '')
              onChange({hour, minute, second: next})
            }}
            className="w-24"
            inputProps={{min: 0}}
          />
          <Typography color="textSecondary" aria-label="second(s)">
            s
          </Typography>
        </div>
      </div>

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : null}
    </div>
  )
}

export default DurationField
