import {gql} from '@apollo/react-hooks'

export const GET_MY_PEOPLE_PROFILE = gql`
  query ($id: uuid) {
    global_users(where: {id: {_eq: $id}}) {
      id
      name
      avatar
      roles
      people_work_placements {
        company_profile {
          id
          brand_name
          logo
        }
        company_organization {
          id
          name
        }
        company_employee_rank {
          id
          name
        }
        company_address {
          id
          office_name
        }
        company_employee_position {
          id
          name
        }
      }
    }
  }
`
