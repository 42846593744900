import {Tab} from '@material-ui/core'
import React, {ChangeEvent, useEffect} from 'react'
import LearningSummaryCard from '../../../components/card/profileCard/LearningSummaryCard.component'
import {StyledTabsGrey} from '../../../components/utils-components/GlobalStyles'
import AddedByCompany from './tabs/AddedByCompany.component'
import AddedByUser from './tabs/AddedByUser.component'
import Dictionary from './tabs/Dictionary.component'
import {useLocation} from 'react-router-dom'

const LearningSummaryPage = () => {
  const [tabValue, setTabValue] = React.useState(0)
  const {state} = useLocation()

  const handleChange = (event: ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue)
  }

  useEffect(() => {
    const _state: any = state

    if (_state) {
      setTabValue(_state.activeTab)
    }
  }, [state])

  const renderContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <div>
            <AddedByCompany />
          </div>
        )
      case 1:
        return (
          <div>
            <AddedByUser />
          </div>
        )

      case 2:
        return (
          <div>
            <Dictionary />
          </div>
        )

      default:
        return true
    }
  }
  return (
    <LearningSummaryCard>
      <StyledTabsGrey
        value={tabValue}
        indicatorColor="primary"
        textColor="inherit"
        onChange={handleChange}
        variant="fullWidth"
      >
        <Tab label="Ditambahkan oleh Instansi" />
        <Tab label="Ditambahkan oleh Pengguna" />
        <Tab label="Kamus" />
      </StyledTabsGrey>
      <div className="p-6">{renderContent()}</div>
    </LearningSummaryCard>
  )
}

export default LearningSummaryPage
