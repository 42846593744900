import gql from 'graphql-tag'

export const GET_SOCIAL_LEARNING_MEMBERSHIP_STATUS = gql`
  query ($userId: uuid!, $classroomIds: [uuid!]!) {
    classroom_member_requests(
      where: {
        classroom_id: {_in: $classroomIds}
        user_id: {_eq: $userId}
        status: {_eq: 0}
      }
      distinct_on: [classroom_id]
    ) {
      id
      classroom_id
      created_at
      status
    }
    classroom_contrib_requests(
      where: {classroom_id: {_in: $classroomIds}, status: {_eq: 0}}
      distinct_on: [classroom_id]
    ) {
      id
      classroom_id
      date_added
      status
    }
  }
`
