import { useState, ChangeEvent, useEffect, useContext, useMemo } from 'react'
import {
  Avatar,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  CircularProgress,
  Popover,
} from '@material-ui/core'
import { Add, ArrowBack, ReportOutlined } from '@material-ui/icons'
import { Link, useNavigate, useParams } from 'react-router-dom'
import helperStyle, {
  CustomTypography,
} from '../../../components/utils-components/GlobalStyles'
import {
  // LEARNING_DIARY_ACTIVITIES,
  LEARNING_DIARY_USER_COMPETENCIES,
  LEARNING_DIARY_COMPANY_COMPETENCIES,
  CREATE_LEARNING_DIARY,
  DETAIL_LEARNING_DIARY,
  UPDATE_LEARNING_DIARY,
} from '../../../provider/services/LearningProfile.service'
import { ADD_USER_COMPETENCY } from '../../../graphql/mutations'
import {
  LIST_FOLLOWING,
  LIST_FOLLOWERS,
} from '../../../provider/services/Academy.service'
import { GET_MY_PEOPLE_PROFILE } from '../../../graphql/queries/getMyPeopleProfile.query'
import { serviceUpload } from '../../../config/axiosQuery.service'
import {
  // LearningDiaryActivity,
  ILearningDiaryCompetency,
  IinsertLearningDiary,
  ISelectedParticipant,
  LearningDiaryFiles,
  GlobalUser,
  ILearningDiaryParticipant,
} from '../../../interfaces/LearningDiary.interface'
import { IUsersSocial } from '../../../interfaces/Academic.interface'
import { AttachmentItem } from '@smartasn/wlb-utils-components'
import {
  UPLOAD_URL,
  HASURA_ROLE_USER_CONTEXT,
  USER_ID,
  COMPANY_ID,
} from '../../../utils/globals'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from '@apollo/client'

import Autocomplete from './CompetenceAutocomplete.component'
import AddIcon from '@material-ui/icons/Add'
import DialogParticipant from '../../../components/dialog/DialogParticipant.component'
import { LearningProfileContext } from '../../../provider/LearningProfile.provider'
import { AvatarGroup } from '@material-ui/lab'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import {
  activityOptions,
  LEARNING_DIARY_ACTIVITY,
  FILE_TYPE_UPLOAD,
} from '../../../utils/constans'
import { getTypeFile } from '../../../utils/helpers'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    fontSize: '12px',
    gap: theme.spacing(1),
  },
}))

const AddLearningDiary = () => {
  const { refetchResume } = useContext(LearningProfileContext)
  const classes = helperStyle()
  const styles = useStyles()
  const navigate = useNavigate()
  const { id } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [openParticipant, setOpenParticipant] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [uploadingFile, setUploadingFile] = useState<string | number>('')
  const [participants, setParticipants] = useState<IUsersSocial[]>([])
  const [selectedParticipan, setSelectedParticipant] = useState<IUsersSocial[]>(
    []
  )
  const [inputAutocomplete, setInputAutocomplete] = useState('')
  const [selectedCompetency, setSelectedCompetency] =
    useState<ILearningDiaryCompetency>({
      id: '',
      name: '',
    })
  const [attachment, setAttachment] = useState<LearningDiaryFiles[]>([])
  const [durationType, setDurationType] = useState<string>('minutes')
  const [isOpenAutocomplete, setIsOpenAutocomplete] = useState(false)
  const [value, setValue] = useState<IinsertLearningDiary>({
    activity_id: 0,
    subject: '',
    duration: 0,
    description: '',
    date: new Date(),
    participants: {
      data: [],
    },
    diaryCompetencyId: null,
  })

  const [anchorEl, setAnchorEl] = useState(null)
  // const [selectedDate, handleDateChange] = useState<Date | null>(new Date())

  // Temporary using activities from constants

  // const {data: activities, loading: activityLoading} =
  //   LEARNING_DIARY_ACTIVITIES()

  const { data: userCompetencyData, refetch: refetchUserCompetency } =
    LEARNING_DIARY_USER_COMPETENCIES()
  const { data: companyCompetencyData } = LEARNING_DIARY_COMPANY_COMPETENCIES()

  const { data: following, isLoading: followingLoading } = LIST_FOLLOWING()
  const { data: followers, isLoading: followersLoading } = LIST_FOLLOWERS()
  const { data: detail } = DETAIL_LEARNING_DIARY(id || '')
  const { data: myProfile, loading: loadingMyProfile } = useQuery(
    GET_MY_PEOPLE_PROFILE,
    {
      variables: { id: USER_ID },
    }
  )

  const [createLearningDiary] = CREATE_LEARNING_DIARY()
  const [updateLearningDiary] = UPDATE_LEARNING_DIARY()
  const [addUserCompetency] = useMutation(ADD_USER_COMPETENCY)

  const competencies = useMemo(() => {
    const arr: ILearningDiaryCompetency[] = []

    if (userCompetencyData) {
      for (const competency of userCompetencyData.learning_diary_competencies) {
        arr.push({ ...competency })
      }
    }

    if (companyCompetencyData) {
      for (const competency of companyCompetencyData.competency_dictionaries) {
        arr.push({ ...competency, name: competency.name.en })
      }
    }

    if (userCompetencyData && companyCompetencyData) {
      const collator = new Intl.Collator()
      arr.sort((a, b) => collator.compare(a.name, b.name))
    }

    return arr
  }, [userCompetencyData, companyCompetencyData])

  useEffect(() => {
    const list: IUsersSocial[] = []

    if (myProfile) {
      list.push(myProfile.global_users[0])
    }

    if (following) {
      following.data.forEach((item: IUsersSocial) => {
        list.push(item)
      })
    }

    if (followers) {
      followers.data.forEach((item: IUsersSocial) => {
        const isExist = list.some((data: IUsersSocial) => data.id === item.id)
        if (!isExist) list.push(item)
      })
    }

    setParticipants(list)
  }, [following, followers, myProfile])

  useEffect(() => {
    if (detail) {
      const _detail = detail.learning_diaries[0]
      const _selectedParticipant: GlobalUser[] = []
      const _participantData: ISelectedParticipant[] = []
      const _attachment: LearningDiaryFiles[] = []

      _detail?.learning_diary_participants.forEach(
        (user: ILearningDiaryParticipant) => {
          _selectedParticipant.push({ ...user.global_user })
          _participantData.push({ participant_user_id: user.global_user.id })
        }
      )

      setDurationType(_detail.duration % 60 === 0 ? 'hours' : 'minutes')

      setSelectedParticipant(_selectedParticipant)
      setValue((prevState) => ({
        ...prevState,
        activity_id: _detail?.learning_diary_activity?.id,
        subject: _detail?.subject,
        description: _detail?.description,
        duration:
          _detail.duration % 60 === 0
            ? _detail.duration / 60
            : _detail.duration,
        date: _detail?.date,
        participants: { data: _participantData },
      }))
      setInputAutocomplete(
        _detail?.learning_diary_competency?.name ||
        _detail?.competency_dictionary?.name?.en
      )
      setSelectedCompetency({
        id:
          _detail?.learning_diary_competency?.id ||
          _detail.competency_dictionary.id,
        name:
          _detail?.learning_diary_competency?.name ||
          _detail.competency_dictionary.name,
      })
      _detail.learning_diary_files.forEach((file: LearningDiaryFiles) => {
        _attachment.push({ ...file })
      })

      setAttachment(_attachment)
    }
  }, [detail])

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement | { value: any }>,
    name: string
  ) => {
    setValue({ ...value, [name]: e.target.value })
  }

  const handleAutoComplete = (e: ChangeEvent<HTMLInputElement>) => {
    setIsOpenAutocomplete(true)
    setInputAutocomplete(e.target.value)
    setSelectedCompetency({
      id: '',
      name: '',
    })
  }

  const handleSelectAutocomplete = (val: ILearningDiaryCompetency) => {
    if (val.id === 'add') {
      const isExist = competencies.some(
        (val: ILearningDiaryCompetency) => val.name === inputAutocomplete
      )

      if (isExist) {
        enqueueSnackbar(
          'Add competency failed. There is a similar competency already registered.',
          {
            variant: 'error',
          }
        )
      } else {
        addUserCompetency({
          variables: {
            competency: {
              name: inputAutocomplete,
              description: '',
              user_id: USER_ID,
              company_id: COMPANY_ID,
            },
          },
        })
          .then((res: any) => {
            refetchUserCompetency()
            setSelectedCompetency({
              id: res.data.insert_learning_diary_competencies_one.id,
              name: inputAutocomplete,
            })
          })
          .catch(() => {
            enqueueSnackbar('Add competency failed', {
              variant: 'error',
            })
          })
      }
    }
    setInputAutocomplete(val.name)
    setSelectedCompetency({ id: val.id, name: val.name })
  }

  const handleOnParticipantChange = (id: string) => {
    const currentIndex = value.participants.data.findIndex(
      (data: ISelectedParticipant) => data.participant_user_id === id
    )
    const newChecked: ISelectedParticipant[] = [...value.participants.data]

    if (currentIndex === -1) {
      newChecked.push({ participant_user_id: id })
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setValue({
      ...value,
      participants: { ...value.participants, data: newChecked },
    })
  }

  const handleOnSaveParticipants = () => {
    const selected: IUsersSocial[] = []
    participants.forEach((item) => {
      const isSelected = value.participants.data.some(
        (data: ISelectedParticipant) => data.participant_user_id === item.id
      )
      const isExist = selected.some(
        (select: IUsersSocial) => select.id === item.id
      )
      if (isSelected && !isExist) {
        selected.push(item)
      }
    })

    setSelectedParticipant(selected)
    setOpenParticipant(false)
  }

  const uploadFiles = (e: ChangeEvent<HTMLInputElement>) => {
    const uploaded: LearningDiaryFiles[] = attachment
    const tempFile = e?.target?.files?.[0]

    if (tempFile) {
      if (!FILE_TYPE_UPLOAD.includes(getTypeFile(tempFile.name))) {
        enqueueSnackbar('This file type is not allowed', {
          variant: 'error',
        })
      } else if (tempFile.size >= 1024 * 1024 * 25) {
        enqueueSnackbar("Attachment size can't be more than 25MB", {
          variant: 'error',
        })
      } else {
        setIsLoading(true)
        const attach: LearningDiaryFiles = {
          name: tempFile.name,
          type: tempFile.type,
          file: '',
          size: tempFile.size,
        }
        uploaded.push(attach)
        setAttachment(uploaded)

        const formData = new FormData()
        const i = uploaded.length - 1
        setUploadingFile(i)
        formData.append('file', tempFile)

        serviceUpload
          .post(`${UPLOAD_URL}`, formData, {
            headers: { 'Content-Type': tempFile.type },
          })
          .then((res: any) => {
            uploaded[i] = { ...uploaded[i], file: res.url }
            setAttachment(uploaded)

            setUploadingFile('')
          })
          .catch((err: Error) => {
            console.error(err)
          })
          .finally(() => setIsLoading(false))
      }
    }
  }

  const removeAttachment = (idx: number) => {
    const data = attachment.slice()
    data.splice(idx, 1)

    setAttachment(data)
  }

  const isValidForm =
    !selectedCompetency.id ||
    !attachment ||
    !selectedParticipan.length ||
    !value.activity_id ||
    !value.subject ||
    value.duration < 1 ||
    !value.description ||
    !value.date

  const handleSubmit = () => {
    setIsLoading(true)
    if (id) {
      const currentParticipant: ILearningDiaryParticipant[] = []
      const deletedParticipant: string[] = []
      const addedParticipant: ISelectedParticipant[] = []
      const currentFile: number[] = []
      const deletedFile: number[] = []
      const addedFile: LearningDiaryFiles[] = []

      const filterAddedFile = attachment.filter(
        (file: LearningDiaryFiles) => !file.id
      )

      filterAddedFile.forEach((file: LearningDiaryFiles) => {
        addedFile.push({ ...file, learning_diary_id: id })
      })

      detail.learning_diaries[0]?.learning_diary_participants.forEach(
        (user: ILearningDiaryParticipant) => {
          currentParticipant.push({ ...user })
        }
      )

      currentParticipant.forEach((current: ILearningDiaryParticipant) => {
        const isExist = value.participants.data.some(
          (val: ISelectedParticipant) =>
            current.global_user.id === val.participant_user_id
        )
        if (!isExist) deletedParticipant.push(current.id)
      })

      value.participants.data.forEach((data: ISelectedParticipant) => {
        const isExist = currentParticipant.some(
          (current: ILearningDiaryParticipant) =>
            current.global_user.id === data.participant_user_id
        )
        if (!isExist)
          addedParticipant.push({
            diary_id: id,
            participant_user_id: data.participant_user_id,
          })
      })

      detail.learning_diaries[0]?.learning_diary_files.forEach(
        (file: LearningDiaryFiles) => {
          currentFile.push(file.id || 0)
        }
      )

      currentFile.forEach((crFile: number) => {
        const isExist = attachment.some(
          (val: LearningDiaryFiles) => crFile === val.id
        )
        if (!isExist) deletedFile.push(crFile)
      })

      const isParticipantDeleted = !deletedParticipant.length
        ? { _is_null: true }
        : deletedParticipant.length === 1
          ? { _eq: deletedParticipant[0] }
          : { _in: deletedParticipant }

      const isFileDeleted = !deletedFile.length
        ? { _is_null: true }
        : deletedFile.length === 1
          ? { _eq: deletedFile[0] }
          : { _in: deletedFile }

      let editVariable: any = {
        id,
        activity: value.activity_id,
        duration:
          durationType === 'hours' ? value.duration * 60 : value.duration,
        subject: value.subject,
        description: value.description,
        date: value.date,
        object: addedParticipant,
        idDelete: isParticipantDeleted,
        objectFiles: addedFile,
        idFiles: isFileDeleted,
      }

      if (!Number.isInteger(selectedCompetency.id)) {
        // for insert competency from company
        editVariable = {
          ...editVariable,
          diaryCompetencyId: selectedCompetency.id,
          competency: null,
        }
      } else {
        // for insert competency from user
        editVariable = {
          ...editVariable,
          diaryCompetencyId: null,
          competency: selectedCompetency.id,
        }
      }

      updateLearningDiary({
        variables: editVariable,
        ...HASURA_ROLE_USER_CONTEXT,
      })
        .then(() => {
          navigate('/profile/learning-diary')
          refetchResume()
        })
        .catch((error: Error) => {
          console.error(error)
        })
        .finally(() => setIsLoading(false))
    } else {
      const variables = {
        ...value,
        files: { data: attachment },
        duration:
          durationType === 'hours' ? value.duration * 60 : value.duration,
      }

      if (!Number.isInteger(selectedCompetency.id)) {
        // for insert competency from company
        variables.diaryCompetencyId = selectedCompetency.id
        variables.competencyDictionaryId = null
      } else {
        // for insert competency from user
        variables.diaryCompetencyId = null
        variables.competencyDictionaryId = selectedCompetency.id
      }

      createLearningDiary({ variables, ...HASURA_ROLE_USER_CONTEXT })
        .then(() => {
          navigate('/profile/learning-diary')
          refetchResume()
        })
        .catch((error: Error) => {
          console.error(error)
        })
        .finally(() => setIsLoading(false))
    }
  }

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <Button
          component={Link}
          to="/profile"
          color="primary"
          startIcon={
            <ArrowBack className="font-black" style={{ fontSize: '16px' }} />
          }
          style={{ fontSize: '16px' }}
          className="font-black"
        >
          {id ? 'Ubah Catatan Pembelajaran' : 'Buat Catatan Pembelajaran'}
        </Button>
      </div>
      <hr />
      <form>
        <div className="flex flex-col pl-6 lg:pr-28 pr-4 mt-7 pb-16">
          <label className="text-[#a9a8a8] text-sm mb-3" htmlFor="name">
            Judul
          </label>
          <input
            type="text"
            id="name"
            placeholder="Tambah Judul"
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            value={value.subject}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleChange(e, 'subject')
            }
            autoComplete="off"
          />
          <label
            className="text-[#a9a8a8] text-sm mb-3 mt-6"
            htmlFor="activity"
          >
            Aktivitas
          </label>
          <Select
            variant="outlined"
            id="activity"
            value={value.activity_id}
            onChange={(e: ChangeEvent<{ value: any }>) =>
              handleChange(e, 'activity_id')
            }
          >
            <MenuItem className="text-[#a9a8a8]" value={0}>
              Pilih Aktivitas
            </MenuItem>
            {
              // activityLoading ? (
              //   <MenuItem value="">Loading</MenuItem>
              // ) : (
              // activities.learning_diary_activities.map((item: LearningDiaryActivity, i: number) => {
              activityOptions.map((item, i: number) => {
                return (
                  <MenuItem value={item.id} key={i}>
                    {LEARNING_DIARY_ACTIVITY?.[item.activity] || item.activity}
                  </MenuItem>
                )
              })
              // )
            }
          </Select>
          <label
            className="text-[#a9a8a8] text-sm mb-3 mt-6"
            htmlFor="activity"
          >
            Kompetensi
          </label>
          <Autocomplete
            loading={false}
            options={competencies}
            open={isOpenAutocomplete}
            onInputChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleAutoComplete(e)
            }
            selected={handleSelectAutocomplete}
            onFocus={() => setIsOpenAutocomplete(true)}
            onBlur={() => setIsOpenAutocomplete(false)}
            textValue={inputAutocomplete}
          />
          <label className="text-[#a9a8a8] text-sm mb-3 mt-6" htmlFor="date">
            Tanggal Pembelajaran
          </label>
          <div className="w-1/3">
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <DatePicker
                value={value.date}
                inputVariant="outlined"
                onChange={(date) => setValue({ ...value, date: date })}
                animateYearScrolling
                format="MMM dd, yyyy"
              />
            </MuiPickersUtilsProvider>
          </div>

          <label
            className="text-[#a9a8a8] text-sm mb-3 mt-6"
            htmlFor="duration"
          >
            Durasi
          </label>
          <div className="flex">
            <TextField
              id="duration"
              name="duration"
              variant="outlined"
              className="w-1/6"
              type="number"
              value={value.duration}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleChange(e, 'duration')
              }
              InputProps={{ inputProps: { min: 0 } }}
            />
            <Select
              variant="outlined"
              id="activity"
              className="ml-4"
              style={{ width: 120 }}
              value={durationType}
              onChange={(e: ChangeEvent<{ value: any }>) =>
                setDurationType(e.target.value)
              }
            >
              <MenuItem value="minutes">Menit</MenuItem>
              <MenuItem value="hours">Jam</MenuItem>
            </Select>
          </div>
          <label
            className="text-[#a9a8a8] text-sm mb-3 mt-6"
            htmlFor="description"
          >
            Keterangan
          </label>
          <textarea
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            rows={8}
            placeholder="Tambah Keterangan"
            name="description"
            id="description"
            value={value.description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              handleChange(e, 'description')
            }
          />
          <CustomTypography mt="24px" mb="12px" fcolor="#a9a8a8" fsize="14px">
            Peserta
          </CustomTypography>
          <div className="flex items-center gap-5">
            <IconButton
              className={classes.addBtnAvatar}
              size="small"
              onClick={() => setOpenParticipant(true)}
            >
              <AddIcon fontSize="inherit" style={{ color: '#0b4a62' }} />
            </IconButton>
            <AvatarGroup max={5}>
              {selectedParticipan.map((member: IUsersSocial) => {
                return (
                  <Avatar
                    alt={member.name}
                    src={member.avatar}
                    key={member.id}
                  />
                )
              })}
            </AvatarGroup>
          </div>

          <CustomTypography fsize="14px" mt="24px" mb="14px">
            Lampiran <span className="text-[#a9a8a8]">(tidak wajib)</span>{' '}
            <ReportOutlined
              style={{ fontSize: '16px' }}
              aria-owns={anchorEl ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          </CustomTypography>
          <label
            htmlFor="icon-button-file"
            className="border rounded-md w-16 h-16 flex items-center justify-center shadow-md mb-6"
          >
            <input
              // accept="image/*"
              id="icon-button-file"
              type="file"
              onChange={uploadFiles}
              style={{ display: 'none' }}
            />
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <Add style={{ color: '#039be5', fontSize: '40px' }} />
            </IconButton>
          </label>
          {attachment.map((val: LearningDiaryFiles, i: number) => {
            return (
              <div className="mb-4" key={i}>
                <AttachmentItem
                  key={i}
                  name={val.name}
                  size={val.size}
                  url={val.file}
                  isUploading={uploadingFile === i}
                  onRemove={() => removeAttachment(i)}
                />
              </div>
            )
          })}
        </div>
        <hr />
        <div className="flex justify-end items-center py-7 pr-8 gap-2">
          <button className="py-3 px-7 bg-transparent text-sm text-[#a9a8a8] rounded-md">
            Batal
          </button>
          {!isLoading ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={isValidForm || isLoading}
            >
              Simpan
            </Button>
          ) : (
            <CircularProgress />
          )}
        </div>
      </form>
      <DialogParticipant
        open={openParticipant}
        onClose={() => setOpenParticipant(false)}
        loading={followingLoading || followersLoading || loadingMyProfile}
        data={participants}
        handleChange={handleOnParticipantChange}
        onSave={handleOnSaveParticipants}
        selected={value.participants.data}
      />
      <Popover
        id="mouse-over-popover"
        className={styles.popover}
        classes={{
          paper: styles.paper,
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="mb-1">Appropriate file extension </div>
        <div>
          &bull; Document{' '}
          <span className="text-[#014a62]">
            (.pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx)
          </span>
        </div>
        <div>
          &bull; Image{' '}
          <span className="text-[#014a62]">
            (.png, .jpg, .jpeg, .gif, .tiff, .pjp, .pjpeg, .jfif, .tif)
          </span>
        </div>
        <div>
          &bull; Video{' '}
          <span className="text-[#014a62]">
            (MP4, MPEG-1, MPEG-4, AVI, FLV, MKV, QuickTIme, WebM, H264/AVC, VP8,
            VP9, ASF, WMV2, WMV3)
          </span>
        </div>
        <div>
          &bull; Other <span className="text-[#014a62]">(.zip, .rar)</span>{' '}
        </div>
        <div className="mt-1">Max Size: 25MB</div>
      </Popover>
    </div>
  )
}

export default AddLearningDiary
