// NOTE(intrnl): move this to another folder if this is being used outside of
// contributor dashboard.

import React from 'react'
import {useField} from 'react-final-form'

import DOMPurify from 'dompurify'

import {CKEditor} from '@ckeditor/ckeditor5-react'
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document'

import {FormControl, FormHelperText, Typography} from '@material-ui/core'

import {showErrorOnBlur, Validator} from '../../input-forms/validators'

import './styles/CkEditorStyles.css'

export interface CkEditorFieldProps {
  name: string
  validate?: Validator
  label?: string
}

interface SanitizationRef {
  raw: string | undefined
  sanitized: string
}

const DEFAULT_EDITOR_CONFIG = {
  placeholder: 'Add Content',
  toolbar: ['bold', 'italic', 'underline', '|', 'bulletedList', 'numberedList'],
}

const CkEditorField = (props: CkEditorFieldProps) => {
  const {name, validate, label} = props

  const toolbarRef = React.useRef<HTMLDivElement>(null)

  const id = `formCk-${name}`
  const {input, meta} = useField(name, {validate})

  const {value, onChange} = input
  const {error, submitError} = meta
  const isError = showErrorOnBlur(meta)

  // NOTE(intrnl): it's expensive if we keep sanitizing on every value change,
  // especially if we know that the values from CkEditor is likely to be safe.
  const ref = React.useRef<SanitizationRef>()

  if (!ref.current || ref.current.raw !== value) {
    ref.current = {
      raw: value,
      sanitized: DOMPurify.sanitize(value || ''),
    }
  }

  return (
    <FormControl fullWidth>
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <div ref={toolbarRef}></div>

      <CKEditor
        editor={DecoupledEditor}
        config={DEFAULT_EDITOR_CONFIG}
        onReady={(editor: any) => {
          toolbarRef.current!.appendChild(editor.ui.view.toolbar.element)
        }}
        data={ref.current!.sanitized}
        onChange={(event: any, editor: any) => {
          const next = editor.getData()

          ref.current = {raw: next, sanitized: next}
          onChange(next)
        }}
      />

      {isError ? (
        <FormHelperText error>{error || submitError}</FormHelperText>
      ) : null}
    </FormControl>
  )
}

export default CkEditorField
