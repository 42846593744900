import {
  Button,
  Divider,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core'
import {useTitle} from 'hoofd'
import React, {useState} from 'react'
import {useNavigate} from 'react-router-dom'
import DeletePopup from '../../shared-component/popup/DeletePopup'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import HoverPopover from 'material-ui-popup-state/HoverPopover'
import {
  usePopupState,
  bindHover,
  bindPopover,
} from 'material-ui-popup-state/hooks'

const data = [
  {
    id: 0,
    bankName: 'Bank Negara Indonesia',
    status: 'Main Bank Account',
    bankAccount: 'Work Life Beyond',
    rekNumber: '46833369',
  },
  {
    id: 1,
    bankName: 'Bank Rakyat Indonesia',
    status: '',
    bankAccount: 'Work Life Beyond',
    rekNumber: '46833369',
  },
  {
    id: 2,
    bankName: 'Bank Central Asia',
    status: '',
    bankAccount: 'Work Life Beyond',
    rekNumber: '46833369',
  },
]

const ContributorBankAccountPage = () => {
  useTitle('Contributor - Bank Account')
  const [openDelete, setOpenDelete] = useState(false)
  const handleClose = () => {
    setOpenDelete(false)
  }
  const navigate = useNavigate()

  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'demoPopover',
  })

  return (
    <Paper>
      <div className="flex h-14 items-center justify-between px-6 py-1">
        <Typography color="primary" className="font-bold">
          Bank Account
        </Typography>
        <Button
          onClick={() => navigate('/contributor/bankaccount/add')}
          variant="contained"
          color="primary"
          disableElevation
        >
          Add Bank Account
        </Button>
      </div>
      <Divider />

      <TableContainer className="overflow-x-visible">
        <Table className="h-full w-full">
          <TableBody className="h-full">
            {data.map((data) => (
              <TableRow key={data.id} className="flex items-center ">
                <TableCell className="flex justify-between gap-3 w-full">
                  <div className="flex h-full flex-col justify-between">
                    <div className="flex flex-col gap-2 pb-1">
                      <p className="text-sm font-semibold">{data.bankName}</p>
                      {data.status !== '' && (
                        <span className="text-sm text-blue-400">
                          {data.status}
                          <InfoOutlinedIcon
                            className="text-sm text-black ml-2"
                            {...bindHover(popupState)}
                          />
                          <HoverPopover
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'center',
                              horizontal: 'left',
                            }}
                            className="ml-5"
                          >
                            <Typography
                              style={{
                                margin: 10,
                                backgroundColor: 'light-gray',
                              }}
                            >
                              The main bank account cannot be
                              <br /> edited or deleted
                            </Typography>
                          </HoverPopover>
                        </span>
                      )}
                    </div>
                    <div className="flex w-full justify-between text-gray-400 text-xs pb-3">
                      <p>{data.bankAccount}</p>
                    </div>
                    <div className="flex w-full justify-between text-gray-400 text-xs">
                      <p>{data.rekNumber}</p>
                    </div>
                  </div>
                  {data.status === '' && (
                    <div className="flex h-full flex-col ">
                      <div className="flex flex-col gap-2 right-0">
                        <Button
                          id="Edit"
                          onClick={() =>
                            navigate('/contributor/bankaccount/edit')
                          }
                          variant="contained"
                          color="secondary"
                          disableElevation
                        >
                          Edit Bank Account
                        </Button>
                        <Button
                          onClick={() => {
                            setOpenDelete(true)
                          }}
                          style={{border: ' 1px solid red'}}
                          className="px-6 py-2 text-red-600 font-medium text-xs rounded bg-white "
                        >
                          Delete Bank Account
                        </Button>
                      </div>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <ConfirmationModal
        open={openDelete}
        title="Delete Bank Account"
        message="Are you sure you want to delete Wilson Workmak bank account?"
        onClose={() => setOpenDelete(false)}
        onConfirm={() => {
          setOpenDelete(false)
        }}
      /> */}
      <DeletePopup
        // title="bank account"
        open={openDelete}
        name="Bank Account"
        handleClose={handleClose}
        checkboxText="I understand that deleting this bank account is permanent and cannot be undone."
      />
    </Paper>
  )
}

export default ContributorBankAccountPage
