import {FC} from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import LearnerContributorBook from './Book.component'
import LearnerContributorCourse from './Course.component'
import LearnerContributorEvent from './Event.component'
import LearnerContributorMicroLearning from './MicroLearning.component'
import LearnerContributorSocialLearning from './SocialLearning.component'

const Section: FC<{}> = () => {
  const {active, contribId} = useParams()
  const [searchParams] = useSearchParams()

  const searchValue = searchParams.get('search') ?? ''
  const sortValue = searchParams.get('sort') ?? 'latest'

  const ActiveComponent = () => {
    switch (active) {
      case 'book':
        return (
          <LearnerContributorBook
            id={contribId}
            search={searchValue}
            sort={sortValue}
          />
        )
      case 'event':
        return (
          <LearnerContributorEvent
            id={contribId}
            search={searchValue}
            sort={sortValue}
          />
        )
      case 'social-learning':
        return (
          <LearnerContributorSocialLearning
            id={contribId}
            search={searchValue}
            sort={sortValue}
          />
        )
      case 'micro-learning':
        return (
          <LearnerContributorMicroLearning
            id={contribId}
            search={searchValue}
            sort={sortValue}
          />
        )
      default:
        return (
          <LearnerContributorCourse
            id={contribId}
            search={searchValue}
            sort={sortValue}
          />
        )
    }
  }

  return <ActiveComponent />
}

export default Section
