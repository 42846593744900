import {gql} from '@apollo/client'

export const GET_DETAIL_CONTRIBUTOR_MICROLEARNING = gql`
  query ($id: uuid!, $search: String, $ordering: [microlearnings_order_by!]) {
    microlearnings(
      where: {
        status: {_eq: "ACTIVE"}
        created_by_user_id: {_eq: $id}
        name: {_ilike: $search}
      }
      order_by: $ordering
    ) {
      id
      name
      global_user {
        id
        name
      }
      average_rating
      price
      review_count
      icon_url
      question_count
      academy_course_category {
        id
        name
      }
      global_language {
        id
        name
      }
      level
      description
    }
  }
`
