import React, { type MouseEvent } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useSnackbar } from 'notistack'

import axios from 'axios'

import {
  Avatar,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core'
import StarIcon from '@material-ui/icons/Star'
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera'
import EditIcon from '@material-ui/icons/Edit'

import Skeleton from 'react-loading-skeleton'

import ImageCropModal from '../../modal/ImageCropModal'

import { contributorTypeOptions } from '../../../utils/constans'
import { TOKEN, UPLOAD_URL, USER_ID } from '../../../utils/globals'
import {
  GET_CONTRIBUTOR_INFO,
  // GET_CONTRIBUTOR_TOTAL_EARNINGS,
} from '../../../graphql/queries'
import { UPDATE_CONTRIBUTOR_INFO } from '../../../graphql/mutations'

import WlbBannerImage from '../../../assets/images/smartasn-banner.png'

const INSTRUCTOR_CONTEXT = {
  headers: {
    'X-Hasura-Role': 'instructor',
  },
}

// NOTE(intrnl): change to id-ID for SmartASN
// const idrCompactFormatter = new Intl.NumberFormat('en-US', {
//   style: 'currency',
//   currency: 'IDR',
//   currencyDisplay: 'narrowSymbol',
//   notation: 'compact',
//   maximumFractionDigits: 1,
// })

const ContributorDashboardHeader = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [openCrop, setOpenCrop] = React.useState(false)
  const [dispatchingUpload, setDispatchingUpload] = React.useState(false)

  const [editButtonRef, setEditButtonRef] =
    React.useState<HTMLButtonElement | null>(null)

  const [dispatchUpdate, { loading: dispatchingUpdate }] = useMutation(
    UPDATE_CONTRIBUTOR_INFO,
    {
      context: INSTRUCTOR_CONTEXT,
    }
  )

  const { data } = useQuery(GET_CONTRIBUTOR_INFO, {
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: USER_ID,
    },
  })

  // const {data: earningsData} = useQuery(GET_CONTRIBUTOR_TOTAL_EARNINGS, {
  //   fetchPolicy: 'cache-and-network',
  //   variables: {
  //     walletId: WALLET_ID,
  //   },
  //   context: INSTRUCTOR_CONTEXT,
  // })

  const contributor = data?.learning_contributor_ratings_by_pk

  const contributorType = React.useMemo(() => {
    if (!contributor) {
      return null
    }

    return contributorTypeOptions.find(
      (x) => x.value === contributor.global_user.contributor_label
    )
  }, [contributor])

  // const formattedNominal = React.useMemo(() => {
  //   if (!earningsData) {
  //     return null
  //   }

  //   const nominal = earningsData.getTotalEarning.total_price
  //   const formatted = idrCompactFormatter.format(nominal)

  //   // NOTE(intrnl): remove the second replace call on SmartASN
  //   return formatted
  //     .replace(/\s+/g, '')
  //     .replace(/[.,]/g, (ch) => (ch === '.' ? ',' : '.'))
  // }, [earningsData])

  const handleCropOpen = () => {
    setOpenCrop(true)
  }

  const handleCropClose = () => {
    setOpenCrop(false)
  }

  const handleCropSubmit = async (blob: Blob) => {
    if (dispatchingUpload) {
      return
    }

    setDispatchingUpload(true)

    const formData = new FormData()
    formData.append('file', blob)

    try {
      const uploadResponse = await axios.post(UPLOAD_URL!, formData, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
          'Content-Type': 'multipart/form-data',
        },
      })

      const url = uploadResponse.data.url

      await dispatchUpdate({
        variables: {
          userId: USER_ID,
          objects: {
            contributor_banner: url,
          },
        },
      })

      setDispatchingUpload(false)
      setOpenCrop(false)
    } catch (err) {
      enqueueSnackbar(`Failed to upload banner`, { variant: 'error' })
      setDispatchingUpload(false)
    }
  }

  const handleLabelMenuOpen = (ev: MouseEvent<HTMLButtonElement>) => {
    setEditButtonRef(ev.currentTarget)
  }

  const handleLabelMenuClose = () => {
    setEditButtonRef(null)
  }

  const bindLabelSelectChange = (value: string) => () => {
    setEditButtonRef(null)

    if (value === contributor!.global_user.contributor_label) {
      return
    }

    const promise = dispatchUpdate({
      variables: {
        userId: USER_ID,
        objects: {
          contributor_label: value,
        },
      },
    })

    promise.catch(() => {
      enqueueSnackbar(`Failed to set contributor type`, { variant: 'error' })
    })
  }

  return (
    <Paper className="overflow-hidden">
      <div className="aspect-[16/3] bg-gray-100 relative">
        <div
          className="h-full w-full"
          style={{
            backgroundImage: `url(${contributor &&
              (contributor.global_user.contributor_banner || WlbBannerImage)
              })`,
            backgroundSize: '100%',
            backgroundPosition: '50% 60%',
            backgroundRepeat: 'no-repeat',
          }}
        />

        <Button
          disabled={dispatchingUpdate}
          onClick={handleCropOpen}
          className="group absolute inset-0 w-full rounded-none bg-black bg-opacity-0 hover:bg-opacity-50 focus:bg-opacity-50"
        >
          <PhotoCameraIcon className="text-6xl text-white invisible group-hover:visible group-focus:visible" />
        </Button>
      </div>
      <div className="grid md:grid-cols-[min-content_minmax(0,_1fr)] lg:flex gap-6 py-6 px-6 relative">
        <Avatar
          alt={contributor?.global_user.name}
          src={contributor?.global_user.avatar}
          className="h-40 w-40 -mt-12"
        />

        <div className="flex-grow">
          <Typography className="font-bold text-lg">
            {contributor?.global_user.name || <Skeleton width="25%" />}
          </Typography>
          <Typography color="textSecondary" className="text-sm">
            {contributor?.global_user.people_work_placements[0]
              ?.company_job_profile.title || <Skeleton width="50%" />}
          </Typography>

          <Typography
            color="secondary"
            className="font-bold mt-2 flex items-center"
          >
            <span className="mr-2">
              {contributorType?.label || <Skeleton width={80} />}
            </span>

            <IconButton
              disabled={!contributor || dispatchingUpdate}
              onClick={handleLabelMenuOpen}
              size="small"
              color="secondary"
            >
              <EditIcon />
            </IconButton>
          </Typography>

          <Menu
            open={!!editButtonRef}
            onClose={handleLabelMenuClose}
            anchorEl={editButtonRef}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            {contributorTypeOptions.map((el) => (
              <MenuItem
                key={el.value}
                onClick={bindLabelSelectChange(el.value)}
              >
                {el.label}
              </MenuItem>
            ))}
          </Menu>
        </div>

        <div className="md:col-span-2 flex flex-wrap md:flex-nowrap justify-evenly gap-6">
          <div className="text-center">
            <h3 className="mb-1">Overall Rating</h3>
            <div className="flex gap-2 items-center text-4xl">
              <StarIcon color="secondary" className="text-3xl" />
              {contributor ? (
                <>
                  <span className="font-bold text-gray-700">
                    {(contributor.total_rating_avg || 0).toFixed(1)}
                  </span>
                  <span className="text-base text-gray-500 self-end">
                    ({contributor.total_rating_count || 0})
                  </span>
                </>
              ) : (
                <Skeleton width={80} />
              )}
            </div>
          </div>

          {/* <div className="text-center lg:text-right">
            <h3 className="mb-1">Total Earnings</h3>
            <span className="font-bold text-4xl text-gray-700">
              {formattedNominal || <Skeleton width={128} />}
            </span>
          </div> */}
        </div>
      </div>

      <ImageCropModal
        open={openCrop}
        disabled={dispatchingUpload}
        aspect={16 / 3}
        onClose={handleCropClose}
        onSubmit={handleCropSubmit}
      />
    </Paper>
  )
}

export default ContributorDashboardHeader
