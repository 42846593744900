import {Button, Divider, Typography} from '@material-ui/core'
import {FC, ReactElement} from 'react'
import Skeleton from 'react-loading-skeleton'
import {useNavigate, useParams} from 'react-router-dom'
import {IAnalyticMicro} from '../../../interfaces'

const AnalyticMicroLearning: FC<IAnalyticMicro> = (
  props: IAnalyticMicro
): ReactElement => {
  const {
    name,
    progress,
    questionAnsweredCount,
    questionCount,
    score,
    isAnswered,
    correct,
    wrong,
    loadingAnalytic,
  } = props
  const navigate = useNavigate()
  const {id} = useParams()
  if (loadingAnalytic) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }

  type listAnalyticParam = {
    name: string
    value: number | string
    description: string
    type?: string | boolean
    isRight: boolean
  }

  const renderListAnalytic = ({
    name,
    description,
    value,
    type = false,
    isRight,
  }: listAnalyticParam) => {
    const renderColor = () => {
      if (type === 'progress') {
        return '#ffa000'
      } else if (type === 'score') {
        return '#039be5'
      } else if (type === 'correct') {
        return '#4caf50'
      } else {
        return '#ef4d5e'
      }
    }
    return (
      <div className={`flex flex-col w-80 ${isRight && 'ml-14'}`}>
        <Typography className="text-sm font-bold">{name}</Typography>
        <Typography
          className="text-3xl my-4 font-bold"
          style={{color: renderColor()}}
        >
          {value}
        </Typography>
        <Typography className="text-sm">{description}</Typography>
      </div>
    )
  }

  const dataListAnalytic = {
    progress: {
      name: 'Progres',
      value: `${progress}%`,
      description: `Pertanyaan Dijawab (${questionAnsweredCount ?? 0} dari ${
        questionCount ?? 0
      } Pertanyaan)`,
      type: 'progress',
      isRight: false,
    },
    score: {
      name: 'Skor',
      value: isAnswered ? score : 'N/A',
      description: isAnswered
        ? `Anda telah menjawab semua pertanyaan`
        : 'Skor belum dapat ditampilkan, karena Anda belum menjawab semua pertanyaan',
      type: 'score',
      isRight: true,
    },
    correct: {
      name: ' Jawaban yang benar',
      value: correct ?? 0,
      description: `dari ${questionCount ?? 0} Pertanyaan Terjawab`,
      type: 'correct',
      isRight: false,
    },
    wrong: {
      name: 'Jawaban yang salah',
      value: wrong ?? 0,
      description: `dari ${questionCount ?? 0} Pertanyaan Terjawab`,
      type: 'wrong',
      isRight: true,
    },
  }

  return (
    <div>
      <div className="flex flex-row">
        {renderListAnalytic(dataListAnalytic.progress)}
        <Divider orientation="vertical" flexItem />
        {renderListAnalytic(dataListAnalytic.score)}
      </div>
      <div className="flex flex-row mt-10">
        {renderListAnalytic(dataListAnalytic.correct)}
        <Divider orientation="vertical" flexItem />
        {renderListAnalytic(dataListAnalytic.wrong)}
      </div>

      <Button
        variant="outlined"
        color="primary"
        className="my-9"
        onClick={() =>
          navigate(`/learner/micro_learning/question/${id}`, {
            state: {prevRouteName: name},
          })
        }
      >
        {isAnswered ? 'Lihat Detail Hasil' : 'Lihat Progres Pertanyaan'}
      </Button>
    </div>
  )
}

export default AnalyticMicroLearning
