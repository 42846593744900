import React, {ReactElement} from 'react'
import {LearningType} from '../../../../utils/enum'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import {LearnerSocialLearningContext} from '../../../../provider/LearnerSocialLearning.provider'
import {RECOMMENDED_TRENDING_CLASSROOM} from '../../../../provider/services/SocialLearning.service'
import SearchSeeAll from '../SearchSeeAll.component'
import {IMySocialLearning} from '../../../../interfaces'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'
import {SeeAllPageContext} from '../../../../pages/learner/see-all'

interface SocialLearningProps extends SeeAllPageContext {}

const SocialLearning = (props: SocialLearningProps): ReactElement => {
  const learnerSocialLearningContext = React.useContext(
    LearnerSocialLearningContext
  )

  const [search, setSearch] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  console.info(props.filter)

  const {
    data: trendingSocialLearning,
    loading: trendingLoading,
    refetch: refetchTrending,
  } = RECOMMENDED_TRENDING_CLASSROOM(
    props.type !== 'trending',
    true,
    false,
    learnerSocialLearningContext.socialLearning.trending?.total,
    0,
    search,
    props.filter.categoriesGQL,
    Number(props.filter.rating)
  )

  const {
    data: recommendedSocialLearning,
    loading: recommendedLoading,
    refetch: refetchRecommended,
  } = RECOMMENDED_TRENDING_CLASSROOM(
    props.type !== 'recommended',
    false,
    true,
    learnerSocialLearningContext.socialLearning.recommended?.total,
    0,
    search,
    props.filter.categoriesGQL,
    Number(props.filter.rating)
  )

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    if (props.type === 'recommended') {
      refetchRecommended()
    } else {
      refetchTrending()
    }
  }, [props, refetchRecommended, refetchTrending])

  const isRecommended = location.pathname.includes('recommended')

  const title = isRecommended
    ? 'Rekomendasi Pembelajaran Sosial di SmartASN'
    : 'Tren di SmartASN'

  const _response = isRecommended
    ? recommendedSocialLearning?.getListClassroom?.classrooms
    : trendingSocialLearning?.getListClassroom?.classrooms

  const dataSocialLearning = _response?.getListClassroom?.classrooms

  return (
    <div>
      <SearchSeeAll
        placeholder="Cari Pembelajaran Sosial"
        value={search}
        handleChange={handleChange}
      />
      <div className="mt-8">
        <p className="mt-5 text-[#a9a8a8] text-sm">
          {dataSocialLearning?.length} Hasil dari {title}
        </p>
      </div>
      <div>
        {trendingLoading || recommendedLoading ? (
          <div className="flex justify-center">
            <LoadingCardComponent length={4} />
          </div>
        ) : (
          dataSocialLearning?.length < 1 && (
            <NoListComponent
              title="Tidak ada hasil yang ditemukan"
              subTitle="Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda"
            />
          )
        )}
        <div className="grid grid-cols-2 lg:grid-cols-4">
          {dataSocialLearning?.map((e: IMySocialLearning, index: number) => (
            <LearnerCatalogCard
              type={LearningType.SOCIAL_LEARNING}
              item={e}
              key={index}
              hidePrice={false}
              width="w-full"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SocialLearning
