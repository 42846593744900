import React, {type ReactElement} from 'react'
import {Link} from 'react-router-dom'
import {useMutation, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {Button, Card, Typography} from '@material-ui/core'
import {FavoriteBorder} from '@material-ui/icons'
import TodayIcon from '@material-ui/icons/Today'
import AccessTimeIcon from '@material-ui/icons/AccessTime'
import PlaceIcon from '@material-ui/icons/Place'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'
import FavoriteIcon from '@material-ui/icons/Favorite'
import {format, parseISO} from 'date-fns'
import Dompurify from 'dompurify'

import {PopperProps} from '../../../../interfaces'

import {GET_WISHLIST_EVENT_COUNT} from '../../../../graphql/queries'
import {ADD_EVENT_TO_WISHLIST} from '../../../../graphql/mutations'
import {DELETE_EVENT_WISHLIST} from '../../../../provider/services/Event.service'

const PopperEventActions = (props: {item: any}) => {
  const {item} = props
  const [loading, setLoading] = React.useState(false)
  const {enqueueSnackbar} = useSnackbar()
  const [addToWishlist] = useMutation(ADD_EVENT_TO_WISHLIST)
  const [deleteFromWishlist] = DELETE_EVENT_WISHLIST()

  const {data: wishlistData, refetch: refetchWishlist} = useQuery(
    GET_WISHLIST_EVENT_COUNT,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        eventIds: [item.id],
      },
    }
  )
  const isInWishlist = wishlistData && wishlistData.event_wishlists.length > 0

  const handleWishlistAddition = () => {
    setLoading(true)
    const variables = {eventId: item.id}

    const promise = addToWishlist({variables})

    promise.then(
      () => {
        refetchWishlist()
        enqueueSnackbar('added to wishlist', {variant: 'success'})
        setLoading(false)
      },
      () => {
        enqueueSnackbar(`Failed to add event to wishlist`, {variant: 'error'})
      }
    )
  }

  const handleRemoveWishlist = () => {
    const id = wishlistData?.event_wishlists[0]?.id

    if (id) {
      setLoading(true)
      deleteFromWishlist({
        variables: {
          id,
        },
      })
        .then(() => {
          enqueueSnackbar('Event removed from wishlist', {variant: 'success'})
          refetchWishlist()
        })
        .catch(() => {
          enqueueSnackbar('Remove event from wishlish failed', {
            variant: 'error',
          })
        })
        .finally(() => setLoading(false))
    }
  }

  return (
    <div className="flex flex-row justify-start mt-4">
      {isInWishlist ? (
        <Button
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
          onClick={handleRemoveWishlist}
        >
          <FavoriteIcon />
        </Button>
      ) : (
        <Button
          onClick={handleWishlistAddition}
          variant="outlined"
          color="primary"
          size="large"
          disabled={loading}
        >
          <FavoriteBorder />
        </Button>
      )}
    </div>
  )
}

const PopperEvent = (props: PopperProps): ReactElement => {
  const {item, hidePrice, featureName} = props

  const {startDate, endDate, startTime, endTime} = React.useMemo(() => {
    return {
      startDate: item.dateStart
        ? format(parseISO(item.dateStart), 'MMM dd, yyyy')
        : '',
      endDate: item.dateEnd
        ? format(parseISO(item.dateEnd), 'MMM dd, yyyy')
        : '',
      startTime: item.dateStart
        ? format(parseISO(item.dateStart), 'HH:mm')
        : '',
      endTime: item.dateEnd ? format(parseISO(item.dateEnd), 'HH:mm') : '',
    }
  }, [item])

  const sanitizedDescription = React.useMemo(() => {
    return Dompurify.sanitize(item.description)
  }, [item])

  const dot = <div className="h-1 w-1 rounded-full bg-gray-600" />

  return (
    <Card style={{width: 320}} className="p-4">
      <div>
        <LinesEllipsis
          text={item.title}
          maxLine={2}
          component={Link}
          to={`/learner/event/${item.id}`}
          state={{prevRouteName: featureName}}
          className="font-bold overflow-hidden"
        />

        <div className="flex flex-wrap items-center gap-x-1 text-gray-600 text-xs mt-1">
          <span>{item.EventType.name}</span>
          {dot}
          <span>{item.EventCategory.name}</span>
        </div>

        <div className="mt-4 grid gap-2">
          <div className="flex gap-2">
            <TodayIcon color="primary" />
            <Typography color="primary" variant="body2">
              {!endDate || endDate === startDate
                ? startDate
                : `${startDate} \u2013 ${endDate}`}
            </Typography>
          </div>
          <div className="flex gap-2">
            <AccessTimeIcon color="primary" />
            <Typography color="primary" variant="body2">
              {!endTime || endTime === startTime
                ? startTime
                : `${startTime} \u2013 ${startTime}`}
            </Typography>
          </div>
          <div className="flex gap-2">
            <PlaceIcon color="primary" />
            <Typography color="primary" variant="body2">
              {item.location_info?.formatted_address ?? '-'}
            </Typography>
          </div>
        </div>

        <Typography gutterBottom className="font-bold text-sm mt-4">
          Keterangan
        </Typography>

        <LinesEllipsis
          dangerouslySetInnerHTML={{__html: sanitizedDescription}}
          maxLine={5}
          component="p"
          className="text-sm overflow-hidden mt-2 mb-0 empty:hidden"
        />
      </div>

      {!hidePrice && <PopperEventActions item={item} />}
    </Card>
  )
}

export default PopperEvent
