import React from 'react'

import {
  Grid,
  // makeStyles
} from '@material-ui/core'

import {LearningType} from '../../../../utils/enum'
import TabRow from './TabRow.component'
// import {RecommendedEventCategoryRow} from './CategoryRow.component'
import {LearnerEventContext} from '../../../../provider/LearnerEvent.provider'

type EventTabProps = {
  isSearchFilter?: boolean
}

// const useStyles = makeStyles(() => ({
//   categoryContainer: {
//     padding: 15,
//     backgroundColor: '#eff2f4',
//     marginTop: 50,
//     marginBottom: 50,
//     marginLeft: 15,
//     marginRight: 15,
//   },
// }))

function EventTab(props: EventTabProps) {
  // const classes = useStyles()
  const learnerEventContext = React.useContext(LearnerEventContext)

  return (
    <Grid container className="lg:p-5 p-1">
      {!props.isSearchFilter && (
        <>
          <TabRow
            title="Acara terdekat"
            data={learnerEventContext.eventsNearBy.data}
            type={LearningType.EVENT}
          />
          <TabRow
            title="Acara-acara untuk Anda"
            data={learnerEventContext.eventsForYou.data}
            type={LearningType.EVENT}
          />
          {/* <Grid item sm={12} className={classes.categoryContainer}>
            <RecommendedEventCategoryRow />
          </Grid> */}
        </>
      )}
      <TabRow
        title={props.isSearchFilter ? '' : `Semua Acara`}
        data={learnerEventContext.eventsAll.data}
        type={LearningType.EVENT}
        isAll={true}
      />
    </Grid>
  )
}

export default EventTab
