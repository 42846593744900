import {useEffect, useState} from 'react'
import {useQuery} from '@apollo/client'
import {GET_WISHLIST_EVENT} from '../../../../graphql/queries'
import {DELETE_EVENT_WISHLIST} from '../../../../provider/services/Event.service'
import {LearningType} from '../../../../utils/enum'
import {IEventWishlistList} from '../../../../interfaces'
import {useSnackbar} from 'notistack'

import LoadingCard from '../../../../components/loading/LoadingCard.component'
import NoListComponent from '../../../../components/empty-state/NoList.component'
import LearnerCatalogCard from '../../../../components/learner/catalog/LearnerCatalogCard.component'

type eventWishlistProps = {
  searchValue: any
}

const EventWishlistComponent = (props: eventWishlistProps) => {
  const {enqueueSnackbar} = useSnackbar()

  const [list, setList] = useState<any[]>([])
  const [wishlistLoading, setWishlistLoading] = useState(false)
  const [tempId, setTempId] = useState(0)
  const {data, loading, refetch} = useQuery(GET_WISHLIST_EVENT)

  const [deleteFromWishlist] = DELETE_EVENT_WISHLIST()

  useEffect(() => {
    if (refetch) refetch()
  }, [refetch])

  useEffect(() => {
    if (data) setList(data.event_wishlists)
  }, [data])

  const removeFromWishlist = (id: number) => {
    setWishlistLoading(true)
    setTempId(id)
    deleteFromWishlist({
      variables: {
        id,
      },
    })
      .then(() => {
        enqueueSnackbar('Event removed from wishlist', {variant: 'success'})
        if (refetch) refetch()
      })
      .catch(() => {
        enqueueSnackbar('Remove event from wishlish failed', {
          variant: 'error',
        })
      })
      .finally(() => {
        setTempId(0)
        setWishlistLoading(false)
      })
  }

  if (loading) return <LoadingCard length={2} />

  const bookList = () => {
    if (props.searchValue) {
      return list.filter((item: IEventWishlistList) =>
        item?.event_schedule?.title
          .toLowerCase()
          .includes(props.searchValue.toLowerCase())
      )
    }

    return list
  }

  return (
    <div>
      {bookList().length > 0 ? (
        <div className="flex flex-wrap">
          {bookList().map((item: IEventWishlistList, key: number) => {
            return (
              <LearnerCatalogCard
                type={LearningType.EVENT}
                item={{
                  ...item.event_schedule,
                  CreatorUser: {...item.event_schedule.global_user},
                  dateStart: item.event_schedule.datestart,
                  EventType: {...item.event_schedule.event_type},
                }}
                key={key}
                hidePrice={false}
                isWishlist
                removeFromWishlist={() => removeFromWishlist(item.id)}
                wishlistLoading={wishlistLoading && tempId === item.id}
              />
            )
          })}
        </div>
      ) : (
        <NoListComponent
          title="Maaf, Tidak Ada Daftar"
          subTitle="Saat ini, Anda tidak memiliki daftar keinginan acara"
        />
      )}
    </div>
  )
}

export default EventWishlistComponent
