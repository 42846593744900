import gql from 'graphql-tag'

export const GET_USER_CART = gql`
  query {
    v_marketplace_user_carts {
      id
      item_id
      item_object
      item_vendor
      item_vendor_id
      item_quantity
      item_table
      available_stock
    }
  }
`

// NOTE(intrnl): we only want to know if an item exists or not and its cart ID,
// we don't need to know the full thing
export const GET_USER_CART_COUNT = gql`
  query ($itemIds: [String!], $itemTable: String) {
    marketplace_user_carts(
      where: {item_id: {_in: $itemIds}, item_table: {_eq: $itemTable}}
    ) {
      id
    }
  }
`
