import {
  IconButton,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  Select,
  TablePagination,
} from '@material-ui/core'
import {MoreHoriz} from '@material-ui/icons'
import SearchIcon from '@material-ui/icons/Search'
import {format, parseISO} from 'date-fns'
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state'
import React from 'react'
import {useNavigate} from 'react-router-dom'
import LearningDiaryResume from '../../../components/card/profileCard/LearningDiaryResume.component'
import NoListComponent from '../../../components/empty-state/NoList.component'
import {TextFieldSearch} from '../../../components/filter/FilterStyles'
import LoadingCircular from '../../../components/loading/LoadingCircular.component'
import {
  CustomTypography,
  FormControlSearch,
} from '../../../components/utils-components/GlobalStyles'
import {ILearningDiary} from '../../../interfaces/LearningDiary.interface'
import {
  LIST_LEARNING_DIARY,
  REMOVE_LEARNING_DIARY,
} from '../../../provider/services/LearningProfile.service'
import {timeConvert} from '../../../utils/helpers'
import {LEARNING_DIARY_ACTIVITY} from '../../../utils/constans'
import DeletePopup from '../../shared-component/popup/DeletePopup'
import {useSnackbar} from 'notistack'
import LinesEllipsis from 'react-lines-ellipsis'

const useStyles = makeStyles(() => ({
  root: {
    height: 40,
    paddingLeft: 20,
    color: '#014a62',
    border: '1px solid #e5e5e5',
    borderRadius: '5px',
  },

  selectIcon: {
    color: '#000000',
  },

  input: {
    '&::placeholder': {
      fontWeight: 'bolder',
      color: '#a9a8a8',
    },
  },
  rootMenuItem: {
    '&:focus': {
      backgroundColor: 'white',
    },
  },
  selected: {
    backgroundColor: 'white !important',
  },
}))

const LearningDiaryPage = () => {
  const classes = useStyles()
  const {enqueueSnackbar} = useSnackbar()
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const navigate = useNavigate()
  const [openDelete, setOpenDelete] = React.useState(false)
  const [deleteData, setDeleteData] = React.useState({id: '', name: ''})
  const [search, setSearch] = React.useState('')
  const [sortValue, setSortValue] = React.useState('latest')

  const {data, loading, refetch} = LIST_LEARNING_DIARY(
    rowsPerPage,
    page,
    search,
    sortValue
  )

  const [deleteDiary] = REMOVE_LEARNING_DIARY()

  const dataDiary = data?.learning_diaries

  React.useEffect(() => {
    if (refetch) {
      refetch()
    }
  }, [refetch])

  const handleClose = () => {
    setOpenDelete(false)
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(e.target.value)

  const handleSort = (e: React.ChangeEvent<{value: unknown}>) =>
    setSortValue(e.target.value as string)

  const handleDelete = () => {
    deleteDiary({variables: {id: deleteData.id}})
      .then(() => {
        setDeleteData({id: '', name: ''})
        refetch()
        handleClose()
        enqueueSnackbar('Learning diary deleted', {
          variant: 'success',
        })
      })
      .catch((err: any) => {
        console.error(err)
      })
  }

  return (
    <LearningDiaryResume type="diary">
      <div className="px-6 flex items-conter justify-between">
        <Select
          value={sortValue}
          onChange={handleSort}
          classes={{root: classes.rootMenuItem}}
          MenuProps={{
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            getContentAnchorEl: null,
          }}
          variant="standard"
          disableUnderline={true}
        >
          <MenuItem
            selected
            classes={{selected: classes.selected}}
            style={{width: '170px'}}
            value="latest"
          >
            Terbaru
          </MenuItem>
          <MenuItem style={{width: '170px'}} value="oldest">
            Terlama
          </MenuItem>
        </Select>
        <FormControlSearch variant="outlined">
          <TextFieldSearch
            placeholder="Cari Catatan Pembelajaran"
            name="search_learning_diary"
            autoComplete="off"
            value={search}
            onChange={handleSearch}
            style={{height: 'auto', boxShadow: 'none'}}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.root,
                input: classes.input,
              },
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton edge="end">
                    <SearchIcon style={{color: '#014a62'}} className="mr-4" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </FormControlSearch>
      </div>
      <div className="my-6" style={{minHeight: 300}}>
        {loading ? (
          <LoadingCircular height="300px" />
        ) : dataDiary.length === 0 ? (
          <NoListComponent
            title="Maaf, tidak ada daftar"
            subTitle="Catatan pembelajaran harian tidak ditemukan"
          />
        ) : (
          dataDiary.map((item: ILearningDiary, i: number) => (
            <div className="flex justify-between items-start px-7 mb-6" key={i}>
              <div
                className="cursor-pointer"
                onClick={() =>
                  navigate(`/profile/learning-diary/${item.id}/detail`)
                }
              >
                <CustomTypography fweight="bold" fsize="14px" fcursor="pointer">
                  <LinesEllipsis
                    text={item.subject}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                  />
                </CustomTypography>
                <CustomTypography
                  mt="4px"
                  mb="4px"
                  fsize="12px"
                  fcolor="#039be5"
                  fcursor="pointer"
                >
                  {LEARNING_DIARY_ACTIVITY?.[
                    item.learning_diary_activity?.activity
                  ] || item.learning_diary_activity?.activity}{' '}
                  -{' '}
                  {item.learning_diary_competency?.name ||
                    item.competency_dictionary?.name?.id ||
                    ''}
                </CustomTypography>
                <CustomTypography
                  fsize="12px"
                  fcolor="#a9a8a8"
                  fcursor="pointer"
                >
                  {format(parseISO(item.date), 'MMMM dd, yyyy')} -{' '}
                  {timeConvert(item.duration)}
                </CustomTypography>
              </div>

              <PopupState variant="popover" popupId="demo-popup-menu">
                {(popupState) => (
                  <>
                    <MoreHoriz
                      style={{
                        fontSize: '20px',
                        color: '#a9a8a8',
                        cursor: 'pointer',
                        background: '#eff2f4',
                        width: '35px',
                        height: '24px',
                      }}
                      {...bindTrigger(popupState)}
                    >
                      more_horiz
                    </MoreHoriz>
                    <Menu
                      {...bindMenu(popupState)}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                      PaperProps={{
                        style: {
                          transform: 'translateX(40px) translateY(40px)',
                        },
                      }}
                    >
                      <MenuItem
                        onClick={() =>
                          navigate(`/profile/learning-diary/${item.id}/edit`)
                        }
                        style={{width: '120px'}}
                        selected
                        classes={{selected: classes.selected}}
                      >
                        Ubah
                      </MenuItem>
                      <MenuItem
                        style={{color: '#ff6666'}}
                        onClick={() => {
                          setDeleteData({id: item.id, name: item.subject})
                          setOpenDelete(true)
                          popupState.close()
                        }}
                      >
                        Hapus
                      </MenuItem>
                    </Menu>
                  </>
                )}
              </PopupState>
            </div>
          ))
        )}
      </div>
      <hr />
      <div className="py-5">
        <TablePagination
          component="div"
          count={data?.learning_diaries_aggregate.aggregate.count || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          style={{color: '#a9a8a8'}}
          labelRowsPerPage="Baris per halaman"
          backIconButtonText="Halaman Sebelumnya"
          nextIconButtonText="Halaman Selanjutnya"
          labelDisplayedRows={({from, to, count}) =>
            `${from}-${to} dari ${count}`
          }
        />
      </div>
      <DeletePopup
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        mutation={() => handleDelete()}
        name={deleteData.name}
      />
    </LearningDiaryResume>
  )
}

export default LearningDiaryPage
