import React from 'react'
import {Card, CardMedia, CardContent, Typography} from '@material-ui/core'
import {ICourse} from '../../../../interfaces'
import Rating from '../../../utils-components/Rating.component'
import {convertToRupiah} from '../../../../utils/helpers'
import {bindHover} from 'material-ui-popup-state/hooks'
import {PopupState} from 'material-ui-popup-state/core'
import {useNavigate} from 'react-router-dom'
import WishlistButton from '../../../button/WishlistButton.component'
import AddToCartButton from '../../../button/AddToCartButton.component'
import LinesEllipsis from 'react-lines-ellipsis'

type CourseCardProps = {
  classes: any
  item: ICourse
  hidePrice: boolean
  popupState: PopupState
  isWishlist?: boolean
  removeFromWishlist?: () => void
  addToCart?: () => void
  width?: string
  featureName?: string
  wishlistLoading?: boolean
}

const CourseCard = ({
  classes,
  item,
  hidePrice,
  popupState,
  isWishlist,
  removeFromWishlist,
  addToCart,
  width = 'w-1/4',
  featureName,
  wishlistLoading,
}: CourseCardProps): React.ReactElement => {
  const navigate = useNavigate()

  const isFree = !item.Price || '' + item.Price.pricing.IDR === '0'

  return (
    <div className={`w-full md:w-1/2 lg:${width} pb-1`}>
      <Card
        className={classes.root}
        {...bindHover(popupState)}
        onClick={() =>
          navigate(`/learner/course/${item.slug}`, {
            state: {prevRouteName: featureName},
          })
        }
      >
        <CardMedia
          className={classes.mediaLandspace}
          image={
            item.thumbnail ||
            require('../../../../assets/images/defaultCourseCover.image.png')
          }
          style={
            isWishlist ? {display: 'flex', justifyContent: 'flex-end'} : {}
          }
        >
          {isWishlist && (
            <WishlistButton
              isWishlist={true}
              action={removeFromWishlist}
              loading={Boolean(wishlistLoading)}
            />
          )}
        </CardMedia>
        <CardContent className="pb-4">
          <div className="h-14 overflow-visible">
            <div style={{lineHeight: '1.5em', height: '3.5em'}}>
              <LinesEllipsis
                text={item.title}
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
                style={{fontSize: '1rem', fontWeight: 'bold'}}
              />
            </div>
            <LinesEllipsis
              className="text-gray-400 text-sm"
              text={item.CreatorUser.name}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
          <div className="mt-6">
            <Rating
              averageRating={parseInt(item.averageRating?.toFixed(0))}
              totalEnrollment={item.countEnrollment}
              isSmallRating
            />
          </div>
          {!hidePrice && (
            <Typography className="pt-1 font-bold capitalize text-right">
              {isFree ? 'Gratis' : convertToRupiah(item.Price.pricing.IDR)}
            </Typography>
          )}
          {isWishlist && (
            <AddToCartButton
              isInCart={item.isInCart || false}
              action={addToCart}
              className="mt-4"
              fullWidth
            />
          )}
        </CardContent>
      </Card>
    </div>
  )
}

export default CourseCard
