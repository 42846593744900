import React, {useMemo, useState} from 'react'
import {Avatar, Button, Typography} from '@material-ui/core'
import {useNavigate} from 'react-router-dom'
import RadioForm from '../../../input-forms/Radio.component'
import TextFieldForm from '../../../input-forms/TextField.component'
import SelectField from '../../../input-forms/SelectField.component'
import {
  LIST_CLASSROOM_CATEGORIES,
  LIST_CLASSROOM_SUB_CATEGORIES,
  LIST_MENTOR_SOCIAL,
} from '../../../../provider/services/SocialLearning.service'
import {IOptionsEmp, IStepOneSocialLearning} from '../../../../interfaces'
import {USER_ID} from '../../../../utils/globals'

function FormStep1(props: IStepOneSocialLearning) {
  const {
    formData,
    setFormDataValue,
    setFormData,
    setActiveStep,
    cancelUrl,
    categoryDropdown,
    setCategoryDropdown,
    subCategoryDropdown,
    setSubCategoryDropdown,
    mentorDropdown,
    setMentorDropdown,
  } = props
  const navigate = useNavigate()
  const [isError, setIsError] = useState(false)
  const [search, setSearch] = useState({
    mentor_id: '',
  })
  const isGroup = formData?.type === 'group'

  const {data: listCategoryData, loading: listClassCategoryLoading} =
    LIST_CLASSROOM_CATEGORIES()

  const {data: listSubCategoryData, loading: listSubCategoryLoading} =
    LIST_CLASSROOM_SUB_CATEGORIES(formData?.category || null)

  const {data: listMentorData, loading: listMentorLoading} = LIST_MENTOR_SOCIAL(
    {
      offset: 0,
      limit: 10,
      searchQuery: `%${search.mentor_id}%`,
    }
  )

  type category = {
    id: number
    name: string
  }

  type mentor = {
    id: string
    name: string
    avatar: string
    people_work_placements: {
      id: string
      company_job_profile: {
        id: string
        position: string
        title: string
      }
    }[]
  }

  const optionsCategory = useMemo(() => {
    return listCategoryData?.academy_course_categories?.map(
      (item: category) => ({
        value: item.id,
        label: item.name,
      })
    )
  }, [listCategoryData])

  const optionsSubCategory = useMemo(() => {
    return listSubCategoryData?.academy_course_categories?.map(
      (item: category) => ({
        value: item.id,
        label: item.name,
      })
    )
  }, [listSubCategoryData])

  const optionsMentor = useMemo(() => {
    return listMentorData?.global_users.map((item: mentor) => {
      if (item.id !== USER_ID) {
        return {
          value: item.id,
          label: item.name,
          avatar: item.avatar,
          position:
            item.people_work_placements?.[0]?.company_job_profile?.title,
        }
      } else {
        return []
      }
    })
  }, [listMentorData])

  const handleNext = () => {
    if (
      !formData.name ||
      !formData.description ||
      !formData.rules ||
      !formData.categoryId ||
      !formData.mentor_id
    ) {
      setIsError(true)
      setTimeout(() => {
        setIsError(false)
      }, 3000)
    } else {
      setActiveStep(1)
    }
  }

  const empMenu = (option: IOptionsEmp) => {
    return (
      <div className="flex my-2 mx-0">
        <div className="mr-2">
          <Avatar src={option.avatar} alt={option.value} />
        </div>
        <div className="w-full">
          <Typography variant="body2">{option.label}</Typography>
          <Typography variant="body2" className="text-[#a9a8a8]">
            {option.position}
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="w-full text-center mb-[48px]">
        <Typography className="text-[20px] mb-[15px]">
          Buat Catatan Pembelajaran Sosial
        </Typography>
        <Typography className="text-[14px] text-[#a9a8a8]">
          Lengkapi data untuk membuat catatan pembelajaran baru
        </Typography>
      </div>

      <RadioForm
        name="type"
        label="Tipe"
        row={true}
        value={formData.type}
        onChange={setFormDataValue}
        options={[
          {value: 'group', label: 'Grup'},
          {value: 'individual', label: 'Individual'},
        ]}
      />

      <TextFieldForm
        name="name"
        value={formData.name}
        onChange={setFormDataValue}
        label="Nama Sesi Pembelajaran*"
        placeholder="Tambah Nama Sesi Pembelajaran"
        error={!formData.name && isError}
        helperText={
          !formData.name && isError ? 'Nama Sesi Pembelajaran Harus diisi' : ''
        }
      />

      <SelectField
        label="Kategori Sesi Pembelajaran*"
        name="category"
        options={optionsCategory}
        valueDropdown={categoryDropdown}
        placeholder="Pilih Kategori"
        loading={listClassCategoryLoading}
        setValue={setFormData}
        setDropdown={setCategoryDropdown}
        error={!formData.category && isError}
        helperText={
          !formData.category && isError
            ? 'Kategori Sesi Pembelajaran Harus diisi'
            : ''
        }
      />

      <SelectField
        label="Subkategori Sesi Pembelajaran*"
        name="categoryId"
        options={optionsSubCategory}
        valueDropdown={subCategoryDropdown}
        placeholder="Pilih Sub Kategori"
        loading={listSubCategoryLoading}
        setValue={setFormData}
        setDropdown={setSubCategoryDropdown}
        error={!formData.categoryId && isError}
        helperText={
          !formData.categoryId && isError
            ? 'Subkategori Sesi Pembelajaran Harus diisi'
            : ''
        }
      />

      <SelectField
        label="Kontributor Pembelajaran*"
        name="mentor_id"
        options={optionsMentor}
        formatOptionLabel={empMenu}
        valueDropdown={mentorDropdown}
        placeholder="Tambah Kontributor Pembelajaran"
        loading={listMentorLoading}
        hideArrow={true}
        setSearch={setSearch}
        setValue={setFormData}
        setDropdown={setMentorDropdown}
        error={!formData.mentor_id && isError}
        helperText={
          !formData.mentor_id && isError
            ? 'Kontributor Pembelajaran Harus diisi'
            : ''
        }
      />

      {isGroup && (
        <RadioForm
          name="privacy"
          label="Privasi"
          row={true}
          value={formData.privacy}
          onChange={setFormDataValue}
          options={[
            {value: '0', label: 'Publik'},
            {value: '1', label: 'Privat'},
          ]}
        />
      )}

      <TextFieldForm
        name="description"
        value={formData.description}
        onChange={setFormDataValue}
        label="Keterangan Sesi Pembelajaran"
        placeholder="Beri tahu semua orang tentang grup Anda"
        rows={6}
        multiline={true}
        error={!formData.description && isError}
        helperText={
          !formData.description && isError
            ? 'Keterangan Sesi Pembelajaran Harus diisi'
            : ''
        }
      />

      <TextFieldForm
        name="rules"
        value={formData.rules}
        onChange={setFormDataValue}
        label="Aturan Sesi Pembelajaran"
        placeholder="Apa peraturan di grupmu"
        rows={6}
        multiline={true}
        error={!formData.rules && isError}
        helperText={
          !formData.rules && isError
            ? 'Aturan Sesi Pembelajaran Harus diisi'
            : ''
        }
      />

      <RadioForm
        label="Paid"
        value={formData.price}
        options={[{value: '0', label: 'Gratis'}]}
      />

      <div className="w-full flex flex-row justify-between gap-4">
        <Button
          fullWidth
          color="primary"
          variant="outlined"
          onClick={() => navigate(cancelUrl)}
        >
          Kembali
        </Button>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={handleNext}
        >
          Selanjutnya
        </Button>
      </div>
    </div>
  )
}

export default FormStep1
