import React from 'react'
import {
  MY_SOCIAL_LEARNING,
  RECOMMENDED_TRENDING_CLASSROOM,
  PUBLIC_SOCIAL_LEARNING,
  // RECOMMENDED_CATEGORY_CLASSROOM,
} from './services/SocialLearning.service'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  initialValue,
} from './reducers/Learner.reducer'

import {LearnerSocialLearningContextType} from '../interfaces'
import {LearnerAcademicContext} from './Academic.provider'

// import {USER_ID} from '../utils/globals'

type LearnerSocialLearningProviderProps = {
  children: React.ReactNode
}

export const LearnerSocialLearningContext =
  React.createContext<LearnerSocialLearningContextType>(initialValue)

export const LearnerSocialLearningProvider = (
  props: LearnerSocialLearningProviderProps
) => {
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)

  const [allSocialLearningData, setAllSocialLearning] = React.useState<any[]>(
    []
  )

  const {data: mySocialLearningData, loading: loadingMySocialLearning} =
    MY_SOCIAL_LEARNING(
      state.socialLearning.limitSocialLearning,
      state.socialLearning.offsetMySocialLearning
    )

  const {data: trendingSocialLearning, loading: loadingTrendingSocial} =
    RECOMMENDED_TRENDING_CLASSROOM(
      false,
      true,
      false,
      state.socialLearning.limitSocialLearning,
      state.socialLearning.offsetTrending
    )

  const {data: recommendedSocialLearning, loading: loadingRecommendedSocial} =
    RECOMMENDED_TRENDING_CLASSROOM(
      false,
      false,
      true,
      state.socialLearning.limitSocialLearning,
      state.socialLearning.offsetRecommended
    )

  const {
    data: publicSocialLearning,
    loading: loadingPublicSocialLearning,
    refetch: refetchPublicSocialLearning,
  } = PUBLIC_SOCIAL_LEARNING(
    state.socialLearning.limitGlobal,
    state.socialLearning.offsetGlobal,
    learnerAcademicContext.reducerState.searchKeyword,
    // learnerAcademicContext.reducerState.academicFilter.priceGQL._lte,
    // learnerAcademicContext.reducerState.academicFilter.priceGQL._gte,
    learnerAcademicContext.reducerState.academicFilter.categoriesGQL,
    learnerAcademicContext.reducerState.academicFilter.ratingGQL
  )

  // const {data: recommendCategoryClassroom, loading: loadingCategoryClassroom} =
  //   RECOMMENDED_CATEGORY_CLASSROOM(USER_ID)

  React.useEffect(() => {
    if (publicSocialLearning) {
      const _newData = allSocialLearningData
      publicSocialLearning.classrooms.forEach((item: any) => {
        _newData.push(item)
      })
      setAllSocialLearning(_newData)
    }
  }, [publicSocialLearning, allSocialLearningData])

  React.useEffect(() => {
    if (refetchPublicSocialLearning) refetchPublicSocialLearning()
  }, [
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.categoriesGQL,
    learnerAcademicContext.reducerState.academicFilter.priceGQL._lte,
    learnerAcademicContext.reducerState.academicFilter.priceGQL._gte,
    learnerAcademicContext.reducerState.academicFilter.ratingGQL,
    learnerAcademicContext.reducerState.academicFilter.rating,
    refetchPublicSocialLearning,
  ])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  const socialLearningReducer = {
    mySocialLearning: {
      data: mySocialLearningData?.classrooms || [],
      total: mySocialLearningData?.classrooms_aggregate?.aggregate?.count || 0,
      loading: loadingMySocialLearning,
    },
    trending: {
      data: trendingSocialLearning?.getListClassroom?.classrooms || [],
      total: trendingSocialLearning?.getListClassroom?.count || 0,
      loading: loadingTrendingSocial,
    },
    recommended: {
      data: recommendedSocialLearning?.getListClassroom?.classrooms || [],
      total: recommendedSocialLearning?.getListClassroom?.count || 0,
      loading: loadingRecommendedSocial,
    },
    global: {
      data: allSocialLearningData,
      total: publicSocialLearning?.classrooms_aggregate?.aggregate?.count || 0,
      loading: loadingPublicSocialLearning,
    },
    category: {
      data:
        // recommendCategoryClassroom?.academy_course_categories ||
        [],
      loading: false,
      // loadingCategoryClassroom,
    },
  }

  return (
    <LearnerSocialLearningContext.Provider
      value={{
        changePageLimit: changePageLimitHandler,
        socialLearning: socialLearningReducer,
        reducerState: state,
      }}
    >
      {props.children}
    </LearnerSocialLearningContext.Provider>
  )
}
