import {useContext} from 'react'
import {CustomTypography} from '../../utils-components/GlobalStyles'
import {Chart as ChartJS, ArcElement, Tooltip} from 'chart.js'
import {Doughnut} from 'react-chartjs-2'
import {LearningProfileContext} from '../../../provider/LearningProfile.provider'

ChartJS.register(ArcElement, Tooltip)

const LearningCollectionCard = () => {
  const {data: dataProfile} = useContext(LearningProfileContext)
  const collection = dataProfile?.learningProfile.learning_material

  const item = [
    {id: 1, color: '#039be5', name: 'Kursus', number: collection?.course},
    {
      id: 2,
      color: '#ef4d5e',
      name: 'Buku Elektronik',
      number: collection?.ebook,
    },
    {
      id: 3,
      color: '#ffa000',
      name: 'Buku Audio',
      number: collection?.audiobook,
    },
    // {
    //   id: 4,
    //   color: '#ffa000',
    //   name: 'Micro Learning',
    //   number: collection?.microlearning,
    // },
    {
      id: 5,
      color: '#f04ba0',
      name: 'Pembelajaran Sosial',
      number: collection?.classroom,
    },
  ]

  const data = {
    labels: item.map((item) => item.name),
    datasets: [
      {
        label: '# of Votes',
        data: item.map((item) => item.number),
        backgroundColor: item.map((item) => item.color),
        borderColor: item.map((item) => item.color),
        borderWidth: 1,
      },
    ],
  }

  let countCatalog = 0

  item.forEach((res) => (countCatalog += res.number || 0))
  const customAfterLabel = (context: any) => ` ${context.parsed} Collection`
  const customLabel = (context: any) => ` ${context.label}`

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
          Koleksi Pembelajaran
        </CustomTypography>
      </div>
      <hr />
      <div className="lg:flex lg:items-center pl-6 py-6">
        <div className="w-auto h-auto lg:w-1/2 lg:h-80 relative px-2 lg:px-0">
          <Doughnut
            data={data}
            // height={212}
            // w={212}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              cutout: '80%',
              plugins: {
                legend: {
                  position: 'right',
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: customLabel,
                    afterLabel: customAfterLabel,
                  },
                  backgroundColor: '#F7F8F9',
                  bodyColor: '#000',
                },
              },
            }}
          />
          <div className="absolute top-1/3 pt-4 left-1/2 -translate-x-1/2 text-center">
            <CustomTypography fsize="20px" fcolor="#414141">
              {countCatalog}
            </CustomTypography>
            <CustomTypography fsize="20px" fcolor="#414141">
              Koleksi Pembelajaran
            </CustomTypography>
          </div>
        </div>
        <div className="w-full lg:px-8">
          {item.map((c: Item) => (
            <div className="flex justify-between px-7 mb-6" key={c.id}>
              <div className="flex gap-6">
                <div
                  className={`w-4 h-4 bg-transparent border-4 rounded-full`}
                  style={{borderColor: c.color}}
                />
                <CustomTypography fsize="14px">{c.name}</CustomTypography>
              </div>
              <CustomTypography fsize="14px" fcolor="#a9a8a8">
                {c.number}
              </CustomTypography>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default LearningCollectionCard

type Item = {
  id: number
  color: string
  name: string
  number: number | undefined
}
