import React from 'react'
import {useQuery} from '@apollo/client'

import clsx from 'classnames'
import {format, formatDistance} from 'date-fns'

import {
  Avatar,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import {USER_ID} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {GET_CURRICULUM_COURSE_LESSONS} from '../../../../../graphql/queries'

export interface DiscussionCommentProps {
  avatarUrl?: string
  author?: string
  position?: string
  positionHighlight?: boolean
  date?: string | number
  body?: string
  footer?: React.ReactNode
  gutterTop?: boolean
  onMenu?: React.MouseEventHandler<HTMLButtonElement>
}

export const DiscussionComment = (props: DiscussionCommentProps) => {
  const {
    avatarUrl,
    author,
    position,
    positionHighlight,
    date,
    body,
    footer,
    gutterTop,
    onMenu,
  } = props

  const formatted = React.useMemo(() => {
    const instance = date ? new Date(date) : null

    return {
      absolute: date ? format(instance!, 'MMMM dd, yyyy') : null,
      relative: date ? formatDistance(instance!, Date.now()) + ' ago' : null,
    }
  }, [date])

  return (
    <div className={clsx('flex gap-4 relative pb-6', gutterTop && 'mt-4')}>
      <Avatar alt={author} src={avatarUrl} className="h-12 w-12 shrink-0" />

      <div className="flex flex-col grow">
        {onMenu && (
          <IconButton
            edge="end"
            onClick={onMenu}
            size="small"
            className="absolute -top-1 right-0"
          >
            <MoreHorizIcon />
          </IconButton>
        )}

        <Typography className="text-sm font-bold pr-9">{author}</Typography>

        <div className="flex items-center justify-between gap-2 mt-1">
          {position && (
            <Typography
              color={positionHighlight ? 'secondary' : 'textSecondary'}
              className="text-xs mt-1"
            >
              {position}
            </Typography>
          )}

          <Typography
            title={formatted.absolute!}
            color="textSecondary"
            className="text-xs"
          >
            {formatted.relative}
          </Typography>
        </div>

        {body && <p className="text-sm mt-2 whitespace-pre-line">{body}</p>}

        {footer}
      </div>
    </div>
  )
}

export interface CommentActionProps {
  courseId?: number
  lessonId?: number
  onSubmit?: (text: string, lesson: number) => void
}

export const CommentAction = (props: CommentActionProps) => {
  const {courseId, lessonId, onSubmit} = props

  const [curriculum, setCurriculum] = React.useState<number>()

  const {data} = useQuery(GET_CURRICULUM_COURSE_LESSONS, {
    fetchPolicy: 'cache-and-network',
    skip: !courseId,
    variables: {
      userId: USER_ID,
      courseId,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const lessons = data?.lessons
  const user = data?.user

  const handleSelectLesson = (
    event: React.ChangeEvent<{name?: string; value: unknown}>
  ) => {
    setCurriculum(event.target.value as number)
  }

  const handleKeyDown = (ev: React.KeyboardEvent<HTMLInputElement>) => {
    const target = ev.currentTarget

    if (onSubmit && ev.key === 'Enter') {
      const input = target.querySelector('input')!
      const value = input.value.trim()

      if (!value) {
        return
      }

      const lesson = lessons.find((lesson: any) => lesson.id === curriculum)
      onSubmit(value, lesson)

      input.value = ''
    }
  }

  if (lessonId !== undefined && lessonId !== curriculum) {
    setCurriculum(lessonId!)
  } else if (curriculum === undefined && lessons && lessons.length > 0) {
    setCurriculum(lessons[0].id)
  }

  return (
    <div className="flex gap-4 p-6">
      <Avatar
        alt={user?.name}
        src={user?.avatar}
        className="h-12 w-12 shrink-0"
      />

      <div className="grow">
        <Typography color="textSecondary" className="text-sm mb-2">
          Lessons
        </Typography>
        <Select
          disabled={!lessons || lessonId !== undefined}
          value={curriculum ?? ''}
          onChange={handleSelectLesson}
          fullWidth
          displayEmpty
          margin="dense"
          variant="outlined"
          className="text-sm mb-4"
          classes={{root: 'py-3'}}
        >
          <MenuItem value="" disabled>
            {lessons ? 'Select lesson' : 'Loading lessons'}
          </MenuItem>

          {lessons?.map((lesson: any, index: number) => (
            <MenuItem value={lesson.id} key={index}>
              Lesson {index + 1}. {lesson.title}
            </MenuItem>
          ))}
        </Select>

        <TextField
          placeholder="Add your comment (Enter to Submit)"
          onKeyDown={handleKeyDown}
          fullWidth
          className="text-sm"
        />
      </div>
    </div>
  )
}
