export const levelOptions = [
  {
    label: 'Pemula',
    value: 1,
  },
  {
    label: 'Menengah',
    value: 2,
  },
  {
    label: 'Tingkat Lanjut',
    value: 3,
  },
]

export const priceOptions = [
  {
    label: 'Gratis',
    value: 1,
  },
  {
    label: 'Berbayar',
    value: 2,
  },
]

export const ratingOptions = [
  {
    label: '4.5 & keatas',
    value: 4.5,
  },
  {
    label: '4.0 & keatas',
    value: 4.0,
  },
  {
    label: '3.0 & keatas',
    value: 3.0,
  },
  {
    label: '2.0 & keatas',
    value: 2.0,
  },
  {
    label: '1.0 & keatas',
    value: 1.0,
  },
]

export const languageOptions = [
  {
    label: 'Bahasa Inggris',
    value: 'eng',
  },
  {
    label: 'Bahasa Indonesia',
    value: 'ind',
  },
]

export const languageOptionsGQL = [
  {
    label: 'English',
    value: 2,
  },
  {
    label: 'Bahasa Indonesia',
    value: 1,
  },
]

export const contributorTypeOptions = [
  {
    label: 'Pengajar',
    value: 'INSTRUCTOR',
  },
  {
    label: 'Pembimbing',
    value: 'MENTOR',
  },
  {
    label: 'Pelatih',
    value: 'COACH',
  },
  {
    label: 'Pelatih Individu',
    value: 'TRAINER',
  },
  {
    label: 'Ahli',
    value: 'EXPERT',
  },
]

export const sortOptions = [
  {
    label: 'Terbaru',
    value: 'latest',
  },
  {
    label: 'Harga Tertinggi',
    value: 'highest',
  },
  {
    label: 'Harga Terendah',
    value: 'lowest',
  },
  {
    label: 'Rating Terbaik',
    value: 'top',
  },
  {
    label: 'Populer',
    value: 'popular',
  },
]

export const sortOptMylearning = [
  {
    label: 'Terbaru',
    value: 'latest',
  },
  {
    label: 'Terlama',
    value: 'oldest',
  },
  {
    label: 'Nama (A-Z)',
    value: 'nameASC',
  },
  {
    label: 'Nama (Z-A)',
    value: 'nameDESC',
  },
]

export const sortOptMylearningMicro = [
  ...sortOptMylearning,
  {
    label: 'Progres Tertinggi',
    value: 'progressDESC',
  },
  {
    label: 'Progres Terendah',
    value: 'progressASC',
  },
]

type TMicroType = {
  [key: string]: string
}

export const microlearningType: TMicroType = {
  1: 'Pemula',
  2: 'Menengah',
  3: 'Tingkat Lanjut',
  // 3: 'Expert',
}

export const daysType = [
  {
    name: 'Minggu',
    value: 'sunday',
  },
  {
    name: 'Senin',
    value: 'monday',
  },
  {
    name: 'Selasa',
    value: 'tuesday',
  },
  {
    name: 'Rabu',
    value: 'wednesday',
  },
  {
    name: 'Kamis',
    value: 'thursday',
  },
  {
    name: "Jum'at",
    value: 'friday',
  },
  {
    name: 'Sabtu',
    value: 'saturday',
  },
]

type TDayName = {
  [key: string]: string
}

export const dayShortName: TDayName = {
  0: 'Min',
  1: 'Sen',
  2: 'Sel',
  3: 'Rab',
  4: 'Kam',
  5: 'Jum',
  6: 'Sab',
}

export const LEARNING_TYPES = {
  COURSE: 'COURSE',
  BOOK: 'BOOK',
  EVENT: 'EVENT',
  MICRO_LEARNING: 'MICRO_LEARNING',
  SOCIAL_LEARNING: 'SOCIAL_LEARNING',
  LEARNING_CONTRIBUTOR: 'LEARNING_CONTRIBUTOR',
}

export const socialLearningPriv: TMicroType = {
  0: 'Publik',
  1: 'Pribadi',
}

export const activityOptions = [
  {
    id: 1,
    activity: 'Course',
  },
  {
    id: 2,
    activity: 'E-Book',
  },
  {
    id: 3,
    activity: 'Audiobook',
  },
  {
    id: 9,
    activity: 'Other',
  },
]

export const CONTRIBUTOR_LABEL: TMicroType = {
  COACH: 'Pelatih',
  INSTRUCTOR: 'Pengajar',
  MENTOR: 'Pembimbing',
  TRAINER: 'Pelatih Individu',
  EXPERT: 'Ahli',
}

export const LEARNING_DIARY_ACTIVITY: TMicroType = {
  Book: 'Buku',
  'E-Book': 'Buku Elektronik',
  Audiobook: 'Buku Audio',
  Mentoring: 'Pendampingan',
  Course: 'Kursus',
  Other: 'Lainnya',
  Others: 'Lainnya',
  'Micro Learning': 'Pembelajaran Mikro',
}

export const LEARNING_CATALOG_TITLE_TYPE: TMicroType = {
  trending: 'Sedang Tren di SmartASN',
  recommended: 'Rekomendasi untuk kamu',
  nearby: 'Acara Terdekat',
  'for-you': 'Acara - acara untuk Anda',
  'top-learning-contributor': 'Kontributor Pembelajaran Teratas',
  'top-instructor': 'Pengajar Teratas',
  'top-mentor': 'Mentor Teratas',
  'top-coach': 'Pelatih Teratas',
  'top-expert': 'Ahli Teratas',
  'top-trainer': 'Pelatih Individu Teratas',
}

export const FILE_TYPE_UPLOAD = [
  'doc',
  'docx',
  'xls',
  'pdf',
  'xlsx',
  'ppt',
  'pptx',
  'jpg',
  'jpeg',
  'png',
  'gif',
  'tiff',
  'pjp',
  'pjpeg',
  'jfif',
  'tif',
  'mp4',
  'mpeg-1',
  'mpeg-4',
  'avi',
  'flv',
  'mkv',
  'quicktime',
  'webm',
  'h264/avc',
  'vp8',
  'vp9',
  'asf',
  'wmv2',
  'wmv3',
  'wmv',
  'mpeg',
  'mpeg4',
  'zip',
  'rar',
  'mov',
]
