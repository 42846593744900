import {withStyles} from '@material-ui/core'
import {Rating} from '@material-ui/lab'

const SecondaryRating = withStyles((theme) => ({
  root: {
    color: theme.palette.secondary.main,
    verticalAlign: 'middle',
  },
}))(Rating)

export default SecondaryRating
