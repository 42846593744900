import {FC, ReactElement} from 'react'
import {Typography, Divider, Paper} from '@material-ui/core'
import {IQuestionSidebar, MicroQuestion} from '../../../../interfaces'
import {CheckCircleOutline, HighlightOff, Lock} from '@material-ui/icons'
import Dompurify from 'dompurify'
import {QuestionSection} from '../MicroLearning.style'
import {trimString} from '../../../../utils/helpers'

const QuestionReviewSidebar: FC<IQuestionSidebar> = (
  props: IQuestionSidebar
): ReactElement => {
  const {
    questionAnsCount,
    microQuestion,
    microQuestionLocked,
    selectQuestion,
    setSelectQuestion,
  } = props

  const selectedQuestion = selectQuestion.locked ? 'locked' : 'unlocked'

  const renderAnswerStatus = (status: string) => {
    if (status === 'correct') {
      return <CheckCircleOutline className="text-[#4caf50]" />
    } else if (status === 'almost') {
      return <CheckCircleOutline className="text-[#ffa000]" />
    } else if (status === 'wrong') {
      return <HighlightOff className="text-[#ef4d5e]" />
    } else {
      return <Lock className="text-[#a9a8a8]" />
    }
  }

  const renderQuestion = (
    dataQuestion: MicroQuestion[],
    selectStatus: boolean,
    locked: boolean = false
  ) =>
    dataQuestion?.map((data, key) => {
      const selected = selectStatus && selectQuestion.number === key
      const status = () => {
        const countScore = data?.microlearning_question_answers?.filter(
          (answers) =>
            answers?.is_correct ===
            answers?.microlearning_member_answer_options?.[0]
              ?.microlearning_member_answer?.is_answered
        )
        const countCheckboxAns = data?.microlearning_question_answers?.filter(
          (answers) => answers?.is_correct
        )

        if (locked) {
          return 'locked'
        } else {
          if (data?.question_type === 0) {
            return countScore.length === 1 ? 'correct' : 'wrong'
          } else {
            if (countScore.length === countCheckboxAns.length) {
              return 'correct'
            } else if (countScore.length > 0) {
              return 'almost'
            } else {
              return 'wrong'
            }
          }
        }
      }

      const number = locked ? key + questionAnsCount + 1 : key + 1

      return (
        <div
          className={`flex flex-row justify-between py-3 px-6 cursor-pointer ${
            selected ? 'bg-[#f7f8f9] text-[#014a62]' : 'text-[#a9a8a8]'
          }`}
          onClick={() => setSelectQuestion({locked: locked, number: key})}
          key={`${locked}${key}`}
        >
          <div
            className="flex font-bold text-sm self-center"
            dangerouslySetInnerHTML={{
              __html: Dompurify.sanitize(
                `<span style="margin-right: 4px">${number}.</span>${trimString(
                  data?.question,
                  30
                )}`
              ),
            }}
          />
          <div className="w-4">{renderAnswerStatus(status())}</div>
        </div>
      )
    })

  return (
    <Paper elevation={3} className="w-[27%] h-[26rem]">
      <Typography color="primary" variant="body1" className="font-bold p-6">
        Question
      </Typography>
      <Divider className="mb-3" />
      <QuestionSection>
        {renderQuestion(microQuestion, selectedQuestion === 'unlocked')}
        {renderQuestion(
          microQuestionLocked,
          selectedQuestion === 'locked',
          true
        )}
      </QuestionSection>
    </Paper>
  )
}

export default QuestionReviewSidebar
