import React from 'react'
import {TextBlue} from '../utils-components/VendorStyles'
import {Link} from 'react-router-dom'
import LinesEllipsis from 'react-lines-ellipsis'

const UserSectionComponent = (props: any) => {
  const {data, styles} = props

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      <img
        alt="user-logged-icon"
        src={
          data.avatar
            ? data.avatar
            : require('../../assets/slicing/default-avatar.png')
        }
        height="120"
        width="120"
        style={{
          borderRadius: '50%',
        }}
      />
      <LinesEllipsis
        style={styles.usernameLabel}
        text={data?.name}
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />
      <LinesEllipsis
        style={styles.posisitionLabel}
        text={`${data?.position_name} at ${data?.company_name}`}
        maxLine="2"
        ellipsis="..."
        trimRight
        basedOn="letters"
      />

      <TextBlue component={Link} to={'/profile'}>
        Lihat Profil Pembelajaran
      </TextBlue>
    </div>
  )
}

export default UserSectionComponent
