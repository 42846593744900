import React, {type ReactNode} from 'react'
import {useField, type UseFieldConfig} from 'react-final-form'
import {useDropzone} from 'react-dropzone'

import {Button, FormHelperText, Typography} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import {showErrorOnChange} from '../../input-forms/validators'
import LocalVideoPreview from '../misc/LocalVideoPreview'
import JWVideoPreview from '../misc/JWVideoPreview'

export type JWVideo = string | File

export interface JWVideoFieldProps {
  name: string
  slug?: string
  validate?: UseFieldConfig<JWVideo>['validate']
  format?: UseFieldConfig<JWVideo>['format']
  parse?: UseFieldConfig<JWVideo>['parse']
  label?: string
  buttonLabel?: string
  description?: ReactNode
}

interface VideoRef {
  value: JWVideo
  url: string
}

const JWVideoField = (props: JWVideoFieldProps) => {
  const {name, slug, validate, format, parse, label, buttonLabel, description} =
    props

  const {
    input: {value, onChange},
    meta,
  } = useField<JWVideo>(name, {
    validate,
    format,
    parse,
  })

  const dropzone = useDropzone({
    disabled: meta.submitting,
    noClick: true,
    accept: {
      'video/mp4': [],
    },
    onDrop: React.useCallback((acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        onChange(acceptedFiles[0])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []),
  })

  const videoRef = React.useRef<VideoRef>()

  if (!videoRef.current || videoRef.current.value !== value) {
    if (videoRef.current && videoRef.current.value instanceof File) {
      URL.revokeObjectURL(videoRef.current.url)
    }

    const url = value instanceof File ? URL.createObjectURL(value) : value
    videoRef.current = {value, url}
  }

  const isError = showErrorOnChange(meta)

  React.useEffect(() => {
    return () => {
      if (videoRef.current && videoRef.current.value instanceof File) {
        URL.revokeObjectURL(videoRef.current.url)
      }
    }
  }, [])

  return (
    <div>
      <Typography
        component="label"
        color={isError ? 'error' : 'textPrimary'}
        className="block text-sm"
        gutterBottom
      >
        {label}
      </Typography>

      {description && (
        <Typography color="textSecondary" className="mb-2 text-sm">
          {description}
        </Typography>
      )}

      <div {...dropzone.getRootProps({className: 'aspect-video h-40 sm:h-64'})}>
        <input {...dropzone.getInputProps()} />

        {value instanceof File ? (
          <LocalVideoPreview
            src={videoRef.current!.url}
            onChange={!meta.submitting ? dropzone.open : undefined}
          />
        ) : value ? (
          <JWVideoPreview
            slug={slug}
            src={value}
            onChange={!meta.submitting ? dropzone.open : undefined}
          />
        ) : (
          <Button
            disabled={meta.submitting}
            onClick={dropzone.open}
            variant="outlined"
            color={dropzone.isDragAccept ? 'secondary' : 'default'}
            className="block border-2 border-dashed bg-gray-50 normal-case h-full w-full"
          >
            <AddIcon fontSize="large" color="action" />
            <Typography
              color={dropzone.isDragAccept ? 'secondary' : 'textSecondary'}
              className="mt-2 text-sm"
            >
              {buttonLabel}
            </Typography>
            <Typography
              color={dropzone.isDragAccept ? 'secondary' : 'textSecondary'}
              className="mt-2 text-sm"
            >
              Drag the file here
            </Typography>
          </Button>
        )}
      </div>

      {isError ? (
        <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>
      ) : null}
    </div>
  )
}

export default JWVideoField
