import {CustomFlex} from '../utils-components/GlobalStyles'

import Skeleton from '@material-ui/lab/Skeleton'

const LoadingUserComponent = () => {
  return (
    <CustomFlex>
      <Skeleton variant="circle" width={40} height={40} />
      <CustomFlex direction="column" className="ml-6">
        <Skeleton variant="text" width={200} />
        <Skeleton variant="text" width={200} />
      </CustomFlex>
    </CustomFlex>
  )
}

export default LoadingUserComponent
