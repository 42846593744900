import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import {LearningType} from '../../../../utils/enum'
import {useSnackbar} from 'notistack'
import {ICourse} from '../../../../interfaces/Course.interface'

import {useQuery} from '@apollo/client'
import {GET_PUBLIC_COURSES} from '../../../../graphql/queries'

import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCard from '../../../loading/LoadingCard.component'
import InfiniteScroll from 'react-infinite-scroll-component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'

type CourseSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: {
    rating: string
    categoriesREST: string
    levelsREST: string
    languagesREST: string
    priceMin: string
    priceMax: string
    priceGQL: {
      _lte: number
      _gte: number
    }
    categoriesGQL: []
    ratingGQL: []
    contributorLabels: []
    languagesGQL: []
    levelsGQL: []
  }
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  activeTab: LearningType
}

function CourseSearchFilterTab(props: CourseSearchFilterTabProps) {
  const LIMIT = 8
  const {countResult, setCountResult} = props
  const {enqueueSnackbar} = useSnackbar()

  const [loading, setLoading] = React.useState(false)
  const [courses, setCourses] = React.useState<ICourse[]>([])
  const [offset, setOffset] = React.useState(0)

  const disableLoading = () => setLoading(false)
  const resetData = () => {
    setCourses([])
    setOffset(0)
  }

  const variables: any = {
    offset: offset,
    limit: LIMIT,
    search: `%${props.searchKeyword}%`,
    rating: props.academicFilter.rating,
    price: {
      _gte: props.academicFilter.priceMin,
      _lte: props.academicFilter.priceMax,
    },
    language: props.academicFilter.languagesGQL.map((item: number) => {
      return String(item)
    }),
    category: props.academicFilter.categoriesGQL,
    level: props.academicFilter.levelsGQL,
  }

  if (!props.academicFilter.rating) {
    delete variables.rating
  }

  if (
    props.academicFilter.priceMin === '' ||
    props.academicFilter.priceMax === ''
  ) {
    delete variables.price
  }

  if (!props.academicFilter.languagesGQL.length) {
    delete variables.language
  }

  if (!props.academicFilter.categoriesGQL.length) {
    delete variables.category
  }

  if (!props.academicFilter.levelsGQL.length) {
    delete variables.level
  }

  const {
    data,
    loading: loadingData,
    error,
  } = useQuery(GET_PUBLIC_COURSES, {
    variables,
  })

  React.useEffect(() => {
    if (data) {
      const _data = courses
      const _total = data.academy_courses_aggregate.aggregate.count

      if (_total > 0) {
        setCountResult({
          ...countResult,
          countResultCourse: _total,
        })
      }

      data.academy_courses.map((item: ICourse) => _data.push(item))

      setCourses(_data)
    }
    // eslint-disable-next-line
  }, [data, courses])

  React.useEffect(() => {
    setLoading(true)
    resetData()

    setTimeout(disableLoading, 1000)
  }, [props.searchKeyword, props.academicFilter])

  const fetchMoreData = () => {
    setOffset(offset + LIMIT)
  }

  if (error) {
    enqueueSnackbar(JSON.parse(error.message), {
      variant: 'error',
    })
  }

  return (
    <Grid container className="px-2 pb-2">
      <div className="w-full">
        {(!courses.length && loadingData) || loading ? (
          <LoadingCard length={4} />
        ) : courses.length ? (
          <InfiniteScroll
            dataLength={courses.length}
            next={fetchMoreData}
            hasMore={countResult.countResultCourse > courses.length}
            loader={<LoadingCard length={4} />}
          >
            <div className="flex flex-wrap">
              {courses.map((item: ICourse) => (
                <LearnerCatalogCard
                  featureName="Learning Catalog"
                  key={item.id}
                  type={LearningType.COURSE}
                  item={item}
                  hidePrice={false}
                />
              ))}
            </div>
          </InfiniteScroll>
        ) : (
          <NoListComponent
            title="No Result Found"
            subTitle={`It seems we can't find any result based on your search`}
          />
        )}
      </div>
    </Grid>
  )
}

export default CourseSearchFilterTab
