import styled from 'styled-components'
import {makeStyles, Box, CircularProgress} from '@material-ui/core'

export const LoadingBox = styled(Box)`
  display: 'flex';
`

export const CircularProgressStyles = styled(CircularProgress)`
  display: 'flex';
`

export const DetailWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px 67.5px 0px 67.5px;
  font-family: ${(props: {theme: {font: any}}) => props.theme.font};
  padding-bottom: 15px;
  @media (max-width: 835px) {
    flex-direction: column;
    align-items: center;
    padding: 30px;
  }
`

const useStyles = makeStyles(() => ({
  imgDetailEvent: {
    maxHeight: 680,
    width: '100%',
    objectFit: 'cover',
  },
  chevronContainer: {
    backgroundColor: '#039be5',
    padding: 5,
    borderRadius: 10,
    marginLeft: 6,
    marginRight: 6,
    color: '#fff',
  },
}))

export default useStyles
