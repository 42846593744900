import {useQuery, useMutation} from '@apollo/client'
import {
  GET_LEARNING_DIARY,
  GET_DETAIL_LEARNING_DIARY,
  GET_LEARNING_DIARY_ACTIVITIES,
  GET_LEARNING_DIARY_USER_COMPETENCIES,
  GET_LEARNING_DIARY_COMPANY_COMPETENCIES,
} from '../../graphql/queries'
import {
  ADD_LEARNING_DIARY,
  DELETE_LEARNING_DIARY,
  EDIT_LEARNING_DIARY,
} from '../../graphql/mutations'
import {HASURA_ROLE_USER_CONTEXT, USER_ID} from '../../utils/globals'

export const LIST_LEARNING_DIARY = (
  rowsPerPage: number,
  page?: number,
  searchText?: string,
  sort?: string
) => {
  const {data, loading, refetch} = useQuery(GET_LEARNING_DIARY, {
    variables: {
      userId: USER_ID,
      offset: (page || 0) * rowsPerPage,
      limit: rowsPerPage,
      search: `%${searchText || ''}%`,
      ordering: {
        date: sort === 'oldest' ? 'asc_nulls_first' : 'desc_nulls_first',
      },
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })

  return {data, loading, refetch}
}

export const DETAIL_LEARNING_DIARY = (id: string) => {
  const {data, loading} = useQuery(GET_DETAIL_LEARNING_DIARY, {
    variables: {
      id,
    },
    skip: !id,
    ...HASURA_ROLE_USER_CONTEXT,
  })

  return {data, loading}
}

export const LEARNING_DIARY_ACTIVITIES = () => {
  const {data, loading} = useQuery(GET_LEARNING_DIARY_ACTIVITIES)

  return {data, loading}
}

export const LEARNING_DIARY_USER_COMPETENCIES = () => {
  const {data, loading, refetch} = useQuery(
    GET_LEARNING_DIARY_USER_COMPETENCIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: USER_ID,
      },
    }
  )

  return {data, loading, refetch}
}

export const LEARNING_DIARY_COMPANY_COMPETENCIES = () => {
  const {data, loading, refetch} = useQuery(
    GET_LEARNING_DIARY_COMPANY_COMPETENCIES,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: USER_ID,
      },
      context: {
        headers: {
          'X-Hasura-Role': 'organization-staff',
        },
      },
    }
  )

  return {data, loading, refetch}
}

export const CREATE_LEARNING_DIARY = () => {
  return useMutation(ADD_LEARNING_DIARY)
}

export const REMOVE_LEARNING_DIARY = () => {
  return useMutation(DELETE_LEARNING_DIARY)
}

export const UPDATE_LEARNING_DIARY = () => {
  return useMutation(EDIT_LEARNING_DIARY)
}
