import {GET_MY_CERTIFICATE_DETAIL_BY_ID} from './../../graphql/queries/course/getMyCertificate.query'
import {useQuery, useMutation} from '@apollo/react-hooks'
import {
  useQueryService,
  useQueryJWService,
  useMutationService,
} from '../../config/axiosQuery.service'
import {USER_ID, HASURA_ROLE_USER_CONTEXT} from '../../utils/globals'
import {
  GET_RECOMMENDED_COURSE_CATEGORIES,
  GET_COURSE_REVIEWS,
  GET_MY_CERTIFICATE,
  GET_RECOMMENDED_TRENDING_COURSES,
  GET_PUBLIC_COURSES,
} from '../../graphql/queries'
import {
  INSERT_REVIEW_COURSE,
  UPDATE_REVIEW_COURSE,
  ADD_ITEM_TO_CART,
} from '../../graphql/mutations'
import {ICertificate} from '../../interfaces'

export const MY_COURSE = () =>
  useQueryService(['myCourse'], `academy/student/${USER_ID}/enrollment`)

export const LIST_COURSE_TRENDINGS = (
  limit: number = 4,
  offset: number = 0,
  type: string,
  priceMin?: number | string,
  priceMax?: number | string,
  rating?: number | string,
  categories?: string | number[],
  levels?: number[],
  languages?: number[],
  search?: string
) =>
  useQuery(GET_RECOMMENDED_TRENDING_COURSES, {
    variables: {
      isRecommended: type !== 'trending',
      sortByTrending: type === 'trending',
      offset: offset,
      limit: limit,
      search: search,
      rating: Number(rating),
      min_price: Number(priceMin),
      max_price: Number(priceMax),
      language: languages,
      category_id: categories,
      level: levels,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })

export const LIST_PUBLIC_COURSES = (limit: number = 8, offset: number = 0) => {
  return useQuery(GET_PUBLIC_COURSES, {
    variables: {
      offset: offset,
      limit: limit,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_RECOMMENDATION_COURSE_CATEGORIES = () => {
  return useQuery(GET_RECOMMENDED_COURSE_CATEGORIES, {
    variables: {
      userId: USER_ID,
      limit: 4,
    },
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const GET_COURSE_VIDEO_PREVIEW = (
  slug: string = '',
  mediaKey: string = ''
) => {
  const enabled = mediaKey !== '' && mediaKey !== null && mediaKey !== undefined

  return useQueryJWService(
    [`getCourseVideoPreview`, slug, mediaKey],
    `${slug}`,
    `${mediaKey}`,
    enabled
  )
}

export const COURSE_BY_ID = (id: number, properties?: any) =>
  useQueryService(['courseById'], `academy/course/${id}`, properties)

export const COURSE_COMMENT = (courseId: number) =>
  useQueryService(['courseComment'], `academy/course/${courseId}/discussion`)

export const COURSE_COMMENT_RESPONSE = (discussionId: number) =>
  useQueryService(
    ['courseCommentDiscussion'],
    `academy/discussion/${discussionId}/response/get`
  )

// REST version
export const COURSE_REVIEW = (courseId: number) =>
  useQueryService(['courseReview'], `academy/course/${courseId}/review`)

// GQL version
export const COURSE_REVIEW_GQL = (
  courseId: number,
  rating: number | undefined,
  limit: number
) => {
  const variables = {
    id: courseId,
    limit,
    rating,
  }

  if (rating === 0) {
    delete variables.rating
  }

  return useQuery(GET_COURSE_REVIEWS, {
    variables,
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const LIST_MY_WISHLIST = () =>
  useQueryService(['listMyWishlist'], `academy/wishlist/get`)

// mutation example
export const ADD_TO_CART = () => {
  return useMutation(ADD_ITEM_TO_CART)
}

export const COURSE_BY_SLUG = (slug: string, properties?: any) =>
  useQueryService(['courseBySlug'], `academy/course/get/${slug}`, properties)

type MyCertificateListProps = Pick<ICertificate, 'search' | 'filterData'> & {
  limit?: number
  order_by?: any
}

export const MY_CERTICIFATE_LIST = ({
  limit,
  search,
  order_by,
  filterData,
}: MyCertificateListProps) => {
  return useQuery(GET_MY_CERTIFICATE, {
    variables: {
      userId: USER_ID,
      limit: limit,
      search: search,
      order_by: order_by,
      ...filterData,
    },
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

export const MY_CERTICIFATE_DETAIL_BY_ID = ({id}: {id: number}) => {
  return useQuery(GET_MY_CERTIFICATE_DETAIL_BY_ID, {
    variables: {
      id,
    },
    fetchPolicy: 'cache-and-network',
    context: {
      headers: {
        'X-Hasura-Role': 'user',
      },
    },
  })
}

// mutation example

export const ADD_TO_WISHLIST = () =>
  useMutationService('academy/wishlist/add', 'POST')

export const REMOVE_FROM_WISHLIST = (wishlistId: number) =>
  useMutationService(`academy/wishlist/${wishlistId}/remove`, 'DELETE')

export const ADD_COURSE_REVIEW = () => {
  return useMutation(INSERT_REVIEW_COURSE, {
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const UPDATE_COURSE_REVIEW = () => {
  return useMutation(UPDATE_REVIEW_COURSE, {
    ...HASURA_ROLE_USER_CONTEXT,
  })
}

export const ADD_COURSE_COMMENT = () =>
  useMutationService('academy/discussion/create', 'POST')

export const ADD_COURSE_COMMENT_RESPONSE = (discussionId: number) =>
  useMutationService(`academy/discussion/${discussionId}/response`, 'POST')

export const UPDATE_COURSE_ENROLLMENT_PROGRESS = (enrollmentId: number) =>
  useMutationService(`academy/enrollment/${enrollmentId}/progress`, 'POST')

export const ADD_LESSON_QUIZ_ANSWER_SUBMISSION = (
  lessonId: number,
  questionId: number
) =>
  useMutationService(
    `academy/lesson/${lessonId}/question/${questionId}/answer`,
    'POST'
  )

export const LESSON_BY_ID = (lessonId: string | undefined) =>
  useQueryService([`lessonById${lessonId}`], `academy/lesson/${lessonId}`)

export const QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID = (lessonId: number) =>
  useQueryService(
    [`quizSubmissionByLessonIdStudentId${USER_ID}${lessonId}`],
    `academy/lesson/${lessonId}/question?student=${USER_ID}`
  )

export const ADD_COMMENT_HELPFUL = (discussionId: number) =>
  useMutationService(`academy/discussion/${discussionId}/helpful`, 'POST')

export const REMOVE_COMMENT_HELPFUL = (discussionId: number) =>
  useMutationService(`academy/discussion/${discussionId}/unhelpful`, 'DELETE')

export const ADD_RESPONSE_HELPFUL = (
  discussionId: number,
  responseId: number
) =>
  useMutationService(
    `academy/discussion/${discussionId}/response/${responseId}/helpful`,
    'POST'
  )

export const REMOVE_RESPONSE_HELPFUL = (
  discussionId: number,
  responseId: number,
  helpfulId: number
) =>
  useMutationService(
    `academy/discussion/${discussionId}/response/${responseId}/helpful/${helpfulId}/remove`,
    'DELETE'
  )

export const REMOVE_COMMENT = (courseId: number, discussionId: number) =>
  useMutationService(
    `academy/course/${courseId}/discussion/${discussionId}/remove`,
    'DELETE'
  )
