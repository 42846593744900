import React from 'react'
import {Form} from 'react-final-form'

import arrayMutators from 'final-form-arrays'

import QuestionSubform, {ChoiceItem, QuestionType} from './QuestionSubform'

import {getInterimId} from '../_utils'
import useEvent from '../../../../../../../hooks/useEvent'

export interface QuestionFormProps {
  initialValues: any
  number: number
  onSubmit: (values: any) => void
  onCancel?: () => void
}

export const formatQuestionValues = (values: any) => {
  const choices: ChoiceItem[] = []
  const answers: string[] = []

  for (const text of values.choices) {
    const id = getInterimId()

    choices.push({
      id,
      text: text,
    })

    if (values.answer_keys.includes(text)) {
      answers.push(id)
    }
  }

  return {
    fieldtype: QuestionType.SINGLE_ANSWER,
    ...values,
    choices: choices,
    answer_keys: answers,
  }
}

export const parseQuestionValues = (values: any) => {
  const {choices, answer_keys} = values

  const actualChoices: string[] = []
  const actualAnswers: string[] = []

  for (const item of choices) {
    const id = item.id
    const text = item.text.trim()

    if (!text) {
      continue
    }

    actualChoices.push(text)

    if (answer_keys.includes(id)) {
      actualAnswers.push(text)
    }
  }

  return {
    ...values,
    choices: actualChoices,
    answer_keys: actualAnswers,
  }
}

const QuestionForm = (props: QuestionFormProps) => {
  const {initialValues, number, onSubmit, onCancel} = props

  const fixedInitialValues = React.useMemo(() => {
    return formatQuestionValues(initialValues)
  }, [initialValues])

  const onSubmitCallback = useEvent(onSubmit)

  const handleSubmit = (values: any) => {
    const fixedValues = parseQuestionValues(values)
    onSubmitCallback(fixedValues)
  }

  return (
    <Form
      initialValues={fixedInitialValues}
      onSubmit={handleSubmit}
      subscription={{pristine: true}}
      mutators={{...arrayMutators}}
    >
      {({handleSubmit, pristine}) => (
        <QuestionSubform
          number={number}
          pristine={pristine}
          onSubmit={() => handleSubmit()}
          onCancel={onCancel}
        />
      )}
    </Form>
  )
}

export default QuestionForm
