import React from 'react'
import {
  AppBar,
  Toolbar,
  createStyles,
  makeStyles,
  Typography,
  List,
  ListItem,
  Divider,
  Card,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemSecondaryAction,
} from '@material-ui/core'
import classNames from 'classnames'
import {useNavigate, useParams, useLocation} from 'react-router-dom'
import SmartASNLogo from '../../assets/images/smartasn-logo-horizontal.svg'
import {AccessAlarm} from '@material-ui/icons'
import {
  LESSON_BY_ID,
  ADD_LESSON_QUIZ_ANSWER_SUBMISSION,
  COURSE_BY_ID,
  QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID,
} from '../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import QuizLessonCheckBox from '../../components/learner/course/lesson/lesson-type/quiz/QuizLessonCheckBox.component'
import QuizLessonMultipleChoice from '../../components/learner/course/lesson/lesson-type/quiz/QuizLessonMultipleChoice.component'
import QuizLessonShortAnswer from '../../components/learner/course/lesson/lesson-type/quiz/QuizLessonShortAnswer.component'
import {useTitle} from 'hoofd'
import {
  ILessonQuestion,
  IAnswerProps,
  ICourseQuizSubmission,
} from '../../interfaces/Course.interface'
import Dompurify from 'dompurify'
import Countdown from 'react-countdown'
import {convertHMSToMilliseconds} from '../../utils/helpers'
import LinesEllipsis from 'react-lines-ellipsis'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import {COMPANY_LOGO} from '../../utils/globals'

type TimeIsOutOrFinishAnswerSubmissionProps = {
  isFinishAnswerSubmission: boolean
  setIsFinishAnswerSubmission: (IsFinishAnswerSubmission: boolean) => void
  isTimeIsRunOut?: boolean
  setIsTimeRunOut?: (IsFinishAnswerSubmission: boolean) => void
  quizSubmission?: () => void
  slug: string
  lessonId: number
}

type RenderCountDownProps = {
  hours: number
  minutes: number
  seconds: number
  completed: boolean
}

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      backgroundColor: 'FAFAFA',
    },
    toolbar: {
      background: 'rgb(246, 248, 249)',
      boxShadow:
        'rgba(0, 0, 0, 0.2) 0px 2px 4px -1px, rgba(0, 0, 0, 0.14) 0px 4px 5px 0px, rgba(0, 0, 0, 0.12) 0px 1px 10px 0px',
    },
    logoContainer: {
      textAlign: 'center',
    },
    logo: {
      height: '100%',
    },
    selectedFont: {
      color: '#004A62',
      fontWeight: 'bold',
    },
    unselectedFont: {
      color: '#A9A8A8',
      fontWeight: 'bold',
    },
    questionContainer: {
      height: 570,
    },
    questionOptionContainer: {
      height: 460,
    },
    logoImg: {
      width: 'auto',
      height: '50px',
    },
  })
)

const TimeHasRunOut = (props: TimeIsOutOrFinishAnswerSubmissionProps) => {
  React.useEffect(() => {
    if (props.setIsTimeRunOut) props.setIsTimeRunOut(true)
    props.setIsFinishAnswerSubmission(true)
  }, [props])

  return <Typography color="textSecondary">Time has run out.</Typography>
}

const FinishAnswerSubmissionDialog = (
  props: TimeIsOutOrFinishAnswerSubmissionProps
) => {
  const navigate = useNavigate()

  return (
    <Dialog
      open={props.isFinishAnswerSubmission}
      maxWidth="xs"
      fullWidth={true}
    >
      <DialogTitle>
        <Typography color="primary">
          {props.isTimeIsRunOut ? 'Time has run out' : 'Finish Quiz?'}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent className="h-14">
        <Typography variant="body2">
          {props.isTimeIsRunOut
            ? 'Your submission has been recorded'
            : 'Are you sure want to finish the quiz?'}
        </Typography>
      </DialogContent>
      <DialogActions>
        {!props.isTimeIsRunOut && (
          <Typography
            color="textSecondary"
            className="cursor-pointer mr-3"
            variant="body1"
            onClick={() => props.setIsFinishAnswerSubmission(false)}
          >
            Cancel
          </Typography>
        )}

        <Button
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => {
            if (props.quizSubmission) props.quizSubmission()
            navigate(`/learner/course/${props.slug}/${props.lessonId}`)
          }}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const AnswerSubmission = () => {
  const [question, setQuestion] = React.useState<ILessonQuestion>({
    id: 0,
    choices: [],
    fulltext: '',
    fieldtype: '',
    lesson: 0,
    order: 0,
  })
  const [answers, setAnswers] = React.useState<IAnswerProps[]>([])
  const [countDownTiming, setCountDownTiming] = React.useState(-1)
  const [isFinishAnswerSubmission, setIsFinishAnswerSubmission] =
    React.useState<boolean>(false)
  const [isTimeRunOut, setIsTimeRunOut] = React.useState<boolean>(false)
  const navigate = useNavigate()
  const location = useLocation()
  const isResult = location?.pathname?.includes('result')
  // TODO: in next development, type can use for course lesson quiz or micro learning detail
  const {id, type} = useParams()
  const classes = useStyles()

  const {data: dataLesson, isLoading: loadingLesson} = LESSON_BY_ID(id)

  const {data: dataCourse, refetch: refetchCourse} = COURSE_BY_ID(
    dataLesson?.courseId,
    {
      enabled: !!dataLesson,
    }
  )

  const {data: dataSubmission} = QUIZ_SUBMISSION_BY_LESSON_ID_STUDENT_ID(
    id || dataLesson?.id
  )

  const {mutate: onAddLessonQuizAnswerSubmission} =
    ADD_LESSON_QUIZ_ANSWER_SUBMISSION(id || dataLesson?.id, question.id)

  const quizSubmission = () => {
    onAddLessonQuizAnswerSubmission({
      courseId: dataLesson.courseId,
      lessonId: dataLesson.id,
      questionId: question.id,
      answers: answers.find(
        (answer: IAnswerProps) => answer.questionId === question.id
      )?.answers,
    })
  }
  const renderCountDown = (props: RenderCountDownProps) => {
    if (props.completed) {
      return (
        <TimeHasRunOut
          isFinishAnswerSubmission={isFinishAnswerSubmission}
          setIsFinishAnswerSubmission={setIsFinishAnswerSubmission}
          setIsTimeRunOut={setIsTimeRunOut}
          slug={dataCourse?.slug}
          lessonId={id || dataLesson?.id}
        />
      )
    } else {
      return (
        <Typography color="primary">
          {props.hours}:{props.minutes}:{props.seconds}
        </Typography>
      )
    }
  }

  const renderField = () => {
    switch (question.fieldtype) {
      case 'checkbox':
        return (
          <QuizLessonCheckBox
            answers={answers}
            setAnswers={setAnswers}
            isIncrementQuestionNumberAvailable={false}
            isDecrementQuestionNumberAvailable={false}
            isResult={isResult}
            data={question}
          />
        )
      case 'multiple':
        return (
          <QuizLessonMultipleChoice
            answers={answers}
            setAnswers={setAnswers}
            isIncrementQuestionNumberAvailable={false}
            isDecrementQuestionNumberAvailable={true}
            isResult={isResult}
            data={question}
          />
        )
      default:
        return (
          <QuizLessonShortAnswer
            answers={answers}
            setAnswers={setAnswers}
            isIncrementQuestionNumberAvailable={false}
            isDecrementQuestionNumberAvailable={false}
            data={question}
          />
        )
    }
  }

  useTitle(dataLesson?.title || '')

  React.useEffect(() => {
    if (dataLesson) {
      setQuestion(dataLesson.AcademyCourseQuestions[0])
      setCountDownTiming(
        Date.now() + convertHMSToMilliseconds(dataLesson.duration)
      )

      refetchCourse()
    }
  }, [dataLesson, refetchCourse])

  React.useEffect(() => {
    if (dataSubmission && isResult) {
      const _answers: any = []
      dataSubmission.forEach((submission: any) => {
        _answers.push({
          questionId: submission.id,
          answers: submission?.AcademyUserAnswers[0]?.answers || [],
        })
      })

      setAnswers(_answers)
    }
  }, [dataSubmission, isResult])

  if (loadingLesson) {
    return <Skeleton height={780} width={'100%'} />
  }
  const currentQuestionIndex = dataLesson.AcademyCourseQuestions.findIndex(
    (qn: ILessonQuestion) => qn.id === question.id
  )

  const handleNextPrev = (isBack: boolean) => {
    const nextQuestionIdx = isBack
      ? currentQuestionIndex - 1
      : currentQuestionIndex + 1

    setQuestion(dataLesson.AcademyCourseQuestions[nextQuestionIdx])
    const isAnswerAlreadyExist = answers.find(
      (answer: IAnswerProps) => answer.questionId === question.id
    )?.answers

    if (isAnswerAlreadyExist) {
      quizSubmission()
    }
  }

  const correctionAnswer = (item: ILessonQuestion) => {
    const findAnswer = dataSubmission.find((data: any) => data.id === item.id)
    const answer = findAnswer?.AcademyUserAnswers[0]?.answers
    const isCorrect = answer.every((e: any) => item?.answerKeys?.includes(e))

    return isCorrect
  }

  return (
    <div className={classes.rootContainer}>
      {type === 'quiz' && (
        <AppBar position="static">
          <Toolbar
            className={classNames(
              classes.toolbar,
              'flex flex-row justify-between items-center px-40'
            )}
          >
            <img
              src={COMPANY_LOGO || SmartASNLogo}
              className={classes.logoImg}
              alt="logo"
            />

            <Typography variant="h6" color="primary">
              {dataLesson.title}
            </Typography>
            <Typography
              color="error"
              className="cursor-pointer"
              hidden={!dataCourse?.slug}
              onClick={() => {
                navigate(-1)
              }}
            >
              Close
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <div className="flex flex-row px-28 py-10">
        <Card className="w-1/4 h-full">
          <List>
            <ListItem className="flex flex-row justify-between py-3">
              <Typography color="primary" className="font-extrabold">
                Question
              </Typography>
            </ListItem>
          </List>
          <Divider />
          <List>
            {dataLesson.AcademyCourseQuestions.map(
              (item: ILessonQuestion, index: number) => (
                <ListItem
                  button
                  onClick={() => setQuestion(item)}
                  key={`question-${index}`}
                  className="flex items-start py-4"
                  selected={item === question}
                >
                  <LinesEllipsis
                    className={
                      item === question
                        ? classes.selectedFont
                        : classes.unselectedFont
                    }
                    text={`${index + 1}. ${item.fulltext.replace(
                      /(<([^>]+)>)/gi,
                      ''
                    )}`}
                    maxLine="1"
                    ellipsis="..."
                    trimRight
                    basedOn="letters"
                  />
                  {/*  <Typography
                    className={
                      item === question
                        ? classes.selectedFont
                        : classes.unselectedFont
                    }
                  >
                    {index + 1}.&nbsp;
                  </Typography>
                  <div
                    className={
                      item === question
                        ? classes.selectedFont
                        : classes.unselectedFont
                    }
                    dangerouslySetInnerHTML={{
                      __html: Dompurify.sanitize(item.fulltext),
                    }}
                  /> */}
                  {isResult && (
                    <ListItemSecondaryAction>
                      {correctionAnswer(item) ? (
                        <CheckCircleOutlineIcon style={{color: '#4CAF50'}} />
                      ) : (
                        <HighlightOffIcon style={{color: '#DC004E'}} />
                      )}
                    </ListItemSecondaryAction>
                  )}
                </ListItem>
              )
            )}
          </List>
        </Card>
        <Card className={classNames(classes.questionContainer, 'mx-10 w-3/4')}>
          <List>
            <ListItem className="flex flex-row justify-between">
              <Typography color="textSecondary">
                {dataLesson.AcademyCourseQuestions.findIndex(
                  (qn: ILessonQuestion) => qn.id === question.id
                ) + 1}{' '}
                of {dataLesson.AcademyCourseQuestions.length} questions
              </Typography>
              {isResult ? (
                <div className="flex flex-col">
                  <Typography variant="body2" className="pl-2">
                    Score:
                  </Typography>
                  <div className="flex flex-row">
                    <Typography variant="h4" className="text-green-700 mx-2">
                      {(
                        (dataSubmission?.filter(
                          (submission: ICourseQuizSubmission) =>
                            submission?.AcademyUserAnswers[
                              submission?.AcademyUserAnswers.length - 1
                            ]?.grade === 100
                        ).length /
                          dataSubmission?.length) *
                        100
                      ).toFixed(0)}
                    </Typography>
                    <Typography variant="body2" className="mt-4">
                      (
                      {
                        dataSubmission?.filter(
                          (submission: ICourseQuizSubmission) =>
                            submission?.AcademyUserAnswers[
                              submission?.AcademyUserAnswers.length - 1
                            ]?.grade === 100
                        ).length
                      }
                      /{dataSubmission?.length})
                    </Typography>
                  </div>
                </div>
              ) : (
                <div className="flex flex-row items-center">
                  <AccessAlarm
                    color="primary"
                    className="mr-2"
                    fontSize="large"
                  />
                  {countDownTiming > -1 && (
                    <Countdown
                      date={
                        // Date.now() + 3000
                        // Date.now() + convertHMSToMilliseconds(dataLesson.duration)
                        countDownTiming
                      }
                      renderer={renderCountDown}
                    />
                  )}
                </div>
              )}
            </ListItem>
          </List>
          <Divider />
          <div
            className={classNames(
              'flex flex-col justify-between overflow-auto',
              classes.questionOptionContainer
            )}
          >
            <div className="flex flex-col">
              <div className="flex flex-row items-center pl-4">
                <Typography className="font-extrabold">
                  {dataLesson.AcademyCourseQuestions.findIndex(
                    (qn: ILessonQuestion) => qn.id === question.id
                  ) + 1}
                  .{' '}
                </Typography>
                <div
                  className="p-4 font-extrabold"
                  dangerouslySetInnerHTML={{
                    __html: Dompurify.sanitize(question.fulltext),
                  }}
                />
              </div>
              {renderField()}
            </div>
            <div className="flex flex-row justify-between px-3">
              <Button
                size="large"
                className={`w-32`}
                variant="outlined"
                color={'primary'}
                disabled={currentQuestionIndex === 0}
                onClick={() => handleNextPrev(true)}
              >
                Previous
              </Button>
              {currentQuestionIndex + 1 >=
              dataLesson.AcademyCourseQuestions.length ? (
                !isResult && (
                  <Button
                    size="large"
                    className={`w-32`}
                    variant="contained"
                    color={'primary'}
                    onClick={() => {
                      setIsFinishAnswerSubmission(true)
                    }}
                  >
                    Finish
                  </Button>
                )
              ) : (
                <Button
                  size="large"
                  className={`w-32`}
                  variant="contained"
                  color={'primary'}
                  onClick={() => handleNextPrev(false)}
                >
                  Next
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
      <FinishAnswerSubmissionDialog
        isFinishAnswerSubmission={isFinishAnswerSubmission}
        setIsFinishAnswerSubmission={setIsFinishAnswerSubmission}
        isTimeIsRunOut={isTimeRunOut}
        quizSubmission={quizSubmission}
        slug={dataCourse?.slug}
        lessonId={id || dataLesson?.id}
      />
    </div>
  )
}

export default AnswerSubmission
