import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import TabRow from '../../catalog/tabs/TabRow.component'
import {LearningType} from '../../../../utils/enum'
import {IMySocialLearning} from '../../../../interfaces/SocialLearning.interface'
import {PUBLIC_SOCIAL_LEARNING} from '../../../../provider/services/SocialLearning.service'
import {useSnackbar} from 'notistack'
import {IAcademicFilter} from '../../../../interfaces/Filter.interface'

import LoadingCard from '../../../loading/LoadingCard.component'

type SocialLearningSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: IAcademicFilter
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  activeTab: LearningType
}

function SocialLearningSearchFilterTab(
  props: SocialLearningSearchFilterTabProps
) {
  const {countResult, setCountResult} = props
  const {enqueueSnackbar} = useSnackbar()

  const {
    data: dataListAllSocialLearning,
    loading: loadingListAllSocialLearning,
    error: errorListAllSocialLearning,
    refetch: refetchListAllSocialLearning,
  } = PUBLIC_SOCIAL_LEARNING(
    -1,
    0,
    props.searchKeyword,
    // Number(props.academicFilter.priceMax),
    // Number(props.academicFilter.priceMin),
    props.academicFilter.categoriesGQL,
    [
      {
        average_rating: {
          _gte: Number(props.academicFilter.rating),
          _neq: -1,
        },
      },
    ]
  )

  React.useEffect(() => {
    if (
      dataListAllSocialLearning?.classrooms_aggregate.aggregate.count !==
      countResult.countResultSocialLearning
    ) {
      setCountResult({
        ...countResult,
        countResultSocialLearning:
          dataListAllSocialLearning?.classrooms_aggregate.aggregate.count,
      })
    }
  }, [
    dataListAllSocialLearning,
    countResult,
    setCountResult,
    errorListAllSocialLearning,
  ])

  React.useEffect(() => {
    setTimeout(refetchListAllSocialLearning, 1000)
  }, [
    props.searchKeyword,
    props.academicFilter,
    props.priceFilter,
    refetchListAllSocialLearning,
    props.activeTab,
  ])

  if (loadingListAllSocialLearning) {
    return <LoadingCard length={4} />
  } else if (errorListAllSocialLearning) {
    enqueueSnackbar(JSON.stringify(errorListAllSocialLearning), {
      variant: 'error',
    })
  }

  const classRoomData = [...dataListAllSocialLearning.classrooms]

  return (
    <Grid container className="px-2 pb-2">
      <TabRow
        title={''}
        data={
          props.priceFilter === 'Price (Low - High)'
            ? classRoomData
                .sort(
                  (a: IMySocialLearning, b: IMySocialLearning) =>
                    Number(a.price) - Number(b.price)
                )
                .filter((socialLearning: IMySocialLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      socialLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      socialLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return socialLearning
                  }
                })
            : props.priceFilter === 'Price (High - Low)'
            ? classRoomData
                .sort(
                  (a: IMySocialLearning, b: IMySocialLearning) =>
                    Number(b.price) - Number(a.price)
                )
                .filter((socialLearning: IMySocialLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      socialLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      socialLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return socialLearning
                  }
                })
            : classRoomData
                .sort(
                  (a: IMySocialLearning, b: IMySocialLearning) =>
                    Number(b.date_added) - Number(a.date_added)
                )
                .filter((socialLearning: IMySocialLearning) => {
                  if (
                    props.academicFilter.priceMin &&
                    props.academicFilter.priceMax
                  ) {
                    return (
                      socialLearning.price >=
                        Number(props.academicFilter.priceMin) &&
                      socialLearning.price <=
                        Number(props.academicFilter.priceMax)
                    )
                  } else {
                    return socialLearning
                  }
                })
        }
        type={LearningType.SOCIAL_LEARNING}
        isAll={true}
        isFilterCatalog={true}
      />
    </Grid>
  )
}

export default SocialLearningSearchFilterTab
