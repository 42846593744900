import {useNavigate} from 'react-router-dom'
import {useQuery} from '@apollo/client'
import LinesEllipsis from 'react-lines-ellipsis'
import {GET_CERTIFICATE_PROFILE} from '../../../graphql/queries'
import {ICertificate} from '../../../interfaces/Certificate.interface'
import {USER_ID} from '../../../utils/globals'
import LoadingCircular from '../../loading/LoadingCircular.component'
import {CustomTypography} from '../../utils-components/GlobalStyles'

const CertificateCard = () => {
  const navigate = useNavigate()
  const {data, loading} = useQuery(GET_CERTIFICATE_PROFILE, {
    variables: {userId: USER_ID},
  })

  if (loading) {
    return <LoadingCircular />
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <CustomTypography fsize="16px" fweight="bold" fcolor="#014a62">
          Sertifikat ({data.academy_certificates.length})
        </CustomTypography>
      </div>
      <hr />
      <div className="px-5 pt-5 space-y-5">
        {data.academy_certificates
          .slice(0, 4)
          .map((item: ICertificate, i: number) => (
            <div key={i}>
              <div
                className="flex gap-3 cursor-pointer"
                onClick={() =>
                  navigate(`/learner/my-certificate/detail/${item?.id}`)
                }
              >
                <img
                  src={
                    item.academy_course_enrollment?.academy_course?.thumbnail ||
                    require('../../../assets/images/defaultCourseCover.image.png')
                  }
                  className="w-11 h-11 rounded-md"
                  alt=""
                />
                <div className="w-full">
                  <CustomTypography fsize="14px" fweight="semi-bold">
                    <LinesEllipsis
                      text={
                        item.academy_course_enrollment?.academy_course?.title ??
                        '-'
                      }
                      maxLine="1"
                      ellipsis="..."
                      trimRight
                    />
                  </CustomTypography>
                  <CustomTypography mt="4px" fsize="14px" fcolor="#a9a8a8">
                    Course
                  </CustomTypography>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="p-6 ">
        <button
          className="text-xs bg-transparent text-[#039be5]"
          onClick={() => navigate(`/learner/my-certificate`)}
        >
          Lihat Semua
        </button>
      </div>
    </div>
  )
}

export default CertificateCard
