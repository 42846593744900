import React, {Dispatch, SetStateAction} from 'react'
import {Grid} from '@material-ui/core'
import TabRow from '../../catalog/tabs/TabRow.component'
import {LearningType} from '../../../../utils/enum'
import {LIST_ALL_BOOKS} from '../../../../provider/services/Book.service'
import LoadingCard from '../../../loading/LoadingCard.component'
import {IBook} from '../../../../interfaces/Book.interface'
import {IAcademicFilter} from '../../../../interfaces/Filter.interface'

type BookSearchFilterTabProps = {
  searchKeyword: string
  academicFilter: IAcademicFilter
  countResult: {
    countResultCourse: number
    countResultBook: number
    countResultEvent: number
    countResultMicroLearning: number
    countResultSocialLearning: number
    countResultContributor: number
  }
  setCountResult: Dispatch<
    SetStateAction<{
      countResultCourse: number
      countResultBook: number
      countResultEvent: number
      countResultMicroLearning: number
      countResultSocialLearning: number
      countResultContributor: number
    }>
  >
  priceFilter: string
  bookType: number
  activeTab: LearningType
}

function BookSearchFilterTab(props: BookSearchFilterTabProps) {
  const {countResult, setCountResult} = props
  const [loading, setLoading] = React.useState(false)

  const {
    data: dataListAllBooks,
    isLoading: loadingListAllBooks,
    refetch: refetchListAllBooks,
  } = LIST_ALL_BOOKS(
    props.bookType,
    -1,
    props.searchKeyword,
    props.academicFilter.priceMin,
    props.academicFilter.priceMax,
    props.academicFilter.rating,
    props.academicFilter.categoriesREST,
    props.academicFilter.levelsREST,
    props.academicFilter.languagesREST,
    props.priceFilter === 'Latest'
      ? 'orders[0][0]=dateAdded&orders[0][1]=desc'
      : ''
  )

  const disableLoading = () => setLoading(false)

  React.useEffect(() => {
    if (dataListAllBooks?.total !== countResult.countResultBook) {
      setCountResult({
        ...countResult,
        countResultBook: dataListAllBooks?.total,
      })
    }
  }, [dataListAllBooks, countResult, setCountResult])

  React.useEffect(() => {
    setLoading(true)

    setTimeout(refetchListAllBooks, 1000)
    setTimeout(disableLoading, 1000)
  }, [
    props.searchKeyword,
    props.academicFilter,
    props.priceFilter,

    refetchListAllBooks,
  ])

  React.useEffect(() => {
    setLoading(true)

    refetchListAllBooks()
    setTimeout(disableLoading, 1000)
  }, [props.bookType, refetchListAllBooks])

  if (loadingListAllBooks || loading) {
    return <LoadingCard length={4} />
  }

  return (
    <Grid container className="px-2 pb-2">
      <TabRow
        title={''}
        data={
          props.priceFilter === 'Price (Low - High)'
            ? dataListAllBooks.data.sort(
                (a: IBook, b: IBook) =>
                  Number(a.pricingIdr) - Number(b.pricingIdr)
              )
            : props.priceFilter === 'Price (High - Low)'
            ? dataListAllBooks.data.sort(
                (a: IBook, b: IBook) =>
                  Number(b.pricingIdr) - Number(a.pricingIdr)
              )
            : dataListAllBooks.data
        }
        type={LearningType.BOOK}
        isAll={true}
        isFilterCatalog={true}
      />
    </Grid>
  )
}

export default BookSearchFilterTab
