const emptyDoughnoutData = {
  labels: ['No data'],
  datasets: [
    {
      data: [100],
      backgroundColor: ['#E5E5E5'],
      borderColor: ['#E5E5E5'],
      borderWidth: 1,
    },
  ],
}

export {emptyDoughnoutData}
