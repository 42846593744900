import ContributorEnrollmentsCard from './ContributorEnrollmentsCard'
import ContributorCatalogAggregateCard from './ContributorCatalogAggregateCard'
import ContributorWishlistAggregateCard from './ContributorWishlistAggregateCard'
// import ContributorEarningsCard from './ContributorEarningsCard'
import ContributorPopularCatalogCard from './ContributorPopularCatalogCard'
import ContributorTopCatalogCard from './ContributorTopCatalogCard'

const ContributorDashboardPage = () => {
  return (
    <div className="grid gap-4">
      <div className="grid gap-4">
        <ContributorEnrollmentsCard />

        <ContributorCatalogAggregateCard />
        <ContributorWishlistAggregateCard />
      </div>

      {/* <ContributorEarningsCard /> */}

      <ContributorPopularCatalogCard />

      <ContributorTopCatalogCard />
    </div>
  )
}

export default ContributorDashboardPage
