import {gql} from '@apollo/react-hooks'

export const GET_RECOMMENDED_EVENTS = gql`
  query ($userId: uuid!, $offset: Int, $limit: Int) {
    event_categories(
      where: {
        event_schedules: {
          event_enrollments: {global_user: {id: {_eq: $userId}}}
        }
      }
      offset: $offset
      limit: $limit
    ) {
      id
      code
      name
      fa_class
      thumbnail
      parent: event_category {
        id
        name
      }
    }
  }
`
