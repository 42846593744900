import React from 'react'
import {useParams, useSearchParams} from 'react-router-dom'
import {useApolloClient, useQuery} from '@apollo/client'
import {useSnackbar} from 'notistack'

import {
  CircularProgress,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@material-ui/core'

import RatingSummary from '../../../../../components/review-rating/RatingSummary'
import RatingSelect from '../../../../../components/review-rating/RatingSelect'
import {
  RatingComment,
  ReplyAction,
  ReplyTextarea,
} from '../../../../../components/review-rating/RatingComment'
import {Pagination} from '../../../../../components/contributor/Pagination'
import NoListComponent from '../../../../../components/empty-state/NoList.component'
import DeletePopup from '../../../../shared-component/popup/DeletePopup'

import {USER_ID} from '../../../../../utils/globals'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import {
  GET_CONTRIBUTOR_BOOK_RATING_AGGREGATE,
  GET_CONTRIBUTOR_BOOK_REVIEWS,
} from '../../../../../graphql/queries'
import {UPDATE_BOOK_REVIEW_REPLY} from '../../../../../graphql/mutations/contributor/books.mutation'

export interface ContributorBookReviewPageProps {
  type: 'ebook' | 'audiobook'
}

interface CommentProps {
  data: any
}

const enum EditState {
  NONE,
  EDITING,
  DELETING,
}

const Comment = (props: CommentProps) => {
  const {data} = props

  const {enqueueSnackbar} = useSnackbar()
  const client = useApolloClient()

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const [state, setState] = React.useState(EditState.NONE)

  const placement = data.user.placements[0]
  const hasReply = data.review_reply != null
  const replyUser = data.globalUserByReviewReplyUser

  const handleReplySubmit = (next: string | null) => {
    setState(EditState.NONE)

    const promise = client.mutate({
      mutation: UPDATE_BOOK_REVIEW_REPLY,
      variables: {
        reviewId: data.id,
        set: {
          review_reply: next,
          review_reply_date: 'now()',
          review_reply_user: USER_ID,
        },
      },
      context: INSTRUCTOR_CONTEXT,
    })

    promise.catch(() => {
      enqueueSnackbar(`Failed to ${next ? 'add' : 'delete'} reply`, {
        variant: 'error',
      })
    })
  }

  const handleMenuClose = () => {
    setAnchorEl(undefined)
  }

  return (
    <>
      <RatingComment
        author={data.user.name}
        avatarUrl={data.user.avatar}
        position={`${placement?.company_job_profile.title} at ${placement?.company_profile.brand_name}`}
        date={data.date_added}
        rating={data.rating}
        body={data.review_text}
        footer={
          state === EditState.EDITING ? (
            <ReplyTextarea
              initialValue={data.review_reply}
              onSubmit={handleReplySubmit}
              onCancel={() => setState(EditState.NONE)}
            />
          ) : !hasReply ? (
            <ReplyAction onReply={() => setState(EditState.EDITING)} />
          ) : (
            <RatingComment
              author={replyUser.name}
              avatarUrl={replyUser.avatar}
              position={replyUser.contributor_label}
              positionHighlight
              date={data.review_reply_date}
              body={data.review_reply}
              gutterTop
              onMenu={(event) => setAnchorEl(event.currentTarget)}
            />
          )
        }
      />

      {hasReply && (
        <>
          <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={handleMenuClose}>
            <MenuItem
              onClick={() => {
                handleMenuClose()
                setState(EditState.EDITING)
              }}
            >
              Edit
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleMenuClose()
                setState(EditState.DELETING)
              }}
            >
              Delete
            </MenuItem>
          </Menu>

          <DeletePopup
            open={state === EditState.DELETING}
            name="comment"
            checkboxText="I understand that deleting this comment is permanent and cannot be undone. This action will also delete replies in this comment."
            mutation={() => handleReplySubmit(null)}
            handleClose={() => setState(EditState.NONE)}
          />
        </>
      )}
    </>
  )
}

const ContributorBookReviewPage = (props: ContributorBookReviewPageProps) => {
  const {type} = props

  const [searchParams, setSearchParams] = useSearchParams()
  const {bookSlug} = useParams()
  const isEbook = type === 'ebook'

  const selectedRating = searchParams.has('rating')
    ? +searchParams.get('rating')!
    : undefined

  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const {data: aggregateData} = useQuery(
    GET_CONTRIBUTOR_BOOK_RATING_AGGREGATE,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        isbn: bookSlug!,
      },
    }
  )

  const {data, error} = useQuery(GET_CONTRIBUTOR_BOOK_REVIEWS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      where: {
        isbn: {_eq: bookSlug!},
        rating: {_eq: selectedRating},
      },
      offset: page * limit,
      limit: limit,
    },
  })

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setSearchParams(next)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setSearchParams(next)
  }

  const handleRatingSelect = (newRating: number | undefined) => {
    const next = new URLSearchParams(searchParams)

    if (newRating) {
      next.set('rating', '' + newRating)
    } else {
      next.delete('rating')
    }

    setSearchParams(next)
  }

  return (
    <Paper>
      <Typography color="primary" className="px-6 py-4 font-bold">
        {isEbook ? 'E-Book' : 'Audiobook'} Review
      </Typography>

      <Divider />

      <div className="flex flex-col gap-6 p-6">
        <RatingSummary
          average={aggregateData?.total.aggregate.avg.rating}
          total={aggregateData?.total.aggregate.count}
          total5={aggregateData?.total5.aggregate.count}
          total4={aggregateData?.total4.aggregate.count}
          total3={aggregateData?.total3.aggregate.count}
          total2={aggregateData?.total2.aggregate.count}
          total1={aggregateData?.total1.aggregate.count}
        />

        <RatingSelect value={selectedRating} onChange={handleRatingSelect} />

        <Divider />

        {error ? (
          <div>Something went wrong</div>
        ) : !data ? (
          <div className="grid h-96">
            <CircularProgress className="place-self-center" />
          </div>
        ) : data.reviews.length > 0 ? (
          data.reviews.map((item: any) => <Comment key={item.id} data={item} />)
        ) : (
          <NoListComponent title="Sorry, No List" />
        )}
      </div>

      <Divider />

      <Pagination
        count={data?.total.aggregate.count || 0}
        page={page}
        rowsPerPage={limit}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default ContributorBookReviewPage
