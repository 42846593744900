import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'

import { Button, MenuItem, Select } from '@material-ui/core'
import { Filter } from '@smartasn/wlb-utils-components'

import { CommentAction } from '../../comment/DiscussionComment'
import CommentRootListing, {
  Answered,
  DateRange,
} from '../../comment/CommentRootListing'

import { USER_ID } from '../../../../../../utils/globals'
import { INSTRUCTOR_CONTEXT } from '../../../../../../utils/contributor-helpers'
import { ADD_COMMENT } from '../../../../../../graphql/mutations'

const commentFilters = [
  {
    name: 'Comment Status',
    fieldName: 'status',
    type: 'checkbox',
    options: {
      list: [
        {
          label: 'Answered',
          value: Answered.ANSWERED,
        },
        {
          label: 'Unanswered',
          value: Answered.UNANSWERED,
        },
      ],
    },
  },

  {
    name: 'Comment Date',
    fieldName: 'date',
    type: 'date',
  },
]

const enum SortState {
  OLDEST = 'asc',
  LATEST = 'desc',
}

export interface LessonCommentsProps {
  courseId: number
  lessonId: number
}

const LessonComments = (props: LessonCommentsProps) => {
  const { courseId, lessonId } = props

  const [addComment] = useMutation(ADD_COMMENT)

  const [searchParams, setSearchParams] = useSearchParams()

  const sort = (searchParams.get('sort') as SortState) ?? SortState.LATEST
  const status = (searchParams.get('status') as Answered) ?? Answered.NONE

  // NOTE(intrnl): these needs to be memoized for <CommentRoot>
  const date = React.useMemo((): DateRange | undefined => {
    const from = searchParams.get('from') ?? undefined
    const to = searchParams.get('to') ?? undefined

    if (from || to) {
      return { from, to }
    }

    return undefined
  }, [searchParams])

  const handleSubmit = (text: string, lesson: any) => {
    addComment({
      variables: {
        objects: {
          fulltext: text,
          user_id: USER_ID,
          title: `Discussion on ${lesson.title}`,
          lesson_id: lesson.id,
        },
      },
      refetchQueries: ['getCourseComments'],
      context: INSTRUCTOR_CONTEXT,
    })
  }

  const handleFilterApply = ({ 0: include }: any) => {
    const next = new URLSearchParams(searchParams)

    const status = include.status
    const date = include.date

    if (status && status.length === 1) {
      next.set('status', status[0].value)
    } else {
      next.delete('status')
    }

    if (date?.from) {
      next.set('from', date.from.toISOString())
    } else {
      next.delete('from')
    }

    if (date?.to) {
      next.set('to', date.to.toISOString())
    } else {
      next.delete('to')
    }

    setSearchParams(next, { replace: true })
  }

  const handleSortChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const next = new URLSearchParams(searchParams)
    next.set('sort', event.target.value as SortState)

    setSearchParams(next, { replace: true })
  }

  return (
    <div>
      <CommentAction
        courseId={courseId}
        lessonId={lessonId}
        onSubmit={handleSubmit}
      />

      <div className="flex items-center justify-between px-2 pb-6">
        <Select
          value={sort}
          defaultValue="desc"
          variant="standard"
          disableUnderline
          SelectDisplayProps={{
            className:
              'text-sm font-semibold pr-6 pl-4 py-2 focus:bg-white outline-none rounded transition',
          }}
          onChange={handleSortChange}
        >
          <MenuItem value="desc">Latest</MenuItem>
          <MenuItem value="asc">Oldest</MenuItem>
        </Select>

        <Filter
          onApply={handleFilterApply}
          listFilter={commentFilters}
          includeExclude={false}
        >
          <Button className="font-semibold">Filter</Button>
        </Filter>
      </div>

      <div className="p-6 pt-0">
        <CommentRootListing
          courseId={courseId}
          lessonId={lessonId}
          answers={status}
          date={date}
          sort={sort}
        />
      </div>
    </div>
  )
}

export default LessonComments
