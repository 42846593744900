import React from 'react'
import {useLocation} from 'react-router-dom'
import {IMicroLearning} from '../../../../interfaces'
import {
  RECOMMENDED_MICROLEARNING,
  TRENDING_MICROLEARNING,
} from '../../../../provider/services/MicroLearning.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import SearchSeeAll from '../SearchSeeAll.component'

import {SeeAllPageContext} from '../../../../pages/learner/see-all'

interface MicroLearningProps extends SeeAllPageContext {}

const MicroLearning = (props: MicroLearningProps): React.ReactElement => {
  const [search, setSearch] = React.useState('')
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  }

  const {
    data: recommendMicroLearning,
    loading: recommendedLoading,
    refetch: refetchRecommended,
  } = RECOMMENDED_MICROLEARNING(
    -1,
    0,
    search,
    Number(props.filter.rating),
    props.filter.priceMin,
    props.filter.priceMax,
    props.filter.languagesGQL,
    props.filter.categoriesGQL,
    props.filter.levelsGQL
  )

  const {data: trendingMicroLearning, loading: trendingLoading} =
    TRENDING_MICROLEARNING(
      -1,
      0,
      search,
      Number(props.filter.rating),
      props.filter.priceMin,
      props.filter.priceMax,
      props.filter.languagesGQL,
      props.filter.categoriesGQL,
      props.filter.levelsGQL
    )

  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  React.useEffect(() => {
    refetchRecommended()
  }, [props.filter, search, refetchRecommended])
  const location = useLocation()
  const isRecommended = location.pathname.includes('recommended')

  const title = isRecommended ? 'Rekomendasi di SmartASN' : 'Tren di SmartASN'

  const _response = isRecommended
    ? recommendMicroLearning
    : trendingMicroLearning

  const dataMicrolearning = _response?.getListMicrolearning?.microlearnings

  return (
    <div>
      <SearchSeeAll
        placeholder="Cari Pembelajaran Mikro"
        value={search}
        handleChange={handleChange}
      />
      <div className="mt-8">
        <p className="mt-5 text-[#a9a8a8] text-sm">
          {dataMicrolearning?.length} Hasil dari {title}
        </p>
      </div>
      <div>
        {trendingLoading || recommendedLoading ? (
          <div className="flex justify-center">
            <LoadingCardComponent length={4} />
          </div>
        ) : (
          dataMicrolearning?.length < 1 && (
            <NoListComponent
              title="Tidak ada hasil yang ditemukan"
              subTitle="Sepertinya kami tidak dapat menemukan hasil apa pun berdasarkan pencarian Anda"
            />
          )
        )}
        <div className="grid grid-cols-2 lg:grid-cols-4">
          {dataMicrolearning?.map((e: IMicroLearning, i: number) => (
            <LearnerCatalogCard
              type={LearningType.MICRO_LEARNING}
              item={e}
              key={i}
              hidePrice={false}
              width="w-full"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default MicroLearning
