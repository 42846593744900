import gql from 'graphql-tag'

export const EDIT_LEARNING_DIARY = gql`
  mutation (
    $id: uuid
    $subject: String
    $competency: bigint
    $activity: bigint
    $duration: Int
    $date: date
    $description: String
    $object: [learning_diary_participants_insert_input!]!
    $objectFiles: [learning_diary_files_insert_input!]!
    $idDelete: uuid_comparison_exp
    $idFiles: bigint_comparison_exp
    $diaryCompetencyId: uuid
  ) {
    update_learning_diaries(
      where: {id: {_eq: $id}}
      _set: {
        subject: $subject
        activity: $activity
        duration: $duration
        description: $description
        date: $date
        competency_dictionary_id: $competency
        diary_competency_id: $diaryCompetencyId
      }
    ) {
      returning {
        user_id
      }
      affected_rows
    }
    insert_learning_diary_files(objects: $objectFiles) {
      affected_rows
    }
    insert_learning_diary_participants(objects: $object) {
      affected_rows
    }
    delete_learning_diary_participants(where: {id: $idDelete}) {
      affected_rows
    }
    delete_learning_diary_files(where: {id: $idFiles}) {
      affected_rows
    }
  }
`
