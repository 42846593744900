import gql from 'graphql-tag'

export const GET_PUBLIC_CLASSROOM = gql`
  query getPublicClassroom(
    $userId: uuid!
    $limit: Int
    $offset: Int
    $keyword: String
    $price: Int_comparison_exp
    $categoryIds: [Int!]
    $rating: [classrooms_bool_exp]
  ) {
    classrooms_aggregate(
      where: {
        name: {_ilike: $keyword}
        price: $price
        category_id: {_in: $categoryIds}
        _or: $rating
      }
    ) {
      aggregate {
        count
      }
    }
    classrooms(
      limit: $limit
      offset: $offset
      where: {
        name: {_ilike: $keyword}
        price: $price
        category_id: {_in: $categoryIds}
        _or: $rating
      }
      order_by: [{date_added: desc}]
    ) {
      id
      avatar_url
      name
      description
      price
      rules
      privacy
      is_finished
      learning_contributors: classroom_members(
        where: {
          _or: [{is_individual_mentor: {_eq: true}}, {is_mentor: {_eq: true}}]
        }
      ) {
        global_user {
          id
          name
          avatar
        }
      }
      members: classroom_members(
        where: {
          is_mentor: {_eq: false}
          is_individual_mentor: {_eq: false}
          is_admin: {_eq: false}
        }
      ) {
        id
        user: global_user {
          id
          avatar
          name
        }
      }
      member_requests: classroom_member_requests(
        where: {user_id: {_eq: $userId}, status: {_eq: 0}}
        order_by: {created_at: desc, user_id: asc}
      ) {
        id
        status
        global_user {
          id
        }
      }
      contributor_requests: classroom_contrib_requests(
        where: {status: {_eq: 0}}
        order_by: {date_added: desc, contrib_user_id: asc}
        limit: 1
      ) {
        id
        status
        global_user {
          id
        }
      }
      academy_course_category {
        code
        name
      }
      global_user {
        avatar
        id
        name
      }
      classroom_reviews_aggregate {
        aggregate {
          avg {
            star
          }
          count
        }
      }
      date_added
    }
  }
`
