import {gql} from '@apollo/client'

export const INSERT_SOCIAL_GROUP = gql`
  mutation (
    $name: String!
    $description: String!
    $rules: String
    $avatar_url: String
    $privacy: Int!
    $price: Int
    $categoryId: Int!
    $mentor_id: String!
  ) {
    classroomCreate(
      name: $name
      description: $description
      rules: $rules
      avatar_url: $avatar_url
      price: $price
      privacy: $privacy
      categoryId: $categoryId
      mentor_id: $mentor_id
    ) {
      id
    }
  }
`

export const INSERT_SOCIAL_INDIVIDU = gql`
  mutation (
    $name: String!
    $mentor_id: String!
    $description: String!
    $rules: String
    $avatar_url: String!
    $category_id: Int!
  ) {
    classroomCreateIndividual(
      name: $name
      mentor_id: $mentor_id
      description: $description
      rules: $rules
      avatar_url: $avatar_url
      category_id: $category_id
    ) {
      id
    }
  }
`
