import {Avatar, Divider, Tooltip, Typography} from '@material-ui/core'
import {formatDistance, format} from 'date-fns'
import React, {FC, ReactElement} from 'react'
import Skeleton from 'react-loading-skeleton'
import {IListReview} from '../../interfaces'
import {TextBlue} from '../utils-components/GlobalStyles'
import DisplayRatingStar from './DisplayRatingStar.component'

const ReviewList: FC<IListReview> = (props: IListReview): ReactElement => {
  const {
    microListReviewData,
    microListReviewLoading,
    ratingCount,
    pagination,
    setPagination,
  } = props

  if (microListReviewLoading && !microListReviewData) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }
  return (
    <div>
      {microListReviewData?.length > 0 ? (
        <>
          {microListReviewData.map((review: any, index: number) => (
            <div className="flex flex-col" key={`review-${index}`}>
              <div
                className="flex flex-row pt-3 pb-2"
                key={`review-list-${index}`}
              >
                <Avatar
                  style={{width: 64, height: 64}}
                  src={review.user.avatar}
                />

                <div className="w-full flex flex-col ml-6 space-y-1">
                  <Typography>
                    {review.user.name?.split(' ')?.slice(0, 4)?.join(' ')}
                  </Typography>

                  {review?.user?.people_work_placements?.[0] && (
                    <Typography color="textSecondary" variant="caption">
                      {
                        review?.user?.people_work_placements?.[0]
                          ?.company_job_profile?.title
                      }{' '}
                      {review?.user?.people_work_placements?.[0]
                        ?.company_profile?.brand_name &&
                        'at ' +
                          review?.user?.people_work_placements?.[0]
                            ?.company_profile?.brand_name}
                    </Typography>
                  )}

                  <div className="flex flex-row items-center space-x-4">
                    <DisplayRatingStar count={review.star} />

                    <Tooltip
                      title={format(new Date(review.created_at), 'd MMM yyyy')}
                      placement="right-start"
                    >
                      <Typography color="textSecondary" className="text-xs">
                        {formatDistance(
                          new Date(review.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Typography>
                    </Tooltip>
                  </div>

                  <Typography variant="subtitle1" color="textSecondary">
                    {review.description}
                  </Typography>

                  {review.created_at && review.reply_date && (
                    <div
                      className="flex flex-row pt-3"
                      key={`review-reply-${index}`}
                    >
                      <Avatar
                        style={{width: 64, height: 64}}
                        src={review.position.avatar}
                      />
                      <div className="w-full flex flex-col ml-6 space-y-1">
                        <Typography>{review.position.name}</Typography>
                        <Typography color="textSecondary" variant="caption">
                          {
                            review?.position?.people_work_placements?.[0]
                              ?.company_job_profile?.title
                          }{' '}
                          {review?.position?.people_work_placements?.[0]
                            ?.company_profile?.brand_name &&
                            'at ' +
                              review?.position?.people_work_placements?.[0]
                                ?.company_profile?.brand_name}
                        </Typography>
                        <Typography
                          style={{color: '#039be5'}}
                          variant="caption"
                        >
                          Instructur
                        </Typography>

                        <div className="flex flex-row items-center ">
                          <Tooltip
                            title={format(
                              new Date(review.reply_date),
                              'd MMM yyyy'
                            )}
                            placement="right-start"
                          >
                            <Typography
                              color="textSecondary"
                              className="text-xs"
                            >
                              {formatDistance(
                                new Date(review.reply_date),
                                new Date(),
                                {
                                  addSuffix: true,
                                }
                              )}
                            </Typography>
                          </Tooltip>
                        </div>

                        <Typography variant="subtitle1" color="textSecondary">
                          {review.description}
                        </Typography>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}

          <Divider className="my-4" />
        </>
      ) : (
        <Typography
          variant="subtitle1"
          color="textSecondary"
          className="italic text-center"
        >
          Tidak ada ulasan untuk rating ini
        </Typography>
      )}

      {pagination.limit < ratingCount ? (
        <TextBlue
          className="w-fit cursor-pointer"
          onClick={() => {
            setPagination({
              ...pagination,
              limit: pagination.limit + 5,
            })
          }}
        >
          Ulasan Lebih Banyak
        </TextBlue>
      ) : ratingCount > 5 ? (
        <TextBlue
          className="w-fit cursor-pointer"
          onClick={() => {
            setPagination({limit: 5, offset: 0})
          }}
        >
          Lebih Sedikit Ulasan
        </TextBlue>
      ) : (
        ''
      )}
    </div>
  )
}

export default ReviewList
