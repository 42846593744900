import React from 'react'
import {useQuery} from '@apollo/client'
import {Link as RouterLink, useParams} from 'react-router-dom'

import {GET_CONTRIBUTOR_COURSE_LESSONS_BY_SECTION} from '../../../../../graphql/queries'
import {INSTRUCTOR_CONTEXT} from '../../../../../utils/contributor-helpers'
import useDebounce from '../../../../../hooks/useDebounce'

import {
  Box,
  Divider,
  InputAdornment,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import NotesOutlinedIcon from '@material-ui/icons/NotesOutlined'
import ListAltOutlinedIcon from '@material-ui/icons/ListAltOutlined'
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined'

import Skeleton from 'react-loading-skeleton'

import LoadingCircular from '../../../../../components/loading/LoadingCircular.component'
import NoListComponent from '../../../../../components/empty-state/NoList.component'

const renderIcon = (type: string) => {
  switch (type) {
    case 'video':
      return <VideocamOutlinedIcon />
    case 'quiz':
      return <ListAltOutlinedIcon />
    default:
      return <NotesOutlinedIcon />
  }
}

function ContributorCourseCurriculumPage() {
  const [search, setSearch] = React.useState('')
  const debouncedSearch = useDebounce(search)

  const {sectionId} = useParams()

  const {data} = useQuery(GET_CONTRIBUTOR_COURSE_LESSONS_BY_SECTION, {
    fetchPolicy: 'cache-and-network',
    variables: {
      sectionId: +sectionId!,
      search: `%${debouncedSearch}%`,
    },
    context: INSTRUCTOR_CONTEXT,
  })

  const section = data?.section
  const lessons = data?.lessons

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string)
  }

  return (
    <Paper>
      <Typography className="px-6 py-4 font-bold" color="primary">
        {section ? section.title : <Skeleton width="25%" />}
      </Typography>
      <Divider />

      <Box className="px-6 py-4">
        <TextField
          variant="outlined"
          placeholder="Search"
          fullWidth
          InputProps={{
            classes: {
              root: `p-0`,
              input: `pr-4 py-3 text-sm placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="primary" className="ml-4 mr-2" />
              </InputAdornment>
            ),
          }}
          onChange={handleSearch}
        />
      </Box>

      <div className="mb-6">
        {!lessons ? (
          <LoadingCircular height="50vh" />
        ) : lessons.length > 0 ? (
          lessons?.map((lesson: any, index: number) => (
            <ListItem className="px-6 py-4 border-b" key={index}>
              <ListItemIcon className="mr-2 min-w-min">
                {renderIcon(lesson.lesson_type)}
              </ListItemIcon>

              <ListItemText
                primary={lesson.title}
                classes={{primary: 'text-sm font-semibold'}}
              />

              <Link
                component={RouterLink}
                to={`${lesson.id}`}
                color="secondary"
                className="font-semibold text-sm"
              >
                Detail
              </Link>
            </ListItem>
          ))
        ) : (
          <NoListComponent title="Sorry, No List" />
        )}
      </div>
    </Paper>
  )
}

export default ContributorCourseCurriculumPage
