// NOTE(intrnl): high level overview for what's going on in this page
//
// - what's with `EditState`?
//   `EditState` enum is a requirement if we want to properly mark the form as
//   dirty when editing or creating new sections/lessons
//
// - what's with the direct use of `value` on `useFieldArray`?
//   you shouldn't be using index for keying, that leads into the wrong section
//   being removed, this is especially apparent when trying to cancel an edit
//   when there are currently two sections being edited at once
//
//   this seems to be the only way to work around that on Final Form
//
// - what's with `subscription` being used everywhere?
//   there is A LOT of things going on in this form page, especially with the
//   nested array setup, this causes unrelated fields to get notified with
//   updates that doesn't have anything to do with them.
//
//   TODO(intrnl): evaluate the performance impact

import React from 'react'
import {useNavigate, useParams} from 'react-router-dom'
import {Form} from 'react-final-form'
import {useApolloClient, useQuery} from '@apollo/client'

import {FormApi} from 'final-form'
import arrayMutators, {type Mutators as ArrayMutators} from 'final-form-arrays'

import {DragDropContext} from 'react-beautiful-dnd'
import {Button, Divider, IconButton, Paper, Typography} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import ConfirmationModal from '../../../../../components/modal/ConfirmationModal'
import FormLoading from '../../../../../components/input-forms/FormLoading'

import SectionList from './curriculums/SectionList'
import {isInterimId, LessonType} from './curriculums/_utils'

import {
  INSTRUCTOR_CONTEXT,
  IdContainer,
  FileContainer,
} from '../../../../../utils/contributor-helpers'
import {UPLOAD_URL, USER_ID} from '../../../../../utils/globals'
import {GET_CONTRIBUTOR_COURSE_CURRICULUM_DETAILS} from '../../../../../graphql/queries'
import {
  ADD_COURSE_CURRICULUM_LESSONS,
  ADD_COURSE_CURRICULUM_QUESTIONS,
  ADD_COURSE_CURRICULUM_SECTIONS,
  DELETE_COURSE_CURRICULUM_LESSONS,
  DELETE_COURSE_CURRICULUM_QUESTIONS,
  DELETE_COURSE_CURRICULUM_SECTIONS,
  UPDATE_COURSE_CURRICULUM_LESSON,
  UPDATE_COURSE_CURRICULUM_QUESTION,
  UPDATE_COURSE_CURRICULUM_SECTION,
} from '../../../../../graphql/mutations'

import useEvent from '../../../../../hooks/useEvent'
import axios from 'axios'

// NOTE(intrnl): we'll keep logging stuff behind this check
const isDevelopment = process.env.NODE_ENV === 'development'

const ContributorCourseManageCurriculumsPage = () => {
  const {courseSlug} = useParams()

  const navigate = useNavigate()
  const [openConfirm, setOpenConfirm] = React.useState(false)

  const client = useApolloClient()

  const {data, loading, refetch} = useQuery(
    GET_CONTRIBUTOR_COURSE_CURRICULUM_DETAILS,
    {
      fetchPolicy: 'cache-and-network',
      variables: {
        userId: USER_ID,
        slug: courseSlug!,
      },
      context: INSTRUCTOR_CONTEXT,
    }
  )

  const initialValues = React.useMemo(() => {
    if (!data || data.academy_courses.length < 1) {
      return
    }

    const course = data.academy_courses[0]

    return {
      id: course.id,
      academy_course_sections: course.academy_course_sections,
    }
  }, [data])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = useEvent(async (data: any, form: FormApi) => {
    const courseId = initialValues!.id

    // NOTE(intrnl): we don't have a custom mutation that would let us update
    // 3 tables at once, so the next best thing is to try batching some of the
    // operations that allows us to

    // INSERT and DELETE can be batched per-table, UPDATE can't be batched.

    // TODO(intrnl): these operations can at least be parallelized
    // https://github.com/microsoft/p-graph

    // NOTE(intrnl): these are used to track deletions
    const prevSectionIds = new Set<number>()
    const prevLessonIds = new Set<number>()
    const prevQuestionIds = new Set<number>()

    const insertSections: any[] = []
    const updateSections: any[] = []

    const insertLessons: any[] = []
    const updateLessons: any[] = []

    const insertQuestions: any[] = []
    const updateQuestions: any[] = []

    const uploadVideos: FileContainer[] = []
    const uploadFiles: FileContainer[] = []

    const _prevSections: any[] = initialValues!.academy_course_sections
    const _sections: any[] = data.academy_course_sections

    // 1. crawl through the previous data
    for (let i = 0; i < _prevSections.length; i++) {
      const prevSection = _prevSections[i]
      const prevSectionId = prevSection.id

      const _prevLessons: any[] = prevSection.academy_course_lessons

      prevSectionIds.add(prevSectionId)

      for (let j = 0; j < _prevLessons.length; j++) {
        const prevLesson = _prevLessons[j]
        const prevLessonId = prevLesson.id

        // NOTE(intrnl): even if this is for the quiz lessons, we'd want to
        // crawl through it anyway since this is available to all lesson types
        const _prevQuestions: any[] = prevLesson.academy_course_questions

        prevLessonIds.add(prevLessonId)

        for (let k = 0; k < _prevQuestions.length; k++) {
          const prevQuestion = _prevQuestions[k]
          const prevQuestionId = prevQuestion.id

          prevQuestionIds.add(prevQuestionId)
        }
      }
    }

    // 2. crawl through the current data
    for (let i = 0; i < _sections.length; i++) {
      const section = _sections[i]

      const _lessons: any[] = section.academy_course_lessons || []

      const inputs = {
        course_id: courseId,

        title: section.title,
        order: i,
      }

      let sectionId = section.id

      if (isInterimId(sectionId)) {
        sectionId = new IdContainer(sectionId)
        insertSections.push([sectionId, inputs])
      } else {
        prevSectionIds.delete(sectionId)
        updateSections.push([sectionId, inputs])
      }

      for (let j = 0; j < _lessons.length; j++) {
        const lesson = _lessons[j]
        const lessonType = lesson.lesson_type

        const inputs: any = {
          course_id: courseId,
          section_id: sectionId,

          title: lesson.title,
          lesson_type: lessonType,
          order: j,
        }

        let lessonId = lesson.id

        if (isInterimId(lessonId)) {
          lessonId = new IdContainer(lessonId)
          insertLessons.push([lessonId, inputs])
        } else {
          prevLessonIds.delete(lessonId)
          updateLessons.push([lessonId, inputs])
        }

        if (lessonType === LessonType.ARTICLE) {
          inputs.duration = lesson.duration

          if (lesson.summary == null) {
            const _resources: any[] = lesson.resources || []
            let resources: any[] = []

            for (let k = 0; k < _resources.length; k++) {
              const resource = _resources[k]

              // NOTE(intrnl): if we encounter a string here, it's a legacy
              // attachment format that hasn't been tampered yet, so we can
              // fully break this loop
              if (typeof resource === 'string') {
                resources = _resources
                break
              }

              let resourceUrl = resource.url

              if (resourceUrl instanceof File) {
                resourceUrl = new FileContainer(resourceUrl)
                uploadFiles.push(resourceUrl)

                resources.push({...resource, url: resourceUrl})
              } else {
                resources.push(resource)
              }
            }

            inputs.resources = resources
          } else {
            inputs.summary = lesson.summary
          }
        } else if (lessonType === LessonType.VIDEO) {
          let videoUrl = lesson.video_url

          if (videoUrl instanceof File) {
            videoUrl = new FileContainer(videoUrl)
            uploadVideos.push(videoUrl)
          }

          inputs.video_url = videoUrl
        } else if (lessonType === LessonType.QUIZ) {
          const _questions = lesson.academy_course_questions || []

          inputs.duration = lesson.duration

          for (let k = 0; k < _questions.length; k++) {
            const question = _questions[k]
            let questionId = question.id

            const inputs = {
              lesson: lessonId,

              fulltext: question.fulltext,
              order: k,
              fieldtype: question.fieldtype,
              choices: question.choices,
              answer_keys: question.answer_keys,

              eval_method: 'auto',
              passing_grade: 100,
              allow_partial_correctness: true,
            }

            if (isInterimId(questionId)) {
              questionId = new IdContainer(questionId)
              insertQuestions.push([questionId, inputs])
            } else {
              prevQuestionIds.delete(questionId)
              updateQuestions.push([questionId, inputs])
            }
          }
        } else {
          continue
        }
      }
    }

    // 3. we'll start by deleting stuff first
    if (prevQuestionIds.size > 0) {
      if (isDevelopment) {
        console.info(`deleting curriculum questions (${prevQuestionIds.size})`)
      }

      await client.mutate({
        mutation: DELETE_COURSE_CURRICULUM_QUESTIONS,
        variables: {
          questionIds: Array.from(prevQuestionIds),
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    if (prevLessonIds.size > 0) {
      if (isDevelopment) {
        console.info(`deleting curriculum lessons (${prevLessonIds.size})`)
      }

      await client.mutate({
        mutation: DELETE_COURSE_CURRICULUM_LESSONS,
        variables: {
          lessonIds: Array.from(prevLessonIds),
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    if (prevSectionIds.size > 0) {
      if (isDevelopment) {
        console.info(`deleting curriculum sections (${prevSectionIds.size})`)
      }

      await client.mutate({
        mutation: DELETE_COURSE_CURRICULUM_SECTIONS,
        variables: {
          sectionIds: Array.from(prevSectionIds),
        },
        context: INSTRUCTOR_CONTEXT,
      })
    }

    // 4. then we deal with the file uploads
    if (uploadFiles.length > 0) {
      if (isDevelopment) {
        console.info(`uploading files (${uploadFiles.length})`)
      }

      for (const file of uploadFiles) {
        const raw = file.raw

        const dataForm = new FormData()
        dataForm.append('file', raw)

        try {
          const response = await axios.post(UPLOAD_URL!, dataForm, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            withCredentials:true
          })

          const url = response.data.url
          file.url = url

          // TODO(intrnl): figure out a way to change the values in the form
          // so we don't have to do this upload again if it ever fails halfway.
          // form.change('')
        } catch {
          // TODO(intrnl): should we break here?
          // UploadContainer already throws anyway so I'm not sure if we need to
          // throw here specifically.
        }
      }
    }

    if (uploadVideos.length) {
      if (isDevelopment) {
        console.info(`uploading videos (${uploadVideos.length})`)
      }

      for (const file of uploadVideos) {
        const raw = file.raw

        try {
          const response = await axios.post(
            UPLOAD_URL! + '/video',
            {
              title: `${courseSlug!} Course Quiz Curriculum`,
              description: `${courseSlug!}`,
              format: raw.type,
            },
            {
              withCredentials:true
            }
          )

          const handshake = response.data
          const url = `${handshake.link.protocol}://${handshake.link.address}${handshake.link.path}`

          await axios.put(url, raw, {
            params: {
              AWSAccessKeyId: handshake.link.query.AWSAccessKeyId,
              Expires: handshake.link.query.Expires,
              Signature: handshake.link.query.Signature,
            },
            headers: {
              'Content-Type': raw.type,
            },
          })

          const key = handshake.media.key
          file.url = key
        } catch {}
      }
    }

    // 5. do insertions
    if (insertSections.length > 0) {
      if (isDevelopment) {
        console.info(`inserting sections (${insertSections.length})`)
      }

      const ids = insertSections.map((x) => x[0])
      const objects = insertSections.map((x) => x[1])

      const result = await client.mutate({
        mutation: ADD_COURSE_CURRICULUM_SECTIONS,
        variables: {
          objects: objects,
        },
        context: INSTRUCTOR_CONTEXT,
      })

      const array = result.data.insert_academy_course_sections.returning

      if (isDevelopment) {
        console.info(`api returned with ${array.length} new sections`)
      }

      for (let i = 0; i < ids.length; i++) {
        const container: IdContainer = ids[i]
        const item = array[i]

        container.newId = item.id
      }
    }

    if (insertLessons.length > 0) {
      if (isDevelopment) {
        console.info(`inserting lessons (${insertLessons.length})`)
      }

      const ids = insertLessons.map((x) => x[0])
      const objects = insertLessons.map((x) => x[1])

      const result = await client.mutate({
        mutation: ADD_COURSE_CURRICULUM_LESSONS,
        variables: {
          objects: objects,
        },
        context: INSTRUCTOR_CONTEXT,
      })

      const array = result.data.insert_academy_course_lessons.returning

      if (isDevelopment) {
        console.info(`api returned with ${array.length} new lessons`)
      }

      for (let i = 0; i < ids.length; i++) {
        const container: IdContainer = ids[i]
        const item = array[i]

        container.newId = item.id
      }
    }

    if (insertQuestions.length > 0) {
      if (isDevelopment) {
        console.info(`inserting questions (${insertQuestions.length})`)
      }

      const ids = insertQuestions.map((x) => x[0])
      const objects = insertQuestions.map((x) => x[1])

      const result = await client.mutate({
        mutation: ADD_COURSE_CURRICULUM_QUESTIONS,
        variables: {
          objects: objects,
        },
        context: INSTRUCTOR_CONTEXT,
      })

      const array = result.data.insert_academy_course_questions.returning

      if (isDevelopment) {
        console.info(`api returned with ${array.length} new questions`)
      }

      for (let i = 0; i < ids.length; i++) {
        const container: IdContainer = ids[i]
        const item = array[i]

        container.newId = item.id
      }
    }

    // 6. do updates
    if (updateSections.length > 0) {
      if (isDevelopment) {
        console.info(`updating sections (${updateSections.length})`)
      }

      for (const [sectionId, objects] of updateSections) {
        await client.mutate({
          mutation: UPDATE_COURSE_CURRICULUM_SECTION,
          variables: {
            sectionId: sectionId,
            objects: objects,
          },
          context: INSTRUCTOR_CONTEXT,
        })
      }
    }

    if (updateLessons.length > 0) {
      if (isDevelopment) {
        console.info(`updating lessons (${updateLessons.length})`)
      }

      for (const [lessonId, objects] of updateLessons) {
        await client.mutate({
          mutation: UPDATE_COURSE_CURRICULUM_LESSON,
          variables: {
            lessonId: lessonId,
            objects: objects,
          },
          context: INSTRUCTOR_CONTEXT,
        })
      }
    }

    if (updateQuestions.length > 0) {
      if (isDevelopment) {
        console.info(`updating questions (${updateQuestions.length})`)
      }

      for (const [questionId, objects] of updateQuestions) {
        await client.mutate({
          mutation: UPDATE_COURSE_CURRICULUM_QUESTION,
          variables: {
            questionId: questionId,
            objects: objects,
          },
          context: INSTRUCTOR_CONTEXT,
        })
      }
    }

    refetch()
  })

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      mutators={{...arrayMutators}}
      subscription={{pristine: true, submitting: true}}
    >
      {({handleSubmit, pristine, submitting, form}) => (
        <Paper component="form">
          <div className="flex items-center gap-2 px-6 py-1">
            <IconButton
              onClick={() => (pristine ? navigate(-1) : setOpenConfirm(true))}
              edge="start"
              color="primary"
              title="Go back to previous page"
            >
              <ArrowBackIcon />
            </IconButton>

            <Typography color="primary" className="grow font-bold">
              Curriculum
            </Typography>

            <Button
              onClick={() => handleSubmit()}
              disabled={pristine}
              color="primary"
              variant="contained"
              disableElevation
            >
              Save
            </Button>
          </div>

          <Divider />

          <div className="relative flex flex-col p-6">
            {(submitting || loading) && <FormLoading />}

            <DragDropContext
              onDragEnd={(result) => {
                const {type, source: src, destination: dst} = result
                const mutators = form.mutators as any as ArrayMutators

                // NOTE(intrnl): item is being dragged outside of any droppable
                // area, do a noop on this operation.
                if (!dst) {
                  return
                }

                if (type === 'section') {
                  mutators.move('academy_course_sections', src.index, dst.index)
                } else if (type === 'lesson' || type === 'quiz-questions') {
                  if (src.droppableId === dst.droppableId) {
                    mutators.move(src.droppableId, src.index, dst.index)
                  } else {
                    // TODO(intrnl): can src array be undefined?
                    const srcState = form.getFieldState(src.droppableId)!
                    const srcArray: any[] = srcState.value
                    const item = srcArray[src.index]

                    mutators.remove(src.droppableId, src.index)
                    mutators.insert(dst.droppableId, dst.index, item)
                  }
                }
              }}
            >
              <SectionList name="academy_course_sections" />
            </DragDropContext>
          </div>

          <ConfirmationModal
            open={openConfirm}
            title="Discard Changes?"
            message="Are you sure want to discard unsaved changes?"
            onClose={() => setOpenConfirm(false)}
            onConfirm={() => navigate(-1)}
          />
        </Paper>
      )}
    </Form>
  )
}

export default ContributorCourseManageCurriculumsPage
