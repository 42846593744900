import {gql} from '@apollo/react-hooks'

export const GET_MY_CERTIFICATE = gql`
  query GetMyCertificate(
    $userId: uuid!
    $search: String
    $limit: Int
    $level: [Int!]
    $category: [Int!]
    $language: [String!]
    $order_by: [academy_certificates_order_by!]
  ) {
    academy_certificates(
      limit: $limit
      order_by: $order_by
      where: {
        student: {_eq: $userId}
        course_name: {_ilike: $search}
        academy_course_enrollment: {
          academy_course: {
            _or: {
              academy_course_category: {id: {_in: $category}}
              level: {_in: $level}
              language: {_in: $language}
            }
          }
        }
        deletedAt: {_is_null: true}
      }
    ) {
      id
      certificate_no: code
      certificate_url: code
      name_to
      course
      course_name
      student
      instructors
      date_added
      last_modified
      academy_course_enrollment {
        id
        academy_course {
          id
          thumbnail
          academy_course_category {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_MY_CERTIFICATE_DETAIL_BY_ID = gql`
  query GetMyCertificateDetailById($id: Int!) {
    academy_certificates(where: {id: {_eq: $id}}) {
      id
      certificate_no: code
      certificate_url: code
      name_to
      course_name
      instructors
      academy_course_enrollment {
        id
        academy_course {
          id
          slug
          thumbnail
          academy_course_category {
            id
            name
          }
        }
      }
    }
  }
`
