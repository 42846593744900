import React from 'react'
import {
  Link as RouterLink,
  Outlet,
  useMatch,
  useResolvedPath,
} from 'react-router-dom'
import { useTitle } from 'hoofd'

import { Collapse, Divider, List } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import Header from '../../components/header/HeaderComponent'
import {
  StyledTab,
  StyledTabs,
  TabsWrapper,
} from '../../components/utils-components/VendorStyles'
import {
  SidebarMenu,
  SidebarItem,
  SidebarNavItem,
} from '../../components/contributor/SidebarMenu'
import ContributorDashboardHeader from '../../components/contributor/header/ContributorDashboardHeader'

const BookList = () => {
  const ebookPath = useResolvedPath('ebooks')
  const ebookMatch = useMatch({ path: ebookPath.pathname })

  const audiobookPath = useResolvedPath('audiobooks')
  const audiobookMatch = useMatch({ path: audiobookPath.pathname })

  const [isOpen, setIsOpen] = React.useState(!!ebookMatch || !!audiobookMatch)

  return (
    <>
      <SidebarItem button onClick={() => setIsOpen(!isOpen)}>
        <span className="grow">My Book</span>
        <ExpandMoreIcon className={`-mr-2 ${isOpen ? 'rotate-180' : ''}`} />
      </SidebarItem>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List className="flex flex-col gap-2" disablePadding>
          <SidebarNavItem to="ebooks" className="pl-10">
            E-Book
          </SidebarNavItem>
          <SidebarNavItem to="audiobooks" className="pl-10">
            Audiobook
          </SidebarNavItem>
        </List>
      </Collapse>
    </>
  )
}

const DashboardSidebar = () => {
  return (
    <SidebarMenu>
      {/* @ts-expect-error */}
      <SidebarItem button component={RouterLink} to="/">
        Catalog View
      </SidebarItem>
      <Divider />
      <SidebarNavItem to="." end>
        Dashboard
      </SidebarNavItem>
      <SidebarNavItem to="courses">My Course</SidebarNavItem>
      <SidebarNavItem to="events">My Event</SidebarNavItem>
      <BookList />
      <SidebarNavItem to="microlearnings">My Micro Learning</SidebarNavItem>
      {/* <SidebarNavItem to="orders">Daftar Pesanan</SidebarNavItem> */}
      {/* <SidebarNavItem to="earnings">Earnings</SidebarNavItem> */}
      <SidebarNavItem to="learner">Learner</SidebarNavItem>
      {/* <SidebarNavItem to="bankaccount">Bank Account</SidebarNavItem> */}
    </SidebarMenu>
  )
}

const ContribDashboardPageLayout = () => {
  useTitle('Contributor')

  return (
    <div className="bg-[#fafafa]">
      <Header />

      <TabsWrapper>
        <StyledTabs value={'dashboard'} centered className="bg-[#f6f8f9]">
          <StyledTab
            label="Dasbor Instruktur"
            value="dashboard"
            className="font-bold"
          />
        </StyledTabs>
      </TabsWrapper>

      <div className="mx-auto box-content grid max-w-screen-xl gap-8 px-4 py-4 md:px-20 md:py-8">
        <ContributorDashboardHeader />

        <div className="grid gap-8 lg:grid-cols-[320px_minmax(0,_1fr)]">
          <div>
            <DashboardSidebar />
          </div>
          <div>
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContribDashboardPageLayout
