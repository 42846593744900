import {gql} from '@apollo/client'

// doc: https://github.com/worklifebeyond/wlb-api-documentation/blob/eca6bb4432293b2cbc761ce0632d2595f6b7a274/learning/GET%20COMPETENCIES%20ADDED%20BY%20USER.md
export const LIST_COMPETENCY_ADDED_BY_USER = gql`
  query getAddedByUser(
    $userId: uuid!
    $limit: Int
    $offset: Int
    $query: String
  ) {
    learning_diary_competencies(
      where: {user_id: {_eq: $userId}, name: {_ilike: $query}}
      limit: $limit
      offset: $offset
    ) {
      id
      name
      description
      date_added
      count_diary: learning_diaries_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`

export const COMPETENCY_ADDED_BY_USER = gql`
  query ($id: uuid!) {
    learning_diary_competencies(where: {id: {_eq: $id}}) {
      id
      name
      description
      date_added
    }
  }
`
