import React from 'react'

import {IconButton} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import EditIcon from '@material-ui/icons/Edit'

export interface LocalVideoPreviewProps {
  src: string
  onChange?: () => void
}

const LocalVideoPreview = (props: LocalVideoPreviewProps) => {
  const {src, onChange} = props

  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = React.useState(false)

  const handlePause = () => {
    const video = videoRef.current!

    video.play()
    video.focus()
    setIsPlaying(true)
  }

  return (
    <div className="rounded overflow-hidden relative">
      <video
        ref={videoRef}
        src={src}
        className="h-full w-full"
        controls={isPlaying}
        onPause={() => setIsPlaying(false)}
      />

      {!isPlaying && (
        <div className="group flex items-center justify-center gap-4 absolute inset-0 rounded-none transition-colors bg-black bg-opacity-0 hover:bg-opacity-50 focus-within:bg-opacity-50">
          <IconButton
            title="Play video"
            onClick={handlePause}
            className="text-white invisible group-hover:visible group-focus-within:visible"
          >
            <PlayArrowIcon />
          </IconButton>

          <IconButton
            title="Replace video"
            onClick={onChange}
            className="text-white invisible group-hover:visible group-focus-within:visible"
          >
            <EditIcon />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default LocalVideoPreview
