import React from 'react'
import {Typography, Avatar, Icon} from '@material-ui/core'
import {ClassNameMap} from '@material-ui/styles'
import classNames from 'classnames'
import {
  ICourseEnrollment,
  ICourseCommentResponse,
} from '../../../../../interfaces/Course.interface'
import {
  ADD_RESPONSE_HELPFUL,
  REMOVE_RESPONSE_HELPFUL,
} from '../../../../../provider/services/Course.service'
import Skeleton from 'react-loading-skeleton'
import {formatDistance} from 'date-fns'
import {USER_ID} from '../../../../../utils/globals'

import {strMaxLength} from '../../../../../utils/helpers'

type ResponseProps = {
  response: ICourseCommentResponse
  enrollment: ICourseEnrollment
  classes: ClassNameMap
  refetchComment: () => void
}

const TabCommentResponse = (props: ResponseProps) => {
  const {response, enrollment, classes, refetchComment} = props
  const [helpfulId, setHelpfulId] = React.useState<number>(0)

  const {
    mutate: onSubmitResponseHelpful,
    isSuccess: isSuccessResponseHelpful,
    isLoading: isLoadingResponseHelpful,
  } = ADD_RESPONSE_HELPFUL(response.discussionId, response.id)

  const {
    mutate: onSubmitRemoveResponseHelpful,
    isSuccess: isSuccessRemoveResponseHelpful,
    isLoading: isLoadingRemoveResponseHelpful,
  } = REMOVE_RESPONSE_HELPFUL(response.discussionId, response.id, helpfulId)

  React.useEffect(() => {
    if (helpfulId) {
      onSubmitRemoveResponseHelpful({})
    }
  }, [helpfulId, onSubmitRemoveResponseHelpful])

  React.useEffect(() => {
    if (isSuccessResponseHelpful || isSuccessRemoveResponseHelpful) {
      refetchComment()
    }
  }, [isSuccessResponseHelpful, isSuccessRemoveResponseHelpful, refetchComment])

  return (
    <div className="pl-20 flex flex-row" key={response.id}>
      <Avatar style={{width: 64, height: 64}} src={response.Student.avatar} />
      <div className="ml-6 w-full flex flex-col">
        <div className="flex flex-row justify-between">
          <Typography className="font-bold">
            {strMaxLength(response.Student.name, 50)}
          </Typography>

          <Typography
            color="textSecondary"
            variant="caption"
            className="font-light"
          >
            {formatDistance(new Date(response.dateAdded), new Date(), {
              addSuffix: true,
            })}
          </Typography>
        </div>
        {response.Student?.PeopleWorkPlacement?.length > 0 && (
          <Typography color="textSecondary" variant="caption" className="mb-2">
            {
              response.Student.PeopleWorkPlacement[
                response.Student.PeopleWorkPlacement.length - 1
              ].CompanyJobProfile?.title
            }{' '}
            at{' '}
            {
              response.Student.PeopleWorkPlacement[
                response.Student.PeopleWorkPlacement.length - 1
              ].CompanyProfile?.legal_name
            }
          </Typography>
        )}
        <Typography className="text-gray-500">{response.fulltext}</Typography>

        {isLoadingResponseHelpful || isLoadingRemoveResponseHelpful ? (
          <Skeleton height={50} width={650} />
        ) : (
          <>
            <div className="flex flex-row my-2">
              <Typography color="textSecondary" variant="caption">
                Suka({response.countHelpful || 0})
              </Typography>
            </div>
            <div
              className={`flex flex-row ${enrollment ? 'visible' : 'hidden'}`}
            >
              <Icon
                className={classNames(
                  !response.isMarkedHelpfulByMe
                    ? classes.greyColor
                    : classes.redColor,
                  'cursor-pointer'
                )}
                onClick={() => {
                  if (!response.isMarkedHelpfulByMe) {
                    onSubmitResponseHelpful({
                      lessonId: response.lessonId,
                      userId: USER_ID,
                    })
                  } else {
                    setHelpfulId(response.helpfulId)
                  }
                }}
              >
                {!response.isMarkedHelpfulByMe ? 'favorite_border' : 'favorite'}
              </Icon>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default TabCommentResponse
