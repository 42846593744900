import React from 'react'

import {
  MY_COURSE,
  LIST_COURSE_TRENDINGS,
  LIST_PUBLIC_COURSES,
} from './services/Course.service'

import {CircularProgress} from '@material-ui/core'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  initialValue,
} from './reducers/Learner.reducer'

import {LearnerCourseContextType, IEnrolledCourse} from '../interfaces'

import {ICourse} from '../interfaces/Course.interface'

type LearnerCourseProviderProps = {
  children: React.ReactNode
}

export const LearnerCourseContext =
  React.createContext<LearnerCourseContextType>(initialValue)

export const LearnerCourseProvider = (props: LearnerCourseProviderProps) => {
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )

  const [publicCourses, setPublicCourses] = React.useState<ICourse[]>([])

  const {data: dataListMyCourse, isLoading: loadingListMyCourse} = MY_COURSE()

  const {
    data: dataListTrendingCourse,
    loading: loadingListTrendingCourse,
    refetch: refetchListTrendingCourse,
  } = LIST_COURSE_TRENDINGS(
    state.course.limitTrendingCourse,
    state.course.offsetTrendingCourse,
    'trending'
  )

  const {
    data: dataListRecommendedCourse,
    loading: loadingListRecommendedCourse,
    refetch: refetchListRecommendedCourse,
  } = LIST_COURSE_TRENDINGS(
    state.course.limitRecommendedCourse,
    state.course.offsetRecommendedCourse,
    'recommended'
  )

  const {data: dataPublicCourses, loading: loadingPublicCourse} =
    LIST_PUBLIC_COURSES(
      state.course.limitAllCourse,
      state.course.offsetAllCourse
    )

  React.useEffect(() => {
    if (dataPublicCourses) {
      const _data = publicCourses

      dataPublicCourses.academy_courses.map((item: ICourse) => _data.push(item))

      setPublicCourses(_data)
    }
  }, [dataPublicCourses, publicCourses])

  React.useEffect(() => {
    if (refetchListRecommendedCourse) refetchListRecommendedCourse()
  }, [state.course.offsetRecommendedCourse, refetchListRecommendedCourse])

  React.useEffect(() => {
    if (refetchListTrendingCourse) refetchListTrendingCourse()
  }, [state.course.offsetTrendingCourse, refetchListTrendingCourse])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  if (loadingListMyCourse) {
    return <CircularProgress />
  }

  const allCourses = {
    loading: loadingPublicCourse,
    total: dataPublicCourses?.academy_courses_aggregate.aggregate.count || 0,
    data: publicCourses,
  }

  const courseTrending = {
    total: dataListTrendingCourse?.getListCourses?.count,
    data: dataListTrendingCourse?.getListCourses?.courses.map(
      (item: ICourse) => ({
        ...item,
        Price: {
          ...item.Price,
          pricing: {IDR: String(item.Price.pricing_idr), USD: ''},
        },
      })
    ),
    loading: loadingListTrendingCourse,
  }

  const courseRecommended = {
    total: dataListRecommendedCourse?.getListCourses?.count,
    data: dataListRecommendedCourse?.getListCourses?.courses.map(
      (item: ICourse) => ({
        ...item,
        Price: {
          ...item.Price,
          pricing: {IDR: String(item.Price.pricing_idr), USD: ''},
        },
      })
    ),
    loading: loadingListRecommendedCourse,
  }

  return (
    <LearnerCourseContext.Provider
      value={{
        trendings: courseTrending,

        courseRecommended: courseRecommended,

        courseAll: allCourses,
        changePageLimit: changePageLimitHandler,

        myCourse: dataListMyCourse
          ? {
              total: dataListMyCourse.length,

              pages: Math.ceil(
                dataListMyCourse.length / state.course.limitMyCourse
              ),
              data: dataListMyCourse.map((item: IEnrolledCourse) => item),
            }
          : initialValue.myCourse,

        courseRecommendationCategories:
          // dataListRecommendationCourseCategories ||
          initialValue.courseRecommendationCategories,

        reducerState: state,

        loadingListAllCourses: false,
      }}
    >
      {props.children}
    </LearnerCourseContext.Provider>
  )
}
