import {type ChangeEvent, type ReactNode} from 'react'
import {useField, type UseFieldConfig} from 'react-final-form'

import {
  Typography,
  FormControl,
  FormHelperText,
  OutlinedInput,
  type FormControlProps,
  type OutlinedInputProps,
} from '@material-ui/core'

import {showErrorOnBlur, type ShowErrorFn} from './validators'

export interface FormTextProps {
  label?: string
  name: string
  initialValue?: string
  fullWidth?: boolean
  disabled?: boolean
  helperText?: ReactNode
  type?: string
  placeholder?: string
  className?: string
  showCharLimit?: number
  validate?: UseFieldConfig<any>['validate']
  format?: UseFieldConfig<any>['format']
  parse?: UseFieldConfig<any>['parse']
  autoFocus?: boolean
  multiline?: boolean
  minRows?: number
  onChange?: (ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  showError?: ShowErrorFn
  margin?: 'dense' | 'none'
  formControlProps?: FormControlProps
  inputProps?: OutlinedInputProps
}

const FormText = (props: FormTextProps) => {
  const {
    label,
    name,
    initialValue,
    fullWidth,
    disabled,
    helperText,
    placeholder,
    type = 'text',
    className = '',
    showCharLimit,
    validate,
    format,
    parse,
    autoFocus,
    multiline,
    minRows,
    onChange,
    showError = showErrorOnBlur,
    margin,
    formControlProps,
    inputProps,
  } = props

  const id = `formText-${name}`

  const {input, meta} = useField(name, {
    validate,
    format,
    parse,
    initialValue,
  })

  const {error, submitError} = meta
  const isError = showError(meta)

  const errorLabel = isError ? (
    <FormHelperText error>{error || submitError}</FormHelperText>
  ) : helperText ? (
    <FormHelperText>{helperText}</FormHelperText>
  ) : null

  return (
    <FormControl fullWidth={fullWidth} {...formControlProps}>
      {label && (
        <Typography
          component="label"
          htmlFor={id}
          color={isError ? 'error' : 'textSecondary'}
          className="block text-sm"
          gutterBottom
        >
          {label}
        </Typography>
      )}

      <OutlinedInput
        id={id}
        className={`text-sm bg-white ${className}`}
        type={type}
        readOnly={meta.submitting}
        disabled={disabled}
        error={isError}
        autoFocus={autoFocus}
        multiline={multiline}
        minRows={minRows}
        placeholder={placeholder}
        margin={margin}
        {...input}
        onChange={(ev) => {
          if (onChange) {
            onChange(ev)
          }

          if (!onChange || !ev.defaultPrevented) {
            input.onChange(ev)
          }
        }}
        {...inputProps}
      />

      {showCharLimit !== undefined ? (
        <div className="flex justify-between">
          {errorLabel || <span></span>}

          <FormHelperText error={input.value.length > showCharLimit}>
            {input.value.length}/{showCharLimit}
          </FormHelperText>
        </div>
      ) : (
        errorLabel
      )}
    </FormControl>
  )
}

export default FormText
