import React, {ChangeEvent} from 'react'
import {Card, Avatar, Icon, Grid, Button, makeStyles} from '@material-ui/core'
import {
  CustomFlex,
  CustomTypography,
  TextBlue,
} from '../../../utils-components/GlobalStyles'
import {
  CHAT_URL,
  CHAT_HOME_SERVER,
  SOSMED_APP_URL,
} from '../../../../utils/globals'
import {useNavigate} from 'react-router-dom'
import classNames from 'classnames'
import {CONTRIBUTOR_LABEL} from '../../../../utils/constans'

type ContributorCardProps = {
  item?: any
  isLearningCatalogChat?: boolean
}

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.16);',
    cursor: 'pointer',
  },
  contributorAvatar: {
    width: 60,
    height: 60,
  },
  contributorCard: {
    height: 260,
    padding: 20,
    gap: 10,
  },
  contributorLabel: {
    backgroundColor: '#014a62',
    padding: '5px 16px',
    borderRadius: '2px',
    color: '#fff',
    fontSize: 12,
  },
  contributorIcon: {
    color: '#039be5',
    fontSize: 14,
    marginRight: 10,
  },
  contributorBanner: {
    width: '100%',
    height: '50px',
    borderRadius: '4px',
  },
  ellipsisOneLine: {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}))

const ContributorCard = ({
  item,
  isLearningCatalogChat,
}: ContributorCardProps): React.ReactElement => {
  const classes = useStyles()
  const navigate = useNavigate()

  const handleClick = (e: ChangeEvent<{}>) => {
    e.stopPropagation()
    window.open(
      `${SOSMED_APP_URL}/profile/${item.global_user.id}/detail`,
      '_blank'
    )
  }

  const _avatar = item?.global_user?.avatar

  const _name = item?.global_user?.name?.split(' ')?.slice(0, 4)?.join(' ')

  return (
    <div className={isLearningCatalogChat ? 'w-full' : 'w-full md:w-1/2'}>
      <Card
        className={classNames(
          classes.paper,
          !isLearningCatalogChat && 'mx-3 mt-3'
        )}
        onClick={() => {
          if (item) {
            navigate(
              `/learner/learning_contributor/${item?.global_user?.id}/course`
            )
          }
        }}
      >
        <CustomFlex
          direction="column"
          justify="space-between"
          className={classes.contributorCard}
        >
          <CustomFlex justify="space-between" style={{width: '100%'}}>
            <CustomFlex>
              <Avatar
                src={_avatar}
                style={{marginRight: '10px'}}
                className={classes.contributorAvatar}
              />

              <CustomFlex direction="column">
                <CustomTypography
                  fweight="600"
                  fsize="14px"
                  className={classNames(classes.ellipsisOneLine, 'pr-2')}
                >
                  {_name}
                </CustomTypography>

                {item?.global_user?.people_work_placements.length > 0 && (
                  <CustomTypography
                    color="textSecondary"
                    className={classes.ellipsisOneLine}
                  >
                    {
                      item?.global_user?.people_work_placements[0]
                        ?.company_job_profile?.title
                    }{' '}
                    at{' '}
                    {
                      item?.global_user?.people_work_placements[0]
                        ?.company_profile?.brand_name
                    }
                  </CustomTypography>
                )}
                {item && (
                  <TextBlue
                    className="text-sm font-extrabold mt-2"
                    onClick={(e: ChangeEvent<{}>) => handleClick(e)}
                  >
                    Lihat Profil
                  </TextBlue>
                )}
              </CustomFlex>
            </CustomFlex>

            {item?.global_user?.contributor_label && (
              <div className={classNames(classes.contributorLabel)}>
                {CONTRIBUTOR_LABEL?.[item?.global_user?.contributor_label] ||
                  item?.global_user?.contributor_label}
              </div>
            )}
          </CustomFlex>

          <CustomFlex direction="column">
            <CustomFlex
              alignItems="center"
              alignContent="center"
              justify="center"
              className="mb-1"
            >
              <Icon className={classes.contributorIcon}>star</Icon>
              <CustomTypography fcolor="#a9a8a8" fsize="10px">
                {item?.total_rating_avg?.toFixed(1) || 0} Penilaian
                {!isLearningCatalogChat && (
                  <span className="ml-1">
                    ({item?.total_rating_count || 0} Ulasan)
                  </span>
                )}
              </CustomTypography>
            </CustomFlex>
            {isLearningCatalogChat && (
              <CustomFlex alignItems="center" className="mb-1">
                <Icon className={classes.contributorIcon}>chat_bubble</Icon>
                <CustomTypography fcolor="#a9a8a8" fsize="10px">
                  {item?.total_rating_count || 0} Ulasan
                </CustomTypography>
              </CustomFlex>
            )}

            <CustomFlex alignItems="center" className="mb-1">
              <Icon className={classes.contributorIcon}>people</Icon>
              <CustomTypography fcolor="#a9a8a8" fsize="10px">
                {item?.total_learner_count || 0} Pelajar
              </CustomTypography>
            </CustomFlex>
            <CustomFlex alignItems="center" className="mb-1">
              <Icon className={classes.contributorIcon}>book</Icon>
              <CustomTypography fcolor="#a9a8a8" fsize="10px">
                {item?.total_catalog_count || 0} Katalog
              </CustomTypography>
            </CustomFlex>
          </CustomFlex>

          {isLearningCatalogChat ? (
            <div className="flex flex-row justify-between w-full">
              <Button
                variant="contained"
                color="primary"
                className="w-10/12"
                onClick={(e: ChangeEvent<{}>) => {
                  if (item) {
                    e.stopPropagation()
                    navigate(
                      `/learner/learning_contributor/${item?.global_user?.id}/course`
                    )
                  }
                }}
              >
                Lihat Katalog Pembelajaran
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className="ml-2"
                onClick={(e: ChangeEvent<{}>) => {
                  if (item) {
                    e.stopPropagation()
                    window.open(
                      `${CHAT_URL}?chat=@${item?.global_user?.id}:${CHAT_HOME_SERVER}`
                    )
                  }
                }}
              >
                <Icon>forward_to_inbox</Icon>
              </Button>
            </div>
          ) : (
            <Grid container spacing={3}>
              {item?.global_user?.academy_courses
                ?.slice(0, 4)
                .map((val: any, index: number) => {
                  return (
                    <Grid item xs={3} key={`${val.id}-${val}-${index}`}>
                      <Avatar
                        src={val.thumbnail}
                        variant="square"
                        className={classes.contributorBanner}
                      />
                    </Grid>
                  )
                })}
            </Grid>
          )}
        </CustomFlex>
      </Card>
    </div>
  )
}

export default ContributorCard
