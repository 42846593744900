import React from 'react'
import {Field} from 'react-final-form'

import DOMPurify from 'dompurify'

import {Link} from '@material-ui/core'

import {type LessonViewProps} from './_Lesson'

import {
  objectMapping,
  AttachmentType,
} from '../../../../../../../components/contributor/forms/AttachmentField'

const enum ReadState {
  INITIAL,
  HIDDEN,
  VISIBLE,
}

export const LocalArticleView = (props: {value: string}) => {
  const {value} = props

  const [state, setState] = React.useState(ReadState.INITIAL)
  const ref = React.useRef<HTMLDivElement>(null)

  const sanitized = React.useMemo(() => {
    return DOMPurify.sanitize(value)
  }, [value])

  const isHidden = state === ReadState.HIDDEN

  React.useLayoutEffect(() => {
    const node = ref.current!

    if ((node as any).$text === sanitized) {
      return
    }

    if (state !== ReadState.INITIAL) {
      setState(ReadState.INITIAL)
      return
    }

    ;(node as any).$text = sanitized
    node.innerHTML = sanitized

    if (node.scrollHeight > node.clientHeight) {
      setState(ReadState.HIDDEN)
    }
  }, [state, sanitized])

  return (
    <>
      <div
        ref={ref}
        className="ck ck-content ck-preview"
        style={{
          display: state !== ReadState.VISIBLE ? '-webkit-box' : undefined,
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: state !== ReadState.VISIBLE ? 6 : undefined,
          overflow: 'hidden',
        }}
      />

      {state >= ReadState.HIDDEN && (
        <Link
          component="button"
          type="button"
          onClick={() => {
            setState(isHidden ? ReadState.VISIBLE : ReadState.HIDDEN)
          }}
          color="secondary"
          className="block mt-2"
        >
          {isHidden ? 'See more' : 'See less'}
        </Link>
      )}
    </>
  )
}

export const RemoteArticleView = (props: {
  src: AttachmentType
  height?: number | string
}) => {
  const {src, height = 512} = props

  const url = src instanceof File ? objectMapping.get(src) : src

  return <iframe src={url} style={{width: '100%', height}} />
}

const ArticleLessonView = (props: LessonViewProps) => {
  const {name} = props

  return (
    <div className="p-6 pl-18 text-sm">
      <Field
        name={`${name}.summary`}
        subscription={{value: true}}
        format={(v) => v}
        allowNull
      >
        {({input: {value}}) => {
          // NOTE(intrnl): attachments view
          if (value == null) {
            return (
              <Field name={`${name}.resources`} subscription={{value: true}}>
                {({input: {value}}) => {
                  const file = value[0]
                  const src = typeof file === 'string' ? file : file.url

                  return <RemoteArticleView src={src} />
                }}
              </Field>
            )
          }

          return <LocalArticleView value={value} />
        }}
      </Field>
    </div>
  )
}

export default ArticleLessonView
