import {Button, Typography} from '@material-ui/core'
import {Favorite, FavoriteBorder, Share} from '@material-ui/icons'
import React, {FC, ReactElement, useEffect, useState} from 'react'
import {IMicroTitle} from '../../../interfaces'
import DisplayRatingStar from '../../review-rating/DisplayRatingStar.component'
import ShareCatalogItemDialog from '../catalog/dialog/ShareCatalogItemDialog.component'
import DEFAULT_IMG_MICROLEARNING from '../../../../src/assets/images/defaultMicrolearningThumbnail.image.png'
import useStyles from './MicroLearning.style'
import SettingMicroDialog from './SettingMicroDialog.component'
import {useParams} from 'react-router-dom'

const MicroLearningTitle: FC<IMicroTitle> = (
  props: IMicroTitle
): ReactElement => {
  const {
    name,
    microImage,
    autor,
    averageRating,
    countReviews,
    wishlist,
    isOwned,
    dailyQuestion,
    schedule,
    scheduleTime,
    allowSetting,
    settingMicroLearning,
    addtoWishlist,
    removeFromWishlist,
  } = props
  const classes = useStyles()
  const {setting} = useParams()
  const [isListed, setIsListed] = useState<boolean>(Boolean(wishlist))
  const [openSetting, setOpenSetting] = useState<boolean>(false)
  const [shareData, setShareData] = React.useState({
    isOpen: false,
    copyStatus: false,
  })

  const isScheduled = schedule !== '0000000'

  const wishlistToggle = (): void => {
    try {
      if (isListed) removeFromWishlist()
      else addtoWishlist()
      setIsListed(!isListed)
    } catch (error) {
      console.error(`Error: ${error}`)
    }
  }
  useEffect(() => {
    setIsListed(Boolean(wishlist))
    if (setting) {
      setOpenSetting(true)
    }
  }, [wishlist, setting])

  const dataSetting = {
    open: openSetting,
    onClose: setOpenSetting,
    onSave: settingMicroLearning,
    dataSetting: {
      dailyQuestion: dailyQuestion,
      schedule: schedule,
      scheduleTime: scheduleTime,
    },
  }

  const shareableURL =
    window.location.protocol +
    '//' +
    window.location.host +
    window.location.pathname

  return (
    <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row">
      <img
        className="w-full max-h-72 lg:max-w-[15rem] xl:max-w-[26rem] object-cover"
        src={microImage || DEFAULT_IMG_MICROLEARNING}
        alt={name}
      />

      <div className="flex flex-col h-72 mt-6 lg:mt-0 lg:mx-7">
        <Typography
          variant="h5"
          color="primary"
          className={classes.titleMicroLearning}
        >
          {name}
        </Typography>

        <Typography
          variant="subtitle1"
          color="textSecondary"
          className={classes.autorMicroLearning}
        >
          Oleh {autor || <i>unknown</i>}
        </Typography>

        <div className="flex flex-row w-96 mt-4">
          <DisplayRatingStar count={averageRating || 0} starSize="medium" />
          <Typography className="ml-3 font-extrabold text-base text-gray-700">
            {`${averageRating?.toFixed(1)}`}
          </Typography>
          <Typography className="ml-1 font-extrabold text-base text-gray-400">
            {`(${countReviews})`}
          </Typography>
        </div>

        <div className="flex space-x-4 mt-6">
          {wishlist !== undefined && (
            <div className="flex cursor-pointer" onClick={wishlistToggle}>
              {isListed ? (
                <Favorite color="primary" />
              ) : (
                <FavoriteBorder color="primary" />
              )}

              <Typography
                color="primary"
                className="ml-3 font-extrabold text-base"
              >
                {isListed && 'Hapus dari'} Daftar Keinginan
              </Typography>
            </div>
          )}

          <div
            className="flex cursor-pointer"
            onClick={() => setShareData({...shareData, isOpen: true})}
          >
            <Share color="primary" />
            <Typography
              color="primary"
              className="ml-3 font-extrabold text-base"
            >
              <b>Bagikan</b>
            </Typography>
          </div>
        </div>
        {isOwned && allowSetting && (
          <Button
            variant="contained"
            color="primary"
            className="w-full mt-auto lg:w-1/2"
            onClick={() => setOpenSetting(true)}
          >
            {isScheduled
              ? 'Atur Pembelajaran Mikro'
              : 'Mulai Pembelajaran Mikro'}
          </Button>
        )}
      </div>

      {shareData?.isOpen && (
        <ShareCatalogItemDialog
          shareData={shareData}
          setShareData={setShareData}
          shareableUrl={shareableURL}
          title={name || ''}
          featureName="Pembelajaran Mikro"
        />
      )}

      {openSetting && <SettingMicroDialog {...dataSetting} />}
    </div>
  )
}

export default MicroLearningTitle
