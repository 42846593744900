import {ChangeEvent, useState, useEffect} from 'react'
import {Button, CircularProgress} from '@material-ui/core'
import {ArrowBack} from '@material-ui/icons'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {
  ADD_USER_COMPETENCY,
  UPDATE_USER_COMPETENCY,
} from '../../../graphql/mutations'
import {COMPETENCY_ADDED_BY_USER} from '../../../graphql/queries'
import {useMutation, useQuery} from '@apollo/client'
import {USER_ID, COMPANY_ID} from '../../../utils/globals'

const LearningAddNewCompetence = () => {
  const navigate = useNavigate()
  const {id} = useParams()

  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  const {data, loading} = useQuery(COMPETENCY_ADDED_BY_USER, {
    variables: {
      id,
    },
    skip: !id,
  })

  const [addMutation] = useMutation(ADD_USER_COMPETENCY)
  const [updateMutation] = useMutation(UPDATE_USER_COMPETENCY)

  useEffect(() => {
    if (id && data) {
      if (data.learning_diary_competencies.length > 0) {
        setName(data.learning_diary_competencies[0].name)
        setDescription(data.learning_diary_competencies[0].description)
      }
    }
  }, [data, id])

  const handleSubmit = () => {
    setIsLoading(true)
    if (id) {
      updateMutation({
        variables: {
          id,
          name,
          description,
        },
      }).then(() => {
        navigate('/profile/learning-summary', {state: {activeTab: 1}})
      })
    } else {
      addMutation({
        variables: {
          competency: {
            name,
            user_id: USER_ID,
            company_id: COMPANY_ID,
            description,
          },
        },
      }).then(() => {
        navigate('/profile/learning-summary', {state: {activeTab: 1}})
      })
    }
    setIsLoading(false)
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6">
        <Button
          component={Link}
          to="/profile/learning-summary"
          color="primary"
          startIcon={<ArrowBack style={{fontSize: '20px'}} />}
          style={{fontSize: '16px', fontWeight: 'bolder'}}
        >
          {id ? 'Ubah' : 'Tambah'} Kompetensi Baru
        </Button>
      </div>
      <hr />

      <form>
        <div className="flex flex-col pl-5 lg:pr-28 pr-4 mt-7 mb-24">
          <label className="text-[#a9a8a8] text-sm mb-3" htmlFor="name">
            Nama Kompetensi
          </label>
          <input
            type="text"
            id="name"
            placeholder="Tambah Nama Kompetensi"
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            value={name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setName(e.target.value)
            }
            disabled={loading || isLoading}
          />
          <label
            className="text-[#a9a8a8] text-sm mb-3 mt-6"
            htmlFor="description"
          >
            Keterangan
          </label>
          <textarea
            className="p-5 text-sm rounded-md border border-[#e5e5e5]"
            rows={8}
            placeholder="Tambah Keterangan"
            name="description"
            id="description"
            value={description}
            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
              setDescription(e.target.value)
            }
            disabled={loading || isLoading}
          />
        </div>
        <hr />
        <div className="flex justify-end items-center py-7 pr-8 gap-2">
          {isLoading ? (
            <CircularProgress />
          ) : (
            <>
              <button
                onClick={() => navigate('/profile/learning-summary')}
                className="py-3 px-7 bg-transparent text-sm text-[#a9a8a8] rounded-md"
              >
                Batalkan
              </button>
              <Button
                onClick={() => handleSubmit()}
                className="py-3 px-7"
                color="primary"
                variant="contained"
                disabled={!name || !description}
              >
                Kirim
              </Button>
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default LearningAddNewCompetence
