import { Button, Avatar, Tooltip, Grid } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import AvatarGroup from '@material-ui/lab/AvatarGroup'
import Skeleton from '@material-ui/lab/Skeleton'
import { Link, useParams } from 'react-router-dom'
import { CustomTypography } from '../../../components/utils-components/GlobalStyles'
import { DETAIL_LEARNING_DIARY } from '../../../provider/services/LearningProfile.service'
import {
  LearningDiaryParticipant,
  LearningDiaryFiles,
} from '../../../interfaces/LearningDiary.interface'
import { format, parseISO } from 'date-fns'
import { timeConvert } from '../../../utils/helpers'
import { AttachmentCard } from '@smartasn/wlb-utils-components'
// import MoreAttachmentCard from '../../../components/utils-components/MoreAttachment'

const LearningDiaryDetailPage = () => {
  const { id } = useParams()

  const { data, loading } = DETAIL_LEARNING_DIARY(id || '')

  const LoadingComponent = () => {
    return (
      <>
        {[...new Array(3)].map((item: number) => (
          <>
            <Skeleton variant="text" width={'40%'} key={item} />
          </>
        ))}
        <Skeleton variant="rect" height={118} className="mt-4" />
      </>
    )
  }

  return (
    <div className="shadow-xl bg-white">
      <div className="p-6 ">
        <Button
          component={Link}
          to="/profile/learning-diary"
          color="primary"
          startIcon={<ArrowBack style={{ fontSize: '20px' }} />}
          style={{ fontSize: '16px' }}
        >
          Diary Detail
        </Button>
      </div>
      <hr />
      <div className="p-6 ">
        {loading ? (
          <LoadingComponent />
        ) : (
          <>
            <CustomTypography fcolor="#014a62" fsize="18px" fweight="bold">
              {data?.learning_diaries[0]?.subject}
            </CustomTypography>
            <CustomTypography fcolor="#039be5" fsize="12px" mt="6px" mb="6px">
              {data?.learning_diaries[0]?.learning_diary_activity?.activity}{' '}
              {(data?.learning_diaries[0]?.competency_dictionary ||
                data?.learning_diaries[0]?.learning_diary_competency?.name) &&
                '-'}{' '}
              {data?.learning_diaries[0]?.competency_dictionary?.name?.en ||
                data?.learning_diaries[0]?.learning_diary_competency?.name ||
                ''}
            </CustomTypography>
            <CustomTypography fcolor="#a9a8a8" fsize="12px">
              {data?.learning_diaries[0]?.date
                ? `${format(
                  parseISO(data?.learning_diaries[0]?.date || ''),
                  'MMMM dd, yyyy'
                )} `
                : ''}
              {data?.learning_diaries[0]?.date &&
                data?.learning_diaries[0]?.duration &&
                '-'}
              {data?.learning_diaries[0]?.duration
                ? timeConvert(data?.learning_diaries[0]?.duration || 0)
                : ''}
            </CustomTypography>
            <CustomTypography mt="25px" mb="26px" fsize="14px">
              {data?.learning_diaries[0]?.description}
            </CustomTypography>
            {data?.learning_diaries[0]?.learning_diary_participants && (
              <div>
                <CustomTypography fcolor="#a9a8a8" mb="15px" fsize="14px">
                  Participant
                </CustomTypography>
                <AvatarGroup max={5}>
                  {data?.learning_diaries[0]?.learning_diary_participants.map(
                    (participant: LearningDiaryParticipant, i: number) => {
                      return (
                        <Tooltip title={participant.global_user.name} key={i}>
                          <Avatar
                            alt={participant.global_user.name}
                            src={participant.global_user.avatar}
                            style={{ zIndex: 0, marginRight: 10 }}
                          />
                        </Tooltip>
                      )
                    }
                  )}
                </AvatarGroup>
              </div>
            )}

            {data?.learning_diaries[0]?.learning_diary_files && (
              <div
                className={
                  data?.learning_diaries[0].learning_diary_files.length > 0
                    ? 'visible'
                    : 'hidden'
                }
              >
                <CustomTypography
                  fcolor="#a9a8a8"
                  mt="15px"
                  mb="10px"
                  fsize="14px"
                >
                  Attachment
                </CustomTypography>
                <Grid container>
                  {data?.learning_diaries[0].learning_diary_files.map(
                    (file: LearningDiaryFiles, idx: number) => (
                      <Grid item md={2} key={idx}>
                        <AttachmentCard
                          name={''}
                          url={file.file}
                          style={{ width: '80px', height: '80px' }}
                        />
                      </Grid>
                    )
                  )}
                </Grid>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default LearningDiaryDetailPage
