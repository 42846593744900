import React, { type ChangeEvent } from 'react'
import { Link as RouterLink, useSearchParams } from 'react-router-dom'
import { useTitle } from 'hoofd'
import { useQuery } from '@apollo/client'

import { format } from 'date-fns'

import {
  Button,
  Divider,
  InputAdornment,
  LinearProgress,
  ListItem,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  type ListItemProps,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import StarIcon from '@material-ui/icons/Star'

import { Filter } from '@smartasn/wlb-utils-components'
import Skeleton from 'react-loading-skeleton'
import LinesEllipsis from 'react-lines-ellipsis/lib/loose'

import NoListComponent from '../../../components/empty-state/NoList.component'
import { Pagination } from '../../../components/contributor/Pagination'

import { convertToRupiah, mapFilterResults } from '../../../utils/helpers'
import { INSTRUCTOR_CONTEXT } from '../../../utils/contributor-helpers'
import { USER_ID } from '../../../utils/globals'
import { GET_CONTRIBUTOR_MY_COURSES } from '../../../graphql/queries'

const enum CourseSortOrder {
  ID_DESC = 'desc',
  ID_ASC = 'asc',
  RATING_DESC = 'desc_rating',
  RATING_ASC = 'asc_rating',
  ENROLLS_DESC = 'desc_enrolls',
  ENROLLS_ASC = 'asc_enrolls',
}

const enum CourseStatus {
  DRAFT = 'draft',
  ACTIVE = 'active',
  PENDING = 'pending',
}

interface CourseItemData {
  __typename: string
  id: number
  title: string
  thumbnail?: any
  date_added: Date
  status: string
  slug: string
  price_tier?: any
  academy_price_tier?: any
  average_rating: number
  comment: any[]
  unanswered_comment: any[]
  total_enrolled: {
    __typename: string
    aggregate: {
      __typename: string
      count: number
    }
  }
  total_rating: {
    __typename: string
    aggregate: {
      __typename: string
      count: number
    }
  }
  this_month: {
    __typename: string
    aggregate: {
      __typename: string
      count: number
    }
  }
  academy_course_enrollments_aggregate: {
    __typename: string
    aggregate: {
      __typename: string
      count: number
    }
  }
}

interface CourseItemProps extends ListItemProps {
  data?: CourseItemData
  to?: string
}

interface VariableInterface {
  limit: number
  offset: number
  userId: string
  search: string
  dateLastmonth: string
  orderBy: any
  createdDate: {
    _lte: null
    _gte: null
  }
  status?: string[]
}

const CourseStatusLabels = {
  [CourseStatus.ACTIVE]: 'Active',
  [CourseStatus.DRAFT]: 'Draft',
  [CourseStatus.PENDING]: 'Pending',
} as const

const CourseStatusColors: any = {
  [CourseStatus.DRAFT]: undefined,
  [CourseStatus.ACTIVE]: '#4CAF50',
  [CourseStatus.PENDING]: '#EF4D5E',
} as const

const FilterOptions = [
  {
    type: 'checkbox',
    fieldName: 'status',
    name: 'Status',
    options: {
      list: [
        {
          value: CourseStatus.DRAFT,
          label: CourseStatusLabels[CourseStatus.DRAFT],
        },
        {
          value: CourseStatus.PENDING,
          label: CourseStatusLabels[CourseStatus.PENDING],
        },
        {
          value: CourseStatus.ACTIVE,
          label: CourseStatusLabels[CourseStatus.ACTIVE],
        },
      ],
    },
  },
  {
    type: 'date',
    fieldName: 'date',
    name: 'Created Date',
  },
  // {
  //   type: 'checkbox',
  //   fieldName: 'comment_status',
  //   name: 'Comment Status',
  //   options: {
  //     list: [
  //       {
  //         value: 'answered',
  //         label: 'Answered',
  //       },
  //       {
  //         value: 'unanswered',
  //         label: 'Unanswered',
  //       },
  //     ],
  //   },
  // },
]

const CourseItem = (props: CourseItemProps) => {
  const { data, to } = props

  const price = data?.academy_price_tier?.pricing_idr || 0

  return (
    <ListItem
      button
      component={to ? RouterLink : 'button'}
      to={to}
      disabled={!data}
      className="block p-0 opacity-100"
    >
      <div className="flex items-center gap-4 px-6 py-4">
        {data?.thumbnail ? (
          <img
            src={data.thumbnail}
            alt={data.title}
            className="hidden aspect-video h-20 rounded object-cover bg-gray-200 sm:block"
          />
        ) : (
          <div className="hidden sm:block">
            <div className="aspect-video h-20 bg-gray-200 rounded" />
          </div>
        )}

        <div className="flex flex-grow flex-col self-stretch xl:basis-1/3">
          {data ? (
            <LinesEllipsis
              text={data.title}
              maxLine={2}
              className="overflow-hidden text-sm font-bold"
            />
          ) : (
            <p className="text-sm">
              <Skeleton count={2} />
            </p>
          )}

          <Typography color="textSecondary" className="mt-1 text-xs">
            {data ? (
              `Created ${format(new Date(data.date_added), 'MMM d, yyyy')}`
            ) : (
              <Skeleton width="60%" />
            )}
          </Typography>

          <div className="mt-auto flex justify-between gap-2 pt-2">
            <Typography
              color="textSecondary"
              className="text-sm capitalize"
              style={{ color: data && CourseStatusColors[data.status] }}
            >
              {data ? data.status : <Skeleton width={41} />}
            </Typography>

            <Typography color="textSecondary" className="text-sm">
              {data ? (
                price ? (
                  convertToRupiah(price, true)
                ) : (
                  'FREE'
                )
              ) : (
                <Skeleton width={72} />
              )}
            </Typography>
          </div>
        </div>

        {!data ? (
          <div className="hidden basis-2/3 grid-flow-col grid-rows-2 gap-x-2 gap-y-4 xl:grid">
            {Array.from({ length: 7 }, (_, idx) => (
              <div
                key={idx}
                className={`grid ${idx % 2 !== 0 ? 'items-end' : 'items-start'
                  }`}
              >
                <span className="h-4 text-xs">
                  <Skeleton width="75%" />
                </span>
                <span className="h-5">
                  <Skeleton width="50%" />
                </span>
              </div>
            ))}
          </div>
        ) : data.status === CourseStatus.DRAFT ? (
          <div
            className="hidden basis-2/3 items-center gap-4 xl:flex"
            style={{ height: 88 }}
          >
            <span className="text-sm">Finish your course</span>
            <LinearProgress
              variant="determinate"
              value={80}
              color="secondary"
              className="h-2 grow rounded"
              classes={{ bar: 'rounded' }}
            />
          </div>
        ) : (
          <div className="hidden basis-2/3 grid-flow-col grid-rows-2 gap-x-2 gap-y-4 xl:grid">
            {/* <div>
              <Typography color="textSecondary" className="text-xs">
                Earnedzz this month
              </Typography>
              <Typography className="text-sm">
                {convertToRupiah(
                  data?.this_month?.aggregate?.count * price,
                  true
                )}
              </Typography>
            </div>
            <div className="grid items-end">
              <Typography color="textSecondary" className="text-xs">
                Total earned
              </Typography>
              <Typography className="text-sm">
                {convertToRupiah(
                  data?.total_enrolled?.aggregate?.count * price,
                  true
                )}
              </Typography>
            </div> */}

            <div>
              <Typography color="textSecondary" className="text-xs">
                Rating
              </Typography>
              <Typography className="text-sm">
                <StarIcon
                  color="secondary"
                  fontSize="inherit"
                  className="mr-1"
                />
                <span>
                  {data?.average_rating ? data.average_rating.toFixed(1) : 0}
                </span>
              </Typography>
            </div>
            <div className="grid items-end">
              <Typography color="textSecondary" className="text-xs">
                Total Rating
              </Typography>
              <Typography className="text-sm">
                {data.total_rating.aggregate.count}
              </Typography>
            </div>

            <div>
              <Typography color="textSecondary" className="text-xs">
                Comment
              </Typography>
              <Typography className="text-sm">{data.comment.length}</Typography>
            </div>
            <div className="grid items-end">
              <Typography color="textSecondary" className="text-xs">
                Unanswered comment
              </Typography>
              <Typography className="text-sm">
                {data.unanswered_comment.length}
              </Typography>
            </div>

            <div>
              <Typography color="textSecondary" className="text-xs">
                Enrolled
              </Typography>
              <Typography className="text-sm">
                {data.total_enrolled.aggregate.count}
              </Typography>
            </div>
          </div>
        )}
      </div>

      <Divider />
    </ListItem>
  )
}

const sortOptions = [
  { label: 'Latest', value: CourseSortOrder.ID_DESC },
  { label: 'Oldest', value: CourseSortOrder.ID_ASC },
  { label: 'Highest Rating', value: CourseSortOrder.RATING_DESC },
  { label: 'Lowest Rating', value: CourseSortOrder.RATING_ASC },
  { label: 'Most Enrolled', value: CourseSortOrder.ENROLLS_DESC },
  { label: 'Fewest Enrolled', value: CourseSortOrder.ENROLLS_ASC },
]

const sortMap = {
  [CourseSortOrder.ID_DESC]: { id: 'desc' },
  [CourseSortOrder.ID_ASC]: { id: 'asc' },
  [CourseSortOrder.RATING_DESC]: { average_rating: 'desc_nulls_last' },
  [CourseSortOrder.RATING_ASC]: { average_rating: 'asc_nulls_first' },
  [CourseSortOrder.ENROLLS_DESC]: {
    academy_course_enrollments_aggregate: {
      count: 'desc_nulls_last',
    },
  },
  [CourseSortOrder.ENROLLS_ASC]: {
    academy_course_enrollments_aggregate: {
      count: 'asc_nulls_first',
    },
  },
}

const ContributorCoursesPage = () => {
  useTitle('Contributor - Courses')

  const dateNow = format(new Date(), 'yyyy-MM-dd')

  const [searchParams, setSearchParams] = useSearchParams()
  const [filter, setFilter] = React.useState({
    date: {
      _lte: null,
      _gte: null,
    },
    status: [],
  })

  const searchTimeoutRef = React.useRef<any>()
  const rootRef = React.useRef<HTMLElement>(null)

  const search = searchParams.get('query') ?? ''

  const page = +(searchParams.get('page') ?? 1) - 1
  const limit = +(searchParams.get('limit') ?? 10)

  const orderBy =
    (searchParams.get('order') as CourseSortOrder) ?? CourseSortOrder.ID_DESC

  const variables: VariableInterface = {
    limit: limit,
    offset: page * limit,
    userId: USER_ID,
    search: `%${search}%`,
    dateLastmonth: dateNow,
    orderBy: sortMap[orderBy],
    createdDate: filter.date,
    status: filter.status,
  }

  if (!filter.status.length) {
    delete variables.status
  }

  const { data } = useQuery(GET_CONTRIBUTOR_MY_COURSES, {
    fetchPolicy: 'cache-and-network',
    variables,
    context: INSTRUCTOR_CONTEXT,
  })

  React.useEffect(() => {
    return () => clearTimeout(searchTimeoutRef.current)
  }, [])

  const setParams = (next: URLSearchParams) => {
    clearTimeout(searchTimeoutRef.current)
    setSearchParams(next, { replace: true })

    setTimeout(() =>
      rootRef.current!.scrollIntoView({ block: 'start', behavior: 'smooth' })
    )
  }

  const handleSearchChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('query', ev.target.value)

    clearTimeout(searchTimeoutRef.current)
    searchTimeoutRef.current = setTimeout(() => {
      setSearchParams(next, { replace: true })
    }, 500)
  }

  const onApplyFilter = (result: any) => {
    const mappedResult: any = mapFilterResults(result[0])

    if (!Object.keys(mappedResult).length) {
      setFilter({
        date: {
          _lte: null,
          _gte: null,
        },
        status: [],
      })
    } else {
      if (mappedResult.date) {
        setFilter({
          ...filter,
          ...mappedResult,
          date: {
            _gte: mappedResult.date.from,
            _lte: mappedResult.date.to,
          },
        })
      } else {
        setFilter({ ...filter, ...mappedResult })
      }
    }
  }

  const handleOrderByChange = (value: any) => {
    const next = new URLSearchParams(searchParams)
    next.set('order', value)

    setParams(next)
  }

  const handlePageChange = (newPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.set('page', '' + (newPage + 1))

    setParams(next)
  }

  const handleRowsPerPageChange = (newRowsPerPage: number) => {
    const next = new URLSearchParams(searchParams)
    next.delete('page')
    next.set('limit', '' + newRowsPerPage)

    setParams(next)
  }

  return (
    <Paper ref={rootRef} className="scroll-m-32">
      <div className="flex h-20 items-center px-6 py-1">
        <Typography color="primary" className="font-bold">
          My Course
        </Typography>

        <div className="grow" />

        <Button
          component={RouterLink}
          to="create"
          variant="contained"
          color="primary"
          size="large"
          disableElevation
        >
          Create Course
        </Button>
      </div>

      <Divider />

      <div className="px-6 pt-6 pb-4">
        <TextField
          key={search}
          placeholder="Search"
          variant="outlined"
          fullWidth
          defaultValue={search}
          onChange={handleSearchChange}
          autoFocus
          InputProps={{
            classes: {
              root: `p-0`,
              input: `px-4 py-3 placeholder:opacity-1 placeholder:color-[#a9a8a8]`,
            },
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon color="primary" className="mr-4" />
              </InputAdornment>
            ),
          }}
        />
      </div>
      <div className="flex items-center justify-between px-2 pb-4">
        <Select
          value={orderBy}
          onChange={(e) => handleOrderByChange(e.target.value)}
          variant="standard"
          disableUnderline={true}
          SelectDisplayProps={{
            className:
              'text-sm pl-4 pr-6 py-2 focus:bg-[#f5f5f5] outline-none rounded transition font-bold',
          }}
        >
          {sortOptions.map((option: any, idx: number) => {
            return (
              <MenuItem value={option.value} key={idx}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>

        <Filter
          includeExclude={false}
          listFilter={FilterOptions}
          onApply={onApplyFilter}
        >
          <Button className="px-4 font-bold">Filter</Button>
        </Filter>
      </div>

      {search && (
        <div className="px-6 pb-4">
          <Typography color="textSecondary" className="text-sm">
            Showing {data?.academy_courses_aggregate?.aggregate?.count || 0}{' '}
            results based on your search &quot;
            <span className="whitespace-pre-wrap">{search}</span>&quot;
          </Typography>
        </div>
      )}

      <Divider />

      <div>
        {!data ? (
          Array.from({ length: limit }, (_, idx) => <CourseItem key={idx} />)
        ) : data.academy_courses.length > 0 ? (
          data.academy_courses.map((item: CourseItemData) => (
            <CourseItem key={item.id} data={item} to={`${item.slug}`} />
          ))
        ) : (
          <div className="py-16">
            <NoListComponent title="Sorry, No List" />
          </div>
        )}
      </div>

      <Pagination
        rowsPerPageOptions={[10, 25, 100]}
        count={data?.academy_courses_aggregate?.aggregate?.count || 0}
        rowsPerPage={limit}
        page={page}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleRowsPerPageChange}
      />
    </Paper>
  )
}

export default ContributorCoursesPage
