import {LessonType} from '../../manage/curriculums/_utils'

import ArticleDetails from './details/ArticleDetails'
import QuizDetails from './details/QuizDetails'
import VideoDetails from './details/VideoDetails'

export interface LessonDetailProps {
  type: LessonType
  lesson: any
}

const LessonDetail = (props: LessonDetailProps) => {
  const {type, lesson} = props

  switch (type) {
    case LessonType.ARTICLE:
      return <ArticleDetails lesson={lesson} />
    case LessonType.QUIZ:
      return <QuizDetails lesson={lesson} />
    case LessonType.VIDEO:
      return <VideoDetails lesson={lesson} />
    default:
      return <div></div>
  }
}

export default LessonDetail
