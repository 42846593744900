// import {CircularProgress} from '@material-ui/core'
import React from 'react'
import {IEvent} from '../../../../interfaces'
import {SeeAllPageContext} from '../../../../pages/learner/see-all'
import {LearnerEventContext} from '../../../../provider/LearnerEvent.provider'
import {
  LIST_EVENTS_FOR_YOU,
  LIST_EVENTS_NEARBY,
} from '../../../../provider/services/Event.service'
import {LearningType} from '../../../../utils/enum'
import NoListComponent from '../../../empty-state/NoList.component'
import LearnerCatalogCard from '../../catalog/LearnerCatalogCard.component'
import SearchSeeAll from '../SearchSeeAll.component'
import LoadingCardComponent from '../../../loading/LoadingCard.component'

interface EventProps extends SeeAllPageContext {}

const Event = (props: EventProps) => {
  const learnerEventContext = React.useContext(LearnerEventContext)
  const [search, setSearch] = React.useState('')

  const handleSearchChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(ev.target.value)
  }

  // TODO(intrnl): trying to isolate the cause of the app freezing on this page

  // temporary solution to make filters update the query
  const stringifiedFilter = React.useMemo(() => {
    return JSON.stringify(props.filter)
  }, [props.filter])

  // we're not breaking the rules of hook here because we're just supplanting
  // it with another hook.

  // this seems to have helped a little, keeping it until we refactor how we
  // do querying for stuff.
  const useEventQuery = () => {
    if (props.type === 'nearby') {
      return LIST_EVENTS_NEARBY(
        -1,
        1,
        learnerEventContext.reducerState.eventsNearbyLat,
        learnerEventContext.reducerState.eventsNearbyLon,
        props.filter.priceMin,
        props.filter.priceMax,
        props.filter.categoriesREST as any,
        `listEventsNearby${stringifiedFilter}`
      )
    } else {
      return LIST_EVENTS_FOR_YOU(
        -1,
        1,
        props.filter.priceMin,
        props.filter.priceMax,
        props.filter.categoriesREST as any,
        `listEventsForYouAll${stringifiedFilter}`
      )
    }
  }

  const title =
    props.type === 'nearby' ? 'Acara Terdekat' : 'acara - acara untuk Anda'

  const {data, isLoading} = useEventQuery()

  const filtered = React.useMemo(() => {
    if (!data) {
      return []
    }

    return data.data.filter((item: IEvent) =>
      item.title.toLowerCase().includes(search.toLowerCase())
    )
  }, [data, search])

  return (
    <div>
      <SearchSeeAll
        value={search}
        handleChange={handleSearchChange}
        placeholder="Cari Acara"
      />

      <div className="mt-8">
        <div className="mt-5 text-[#a9a8a8] text-sm">
          {'' + filtered.length} Hasil dari {title}
        </div>
      </div>

      <div>
        {isLoading ? (
          <div className="flex justify-center">
            <LoadingCardComponent length={2} isContributor />
          </div>
        ) : (
          filtered.length < 1 && (
            <NoListComponent
              title="Sorry, No List"
              subTitle="Event not found"
            />
          )
        )}

        <div className="grid grid-cols-1 lg:grid-cols-2">
          {filtered.map((item: IEvent) => (
            <LearnerCatalogCard
              key={item.id}
              type={LearningType.EVENT}
              item={item}
              hidePrice={false}
              width="w-full"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Event
