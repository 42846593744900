import React, {FC, ReactElement, ReactNode} from 'react'
import {Typography} from '@material-ui/core'
import Skeleton from 'react-loading-skeleton'
import Dompurify from 'dompurify'
interface IHash {
  [key: string]: any
}

type TabAboutProps = {
  title: string
  authorWritten: string
  publisherWritten: string
  isbn: string
  copyrightYear: number
  edition: number
  bookCategory: string
  subBookCategory: string
  summary: string
}

const TabAbout: FC<TabAboutProps> = (props: TabAboutProps): ReactElement => {
  const isLoading = !props
  if (isLoading) {
    return (
      <>
        <Skeleton className="my-2" width={'20%'} />
        <Skeleton count={5} className="my-2" width={'100%'} />
      </>
    )
  }

  const data: IHash = {
    Judul: props.title || 'unknown',
    Penulis: props.authorWritten || '-',
    Penerbit: props.publisherWritten || '-',
    ISBN: props.isbn || '-',
    'Tahun Hak Cipta': props.copyrightYear || '-',
    Edisi: props.edition || '-',
    Kategori: props.bookCategory || '-',
    'Sub Kategori': props.subBookCategory || '-',
  }

  return (
    <div className="flex flex-col pt-3">
      <Typography variant="body1" className="font-bold">
        Rincian
      </Typography>

      <table className="mt-4 w-fit">
        <tbody>
          {Object.entries(data).map(([key, value], index): ReactNode => {
            return (
              <tr className="h-8" key={`${key}-${index}`}>
                <td className="align-top w-min-fit">
                  <Typography variant="subtitle2" className="text-gray-700">
                    {key}
                  </Typography>
                </td>

                <td className="align-top w-5">
                  <Typography
                    variant="subtitle2"
                    className="text-gray-700 text-center"
                  >
                    :
                  </Typography>
                </td>

                <td>
                  <Typography
                    variant="subtitle2"
                    className="text-gray-400 whitespace-normal"
                  >
                    {value}
                  </Typography>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <Typography variant="body1" className="mt-9 font-bold">
        Keterangan
      </Typography>

      <div
        className="mt-4 text-sm text-gray-700 whitespace-normal"
        dangerouslySetInnerHTML={{__html: Dompurify.sanitize(props.summary)}}
      />
    </div>
  )
}

export default TabAbout
