import React from 'react'
import {LearnerAcademicContext} from './Academic.provider'
import {
  MY_BOOKS,
  LIST_TRENDING_BOOKS,
  LIST_RECOMMENDATION_BOOKS,
  LIST_ALL_BOOKS,
  // LIST_RECOMMENDATION_BOOK_CATEGORIES,
} from './services/Book.service'

import {CircularProgress} from '@material-ui/core'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  resetBookPagination,
  initialValue,
} from './reducers/Learner.reducer'

import {LearnerBookContextType} from '../interfaces'

type LearnerBookProviderProps = {
  children: React.ReactNode
}

export const LearnerBookContext =
  React.createContext<LearnerBookContextType>(initialValue)

export const LearnerBookProvider = (props: LearnerBookProviderProps) => {
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )

  const {
    data: dataListTrendingBooks,
    isLoading: loadingListTrendingBooks,
    refetch: refetchTrendingBooks,
  } = LIST_TRENDING_BOOKS(
    state.limitTrendingBooks,
    state.pageTrendingBooks,
    state.bookType
  )

  const {
    data: dataListRecommendedBooks,
    isLoading: loadingListRecommendedBooks,
    refetch: refetchRecommendedBooks,
  } = LIST_RECOMMENDATION_BOOKS(
    state.limitRecommendedBooks,
    state.pageRecommendedBooks,
    state.bookType
  )

  const {
    data: dataListAllBooks,
    isLoading: loadingListAllBooks,
    refetch: refetchAllBooks,
  } = LIST_ALL_BOOKS(
    state.bookType,
    state.limitAllBooks,
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.priceMin,
    learnerAcademicContext.reducerState.academicFilter.priceMax,
    learnerAcademicContext.reducerState.academicFilter.rating,
    learnerAcademicContext.reducerState.academicFilter.categoriesREST,
    learnerAcademicContext.reducerState.academicFilter.levelsREST,
    learnerAcademicContext.reducerState.academicFilter.languagesREST,
    ''
  )

  const {
    data: dataMyBooks,
    loading: loadingMyBooks,
    refetch: refetchMyBooks,
  } = MY_BOOKS(
    state.limitMyBooks,
    state.offsetMyBooks,
    state.bookType,
    state.isSkipMyBooks
  )

  // const {
  //   data: dataListRecommendationBookCategories,
  //   loading: loadingListRecommendationBookCategories,
  //   // refetch: refetchListRecommendationBookCategories,
  // } = LIST_RECOMMENDATION_BOOK_CATEGORIES()

  React.useEffect(() => {
    resetBookPagination(dispatch)
    refetchMyBooks()
  }, [state.bookType, refetchMyBooks])

  React.useEffect(() => {
    if (refetchTrendingBooks) refetchTrendingBooks()
  }, [
    state.limitTrendingBooks,
    state.pageTrendingBooks,
    refetchTrendingBooks,
    state.bookType,
  ])

  React.useEffect(() => {
    if (refetchRecommendedBooks) refetchRecommendedBooks()
  }, [
    state.limitRecommendedBooks,
    state.pageRecommendedBooks,
    refetchRecommendedBooks,
    state.bookType,
  ])
  React.useEffect(() => {
    if (refetchAllBooks) refetchAllBooks()
  }, [
    state.bookType,
    state.limitAllBooks,
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter,
    refetchAllBooks,
  ])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  if (
    loadingMyBooks ||
    // loadingListRecommendationBookCategories ||
    loadingListTrendingBooks ||
    loadingListRecommendedBooks ||
    loadingListAllBooks
  ) {
    return <CircularProgress />
  }

  return (
    <LearnerBookContext.Provider
      value={{
        changePageLimit: changePageLimitHandler,

        myBooks: dataMyBooks || initialValue.myBooks,
        myBooksIsLoading: loadingMyBooks,
        bookTrendings: dataListTrendingBooks || initialValue.bookTrendings,
        bookRecommendationCategories:
          // dataListRecommendationBookCategories ||
          initialValue.bookRecommendationCategories,

        bookRecommended:
          dataListRecommendedBooks || initialValue.bookRecommended,
        bookAll: dataListAllBooks || initialValue.bookAll,

        reducerState: state,
        refetchMyBooks,
        refetchAllBooks,
        loadingListAllBooks,
      }}
    >
      {props.children}
    </LearnerBookContext.Provider>
  )
}
