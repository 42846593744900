import React from 'react'
import {Card, CardMedia} from '@material-ui/core'
import {IMicroLearning} from '../../../../interfaces'
import Rating from '../../../utils-components/Rating.component'
import {
  CustomFlex,
  CustomTypography,
} from '../../../utils-components/GlobalStyles'
import {convertToRupiah} from '../../../../utils/helpers'
import {useNavigate} from 'react-router-dom'
import {bindHover} from 'material-ui-popup-state/hooks'
import {PopupState} from 'material-ui-popup-state/core'

import AddToCartButton from '../../../button/AddToCartButton.component'
import WishlistButton from '../../../button/WishlistButton.component'
import LinesEllipsis from 'react-lines-ellipsis'

type MicroLearningCardProps = {
  classes: any
  item: IMicroLearning
  hidePrice: boolean
  popupState: PopupState
  isWishlist?: boolean
  removeFromWishlist?: () => void
  addToCart?: () => void
  width?: string
  featureName?: string
  wishlistLoading?: boolean
}

const MicroLearningCard = ({
  classes,
  item,
  hidePrice,
  popupState,
  isWishlist,
  removeFromWishlist,
  addToCart,
  width = 'w-1/4',
  featureName,
  wishlistLoading,
}: MicroLearningCardProps): React.ReactElement => {
  const defaultimage = require('../../../../assets/images/defaultMicrolearningThumbnail.image.png')
  const navigate = useNavigate()

  const isFree = item.price === 0

  return (
    <div
      className={`w-full md:w-1/2 lg:${width}`}
      style={{height: hidePrice ? 280 : isWishlist ? 370 : 310}}
      {...bindHover(popupState)}
      onClick={() =>
        navigate(`/learner/micro_learning/${item.id}`, {
          state: {prevRouteName: featureName},
        })
      }
    >
      <Card
        className={classes.root}
        style={{
          height: '90%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <CardMedia
            className={classes.mediaLandspace}
            image={item.icon_url || defaultimage}
            style={
              isWishlist ? {display: 'flex', justifyContent: 'flex-end'} : {}
            }
          >
            {isWishlist && (
              <WishlistButton
                isWishlist={true}
                action={removeFromWishlist}
                loading={Boolean(wishlistLoading)}
              />
            )}
          </CardMedia>
          <CustomFlex direction="column" className={classes.microLearningCard}>
            <div className="h-14 overflow-visible">
              <div style={{lineHeight: '1.5em', height: '3.5em'}}>
                <LinesEllipsis
                  text={item?.name}
                  maxLine="2"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                  style={{fontSize: '0.9rem', fontWeight: 'bold'}}
                />
              </div>
              <LinesEllipsis
                className="text-gray-400 text-xs"
                text={item?.global_user?.name}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
            <div className="mt-3">
              <Rating
                averageRating={item.average_rating || 0}
                totalEnrollment={item.review_count || 0}
                isSmallRating={true}
              />
            </div>
          </CustomFlex>
        </div>
        <div className="p-2">
          {!hidePrice && (
            <CustomTypography fweight="bold" fsize="14px" align="right">
              {isFree ? 'Gratis' : convertToRupiah(item.price)}
            </CustomTypography>
          )}
          {isWishlist && (
            <AddToCartButton
              isInCart={item?.isInCart || false}
              action={addToCart}
              className="mt-4"
              fullWidth
            />
          )}
        </div>
      </Card>
    </div>
  )
}

export default MicroLearningCard
