import JWVideoPreview from '../../../../../../../components/contributor/misc/JWVideoPreview'

export interface VideoDetailsProps {
  lesson: any
}

const VideoDetails = (props: VideoDetailsProps) => {
  const {lesson} = props

  return (
    <div className="p-6">
      <div className="w-1/2 mx-auto aspect-video">
        <JWVideoPreview src={lesson.video_url} />
      </div>
    </div>
  )
}

export default VideoDetails
