import React from 'react'
import {LearnerAcademicContext} from './Academic.provider'
import {
  LIST_EVENTS_FOR_YOU,
  LIST_EVENTS_NEARBY,
  // LIST_RECOMMENDATION_EVENT_CATEGORIES,
  LIST_ALL_EVENTS,
} from './services/Event.service'

import {CircularProgress} from '@material-ui/core'

import {
  LearnerReducer,
  LearnerReducerInitialState,
  changePageLimit,
  changeActiveCatalogTab,
  changeEventsForYouLocation,
  initialValue,
} from './reducers/Learner.reducer'

import {LearningType} from '../utils/enum'
import {LearnerEventContextType} from '../interfaces'

type LearnerEventProviderProps = {
  children: React.ReactNode
}

export const LearnerEventContext =
  React.createContext<LearnerEventContextType>(initialValue)

export const LearnerEventProvider = (props: LearnerEventProviderProps) => {
  const learnerAcademicContext = React.useContext(LearnerAcademicContext)
  const [state, dispatch] = React.useReducer(
    LearnerReducer,
    LearnerReducerInitialState
  )

  const {
    data: dataListEventsForyou,
    isLoading: loadingListEventsForyou,
    refetch: refetchListEventsForyou,
  } = LIST_EVENTS_FOR_YOU(state.limitEventsForYou, state.pageEventsForYou)

  const {
    data: dataListEventsNearBy,
    isLoading: loadingListEventsNearBy,
    refetch: refetchListEventsNearBy,
  } = LIST_EVENTS_NEARBY(
    state.limitEventsForYou,
    state.pageEventsNearby,
    state.eventsNearbyLat,
    state.eventsNearbyLon
  )

  // const {
  //   data: dataListRecommendationEventCategories,
  //   loading: loadingListRecommendationEventCategories,
  //   // refetch: refetchListRecommendationBookCategories,
  // } = LIST_RECOMMENDATION_EVENT_CATEGORIES()

  const {
    data: dataListAllEvents,
    isLoading: loadingListAllEvents,
    refetch: refetchListAllEvents,
  } = LIST_ALL_EVENTS(
    state.limitAllEvents,
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.priceMin,
    learnerAcademicContext.reducerState.academicFilter.priceMax,
    learnerAcademicContext.reducerState.academicFilter.categoriesREST,
    ''
  )

  React.useEffect(() => {
    if (refetchListEventsForyou) refetchListEventsForyou()
  }, [state.pageEventsForYou, refetchListEventsForyou])

  React.useEffect(() => {
    if (refetchListEventsNearBy) refetchListEventsNearBy()
  }, [
    state.pageEventsNearby,
    state.eventsNearbyLat,
    state.eventsNearbyLon,
    refetchListEventsNearBy,
  ])

  React.useEffect(() => {
    if (refetchListAllEvents) refetchListAllEvents()
  }, [
    state.limitAllEvents,
    refetchListAllEvents,
    learnerAcademicContext.reducerState.searchKeyword,
    learnerAcademicContext.reducerState.academicFilter.priceMin,
    learnerAcademicContext.reducerState.academicFilter.priceMax,
    learnerAcademicContext.reducerState.academicFilter.rating,
    learnerAcademicContext.reducerState.academicFilter.categoriesREST,
    learnerAcademicContext.reducerState.academicFilter.levelsREST,
    learnerAcademicContext.reducerState.academicFilter.languagesREST,
  ])

  const changePageLimitHandler = async (
    page: number = 1,
    limit: number = 4,
    dispatchType: string = '',
    offset: number = 0,
    catalogType: number = 0,
    isSkip: boolean = false
  ) => {
    return changePageLimit(
      page,
      limit,
      dispatchType,
      dispatch,
      offset,
      catalogType,
      isSkip
    )
  }

  const changeActiveTabHandler = async (activeTabCatalog: LearningType) => {
    return changeActiveCatalogTab(dispatch, activeTabCatalog)
  }

  const changeEventsForYouLocationHandler = async (
    lat: number,
    lon: number
  ) => {
    return changeEventsForYouLocation(dispatch, lat, lon)
  }

  if (
    loadingListEventsForyou ||
    loadingListEventsNearBy ||
    // loadingListRecommendationEventCategories ||
    loadingListAllEvents
  ) {
    return <CircularProgress />
  }

  return (
    <LearnerEventContext.Provider
      value={{
        changePageLimit: changePageLimitHandler,
        changeActiveTab: changeActiveTabHandler,
        changeEventsForYouLocation: changeEventsForYouLocationHandler,
        eventsForYou: dataListEventsForyou || initialValue.eventsForYou,
        eventsNearBy: dataListEventsNearBy || initialValue.eventsNearBy,
        eventRecommendationCategories:
          // dataListRecommendationEventCategories ||
          initialValue.eventRecommendationCategories,
        eventsAll: dataListAllEvents || initialValue.eventsForYou,
        reducerState: state,
      }}
    >
      {props.children}
    </LearnerEventContext.Provider>
  )
}
